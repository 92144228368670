import * as React from "react";
import type { SVGProps } from "react";
const SvgSolomonIslands = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Solomon_Islands_svg__cls-3{fill:#f3d02f}.Solomon_Islands_svg__cls-4{fill:#fff}"
        }
      </style>
    </defs>
    <g id="Solomon_Islands_svg__Solomon_Islands" data-name="Solomon Islands">
      <path
        d="M64.41 719.84H1280V36.37z"
        style={{
          fill: "#325d37",
        }}
      />
      <path
        d="M0 .17v683.46L1215.6.17z"
        style={{
          fill: "#2d4db6",
        }}
      />
      <path
        d="M0 719.84h64.41L1280 36.37V.17z"
        className="Solomon_Islands_svg__cls-3"
      />
      <path
        d="M1215.6.17 0 683.63v36.21L1280 .17z"
        className="Solomon_Islands_svg__cls-3"
      />
      <path
        d="m235.9 160.44-19.06 58.66 29.34 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m235.9 160.44 19.06 58.66-29.32 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M294.56 203.05h-61.68v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m294.56 203.05-49.89 36.27-18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m177.24 203.05 49.91 36.27 18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M177.24 203.05h61.67v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m272.16 272.02-19.06-58.65-29.34 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m272.16 272.02-49.91-36.26 18.14-24.94z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m199.66 272.02 49.88-36.26-18.1-24.94z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m199.66 272.02 19.06-58.65 29.31 9.53zM349.31 273.85l-19.07 58.66 29.35 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m349.31 273.85 19.05 58.66-29.31 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M407.99 316.46h-61.7v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m407.99 316.46-49.91 36.27-18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m290.64 316.46 49.92 36.27 18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M290.64 316.46h61.68v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m385.57 385.43-19.06-58.66-29.34 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m385.57 385.43-49.91-36.26 18.13-24.94z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m313.07 385.43 49.88-36.26-18.14-24.94z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m313.07 385.43 19.06-58.66 29.31 9.53zM122.49 273.85l-19.06 58.66 29.34 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m122.49 273.85 19.06 58.66-29.32 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M181.15 316.46h-61.67v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m181.15 316.46-49.89 36.27-18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m63.83 316.46 49.91 36.27 18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M63.83 316.46h61.67v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m158.75 385.43-19.06-58.66-29.34 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m158.75 385.43-49.91-36.26 18.14-24.94z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m86.25 385.43 49.88-36.26L118 324.23z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m86.25 385.43 19.06-58.66 29.32 9.53zM122.49 47.03l-19.06 58.66 29.34 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m122.49 47.03 19.06 58.66-29.32 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M181.15 89.65h-61.67v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m181.15 89.65-49.89 36.26-18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m63.83 89.65 49.91 36.26 18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M63.83 89.65h61.67v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m158.75 158.62-19.06-58.66-29.34 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m158.75 158.62-49.91-36.27 18.14-24.94z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m86.25 158.62 49.88-36.27L118 97.41z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m86.25 158.62 19.06-58.66 29.32 9.53zM349.31 47.03l-19.07 58.66 29.35 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m349.31 47.03 19.05 58.66-29.31 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M407.99 89.65h-61.7v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m407.99 89.65-49.91 36.26-18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m290.64 89.65 49.92 36.26 18.13-24.97z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="M290.64 89.65h61.68v30.85z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m385.57 158.62-19.06-58.66-29.34 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m385.57 158.62-49.91-36.27 18.13-24.94z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m313.07 158.62 49.88-36.27-18.14-24.94z"
        className="Solomon_Islands_svg__cls-4"
      />
      <path
        d="m313.07 158.62 19.06-58.66 29.31 9.53z"
        className="Solomon_Islands_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgSolomonIslands;
