import * as React from "react";
import type { SVGProps } from "react";

const SvgBermuda = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20" {...props}>
    {/* Red Background */}
    <rect width="30" height="20" fill="#C8102E" />

    {/* Union Jack in Top Left Corner */}
    <g transform="scale(0.6)">
      <rect width="12" height="8" fill="#012169" />
      <polygon points="0,0 6,3.5 12,0 12,1 7.5,4 12,7 12,8 6,4.5 0,8 0,7 4.5,4 0,1" fill="#FFF" />
      <polygon points="0,0 6,3.5 12,0 12,0.5 6,4 0,0.5" fill="#C8102E" />
      <polygon points="0,0 3,0 9,6 12,6 12,8 9,8 3,2 0,2" fill="#FFF" />
      <polygon points="12,0 9,0 3,6 0,6 0,8 3,8 9,2 12,2" fill="#FFF" />
      <polygon points="0,0 2,0 10,8 8,8" fill="#C8102E" />
      <polygon points="12,0 10,0 2,8 4,8" fill="#C8102E" />
    </g>

    {/* Bermuda Coat of Arms */}
    <g transform="translate(19 5) scale(0.85)">
      <rect x="0" y="0" width="6" height="8" fill="#FFFFFF" stroke="#000" strokeWidth="0.5" />
      <path d="M 1.5 7 L 1.5 5 L 2.5 4 L 4 4 L 4.5 5 L 4.5 7" fill="#FFB02E" />
      <path d="M 3 5 Q 4 6 2.5 7 Q 1 6 3 5" fill="#C8102E" />
    </g>
  </svg>
);

export default SvgBermuda;