import * as React from "react";
import type { SVGProps } from "react";
const SvgJamaica = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Jamaica_svg__cls-1{fill:#009b3a}.Jamaica_svg__cls-2{fill:#fed100}"}
      </style>
    </defs>
    <g id="Jamaica_svg__Jamaica">
      <path
        d="M119.26 720h1041.48L639.99 427.09zM1160.74 0H119.26l520.73 292.92z"
        className="Jamaica_svg__cls-1"
      />
      <path d="M0 652.92 520.73 360 0 67.09zM1280 652.92V67.09L759.27 360z" />
      <path
        d="M0 720h119.26l520.73-292.91L1160.74 720H1280L639.99 360zM1160.74 0 639.99 292.92 119.26 0H0l640.01 360L1280 0z"
        className="Jamaica_svg__cls-2"
      />
      <path
        d="M1280 0 640.01 360 1280 720v-67.08L759.27 360 1280 67.09zM0 67.09 520.73 360 0 652.92V720l639.99-360L0 0z"
        className="Jamaica_svg__cls-2"
      />
    </g>
  </svg>
);
export default SvgJamaica;
