import * as React from "react";
import type { SVGProps } from "react";
const SvgSaintLucia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <g data-name="Saint Lucia">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#6cf",
        }}
      />
      <path
        d="M426.67 657.6 640 624l213.33 33.6L640 64.8z"
        style={{
          fill: "#fff",
        }}
      />
      <path d="M455.47 657.6 640 624l184.53 33.6L640 144z" />
      <path
        d="M426.67 657.6h426.66L640 360z"
        style={{
          fill: "#fcd116",
        }}
      />
    </g>
  </svg>
);
export default SvgSaintLucia;
