import * as React from "react";
import type { SVGProps } from "react";

const SvgAmericanSamoa = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <rect width="1280" height="720" fill="#002b7f" />
    <polygon points="0,0 640,360 0,720" fill="#ffffff" />
    <polygon points="0,0 620,360 0,720" fill="#ce1126" />
    <polygon points="1280,0 640,360 1280,720" fill="#ffffff" />
    <polygon points="1280,0 660,360 1280,720" fill="#ce1126" />
    <path
      d="M855 360c0-50-35-95-95-95l-60 20c10 0 10 15 0 20l50 20c10 10 15 10 20 25l20 10c10 10 0 25-10 30s-20 0-20 10c0 10 20 20 20 35 10 15 10 35 10 55"
      fill="#ffb915"
    />
    <path
      d="M760 355l-5 10 20 15-15 10-10-10v10l15 15h10l5-5-5-10 15 5 5-15"
      fill="#000000"
    />
  </svg>
);

export default SvgAmericanSamoa;