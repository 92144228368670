import * as React from "react";
import type { SVGProps } from "react";
const SvgTurkey = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Turkey_svg__cls-1{fill:#e11f26}.Turkey_svg__cls-2{fill:#fff}"}
      </style>
    </defs>
    <g id="Turkey_svg__Turkey">
      <path d="M0 0h1280v720H0z" className="Turkey_svg__cls-1" />
      <circle cx={425} cy={360.08} r={200} className="Turkey_svg__cls-2" />
      <circle cx={475} cy={360.08} r={160} className="Turkey_svg__cls-1" />
      <path
        d="m583.33 360.08 180.91 58.78-111.81-153.88v190.21L764.24 301.3z"
        className="Turkey_svg__cls-2"
      />
    </g>
  </svg>
);
export default SvgTurkey;
