import * as React from "react";
import type { SVGProps } from "react";

const SvgMartinique = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 768" {...props}>
    {/* Blue Background */}
    <rect width="1024" height="768" fill="#0033a0" />
    
    {/* White Cross */}
    <rect x="0" y="0" width="1024" height="192" fill="#fff" />
    <rect x="0" y="0" width="192" height="768" fill="#fff" />

    {/* Serpent Symbols */}
    <g fill="#fff">
      <path d="M376 168c-16 0-31 6-43 17-11 11-18 26-18 43s7 32 18 43c12 11 27 17 43 17s31-6 43-17c12-11 18-26 18-43s-6-32-18-43c-12-11-27-17-43-17zM504 360c-16 0-31 6-43 17-11 11-18 26-18 43s7 32 18 43c12 11 27 17 43 17s31-6 43-17c12-11 18-26 18-43s-6-32-18-43c-12-11-27-17-43-17z"/>
      <path d="M264 168c-16 0-31 6-43 17-11 11-18 26-18 43s7 32 18 43c12 11 27 17 43 17s31-6 43-17c12-11 18-26 18-43s-6-32-18-43c-12-11-27-17-43-17zM392 360c-16 0-31 6-43 17-11 11-18 26-18 43s7 32 18 43c12 11 27 17 43 17s31-6 43-17c12-11 18-26 18-43s-6-32-18-43c-12-11-27-17-43-17z"/>
    </g>
  </svg>
);

export default SvgMartinique;