import * as React from "react";
import type { SVGProps } from "react";
const SvgKyrgyzstan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <clipPath id="Kyrgyzstan_svg__clip-path">
        <circle
          cx={642.8}
          cy={359.4}
          r={120.24}
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <style>
        {
          ".Kyrgyzstan_svg__cls-2{fill:#e8112d}.Kyrgyzstan_svg__cls-3{fill:#ffef00}.Kyrgyzstan_svg__cls-4{clip-path:url(#Kyrgyzstan_svg__clip-path)}"
        }
      </style>
    </defs>
    <g id="Kyrgyzstan_svg__Kyrgyzstan">
      <path d="M0-.12h1280v720.24H0z" className="Kyrgyzstan_svg__cls-2" />
      <g id="Kyrgyzstan_svg__r4">
        <path
          id="Kyrgyzstan_svg__r1"
          d="M632.7 489.26c10.1 26.45-11.55 67.33 10.1 86.57-7.22-28.86 16.83-60.12 10.1-86.57s-30.31-26.46-20.2 0"
          className="Kyrgyzstan_svg__cls-3"
        />
        <path
          id="Kyrgyzstan_svg__r1-2"
          d="M512.94 349.3c-26.46 10.1-67.34-11.55-86.58 10.1 28.86-7.22 60.12 16.83 86.58 10.1s26.45-30.3 0-20.2"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-3"
          d="M652.9 229.54c-10.1-26.46 11.54-67.34-10.1-86.58 7.21 28.86-16.84 60.12-10.1 86.58s30.3 26.46 20.2 0"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-4"
          d="M772.66 369.5c26.45-10.1 67.34 11.5 86.57-10.1-28.86 7.21-60.12-16.84-86.57-10.1s-26.46 30.3 0 20.2"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
      </g>
      <g id="Kyrgyzstan_svg__r4-2" data-name="r4">
        <path
          id="Kyrgyzstan_svg__r1-5"
          d="M593.06 479.78c1.43 28.28-31.79 60.47-17.15 85.46 2.06-29.68 34.59-52 36.36-79.22s-20.64-34.52-19.21-6.24"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-6"
          d="M522.41 309.66c-28.28 1.43-60.47-31.78-85.46-17.14 29.68 2.05 52 34.58 79.22 36.35s34.52-20.64 6.24-19.21"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-7"
          d="M692.53 239c-1.43-28.28 31.79-60.47 17.15-85.45-2.06 29.67-34.59 52-36.36 79.21S694 267.29 692.53 239"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-8"
          d="M763.18 409.13c28.28-1.43 60.47 31.79 85.46 17.15-29.68-2.06-52-34.59-79.22-36.36s-34.52 20.64-6.24 19.21"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
      </g>
      <g id="Kyrgyzstan_svg__r4-3" data-name="r4">
        <path
          id="Kyrgyzstan_svg__r1-9"
          d="M558.29 458.52c-7.37 27.34-48.91 47.69-42.71 76 11.12-27.59 49-38.75 59.06-64.11s-8.97-39.23-16.35-11.89"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-10"
          d="M543.67 274.9c-27.33-7.38-47.69-48.92-76-42.72 27.58 11.13 38.74 49 64.1 59.06s39.23-8.97 11.9-16.34"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-11"
          d="M727.3 260.27c7.37-27.33 48.91-47.69 42.71-76-11.12 27.59-49 38.74-59.06 64.1s8.97 39.24 16.35 11.9"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-12"
          d="M741.92 443.9c27.34 7.38 47.69 48.92 76 42.71-27.58-11.12-38.74-48.95-64.1-59.05s-39.24 8.96-11.9 16.34"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
      </g>
      <g id="Kyrgyzstan_svg__r4-4" data-name="r4">
        <path
          id="Kyrgyzstan_svg__r1-13"
          d="M531.8 427.56c-15.46 23.72-61.26 30.24-64.1 59.05 19.1-22.8 58.53-21.72 76-42.71s3.56-40.06-11.9-16.34"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-14"
          d="M574.64 248.4c-23.72-15.46-30.24-61.26-59.06-64.1 22.8 19.1 21.72 58.53 42.71 76s40.07 3.57 16.35-11.9"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-15"
          d="M753.79 291.24c15.47-23.72 61.26-30.24 64.1-59.06-19.1 22.8-58.53 21.72-76 42.72s-3.56 40.1 11.9 16.34"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-16"
          d="M711 470.39c23.72 15.47 30.24 61.26 59.06 64.11-22.8-19.11-21.72-58.54-42.71-76s-40.12-3.57-16.35 11.89"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
      </g>
      <g id="Kyrgyzstan_svg__r20">
        <g id="Kyrgyzstan_svg__r4-5" data-name="r4">
          <path
            id="Kyrgyzstan_svg__r1-17"
            d="M516.17 389.92c-22 17.78-67.61 9.83-79.22 36.36 25.22-15.78 62.38-2.57 85.46-17.15s15.8-36.99-6.24-19.21"
            className="Kyrgyzstan_svg__cls-3"
            data-name="r1"
          />
          <path
            id="Kyrgyzstan_svg__r1-18"
            d="M612.27 232.77c-17.78-22-9.83-67.6-36.36-79.21 15.78 25.21 2.57 62.38 17.15 85.45s36.99 15.8 19.21-6.24"
            className="Kyrgyzstan_svg__cls-3"
            data-name="r1"
          />
          <path
            id="Kyrgyzstan_svg__r1-19"
            d="M769.42 328.87c22-17.78 67.61-9.83 79.22-36.35-25.22 15.77-62.38 2.56-85.46 17.14s-15.8 36.99 6.24 19.21"
            className="Kyrgyzstan_svg__cls-3"
            data-name="r1"
          />
          <path
            id="Kyrgyzstan_svg__r1-20"
            d="M673.32 486c17.78 22 9.83 67.61 36.36 79.22-15.78-25.22-2.57-62.38-17.15-85.46S655.54 464 673.32 486"
            className="Kyrgyzstan_svg__cls-3"
            data-name="r1"
          />
        </g>
      </g>
      <g id="Kyrgyzstan_svg__r4-6" data-name="r4">
        <path
          id="Kyrgyzstan_svg__r1-21"
          d="M612.5 486.08c5.84 27.71-21.93 64.7-3.56 87.09-2.61-29.63 26-56.75 23.52-83.93s-25.79-30.87-19.96-3.16"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-22"
          d="M516.11 329.11c-27.7 5.83-64.7-21.94-87.08-3.57 29.63-2.61 56.74 26 83.92 23.52s30.87-25.79 3.16-19.95"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-23"
          d="M673.09 232.72C667.25 205 695 168 676.65 145.63c2.61 29.63-26 56.75-23.52 83.93s25.79 30.86 19.96 3.16"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-24"
          d="M769.48 389.69c27.7-5.84 64.7 21.93 87.08 3.56-29.63 2.62-56.74-26-83.92-23.51s-30.87 25.79-3.16 19.95"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
      </g>
      <g id="Kyrgyzstan_svg__r4-7" data-name="r4">
        <path
          id="Kyrgyzstan_svg__r1-25"
          d="M574.84 470.52c-3 28.15-40.85 54.75-30.3 81.72 6.67-29 42.29-45.92 48.3-72.55s-14.99-37.33-18-9.17"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-26"
          d="M531.67 291.44c-28.15-3-54.75-40.85-81.72-30.3 29 6.67 45.93 42.29 72.55 48.3s37.33-14.99 9.17-18"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-27"
          d="M710.75 248.28c3-28.16 40.85-54.76 30.3-81.73-6.67 29-42.29 45.93-48.3 72.56s14.99 37.32 18 9.17"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-28"
          d="M753.92 427.35c28.15 3 54.75 40.86 81.72 30.31-29-6.68-45.93-42.3-72.55-48.31s-37.33 14.99-9.17 18"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
      </g>
      <g id="Kyrgyzstan_svg__r4-8" data-name="r4">
        <path
          id="Kyrgyzstan_svg__r1-29"
          d="M543.83 444.08c-11.56 25.85-55.78 39.45-54.08 68.36 15.31-25.51 54.42-30.61 68.36-54.08s-2.72-40.13-14.28-14.28"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-30"
          d="M558.11 260.43c-25.84-11.56-39.45-55.78-68.36-54.07 25.51 15.3 30.61 54.41 54.08 68.35S584 272 558.11 260.43"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-31"
          d="M741.76 274.71c11.57-25.84 55.78-39.45 54.08-68.35-15.31 25.5-54.42 30.6-68.36 54.07s2.72 40.13 14.28 14.28"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-32"
          d="M727.48 458.36c25.85 11.57 39.45 55.78 68.36 54.08-25.51-15.31-30.61-54.42-54.08-68.36s-40.13 2.72-14.28 14.28"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
      </g>
      <g id="Kyrgyzstan_svg__r4-9" data-name="r4">
        <path
          id="Kyrgyzstan_svg__r1-33"
          d="M522.5 409.35c-19 21-65.23 20.29-72.55 48.31 22.44-19.53 61.21-12.3 81.72-30.31s9.82-39.01-9.17-18"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-34"
          d="M592.84 239.11c-21-19-20.29-65.24-48.3-72.56 19.53 22.44 12.29 61.21 30.3 81.73s39.01 9.81 18-9.17"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-35"
          d="M763.09 309.44c19-21 65.23-20.28 72.55-48.3-22.44 19.53-61.21 12.29-81.72 30.3s-9.82 39.01 9.17 18"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
        <path
          id="Kyrgyzstan_svg__r1-36"
          d="M692.75 479.69c21 19 20.29 65.24 48.3 72.55-19.53-22.44-12.29-61.21-30.3-81.72s-39.01-9.82-18 9.17"
          className="Kyrgyzstan_svg__cls-3"
          data-name="r1"
        />
      </g>
      <g id="Kyrgyzstan_svg__r20-2" data-name="r20">
        <g id="Kyrgyzstan_svg__r4-10" data-name="r4">
          <path
            id="Kyrgyzstan_svg__r1-37"
            d="M513 369.74c-24.54 14.11-68.31-.87-83.92 23.51 27.37-11.64 62 7.23 87.08-3.56s21.34-34.07-3.16-19.95"
            className="Kyrgyzstan_svg__cls-3"
            data-name="r1"
          />
          <path
            id="Kyrgyzstan_svg__r1-38"
            d="M632.46 229.56c-14.12-24.55.86-68.32-23.52-83.93 11.64 27.37-7.22 62 3.56 87.09s34.07 21.38 19.96-3.16"
            className="Kyrgyzstan_svg__cls-3"
            data-name="r1"
          />
          <path
            id="Kyrgyzstan_svg__r1-39"
            d="M772.64 349.06c24.54-14.12 68.31.87 83.92-23.52-27.37 11.64-62-7.22-87.08 3.57s-21.39 34.06 3.16 19.95"
            className="Kyrgyzstan_svg__cls-3"
            data-name="r1"
          />
          <path
            id="Kyrgyzstan_svg__r1-40"
            d="M653.13 489.24c14.12 24.55-.86 68.31 23.52 83.93-11.64-27.38 7.22-62-3.56-87.09s-34.09-21.39-19.96 3.16"
            className="Kyrgyzstan_svg__cls-3"
            data-name="r1"
          />
        </g>
      </g>
      <circle
        cx={642.8}
        cy={359.4}
        r={129.86}
        className="Kyrgyzstan_svg__cls-2"
      />
      <circle
        cx={642.8}
        cy={352.18}
        r={108.22}
        className="Kyrgyzstan_svg__cls-3"
      />
      <g className="Kyrgyzstan_svg__cls-4">
        <g id="Kyrgyzstan_svg__x">
          <path
            d="M546 446a167.5 167.5 0 1 1 335 0h4.62a163.46 163.46 0 1 0-326.92 0Z"
            className="Kyrgyzstan_svg__cls-2"
          />
          <path
            d="M566.93 446a161 161 0 0 1 322 0h5.3A157.64 157.64 0 1 0 579 446Z"
            className="Kyrgyzstan_svg__cls-2"
          />
          <path
            d="M742.35 290.33a155.65 155.65 0 0 0-.06 311.29h.06L751 599a153 153 0 1 1 0-306.08Z"
            className="Kyrgyzstan_svg__cls-2"
          />
        </g>
      </g>
      <g className="Kyrgyzstan_svg__cls-4">
        <g id="Kyrgyzstan_svg__x-2" data-name="x">
          <path
            d="M739.59 446a167.5 167.5 0 1 0-335 0H400a163.46 163.46 0 0 1 326.92 0Z"
            className="Kyrgyzstan_svg__cls-2"
          />
          <path
            d="M718.66 446a161 161 0 0 0-322 0h-5.3a157.64 157.64 0 1 1 315.27 0Z"
            className="Kyrgyzstan_svg__cls-2"
          />
          <path
            d="M543.24 290.33a155.65 155.65 0 0 1 .06 311.29h-.06l-8.66-2.62a153 153 0 1 0 0-306.08Z"
            className="Kyrgyzstan_svg__cls-2"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgKyrgyzstan;
