import * as React from "react";
import type { SVGProps } from "react";
const SvgCameroon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Cameroon_svg__cls-3{fill:#fcd116}"}</style>
    </defs>
    <g id="Cameroon_svg__Cameroon">
      <path
        d="M0-.33h426.67v720.67H0z"
        style={{
          fill: "#007a5e",
        }}
      />
      <path
        d="M426.67-.33h426.67v720.67H426.67z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        d="M853.33-.33H1280v720.67H853.33z"
        className="Cameroon_svg__cls-3"
      />
      <g id="Cameroon_svg__c">
        <path
          id="Cameroon_svg__t"
          d="m639.5 263.91-29.69 91.39 45.69 14.84Z"
          className="Cameroon_svg__cls-3"
        />
        <path
          id="Cameroon_svg__t-2"
          d="m639.5 263.91 29.69 91.39-45.69 14.84Z"
          className="Cameroon_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Cameroon_svg__c-2" data-name="c">
        <path
          id="Cameroon_svg__t-3"
          d="M730.89 330.31H634.8v48Z"
          className="Cameroon_svg__cls-3"
          data-name="t"
        />
        <path
          id="Cameroon_svg__t-4"
          d="m730.89 330.31-77.74 56.48-28.24-38.87Z"
          className="Cameroon_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Cameroon_svg__c-3" data-name="c">
        <path
          id="Cameroon_svg__t-5"
          d="m696 437.74-29.69-91.39-45.7 14.85Z"
          className="Cameroon_svg__cls-3"
          data-name="t"
        />
        <path
          id="Cameroon_svg__t-6"
          d="m696 437.74-77.74-56.48 28.24-38.87Z"
          className="Cameroon_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Cameroon_svg__c-4" data-name="c">
        <path
          id="Cameroon_svg__t-7"
          d="m583 437.74 77.74-56.48-28.24-38.87Z"
          className="Cameroon_svg__cls-3"
          data-name="t"
        />
        <path
          id="Cameroon_svg__t-8"
          d="m583 437.74 29.69-91.39 45.7 14.85Z"
          className="Cameroon_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Cameroon_svg__c-5" data-name="c">
        <path
          id="Cameroon_svg__t-9"
          d="m548.11 330.31 77.74 56.48 28.24-38.87Z"
          className="Cameroon_svg__cls-3"
          data-name="t"
        />
        <path
          id="Cameroon_svg__t-10"
          d="M548.11 330.31h96.09v48Z"
          className="Cameroon_svg__cls-3"
          data-name="t"
        />
      </g>
    </g>
  </svg>
);
export default SvgCameroon;
