import * as React from "react";
import type { SVGProps } from "react";

const SvgCookIslands = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 480" {...props}>
    {/* Background */}
    <rect width="960" height="480" fill="#0033a0" />
    
    {/* Union Jack in the top left corner */}
    <rect width="240" height="160" fill="#00247d" />
    <rect width="240" height="40" y="40" fill="#fff" />
    <rect width="240" height="40" y="80" fill="#c8102e" />
    <rect width="40" height="160" fill="#fff" />
    <rect width="40" height="160" x="80" fill="#c8102e" />
    
    {/* White stars in a circle */}
    <circle cx="720" cy="120" r="80" fill="#0033a0" />
    <circle cx="720" cy="120" r="60" fill="#fff" />
    {Array.from({ length: 15 }).map((_, i) => {
      const angle = (i / 15) * 2 * Math.PI;
      const x = 720 + Math.cos(angle) * 60;
      const y = 120 - Math.sin(angle) * 60;
      return <circle key={i} cx={x} cy={y} r="10" fill="#0033a0" />;
    })}
  </svg>
);

export default SvgCookIslands;