import * as React from "react";
import type { SVGProps } from "react";
const SvgBrazil = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Brazil_svg__cls-4{fill:#fff}.Brazil_svg__cls-5,.Brazil_svg__cls-6{fill:#3c9242}.Brazil_svg__cls-5{fill-rule:evenodd}"
        }
      </style>
    </defs>
    <g id="Brazil_svg__Brazil">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#009b3a",
        }}
      />
      <path
        d="m212.3 359.97 426.83 272.6 426.88-272.6L639.13 87.44z"
        style={{
          fill: "#fedf00",
        }}
      />
      <path
        d="M819.15 360c0 99.38-80.64 180-180 180s-180-80.62-180-180 80.63-180 180-180 180 80.65 180 180"
        style={{
          fill: "#2d146e",
        }}
      />
      <path
        d="M536.31 282.85a444 444 0 0 0-61.83 4.35 185.5 185.5 0 0 0-9.59 27.53 417 417 0 0 1 71.42-6.17A410 410 0 0 1 811 413.7a180 180 0 0 0 6.4-28.46 435.3 435.3 0 0 0-281.09-102.39"
        className="Brazil_svg__cls-4"
      />
      <path
        d="M485.62 307.37c4.23-.52 7.2-4.72 6.62-9.45s-4.46-8.08-8.69-7.56-7.2 4.75-6.62 9.45 4.47 8.08 8.69 7.56m-.36-3.16c2.48-.32 4.19-2.95 3.82-5.88s-2.66-5.13-5.15-4.81-4.22 2.93-3.84 5.88 2.69 5.1 5.17 4.81M499.59 305.84l3.17-.24-.42-6.34 6.83-.5a5.39 5.39 0 1 0-.76-10.75l-9.76.7Zm2.52-9.77 6.61-.47a2.19 2.19 0 1 0-.32-4.37l-6.61.43Z"
        className="Brazil_svg__cls-5"
      />
      <path
        d="M514.12 304.82c-.16-2.46-.55-7.85-4.18-7.56l-4.64.32c5.35-.38 5.72 5 5.9 7.41"
        className="Brazil_svg__cls-6"
      />
      <path
        d="m521.33 304.47 8.08-.17a7.27 7.27 0 0 0 7.22-7.45l-.06-2.46a7.35 7.35 0 0 0-7.46-7.22l-8.09.15Zm3.12-3.21 4.66-.12a4.63 4.63 0 0 0 4.55-4.72v-1.48a4.62 4.62 0 0 0-4.74-4.55l-4.63.06Z"
        className="Brazil_svg__cls-5"
      />
      <path
        d="m543.09 304.24 15.43.55.1-3.19-12.47-.43.14-4.41 9.81.35.1-2.95-9.81-.32.12-3.42 11.78.4.07-3.21-14.68-.5zM564.86 305.2l2.92.23 1.01-11.71 2.38 12 2.71.26 4.44-11.42-1.03 11.71 2.93.26 1.46-17.07-4.25-.4-4.45 11.44-2.37-12.02-4.26-.38zM600.36 309.17l12.67 2.17.51-2.87-9.79-1.68.66-3.88 7.97 1.39.5-2.93-7.96-1.33.45-2.67 9.45 1.65.53-2.92-12.38-2.15z"
        className="Brazil_svg__cls-6"
      />
      <path
        d="m632.9 315.57 3.09.79 1.59-6.15 6.64 1.71a5.39 5.39 0 1 0 2.71-10.43l-9.48-2.49Zm5.49-8.46 6.41 1.68a2.21 2.21 0 0 0 1.08-4.29l-6.39-1.65ZM654 321.17l3 1 1.91-6.09 6.55 2.06a5.38 5.38 0 1 0 3.26-10.26l-9.37-2.95Zm5.89-8.12 6.29 2a2.21 2.21 0 1 0 1.35-4.2l-6.3-2Z"
        className="Brazil_svg__cls-5"
      />
      <path
        d="M667.84 325.57c.75-2.32 2.36-7.45-1.13-8.58l-4.45-1.39c5.14 1.63 3.52 6.75 2.77 9.07"
        className="Brazil_svg__cls-6"
      />
      <path
        d="M681.88 330.58c4 1.51 8.58-.89 10.23-5.3s-.25-9.24-4.23-10.75-8.57.9-10.22 5.33.22 9.25 4.22 10.72m1.11-3c2.34.9 5.09-.67 6.13-3.45s0-5.74-2.34-6.64-5.1.67-6.13 3.48 0 5.77 2.34 6.61M702.06 338.76c3.9 1.71 8.63-.47 10.49-4.81s.27-9.22-3.66-10.93-8.61.44-10.49 4.78-.25 9.25 3.66 11m1.28-2.93c2.27 1 5.1-.41 6.29-3.13s.3-5.77-2-6.75-5.12.4-6.32 3.13-.28 5.74 2 6.75"
        className="Brazil_svg__cls-5"
      />
      <path
        d="m706.6 331.37 5.95 2.58-.96 2.26-5.98-2.61z"
        className="Brazil_svg__cls-6"
      />
      <path
        d="m710.31 332.99 2.24.96-3.42 7.88-2.24-.99z"
        className="Brazil_svg__cls-6"
      />
      <path
        d="m714.86 344.49 2.87 1.42 2.84-5.68 6.13 3a5.36 5.36 0 0 0 7.24-2.41 5.42 5.42 0 0 0-2.43-7.24l-8.75-4.35Zm7.13-7.1 5.93 2.93a2.18 2.18 0 0 0 3-1 2.22 2.22 0 0 0-1-3l-5.92-3Z"
        className="Brazil_svg__cls-5"
      />
      <path
        d="M727.92 351c1.08-2.18 3.49-7 .21-8.64l-4.13-2.1c4.81 2.41 2.41 7.25 1.32 9.42M734.29 354.38l13.42 7.59 1.56-2.78-10.86-6.17 2.17-3.8 8.52 4.81 1.46-2.55-8.53-4.81 1.68-3.02 10.26 5.8 1.55-2.78-12.8-7.22zM759.3 362.75c-.93 1.45 0 3.07 2.07 4.38s3.48 1.25 4.08.35c1.88-3-9-7.25-5.63-12.38 2.87-4.4 7.8-1 9.67.21s4.84 4.26 2.52 7.76l-3.15-2c1-1.53-.1-3-1.76-4.05s-2.9-1.51-3.84 0c-1.61 2.4 9 6.89 5.75 12-2.36 3.77-6.56 2.12-9.35.35-2.39-1.54-5.92-5.1-3.62-8.69ZM777.7 374.9c-1 1.42-.16 3 1.83 4.49s3.41 1.45 4 .55c2-2.81-8.58-7.68-5-12.64 3.1-4.26 7.84-.58 9.65.7s4.61 4.49 2.1 7.91l-3-2.17c1-1.48.07-3-1.55-4.18s-2.8-1.62-3.84-.17c-1.72 2.29 8.66 7.36 5.12 12.29-2.59 3.65-6.69 1.79-9.36-.12-2.29-1.65-5.65-5.39-3.16-8.89Z"
        className="Brazil_svg__cls-6"
      />
      <path
        d="M795.09 394.63c3.32 2.67 8.41 1.8 11.36-1.88s2.63-8.84-.69-11.51-8.44-1.82-11.39 1.89-2.63 8.86.72 11.5m2-2.49c1.95 1.56 5 1 6.88-1.39s1.77-5.48-.2-7-5-.93-6.89 1.39-1.76 5.47.21 7"
        className="Brazil_svg__cls-5"
      />
      <path
        d="m492.23 319.95-2.41 7.33 3.66 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m492.23 319.95 2.37 7.33-3.66 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path d="M499.55 325.28h-7.73v3.83z" className="Brazil_svg__cls-4" />
      <path
        d="m499.55 325.28-6.24 4.52-2.29-3.1z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m484.88 325.28 6.23 4.52 2.26-3.1z"
        className="Brazil_svg__cls-4"
      />
      <path d="M484.88 325.28h7.73v3.83z" className="Brazil_svg__cls-4" />
      <path
        d="m496.74 333.92-2.38-7.36-3.67 1.21z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m496.74 333.92-6.22-4.55 2.24-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m487.69 333.92 6.25-4.55-2.29-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m487.69 333.92 2.37-7.36 3.68 1.21zM508.13 395.62l-2.38 7.33 3.66 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m508.13 395.62 2.37 7.33-3.64 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path d="M515.46 400.95h-7.7v3.86z" className="Brazil_svg__cls-4" />
      <path
        d="m515.46 400.95-6.25 4.52-2.25-3.1z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m500.78 400.95 6.24 4.52 2.29-3.1z"
        className="Brazil_svg__cls-4"
      />
      <path d="M500.78 400.95h7.73v3.86z" className="Brazil_svg__cls-4" />
      <path
        d="m512.66 409.56-2.38-7.31-3.68 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m512.66 409.56-6.23-4.52 2.26-3.1z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m503.6 409.56 6.23-4.52-2.26-3.1z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m503.6 409.56 2.39-7.31 3.69 1.19zM486.1 414.34l-1.59 4.9 2.43.78z"
        className="Brazil_svg__cls-4"
      />
      <path d="m486.1 414.34 1.59 4.9-2.46.78z" className="Brazil_svg__cls-4" />
      <path d="M490.97 417.9h-5.13v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m490.97 417.9-4.16 3.02-1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m481.2 417.9 4.15 3.02 1.5-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path d="M481.2 417.9h5.13v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m489.13 423.64-1.6-4.87-2.45.79z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m489.13 423.64-4.19-3.01 1.54-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m483.07 423.64 4.16-3.01-1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m483.07 423.64 1.59-4.87 2.42.79zM525.76 388.63l-1.12 3.48 1.74.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m525.76 388.63 1.14 3.48-1.74.58z"
        className="Brazil_svg__cls-4"
      />
      <path d="M529.27 391.18h-3.68v1.83z" className="Brazil_svg__cls-4" />
      <path
        d="m529.27 391.18-2.99 2.18-1.07-1.51z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m522.27 391.18 2.95 2.18 1.1-1.51z"
        className="Brazil_svg__cls-4"
      />
      <path d="M522.27 391.18h3.65v1.83z" className="Brazil_svg__cls-4" />
      <path
        d="m527.92 395.27-1.15-3.51-1.72.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m527.92 395.27-2.97-2.14 1.07-1.48z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m523.6 395.27 2.97-2.14-1.09-1.48z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m523.6 395.27 1.15-3.51 1.73.58zM545.6 414.77l-1.97 6.12 3.04 1.01z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m545.6 414.77 1.98 6.12-3.04 1.01z"
        className="Brazil_svg__cls-4"
      />
      <path d="M551.7 419.21h-6.42v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m551.7 419.21-5.19 3.79-1.87-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m539.47 419.21 5.21 3.79 1.89-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path d="M539.47 419.21h6.44v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m549.38 426.4-1.99-6.09-3.05.98z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m549.38 426.4-5.19-3.77 1.89-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m541.83 426.4 5.19-3.77-1.89-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m541.83 426.4 2-6.09 3.04.98zM540.22 432.65l-1.98 6.12 3.04 1.01z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m540.22 432.65 1.97 6.12-3.06 1.01z"
        className="Brazil_svg__cls-4"
      />
      <path d="M546.34 437.09h-6.45v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m546.34 437.09-5.22 3.79-1.88-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m534.09 437.09 5.22 3.79 1.87-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path d="M534.09 437.09h6.44v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m543.99 444.31-1.97-6.15-3.06.99z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m543.99 444.31-5.22-3.8 1.9-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m536.42 444.31 5.22-3.8-1.9-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m536.42 444.31 2.02-6.15 3.04.99zM695.01 296.42l-2.41 7.36 3.67 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m695.01 296.42 2.37 7.36-3.66 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path d="M702.31 301.75h-7.69v3.88z" className="Brazil_svg__cls-4" />
      <path
        d="m702.31 301.75-6.23 4.55-2.26-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m687.65 301.75 6.23 4.55 2.29-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path d="M687.65 301.75h7.72v3.88z" className="Brazil_svg__cls-4" />
      <path
        d="m699.53 310.38-2.39-7.33-3.68 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m699.53 310.38-6.25-4.52 2.26-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m690.46 310.38 6.23-4.52-2.26-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m690.46 310.38 2.39-7.33 3.66 1.19zM765.27 415.47l-2.39 7.33 3.68 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m765.27 415.47 2.39 7.33-3.68 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path d="M772.6 420.77h-7.71v3.86z" className="Brazil_svg__cls-4" />
      <path
        d="m772.6 420.77-6.24 4.55-2.26-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m757.92 420.77 6.25 4.55 2.27-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path d="M757.92 420.77h7.73v3.86z" className="Brazil_svg__cls-4" />
      <path
        d="m769.81 429.41-2.39-7.33-3.66 1.16z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m769.81 429.41-6.25-4.55 2.29-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m760.74 429.41 6.24-4.55-2.29-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m760.74 429.41 2.36-7.33 3.68 1.16zM790.25 419.73l-1.59 4.9 2.45.78z"
        className="Brazil_svg__cls-4"
      />
      <path d="m790.25 419.73 1.6 4.9-2.45.78z" className="Brazil_svg__cls-4" />
      <path d="M795.13 423.29h-5.14v2.56z" className="Brazil_svg__cls-4" />
      <path
        d="m795.13 423.29-4.14 3.02-1.5-2.06z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m785.36 423.29 4.18 3.02 1.51-2.06z"
        className="Brazil_svg__cls-4"
      />
      <path d="M785.36 423.29h5.14v2.56z" className="Brazil_svg__cls-4" />
      <path
        d="m793.28 429.03-1.59-4.9-2.45.82z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m793.28 429.03-4.17-3.01 1.5-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m787.22 429.03 4.16-3.01-1.5-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m787.22 429.03 1.58-4.9 2.47.82zM772.6 432.65l-1.98 6.12 3.07 1.01z"
        className="Brazil_svg__cls-4"
      />
      <path d="m772.6 432.65 2 6.12-3.04 1.01z" className="Brazil_svg__cls-4" />
      <path d="M778.73 437.09h-6.42v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m778.73 437.09-5.2 3.79-1.88-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m766.5 437.09 5.19 3.79 1.91-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path d="M766.5 437.09h6.44v3.22z" className="Brazil_svg__cls-4" />
      <path d="m776.41 444.31-2-6.15-3.05.99z" className="Brazil_svg__cls-4" />
      <path
        d="m776.41 444.31-5.21-3.8 1.88-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m768.85 444.31 5.19-3.8-1.9-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m768.85 444.31 1.97-6.15 3.06.99zM729.28 470.36l-1.99 6.11 3.04 1.02z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m729.28 470.36 2 6.11-3.07 1.02z"
        className="Brazil_svg__cls-4"
      />
      <path d="M735.39 474.82h-6.43v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m735.39 474.82-5.18 3.77-1.92-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m723.16 474.82 5.2 3.77 1.87-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path d="M723.16 474.82h6.42v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m733.04 481.98-1.97-6.09-3.05.99z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m733.04 481.98-5.21-3.77 1.91-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m725.48 481.98 5.22-3.77-1.89-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m725.48 481.98 2.01-6.09 3.05.99zM729.03 489.78l-1.58 4.89 2.44.79z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m729.03 489.78 1.58 4.89-2.45.79z"
        className="Brazil_svg__cls-4"
      />
      <path d="M733.91 493.34h-5.13v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m733.91 493.34-4.17 3.02-1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m724.15 493.34 4.14 3.02 1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path d="M724.15 493.34h5.13v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m732.04 499.08-1.56-4.87-2.46.78z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m732.04 499.08-4.15-3.02 1.52-2.08z"
        className="Brazil_svg__cls-4"
      />
      <path d="m726 499.08 4.17-3.02-1.5-2.08z" className="Brazil_svg__cls-4" />
      <path
        d="m726 499.08 1.6-4.87 2.46.78zM747.14 457.46l-1.59 4.9 2.45.78z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m747.14 457.46 1.58 4.9-2.43.78z"
        className="Brazil_svg__cls-4"
      />
      <path d="M752.04 461h-5.16v2.58z" className="Brazil_svg__cls-4" />
      <path d="m752.04 461-4.16 3.04-1.5-2.09z" className="Brazil_svg__cls-4" />
      <path
        d="m742.26 461 4.17 3.04 1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path d="M742.26 461h5.14v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m750.17 466.77-1.59-4.9-2.42.81z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m750.17 466.77-4.16-3.05 1.54-2.06z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m744.14 466.77 4.15-3.05-1.52-2.06z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m744.14 466.77 1.6-4.9 2.42.81zM761.59 447.12l-1.97 6.11 3.04.99z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m761.59 447.12 1.99 6.11-3.05.99z"
        className="Brazil_svg__cls-4"
      />
      <path d="M767.69 451.55h-6.42v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m767.69 451.55-5.17 3.77-1.89-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m755.48 451.55 5.2 3.77 1.88-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path d="M755.48 451.55h6.44v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m765.37 458.71-1.98-6.09-3.06.99z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m765.37 458.71-5.17-3.77 1.87-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m757.82 458.71 5.2-3.77-1.89-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m757.82 458.71 2-6.09 3.05.99zM728.52 454.04l-1.56 4.87 2.45.81z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m728.52 454.04 1.6 4.87-2.42.81z"
        className="Brazil_svg__cls-4"
      />
      <path d="M733.42 457.58h-5.13v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m733.42 457.58-4.14 3.01-1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m723.65 457.58 4.16 3.01 1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path d="M723.65 457.58h5.13v2.58z" className="Brazil_svg__cls-4" />
      <path d="m731.57 463.35-1.6-4.9-2.46.75z" className="Brazil_svg__cls-4" />
      <path
        d="m731.57 463.35-4.18-3.05 1.51-2.05z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m725.51 463.35 4.17-3.05-1.52-2.05z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m725.51 463.35 1.58-4.9 2.48.75zM570.58 360.9l-1.99 6.11 3.06.99z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m570.58 360.9 1.98 6.11-3.05.99z"
        className="Brazil_svg__cls-4"
      />
      <path d="M576.69 365.33h-6.43v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m576.69 365.33-5.2 3.8-1.88-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m564.48 365.33 5.18 3.8 1.87-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path d="M564.48 365.33h6.42v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m574.35 372.52-1.98-6.08-3.07.98z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m574.35 372.52-5.18-3.77 1.89-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m566.8 372.52 5.2-3.77-1.9-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m566.8 372.52 1.99-6.08 3.06.98zM688.12 345.8l-1.59 4.87 2.46.81z"
        className="Brazil_svg__cls-4"
      />
      <path d="m688.12 345.8 1.6 4.87-2.44.81z" className="Brazil_svg__cls-4" />
      <path d="M693.02 349.33h-5.14v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m693.02 349.33-4.14 3.05-1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m683.25 349.33 4.16 3.05 1.5-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path d="M683.25 349.33h5.13v2.58z" className="Brazil_svg__cls-4" />
      <path d="m691.15 355.1-1.59-4.9-2.45.79z" className="Brazil_svg__cls-4" />
      <path
        d="m691.15 355.1-4.16-3.04 1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m685.1 355.1 4.18-3.04-1.53-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m685.1 355.1 1.59-4.9 2.45.79zM639.13 433.06l-2.37 7.36 3.66 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m639.13 433.06 2.41 7.36-3.67 1.19z"
        className="Brazil_svg__cls-4"
      />
      <path d="M646.49 438.39h-7.72v3.89z" className="Brazil_svg__cls-4" />
      <path
        d="m646.49 438.39-6.23 4.55-2.29-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m631.83 438.39 6.23 4.55 2.26-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path d="M631.83 438.39h7.72v3.89z" className="Brazil_svg__cls-4" />
      <path
        d="m643.68 447.03-2.38-7.33-3.67 1.18z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m643.68 447.03-6.23-4.52 2.26-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m634.61 447.03 6.23-4.52-2.24-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m634.61 447.03 2.39-7.33 3.68 1.18zM659.98 398.63l-1.99 6.09 3.07 1.01z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m659.98 398.63 1.99 6.09-3.09 1.01z"
        className="Brazil_svg__cls-4"
      />
      <path d="M666.09 403.07h-6.43v3.18z" className="Brazil_svg__cls-4" />
      <path
        d="m666.09 403.07-5.22 3.76-1.88-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m653.85 403.07 5.21 3.76 1.9-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path d="M653.85 403.07h6.42v3.18z" className="Brazil_svg__cls-4" />
      <path
        d="m663.74 410.25-1.97-6.11-3.06.98z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m663.74 410.25-5.19-3.79 1.87-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m656.17 410.25 5.22-3.79-1.88-2.61z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m656.17 410.25 2.02-6.11 3.04.98zM639.13 382.46l-1.97 6.11 3.06.99z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m639.13 382.46 2.02 6.11-3.07.99z"
        className="Brazil_svg__cls-4"
      />
      <path d="M645.26 386.89h-6.43v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m645.26 386.89-5.2 3.77-1.88-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m633.05 386.89 5.2 3.77 1.88-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path d="M633.05 386.89h6.42v3.22z" className="Brazil_svg__cls-4" />
      <path
        d="m642.93 394.08-1.99-6.11-3.04.98z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m642.93 394.08-5.2-3.77 1.88-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m635.37 394.08 5.21-3.77-1.88-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m635.37 394.08 1.99-6.11 3.06.98zM621.03 399.91l-1.59 4.9 2.43.78z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m621.03 399.91 1.58 4.9-2.45.78z"
        className="Brazil_svg__cls-4"
      />
      <path d="M625.92 403.44h-5.13v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m625.92 403.44-4.18 3.05-1.5-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m616.14 403.44 4.15 3.05 1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path d="M616.14 403.44h5.14v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m624.05 409.21-1.57-4.9-2.46.78z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m624.05 409.21-4.16-3.02 1.52-2.08z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m618 409.21 4.18-3.02-1.51-2.08z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m618 409.21 1.6-4.9 2.45.78zM630.08 413.85l-1.13 3.5 1.76.55z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m630.08 413.85 1.15 3.5-1.75.55z"
        className="Brazil_svg__cls-4"
      />
      <path d="M633.58 416.4h-3.66v1.82z" className="Brazil_svg__cls-4" />
      <path
        d="m633.58 416.4-2.98 2.17-1.06-1.48z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m626.6 416.4 2.97 2.17 1.09-1.48z"
        className="Brazil_svg__cls-4"
      />
      <path d="M626.6 416.4h3.66v1.82z" className="Brazil_svg__cls-4" />
      <path
        d="m632.25 420.51-1.16-3.5-1.72.57z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m632.25 420.51-2.99-2.17 1.11-1.48z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m627.93 420.51 2.98-2.17-1.1-1.48z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m627.93 420.51 1.13-3.5 1.74.57zM693.04 474.76l-2 6.15 3.07.98z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m693.04 474.76 1.98 6.15-3.07.98z"
        className="Brazil_svg__cls-4"
      />
      <path d="M699.15 479.2h-6.43v3.24z" className="Brazil_svg__cls-4" />
      <path
        d="m699.15 479.2-5.22 3.79-1.88-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m686.91 479.2 5.21 3.79 1.89-2.6z"
        className="Brazil_svg__cls-4"
      />
      <path d="M686.91 479.2h6.43v3.24z" className="Brazil_svg__cls-4" />
      <path
        d="m696.82 486.42-1.99-6.12-3.07.99z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m696.82 486.42-5.22-3.8 1.9-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m689.24 486.42 5.22-3.8-1.9-2.58z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m689.24 486.42 1.99-6.12 3.04.99zM708.44 460.16l-1.58 4.87 2.45.81z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m708.44 460.16 1.61 4.87-2.45.81z"
        className="Brazil_svg__cls-4"
      />
      <path d="M713.35 463.72h-5.16v2.52z" className="Brazil_svg__cls-4" />
      <path
        d="m713.35 463.72-4.16 2.99-1.5-2.06z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m703.56 463.72 4.16 2.99 1.52-2.06z"
        className="Brazil_svg__cls-4"
      />
      <path d="M703.56 463.72h5.16v2.52z" className="Brazil_svg__cls-4" />
      <path
        d="m711.48 469.43-1.59-4.9-2.45.82z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m711.48 469.43-4.16-2.98 1.51-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m705.46 469.43 4.12-2.98-1.5-2.09z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m705.46 469.43 1.56-4.9 2.43.82zM678.84 455.75l-1.6 4.87 2.44.81z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m678.84 455.75 1.57 4.87-2.44.81z"
        className="Brazil_svg__cls-4"
      />
      <path d="M683.71 459.29h-5.13v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m683.71 459.29-4.16 3.01-1.51-2.08z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m673.94 459.29 4.14 3.01 1.51-2.08z"
        className="Brazil_svg__cls-4"
      />
      <path d="M673.94 459.29h5.13v2.58z" className="Brazil_svg__cls-4" />
      <path
        d="m681.86 465.06-1.59-4.9-2.46.81z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m681.86 465.06-4.18-3.05 1.52-2.06z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m675.79 465.06 4.18-3.05-1.51-2.06z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m675.79 465.06 1.6-4.9 2.46.81zM566.9 447.78l-2.37 7.31 3.67 1.24z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m566.9 447.78 2.39 7.31-3.67 1.24z"
        className="Brazil_svg__cls-4"
      />
      <path d="M574.26 453.12h-7.73v3.85z" className="Brazil_svg__cls-4" />
      <path
        d="m574.26 453.12-6.26 4.52-2.25-3.1z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m559.57 453.12 6.26 4.52 2.24-3.1z"
        className="Brazil_svg__cls-4"
      />
      <path d="M559.57 453.12h7.7v3.85z" className="Brazil_svg__cls-4" />
      <path
        d="m571.45 461.72-2.38-7.33-3.68 1.22z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m571.45 461.72-6.26-4.52 2.27-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m562.36 461.72 6.25-4.52-2.26-3.13z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m562.36 461.72 2.39-7.33 3.68 1.22zM639.13 498.21l-.8 2.46 1.25.41z"
        className="Brazil_svg__cls-4"
      />
      <path d="m639.13 498.21.83 2.46-1.25.41z" className="Brazil_svg__cls-4" />
      <path d="M641.6 500.01h-2.58v1.27z" className="Brazil_svg__cls-4" />
      <path
        d="m641.6 500.01-2.09 1.5-.74-1.04z"
        className="Brazil_svg__cls-4"
      />
      <path d="m636.7 500.01 2.1 1.5.75-1.04z" className="Brazil_svg__cls-4" />
      <path d="M636.7 500.01h2.59v1.27z" className="Brazil_svg__cls-4" />
      <path
        d="m640.64 502.88-.77-2.44-1.22.41z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m640.64 502.88-2.04-1.51.73-1.05z"
        className="Brazil_svg__cls-4"
      />
      <path
        d="m637.65 502.88 2.06-1.51-.74-1.05z"
        className="Brazil_svg__cls-4"
      />
      <path d="m637.65 502.88.8-2.44 1.22.41z" className="Brazil_svg__cls-4" />
    </g>
  </svg>
);
export default SvgBrazil;
