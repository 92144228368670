import * as React from "react";
import type { SVGProps } from "react";

const SvgFrenchPolynesia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 800" {...props}>
    {/* Background */}
    <rect width="1200" height="800" fill="#fff" />
    
    {/* Red Stripes */}
    <rect width="1200" height="160" y="0" fill="#c8102e" />
    <rect width="1200" height="160" y="320" fill="#c8102e" />
    <rect width="1200" height="160" y="640" fill="#c8102e" />
    
    {/* White Stripes */}
    <rect width="1200" height="160" y="160" fill="#fff" />
    <rect width="1200" height="160" y="480" fill="#fff" />
    
    {/* Polynesian Motif */}
    <circle cx="600" cy="400" r="160" fill="#ffcc00" />
    <path d="M600,240 l40,120 l130,0 l-105,75 l40,130 l-105,-75 l-105,75 l40,-130 l-105,-75 l130,0 z" fill="#d52b1e" />
    <path d="M600,240 l40,120 l-105,75 l40,-130 l105,-75 l-130,0 l40,130 l105,-75 l-40,130 z" fill="#d52b1e" />
  </svg>
);

export default SvgFrenchPolynesia;