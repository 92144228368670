import * as React from "react";
import type { SVGProps } from "react";

const SvgAnguilla = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 480" {...props}>
    {/* Background of the flag */}
    <rect width="960" height="480" fill="#00247d" />
    
    {/* Union Jack in the top-left corner */}
    <polygon points="0,0 400,0 400,200 0,200" fill="#012169" />
    <polygon points="0,0 200,100 0,200" fill="#FFF" />
    <polygon points="0,0 200,100 0,120" fill="#C8102E" />
    <polygon points="0,200 200,100 400,200" fill="#FFF" />
    <polygon points="0,200 200,100 400,180" fill="#C8102E" />
    <polygon points="0,0 150,0 400,150 400,180" fill="#FFF" />
    <polygon points="0,0 150,0 400,140 400,160" fill="#C8102E" />
    <polygon points="0,0 0,50 180,200 150,200" fill="#FFF" />
    <polygon points="0,0 0,50 160,200 140,200" fill="#C8102E" />
    
    {/* Anguilla's coat of arms */}
    <rect x="630" y="160" width="200" height="240" fill="#FFF" rx="10" />
    <path
      d="M730 310c0 40-60 40-60 0s60-40 60 0zM700 270c0 40 60 40 60 0s-60-40-60 0zM700 350c0 40 60 40 60 0s-60-40-60 0z"
      fill="#FF8C00"
    />
    <path
      d="M690 310c-20-10-10-50 20-30M690 270c-10-20 20-50 30-20M690 350c-20-10 20-50 40-20"
      fill="#0082A4"
    />
  </svg>
);

export default SvgAnguilla;