import * as React from "react";
import type { SVGProps } from "react";
const SvgAngola = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Angola_svg__cls-2{fill:#f9d616}"}</style>
    </defs>
    <g id="Angola_svg__Angola">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path d="M0 360h1280v360H0z" />
      <path
        d="M578.38 275.17h47.08L640 230.4l14.54 44.77h47.08l-38.08 27.67 14.54 44.78L640 320l-38.08 27.67 14.54-44.78ZM826.3 341.65a187.5 187.5 0 0 1 0 36.7l-35.83-3.53a151.3 151.3 0 0 0 0-29.64zm-7.16 72.69a187.3 187.3 0 0 1-14 33.91l-31.75-17a151.6 151.6 0 0 0 11.34-27.39l34.45 10.45m-34.43 64.42a187 187 0 0 1-26 25.95l-22.84-27.83a150.4 150.4 0 0 0 21-21l27.83 22.84m-56.49 46.41a187.3 187.3 0 0 1-33.91 14l-10.45-34.45a151 151 0 0 0 27.39-11.34l17 31.75m-69.9 21.2a187.5 187.5 0 0 1-36.7 0l3.53-35.83a151.3 151.3 0 0 0 29.64 0zm-72.69-7.16a187.3 187.3 0 0 1-33.91-14l17-31.75a151 151 0 0 0 27.39 11.34l-10.45 34.45m108.62-358.28a187.3 187.3 0 0 1 33.91 14l-17 31.75a152.3 152.3 0 0 0-27.39-11.34l10.45-34.45m64.42 34.43a187 187 0 0 1 26 26l-27.83 22.84a150.4 150.4 0 0 0-21-21l22.84-27.83m46.34 56.47a187 187 0 0 1 14 33.9l-34.45 10.45a151.2 151.2 0 0 0-11.34-27.38ZM773.41 539.68l17.5-20.52c9.21 8.91 21.61 17.7 30.61 25.68s15.89 14.62 21.47 23.69c8.18 13.3-1 28.35-13.74 19.51-6.88-4.77-11.21-17.67-21.76-28.35-7.81-6.65-14-13.62-33.6-17-.95-.15-1.22-2.14-.48-3.01"
        className="Angola_svg__cls-2"
      />
      <path
        id="Angola_svg__path13009"
        d="M799.37 533.51a2.39 2.39 0 0 1-1.2 2.1 2.42 2.42 0 1 1-2.42-4.19 2.41 2.41 0 0 1 3.62 2.09"
      />
      <path
        d="m658 189.07-3.42 32c70.56 7.4 125.82 66.46 125.82 139A140.45 140.45 0 0 1 640 500.47c-33.79 0-64.32-12.48-88.56-32.4l-20.52 25c29.82 24.49 67.51 39.78 109.08 39.78 95.39 0 172.8-77.41 172.8-172.8 0-89.19-68-161.83-154.8-170.98"
        className="Angola_svg__cls-2"
      />
      <path d="m655.27 474.83 48.59 28.73 10.56 41 55.78-4.23 24.51-28.73-90.85-63Z" />
      <path
        d="M771.19 536.85c-62.48-41.55-102.22-64-162.1-98-23.52-13.37-60.05-47-31.24-103.24 1.82 36.93 147.77 138.29 209.44 182.37Z"
        className="Angola_svg__cls-2"
      />
      <path
        id="Angola_svg__path11763"
        d="M618.61 402.05c48.11 45.33 108.69 82.32 162.74 123.2"
        style={{
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeWidth: "1.5px",
        }}
      />
      <path
        id="Angola_svg__path13009-2"
        d="M817.18 549a2.44 2.44 0 0 1-1.21 2.1 2.41 2.41 0 0 1-2.41 0 2.43 2.43 0 0 1 0-4.2 2.41 2.41 0 0 1 2.41 0 2.44 2.44 0 0 1 1.21 2.1"
        data-name="path13009"
      />
      <path
        id="Angola_svg__path13009-3"
        d="M832.83 564.93a2.42 2.42 0 1 1-1.21-2.1 2.44 2.44 0 0 1 1.21 2.1"
        data-name="path13009"
      />
    </g>
  </svg>
);
export default SvgAngola;
