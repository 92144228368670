import * as React from "react";
import type { SVGProps } from "react";
const SvgMongolia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Mongolia_svg__cls-1{fill:#c4272f}.Mongolia_svg__cls-3{fill:#f9cf02}"}
      </style>
    </defs>
    <g id="Mongolia_svg__Mongolia">
      <path d="M0 0h1280v720H0z" className="Mongolia_svg__cls-1" />
      <path
        d="M426.67 0h426.67v720H426.67z"
        style={{
          fill: "#015197",
        }}
      />
      <circle
        cx={213.33}
        cy={258.67}
        r={58.67}
        className="Mongolia_svg__cls-3"
      />
      <circle cx={213.33} cy={232} r={64} className="Mongolia_svg__cls-1" />
      <circle
        cx={213.33}
        cy={242.67}
        r={42.67}
        className="Mongolia_svg__cls-3"
      />
      <path
        d="M186.67 162.67a26.67 26.67 0 0 0 53.33 0c0-10.67-6.67-12-6.67-16s4-9.34-4-16c4 6.66-2.66 8-2.66 14.66s2.66 6.67 2.66 12a5.33 5.33 0 1 1-10.66 0c0-5.33 5.33-10.66 5.33-18.66s-1.33-10.67-5.33-17.34-10.67-12-5.34-17.33c-8 2.67-4 13.33-4 20S204 134.67 204 142.67s4 9.33 4 14.66a5.34 5.34 0 1 1-10.67 0c0-5.33 2.67-5.33 2.67-12s-6.67-8-2.67-14.66c-8 6.66-4 12-4 16s-6.66 5.33-6.66 16M96 328h53.33v256H96zM277.33 328h53.33v256h-53.33zM160 370.67h106.67V392H160zM160 520h106.67v21.33H160zM160 328h106.67l-53.34 32Zm0 224h106.67l-53.34 32Z"
        className="Mongolia_svg__cls-3"
      />
      <circle
        cx={213.33}
        cy={456}
        r={56.53}
        style={{
          fill: "#f9cf02",
          stroke: "#c4272f",
          strokeWidth: 24,
        }}
      />
      <path
        d="M213.33 399.47a28.27 28.27 0 1 1 0 56.53 28.27 28.27 0 0 0 0 56.53"
        style={{
          stroke: "#c4272f",
          strokeWidth: 24,
          fill: "none",
        }}
      />
      <circle
        cx={213.33}
        cy={427.73}
        r={10.67}
        className="Mongolia_svg__cls-1"
      />
      <circle
        cx={213.33}
        cy={484.27}
        r={10.67}
        className="Mongolia_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgMongolia;
