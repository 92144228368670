import * as React from "react";
import type { SVGProps } from "react";
const SvgSwitzerland = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Switzerland_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Switzerland_svg__Switzerland">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#d32d27",
        }}
      />
      <path d="M410 292.5h450v135H410z" className="Switzerland_svg__cls-2" />
      <path d="M567.5 135h135v450h-135z" className="Switzerland_svg__cls-2" />
    </g>
  </svg>
);
export default SvgSwitzerland;
