import * as React from "react";
import type { SVGProps } from "react";
const SvgParcelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.6759 18.576L16.1598 17.0921L13.4245 7.69707L17.2544 3.86716C17.2544 3.86716 19.4802 1.64133 18.4197 0.580811C17.3584 -0.480468 15.1326 1.74536 15.1326 1.74536L11.3027 5.57527L1.90836 2.84066L0.424475 4.32455L8.33488 8.54305L5.67353 11.2044L2.48655 11.3576L1.00266 12.8415L4.7679 14.2311L6.1582 17.9971L7.64209 16.5132L7.79533 13.3262L10.4567 10.6648L14.6759 18.576Z" fill="#38BA9C"/>
  </svg>
)
export default SvgParcelIcon;




