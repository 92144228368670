import * as React from "react";
import type { SVGProps } from "react";
const SvgBelgium = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path d="M0 .75h1280v718.5H0z" />
    <path
      d="M426.67.75h426.67v718.5H426.67z"
      style={{
        fill: "#f9df43",
      }}
    />
    <path
      d="M853.33.75H1280v718.5H853.33z"
      style={{
        fill: "#ec2b3a",
      }}
    />
  </svg>
);
export default SvgBelgium;
