import * as React from "react";
import type { SVGProps } from "react";

const SvgMayotte = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20" {...props}>
    {/* Background Color */}
    <rect width="30" height="20" fill="#fff" />

    {/* Blue Stripes */}
    <rect x="0" y="0" width="30" height="2" fill="#005b77" />
    <rect x="0" y="18" width="30" height="2" fill="#005b77" />
    
    {/* Green Star */}
    <path d="M15 6l1.09 3.36h3.53l-2.86 2.08 1.09 3.36-2.86-2.08-2.86 2.08 1.09-3.36-2.86-2.08h3.53L15 6z" fill="#009f49" transform="translate(-3 -1)" />
    
    {/* Red Text */}
    <text x="50%" y="14" fontSize="3" textAnchor="middle" fill="#d52b1e" fontFamily="Arial" fontWeight="bold">
      Mayotte
    </text>
  </svg>
);

export default SvgMayotte;