import * as React from "react";
import type { SVGProps } from "react";
const SvgMalaysia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Malaysia_svg__cls-2{fill:#fff}.Malaysia_svg__cls-4{fill:#fc0}"}
      </style>
    </defs>
    <g id="Malaysia_svg__Malaysia">
      <path
        id="Malaysia_svg__red"
        d="M0-.25h1280v720.5H0z"
        style={{
          fill: "#cc0001",
        }}
      />
      <path
        id="Malaysia_svg__white"
        d="M0 668.79h1280v51.46H0z"
        className="Malaysia_svg__cls-2"
      />
      <g id="Malaysia_svg__use3035">
        <path
          id="Malaysia_svg__white-2"
          d="M0 565.86h1280v51.46H0z"
          className="Malaysia_svg__cls-2"
          data-name="white"
        />
      </g>
      <g id="Malaysia_svg__use3038">
        <path
          id="Malaysia_svg__white-3"
          d="M0 462.93h1280v51.46H0z"
          className="Malaysia_svg__cls-2"
          data-name="white"
        />
      </g>
      <g id="Malaysia_svg__use3062">
        <path
          id="Malaysia_svg__white-4"
          d="M0 360h1280v51.46H0z"
          className="Malaysia_svg__cls-2"
          data-name="white"
        />
      </g>
      <g id="Malaysia_svg__use3064">
        <path
          id="Malaysia_svg__white-5"
          d="M0 257.07h1280v51.46H0z"
          className="Malaysia_svg__cls-2"
          data-name="white"
        />
      </g>
      <g id="Malaysia_svg__use3066">
        <path
          id="Malaysia_svg__white-6"
          d="M0 154.14h1280v51.46H0z"
          className="Malaysia_svg__cls-2"
          data-name="white"
        />
      </g>
      <g id="Malaysia_svg__use3068">
        <path
          id="Malaysia_svg__white-7"
          d="M0 51.21h1280v51.46H0z"
          className="Malaysia_svg__cls-2"
          data-name="white"
        />
      </g>
      <path
        id="Malaysia_svg__blue"
        d="M0-.25h720.5v411.71H0z"
        style={{
          fill: "#010066",
        }}
      />
      <path
        id="Malaysia_svg__path4945"
        d="M296.43 51.21c-85.5 0-154.9 69.18-154.9 154.4S210.93 360 296.43 360a154.46 154.46 0 0 0 83.85-24.59 137.3 137.3 0 0 1-44.91 7.51C259.55 342.92 198 281.59 198 206S259.54 69.1 335.36 69.1A137.4 137.4 0 0 1 383 77.6a154.56 154.56 0 0 0-86.57-26.39"
        className="Malaysia_svg__cls-4"
      />
      <path
        id="Malaysia_svg__path4959"
        d="m553.33 286-65.25-34 19.29 69.67-43.65-58.28-13.61 70.94-13.39-71-43.83 58.15 19.51-69.62-65.4 33.81 48.6-54.47-74 2.84 68-28.52-67.89-28.74 73.94 3.07-48.35-54.62 65.25 34.05-19.29-69.68 43.64 58.29L450.52 77l13.39 71 43.83-58.16-19.52 69.62 65.36-33.85L505 180l74-2.83-68 28.53 67.88 28.73-73.88-3.06Z"
        className="Malaysia_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgMalaysia;
