import * as React from "react";
import type { SVGProps } from "react";
const SvgMyanmar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Myanmar_svg__cls-4{fill:#fff}"}</style>
    </defs>
    <g id="Myanmar_svg__Myanmar">
      <path
        d="M0-.33h1280V360H0z"
        style={{
          fill: "#fecb00",
        }}
      />
      <path
        d="M0 360h1280v360.33H0z"
        style={{
          fill: "#ea2839",
        }}
      />
      <path
        d="M0 239.89h1280v240.22H0z"
        style={{
          fill: "#34b233",
        }}
      />
      <g id="Myanmar_svg__star">
        <path
          id="Myanmar_svg__pt"
          d="M710.31 436.06 484.4 600.19l86.29-265.56z"
          className="Myanmar_svg__cls-4"
        />
        <path
          id="Myanmar_svg__pt-2"
          d="M613.84 467.41 387.93 303.28h279.23z"
          className="Myanmar_svg__cls-4"
          data-name="pt"
        />
        <path
          id="Myanmar_svg__pt-3"
          d="m554.21 385.34 86.29-265.56 86.29 265.56z"
          className="Myanmar_svg__cls-4"
          data-name="pt"
        />
        <path
          id="Myanmar_svg__pt-4"
          d="M613.84 303.28h279.23L667.16 467.41z"
          className="Myanmar_svg__cls-4"
          data-name="pt"
        />
        <path
          id="Myanmar_svg__pt-5"
          d="m710.31 334.63 86.29 265.56-225.91-164.13z"
          className="Myanmar_svg__cls-4"
          data-name="pt"
        />
      </g>
    </g>
  </svg>
);
export default SvgMyanmar;
