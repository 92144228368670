import * as React from "react";
import type { SVGProps } from "react";
const SvgTrinidadAndTobago = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <g data-name="Trinidad and Tobago">
      <path
        d="M0-.3h1280v720.6H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        d="m0-.3 888.52 720.6H1280L391.48-.3z"
        style={{
          fill: "#fff",
        }}
      />
      <path d="m65.25-.3 888.52 720.6h260.98L326.23-.3z" />
    </g>
  </svg>
);
export default SvgTrinidadAndTobago;
