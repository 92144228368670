import * as React from "react";
import type { SVGProps } from "react";
const SvgMaldives = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Maldives_svg__cls-2{fill:#007e3a}"}</style>
    </defs>
    <g id="Maldives_svg__Maldives">
      <path
        id="Maldives_svg__red_background"
        d="M0 0h1280v720H0z"
        data-name="red background"
        style={{
          fill: "#d21034",
        }}
      />
      <path
        id="Maldives_svg__green_field"
        d="M280 180h720v360H280z"
        className="Maldives_svg__cls-2"
        data-name="green field"
      />
      <circle
        id="Maldives_svg__white_crescent_arc"
        cx={685}
        cy={360}
        r={120}
        data-name="white crescent arc"
        style={{
          fill: "#fff",
        }}
      />
      <circle
        id="Maldives_svg__green_crescent_arc"
        cx={730}
        cy={360}
        r={120}
        className="Maldives_svg__cls-2"
        data-name="green crescent arc"
      />
    </g>
  </svg>
);
export default SvgMaldives;
