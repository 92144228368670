import * as React from "react";
import type { SVGProps } from "react";
const SvgGuinea = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.33h426.67v720.67H0z"
      style={{
        fill: "#ce1126",
      }}
    />
    <path
      d="M426.67-.33h426.67v720.67H426.67z"
      style={{
        fill: "#fcd116",
      }}
    />
    <path
      d="M853.33-.33H1280v720.67H853.33z"
      style={{
        fill: "#009460",
      }}
    />
  </svg>
);
export default SvgGuinea;
