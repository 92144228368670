import * as React from "react";
import type { SVGProps } from "react";
const SvgUzbekistan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Uzbekistan_svg__cls-1{fill:#0099b5}.Uzbekistan_svg__cls-3{fill:#fff}"
        }
      </style>
    </defs>
    <g id="Uzbekistan_svg__Uzbekistan">
      <path d="M0 0h1280v230.4H0z" className="Uzbekistan_svg__cls-1" />
      <path
        d="M0 230.4h1280v259.2H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path d="M0 244.8h1280v230.4H0z" className="Uzbekistan_svg__cls-3" />
      <path
        d="M0 489.6h1280V720H0z"
        style={{
          fill: "#1eb53a",
        }}
      />
      <circle
        cx={179.2}
        cy={114.4}
        r={76.8}
        className="Uzbekistan_svg__cls-3"
      />
      <circle
        cx={204.8}
        cy={114.4}
        r={76.8}
        className="Uzbekistan_svg__cls-1"
      />
      <path
        id="Uzbekistan_svg__star"
        d="M209 170.36h11.76l3.63-11.18 3.61 11.18h11.76l-9.51 6.91 3.63 11.18-9.51-6.91-9.51 6.91 3.63-11.18Z"
        className="Uzbekistan_svg__cls-3"
      />
      <path
        id="Uzbekistan_svg__star-2"
        d="M271.73 170.36h11.76l3.63-11.18 3.63 11.18h11.76l-9.51 6.91 3.63 11.18-9.51-6.91-9.51 6.91 3.63-11.18Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-3"
        d="M333.17 170.36h11.76l3.63-11.18 3.63 11.18H364l-9.51 6.91 3.63 11.18-9.51-6.91-9.51 6.91 3.63-11.18Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-4"
        d="M393.76 170.36h11.75l3.64-11.18 3.63 11.18h11.75l-9.53 6.91 3.64 11.18-9.51-6.91-9.51 6.91 3.63-11.18Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-5"
        d="M455.62 170.36h11.76l3.62-11.18 3.63 11.18h11.77l-9.51 6.91 3.63 11.18-9.52-6.91-9.51 6.91 3.63-11.18Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-6"
        d="M271.73 108.39h11.76l3.63-11.19 3.63 11.19h11.76l-9.51 6.9 3.63 11.19-9.51-6.91-9.51 6.91 3.63-11.19Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-7"
        d="M333.17 108.39h11.76l3.63-11.19 3.63 11.19H364l-9.51 6.9 3.63 11.19-9.51-6.91-9.51 6.91 3.63-11.19Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-8"
        d="M393.76 108.39h11.75l3.64-11.19 3.63 11.19h11.75l-9.51 6.9 3.64 11.19-9.51-6.91-9.51 6.91 3.63-11.19Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-9"
        d="M455.62 108.39h11.76L471 97.2l3.63 11.19h11.77l-9.51 6.9 3.63 11.19-9.52-6.91-9.51 6.91 3.63-11.19Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-10"
        d="M332.53 47h11.76l3.63-11.19L351.55 47h11.76l-9.51 6.9 3.63 11.1-9.51-6.91-9.51 6.91L342 53.85Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-11"
        d="M393.12 47h11.75l3.64-11.19L412.14 47h11.75l-9.51 6.9L418 65l-9.51-6.91L399 65l3.63-11.19Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
      <path
        id="Uzbekistan_svg__star-12"
        d="M455 47h11.76l3.63-11.19L474 47h11.76l-9.51 6.9 3.63 11.1-9.51-6.91-9.51 6.91 3.63-11.19Z"
        className="Uzbekistan_svg__cls-3"
        data-name="star"
      />
    </g>
  </svg>
);
export default SvgUzbekistan;
