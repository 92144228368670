import * as React from "react";
import type { SVGProps } from "react";
const SvgSlovenia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Slovenia_svg__cls-1{fill:#eb2027}.Slovenia_svg__cls-2{fill:#005ea4}.Slovenia_svg__cls-3{fill:#fff}.Slovenia_svg__cls-4{fill:#fddb00}"
        }
      </style>
    </defs>
    <g id="Slovenia_svg__Slovenia">
      <path d="M0-.58h1280v721.16H0z" className="Slovenia_svg__cls-1" />
      <path d="M0-.58h1280v480.77H0z" className="Slovenia_svg__cls-2" />
      <path d="M0-.58h1280v240.39H0z" className="Slovenia_svg__cls-3" />
      <path
        d="m425 186.53 7.52-111a216.45 216.45 0 0 0-185.38 0l7.53 111a120.18 120.18 0 0 0 85.16 106.94A120.2 120.2 0 0 0 425 186.53"
        className="Slovenia_svg__cls-2"
      />
      <path
        d="M270.3 201.57a106.83 106.83 0 0 0 69.52 77.84 106.81 106.81 0 0 0 69.52-77.84l-34.76-46.34-13.9 18.54-20.86-41.72L319 173.77l-13.91-18.54z"
        className="Slovenia_svg__cls-3"
      />
      <path
        id="Slovenia_svg__wave"
        d="M273.41 213.16a23.17 23.17 0 0 0 31.65 8.48 23.18 23.18 0 0 1 23.18 0 23.16 23.16 0 0 0 23.17 0 23.16 23.16 0 0 1 23.17 0 23.18 23.18 0 0 0 31.66-8.48v6.69a23.18 23.18 0 0 1-31.66 8.48 23.16 23.16 0 0 0-23.17 0 23.16 23.16 0 0 1-23.17 0 23.18 23.18 0 0 0-23.18 0 23.17 23.17 0 0 1-31.65-8.48Z"
        className="Slovenia_svg__cls-2"
      />
      <path
        id="Slovenia_svg__wave-2"
        d="M273.41 226.54a23.17 23.17 0 0 0 31.65 8.46 23.18 23.18 0 0 1 23.18 0 23.16 23.16 0 0 0 23.17 0 23.16 23.16 0 0 1 23.17 0 23.18 23.18 0 0 0 31.66-8.48v6.69a23.18 23.18 0 0 1-31.66 8.48 23.16 23.16 0 0 0-23.17 0 23.16 23.16 0 0 1-23.17 0 23.18 23.18 0 0 0-23.18 0 23.17 23.17 0 0 1-31.65-8.48Z"
        className="Slovenia_svg__cls-2"
        data-name="wave"
      />
      <path
        id="Slovenia_svg__s"
        d="m339.82 100.19 1.74 5.68 5.79-1.33-4.05 4.34 4.05 4.35-5.79-1.34-1.74 5.68-1.74-5.68-5.78 1.34 4.05-4.35-4.05-4.34 5.78 1.33Z"
        className="Slovenia_svg__cls-4"
      />
      <path
        id="Slovenia_svg__s-2"
        d="m313.75 65.43 1.74 5.68 5.79-1.33-4 4.34 4 4.35-5.79-1.34-1.74 5.68-1.75-5.68-5.79 1.34 4-4.35-4-4.34 5.79 1.33Z"
        className="Slovenia_svg__cls-4"
        data-name="s"
      />
      <path
        id="Slovenia_svg__s-3"
        d="m365.89 65.43 1.74 5.68 5.79-1.33-4.05 4.34 4.05 4.35-5.79-1.34-1.74 5.68-1.74-5.68-5.78 1.34 4.05-4.35-4.05-4.34 5.78 1.33Z"
        className="Slovenia_svg__cls-4"
        data-name="s"
      />
      <path
        d="m253.63 72.54 7.7 113.54a113.49 113.49 0 0 0 78.49 100.37 113.51 113.51 0 0 0 78.5-100.37L426 72.54q3.23 1.53 6.5 2.95l-7.52 111a120.2 120.2 0 0 1-85.17 106.94 120.18 120.18 0 0 1-85.16-106.94l-7.53-111c2.19-.95 4.35-1.93 6.5-2.95"
        className="Slovenia_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgSlovenia;
