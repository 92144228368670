import * as React from "react";
import type { SVGProps } from "react";
const SvgBahamas = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#00abc9",
      }}
    />
    <path
      d="M0 240h1280v240H0z"
      style={{
        fill: "#fae042",
      }}
    />
    <path d="M0 0v720l554.26-360Z" />
  </svg>
);
export default SvgBahamas;
