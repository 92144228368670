import * as React from "react";
import type { SVGProps } from "react";

const SvgGreenland = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 800" {...props}>
    {/* Background */}
    <rect width="1200" height="800" fill="#fff" />
    
    {/* Red Half Circle */}
    <circle cx="900" cy="400" r="400" fill="#d52b1e" />
    <circle cx="700" cy="400" r="400" fill="#fff" />
    
    {/* White Half Circle */}
    <circle cx="700" cy="400" r="300" fill="#fff" />
    <circle cx="900" cy="400" r="300" fill="#d52b1e" />
  </svg>
);

export default SvgGreenland;