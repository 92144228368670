import * as React from "react";
import type { SVGProps } from "react";

const SvgCocosKeelingIslands = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 480" {...props}>
    {/* Background with green stripes */}
    <rect width="960" height="480" fill="#009e49" />
    <rect width="960" height="160" y="320" fill="#ffcc00" />
    
    {/* Union Jack in the top left corner */}
    <rect width="240" height="160" fill="#00247d" />
    <rect width="240" height="40" y="40" fill="#fff" />
    <rect width="240" height="40" y="80" fill="#c8102e" />
    <rect width="40" height="160" fill="#fff" />
    <rect width="40" height="160" x="80" fill="#c8102e" />
    
    {/* White stars and yellow circle in the Union Jack section */}
    <circle cx="80" cy="80" r="40" fill="#ffcc00" />
    <polygon points="60,40 80,20 100,40 80,60" fill="#fff" />
    <polygon points="60,100 80,80 100,100 80,120" fill="#fff" />
    
    {/* Green and yellow stars in the bottom right corner */}
    <circle cx="800" cy="400" r="40" fill="#ffcc00" />
    <circle cx="720" cy="320" r="40" fill="#ffcc00" />
    <circle cx="880" cy="320" r="40" fill="#ffcc00" />
    <circle cx="800" cy="240" r="40" fill="#ffcc00" />
    
    {/* Green stars */}
    <circle cx="800" cy="400" r="20" fill="#009e49" />
    <circle cx="720" cy="320" r="20" fill="#009e49" />
    <circle cx="880" cy="320" r="20" fill="#009e49" />
    <circle cx="800" cy="240" r="20" fill="#009e49" />
  </svg>
);

export default SvgCocosKeelingIslands;