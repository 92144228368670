import * as React from "react";
import type { SVGProps } from "react";
const SvgUnitedStates = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".United_States_svg__cls-1{fill:#fff}.United_States_svg__cls-2{fill:#b22234}"
        }
      </style>
    </defs>
    <g id="United_States_svg__United_States" data-name="United States">
      <path
        id="United_States_svg__White"
        d="M0-.37h1280v720.74H0z"
        className="United_States_svg__cls-1"
      />
      <g id="United_States_svg__Stripes">
        <path
          id="United_States_svg__stripe"
          d="M0-.37h1280v55.44H0z"
          className="United_States_svg__cls-2"
        />
        <path
          id="United_States_svg__stripe-2"
          d="M0 110.51h1280v55.44H0z"
          className="United_States_svg__cls-2"
          data-name="stripe"
        />
        <path
          id="United_States_svg__stripe-3"
          d="M0 221.4h1280v55.44H0z"
          className="United_States_svg__cls-2"
          data-name="stripe"
        />
        <path
          id="United_States_svg__stripe-4"
          d="M0 332.28h1280v55.44H0z"
          className="United_States_svg__cls-2"
          data-name="stripe"
        />
        <path
          id="United_States_svg__stripe-5"
          d="M0 443.16h1280v55.44H0z"
          className="United_States_svg__cls-2"
          data-name="stripe"
        />
        <path
          id="United_States_svg__stripe-6"
          d="M0 554.04h1280v55.44H0z"
          className="United_States_svg__cls-2"
          data-name="stripe"
        />
        <path
          id="United_States_svg__stripe-7"
          d="M0 664.93h1280v55.44H0z"
          className="United_States_svg__cls-2"
          data-name="stripe"
        />
      </g>
      <path
        id="United_States_svg__Square"
        d="M0-.37h547.76v388.09H0z"
        style={{
          fill: "#3c3b6e",
        }}
      />
      <g id="United_States_svg__Stars">
        <g id="United_States_svg__star">
          <path
            id="United_States_svg__pt"
            d="M52.68 42.24 33.8 55.96l7.21-22.2z"
            className="United_States_svg__cls-1"
          />
          <path
            id="United_States_svg__pt-2"
            d="M44.62 44.86 25.74 31.14h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-3"
            d="m39.63 38 7.22-22.2L54.06 38z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-4"
            d="M44.62 31.14h23.34L49.08 44.86z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-5"
            d="m52.68 33.76 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-2" data-name="star">
          <path
            id="United_States_svg__pt-6"
            d="m143.5 42.24-18.89 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-7"
            d="m135.43 44.86-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-8"
            d="m130.45 38 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-9"
            d="M135.43 31.14h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-10"
            d="m143.5 33.76 7.21 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-3" data-name="star">
          <path
            id="United_States_svg__pt-11"
            d="m234.31 42.24-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-12"
            d="m226.25 44.86-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-13"
            d="m221.26 38 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-14"
            d="M226.25 31.14h23.34L230.7 44.86z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-15"
            d="m234.31 33.76 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-4" data-name="star">
          <path
            id="United_States_svg__pt-16"
            d="m325.12 42.24-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-17"
            d="m317.06 44.86-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-18"
            d="m312.07 38 7.22-22.2L326.5 38z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-19"
            d="M317.06 31.14h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-20"
            d="m325.12 33.76 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-5" data-name="star">
          <path
            id="United_States_svg__pt-21"
            d="m415.93 42.24-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-22"
            d="m407.87 44.86-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-23"
            d="m402.89 38 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-24"
            d="M407.87 31.14h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-25"
            d="m415.93 33.76 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-6" data-name="star">
          <path
            id="United_States_svg__pt-26"
            d="m506.75 42.24-18.89 13.72 7.22-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-27"
            d="M498.68 44.86 479.8 31.14h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-28"
            d="m493.7 38 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-29"
            d="M498.68 31.14h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-30"
            d="m506.75 33.76 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-7" data-name="star">
          <path
            id="United_States_svg__pt-31"
            d="M98.09 81.16 79.21 94.88l7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-32"
            d="M90.03 83.78 71.14 70.06h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-33"
            d="m85.04 76.92 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-34"
            d="M90.03 70.06h23.34L94.48 83.78z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-35"
            d="m98.09 72.68 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-8" data-name="star">
          <path
            id="United_States_svg__pt-36"
            d="m188.9 81.16-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-37"
            d="m180.84 83.78-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-38"
            d="m175.85 76.92 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-39"
            d="M180.84 70.06h23.34L185.3 83.78z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-40"
            d="m188.9 72.68 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-9" data-name="star">
          <path
            id="United_States_svg__pt-41"
            d="m279.71 81.16-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-42"
            d="m271.65 83.78-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-43"
            d="m266.67 76.92 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-44"
            d="M271.65 70.06h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-45"
            d="m279.71 72.68 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-10" data-name="star">
          <path
            id="United_States_svg__pt-46"
            d="m370.53 81.16-18.89 13.72 7.22-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-47"
            d="m362.46 83.78-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-48"
            d="m357.48 76.92 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-49"
            d="M362.46 70.06h23.35l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-50"
            d="m370.53 72.68 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-11" data-name="star">
          <path
            id="United_States_svg__pt-51"
            d="m461.34 81.16-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-52"
            d="m453.28 83.78-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-53"
            d="m448.29 76.92 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-54"
            d="M453.28 70.06h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-55"
            d="m461.34 72.68 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-12" data-name="star">
          <path
            id="United_States_svg__pt-56"
            d="M52.68 120.08 33.8 133.79l7.21-22.19z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-57"
            d="m44.62 122.7-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-58"
            d="m39.63 115.84 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-59"
            d="M44.62 108.98h23.34L49.08 122.7z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-60"
            d="m52.68 111.6 7.22 22.19-18.89-13.71z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-13" data-name="star">
          <path
            id="United_States_svg__pt-61"
            d="m143.5 120.08-18.89 13.71 7.21-22.19z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-62"
            d="m135.43 122.7-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-63"
            d="m130.45 115.84 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-64"
            d="M135.43 108.98h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-65"
            d="m143.5 111.6 7.21 22.19-18.89-13.71z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-14" data-name="star">
          <path
            id="United_States_svg__pt-66"
            d="m234.31 120.08-18.88 13.71 7.21-22.19z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-67"
            d="m226.25 122.7-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-68"
            d="m221.26 115.84 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-69"
            d="M226.25 108.98h23.34L230.7 122.7z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-70"
            d="m234.31 111.6 7.21 22.19-18.88-13.71z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-15" data-name="star">
          <path
            id="United_States_svg__pt-71"
            d="m325.12 120.08-18.88 13.71 7.21-22.19z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-72"
            d="m317.06 122.7-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-73"
            d="m312.07 115.84 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-74"
            d="M317.06 108.98h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-75"
            d="m325.12 111.6 7.21 22.19-18.88-13.71z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-16" data-name="star">
          <path
            id="United_States_svg__pt-76"
            d="m415.93 120.08-18.88 13.71 7.21-22.19z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-77"
            d="m407.87 122.7-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-78"
            d="m402.89 115.84 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-79"
            d="M407.87 108.98h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-80"
            d="m415.93 111.6 7.22 22.19-18.89-13.71z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-17" data-name="star">
          <path
            id="United_States_svg__pt-81"
            d="m506.75 120.08-18.89 13.71 7.22-22.19z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-82"
            d="m498.68 122.7-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-83"
            d="m493.7 115.84 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-84"
            d="M498.68 108.98h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-85"
            d="m506.75 111.6 7.21 22.19-18.88-13.71z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-18" data-name="star">
          <path
            id="United_States_svg__pt-86"
            d="m98.09 159-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-87"
            d="M90.03 161.62 71.14 147.9h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-88"
            d="m85.04 154.76 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-89"
            d="M90.03 147.9h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-90"
            d="m98.09 150.52 7.21 22.2L86.42 159z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-19" data-name="star">
          <path
            id="United_States_svg__pt-91"
            d="m188.9 159-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-92"
            d="m180.84 161.62-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-93"
            d="m175.85 154.76 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-94"
            d="M180.84 147.9h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-95"
            d="m188.9 150.52 7.22 22.2L177.23 159z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-20" data-name="star">
          <path
            id="United_States_svg__pt-96"
            d="m279.71 159-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-97"
            d="m271.65 161.62-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-98"
            d="m266.67 154.76 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-99"
            d="M271.65 147.9h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-100"
            d="m279.71 150.52 7.22 22.2L268.04 159z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-21" data-name="star">
          <path
            id="United_States_svg__pt-101"
            d="m370.53 159-18.89 13.72 7.22-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-102"
            d="m362.46 161.62-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-103"
            d="m357.48 154.76 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-104"
            d="M362.46 147.9h23.35l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-105"
            d="m370.53 150.52 7.21 22.2L358.86 159z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-22" data-name="star">
          <path
            id="United_States_svg__pt-106"
            d="m461.34 159-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-107"
            d="m453.28 161.62-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-108"
            d="m448.29 154.76 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-109"
            d="M453.28 147.9h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-110"
            d="m461.34 150.52 7.21 22.2L449.67 159z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-23" data-name="star">
          <path
            id="United_States_svg__pt-111"
            d="M52.68 198.2 33.8 211.92l7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-112"
            d="M44.62 200.82 25.74 187.1h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-113"
            d="m39.63 193.96 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-114"
            d="M44.62 187.1h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-115"
            d="m52.68 189.72 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-24" data-name="star">
          <path
            id="United_States_svg__pt-116"
            d="m143.5 198.2-18.89 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-117"
            d="m135.43 200.82-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-118"
            d="m130.45 193.96 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-119"
            d="M135.43 187.1h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-120"
            d="m143.5 189.72 7.21 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-25" data-name="star">
          <path
            id="United_States_svg__pt-121"
            d="m234.31 198.2-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-122"
            d="m226.25 200.82-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-123"
            d="m221.26 193.96 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-124"
            d="M226.25 187.1h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-125"
            d="m234.31 189.72 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-26" data-name="star">
          <path
            id="United_States_svg__pt-126"
            d="m325.12 198.2-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-127"
            d="m317.06 200.82-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-128"
            d="m312.07 193.96 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-129"
            d="M317.06 187.1h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-130"
            d="m325.12 189.72 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-27" data-name="star">
          <path
            id="United_States_svg__pt-131"
            d="m415.93 198.2-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-132"
            d="m407.87 200.82-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-133"
            d="m402.89 193.96 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-134"
            d="M407.87 187.1h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-135"
            d="m415.93 189.72 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-28" data-name="star">
          <path
            id="United_States_svg__pt-136"
            d="m506.75 198.2-18.89 13.72 7.22-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-137"
            d="M498.68 200.82 479.8 187.1h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-138"
            d="m493.7 193.96 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-139"
            d="M498.68 187.1h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-140"
            d="m506.75 189.72 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-29" data-name="star">
          <path
            id="United_States_svg__pt-141"
            d="m98.09 237.12-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-142"
            d="m90.03 239.74-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-143"
            d="m85.04 232.88 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-144"
            d="M90.03 226.02h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-145"
            d="m98.09 228.64 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-30" data-name="star">
          <path
            id="United_States_svg__pt-146"
            d="m188.9 237.12-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-147"
            d="m180.84 239.74-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-148"
            d="m175.85 232.88 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-149"
            d="M180.84 226.02h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-150"
            d="m188.9 228.64 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-31" data-name="star">
          <path
            id="United_States_svg__pt-151"
            d="m279.71 237.12-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-152"
            d="m271.65 239.74-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-153"
            d="m266.67 232.88 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-154"
            d="M271.65 226.02h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-155"
            d="m279.71 228.64 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-32" data-name="star">
          <path
            id="United_States_svg__pt-156"
            d="m370.53 237.12-18.89 13.72 7.22-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-157"
            d="m362.46 239.74-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-158"
            d="m357.48 232.88 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-159"
            d="M362.46 226.02h23.35l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-160"
            d="m370.53 228.64 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-33" data-name="star">
          <path
            id="United_States_svg__pt-161"
            d="m461.34 237.12-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-162"
            d="m453.28 239.74-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-163"
            d="m448.29 232.88 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-164"
            d="M453.28 226.02h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-165"
            d="m461.34 228.64 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-34" data-name="star">
          <path
            id="United_States_svg__pt-166"
            d="M52.68 276.04 33.8 289.76l7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-167"
            d="m44.62 278.66-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-168"
            d="m39.63 271.8 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-169"
            d="M44.62 264.94h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-170"
            d="m52.68 267.56 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-35" data-name="star">
          <path
            id="United_States_svg__pt-171"
            d="m143.5 276.04-18.89 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-172"
            d="m135.43 278.66-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-173"
            d="m130.45 271.8 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-174"
            d="M135.43 264.94h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-175"
            d="m143.5 267.56 7.21 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-36" data-name="star">
          <path
            id="United_States_svg__pt-176"
            d="m234.31 276.04-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-177"
            d="m226.25 278.66-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-178"
            d="m221.26 271.8 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-179"
            d="M226.25 264.94h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-180"
            d="m234.31 267.56 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-37" data-name="star">
          <path
            id="United_States_svg__pt-181"
            d="m325.12 276.04-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-182"
            d="m317.06 278.66-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-183"
            d="m312.07 271.8 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-184"
            d="M317.06 264.94h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-185"
            d="m325.12 267.56 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-38" data-name="star">
          <path
            id="United_States_svg__pt-186"
            d="m415.93 276.04-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-187"
            d="m407.87 278.66-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-188"
            d="m402.89 271.8 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-189"
            d="M407.87 264.94h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-190"
            d="m415.93 267.56 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-39" data-name="star">
          <path
            id="United_States_svg__pt-191"
            d="m506.75 276.04-18.89 13.72 7.22-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-192"
            d="m498.68 278.66-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-193"
            d="m493.7 271.8 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-194"
            d="M498.68 264.94h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-195"
            d="m506.75 267.56 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-40" data-name="star">
          <path
            id="United_States_svg__pt-196"
            d="m98.09 314.96-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-197"
            d="m90.03 317.58-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-198"
            d="m85.04 310.72 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-199"
            d="M90.03 303.86h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-200"
            d="m98.09 306.48 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-41" data-name="star">
          <path
            id="United_States_svg__pt-201"
            d="m188.9 314.96-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-202"
            d="m180.84 317.58-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-203"
            d="m175.85 310.72 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-204"
            d="M180.84 303.86h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-205"
            d="m188.9 306.48 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-42" data-name="star">
          <path
            id="United_States_svg__pt-206"
            d="m279.71 314.96-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-207"
            d="m271.65 317.58-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-208"
            d="m266.67 310.72 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-209"
            d="M271.65 303.86h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-210"
            d="m279.71 306.48 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-43" data-name="star">
          <path
            id="United_States_svg__pt-211"
            d="m370.53 314.96-18.89 13.72 7.22-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-212"
            d="m362.46 317.58-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-213"
            d="m357.48 310.72 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-214"
            d="M362.46 303.86h23.35l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-215"
            d="m370.53 306.48 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-44" data-name="star">
          <path
            id="United_States_svg__pt-216"
            d="m461.34 314.96-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-217"
            d="m453.28 317.58-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-218"
            d="m448.29 310.72 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-219"
            d="M453.28 303.86h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-220"
            d="m461.34 306.48 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-45" data-name="star">
          <path
            id="United_States_svg__pt-221"
            d="M52.68 354.04 33.8 367.76l7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-222"
            d="m44.62 356.66-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-223"
            d="m39.63 349.8 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-224"
            d="M44.62 342.94h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-225"
            d="m52.68 345.56 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-46" data-name="star">
          <path
            id="United_States_svg__pt-226"
            d="m143.5 354.04-18.89 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-227"
            d="m135.43 356.66-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-228"
            d="m130.45 349.8 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-229"
            d="M135.43 342.94h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-230"
            d="m143.5 345.56 7.21 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-47" data-name="star">
          <path
            id="United_States_svg__pt-231"
            d="m234.31 354.04-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-232"
            d="m226.25 356.66-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-233"
            d="m221.26 349.8 7.21-22.2 7.22 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-234"
            d="M226.25 342.94h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-235"
            d="m234.31 345.56 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-48" data-name="star">
          <path
            id="United_States_svg__pt-236"
            d="m325.12 354.04-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-237"
            d="m317.06 356.66-18.89-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-238"
            d="m312.07 349.8 7.22-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-239"
            d="M317.06 342.94h23.34l-18.89 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-240"
            d="m325.12 345.56 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-49" data-name="star">
          <path
            id="United_States_svg__pt-241"
            d="m415.93 354.04-18.88 13.72 7.21-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-242"
            d="m407.87 356.66-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-243"
            d="m402.89 349.8 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-244"
            d="M407.87 342.94h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-245"
            d="m415.93 345.56 7.22 22.2-18.89-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
        <g id="United_States_svg__star-50" data-name="star">
          <path
            id="United_States_svg__pt-246"
            d="m506.75 354.04-18.89 13.72 7.22-22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-247"
            d="m498.68 356.66-18.88-13.72h23.34z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-248"
            d="m493.7 349.8 7.21-22.2 7.21 22.2z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-249"
            d="M498.68 342.94h23.34l-18.88 13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
          <path
            id="United_States_svg__pt-250"
            d="m506.75 345.56 7.21 22.2-18.88-13.72z"
            className="United_States_svg__cls-1"
            data-name="pt"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgUnitedStates;
