import * as React from "react";
import type { SVGProps } from "react";
const SvgMadagascar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1169.86v720H0z"
      data-name="white stripe"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M443.13 0H1280v720H443.13z"
      data-name="red stripe"
      style={{
        fill: "#fc3d32",
      }}
    />
    <path
      d="M443.13 360H1280v360H443.13z"
      data-name="green stripe"
      style={{
        fill: "#007e3a",
      }}
    />
  </svg>
);
export default SvgMadagascar;
