import * as React from "react";
import type { SVGProps } from "react";
const SvgPapuaNewGuinea = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Papua_New_Guinea_svg__cls-3{fill:#fff}"}</style>
    </defs>
    <g id="Papua_New_Guinea_svg__Papua_New_Guinea" data-name="Papua New Guinea">
      <path id="Papua_New_Guinea_svg__rect3372" d="M0 0h1280v720H0z" />
      <path
        id="Papua_New_Guinea_svg__path4249"
        d="M0 0h1280v720Z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        id="Papua_New_Guinea_svg__path3348"
        d="M886.36 209.19c-7.09 9.16-23.87-9.21-44-9.63s-34.34 19.26-40.2 26-24.71 41.88-24.29 45.65 11.73-10.47 15.91-13c.42 5-4.6 10.88-3.35 12.56s14.66-10.05 19.26-10.47c1.26 3.35-2.51 6.7-1.25 11.72 1.67 1.68 9.21-8.37 14.24-10.46.83 3.76-4.61 10.88-1.68 15.49 2.09.42 14.66-14.24 18.84-15.91s-2.09 13.82 0 15.07c3.77-2.51 15.5-15.91 19.27-17.59s-.42 17.17 1.25 17.17C865 272.42 876.73 256.51 878 254s-1.25 24.29 11.73 38.52 32.66 30.57 34.75 30.57c2.52-4.6-.83-14.23 1.26-13.82 5.86 6.28 16.75 15.5 19.26 15.5s-1.67-10 1.68-10c6.7 6.28 26 31 63.65 42.29-2.52-7.12-8-13.4-8.38-16.75 2.51.42 11.31 4.61 13 4.19s-18.46-25.18-17.21-28.5c4.61-.42 6.7 2.52 8.38.42-2.1-3.35-19.68-23-19.68-27.64s5.44.84 7.12 0-6.7-18.42-5-20.51 20.1 11.3 24.71 11.3 1.25-16.33 3.35-17.17 17.59 8.8 20.94 8.8 2.93-7.12 4.18-6.28 37.69 37.26 25.13 80c-12.56 30.57-37.69 46.9-74.54 33.5 2.09 3.35 21.77 19.68 43.13 13.82s47.32-22.62 44.39-64.49-16.75-47.74-18.85-54 3.36 1.67 8 3.35-.84-8.79-2.09-13.4 13.82 9.63 17.59 10.89-8.8-34.34-12.57-36.85 3.77.83 6.28 1.67-23-38.94-28.47-38.94 4.6-2.1 7.54-2.1-29.31-26-34.76-26 11.72-6.7 13.4-7.12c2.93.42-37.27-21.35-73.28.42-2.1-1.67 17-14.86 17.59-20.52.21-1.46-6.28-4.6-6.7-6.28s10-6.7 10.46-10.47c-2.51-3.35-7.11-2.09-9.63-2.93-.62-3.14 8.8-10.89 8-14.23-.63-1.89-7.54-2.52-9.21-1.26s4.4-8.79 3.35-11.73c-.84-1.25-4.19-.41-7.54.42-2.09-.42 10.89-18.42 8.38-20.93s-37.69 18.42-50.67 41.87-2.93 42.29-4.61 50.25-3.77 15.91-9.21 15.91-18-8.37-22.2-12.56-5-7.12-10.88-8.79-19.27 3.35-24.29 3.77-24.71-3.77-26-2.52 19.26 8.8 23.86 11.31 5 5.86 8.38 8 34.69 11.23 24.64 24.21m122.69 59.05c10.05 10.05 77.89 55.27 77.89 110.55s-49.42 53.6-63.65 51.92c15.91 13.4 76.21 10.89 76.21-51.92 0-55.28-77.89-110.55-87.94-115.58s-12.56-5.02-2.51 5.03"
        style={{
          fill: "#fcd116",
        }}
      />
      <g id="Papua_New_Guinea_svg__use2390">
        <path
          id="Papua_New_Guinea_svg__polygon5131"
          d="m430 353.75 10.5 36.05h36.27l-28.64 22.03 10.51 35.05L430 424.85l-28.64 21.03 10.5-34.05-28.63-22.03h35.32z"
          className="Papua_New_Guinea_svg__cls-3"
        />
      </g>
      <path
        id="Papua_New_Guinea_svg__polygon5131-2"
        d="m320.52 553.75 10.5 36.05h36.28l-28.64 22.03 10.5 35.05-28.64-22.03-28.63 21.03 10.5-34.05-28.64-22.03h35.32z"
        className="Papua_New_Guinea_svg__cls-3"
        data-name="polygon5131"
      />
      <path
        id="Papua_New_Guinea_svg__polygon5133"
        d="m369.64 477.19 5.25 18.02h18.13l-14.32 11.02 5.25 17.52-14.31-11.02-14.32 10.52 5.25-17.02-14.32-11.02h17.66z"
        className="Papua_New_Guinea_svg__cls-3"
      />
      <g id="Papua_New_Guinea_svg__use2392">
        <path
          id="Papua_New_Guinea_svg__polygon5131-3"
          d="m320.52 223.12 10.5 36.05h36.28l-28.64 22.03 10.5 35.05-28.64-22.03-28.63 21.03 10.5-34.05-28.64-22.03h35.32z"
          className="Papua_New_Guinea_svg__cls-3"
          data-name="polygon5131"
        />
      </g>
      <g id="Papua_New_Guinea_svg__use2394">
        <path
          id="Papua_New_Guinea_svg__polygon5131-4"
          d="m210.52 363.75 10.5 36.05h36.28l-28.64 22.03 10.5 35.05-28.64-22.03-28.63 21.03 10.5-34.05-28.64-22.03h35.32z"
          className="Papua_New_Guinea_svg__cls-3"
          data-name="polygon5131"
        />
      </g>
    </g>
  </svg>
);
export default SvgPapuaNewGuinea;
