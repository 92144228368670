import * as React from "react";
import type { SVGProps } from "react";
const SvgTogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Togo_svg__cls-2{fill:#ffce00}.Togo_svg__cls-4{fill:#fff}"}
      </style>
    </defs>
    <g id="Togo_svg__Togo">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#006a4e",
        }}
      />
      <path
        d="M0 144h1280v144H0zM0 432h1280v144H0z"
        className="Togo_svg__cls-2"
      />
      <path
        d="M0 0h432v432H0z"
        style={{
          fill: "#d21034",
        }}
      />
      <g id="Togo_svg__c">
        <path
          id="Togo_svg__t"
          d="m216 79.2-42.27 130.1 68.47 22.25"
          className="Togo_svg__cls-4"
        />
        <path
          id="Togo_svg__t-2"
          d="m216 79.2 42.27 130.1-68.47 22.25"
          className="Togo_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Togo_svg__c-2" data-name="c">
        <path
          id="Togo_svg__t-3"
          d="M346.1 173.73H209.3v72"
          className="Togo_svg__cls-4"
          data-name="t"
        />
        <path
          id="Togo_svg__t-4"
          d="m346.1 173.73-110.67 80.41-42.32-58.25"
          className="Togo_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Togo_svg__c-3" data-name="c">
        <path
          id="Togo_svg__t-5"
          d="m296.41 326.67-42.27-130.1-68.48 22.25"
          className="Togo_svg__cls-4"
          data-name="t"
        />
        <path
          id="Togo_svg__t-6"
          d="m296.41 326.67-110.67-80.41L228.06 188"
          className="Togo_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Togo_svg__c-4" data-name="c">
        <path
          id="Togo_svg__t-7"
          d="m135.59 326.67 110.67-80.41L203.94 188"
          className="Togo_svg__cls-4"
          data-name="t"
        />
        <path
          id="Togo_svg__t-8"
          d="m135.59 326.67 42.27-130.1 68.48 22.25"
          className="Togo_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Togo_svg__c-5" data-name="c">
        <path
          id="Togo_svg__t-9"
          d="m85.9 173.73 110.67 80.41 42.32-58.25"
          className="Togo_svg__cls-4"
          data-name="t"
        />
        <path
          id="Togo_svg__t-10"
          d="M85.9 173.73h136.8v72"
          className="Togo_svg__cls-4"
          data-name="t"
        />
      </g>
    </g>
  </svg>
);
export default SvgTogo;
