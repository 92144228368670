import * as React from "react";
import type { SVGProps } from "react";
const SvgFinland = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Finland_svg__cls-2{fill:#243c7b}"}</style>
    </defs>
    <g id="Finland_svg__Finland">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path d="M0 254.44h1280v213.33H0z" className="Finland_svg__cls-2" />
      <path d="M355.56 0h213.33v720H355.56z" className="Finland_svg__cls-2" />
    </g>
  </svg>
);
export default SvgFinland;
