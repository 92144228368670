import * as React from "react";
import type { SVGProps } from "react";

const SvgMontserrat = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20" {...props}>
    {/* Green Background */}
    <rect width="30" height="20" fill="#009e49" />
    
    {/* Union Jack */}
    <rect x="0" y="0" width="12" height="8" fill="#0033a0" />
    <rect x="1" y="0" width="2" height="8" fill="#ffffff" />
    <rect x="0" y="1" width="12" height="2" fill="#ffffff" />
    <rect x="0" y="3" width="12" height="2" fill="#ffffff" />
    <rect x="0" y="5" width="12" height="2" fill="#ffffff" />
    <rect x="4" y="0" width="2" height="8" fill="#ffffff" />
    <rect x="6" y="0" width="2" height="8" fill="#ffffff" />
    
    {/* Green Rectangle */}
    <rect x="0" y="12" width="30" height="8" fill="#009e49" />
    
    {/* Yellow Star */}
    <polygon points="15,14 16,16 14,16" fill="#f7e300" />
    <polygon points="15,14 15.5,15.5 14.5,15.5" fill="#f7e300" />
    <polygon points="15,14 15.5,15.5 16,15.5" fill="#f7e300" />
  </svg>
);

export default SvgMontserrat;