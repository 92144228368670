import * as React from "react";
import type { SVGProps } from "react";
const SvgLithuania = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <clipPath id="Lithuania_svg__clip-path">
        <path
          d="M-121.59 1334.82c206.34 0 373.59-167.28 373.59-373.61S84.75 587.59-121.59 587.59h783.15C455.22 587.59 288 754.87 288 961.21s167.27 373.61 373.61 373.61Z"
          className="Lithuania_svg__cls-1"
        />
      </clipPath>
      <clipPath id="Lithuania_svg__clip-path-2">
        <path
          d="M613.07 1334.82c206.34 0 373.62-167.28 373.62-373.61S819.41 587.59 613.07 587.59h783.15c-206.34 0-373.61 167.28-373.61 373.62s167.27 373.61 373.61 373.61Z"
          className="Lithuania_svg__cls-1"
        />
      </clipPath>
      <style>
        {".Lithuania_svg__cls-1{fill:none}.Lithuania_svg__cls-3{fill:#ec2024}"}
      </style>
    </defs>
    <g id="Lithuania_svg__Georgia">
      <g
        id="Lithuania_svg__smallcross"
        style={{
          clipPath: "url(#Lithuania_svg__clip-path)",
        }}
      >
        <path
          id="Lithuania_svg__varm"
          d="M72.4 1227c0-109.12 88.46-197.58 197.59-197.58s197.58 88.5 197.58 197.58V695.37C467.57 804.49 379.11 893 270 893S72.4 804.49 72.4 695.37Z"
          className="Lithuania_svg__cls-3"
        />
      </g>
      <g
        id="Lithuania_svg__smallcross-2"
        data-name="smallcross"
        style={{
          clipPath: "url(#Lithuania_svg__clip-path-2)",
        }}
      >
        <path
          id="Lithuania_svg__varm-2"
          d="M807.06 1227c0-109.12 88.47-197.58 197.59-197.58s197.58 88.46 197.58 197.58V695.37c0 109.12-88.46 197.58-197.58 197.58s-197.59-88.46-197.59-197.58Z"
          className="Lithuania_svg__cls-3"
          data-name="varm"
        />
      </g>
    </g>
    <g id="Lithuania_svg__Lithuania">
      <path
        d="M0-.25h1280v720.5H0z"
        style={{
          fill: "#fbb817",
        }}
      />
      <path
        d="M0 360h1280v360.25H0z"
        style={{
          fill: "#c0272d",
        }}
      />
      <path
        d="M0 239.92h1280v240.17H0z"
        style={{
          fill: "#006b44",
        }}
      />
    </g>
  </svg>
);
export default SvgLithuania;
