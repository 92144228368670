import * as React from "react";
import type { SVGProps } from "react";
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.00059 16.0002V11.0002H12.0006V16.0002C12.0006 16.5502 12.4506 17.0002 13.0006 17.0002H16.0006C16.5506 17.0002 17.0006 16.5502 17.0006 16.0002V9.00021H18.7006C19.1606 9.00021 19.3806 8.43021 19.0306 8.13022L10.6706 0.600215C10.2906 0.260215 9.71059 0.260215 9.33059 0.600215L0.970586 8.13022C0.630586 8.43021 0.840586 9.00021 1.30059 9.00021H3.00059V16.0002C3.00059 16.5502 3.45059 17.0002 4.00059 17.0002H7.00059C7.55059 17.0002 8.00059 16.5502 8.00059 16.0002Z" fill="#38BA9C"/>
  </svg>
)
export default SvgHome;


