import * as React from "react";
import type { SVGProps } from "react";
const SvgSlovakia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <clipPath id="Slovakia_svg__clip-path">
        <path
          d="M409.76 542c-59.1-28.47-143.64-85-143.64-196.74s5.35-162.61 5.35-162.61h276.59s5.35 50.83 5.35 162.61S468.87 513.51 409.76 542"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <style>
        {
          ".Slovakia_svg__cls-2{fill:#ec2027}.Slovakia_svg__cls-3{fill:#0e509e}.Slovakia_svg__cls-4{fill:#fff}"
        }
      </style>
    </defs>
    <g id="Slovakia_svg__Slovakia">
      <path d="M0 0h1280v720H0z" className="Slovakia_svg__cls-2" />
      <path d="M0 0h1280v480H0z" className="Slovakia_svg__cls-3" />
      <path d="M0 0h1280v240H0z" className="Slovakia_svg__cls-4" />
      <path
        id="Slovakia_svg__coa"
        d="M409.76 558.15c-64.41-31-156.56-92.62-156.56-214.45s5.8-177.24 5.8-177.24h301.51s5.83 55.41 5.83 177.24-92.15 183.42-156.58 214.45"
        className="Slovakia_svg__cls-4"
      />
      <path
        id="Slovakia_svg__coa-2"
        d="M409.76 542c-59.1-28.47-143.64-85-143.64-196.74s5.35-162.61 5.35-162.61h276.59s5.35 50.83 5.35 162.61S468.87 513.51 409.76 542"
        className="Slovakia_svg__cls-2"
        data-name="coa"
      />
      <path
        id="Slovakia_svg__k"
        d="M422.41 403.61h-25.28v-61.73c-18-.23-49.53-.05-75 8.48 0 0 .74-9.95.74-21.54s-.74-21.54-.74-21.54c27.8 9.31 59 8.68 75 8.42v-38.77c-14.59-.13-35.6.57-59.37 8.52 0 0 .73-9.95.73-21.54s-.73-21.54-.73-21.54c23.73 7.94 44.72 8.65 59.3 8.52-.75-24.55-7.9-55.49-7.9-55.49s14.72 1.15 20.6 1.15Z"
        className="Slovakia_svg__cls-4"
      />
      <path
        id="Slovakia_svg__k-2"
        d="M397.12 403.61h25.28v-61.73c18-.23 49.52-.05 75 8.48 0 0-.74-9.95-.74-21.54s.74-21.54.74-21.54c-27.8 9.31-59 8.68-75 8.42v-38.77c14.58-.13 35.6.57 59.37 8.52 0 0-.74-9.95-.74-21.54s.74-21.54.74-21.54c-23.73 7.94-44.72 8.65-59.3 8.52.74-24.55 7.9-55.49 7.9-55.49s-14.72 1.15-20.61 1.15Z"
        className="Slovakia_svg__cls-4"
        data-name="k"
      />
      <path
        d="M407.16 578.65 284.7 469.49s15.66-50.63 46.22-50.63c24.28 0 33.14 19.58 33.14 19.58s15.94-41.27 45.7-41.27 45.71 41.27 45.71 41.27 8.87-19.58 33.15-19.58c30.56 0 46.21 50.63 46.21 50.63z"
        className="Slovakia_svg__cls-3"
        style={{
          clipPath: "url(#Slovakia_svg__clip-path)",
        }}
      />
    </g>
  </svg>
);
export default SvgSlovakia;
