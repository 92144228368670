import * as React from "react";
import type { SVGProps } from "react";

const SvgChristmasIsland = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 480" {...props}>
    {/* Diagonal green and blue background */}
    <polygon points="0,0 960,480 960,0" fill="#006847" />
    <polygon points="0,0 960,480 0,480" fill="#002395" />

    {/* Southern Cross (five stars in blue section) */}
    <g fill="#fff" transform="scale(2) translate(50, 30)">
      <polygon points="2,2 4,0 6,2 5,4 7,6 5,6 4,8 3,6 1,6 3,4" />
      <polygon points="10,14 12,12 14,14 13,16 15,18 13,18 12,20 11,18 9,18 11,16" />
      <polygon points="8,6 10,4 12,6 11,8 13,10 11,10 10,12 9,10 7,10 9,8" />
      <polygon points="14,2 16,0 18,2 17,4 19,6 17,6 16,8 15,6 13,6 15,4" />
      <polygon points="20,10 22,8 24,10 23,12 25,14 23,14 22,16 21,14 19,14 21,12" />
    </g>

    {/* Golden Bird (Frigatebird) */}
    <g fill="#FFD100" transform="translate(600, 160)">
      <path d="M30,0 C45,-10 75,15 60,30 C60,30 55,15 40,25 C40,25 60,45 50,55 C50,55 25,30 30,10 Z" />
    </g>

    {/* Golden circle and green island */}
    <circle cx="720" cy="240" r="80" fill="#FFD100" />
    <polygon
      points="740,240 720,220 710,230 700,220 690,240 710,260 720,250"
      fill="#006847"
    />
  </svg>
);

export default SvgChristmasIsland;