import * as React from "react";
import type { SVGProps } from "react";
const SvgUruguay = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Uruguay_svg__cls-2{fill:#0038a8}.Uruguay_svg__cls-3,.Uruguay_svg__cls-5{fill:#fcd116}.Uruguay_svg__cls-3,.Uruguay_svg__cls-4,.Uruguay_svg__cls-5{stroke:#000;stroke-miterlimit:20;stroke-width:.6px}.Uruguay_svg__cls-3{stroke-linecap:square}.Uruguay_svg__cls-4{fill:none}"
        }
      </style>
    </defs>
    <g id="Uruguay_svg__Uruguay">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M400 80h880v80H400zM400 240h880v80H400zM0 400h1280v80H0zM0 560h1280v80H0z"
        className="Uruguay_svg__cls-2"
      />
      <g id="Uruguay_svg__t">
        <g id="Uruguay_svg__s">
          <path
            d="m190.94 239.11 13.24 19.77c-54.41 39.38-21.19 62.66-59.75 75.27 24-23.41-3.77-25.38 16.43-74.16"
            className="Uruguay_svg__cls-3"
          />
          <path
            d="M181.48 244.72c-30 49.47-10.33 76.68-37 89.43"
            className="Uruguay_svg__cls-4"
          />
          <path
            d="M200 200h26.4L200 345.2 173.6 200H200v145.2"
            className="Uruguay_svg__cls-5"
          />
        </g>
        <g id="Uruguay_svg__s-2" data-name="s">
          <path
            d="m165.94 221.25-4.62 23.35C95 234 102 273.92 65.85 255.57c33.51.4 15.28-20.62 64.06-40.82"
            className="Uruguay_svg__cls-3"
          />
          <path
            d="M155.28 218.52c-56.21 13.76-61.52 46.92-89.43 37"
            className="Uruguay_svg__cls-4"
          />
          <path
            d="m200 200 18.67 18.67-121.34 84 84-121.34L200 200 97.33 302.67"
            className="Uruguay_svg__cls-5"
          />
        </g>
      </g>
      <g id="Uruguay_svg__u">
        <g id="Uruguay_svg__t-2" data-name="t">
          <g id="Uruguay_svg__s-3" data-name="s">
            <path
              d="m160.89 190.94-19.77 13.24c-39.38-54.41-62.66-21.18-75.27-59.75 23.41 24 25.38-3.77 74.16 16.43"
              className="Uruguay_svg__cls-3"
            />
            <path
              d="M155.28 181.48c-49.47-30-76.68-10.33-89.43-37"
              className="Uruguay_svg__cls-4"
            />
            <path
              d="M200 200v26.4L54.8 200 200 173.6V200H54.8"
              className="Uruguay_svg__cls-5"
            />
          </g>
          <g id="Uruguay_svg__s-4" data-name="s">
            <path
              d="m178.75 165.94-23.35-4.62C166 95 126.08 102 144.43 65.85c-.4 33.51 20.62 15.28 40.82 64.06"
              className="Uruguay_svg__cls-3"
            />
            <path
              d="M181.48 155.28c-13.76-56.21-46.92-61.52-37-89.43"
              className="Uruguay_svg__cls-4"
            />
            <path
              d="m200 200-18.67 18.67-84-121.34 121.34 84L200 200 97.33 97.33"
              className="Uruguay_svg__cls-5"
            />
          </g>
        </g>
      </g>
      <g id="Uruguay_svg__t-3" data-name="t">
        <g id="Uruguay_svg__s-5" data-name="s">
          <path
            d="m209.06 160.89-13.24-19.77c54.41-39.38 21.19-62.66 59.75-75.27-24 23.41 3.77 25.38-16.43 74.16"
            className="Uruguay_svg__cls-3"
          />
          <path
            d="M218.52 155.28c30-49.47 10.33-76.68 37-89.43"
            className="Uruguay_svg__cls-4"
          />
          <path
            d="M200 200h-26.4L200 54.8 226.4 200H200V54.8"
            className="Uruguay_svg__cls-5"
          />
        </g>
        <g id="Uruguay_svg__s-6" data-name="s">
          <path
            d="m234.06 178.75 4.62-23.35C305 166 298 126.08 334.15 144.43c-33.51-.4-15.28 20.62-64.06 40.82"
            className="Uruguay_svg__cls-3"
          />
          <path
            d="M244.72 181.48c56.21-13.76 61.52-46.92 89.43-37"
            className="Uruguay_svg__cls-4"
          />
          <path
            d="m200 200-18.67-18.67 121.34-84-84 121.34L200 200 302.67 97.33"
            className="Uruguay_svg__cls-5"
          />
        </g>
      </g>
      <g id="Uruguay_svg__u-2" data-name="u">
        <g id="Uruguay_svg__t-4" data-name="t">
          <g id="Uruguay_svg__s-7" data-name="s">
            <path
              d="m239.11 209.06 19.77-13.24c39.38 54.41 62.66 21.19 75.27 59.75-23.41-24-25.38 3.77-74.16-16.43"
              className="Uruguay_svg__cls-3"
            />
            <path
              d="M244.72 218.52c49.47 30 76.68 10.33 89.43 37"
              className="Uruguay_svg__cls-4"
            />
            <path
              d="M200 200v-26.4L345.2 200 200 226.4V200h145.2"
              className="Uruguay_svg__cls-5"
            />
          </g>
          <g id="Uruguay_svg__s-8" data-name="s">
            <path
              d="m221.25 234.06 23.35 4.62C234 305 273.92 298 255.57 334.15c.4-33.51-20.62-15.28-40.82-64.06"
              className="Uruguay_svg__cls-3"
            />
            <path
              d="M218.52 244.72c13.76 56.21 46.92 61.52 37 89.43"
              className="Uruguay_svg__cls-4"
            />
            <path
              d="m200 200 18.67-18.67 84 121.34-121.34-84L200 200l102.67 102.67"
              className="Uruguay_svg__cls-5"
            />
          </g>
        </g>
      </g>
      <circle cx={200} cy={200} r={48.4} className="Uruguay_svg__cls-5" />
      <g id="Uruguay_svg__right">
        <path d="M235.64 180.64c-3.08 3.52-4.84-2.64-15.84-2.64s-12.76 6.6-14.52 5.28 9.24-9.28 12.72-9.68 13.64 3.08 17.6 7m-12.76 4c3.08 2.64.44 8.36-2.64 8.36s-8.8-5.28-6.16-8.8" />
        <path d="M208.36 188.56c.44-5.28 4.84-6.16 11.88-6.16s10.12 5.28 12.76 6.6c-3.08 0-5.72-4.4-12.76-4.4s-7 0-11.88 4.4m1.32.88c1.76-2.64 4 2.64 8.8 2.64A16.06 16.06 0 0 0 229 189c3.08-2.2-4.4 5.28-9.24 5.28s-11.44-2.64-10.12-4.4" />
        <path d="M224.64 192.52c5.72-3.08 2.2-7.48 0-8.36.88.88 4.4 5.28 0 8.36M200 218.92c2.64 0 3.52-.88 7-.88s11.88 4.84 16.72 3.08c-10.12 4-6.16 1.32-23.76 1.32h-2.2m27.72 2.64c-1.76-3.08-1.32-2.2-4.84-7 3.52 2.64 4.4 4 4.84 7m-25.48 4.4c11 0 9.24-2.2 23.76-8.36C213.2 222.44 211 226 200 226h-2.2m2.2-12.8c3.08 0 4-2.2 7.48-2.2s8.36 1.32 10.56 3.08c.44.44-1.32-3.52-4.84-4s-2.2-6.16-6.16-7c0 1.76 1.32 1.32 1.76 4 0 2.2-4 2.2-4.84 0 .88 3.52-1.76 3.52-4 3.52" />
      </g>
      <g id="Uruguay_svg__right-2" data-name="right">
        <path d="M164.36 180.64c3.08 3.52 4.84-2.64 15.84-2.64s12.76 6.6 14.52 5.28-9.24-9.28-12.72-9.68-13.64 3.08-17.6 7m12.76 4c-3.08 2.64-.44 8.36 2.64 8.36s8.8-5.28 6.16-8.8" />
        <path d="M191.64 188.56c-.44-5.28-4.84-6.16-11.88-6.16s-10.12 5.28-12.76 6.6c3.08 0 5.72-4.4 12.76-4.4s7 0 11.88 4.4m-1.32.88c-1.76-2.64-4 2.64-8.8 2.64A16.06 16.06 0 0 1 171 189c-3.08-2.2 4.4 5.28 9.24 5.28s11.44-2.64 10.12-4.4" />
        <path d="M175.36 192.52c-5.72-3.08-2.2-7.48 0-8.36-.88.88-4.4 5.28 0 8.36m24.64 26.4c-2.64 0-3.52-.88-7-.88s-11.88 4.84-16.72 3.08c10.12 4 6.16 1.32 23.76 1.32h2.2m-27.72 2.64c1.76-3.08 1.32-2.2 4.84-7-3.52 2.64-4.4 4-4.84 7m25.52 4.4c-11 0-9.24-2.2-23.76-8.36C186.8 222.44 189 226 200 226h2.2m-2.2-12.8c-3.08 0-4-2.2-7.48-2.2s-8.36 1.32-10.56 3.08c-.44.44 1.32-3.52 4.84-4s2.2-6.16 6.16-7c0 1.76-1.32 1.32-1.76 4 0 2.2 4 2.2 4.84 0-.88 3.52 1.76 3.52 4 3.52" />
      </g>
      <path d="M200 233.44c-2.2 0-7.92 1.32 0 1.32s2.2-1.32 0-1.32" />
    </g>
  </svg>
);
export default SvgUruguay;
