import * as React from "react";
import type { SVGProps } from "react";
const SvgTunisia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Tunisia_svg__cls-1{fill:#e70013}.Tunisia_svg__cls-2{fill:#fff}"}
      </style>
    </defs>
    <g id="Tunisia_svg__Tunisia">
      <path d="M0 0h1280v720H0z" className="Tunisia_svg__cls-1" />
      <circle cx={639} cy={360} r={180} className="Tunisia_svg__cls-2" />
      <circle cx={639} cy={360} r={135} className="Tunisia_svg__cls-1" />
      <circle cx={675} cy={360} r={108} className="Tunisia_svg__cls-2" />
      <path
        d="m594 360 146.53-47.61L650 437V283l90.56 124.65Z"
        className="Tunisia_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgTunisia;
