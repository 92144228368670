import * as React from "react";
import type { SVGProps } from "react";
const SvgSierraLeone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <g data-name="Sierra Leone">
      <path
        d="M-1.91 0h1283.82v240H-1.91z"
        style={{
          fill: "#1eb53a",
        }}
      />
      <path
        d="M-1.91 240h1283.82v240H-1.91z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M-1.91 480h1283.82v240H-1.91z"
        style={{
          fill: "#0072c6",
        }}
      />
    </g>
  </svg>
);
export default SvgSierraLeone;
