import * as React from "react";
import type { SVGProps } from "react";
const SvgPortugal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Portugal_svg__cls-1{fill:red}.Portugal_svg__cls-3{fill:#ff0}.Portugal_svg__cls-11,.Portugal_svg__cls-17,.Portugal_svg__cls-18,.Portugal_svg__cls-19,.Portugal_svg__cls-20,.Portugal_svg__cls-21,.Portugal_svg__cls-22,.Portugal_svg__cls-23,.Portugal_svg__cls-24,.Portugal_svg__cls-5,.Portugal_svg__cls-6,.Portugal_svg__cls-7,.Portugal_svg__cls-9{fill:none;stroke:#000}.Portugal_svg__cls-11,.Portugal_svg__cls-5,.Portugal_svg__cls-6,.Portugal_svg__cls-7,.Portugal_svg__cls-9{stroke-linecap:round;stroke-linejoin:round}.Portugal_svg__cls-5,.Portugal_svg__cls-6{stroke-width:.57px}.Portugal_svg__cls-7{stroke-width:.55px}.Portugal_svg__cls-11,.Portugal_svg__cls-9{stroke-width:.57px}.Portugal_svg__cls-13{fill:#fff}.Portugal_svg__cls-16{fill:#039}.Portugal_svg__cls-17,.Portugal_svg__cls-18,.Portugal_svg__cls-19,.Portugal_svg__cls-20,.Portugal_svg__cls-21,.Portugal_svg__cls-22,.Portugal_svg__cls-23,.Portugal_svg__cls-24{stroke-width:.5px}"
        }
      </style>
    </defs>
    <g id="Portugal_svg__Portugal">
      <path
        id="Portugal_svg__path1339"
        d="M512.06 720h767.62V0H512.06z"
        className="Portugal_svg__cls-1"
      />
      <path
        id="Portugal_svg__path1341"
        d="M.32 720h511.74V0H.32Z"
        style={{
          fill: "#060",
        }}
      />
      <path
        id="Portugal_svg__path1343"
        d="M653.14 471.67C598.77 470 349.42 314.39 347.71 289.6l13.77-23c24.73 35.98 279.64 187.4 304.67 181.98l-13 23.08"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1345"
        d="M358.58 263.52c-4.88 13.13 65.07 56.37 149.22 107.56s156.71 82.81 162.09 78.27a48 48 0 0 0 2.44-4.54c-1 1.52-3.47 2-7.3.89-22.73-6.55-82-33.77-155.47-78.3s-137.32-85.58-147.25-103a10.6 10.6 0 0 1-1.09-5.13H361l-2.11 3.7-.29.58Zm295.84 208.85c-.93 1.67-2.64 1.73-5.91 1.37-20.34-2.25-82.06-32.26-155.09-76-85-50.87-155.16-97.22-147.53-109.34l2.07-3.66.41.13c-6.85 20.53 138.5 103.63 147.06 108.93 84.1 52.11 155 82.53 161.27 74.63l-2.28 3.92"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1347"
        d="M358.58 263.52c-4.88 13.13 65.07 56.37 149.22 107.56s156.71 82.81 162.09 78.27a48 48 0 0 0 2.44-4.54c-1 1.52-3.47 2-7.3.89-22.73-6.55-82-33.77-155.46-78.3s-137.33-85.58-147.26-103a10.6 10.6 0 0 1-1.08-5.13H361l-2.11 3.69-.29.58Zm295.84 208.87c-.92 1.67-2.64 1.73-5.91 1.37-20.34-2.25-82-32.26-155.09-76-85-50.87-155.15-97.22-147.54-109.33l2.07-3.66.41.12c-6.84 20.53 138.5 103.64 147.06 108.94 84.1 52.1 155 82.53 161.28 74.62l-2.28 3.93h0Z"
        style={{
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".57px",
        }}
      />
      <path
        id="Portugal_svg__path1349"
        d="M512.62 304.62c54.42-.43 121.58-7.44 160.24-22.85l-8.33-13.53c-22.85 12.65-90.38 21-152.37 22.21-73.32-.68-125.08-7.51-151-24.91L353.29 280c47.67 20.17 96.52 24.46 159.33 24.67"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1351"
        d="M675.1 282.08c-1.33 2.13-26.54 10.82-63.67 17.23a658 658 0 0 1-99 7.16 701 701 0 0 1-94.83-6c-39-6.11-59.09-14.61-66.53-17.61.71-1.42 1.17-2.41 1.85-3.74 21.41 8.53 41.62 13.67 65.3 17.32a695.5 695.5 0 0 0 94.1 6 660 660 0 0 0 98.3-7.3c38.08-6.14 58.88-14 61.8-17.71l2.69 4.64Zm-7.31-13.72c-4.13 3.31-24.69 10.6-60.87 16.38a654 654 0 0 1-94.08 6.6c-37.26 0-67.71-2.47-90.87-5.85-36.74-4.8-56.3-13.45-63.35-16 .7-1.21 1.41-2.42 2.15-3.62 5.48 2.76 24.36 10.42 61.6 15.7 22.9 3.24 53.39 5.66 90.47 5.62a646 646 0 0 0 93.39-6.63c36.37-5 55.94-14.31 58.91-16.64l2.65 4.42"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1353"
        d="M675.1 282.08c-1.33 2.13-26.54 10.82-63.67 17.23a657 657 0 0 1-99 7.16 703 703 0 0 1-94.83-6c-39-6.12-59.1-14.62-66.54-17.62.71-1.42 1.17-2.41 1.86-3.73 21.41 8.52 41.62 13.66 65.3 17.31a695.5 695.5 0 0 0 94.09 6 660 660 0 0 0 98.31-7.3c38.08-6.14 58.88-14 61.8-17.71l2.69 4.64Zm-7.31-13.72c-4.12 3.31-24.69 10.61-60.87 16.39a655.6 655.6 0 0 1-94.07 6.59c-37.27 0-67.72-2.47-90.88-5.85-36.74-4.8-56.3-13.45-63.35-16 .7-1.21 1.42-2.41 2.15-3.62 5.49 2.76 24.36 10.42 61.61 15.7 22.89 3.25 53.39 5.66 90.47 5.63a647 647 0 0 0 93.38-6.64c36.37-5 55.94-14.31 58.91-16.64l2.65 4.42Z"
        className="Portugal_svg__cls-5"
      />
      <path
        id="Portugal_svg__path1355"
        d="M333.9 370.19c33.48 18 107.83 27.08 178.19 27.7 64.06.1 147.52-9.9 178.72-26.44l-.85-18c-9.77 15.26-99.2 29.89-178.57 29.29S358.33 369.87 333.76 354l.14 16.18"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1357"
        d="M692.52 367.6v4.3c-4.7 5.6-34.11 14.08-71 20.05-28.07 4.3-64.67 7.55-110.27 7.55-43.32 0-77.87-3.09-104.66-7.2-42.35-6.17-69.44-17-74.88-20.19v-5c16.33 10.85 60.58 18.8 75.44 21.23 26.62 4.09 61 7.15 104.07 7.15 45.39 0 81.78-3.22 109.68-7.5 26.46-3.82 64.15-13.76 71.57-20.38Zm0-15.29v4.3c-4.69 5.61-34.1 14.08-71 20.05-28.07 4.31-64.67 7.55-110.27 7.55-43.32 0-77.87-3.08-104.66-7.2-42.35-6.16-69.44-17-74.88-20.19v-5c16.34 10.86 60.59 18.8 75.45 21.23 26.62 4.09 61 7.16 104.07 7.16 45.39 0 81.78-3.23 109.67-7.51 26.47-3.82 64.16-13.76 71.58-20.38"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1359"
        d="M692.52 367.6v4.29c-4.7 5.61-34.11 14.09-71 20.06-28.07 4.3-64.67 7.55-110.27 7.55-43.32 0-77.87-3.09-104.66-7.2-42.35-6.17-69.44-17-74.88-20.19v-5c16.34 10.86 60.59 18.81 75.45 21.24 26.62 4.08 61 7.15 104.07 7.15 45.39 0 81.78-3.23 109.67-7.5 26.46-3.82 64.16-13.76 71.58-20.38Zm0-15.29v4.3c-4.69 5.6-34.1 14.08-71 20.05-28.07 4.3-64.67 7.55-110.27 7.55-43.32 0-77.87-3.09-104.66-7.2-42.36-6.17-69.44-17-74.88-20.19v-5c16.34 10.85 60.59 18.8 75.45 21.23 26.62 4.09 61 7.15 104.07 7.15 45.38 0 81.78-3.22 109.67-7.5 26.46-3.82 64.16-13.76 71.58-20.38Z"
        className="Portugal_svg__cls-6"
      />
      <path
        id="Portugal_svg__path1361"
        d="M511.93 468.57c-77-.46-142.95-21-156.88-24.38l10.16 15.91c24.6 10.35 89 25.77 147.46 24.06s109.64-6.23 145.67-23.78l10.41-16.48c-24.55 11.57-108.11 24.55-156.82 24.67"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1363"
        d="M662.25 456.69c-1.53 2.34-3.09 4.65-4.68 6.9-17 6-43.77 12.28-55.07 14.11-23.08 4.76-58.79 8.3-90.5 8.3-68.2-1-124-14.35-150.29-25.74l-2.12-3.64.35-.55 3.59 1.39c46.71 16.71 99.17 23.37 148.86 24.57 31.56.12 63.16-3.61 88.72-8.18 39.19-7.86 55-13.77 59.89-16.46l1.24-.69Zm9-14.89.12.14q-1.73 2.9-3.54 5.87c-9.06 3.24-33.66 10.43-69.58 15.46-23.67 3.22-38.38 6.34-85.46 7.26-88.23-2.24-145.35-19.5-158.92-23.75l-2-3.86c51.13 13.35 103.36 22.67 160.93 23.62 43.08-.92 61.4-4.09 84.87-7.29 41.89-6.51 63-13.41 69.33-15.4l-.28-.36 4.54-1.69"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1365"
        d="M662.25 456.69c-1.53 2.34-3.09 4.65-4.68 6.9-17 6-43.77 12.28-55.07 14.12-23.08 4.75-58.79 8.29-90.5 8.29-68.2-1-124-14.35-150.29-25.74l-2.12-3.64.35-.55 3.59 1.4c46.71 16.7 99.17 23.37 148.86 24.57 31.56.11 63.16-3.62 88.72-8.19 39.19-7.85 55-13.77 59.89-16.45l1.24-.7Zm9-14.89.12.14c-1.15 1.93-2.33 3.9-3.54 5.87-9.06 3.24-33.66 10.44-69.58 15.46-23.67 3.22-38.38 6.35-85.46 7.26-88.19-2.24-145.32-19.53-158.89-23.75l-2-3.86c51.13 13.35 103.36 22.67 160.93 23.62 43.08-.92 61.41-4.09 84.87-7.29 41.89-6.51 63-13.41 69.33-15.4-.08-.11-.17-.23-.28-.36l4.54-1.69Z"
        className="Portugal_svg__cls-7"
      />
      <path
        id="Portugal_svg__path3966"
        d="M672.2 356c.25 50.64-25.66 96.09-46.54 116.14-29.53 28.37-68.69 46.61-114.42 47.45-51.07.94-99.22-32.35-112.13-47-25.25-28.6-45.81-64.9-46.47-113.84 3.13-55.27 24.83-93.78 56.28-120.18s73.34-39.26 108.22-38.34c40.24 1.06 87.25 20.8 119.73 60 21.29 25.68 30.5 53.55 35.33 95.75ZM511.67 182.56c98.07 0 178.73 79.8 178.73 177.66s-80.66 177.66-178.73 177.66-178.06-79.81-178.06-177.66 80-177.66 178.06-177.66"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1371"
        d="M512.15 182c98.16 0 178.22 80.07 178.22 178.23s-80.06 178.19-178.22 178.19-178.21-80.07-178.21-178.24S414 182 512.15 182m0 3.92c95.57 0 174.3 78.45 174.3 174.32s-78.73 174.3-174.3 174.3-174.29-78.44-174.29-174.3 78.72-174.37 174.29-174.37Z"
        className="Portugal_svg__cls-6"
      />
      <path
        id="Portugal_svg__path1375"
        d="M512.29 196.76c89.52 0 163.25 73.48 163.25 163.27s-73.73 163.26-163.25 163.26S349.05 449.81 349.05 360s73.73-163.24 163.24-163.24m0 3.92c87.37 0 159.33 71.71 159.33 159.34s-72 159.34-159.33 159.34S353 447.65 353 360s71.93-159.32 159.29-159.32"
        className="Portugal_svg__cls-6"
      />
      <path
        id="Portugal_svg__path1377"
        d="M517.93 179.16v2h-11.25v-1.91h-3.88V541.4h3.88v-1.68H518v1.74h3.88l-.06-362.29h-3.88Z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1379"
        d="M507 181.23h10.83"
        className="Portugal_svg__cls-6"
      />
      <path
        id="Portugal_svg__path1381"
        d="M507 539.35h10.88"
        className="Portugal_svg__cls-6"
      />
      <path
        id="Portugal_svg__path1385"
        d="M518 179.19h3.88v362.24H518zm-15.16 0h3.92v362.24h-3.92z"
        style={{
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".57px",
        }}
      />
      <path
        id="Portugal_svg__path1387"
        d="M333.29 352.58C355.64 332 438.1 318.67 511.25 319s159.81 15.38 178.65 33.14l.19 15.34c-15.78-14.85-83.1-32.55-178.69-32.75s-165 18-178.11 33.15z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1389"
        d="M333.25 353.3v13.25"
        className="Portugal_svg__cls-9"
      />
      <path
        id="Portugal_svg__path1391"
        d="M690.49 353.84v13.24"
        className="Portugal_svg__cls-9"
      />
      <path
        id="Portugal_svg__path1393"
        d="M512.22 332.59c42.09-.08 82.93 4 115.32 10.29 33.42 6.69 56.94 15 65 24.43V372c-9.7-11.68-41.35-20.25-65.73-25.14-32.14-6.26-72.7-10.29-114.56-10.21-44.17.08-85.29 4.26-117 10.44-25.41 5-59.31 15.06-63.54 24.94v-4.84c2.32-6.83 27.56-17.06 63-24.15 31.91-6.22 73.11-10.33 117.54-10.41Zm0-15.29c42.09-.07 82.92 4 115.32 10.3 33.42 6.68 56.94 15 65 24.43v4.64c-9.7-11.68-41.35-20.24-65.73-25.14-32.14-6.25-72.71-10.29-114.56-10.21-44.17.08-85.1 4.27-116.77 10.44-24.53 4.65-59.81 15.07-63.74 24.95v-4.84c2.32-6.77 28.07-17.36 63-24.15 31.91-6.23 73.11-10.33 117.54-10.42"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1395"
        d="M512.22 332.59c42.09-.08 82.93 4 115.32 10.29 33.42 6.68 56.94 15 65 24.43V372c-9.7-11.69-41.35-20.25-65.74-25.15-32.14-6.25-72.7-10.28-114.56-10.21-44.16.09-85.29 4.27-117 10.45-25.41 5-59.31 15.06-63.54 24.94v-4.84c2.33-6.84 27.57-17.06 63-24.15 31.91-6.22 73.12-10.33 117.54-10.41Zm0-15.29c42.09-.07 82.93 4 115.32 10.3 33.42 6.68 56.94 15 65 24.42v4.65c-9.7-11.68-41.35-20.24-65.73-25.14-32.14-6.25-72.7-10.29-114.56-10.21-44.17.08-85.09 4.27-116.77 10.44-24.53 4.65-59.81 15.07-63.74 24.95v-4.84c2.32-6.77 28.07-17.36 63-24.15 31.91-6.23 73.11-10.33 117.54-10.42Z"
        className="Portugal_svg__cls-6"
      />
      <path
        id="Portugal_svg__path1397"
        d="M511.38 239.33c66.32-.33 124.18 9.28 150.62 22.83l9.66 16.7c-23-12.39-85.42-25.28-160.21-23.36-60.93.38-126 6.71-158.71 24.14l11.53-19.27c26.81-13.91 90-21 147.09-21"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1399"
        d="m663.71 264.87 6.57 10.55"
        style={{
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".57px",
        }}
      />
      <path
        id="Portugal_svg__path1401"
        d="M512.26 253.12c37.83-.1 74.38 2 103.46 7.28 27.07 5 52.89 12.6 56.58 16.67l2.86 5.06c-9-5.87-31.32-12.39-60-17.83s-65.34-7.21-103-7.11c-42.7-.15-75.87 2.1-104.34 7.06-30.11 5.64-51 13.69-56.16 17.51l2.81-5.34c10-5.11 25.9-11.27 52.6-16.07 29.44-5.38 63-7 105.16-7.23Zm0-15.27c36.21-.1 72 1.92 99.81 6.93 22 4.28 43.71 11 51.67 16.9l4.18 6.65c-7.11-7.91-33.9-15.41-57.55-19.6-27.65-4.77-61.91-6.66-98.11-7-38 .1-73.11 2.43-100.35 7.39-26 5-42.77 10.81-49.87 15.38l3.69-5.56c9.79-5.15 25.61-9.89 45.54-13.76 27.45-5 62.78-7.27 101-7.37"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1403"
        d="M512.26 253.12c37.83-.1 74.38 2 103.45 7.28 27.08 5 52.89 12.6 56.59 16.66l2.86 5.06c-9-5.86-31.32-12.39-60-17.83s-65.34-7.21-103-7.11c-42.7-.14-75.87 2.11-104.34 7.07-30.11 5.64-51 13.69-56.15 17.51l2.8-5.35c10-5.1 25.9-11.26 52.6-16.07 29.44-5.38 63-7 105.16-7.22Zm0-15.27c36.21-.1 72 1.92 99.81 6.93 22 4.28 43.71 10.95 51.67 16.9l4.18 6.65c-7.11-7.91-33.9-15.41-57.55-19.61-27.65-4.76-61.91-6.65-98.11-6.95-38 .1-73.1 2.43-100.35 7.39-26 5-42.77 10.8-49.86 15.38l3.68-5.56c9.79-5.15 25.61-9.89 45.54-13.76 27.45-5 62.78-7.27 101-7.37Z"
        className="Portugal_svg__cls-5"
      />
      <path
        id="Portugal_svg__path1405"
        d="M600.78 434.27c-32.79-6.12-65.64-7-88.59-6.74C401.66 428.82 366 450.22 361.6 456.7l-8.26-13.47c28.14-20.39 88.33-31.83 159.45-30.66 36.93.6 68.8 3.06 95.61 8.25z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1407"
        d="m355.18 445.1 5.42 9.34"
        className="Portugal_svg__cls-6"
      />
      <path
        id="Portugal_svg__path1409"
        d="M511.56 425.63c30.74.46 60.92 1.72 90.05 7.16l-2.11 3.72c-27-5-55.89-6.9-87.84-6.75-40.79-.32-82.05 3.49-118 13.8-11.33 3.15-30.1 10.44-32 16.46l-2.1-3.46c.6-3.56 11.95-10.94 33.14-16.9 41.15-11.78 79.63-13.77 118.83-14Zm1.44-15.48c31.86.6 64.76 2.07 96.73 8.41l-2.19 3.88c-28.88-5.74-56.47-7.65-94.35-8.23-40.92.07-84.33 3-123.8 14.47-12.74 3.71-34.73 11.73-35.46 18.08l-2.1-3.72c.48-5.77 19.51-13.29 36.64-18.28 39.77-11.56 83.3-14.53 124.53-14.61"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1411"
        d="M511.56 425.63c30.74.46 60.92 1.72 90.05 7.16l-2.11 3.72c-27-5-55.89-6.9-87.84-6.75-40.79-.32-82.05 3.49-118 13.8-11.33 3.16-30.1 10.44-32 16.46l-2.1-3.46c.61-3.56 11.95-10.94 33.15-16.9 41.14-11.78 79.62-13.77 118.83-14Zm1.44-15.48c31.86.6 64.76 2.07 96.73 8.41l-2.19 3.87c-28.88-5.73-56.47-7.64-94.35-8.22-40.92.07-84.33 3-123.8 14.46-12.74 3.72-34.73 11.74-35.46 18.09l-2.1-3.72c.48-5.77 19.51-13.29 36.64-18.28 39.77-11.56 83.29-14.53 124.53-14.61"
        className="Portugal_svg__cls-7"
      />
      <path
        id="Portugal_svg__path1419"
        d="M362.74 260.14c54.75-.71 307.63 154.52 308.36 183.11l-15.66 24c-7.07-33.19-263.66-184.26-306-181.4l13.28-25.72"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1421"
        d="m670 445.28-13.28 20.61"
        className="Portugal_svg__cls-11"
      />
      <path
        id="Portugal_svg__path1423"
        d="M362.32 261.49 350 284.38"
        className="Portugal_svg__cls-11"
      />
      <path
        id="Portugal_svg__path1425"
        d="M347.89 284.94c10.16-6.89 84.84 26.38 163 73.5 77.94 47.25 152.42 100.65 145.7 110.65l-2.22 3.47-1 .81c.21-.16 1.34-1.53-.11-5.23-3.32-10.93-56.15-53.08-143.81-106-85.45-51-156.67-81.67-163.79-72.89l2.25-4.3ZM672.67 445c6.43-12.69-62.85-64.87-148.73-115.68-87.86-49.8-151.19-79.11-162.76-70.37l-2.57 4.67c0 .26.1-.31.64-.74 2.1-1.83 5.59-1.71 7.16-1.74 19.92.3 76.8 26.48 156.61 72.23 35 20.37 147.72 92.68 147.29 113 0 1.75.15 2.1-.51 3l2.87-4.36"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1427"
        d="M347.89 284.92c10.16-6.89 84.84 26.38 163 73.5 77.94 47.26 152.42 100.66 145.69 110.65l-2.21 3.48-1 .8c.21-.16 1.34-1.53-.11-5.23-3.32-10.93-56.15-53.08-143.81-106-85.45-50.96-156.69-81.67-163.81-72.9zM672.67 445c6.43-12.69-62.85-64.88-148.73-115.69-87.86-49.8-151.19-79.11-162.76-70.37l-2.56 4.68c0 .26.09-.32.63-.74 2.1-1.83 5.59-1.71 7.16-1.74 19.92.3 76.8 26.48 156.61 72.23 35 20.37 147.72 92.68 147.29 113 0 1.75.15 2.11-.51 3l2.87-4.36Z"
        style={{
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".57px",
        }}
      />
      <path
        id="Portugal_svg__path1429"
        d="M405.4 379.82A105.68 105.68 0 0 0 436.82 455c19.47 19.4 45.92 31.64 75.25 31.64a107 107 0 0 0 107-106.67V237.38l-213.73-.26v142.7"
        className="Portugal_svg__cls-13"
      />
      <path
        id="Portugal_svg__path1431"
        d="M405.4 379.82A105.66 105.66 0 0 0 436.82 455c19.46 19.4 45.92 31.64 75.25 31.64a107 107 0 0 0 107-106.68V237.37l-213.73-.25v142.7Z"
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: ".67px",
        }}
      />
      <path
        id="Portugal_svg__path1433"
        d="M409.39 380v.08a101.5 101.5 0 0 0 30.2 72 102.34 102.34 0 0 0 144.78.16 101.5 101.5 0 0 0 30-72.09V241.91H409.46zm163.83-96.74v88l-.08 9.31a66 66 0 0 1-.44 7.62 59.67 59.67 0 0 1-17.54 35.47 61.18 61.18 0 0 1-43.28 17.9c-16.79 0-31.75-7.09-42.89-18.18A61.1 61.1 0 0 1 451 380.29v-97.24l122.29.22"
        className="Portugal_svg__cls-1"
      />
      <path
        id="Portugal_svg__path1435"
        d="M409.39 380v.08a101.53 101.53 0 0 0 30.19 72 102.35 102.35 0 0 0 144.79.16 101.5 101.5 0 0 0 30-72.08V241.91H409.46L409.39 380m163.83-96.74v88l-.07 9.31a66 66 0 0 1-.44 7.62 59.73 59.73 0 0 1-17.54 35.48 61.2 61.2 0 0 1-43.28 17.89c-16.79 0-31.75-7.09-42.89-18.18a61.1 61.1 0 0 1-18-43.09v-97.24l122.29.22Z"
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: ".51px",
        }}
      />
      <g id="Portugal_svg__Layer_5" data-name="Layer 5">
        <g id="Portugal_svg__use3438">
          <path
            id="Portugal_svg__path1485"
            d="M499.06 364.33a15 15 0 0 0 4 10.24 12.8 12.8 0 0 0 19.09 0 15 15 0 0 0 4-10.24V345h-27v19.38"
            className="Portugal_svg__cls-16"
          />
        </g>
      </g>
      <g id="Portugal_svg__Layer_4" data-name="Layer 4">
        <g id="Portugal_svg__use3438-2" data-name="use3438">
          <path
            id="Portugal_svg__path1485-2"
            d="M499.06 364.33a15 15 0 0 0 4 10.24 12.8 12.8 0 0 0 19.09 0 15 15 0 0 0 4-10.24V345h-27v19.38"
            className="Portugal_svg__cls-16"
            data-name="path1485"
          />
        </g>
        <g id="Portugal_svg__use3434">
          <path
            id="Portugal_svg__path1485-3"
            d="M498.93 410.74a15 15 0 0 0 4 10.24A12.79 12.79 0 0 0 522 421a15 15 0 0 0 4-10.25v-19.37h-27l-.05 19.38"
            className="Portugal_svg__cls-16"
            data-name="path1485"
          />
        </g>
      </g>
      <g id="Portugal_svg__Layer_3" data-name="Layer 3">
        <g id="Portugal_svg__use3438-3" data-name="use3438">
          <path
            id="Portugal_svg__path1485-4"
            d="M499.06 364.33a15 15 0 0 0 4 10.24 12.8 12.8 0 0 0 19.09 0 15 15 0 0 0 4-10.24V345h-27v19.38"
            className="Portugal_svg__cls-16"
            data-name="path1485"
          />
        </g>
        <g id="Portugal_svg__use3434-2" data-name="use3434">
          <path
            id="Portugal_svg__path1485-5"
            d="M498.93 410.74a15 15 0 0 0 4 10.24A12.79 12.79 0 0 0 522 421a15 15 0 0 0 4-10.25v-19.37h-27l-.05 19.38"
            className="Portugal_svg__cls-16"
            data-name="path1485"
          />
        </g>
      </g>
      <path
        id="Portugal_svg__path1485-6"
        d="M461.62 364.42a15 15 0 0 0 4 10.24 12.8 12.8 0 0 0 19.09 0 15 15 0 0 0 4-10.24v-19.35h-27l-.05 19.38"
        className="Portugal_svg__cls-16"
        data-name="path1485"
      />
      <path
        id="Portugal_svg__path1485-7"
        d="M536.4 364.4a15 15 0 0 0 4 10.24 12.79 12.79 0 0 0 19.09 0 15 15 0 0 0 4-10.25v-19.34h-27l-.05 19.37"
        className="Portugal_svg__cls-16"
        data-name="path1485"
      />
      <path
        id="Portugal_svg__path1485-8"
        d="M499.05 317.5a15 15 0 0 0 4 10.24 12.8 12.8 0 0 0 19.09 0 15 15 0 0 0 4-10.24v-19.35h-27v19.38"
        className="Portugal_svg__cls-16"
        data-name="path1485"
      />
      <path
        id="Portugal_svg__path1539"
        d="m443.1 254.81.24 6.26h-7.43l.21-6.26h7"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1497"
        d="M422.66 278c.24-9.94 7.29-12.29 7.34-12.33s7.62 2.54 7.59 12.42z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1499"
        d="m416.57 265.83-1.22 11.42h7.45c.07-9.45 7.15-11 7.32-11s7.18 2.09 7.37 11H445l-1.35-11.51-27 .07"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1501"
        d="m416.57 265.84-1.22 11.42h7.45c.07-9.45 7.15-11 7.32-11s7.18 2.09 7.37 11H445l-1.35-11.51-27 .07Z"
        className="Portugal_svg__cls-17"
      />
      <path
        id="Portugal_svg__path1503"
        d="M414.85 277.31h30.5a1.44 1.44 0 0 1 0 2.82h-30.5a1.43 1.43 0 0 1 0-2.82"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1505"
        d="M414.85 277.31h30.5a1.43 1.43 0 0 1 0 2.82h-30.5a1.43 1.43 0 0 1 0-2.82Z"
        className="Portugal_svg__cls-18"
      />
      <path
        id="Portugal_svg__path1507"
        d="M425.93 277.26c0-6 4.07-7.65 4.09-7.65s4.22 1.74 4.25 7.65z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1509"
        d="M425.93 277.26c0-6 4.07-7.65 4.09-7.65s4.22 1.74 4.25 7.65h-8.34"
        className="Portugal_svg__cls-19"
      />
      <path
        id="Portugal_svg__path1511"
        d="M415.49 261.11h29.24a1.28 1.28 0 0 1 0 2.54h-29.24a1.28 1.28 0 0 1 0-2.54"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1513"
        d="M415.49 261.09h29.24a1.28 1.28 0 0 1 0 2.54h-29.24a1.28 1.28 0 0 1 0-2.54Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path1515"
        d="M416.1 263.65h28a1.28 1.28 0 0 1 0 2.53h-28a1.28 1.28 0 0 1 0-2.53"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1517"
        d="M416.1 263.65h28a1.28 1.28 0 0 1 0 2.53h-28a1.28 1.28 0 0 1 0-2.53Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path1535"
        d="M425.25 244.58h2.16v1.56H429v-1.6h2.21v1.59h1.58v-1.6H435v3.62a.93.93 0 0 1-1 .93h-7.77a1 1 0 0 1-1-1v-3.58"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1521"
        d="M425.14 244.59h2.21v1.57H429v-1.6h2.26v1.6h1.62v-1.6h2.26v3.62a.94.94 0 0 1-1 .93h-7.94a1 1 0 0 1-1-.94v-3.58Z"
        className="Portugal_svg__cls-21"
      />
      <path
        id="Portugal_svg__path1523"
        d="M433.46 249.43 434 261h-7.75l.51-11.62h6.74"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path1525"
        d="M433.46 249.43 434 261h-7.75l.51-11.61h6.74"
        className="Portugal_svg__cls-22"
      />
      <path
        id="Portugal_svg__path1535-2"
        d="M434.75 250.27h2.16v1.57h1.58v-1.61h2.21v1.6h1.58v-1.61h2.22v3.62a.92.92 0 0 1-1 .93h-7.77a1 1 0 0 1-1-1v-3.57"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path1541"
        d="m443.1 254.81.24 6.26h-7.43l.21-6.26Z"
        className="Portugal_svg__cls-23"
      />
      <path
        id="Portugal_svg__path1537"
        d="M434.75 250.26h2.16v1.57h1.58v-1.61h2.21v1.6h1.58v-1.6h2.22v3.62a.92.92 0 0 1-1 .93h-7.77a1 1 0 0 1-1-.94v-3.58Z"
        className="Portugal_svg__cls-24"
      />
      <path
        id="Portugal_svg__path1535-3"
        d="M415.65 250.27h2.16v1.57h1.58v-1.61h2.22v1.6h1.58v-1.61h2.21v3.62a.93.93 0 0 1-1 .93h-7.78a1 1 0 0 1-1-1v-3.57"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path1537-2"
        d="M415.65 250.27h2.16v1.57h1.58v-1.61h2.22v1.6h1.58v-1.6h2.21v3.62a.93.93 0 0 1-1 .93h-7.78a1 1 0 0 1-1-.94v-3.58Z"
        className="Portugal_svg__cls-24"
        data-name="path1537"
      />
      <path
        id="Portugal_svg__path1539-2"
        d="m424 254.81.23 6.26h-7.42l.21-6.26h7"
        className="Portugal_svg__cls-3"
        data-name="path1539"
      />
      <path
        id="Portugal_svg__path1541-2"
        d="m424 254.81.23 6.26h-7.42l.21-6.26Z"
        className="Portugal_svg__cls-23"
        data-name="path1541"
      />
      <path
        id="Portugal_svg__path1543"
        d="M429.33 253.1a.78.78 0 1 1 1.56 0v2.77h-1.56z"
      />
      <path
        id="Portugal_svg__path1547"
        d="M438.89 257.11a.75.75 0 1 1 1.49 0v2.14h-1.49z"
      />
      <path
        id="Portugal_svg__path1547-2"
        d="M419.74 257.11a.75.75 0 1 1 1.49 0v2.14h-1.49z"
        data-name="path1547"
      />
      <path
        id="Portugal_svg__path1539-3"
        d="m525.38 254.81.24 6.26h-7.43l.21-6.26h7"
        className="Portugal_svg__cls-3"
        data-name="path1539"
      />
      <path
        id="Portugal_svg__path5168"
        d="M504.94 278c.24-9.94 7.29-12.29 7.34-12.33s7.62 2.54 7.6 12.42z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5170"
        d="m498.86 265.83-1.23 11.42h7.45c.07-9.45 7.16-11 7.33-11s7.18 2.09 7.36 11h7.48l-1.35-11.51-27 .07"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5172"
        d="m498.86 265.84-1.23 11.42h7.45c.07-9.45 7.16-11 7.33-11s7.18 2.09 7.36 11h7.48l-1.35-11.51-27 .07Z"
        className="Portugal_svg__cls-17"
      />
      <path
        id="Portugal_svg__path5174"
        d="M497.13 277.31h30.5a1.43 1.43 0 0 1 0 2.82h-30.5a1.43 1.43 0 0 1 0-2.82"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5176"
        d="M497.13 277.31h30.5a1.43 1.43 0 0 1 0 2.82h-30.5a1.43 1.43 0 0 1 0-2.82Z"
        className="Portugal_svg__cls-18"
      />
      <path
        id="Portugal_svg__path5178"
        d="M508.22 277.26c0-6 4.07-7.65 4.09-7.65s4.21 1.74 4.25 7.65z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5180"
        d="M508.22 277.26c0-6 4.07-7.65 4.09-7.65s4.21 1.74 4.25 7.65h-8.34"
        className="Portugal_svg__cls-19"
      />
      <path
        id="Portugal_svg__path5182"
        d="M497.78 261.11H527a1.28 1.28 0 0 1 0 2.54h-29.22a1.28 1.28 0 0 1 0-2.54"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5184"
        d="M497.78 261.09H527a1.28 1.28 0 0 1 0 2.54h-29.22a1.28 1.28 0 0 1 0-2.54Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path5186"
        d="M498.39 263.65h28a1.28 1.28 0 0 1 0 2.53h-28a1.28 1.28 0 0 1 0-2.53"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5188"
        d="M498.39 263.65h28a1.28 1.28 0 0 1 0 2.53h-28a1.28 1.28 0 0 1 0-2.53Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path1535-4"
        d="M507.53 244.58h2.16v1.56h1.58v-1.6h2.21v1.59h1.58v-1.6h2.22v3.62a.92.92 0 0 1-1 .93h-7.77a1 1 0 0 1-1-1v-3.58"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path5192"
        d="M507.42 244.59h2.21v1.57h1.61v-1.6h2.27v1.6h1.61v-1.6h2.26v3.62a.94.94 0 0 1-1 .93h-7.94a1 1 0 0 1-1-.94v-3.58Z"
        className="Portugal_svg__cls-21"
      />
      <path
        id="Portugal_svg__path5194"
        d="m515.74 249.43.5 11.61h-7.75l.51-11.64h6.73"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5196"
        d="m515.74 249.43.5 11.61h-7.75l.51-11.64h6.73"
        className="Portugal_svg__cls-22"
      />
      <path
        id="Portugal_svg__path1535-5"
        d="M517 250.27h2.16v1.57h1.58v-1.61H523v1.6h1.59v-1.61h2.21v3.62a.93.93 0 0 1-1 .93H518a1 1 0 0 1-1-1v-3.57"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path1541-3"
        d="m525.39 254.81.23 6.26h-7.42l.21-6.26Z"
        className="Portugal_svg__cls-23"
        data-name="path1541"
      />
      <path
        id="Portugal_svg__path1537-3"
        d="M517 250.26h2.16v1.57h1.58v-1.61H523v1.6h1.59v-1.6h2.21v3.62a.92.92 0 0 1-1 .93H518a1 1 0 0 1-1-.94v-3.58Z"
        className="Portugal_svg__cls-24"
        data-name="path1537"
      />
      <path
        id="Portugal_svg__path5204"
        d="M497.94 250.27h2.16v1.57h1.58v-1.61h2.21v1.6h1.58v-1.61h2.22v3.62a.92.92 0 0 1-1 .93H499a1 1 0 0 1-1-1v-3.57"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5206"
        d="M497.94 250.27h2.16v1.57h1.58v-1.61h2.21v1.6h1.58v-1.6h2.22v3.62a.92.92 0 0 1-1 .93H499a1 1 0 0 1-1-.94v-3.58Z"
        className="Portugal_svg__cls-24"
      />
      <path
        id="Portugal_svg__path5208"
        d="m506.29 254.81.24 6.26h-7.43l.21-6.26h7"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5210"
        d="m506.29 254.81.24 6.26h-7.43l.21-6.26Z"
        className="Portugal_svg__cls-23"
      />
      <path
        id="Portugal_svg__path5212"
        d="M511.62 253.1a.78.78 0 1 1 1.56 0v2.77h-1.56z"
      />
      <path
        id="Portugal_svg__path1547-3"
        d="M521.18 257.11a.74.74 0 1 1 1.48 0v2.14h-1.48z"
        data-name="path1547"
      />
      <path
        id="Portugal_svg__path5216"
        d="M502 257.11a.75.75 0 1 1 1.49 0v2.14H502z"
      />
      <path
        id="Portugal_svg__path1539-4"
        d="m606.75 254.81.24 6.26h-7.43l.21-6.26h7"
        className="Portugal_svg__cls-3"
        data-name="path1539"
      />
      <path
        id="Portugal_svg__path5168-2"
        d="M586.31 278c.24-9.94 7.29-12.29 7.34-12.33s7.62 2.54 7.59 12.42z"
        className="Portugal_svg__cls-3"
        data-name="path5168"
      />
      <path
        id="Portugal_svg__path5170-2"
        d="M580.22 265.83 579 277.25h7.45c.07-9.45 7.15-11 7.32-11s7.18 2.09 7.37 11h7.47l-1.35-11.51-27 .07"
        className="Portugal_svg__cls-3"
        data-name="path5170"
      />
      <path
        id="Portugal_svg__path5172-2"
        d="M580.22 265.84 579 277.26h7.45c.07-9.45 7.15-11 7.32-11s7.18 2.09 7.37 11h7.47l-1.35-11.51-27 .07Z"
        className="Portugal_svg__cls-17"
        data-name="path5172"
      />
      <path
        id="Portugal_svg__path5174-2"
        d="M578.5 277.31H609a1.44 1.44 0 0 1 0 2.82h-30.5a1.43 1.43 0 0 1 0-2.82"
        className="Portugal_svg__cls-3"
        data-name="path5174"
      />
      <path
        id="Portugal_svg__path5176-2"
        d="M578.5 277.31H609a1.43 1.43 0 0 1 0 2.82h-30.5a1.43 1.43 0 0 1 0-2.82Z"
        className="Portugal_svg__cls-18"
        data-name="path5176"
      />
      <path
        id="Portugal_svg__path5178-2"
        d="M589.58 277.26c0-6 4.08-7.65 4.1-7.65s4.21 1.74 4.24 7.65z"
        className="Portugal_svg__cls-3"
        data-name="path5178"
      />
      <path
        id="Portugal_svg__path5180-2"
        d="M589.58 277.26c0-6 4.08-7.65 4.1-7.65s4.21 1.74 4.24 7.65h-8.34"
        className="Portugal_svg__cls-19"
        data-name="path5180"
      />
      <path
        id="Portugal_svg__path5182-2"
        d="M579.14 261.11h29.24a1.28 1.28 0 0 1 0 2.54h-29.24a1.28 1.28 0 0 1 0-2.54"
        className="Portugal_svg__cls-3"
        data-name="path5182"
      />
      <path
        id="Portugal_svg__path5184-2"
        d="M579.14 261.09h29.24a1.28 1.28 0 0 1 0 2.54h-29.24a1.28 1.28 0 0 1 0-2.54Z"
        className="Portugal_svg__cls-20"
        data-name="path5184"
      />
      <path
        id="Portugal_svg__path5186-2"
        d="M579.75 263.65h28a1.28 1.28 0 0 1 0 2.53h-28a1.28 1.28 0 0 1 0-2.53"
        className="Portugal_svg__cls-3"
        data-name="path5186"
      />
      <path
        id="Portugal_svg__path5188-2"
        d="M579.75 263.65h28a1.28 1.28 0 0 1 0 2.53h-28a1.28 1.28 0 0 1 0-2.53Z"
        className="Portugal_svg__cls-20"
        data-name="path5188"
      />
      <path
        id="Portugal_svg__path1535-6"
        d="M588.9 244.58h2.16v1.56h1.58v-1.6h2.21v1.59h1.58v-1.6h2.22v3.62a.93.93 0 0 1-1 .93h-7.77a1 1 0 0 1-1-1v-3.58"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path5192-2"
        d="M588.79 244.59H591v1.57h1.61v-1.6h2.26v1.6h1.62v-1.6h2.26v3.62a.94.94 0 0 1-1 .93h-7.94a1 1 0 0 1-1-.94v-3.58Z"
        className="Portugal_svg__cls-21"
        data-name="path5192"
      />
      <path
        id="Portugal_svg__path5194-2"
        d="m597.11 249.43.49 11.57h-7.74l.51-11.62h6.74"
        className="Portugal_svg__cls-3"
        data-name="path5194"
      />
      <path
        id="Portugal_svg__path5196-2"
        d="m597.11 249.43.49 11.57h-7.74l.51-11.61h6.74"
        className="Portugal_svg__cls-22"
        data-name="path5196"
      />
      <path
        id="Portugal_svg__path1535-7"
        d="M598.4 250.27h2.16v1.57h1.58v-1.61h2.21v1.6h1.58v-1.61h2.22v3.62a.92.92 0 0 1-1 .93h-7.77a1 1 0 0 1-1-1v-3.57"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path1541-4"
        d="m606.75 254.81.24 6.26h-7.43l.21-6.26Z"
        className="Portugal_svg__cls-23"
        data-name="path1541"
      />
      <path
        id="Portugal_svg__path1537-4"
        d="M598.4 250.26h2.16v1.57h1.58v-1.61h2.21v1.6h1.58v-1.6h2.22v3.62a.92.92 0 0 1-1 .93h-7.77a1 1 0 0 1-1-.94v-3.58Z"
        className="Portugal_svg__cls-24"
        data-name="path1537"
      />
      <path
        id="Portugal_svg__path5204-2"
        d="M579.3 250.27h2.16v1.57H583v-1.61h2.22v1.6h1.58v-1.61h2.21v3.62a.93.93 0 0 1-1 .93h-7.77a1 1 0 0 1-1-1v-3.57"
        className="Portugal_svg__cls-3"
        data-name="path5204"
      />
      <path
        id="Portugal_svg__path5206-2"
        d="M579.3 250.27h2.16v1.57H583v-1.61h2.22v1.6h1.58v-1.6h2.21v3.62a.93.93 0 0 1-1 .93h-7.77a1 1 0 0 1-1-.94v-3.58Z"
        className="Portugal_svg__cls-24"
        data-name="path5206"
      />
      <path
        id="Portugal_svg__path5208-2"
        d="m587.66 254.81.23 6.26h-7.42l.21-6.26h7"
        className="Portugal_svg__cls-3"
        data-name="path5208"
      />
      <path
        id="Portugal_svg__path5210-2"
        d="m587.66 254.81.23 6.26h-7.42l.21-6.26Z"
        className="Portugal_svg__cls-23"
        data-name="path5210"
      />
      <path
        id="Portugal_svg__path5212-2"
        d="M593 253.1a.78.78 0 1 1 1.56 0v2.77H593z"
        data-name="path5212"
      />
      <path
        id="Portugal_svg__path1547-4"
        d="M602.54 257.11a.75.75 0 1 1 1.49 0v2.14h-1.49z"
        data-name="path1547"
      />
      <path
        id="Portugal_svg__path5216-2"
        d="M583.39 257.11a.75.75 0 1 1 1.49 0v2.14h-1.49z"
        data-name="path5216"
      />
      <path
        id="Portugal_svg__path1539-5"
        d="m443.1 338.18.24 6.26h-7.43l.21-6.27h7"
        className="Portugal_svg__cls-3"
        data-name="path1539"
      />
      <path
        id="Portugal_svg__path5168-3"
        d="M422.66 361.35c.24-9.94 7.29-12.29 7.34-12.33s7.62 2.54 7.59 12.42z"
        className="Portugal_svg__cls-3"
        data-name="path5168"
      />
      <path
        id="Portugal_svg__path5170-3"
        d="m416.57 349.2-1.22 11.42h7.45c.07-9.45 7.15-11 7.32-11s7.18 2.09 7.37 11H445l-1.35-11.51-27 .07"
        className="Portugal_svg__cls-3"
        data-name="path5170"
      />
      <path
        id="Portugal_svg__path5172-3"
        d="m416.57 349.22-1.22 11.42h7.45c.07-9.44 7.15-11 7.32-11s7.18 2.09 7.37 11H445l-1.35-11.5-27 .06Z"
        className="Portugal_svg__cls-17"
        data-name="path5172"
      />
      <path
        id="Portugal_svg__path5174-3"
        d="M414.85 360.68h30.5a1.31 1.31 0 0 1 1.16 1.42 1.3 1.3 0 0 1-1.16 1.4h-30.5a1.3 1.3 0 0 1-1.17-1.4 1.31 1.31 0 0 1 1.17-1.42"
        className="Portugal_svg__cls-3"
        data-name="path5174"
      />
      <path
        id="Portugal_svg__path5176-3"
        d="M414.85 360.68h30.5a1.32 1.32 0 0 1 1.17 1.42 1.31 1.31 0 0 1-1.17 1.4h-30.5a1.3 1.3 0 0 1-1.17-1.4 1.31 1.31 0 0 1 1.17-1.42Z"
        className="Portugal_svg__cls-18"
        data-name="path5176"
      />
      <path
        id="Portugal_svg__path5178-3"
        d="M425.93 360.63c0-6 4.07-7.65 4.09-7.65s4.22 1.74 4.25 7.65z"
        className="Portugal_svg__cls-3"
        data-name="path5178"
      />
      <path
        id="Portugal_svg__path5180-3"
        d="M425.93 360.63c0-6 4.07-7.65 4.09-7.65s4.22 1.74 4.25 7.65h-8.34"
        className="Portugal_svg__cls-19"
        data-name="path5180"
      />
      <path
        id="Portugal_svg__path5182-3"
        d="M415.49 344.48h29.24a1.28 1.28 0 0 1 0 2.54h-29.24a1.28 1.28 0 0 1 0-2.54"
        className="Portugal_svg__cls-3"
        data-name="path5182"
      />
      <path
        id="Portugal_svg__path5184-3"
        d="M415.49 344.47h29.24a1.27 1.27 0 0 1 0 2.53h-29.24a1.27 1.27 0 0 1 0-2.53Z"
        className="Portugal_svg__cls-20"
        data-name="path5184"
      />
      <path
        id="Portugal_svg__path5186-3"
        d="M416.1 347h28a1.29 1.29 0 0 1 0 2.54h-28a1.29 1.29 0 0 1 0-2.54"
        className="Portugal_svg__cls-3"
        data-name="path5186"
      />
      <path
        id="Portugal_svg__path5188-3"
        d="M416.1 347h28a1.29 1.29 0 0 1 0 2.54h-28a1.29 1.29 0 0 1 0-2.54Z"
        className="Portugal_svg__cls-20"
        data-name="path5188"
      />
      <path
        id="Portugal_svg__path1535-8"
        d="M425.25 328h2.16v1.57H429v-1.61h2.21v1.6h1.58v-1.61H435v3.62a.93.93 0 0 1-1 .93h-7.77a1 1 0 0 1-1-1V328"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path5192-3"
        d="M425.14 328h2.21v1.57H429v-1.6h2.26v1.59h1.62v-1.6h2.26v3.62a.94.94 0 0 1-1 .94h-7.94a1 1 0 0 1-1-.95V328Z"
        className="Portugal_svg__cls-21"
        data-name="path5192"
      />
      <path
        id="Portugal_svg__path5194-3"
        d="m433.46 332.8.5 11.62h-7.75l.51-11.61h6.74"
        className="Portugal_svg__cls-3"
        data-name="path5194"
      />
      <path
        id="Portugal_svg__path5196-3"
        d="m433.46 332.8.5 11.61h-7.75l.51-11.62h6.74"
        className="Portugal_svg__cls-22"
        data-name="path5196"
      />
      <path
        id="Portugal_svg__path1535-9"
        d="M434.75 333.65h2.16v1.57h1.58v-1.6h2.21v1.6h1.58v-1.6h2.22v3.62a.92.92 0 0 1-1 .94h-7.77a1 1 0 0 1-1-.95v-3.58"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path1541-5"
        d="m443.1 338.18.24 6.26h-7.43l.21-6.27Z"
        className="Portugal_svg__cls-23"
        data-name="path1541"
      />
      <path
        id="Portugal_svg__path1537-5"
        d="M434.75 333.63h2.16v1.57h1.58v-1.6h2.21v1.6h1.58v-1.6h2.22v3.62a.93.93 0 0 1-1 .94h-7.77a1 1 0 0 1-1-1v-3.58Z"
        className="Portugal_svg__cls-24"
        data-name="path1537"
      />
      <path
        id="Portugal_svg__path5204-3"
        d="M415.65 333.65h2.16v1.57h1.58v-1.6h2.22v1.6h1.58v-1.6h2.21v3.62a.93.93 0 0 1-1 .94h-7.78a1 1 0 0 1-1-.95v-3.58"
        className="Portugal_svg__cls-3"
        data-name="path5204"
      />
      <path
        id="Portugal_svg__path5206-3"
        d="M415.65 333.65h2.16v1.57h1.58v-1.6h2.22v1.6h1.58v-1.6h2.21v3.62a.93.93 0 0 1-1 .94h-7.78a1 1 0 0 1-1-.95v-3.58Z"
        className="Portugal_svg__cls-24"
        data-name="path5206"
      />
      <path
        id="Portugal_svg__path5208-3"
        d="m424 338.18.23 6.26h-7.42l.21-6.27h7"
        className="Portugal_svg__cls-3"
        data-name="path5208"
      />
      <path
        id="Portugal_svg__path5210-3"
        d="m424 338.18.23 6.26h-7.42l.21-6.27Z"
        className="Portugal_svg__cls-23"
        data-name="path5210"
      />
      <path
        id="Portugal_svg__path5212-3"
        d="M429.33 336.47a.78.78 0 1 1 1.56 0v2.77h-1.56z"
        data-name="path5212"
      />
      <path
        id="Portugal_svg__path1547-5"
        d="M438.89 340.49a.75.75 0 1 1 1.49 0v2.14h-1.49z"
        data-name="path1547"
      />
      <path
        id="Portugal_svg__path5216-3"
        d="M419.74 340.49a.75.75 0 1 1 1.49 0v2.14h-1.49z"
        data-name="path5216"
      />
      <path
        id="Portugal_svg__path1539-6"
        d="m589.45 435.81-4.25 4.6-5.26-5.25 4.58-4.28z"
        className="Portugal_svg__cls-3"
        data-name="path1539"
      />
      <path
        id="Portugal_svg__path5330"
        d="M558.62 437.74c7.2-6.86 13.85-3.53 13.91-3.52s3.59 7.17-3.41 14.14z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5332"
        d="m562.91 424.84-8.91 7.21 5.26 5.29c6.73-6.63 12.85-2.74 12.95-2.59s3.6 6.55-2.56 13l5.28 5.28 7.18-9.09-19.16-19.07"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5334"
        d="m562.89 424.86-8.94 7.2 5.26 5.29c6.73-6.63 12.85-2.74 12.95-2.59s3.6 6.55-2.56 13l5.28 5.28 7.18-9.09-19.16-19.09Z"
        className="Portugal_svg__cls-17"
      />
      <path
        id="Portugal_svg__path5336"
        d="m553.57 431.74 21.56 21.57a1.43 1.43 0 0 1-2 2l-21.57-21.57a1.3 1.3 0 0 1 .17-1.81 1.32 1.32 0 0 1 1.83-.18"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5338"
        d="m553.57 431.74 21.56 21.57a1.43 1.43 0 0 1-2 2l-21.57-21.57a1.3 1.3 0 0 1 .17-1.81 1.32 1.32 0 0 1 1.84-.19Z"
        className="Portugal_svg__cls-18"
      />
      <path
        id="Portugal_svg__path5340"
        d="M561.44 439.55c4.24-4.2 8.29-2.53 8.31-2.52s1.74 4.21-2.41 8.41z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5342"
        d="M561.44 439.55c4.24-4.2 8.29-2.53 8.31-2.52s1.75 4.21-2.41 8.41l-5.9-5.89"
        className="Portugal_svg__cls-19"
      />
      <path
        id="Portugal_svg__path5344"
        d="m565.48 420.75 20.67 20.67a1.28 1.28 0 0 1-1.79 1.8l-20.67-20.68a1.27 1.27 0 0 1 1.79-1.79"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5346"
        d="m565.49 420.73 20.68 20.68a1.28 1.28 0 0 1-1.8 1.79l-20.67-20.67a1.28 1.28 0 0 1 1.79-1.8Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path5348"
        d="m564.12 423 19.77 19.78a1.29 1.29 0 0 1-1.79 1.8l-19.78-19.78a1.29 1.29 0 0 1 1.8-1.8"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5350"
        d="m564.12 423 19.77 19.78a1.28 1.28 0 0 1-1.79 1.79l-19.78-19.77a1.29 1.29 0 0 1 1.8-1.8Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path1535-10"
        d="m584.06 416 1.53 1.53-1.11 1.11 1.11 1.11 1.14-1.13 1.56 1.57-1.13 1.13 1.12 1.12 1.13-1.14 1.57 1.57-2.57 2.56a.93.93 0 0 1-1.34 0l-5.49-5.5a1 1 0 0 1 0-1.38l2.52-2.53"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path5354"
        d="m584 415.88 1.56 1.57-1.11 1.11 1.14 1.14 1.14-1.14 1.59 1.61-1.13 1.13 1.14 1.14 1.14-1.13 1.6 1.6-2.56 2.55a.94.94 0 0 1-1.36 0l-5.62-5.62a1 1 0 0 1 0-1.39l2.52-2.54Z"
        className="Portugal_svg__cls-21"
      />
      <path
        id="Portugal_svg__path5356"
        d="m586.44 425.19-7.86 8.56-5.46-5.49 8.58-7.85z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5358"
        d="m586.44 425.19-7.86 8.56-5.45-5.49 8.57-7.85 4.74 4.78"
        className="Portugal_svg__cls-22"
      />
      <path
        id="Portugal_svg__path1535-11"
        d="m586.76 426.7 1.52 1.53-1.11 1.11 1.12 1.11 1.13-1.13 1.56 1.57-1.13 1.11 1.12 1.12 1.13-1.12 1.57 1.56-2.56 2.56a.93.93 0 0 1-1.35 0l-5.49-5.49a1 1 0 0 1 0-1.39l2.53-2.53"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path1541-6"
        d="m589.46 435.81-4.26 4.6-5.26-5.25 4.58-4.28 4.94 4.94Z"
        className="Portugal_svg__cls-23"
        data-name="path1541"
      />
      <path
        id="Portugal_svg__path1537-6"
        d="m586.77 426.69 1.52 1.53-1.11 1.11 1.12 1.12 1.14-1.14 1.56 1.57-1.14 1.12 1.12 1.12 1.14-1.14 1.56 1.57-2.56 2.56a.93.93 0 0 1-1.34 0l-5.5-5.5a1 1 0 0 1 0-1.38l2.53-2.53Z"
        className="Portugal_svg__cls-24"
        data-name="path1537"
      />
      <path
        id="Portugal_svg__path5366"
        d="m573.25 413.2 1.53 1.53-1.11 1.11 1.12 1.12 1.13-1.14 1.56 1.57-1.13 1.13 1.12 1.12 1.13-1.13 1.57 1.56-2.57 2.56a.93.93 0 0 1-1.34 0l-5.49-5.5a1 1 0 0 1 0-1.38l2.52-2.53"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5368"
        d="m573.25 413.2 1.53 1.53-1.11 1.11 1.11 1.12 1.14-1.14 1.56 1.57-1.13 1.13 1.12 1.12 1.13-1.13 1.57 1.56-2.57 2.56a.93.93 0 0 1-1.34 0l-5.49-5.5a1 1 0 0 1 0-1.38l2.52-2.53Z"
        className="Portugal_svg__cls-24"
      />
      <path
        id="Portugal_svg__path5370"
        d="m576 422.31-4.25 4.6-5.26-5.26 4.58-4.27z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5372"
        d="m576 422.31-4.25 4.6-5.26-5.25 4.58-4.28z"
        className="Portugal_svg__cls-23"
      />
      <path
        id="Portugal_svg__path5374"
        d="M580.93 424.87A.78.78 0 1 1 582 426l-1.95 2-1.11-1.1 2-2"
      />
      <path
        id="Portugal_svg__path1547-6"
        d="M584.85 434.47a.75.75 0 1 1 1 1l-1.46 1.53-1-1 1.51-1.51"
        data-name="path1547"
      />
      <path
        id="Portugal_svg__path5378"
        d="M571.31 420.92a.75.75 0 1 1 1.05 1.06l-1.51 1.51-1.05-1.05z"
      />
      <path
        id="Portugal_svg__path1539-7"
        d="m606.63 338.19.23 6.26h-7.42l.21-6.26h7"
        className="Portugal_svg__cls-3"
        data-name="path1539"
      />
      <path
        id="Portugal_svg__path5222"
        d="M586.18 361.35c.25-9.94 7.3-12.29 7.35-12.32s7.61 2.53 7.59 12.41z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5224"
        d="m580.1 349.2-1.23 11.43h7.45c.08-9.45 7.16-11 7.33-11s7.18 2.08 7.37 11h7.47l-1.35-11.51-27 .07"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5226"
        d="m580.1 349.22-1.23 11.42h7.45c.08-9.45 7.16-11 7.33-11s7.18 2.09 7.37 11h7.47l-1.35-11.51-27 .07Z"
        className="Portugal_svg__cls-17"
      />
      <path
        id="Portugal_svg__path5228"
        d="M578.37 360.69h30.5a1.43 1.43 0 0 1 0 2.81h-30.5a1.43 1.43 0 0 1 0-2.81"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5230"
        d="M578.37 360.69h30.5a1.43 1.43 0 0 1 0 2.81h-30.5a1.43 1.43 0 0 1 0-2.81Z"
        className="Portugal_svg__cls-18"
      />
      <path
        id="Portugal_svg__path5232"
        d="M589.46 360.63c0-6 4.07-7.65 4.09-7.65s4.21 1.74 4.25 7.65z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5234"
        d="M589.46 360.63c0-6 4.07-7.65 4.09-7.65s4.22 1.74 4.25 7.65h-8.34"
        className="Portugal_svg__cls-19"
      />
      <path
        id="Portugal_svg__path5236"
        d="M579 344.49h29.24a1.27 1.27 0 0 1 0 2.53H579a1.27 1.27 0 0 1 0-2.53"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5238"
        d="M579 344.47h29.24a1.28 1.28 0 0 1 0 2.54H579a1.28 1.28 0 0 1 0-2.54Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path5240"
        d="M579.63 347h28a1.29 1.29 0 0 1 0 2.54h-28a1.29 1.29 0 0 1 0-2.54"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5242"
        d="M579.63 347h28a1.29 1.29 0 0 1 0 2.54h-28a1.29 1.29 0 0 1 0-2.54Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path1535-12"
        d="M588.77 328h2.16v1.57h1.58v-1.6h2.21v1.6h1.59v-1.6h2.21v3.62a.93.93 0 0 1-1 .94h-7.77a1 1 0 0 1-1-.95V328"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path5246"
        d="M588.67 328h2.2v1.56h1.62v-1.6h2.26v1.6h1.61v-1.61h2.27v3.62a.94.94 0 0 1-1 .94h-7.93a1 1 0 0 1-1-.95V328Z"
        className="Portugal_svg__cls-21"
      />
      <path
        id="Portugal_svg__path5248"
        d="m597 332.8.5 11.62h-7.74l.51-11.61H597"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5250"
        d="m597 332.8.5 11.62h-7.74l.51-11.62H597"
        className="Portugal_svg__cls-22"
      />
      <path
        id="Portugal_svg__path1535-13"
        d="M598.27 333.65h2.17v1.57H602v-1.6h2.22v1.59h1.58v-1.6h2.2v3.62a.93.93 0 0 1-1 .94h-7.77a1 1 0 0 1-1-.95v-3.58"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path1541-7"
        d="m606.63 338.19.23 6.26h-7.42l.21-6.26Z"
        className="Portugal_svg__cls-23"
        data-name="path1541"
      />
      <path
        id="Portugal_svg__path1537-7"
        d="M598.28 333.63h2.16v1.57H602v-1.6h2.21v1.6h1.58v-1.6H608v3.63a.93.93 0 0 1-1 .93h-7.77a1 1 0 0 1-1-1v-3.58Z"
        className="Portugal_svg__cls-24"
        data-name="path1537"
      />
      <path
        id="Portugal_svg__path5258"
        d="M579.18 333.65h2.16v1.57h1.58v-1.6h2.21v1.59h1.59v-1.6h2.21v3.62a.92.92 0 0 1-1 .94h-7.77a1 1 0 0 1-1-.95v-3.58"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5260"
        d="M579.18 333.65h2.16v1.57h1.58v-1.61h2.21v1.6h1.59v-1.61h2.21v3.62a.92.92 0 0 1-1 .94h-7.77a1 1 0 0 1-1-.95v-3.58Z"
        className="Portugal_svg__cls-24"
      />
      <path
        id="Portugal_svg__path5262"
        d="m587.53 338.19.24 6.26h-7.43l.21-6.26h7"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5264"
        d="m587.53 338.19.24 6.26h-7.43l.21-6.26Z"
        className="Portugal_svg__cls-23"
      />
      <path
        id="Portugal_svg__path5266"
        d="M592.86 336.48a.78.78 0 1 1 1.56 0v2.76h-1.56z"
      />
      <path
        id="Portugal_svg__path1547-7"
        d="M602.42 340.49a.75.75 0 1 1 1.49 0v2.14h-1.49z"
        data-name="path1547"
      />
      <path
        id="Portugal_svg__path5270"
        d="M583.27 340.49a.74.74 0 1 1 1.48 0v2.14h-1.48z"
      />
      <path
        id="Portugal_svg__path1539-8"
        d="m453.18 417.32 4.6 4.25-5.25 5.26-4.28-4.58z"
        className="Portugal_svg__cls-3"
        data-name="path1539"
      />
      <path
        id="Portugal_svg__path5276"
        d="M455.11 448.16c-6.86-7.2-3.53-13.85-3.52-13.91s7.18-3.59 14.15 3.41z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5278"
        d="m442.22 443.86 7.21 8.95 5.28-5.26c-6.63-6.74-2.73-12.86-2.58-12.95s6.55-3.6 13 2.56l5.29-5.29-9.1-7.18-19.07 19.17"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5280"
        d="m442.22 443.88 7.21 8.94 5.28-5.26c-6.63-6.73-2.73-12.85-2.59-12.94s6.56-3.6 13 2.55l5.28-5.28-9.09-7.18-19.07 19.17Z"
        className="Portugal_svg__cls-17"
      />
      <path
        id="Portugal_svg__path5282"
        d="m449.12 453.2 21.56-21.57a1.44 1.44 0 0 1 2 2l-21.57 21.56a1.43 1.43 0 0 1-2-2"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5284"
        d="m449.12 453.2 21.57-21.57a1.44 1.44 0 0 1 2 2l-21.57 21.56a1.43 1.43 0 0 1-2-2Z"
        className="Portugal_svg__cls-18"
      />
      <path
        id="Portugal_svg__path5286"
        d="M456.91 445.32c-4.19-4.24-2.53-8.29-2.51-8.3s4.21-1.75 8.41 2.4z"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5288"
        d="M456.91 445.32c-4.19-4.24-2.53-8.29-2.51-8.3s4.21-1.75 8.41 2.4l-5.9 5.9"
        className="Portugal_svg__cls-19"
      />
      <path
        id="Portugal_svg__path5290"
        d="m438.1 441.29 20.68-20.68a1.27 1.27 0 0 1 1.79 1.79l-20.68 20.68a1.19 1.19 0 0 1-1.68-.1 1.21 1.21 0 0 1-.11-1.69"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5292"
        d="m438.1 441.29 20.68-20.68a1.27 1.27 0 0 1 1.79 1.79l-20.68 20.68a1.19 1.19 0 0 1-1.68-.1 1.21 1.21 0 0 1-.11-1.69Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path5294"
        d="m440.33 442.64 19.78-19.78a1.28 1.28 0 0 1 1.79 1.79l-19.78 19.78a1.28 1.28 0 0 1-1.79-1.79"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5296"
        d="m440.33 442.64 19.78-19.78a1.28 1.28 0 0 1 1.79 1.79l-19.78 19.78a1.28 1.28 0 0 1-1.79-1.79Z"
        className="Portugal_svg__cls-20"
      />
      <path
        id="Portugal_svg__path1535-14"
        d="m433.33 422.71 1.53-1.52 1.1 1.11 1.12-1.12-1.08-1.13 1.57-1.56 1.13 1.13 1.11-1.12-1.13-1.14 1.57-1.56 2.56 2.56a.93.93 0 0 1 0 1.35l-5.49 5.49a1 1 0 0 1-1.38 0l-2.53-2.53"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path5300"
        d="m433.26 422.78 1.56-1.56 1.11 1.11 1.14-1.14-1.13-1.13 1.6-1.6 1.13 1.13 1.14-1.14-1.13-1.13 1.6-1.6 2.56 2.56a.94.94 0 0 1 0 1.36l-5.61 5.61a1 1 0 0 1-1.4.06l-2.53-2.53Z"
        className="Portugal_svg__cls-21"
      />
      <path
        id="Portugal_svg__path5302"
        d="m442.56 420.32 8.57 7.86-5.5 5.45-7.84-8.57 4.78-4.74"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5304"
        d="m442.56 420.33 8.57 7.86-5.5 5.46-7.85-8.58 4.78-4.74"
        className="Portugal_svg__cls-22"
      />
      <path
        id="Portugal_svg__path1535-15"
        d="m444.07 420 1.53-1.52 1.11 1.11 1.11-1.12-1.13-1.13 1.57-1.56 1.13 1.13 1.12-1.12-1.13-1.14 1.56-1.56 2.56 2.56a.94.94 0 0 1 0 1.35l-5.5 5.5a1 1 0 0 1-1.38 0z"
        className="Portugal_svg__cls-3"
        data-name="path1535"
      />
      <path
        id="Portugal_svg__path1541-8"
        d="m453.18 417.32 4.6 4.25-5.25 5.26-4.28-4.58z"
        className="Portugal_svg__cls-23"
        data-name="path1541"
      />
      <path
        id="Portugal_svg__path1537-8"
        d="m444.06 420 1.53-1.53 1.11 1.11 1.11-1.11-1.13-1.14 1.57-1.56 1.13 1.13 1.12-1.12-1.14-1.13 1.57-1.57 2.56 2.57a.93.93 0 0 1 0 1.34l-5.5 5.49a1 1 0 0 1-1.38 0z"
        className="Portugal_svg__cls-24"
        data-name="path1537"
      />
      <path
        id="Portugal_svg__path5312"
        d="m430.58 433.51 1.53-1.53 1.11 1.11 1.11-1.11-1.13-1.13 1.57-1.57 1.13 1.13 1.12-1.12-1.14-1.13 1.57-1.56 2.56 2.56a.92.92 0 0 1 0 1.34l-5.49 5.5a1 1 0 0 1-1.38 0l-2.53-2.53"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5314"
        d="m430.58 433.51 1.53-1.52 1.11 1.11 1.11-1.12-1.13-1.13 1.57-1.57 1.13 1.14 1.1-1.12-1.14-1.14 1.57-1.56 2.56 2.56a.92.92 0 0 1 0 1.34l-5.49 5.5a1 1 0 0 1-1.38 0l-2.53-2.53Z"
        className="Portugal_svg__cls-24"
      />
      <path
        id="Portugal_svg__path5316"
        d="m439.68 430.81 4.6 4.26-5.28 5.25-4.28-4.57 4.93-4.94"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__path5318"
        d="m439.68 430.81 4.6 4.26-5.28 5.25-4.28-4.57 4.94-4.94Z"
        className="Portugal_svg__cls-23"
      />
      <path
        id="Portugal_svg__path5320"
        d="M442.24 425.84a.78.78 0 1 1 1.1-1.1l2 2-1.1 1.1z"
      />
      <path
        id="Portugal_svg__path1547-8"
        d="M451.84 421.93a.75.75 0 1 1 1.05-1.06l1.51 1.51-1 1.06-1.51-1.51"
        data-name="path1547"
      />
      <path
        id="Portugal_svg__path5324"
        d="M438.3 435.47a.75.75 0 1 1 1.05-1.05l1.51 1.51-1.05 1.07-1.51-1.51"
      />
      <path
        id="Portugal_svg__use5944"
        d="M520.07 410.77a2.67 2.67 0 1 0 2.68 2.67 2.68 2.68 0 0 0-2.68-2.67"
        className="Portugal_svg__cls-13"
      />
      <path
        id="Portugal_svg__use5944-2"
        d="M505.25 410.78a2.68 2.68 0 1 0 2.67 2.68 2.68 2.68 0 0 0-2.67-2.68"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-3"
        d="M512.59 403.26a2.68 2.68 0 1 0 2.67 2.67 2.68 2.68 0 0 0-2.67-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-4"
        d="M520.09 396.07a2.68 2.68 0 1 0 2.67 2.68 2.69 2.69 0 0 0-2.67-2.68"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-5"
        d="M557.55 364.43a2.67 2.67 0 1 0 2.67 2.67 2.68 2.68 0 0 0-2.67-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-6"
        d="M542.94 364.45a2.68 2.68 0 1 0 2.68 2.67 2.68 2.68 0 0 0-2.68-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-7"
        d="M550.06 356.93a2.67 2.67 0 1 0 2.68 2.67 2.68 2.68 0 0 0-2.68-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-8"
        d="M557.56 349.74a2.67 2.67 0 1 0 2.68 2.67 2.68 2.68 0 0 0-2.68-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-9"
        d="M542.91 349.72a2.68 2.68 0 1 0 2.68 2.68 2.68 2.68 0 0 0-2.68-2.68"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-10"
        d="M520.2 364.36a2.67 2.67 0 1 0 2.68 2.67 2.68 2.68 0 0 0-2.68-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-11"
        d="M505.25 364.38a2.67 2.67 0 1 0 2.67 2.67 2.68 2.68 0 0 0-2.67-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-12"
        d="M512.72 356.85a2.68 2.68 0 1 0 2.67 2.67 2.68 2.68 0 0 0-2.67-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-13"
        d="M520.22 349.66a2.68 2.68 0 1 0 2.67 2.68 2.69 2.69 0 0 0-2.67-2.68"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-14"
        d="M505.25 349.65a2.68 2.68 0 1 0 2.67 2.67 2.68 2.68 0 0 0-2.67-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-15"
        d="M482.77 364.45a2.68 2.68 0 1 0 2.67 2.67 2.68 2.68 0 0 0-2.67-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-16"
        d="M468.16 364.47a2.67 2.67 0 1 0 2.68 2.67 2.68 2.68 0 0 0-2.68-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-17"
        d="M475.28 356.94a2.68 2.68 0 1 0 2.68 2.68 2.68 2.68 0 0 0-2.68-2.68"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-18"
        d="M482.78 349.76a2.67 2.67 0 1 0 2.68 2.67 2.68 2.68 0 0 0-2.68-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-19"
        d="M505.25 396.06a2.68 2.68 0 1 0 2.67 2.67 2.68 2.68 0 0 0-2.67-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use6320"
        d="M468.12 349.74a2.68 2.68 0 1 0 2.68 2.67 2.68 2.68 0 0 0-2.68-2.67"
        className="Portugal_svg__cls-3"
      />
      <path
        id="Portugal_svg__use5944-20"
        d="M505.25 302.82a2.68 2.68 0 1 0 2.67 2.68 2.68 2.68 0 0 0-2.67-2.68"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-21"
        d="M519.9 302.82a2.68 2.68 0 1 0 2.67 2.68 2.68 2.68 0 0 0-2.67-2.68"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-22"
        d="M512.4 310a2.68 2.68 0 1 0 2.67 2.68 2.68 2.68 0 0 0-2.67-2.68"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-23"
        d="M519.88 317.53a2.68 2.68 0 1 0 2.68 2.67 2.68 2.68 0 0 0-2.68-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-24"
        d="M505.25 317.55a2.67 2.67 0 1 0 2.67 2.67 2.68 2.68 0 0 0-2.67-2.67"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
      <path
        id="Portugal_svg__use5944-25"
        d="M468.13 349.74a2.68 2.68 0 1 0 2.68 2.68 2.68 2.68 0 0 0-2.68-2.68"
        className="Portugal_svg__cls-13"
        data-name="use5944"
      />
    </g>
  </svg>
);
export default SvgPortugal;
