import * as React from "react";
import type { SVGProps } from "react";
const SvgNiger = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Niger_svg__cls-3{fill:#e05206}"}</style>
    </defs>
    <g id="Niger_svg__Niger">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#0db02b",
        }}
      />
      <path
        d="M0 0h1280v480H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path d="M0 0h1280v240H0z" className="Niger_svg__cls-3" />
      <circle cx={639} cy={360} r={102} className="Niger_svg__cls-3" />
    </g>
  </svg>
);
export default SvgNiger;
