import * as React from "react";
import type { SVGProps } from "react";

const SvgFaroeIslands = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 480" {...props}>
    {/* Background */}
    <rect width="960" height="480" fill="#fff" />
    
    {/* Red cross */}
    <rect x="0" y="160" width="960" height="160" fill="#d52b1e" />
    <rect x="160" y="0" width="160" height="480" fill="#d52b1e" />
    
    {/* Blue cross */}
    <rect x="160" y="160" width="640" height="80" fill="#0033a0" />
    <rect x="160" y="160" width="80" height="480" fill="#0033a0" />
  </svg>
);

export default SvgFaroeIslands;