import * as React from "react";
import type { SVGProps } from "react";
const SvgBurundi = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Burundi_svg__cls-1{fill:#ce1126}.Burundi_svg__cls-2{fill:#1eb53a}"}
      </style>
    </defs>
    <g id="Burundi_svg__Burundi">
      <path d="M0-.3h1280L0 720.3h1280Z" className="Burundi_svg__cls-1" />
      <path d="M0-.3v720.6L1280-.3v720.6Z" className="Burundi_svg__cls-2" />
      <path
        d="m0-.3 1280 720.6m0-720.6L0 720.3"
        style={{
          stroke: "#fff",
          strokeWidth: 20,
        }}
      />
      <circle
        cx={645.5}
        cy={360}
        r={204.17}
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M603.9 230.29h27.73l13.87-24.02 13.87 24.02h27.73l-13.86 24.02 13.86 24.02h-27.73l-13.87 24.02-13.76-23.92-27.84-.1 13.97-23.83z"
        className="Burundi_svg__cls-2"
      />
      <path
        d="M611.38 234.43h22.75l11.25-19.69 11.49 19.69h22.75l-11.38 19.7 11.38 19.7h-22.75l-11.37 20.06L633.99 274l-22.61-.17 11.38-19.7z"
        className="Burundi_svg__cls-1"
      />
      <path
        d="M512.37 388.82h27.73l13.87-24.02 13.87 24.02h27.74l-13.87 24.02 13.87 24.02h-27.74l-13.87 24.02-13.76-23.92-27.84-.1 13.97-23.83z"
        className="Burundi_svg__cls-2"
      />
      <path
        d="M519.86 392.96h22.74l11.25-19.69 11.49 19.69h22.75l-11.37 19.7 11.37 19.7h-22.75l-11.37 20.06-11.51-19.89-22.6-.17 11.37-19.7z"
        className="Burundi_svg__cls-1"
      />
      <path
        d="M695.42 388.82h27.74l13.87-24.02 13.87 24.02h27.73l-13.87 24.02 13.87 24.02H750.9l-13.87 24.02-13.76-23.92-27.85-.1 13.98-23.83z"
        className="Burundi_svg__cls-2"
      />
      <path
        d="M702.91 392.96h22.75l11.24-19.69 11.5 19.69h22.74l-11.37 19.7 11.37 19.7H748.4l-11.37 20.06-11.51-19.89-22.61-.17 11.37-19.7z"
        className="Burundi_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgBurundi;
