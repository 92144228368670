import * as React from "react";
import type { SVGProps } from "react";

const SvgAruba = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 480" {...props}>
    {/* Background of the flag */}
    <rect width="960" height="480" fill="#00a1de" />
    
    {/* Yellow stripes */}
    <rect y="350" width="960" height="30" fill="#ffcd00" />
    <rect y="410" width="960" height="30" fill="#ffcd00" />
    
    {/* Red star with white border (upper left) */}
    <g transform="translate(150, 100)">
      <polygon
        points="0,-40 12,-12 40,0 12,12 0,40 -12,12 -40,0 -12,-12"
        fill="#ef3340"
      />
      <polygon
        points="0,-48 14,-14 48,0 14,14 0,48 -14,14 -48,0 -14,-14"
        fill="none"
        stroke="#fff"
        strokeWidth="8"
      />
    </g>
  </svg>
);

export default SvgAruba;