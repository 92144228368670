import * as React from "react";
import type { SVGProps } from "react";
const SvgArgentina = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Argentina_svg__Argentina"
    viewBox="0 0 1280 720"
    {...props}
  >
    <defs>
      <style>
        {
          ".Argentina_svg__cls-3{fill:#f6b40e;stroke:#85340a;stroke-width:1.11px}.Argentina_svg__cls-4{fill:#85340a}.Argentina_svg__cls-6{fill:#843511}"
        }
      </style>
    </defs>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#74acdf",
      }}
    />
    <path
      d="M0 240h1280v240H0z"
      style={{
        fill: "#fff",
      }}
    />
    <g id="Argentina_svg__rays">
      <path
        id="Argentina_svg__ray1"
        d="m634.45 361.89 41 89.26s.71 1.71 1.85 1.24.43-2.18.43-2.18l-34.15-92.09m-1 34.73c-.5 13.58 7.85 21 6.76 33.17s5.57 19 7.11 23.69-1.67 7.54-.28 8.21 4.42-3.06 3.43-9.76-6.08-8.69-4.88-23.5-6.06-18.26-4.29-31.77"
        className="Argentina_svg__cls-3"
      />
      <path
        id="Argentina_svg__ray1-2"
        d="m634.07 360 3.7 98.15s0 1.85 1.23 1.85 1.23-1.84 1.23-1.84l3.69-98.16m-14.19 31.72c-5.66 12.35-.8 22.44-6.45 33.22s-2.12 19.67-2.5 24.62-4.42 6.32-3.4 7.47 5.25-1.13 6.9-7.7-2.28-10.33 4.49-23.58 1.39-19.19 8.19-31"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-3"
        d="m634.45 358.12-34.15 92.09s-.71 1.71.43 2.18 1.84-1.24 1.84-1.24l41-89.27m-25.26 23.87C608.34 395 609 406.18 599.62 414s-9.48 17.36-11.72 21.79-6.51 4.14-6 5.6 5.29 1 9.33-4.47 1.85-10.45 13.17-20.07 8.62-17.2 19.42-25.5"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-4"
        d="m635.52 356.52-66.8 72s-1.3 1.31-.43 2.18 2.17-.44 2.17-.44l72-66.79M610 375.87c-12.74 4.73-16.44 15.3-28.06 18.94s-15.4 12.4-19.17 15.63-7.6 1.34-7.69 2.88 4.52 2.92 10.33-.56 5.71-8.94 19.84-13.51 14.55-12.58 27.71-16.12"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray2"
        d="M645.21 395.15c.65 13 8 18.81 6.59 30.69 3.19-9.39-4.5-16.68-4.06-30.55m-11-34.21 28.07 61.31-23.52-63.2"
        className="Argentina_svg__cls-4"
      />
      <path
        id="Argentina_svg__ray2-2"
        d="M631.28 394.85c-4.38 12.29.24 20.46-5.65 30.88 6.54-7.46 2.23-17.13 7.94-29.79m2.92-35.82 2.51 67.39 2.46-67.39"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-3"
        d="M618.53 389.24c-8.75 9.68-7.61 19-17 26.37 8.9-4.39 8.62-15 18.74-24.48m16.4-32-23.51 63.2L641.18 361"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-4"
        d="M608.9 379.19c-11.79 5.59-14.3 14.63-25.83 17.83 9.9-.65 13.69-10.54 26.67-15.44m27.4-23.27-45.91 49.39 49.39-45.91"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
    </g>
    <g id="Argentina_svg__rays-2" data-name="rays">
      <path
        id="Argentina_svg__ray1-5"
        d="m637.11 355.45-89.26 41s-1.71.71-1.24 1.85 2.18.43 2.18.43l92.09-34.15m-34.73-1c-13.58-.5-21 7.85-33.17 6.76s-19 5.57-23.69 7.11-7.54-1.67-8.21-.28 3.06 4.42 9.76 3.43 8.69-6.08 23.5-4.88 18.26-6.06 31.77-4.29"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-6"
        d="m639 355.07-98.15 3.7s-1.85 0-1.85 1.23 1.84 1.23 1.84 1.23l98.16 3.69m-31.72-14.19c-12.35-5.66-22.44-.8-33.22-6.45s-19.67-2.12-24.62-2.5-6.32-4.42-7.47-3.4 1.13 5.25 7.7 6.9 10.33-2.28 23.58 4.49 19.19 1.39 31 8.19"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-7"
        d="m640.88 355.45-92.09-34.15s-1.71-.71-2.18.43 1.24 1.84 1.24 1.84l89.27 41m-23.87-25.26c-9.25-10-20.43-9.32-28.23-18.67s-17.36-9.48-21.79-11.72-4.14-6.51-5.6-6-1 5.29 4.47 9.33 10.45 1.85 20.07 13.17 17.2 8.62 25.5 19.42"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-8"
        d="m642.48 356.52-72-66.8s-1.31-1.3-2.18-.43.44 2.17.44 2.17l66.79 72M623.13 331c-4.73-12.74-15.3-16.44-18.94-28.06s-12.4-15.4-15.63-19.17-1.34-7.6-2.88-7.69-2.92 4.52.56 10.33 8.94 5.71 13.51 19.84 12.58 14.55 16.12 27.71"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray2-5"
        d="M603.85 366.21c-13 .65-18.81 8-30.69 6.59 9.39 3.19 16.68-4.5 30.55-4.06m34.21-11-61.31 28.07 63.2-23.52"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-6"
        d="M604.15 352.28c-12.29-4.38-20.46.24-30.88-5.65 7.46 6.54 17.13 2.23 29.79 7.94m35.82 2.92L571.49 360l67.39 2.46"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-7"
        d="M609.76 339.53c-9.68-8.75-19-7.61-26.37-17 4.39 8.9 15 8.62 24.48 18.74m32 16.4-63.2-23.51L638 362.18"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-8"
        d="M619.81 329.9c-5.59-11.79-14.63-14.3-17.83-25.83.65 9.9 10.54 13.69 15.44 26.67m23.27 27.4-49.39-45.91 45.91 49.39"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
    </g>
    <g id="Argentina_svg__rays-3" data-name="rays">
      <path
        id="Argentina_svg__ray1-9"
        d="m643.55 358.11-41-89.26s-.71-1.71-1.85-1.24-.43 2.18-.43 2.18l34.15 92.09m1-34.73c.5-13.58-7.85-21-6.76-33.17s-5.57-19-7.11-23.69 1.67-7.54.28-8.21-4.42 3.06-3.43 9.76 6.08 8.69 4.88 23.5 6.06 18.26 4.29 31.77"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-10"
        d="m643.93 360-3.7-98.15s0-1.85-1.23-1.85-1.23 1.84-1.23 1.84L634.08 360m14.19-31.72c5.66-12.35.8-22.44 6.45-33.22s2.12-19.67 2.5-24.62 4.42-6.32 3.4-7.47-5.25 1.13-6.9 7.7 2.28 10.33-4.49 23.58-1.39 19.19-8.19 31"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-11"
        d="m643.55 361.88 34.15-92.09s.71-1.71-.43-2.18-1.84 1.24-1.84 1.24l-41 89.27m25.26-23.87c9.97-9.25 9.31-20.43 18.69-28.25s9.48-17.36 11.72-21.79 6.51-4.14 6-5.6-5.29-1-9.33 4.47-1.85 10.45-13.17 20.07-8.62 17.2-19.42 25.5"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-12"
        d="m642.48 363.48 66.8-72s1.3-1.31.43-2.18-2.17.44-2.17.44l-72 66.79m32.46-12.4c12.74-4.73 16.44-15.3 28.06-18.94s15.4-12.4 19.17-15.63 7.6-1.34 7.69-2.88-4.52-2.92-10.33.56-5.71 8.94-19.84 13.51-14.57 12.58-27.75 16.12"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray2-9"
        d="M632.79 324.85c-.65-13-8-18.81-6.59-30.69-3.19 9.39 4.5 16.68 4.06 30.55m11 34.21-28.07-61.31 23.52 63.2"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-10"
        d="M646.72 325.15c4.38-12.29-.24-20.46 5.65-30.88-6.54 7.46-2.23 17.13-7.94 29.79m-2.92 35.82-2.46-67.39-2.46 67.39"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-11"
        d="M659.47 330.76c8.75-9.68 7.61-19 17-26.37-8.9 4.39-8.62 15-18.74 24.48m-16.4 32 23.51-63.2L636.82 359"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-12"
        d="M669.1 340.81c11.79-5.59 14.3-14.63 25.83-17.83-9.9.65-13.69 10.54-26.67 15.44m-27.4 23.27 45.91-49.39-49.39 45.91"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
    </g>
    <g id="Argentina_svg__rays-4" data-name="rays">
      <path
        id="Argentina_svg__ray1-13"
        d="m640.89 364.55 89.26-41s1.71-.71 1.24-1.85-2.18-.43-2.18-.43l-92.09 34.15m34.73 1c13.58.5 21-7.85 33.17-6.76s19-5.57 23.69-7.11 7.54 1.67 8.21.28-3.06-4.42-9.76-3.43-8.69 6.08-23.5 4.88-18.26 6.06-31.77 4.29"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-14"
        d="m639 364.93 98.15-3.7s1.85 0 1.85-1.23-1.84-1.23-1.84-1.23L639 355.08m31.72 14.19c12.35 5.66 22.44.8 33.22 6.45s19.67 2.12 24.62 2.5 6.32 4.42 7.47 3.4-1.13-5.25-7.7-6.9-10.33 2.28-23.58-4.49-19.19-1.39-31-8.19"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-15"
        d="m637.12 364.55 92.09 34.15s1.71.71 2.18-.43-1.24-1.84-1.24-1.84l-89.27-41m23.87 25.26c9.25 10 20.43 9.32 28.23 18.67s17.36 9.48 21.79 11.72 4.14 6.51 5.6 6 1-5.29-4.47-9.33-10.45-1.85-20.07-13.17-17.2-8.62-25.5-19.42"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray1-16"
        d="m635.52 363.48 72 66.8s1.31 1.3 2.18.43-.44-2.17-.44-2.17l-66.79-72m12.4 32.46c4.73 12.74 15.3 16.44 18.94 28.06s12.4 15.4 15.63 19.17 1.34 7.6 2.88 7.69 2.92-4.52-.56-10.33-8.94-5.71-13.51-19.84-12.58-14.57-16.12-27.75"
        className="Argentina_svg__cls-3"
        data-name="ray1"
      />
      <path
        id="Argentina_svg__ray2-13"
        d="M674.15 353.79c13-.65 18.81-8 30.69-6.59-9.39-3.19-16.68 4.5-30.55 4.06m-34.21 11 61.31-28.07-63.2 23.52"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-14"
        d="M673.85 367.72c12.29 4.38 20.46-.24 30.88 5.65-7.46-6.54-17.13-2.23-29.79-7.94m-35.82-2.92 67.39-2.46-67.39-2.46"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-15"
        d="M668.24 380.47c9.68 8.75 19 7.61 26.37 17-4.39-8.9-15-8.62-24.48-18.74m-32-16.4 63.2 23.51L640 357.82"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
      <path
        id="Argentina_svg__ray2-16"
        d="M658.19 390.1c5.59 11.79 14.63 14.3 17.83 25.83-.65-9.9-10.54-13.69-15.44-26.67m-23.27-27.4 49.39 45.91-45.91-49.39"
        className="Argentina_svg__cls-4"
        data-name="ray2"
      />
    </g>
    <circle
      cx={639}
      cy={360}
      r={40}
      style={{
        fill: "#f6b40e",
        stroke: "#85340a",
        strokeWidth: "1.5px",
      }}
    />
    <path
      id="Argentina_svg__loweyecontour"
      d="M652.64 351.45a7.92 7.92 0 0 0-6.89 3.64c3.08 2.77 9.88 3.07 14.49-.31a10.53 10.53 0 0 0-7.6-3.33m0 .63c2.66-.05 5.15 1.17 5.49 2.38a8.11 8.11 0 0 1-11.07.63 6.47 6.47 0 0 1 5.58-3Z"
      className="Argentina_svg__cls-6"
    />
    <path
      id="Argentina_svg__uppalpebra"
      d="M653.16 349.55c-4 0-4.93.92-6.77 2.46s-2.77 1.23-3.08 1.54 0 1.23.61.92a17.4 17.4 0 0 0 3.69-2.46c1.85-1.54 3.7-1.54 5.54-1.54 5.54 0 8.62 4.61 9.23 4.31s-3.07-5.24-9.23-5.24Z"
      className="Argentina_svg__cls-4"
    />
    <path
      id="Argentina_svg__eyebrow_nose"
      d="M664.23 351.39c-7.08-6.15-16-7.08-20.92-2.46a12 12 0 0 0-2.3 5.14c-.62 3.5.48 7.29 3.22 11.17a2.3 2.3 0 0 0-1.23.61 21.87 21.87 0 0 1-2.51-14 19.7 19.7 0 0 1 1-3.51c6.77-5.54 16-6.15 22.76 3.08Z"
      className="Argentina_svg__cls-4"
      data-name="eyebrow nose"
    />
    <circle
      id="Argentina_svg__pupil"
      cx={652.54}
      cy={354.46}
      r={2.77}
      className="Argentina_svg__cls-4"
    />
    <path
      id="Argentina_svg__lowpalpebra"
      d="M660.54 356.31c-5.23 4-10.46 3.7-13.54 1.85s-3.07-2.46-2.46-2.46 1.23.61 3.69 1.84 6.16 1.24 12.31-1.23"
      className="Argentina_svg__cls-4"
    />
    <path
      d="M632.88 365.53a2.77 2.77 0 0 0 .54 5.49 2.73 2.73 0 0 0 2.25-1.17 5.8 5.8 0 0 0 3.33.9h.36a5.8 5.8 0 0 0 3.33-.9 2.73 2.73 0 0 0 2.25 1.17 2.77 2.77 0 0 0 .54-5.49 1.85 1.85 0 1 1-2.47 1.84 4.19 4.19 0 0 1-7.65 0 1.87 1.87 0 0 1-1.85 1.76 1.86 1.86 0 0 1-.63-3.6M635.89 373.72c-3.08 0-4.3 2.79-7.07 4.64 1.54-.62 2.75-1.83 4.91-3.06s4 .27 5.22.27c1.23 0 3.07-1.5 5.22-.27s3.42 2.44 4.95 3.06c-2.77-1.85-4-4.64-7.11-4.64a8.4 8.4 0 0 0-3.06.95 8.2 8.2 0 0 0-3.06-.95"
      className="Argentina_svg__cls-4"
    />
    <path
      d="M634.85 377.37a20.6 20.6 0 0 0-5.13 1c5.54-1.23 6.77.63 9.23.63s3.69-1.86 9.23-.63c-6.16-1.85-7.38-.63-9.23-.63a33 33 0 0 0-4.1-.36Z"
      className="Argentina_svg__cls-4"
    />
    <path
      d="M630 378.32h-1.17c6.46.62 3.36 4.32 10.12 4.32 6.77 0 3.71-3.7 10.17-4.32-6.77-.61-4.63 3.38-10.17 3.38-5.19 0-3.59-3.49-9-3.42Z"
      className="Argentina_svg__cls-4"
    />
    <path
      d="M644.54 388.31a5.54 5.54 0 1 0-11.07 0 5.69 5.69 0 0 1 11.07 0"
      className="Argentina_svg__cls-4"
    />
    <path
      id="Argentina_svg__eyebrow_nose-2"
      d="M614.13 351.39c7.08-6.15 16-7.08 20.92-2.46a12 12 0 0 1 2.3 5.14c.62 3.5-.48 7.29-3.22 11.17a2.3 2.3 0 0 1 1.23.61 21.87 21.87 0 0 0 2.51-14 19.7 19.7 0 0 0-1-3.51c-6.77-5.54-16-6.15-22.76 3.08Z"
      className="Argentina_svg__cls-4"
      data-name="eyebrow nose"
    />
    <path
      id="Argentina_svg__uppalpebra-2"
      d="M625.2 349.55c4 0 4.93.92 6.77 2.46s2.77 1.23 3.08 1.54 0 1.23-.61.92a17.4 17.4 0 0 1-3.69-2.46c-1.85-1.54-3.7-1.54-5.54-1.54-5.54 0-8.62 4.61-9.23 4.31s3.07-5.24 9.23-5.24Z"
      className="Argentina_svg__cls-4"
      data-name="uppalpebra"
    />
    <path
      id="Argentina_svg__loweyecontour-2"
      d="M625 351.45a7.91 7.91 0 0 0-6.89 3.64c3.08 2.77 9.87 3.07 14.49-.31a10.53 10.53 0 0 0-7.6-3.33m0 .63c2.66-.05 5.14 1.17 5.49 2.38a8.11 8.11 0 0 1-11.07.63 6.47 6.47 0 0 1 5.58-3Z"
      className="Argentina_svg__cls-6"
      data-name="loweyecontour"
    />
    <circle
      id="Argentina_svg__pupil-2"
      cx={625.38}
      cy={354.46}
      r={2.77}
      className="Argentina_svg__cls-4"
      data-name="pupil"
    />
    <path
      id="Argentina_svg__lowpalpebra-2"
      d="M617.82 356.31c5.23 4 10.46 3.7 13.54 1.85s3.07-2.46 2.46-2.46-1.23.61-3.69 1.84-6.16 1.24-12.31-1.23"
      className="Argentina_svg__cls-4"
      data-name="lowpalpebra"
    />
  </svg>
);
export default SvgArgentina;
