import * as React from "react";
import type { SVGProps } from "react";

const SvgFrenchGuiana = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 480" {...props}>
    {/* Background */}
    <rect width="960" height="480" fill="#fff" />
    
    {/* Green Triangle */}
    <polygon points="0,0 960,0 0,480" fill="#009639" />
    
    {/* Yellow Rectangle */}
    <rect x="0" y="240" width="960" height="240" fill="#f1c300" />
    
    {/* Red Star */}
    <polygon points="384,240 400,280 440,280 408,308 424,352 384,320 344,352 360,308 328,280 368,280" fill="#c8102e" />
  </svg>
);

export default SvgFrenchGuiana;