import * as React from "react";
import type { SVGProps } from "react";
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg width="4" height="23" viewBox="0 0 4 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.6 1.6C3.6 0.715 2.885 0 2 0C1.115 0 0.4 0.715 0.4 1.6V14.4C0.4 15.285 1.115 16 2 16C2.885 16 3.6 15.285 3.6 14.4V1.6ZM2 22.4C2.53043 22.4 3.03914 22.1893 3.41421 21.8142C3.78929 21.4391 4 20.9304 4 20.4C4 19.8696 3.78929 19.3609 3.41421 18.9858C3.03914 18.6107 2.53043 18.4 2 18.4C1.46957 18.4 0.960859 18.6107 0.585786 18.9858C0.210714 19.3609 0 19.8696 0 20.4C0 20.9304 0.210714 21.4391 0.585786 21.8142C0.960859 22.1893 1.46957 22.4 2 22.4Z" fill="white"/>
  </svg>
)
export default SvgHome;




