import * as React from "react";
import type { SVGProps } from "react";
const SvgDemocraticRepublicOfTheCongo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <g data-name="Democratic Republic of the Congo">
      <path
        d="M0-.38h1280v720.75H0z"
        style={{
          fill: "#007fff",
        }}
      />
      <path
        d="M43.24 143.77h100.91l31.23-100.9 31.23 100.9h100.91l-81.68 62.47 31.23 100.91-81.69-62.47-81.68 62.47 31.23-100.91ZM1219.94-.38 0 540.19v180.19h60.06L1280 179.81V-.38H900.94"
        style={{
          fill: "#f7d618",
        }}
      />
      <path
        d="M1280-.38 0 576.23v144.15l1280-576.61z"
        style={{
          fill: "#ce1021",
        }}
      />
    </g>
  </svg>
);
export default SvgDemocraticRepublicOfTheCongo;
