import * as React from "react";
import type { SVGProps } from "react";
const SvgRussia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 .5h1280V360H0z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0 360h1280v359.5H0z"
      style={{
        fill: "#d32d27",
      }}
    />
    <path
      d="M0 240.17h1280v239.67H0z"
      style={{
        fill: "#1f429a",
      }}
    />
  </svg>
);
export default SvgRussia;
