import * as React from "react";
import type { SVGProps } from "react";
const SvgPakistan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Pakistan_svg__cls-1{fill:#fff}.Pakistan_svg__cls-2{fill:#01411c}"}
      </style>
    </defs>
    <g id="Pakistan_svg__Pakistan">
      <path d="M0 0h1280v720H0z" className="Pakistan_svg__cls-1" />
      <path d="M320 0h960v720H320z" className="Pakistan_svg__cls-2" />
      <circle cx={775} cy={360} r={216} className="Pakistan_svg__cls-1" />
      <circle cx={830.21} cy={310.92} r={198} className="Pakistan_svg__cls-2" />
      <path
        d="m853.76 198.37 90.99 102.36-133.77-29.33 125.46-54.9-69.23 118.16z"
        className="Pakistan_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgPakistan;
