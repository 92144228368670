import * as React from "react";
import type { SVGProps } from "react";
const SvgSweden = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Sweden_svg__cls-2{fill:#fdcb08}"}</style>
    </defs>
    <g id="Sweden_svg__Sweden">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#005392",
        }}
      />
      <path d="M400 0h160v720H400z" className="Sweden_svg__cls-2" />
      <path d="M0 280h1280v160H0z" className="Sweden_svg__cls-2" />
    </g>
  </svg>
);
export default SvgSweden;
