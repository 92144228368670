import * as React from "react";
import type { SVGProps } from "react";

const SvgPuertoRico = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20" {...props}>
    {/* Red Stripes */}
    <rect y="0" width="30" height="3" fill="#c8102e" />
    <rect y="6" width="30" height="3" fill="#c8102e" />
    <rect y="12" width="30" height="3" fill="#c8102e" />
    <rect y="18" width="30" height="3" fill="#c8102e" />
    
    {/* White Stripes */}
    <rect y="3" width="30" height="3" fill="#ffffff" />
    <rect y="9" width="30" height="3" fill="#ffffff" />
    <rect y="15" width="30" height="3" fill="#ffffff" />
    
    {/* Blue Triangle */}
    <polygon points="0,0 0,20 10,10" fill="#0033a0" />
    
    {/* White Star */}
    <polygon points="5,7 6,10 5,13 8,10 5,7" fill="#ffffff" />
  </svg>
);

export default SvgPuertoRico;