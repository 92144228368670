import * as React from "react";
import type { SVGProps } from "react";
const SvgSaintVincentAndTheGrenadines = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Saint_Vincent_and_the_Grenadines_svg__cls-1{fill:#009e60}"}
      </style>
    </defs>
    <g
      id="Saint_Vincent_and_the_Grenadines_svg__Saint_Vincent_and_the_Grenadines"
      data-name="Saint Vincent and the Grenadines"
    >
      <path
        d="M0-.33h1280v720.67H0z"
        className="Saint_Vincent_and_the_Grenadines_svg__cls-1"
      />
      <path
        d="M0-.33h960v720.67H0z"
        style={{
          fill: "#fcd116",
        }}
      />
      <path
        d="M0-.33h320v720.67H0z"
        style={{
          fill: "#0072c6",
        }}
      />
      <path
        d="M568.89 510.14 640 630.25l71.11-120.11L640 390ZM480 360l71.11 120.11L622.22 360l-71.11-120.11ZM657.78 360l71.11 120.11L800 360l-71.11-120.11Z"
        className="Saint_Vincent_and_the_Grenadines_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgSaintVincentAndTheGrenadines;
