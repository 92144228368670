import * as React from "react";
import type { SVGProps } from "react";
const SvgSouthKorea = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".South_Korea_svg__cls-4{stroke:#000;stroke-miterlimit:10}"}
      </style>
    </defs>
    <g id="South_Korea_svg__South_Korea" data-name="South Korea">
      <path
        d="M0-.03h1280v720.07H0z"
        style={{
          fill: "#fff",
        }}
      />
      <circle
        cx={639.95}
        cy={360}
        r={180.02}
        style={{
          fill: "#c60c30",
        }}
      />
      <path
        d="M490.17 260.14A90 90 0 0 0 640 360a90 90 0 0 1 149.78 99.86 180 180 0 0 1-299.61-199.72"
        style={{
          fill: "#003478",
        }}
      />
      <path
        d="m815.17 135.58 45 67.21 24.6-16.21-44.4-67.8zM869.17 215.99l45.01 67.8 24.6-15.6-46.21-67.81zM852.97 110.38l23.4-15.6 100.21 148.51-24 15.9zM889.57 86.38l45.61 67.2 24-16.2-44.4-68.41zM944.18 166.18l45 67.21 24.6-15.6-45-67.21zM1012.88 501.61l-45 67.21-24.6-16.2 44.4-67.81zM958.88 582.02l-45 67.81-24.61-15.6 46.21-67.81zM975.08 476.41l-23.4-15.6-100.21 148.51 24 15.9zM938.48 452.41l-45.61 67.21-24-16.21 44.4-68.4zM883.87 532.22l-45 67.2-24.6-15.6 45-67.21zM464.93 134.08l-45 67.2-24.6-16.2 44.4-67.8zM410.93 214.49l-45 67.8-24.61-15.6 46.21-67.81zM427.13 108.88l-23.4-15.6-100.21 148.51 24 15.9zM390.53 84.88l-45.61 67.2-24-16.2 44.4-68.41zM335.92 164.68l-45 67.21-24.6-15.6 45-67.21zM463.43 582.92l-45-67.21-24.6 16.21 44.4 67.8zM409.43 502.51l-45-67.8-24.61 15.6 46.21 67.81zM425.63 608.12l-23.4 15.6-100.21-148.51 24-15.9zM389.03 632.13l-45.61-67.21-24 16.2 44.4 68.41zM334.42 552.32l-45-67.21-24.6 15.6 45 67.21z"
        className="South_Korea_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgSouthKorea;
