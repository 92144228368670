import * as React from "react";
import type { SVGProps } from "react";
const SvgIran = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Iran_svg__cls-3{fill:#da0000}.Iran_svg__cls-4{fill:none;stroke:#fff;stroke-linecap:square}"
        }
      </style>
    </defs>
    <g id="Iran_svg__Iran">
      <path
        d="M0-.29h1280v720.57H0z"
        style={{
          fill: "#239f40",
        }}
      />
      <path
        d="M0 239.9h1280v240.19H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path d="M0 480.1h1280v240.19H0z" className="Iran_svg__cls-3" />
      <g id="Iran_svg__b">
        <path
          id="Iran_svg__p"
          d="M588.8 203.48h68.27m-68.27 22.41v-11.21h22.76v11.21h22.75v-11.21h-11.38m34.14 11.21h-11.38v-11.21h22.75v-11.2m0 11.2v11.21h22.76v-22.41m-11.38 0v22.41m-79.64 0h0"
          className="Iran_svg__cls-4"
        />
        <path
          id="Iran_svg__t"
          d="M588.8 238.3h45.51m11.38 0h45.51"
          className="Iran_svg__cls-4"
        />
        <path
          id="Iran_svg__p-2"
          d="M588.8 494.11h68.27m-68.27 22.41v-11.2h22.76v11.2h22.75v-11.2h-11.38m34.14 11.2h-11.38v-11.2h22.75v-11.21m0 11.21v11.2h22.76v-22.41m-11.38 0v22.41m-79.64 0h0"
          className="Iran_svg__cls-4"
          data-name="p"
        />
        <path
          id="Iran_svg__t-2"
          d="M588.8 481.7h45.51m11.38 0h45.51"
          className="Iran_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Iran_svg__b2">
        <g id="Iran_svg__b-2" data-name="b">
          <path
            id="Iran_svg__p-3"
            d="M702.58 203.48h68.26m-68.26 22.41v-11.21h22.75v11.21h22.76v-11.21h-11.38m34.13 11.21h-11.37v-11.21h22.75v-11.2m0 11.2v11.21H805v-22.41m-11.38 0v22.41m-79.64 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-3"
            d="M702.58 238.3h45.51m11.38 0H805"
            className="Iran_svg__cls-4"
            data-name="t"
          />
          <path
            id="Iran_svg__p-4"
            d="M702.58 494.11h68.26m-68.26 22.41v-11.2h22.75v11.2h22.76v-11.2h-11.38m34.13 11.2h-11.37v-11.2h22.75v-11.21m0 11.21v11.2H805v-22.41m-11.38 0v22.41m-79.64 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-4"
            d="M702.58 481.7h45.51m11.38 0H805"
            className="Iran_svg__cls-4"
            data-name="t"
          />
        </g>
      </g>
      <g id="Iran_svg__b4">
        <g id="Iran_svg__b-3" data-name="b">
          <path
            id="Iran_svg__p-5"
            d="M361.24 203.48h68.27m-68.27 22.41v-11.21H384v11.21h22.76v-11.21h-11.38m34.13 11.21h-11.38v-11.21h22.76v-11.2m0 11.2v11.21h22.75v-22.41m-11.37 0v22.41m-79.65 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-5"
            d="M361.24 238.3h45.52m11.37 0h45.51"
            className="Iran_svg__cls-4"
            data-name="t"
          />
          <path
            id="Iran_svg__p-6"
            d="M361.24 494.11h68.27m-68.27 22.41v-11.2H384v11.2h22.76v-11.2h-11.38m34.13 11.2h-11.38v-11.2h22.76v-11.21m0 11.21v11.2h22.75v-22.41m-11.37 0v22.41m-79.65 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-6"
            d="M361.24 481.7h45.52m11.37 0h45.51"
            className="Iran_svg__cls-4"
            data-name="t"
          />
        </g>
        <g id="Iran_svg__b2-2" data-name="b2">
          <g id="Iran_svg__b-4" data-name="b">
            <path
              id="Iran_svg__p-7"
              d="M475 203.48h68.27M475 225.89v-11.21h22.76v11.21h22.75v-11.21h-11.35m34.13 11.21h-11.38v-11.21h22.76v-11.2m0 11.2v11.21h22.75v-22.41m-11.38 0v22.41m-79.64 0h0"
              className="Iran_svg__cls-4"
              data-name="p"
            />
            <path
              id="Iran_svg__t-7"
              d="M475 238.3h45.51m11.38 0h45.51"
              className="Iran_svg__cls-4"
              data-name="t"
            />
            <path
              id="Iran_svg__p-8"
              d="M475 494.11h68.27M475 516.52v-11.2h22.76v11.2h22.75v-11.2h-11.35m34.13 11.2h-11.38v-11.2h22.76v-11.21m0 11.21v11.2h22.75v-22.41m-11.38 0v22.41m-79.64 0h0"
              className="Iran_svg__cls-4"
              data-name="p"
            />
            <path
              id="Iran_svg__t-8"
              d="M475 481.7h45.51m11.38 0h45.51"
              className="Iran_svg__cls-4"
              data-name="t"
            />
          </g>
        </g>
      </g>
      <g id="Iran_svg__b-5" data-name="b">
        <path
          id="Iran_svg__p-9"
          d="M1043.91 203.48h68.27m-68.27 22.41v-11.21h22.76v11.21h22.75v-11.21H1078m34.14 11.21h-11.34v-11.21h22.76v-11.2m0 11.2v11.21h22.75v-22.41m-11.38 0v22.41m-79.64 0h0"
          className="Iran_svg__cls-4"
          data-name="p"
        />
        <path
          id="Iran_svg__t-9"
          d="M1043.91 238.3h45.51m11.38 0h45.51"
          className="Iran_svg__cls-4"
          data-name="t"
        />
        <path
          id="Iran_svg__p-10"
          d="M1043.91 494.11h68.27m-68.27 22.41v-11.2h22.76v11.2h22.75v-11.2H1078m34.14 11.2h-11.34v-11.2h22.76v-11.21m0 11.21v11.2h22.75v-22.41m-11.38 0v22.41m-79.64 0h0"
          className="Iran_svg__cls-4"
          data-name="p"
        />
        <path
          id="Iran_svg__t-10"
          d="M1043.91 481.7h45.51m11.38 0h45.51"
          className="Iran_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Iran_svg__b2-3" data-name="b2">
        <g id="Iran_svg__b-6" data-name="b">
          <path
            id="Iran_svg__p-11"
            d="M1157.69 203.48H1226m-68.27 22.41v-11.21h22.75v11.21h22.76v-11.21h-11.38m34.14 11.21h-11.38v-11.21h22.75v-11.2m0 11.2v11.21h22.76v-22.41m-11.38 0v22.41m-79.64 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-11"
            d="M1157.69 238.3h45.51m11.38 0h45.51"
            className="Iran_svg__cls-4"
            data-name="t"
          />
          <path
            id="Iran_svg__p-12"
            d="M1157.69 494.11H1226m-68.27 22.41v-11.2h22.75v11.2h22.76v-11.2h-11.38m34.14 11.2h-11.38v-11.2h22.75v-11.21m0 11.21v11.2h22.76v-22.41m-11.38 0v22.41m-79.64 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-12"
            d="M1157.69 481.7h45.51m11.38 0h45.51"
            className="Iran_svg__cls-4"
            data-name="t"
          />
        </g>
      </g>
      <g id="Iran_svg__b4-2" data-name="b4">
        <g id="Iran_svg__b-7" data-name="b">
          <path
            id="Iran_svg__p-13"
            d="M816.36 203.48h68.26m-68.26 22.41v-11.21h22.75v11.21h22.76v-11.21h-11.38m34.13 11.21h-11.38v-11.21H896v-11.2m0 11.2v11.21h22.76v-22.41m-11.38 0v22.41m-79.65 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-13"
            d="M816.36 238.3h45.51m11.37 0h45.52"
            className="Iran_svg__cls-4"
            data-name="t"
          />
          <path
            id="Iran_svg__p-14"
            d="M816.36 494.11h68.26m-68.26 22.41v-11.2h22.75v11.2h22.76v-11.2h-11.38m34.13 11.2h-11.38v-11.2H896v-11.21m0 11.21v11.2h22.76v-22.41m-11.38 0v22.41m-79.65 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-14"
            d="M816.36 481.7h45.51m11.37 0h45.52"
            className="Iran_svg__cls-4"
            data-name="t"
          />
        </g>
        <g id="Iran_svg__b2-4" data-name="b2">
          <g id="Iran_svg__b-8" data-name="b">
            <path
              id="Iran_svg__p-15"
              d="M930.13 203.48h68.27m-68.27 22.41v-11.21h22.76v11.21h22.75v-11.21h-11.37m34.13 11.21H987v-11.21h22.76v-11.2m0 11.2v11.21h22.75v-22.41m-11.37 0v22.41m-79.65 0h0"
              className="Iran_svg__cls-4"
              data-name="p"
            />
            <path
              id="Iran_svg__t-15"
              d="M930.13 238.3h45.51m11.38 0h45.51"
              className="Iran_svg__cls-4"
              data-name="t"
            />
            <path
              id="Iran_svg__p-16"
              d="M930.13 494.11h68.27m-68.27 22.41v-11.2h22.76v11.2h22.75v-11.2h-11.37m34.13 11.2H987v-11.2h22.76v-11.21m0 11.21v11.2h22.75v-22.41m-11.37 0v22.41m-79.65 0h0"
              className="Iran_svg__cls-4"
              data-name="p"
            />
            <path
              id="Iran_svg__t-16"
              d="M930.13 481.7h45.51m11.38 0h45.51"
              className="Iran_svg__cls-4"
              data-name="t"
            />
          </g>
        </g>
      </g>
      <g id="Iran_svg__b-9" data-name="b">
        <path
          id="Iran_svg__p-17"
          d="M247.47 203.48h68.26m-68.26 22.41v-11.21h22.75v11.21H293v-11.21h-11.4m34.13 11.21h-11.37v-11.21h22.75v-11.2m0 11.2v11.21h22.76v-22.41m-11.38 0v22.41m-79.65 0h0"
          className="Iran_svg__cls-4"
          data-name="p"
        />
        <path
          id="Iran_svg__t-17"
          d="M247.47 238.3H293m11.38 0h45.51"
          className="Iran_svg__cls-4"
          data-name="t"
        />
        <path
          id="Iran_svg__p-18"
          d="M247.47 494.11h68.26m-68.26 22.41v-11.2h22.75v11.2H293v-11.2h-11.4m34.13 11.2h-11.37v-11.2h22.75v-11.21m0 11.21v11.2h22.76v-22.41m-11.38 0v22.41m-79.65 0h0"
          className="Iran_svg__cls-4"
          data-name="p"
        />
        <path
          id="Iran_svg__t-18"
          d="M247.47 481.7H293m11.38 0h45.51"
          className="Iran_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Iran_svg__b-10" data-name="b">
        <path
          id="Iran_svg__p-19"
          d="M19.91 203.48h68.27m-68.27 22.41v-11.21h22.76v11.21h22.75v-11.21H54m34.14 11.21H76.8v-11.21h22.76v-11.2m0 11.2v11.21h22.75v-22.41m-11.38 0v22.41m-79.64 0h0"
          className="Iran_svg__cls-4"
          data-name="p"
        />
        <path
          id="Iran_svg__t-19"
          d="M19.91 238.3h45.51m11.38 0h45.51"
          className="Iran_svg__cls-4"
          data-name="t"
        />
        <path
          id="Iran_svg__p-20"
          d="M19.91 494.11h68.27m-68.27 22.41v-11.2h22.76v11.2h22.75v-11.2H54m34.14 11.2H76.8v-11.2h22.76v-11.21m0 11.21v11.2h22.75v-22.41m-11.38 0v22.41m-79.64 0h0"
          className="Iran_svg__cls-4"
          data-name="p"
        />
        <path
          id="Iran_svg__t-20"
          d="M19.91 481.7h45.51m11.38 0h45.51"
          className="Iran_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Iran_svg__b2-5" data-name="b2">
        <g id="Iran_svg__b-11" data-name="b">
          <path
            id="Iran_svg__p-21"
            d="M133.69 203.48H202m-68.27 22.41v-11.21h22.75v11.21h22.72v-11.21h-11.38M202 225.89h-11.42v-11.21h22.75v-11.2m0 11.2v11.21h22.76v-22.41m-11.38 0v22.41m-79.64 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-21"
            d="M133.69 238.3h45.51m11.38 0h45.51"
            className="Iran_svg__cls-4"
            data-name="t"
          />
          <path
            id="Iran_svg__p-22"
            d="M133.69 494.11H202m-68.27 22.41v-11.2h22.75v11.2h22.72v-11.2h-11.38m34.18 11.2h-11.42v-11.2h22.75v-11.21m0 11.21v11.2h22.76v-22.41m-11.38 0v22.41m-79.64 0h0"
            className="Iran_svg__cls-4"
            data-name="p"
          />
          <path
            id="Iran_svg__t-22"
            d="M133.69 481.7h45.51m11.38 0h45.51"
            className="Iran_svg__cls-4"
            data-name="t"
          />
        </g>
      </g>
      <path
        d="M677.25 295.61s66.15 51 17.52 124.22c45.8-27.58 49.76-103.4-17.52-124.22M640 288.37c-10.53 11.7-28.62.07-22.4-12.67 10.88 19.22 22.4 3.69 22.4 3.69s11.52 15.53 22.41-3.69c6.22 12.74-11.88 24.37-22.41 12.67"
        className="Iran_svg__cls-3"
      />
      <path
        d="m640 447.12-7.56-9.74s-17.81 5.85-41.28-2.23c19.23 0 30-3.34 33.93-5.29 0 0-43.54-23.12-42.69-70.19 2.54-47.62 28.27-62.11 28.27-62.11s-45.8 65.73 21.49 120.67l-3-68v-49.88c3.84-.91 10.84-8.85 10.84-8.85s7 7.94 10.81 8.85v49.92l-3 68c67.28-54.94 21.48-120.67 21.48-120.67s25.76 14.4 28.31 62.07c.85 47.07-42.69 70.18-42.69 70.18 4 1.95 14.7 5.29 33.92 5.29-23.46 8.08-41.27 2.23-41.27 2.23z"
        className="Iran_svg__cls-3"
      />
      <path
        d="M602.76 295.61s-66.16 51-17.53 124.22c-45.8-27.58-49.76-103.4 17.53-124.22"
        className="Iran_svg__cls-3"
      />
    </g>
  </svg>
);
export default SvgIran;
