import * as React from "react";
import type { SVGProps } from "react";
const SvgMali = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h426.67v720H0z"
      style={{
        fill: "#14b53a",
      }}
    />
    <path
      d="M426.67 0h426.67v720H426.67z"
      style={{
        fill: "#fcd116",
      }}
    />
    <path
      d="M853.33 0H1280v720H853.33z"
      style={{
        fill: "#ce1126",
      }}
    />
  </svg>
);
export default SvgMali;
