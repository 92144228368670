import * as React from "react";
import type { SVGProps } from "react";
const SvgChad = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M-.5-.33h1281v720.67H-.5z"
      style={{
        fill: "#002664",
      }}
    />
    <path
      d="M426.5-.33h854v720.67h-854z"
      style={{
        fill: "#fecb00",
      }}
    />
    <path
      d="M853.5-.33h427v720.67h-427z"
      style={{
        fill: "#c60c30",
      }}
    />
  </svg>
);
export default SvgChad;
