import * as React from "react";
import type { SVGProps } from "react";
const SvgSamoa = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Samoa_svg__cls-3{fill:#fff}"}</style>
    </defs>
    <g id="Samoa_svg__Samoa">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        d="M0 0h640v360H0z"
        style={{
          fill: "#002b7f",
        }}
      />
      <path
        d="M320 249.89 350.9 345 270 286.22h100L289.1 345z"
        className="Samoa_svg__cls-3"
      />
      <path
        id="Samoa_svg__p"
        d="m210 105 24.72 75L170 133.65h80L185.28 180z"
        className="Samoa_svg__cls-3"
      />
      <path
        d="m432.5 90 23.18 70L395 116.74h75L409.32 160z"
        className="Samoa_svg__cls-3"
      />
      <path
        id="Samoa_svg__p-2"
        d="m320 15 24.72 75L280 43.65h80L295.28 90z"
        className="Samoa_svg__cls-3"
        data-name="p"
      />
      <path
        d="M375 182.45 390.45 230 350 200.61h50L359.55 230z"
        className="Samoa_svg__cls-3"
      />
    </g>
  </svg>
);
export default SvgSamoa;
