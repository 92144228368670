import * as React from "react";
import type { SVGProps } from "react";
const SvgNewZealand = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".New_Zealand_svg__cls-2{fill:#1a237b}.New_Zealand_svg__cls-3{fill:#fff}.New_Zealand_svg__cls-4{fill:#bd0034}"
        }
      </style>
    </defs>
    <g id="New_Zealand_svg__New_Zealand" data-name="New Zealand">
      <path
        d="M640.29 0v38.33l-142.07 76.38h142.07v114.71H498.22l142.07 76.41v40.59h-67.6L373.34 239.25v107.17h-106.7V239.25L67.29 346.42H0V720h1280V0z"
        style={{
          fill: "#002776",
        }}
      />
      <path
        d="M0 237.92v79.07l141.77-79.07zM373.34 0v108.8L568.46 0zM266.64 0H71.53l195.11 108.8zM0 118.96h141.77L0 39.92z"
        className="New_Zealand_svg__cls-2"
      />
      <path
        d="M639.99 0 426.66 118.96h-47.7L592.29 0h-23.83L373.34 108.8V0H352v142.76h288.29v-23.8H498.22l142.07-79.21V0zM266.64 0v108.8L71.53 0H0l213.33 118.96h-47.7L0 26.61v13.31l141.77 79.04H0v23.8h287.98V0zM431.71 214.16H352v145.1h21.34V248.11l199.35 111.15h67.6v-2.18L426.66 237.92h47.69l165.94 92.55v-13.31l-142.07-79.24h142.07v-23.76H431.71M255.98 214.16H0v23.79h141.77L0 316.99v39.92l213.33-118.96h47.69L43.46 359.26h23.83l199.35-111.15v111.15h21.34v-145.1z"
        className="New_Zealand_svg__cls-3"
      />
      <path
        d="M213.33 237.92 0 356.91v2.35h43.46l217.56-121.34zM0 26.61l165.63 92.35h47.7L0 0zM639.99 0h-47.7L378.96 118.96h47.7zM426.66 237.92l213.63 119.16v-26.61l-165.94-92.55z"
        className="New_Zealand_svg__cls-4"
      />
      <path
        d="M287.98 0v142.76H0v71.4h287.98v145.1H352v-145.1h288.29v-71.4H352V0z"
        className="New_Zealand_svg__cls-4"
      />
      <path
        d="M936.51 166.9h34.77l10.76-33.09 10.73 33.09h34.8l-28.13 20.43 10.73 33.09-28.13-20.43-28.16 20.43 10.76-33.09z"
        className="New_Zealand_svg__cls-3"
      />
      <path
        d="M951.93 171.95h22.99l7.12-21.88 7.09 21.88h23l-18.61 13.52 7.12 21.88-18.6-13.53-18.6 13.53 7.09-21.88z"
        className="New_Zealand_svg__cls-4"
      />
      <path
        d="M797.38 306.86h34.78l10.76-33.09 10.73 33.09h34.8l-28.13 20.44 10.73 33.09-28.13-20.44-28.13 20.44 10.72-33.09z"
        className="New_Zealand_svg__cls-3"
      />
      <path
        d="M812.8 311.91h22.99l7.13-21.88 7.09 21.88h23.02l-18.6 13.53 7.09 21.87-18.6-13.52-18.61 13.52 7.1-21.87z"
        className="New_Zealand_svg__cls-4"
      />
      <path
        d="M1060.78 272.15h30.96l9.56-29.43 9.59 29.43h30.95l-25.06 18.18 9.58 29.46-25.06-18.22-25.04 18.22 9.56-29.46z"
        className="New_Zealand_svg__cls-3"
      />
      <path
        d="M1074.52 276.63h20.47l6.31-19.45 6.34 19.45h20.47l-16.56 12.05 6.31 19.44-16.56-12.02-16.53 12.02 6.31-19.44z"
        className="New_Zealand_svg__cls-4"
      />
      <path
        d="M931.49 526.56h38.62l11.93-36.79 11.93 36.79h38.65l-31.25 22.75 11.93 36.79-31.26-22.75-31.23 22.75 11.94-36.79z"
        className="New_Zealand_svg__cls-3"
      />
      <path
        d="M948.62 532.18h25.55l7.87-24.34 7.91 24.34h25.51l-20.65 15.03 7.91 24.34-20.68-15.05-20.65 15.05 7.91-24.34z"
        className="New_Zealand_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgNewZealand;
