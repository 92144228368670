import * as React from "react";
import type { SVGProps } from "react";
const SvgGuyana = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#009e49",
      }}
    />
    <path
      d="m0 0 1280 360L0 720Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0 31.32 1167.36 360 0 688.68Z"
      style={{
        fill: "#fcd116",
      }}
    />
    <path d="m0 0 640 360L0 720Z" />
    <path
      d="m0 42 565.38 318L0 678Z"
      style={{
        fill: "#ce1126",
      }}
    />
  </svg>
);
export default SvgGuyana;
