import * as React from "react";
import type { SVGProps } from "react";
const SvgBelarus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <clipPath id="Belarus_svg__clip-path">
        <path
          d="M613.07 129.86c206.34 0 373.62-167.27 373.62-373.61S819.41-617.37 613.07-617.37h783.15c-206.34 0-373.61 167.28-373.61 373.62s167.27 373.61 373.61 373.61Z"
          className="Belarus_svg__cls-1"
        />
      </clipPath>
      <clipPath id="Belarus_svg__clip-path-2">
        <path
          d="M-121.59 129.86C84.75 129.86 252-37.41 252-243.75S84.75-617.37-121.59-617.37h783.15C455.22-617.37 288-450.09 288-243.75s167.22 373.61 373.56 373.61Z"
          className="Belarus_svg__cls-1"
        />
      </clipPath>
      <style>
        {
          ".Belarus_svg__cls-1{fill:none}.Belarus_svg__cls-2{fill:#c7313e}.Belarus_svg__cls-6{fill:#ec2024}"
        }
      </style>
    </defs>
    <g id="Belarus_svg__Belarus">
      <path d="M0 .75h1280v718.5H0z" className="Belarus_svg__cls-2" />
      <path
        d="M0 479.75h1280v239.5H0z"
        style={{
          fill: "#49a556",
        }}
      />
      <path
        d="M0 .75h159.67v718.5H0z"
        style={{
          fill: "#fff",
        }}
      />
      <g id="Belarus_svg__h">
        <g id="Belarus_svg__q">
          <path
            d="M27.77.75h20.82v11.78h6.95v11.78h6.94v11.78h6.94v11.78h6.94v11.77h-6.94v11.78h-6.94V83.2h-6.94V95h-6.95v11.78h-6.94v11.78h-6.94v-11.8h-6.94V95h-6.94V83.2h-6.95V71.42H6.94V59.64H0V47.87h6.94V36.09h6.94V24.31h6.95V12.53h6.94Zm6.94 23.56h6.94v11.78h6.94v11.78h6.95v11.77h-6.95v11.78h-6.94V83.2h-6.94V71.42h-6.94V59.64h-6.94V47.87h6.94V36.09h6.94Zm0 23.56h6.94v11.77h-6.94ZM0 12.53h6.94v11.78H0ZM0 83.2h6.94V95H0ZM76.36.75h4.17v23.56h-4.17Zm0 82.45h4.17v23.56h-4.17Zm-62.48 23.56h6.95v11.78h6.94v11.78h6.94v11.77h-6.94v11.78h-6.94v11.78h-6.95v-11.78H6.94v-11.78H0v-11.77h6.94v-11.78h6.94Zm0 23.56h6.95v11.77h-6.95Zm41.66-23.56h6.94v11.78h6.94v11.78h6.94v11.77h-6.94v11.78h-6.94v11.78h-6.94v-11.78h-6.95v-11.78h-6.94v-11.77h6.94v-11.78h6.95Zm0 23.56h6.94v11.77h-6.94ZM0 177.43h6.94v11.78H0Zm76.36-11.78h4.17v23.56h-4.17ZM0 212.77h6.94V201h6.94v-11.79h6.95v-11.78h6.94v-11.78h6.94v-11.78h6.94v11.78h6.94v11.78h6.95v11.78h6.94V201h6.94v11.78h6.94v11.77h4.17v47.12h-4.17v11.78h-6.94v11.78h-6.94V307h-6.94v11.77h-6.95v11.78h-6.94v30.63H27.77v-7.07h-6.94v-11.78h-6.95v-11.78H6.94v-11.78H0v-35.33h6.94v11.78h6.94V307h6.95v11.77h6.94V307h6.94v-11.78h6.94v-11.78h6.94v-11.78h6.95v-11.78h6.94V248.1h-6.94v-11.78h-6.95v-11.78H27.77v11.78h13.88v11.78h-6.94v11.78h-6.94v11.78h-6.94v-11.78h-6.95V248.1H6.94v-11.78H0Zm0 47.11h6.94v11.78H0Zm76.36 35.34h4.17V307h-4.17Zm-13.88 23.55h6.94v11.78h6.94v11.78h4.17v18.85h-4.17v-7.07h-6.94v-11.78h-6.94Zm-13.89 35.34h6.95v7.07h-6.95Z"
            className="Belarus_svg__cls-2"
          />
        </g>
        <g id="Belarus_svg__q-2" data-name="q">
          <path
            d="M131.9.75h-20.83v11.78h-6.94v11.78h-6.94v11.78h-6.94v11.78h-7v11.77h7v11.78h6.94V83.2h6.94V95h6.94v11.78h7v11.78H125v-11.8h6.94V95h6.94V83.2h6.94V71.42h6.95V59.64h6.94V47.87h-6.94V36.09h-6.95V24.31h-6.94V12.53h-6.98ZM125 24.31h-7v11.78h-7v11.78h-6.94v11.77H111v11.78h7V83.2h7V71.42h6.94V59.64h6.94V47.87h-6.98V36.09H125Zm0 23.56h-7v11.77h7Zm34.71-35.34h-6.94v11.78h6.94Zm0 70.67h-6.94V95h6.94ZM83.3.75h-4.16v23.56h4.16Zm0 82.45h-4.16v23.56h4.16Zm62.48 23.56h-6.94v11.78h-6.94v11.78H125v11.77h6.94v11.78h6.94v11.78h6.94v-11.78h6.95v-11.78h6.94v-11.77h-6.94v-11.78h-6.95Zm0 23.56h-6.94v11.77h6.94Zm-41.65-23.56h-6.94v11.78h-6.94v11.78h-7v11.77h7v11.78h6.94v11.78h6.94v-11.78h6.94v-11.78h7v-11.77h-7v-11.78h-6.94Zm0 23.56h-6.94v11.77h6.94Zm55.54 47.11h-6.94v11.78h6.94ZM83.3 165.65h-4.16v23.56h4.16ZM159.67 212.77h-6.94V201h-6.95v-11.79h-6.94v-11.78h-6.94v-11.78H125v-11.78h-7v11.78h-7v11.78h-6.94v11.78h-6.87V201h-6.94v11.78h-7v11.77h-4.11v47.12h4.16v11.78h7v11.78h6.94V307h6.94v11.77h6.94v11.78h7v30.63h13.78v-7.07h6.94v-11.78h6.94v-11.78h6.95v-11.78h6.94v-35.33h-6.94v11.78h-6.95V307h-6.94v11.77h-6.94V307H125v-11.78h-7v-11.78h-7v-11.78h-6.94v-11.78h-6.87V248.1h6.94v-11.78h6.94v-11.78h20.83v11.78H118v11.78h7v11.78h6.94v11.78h6.94v-11.78h6.94V248.1h6.95v-11.78h6.94Zm0 47.11h-6.94v11.78h6.94ZM83.3 295.22h-4.16V307h4.16Zm13.89 23.55h-6.94v11.78h-7v11.78h-4.11v18.85h4.16v-7.07h7v-11.78h6.94Zm13.88 35.34h-6.94v7.07h6.94Z"
            className="Belarus_svg__cls-2"
          />
        </g>
      </g>
      <g id="Belarus_svg__h-2" data-name="h">
        <g id="Belarus_svg__q-3" data-name="q">
          <path
            d="M27.77 719.25h20.82v-11.78h6.95v-11.78h6.94v-11.78h6.94v-11.77h6.94v-11.78h-6.94v-11.78h-6.94V636.8h-6.94V625h-6.95v-11.76h-6.94v-11.78h-6.94v11.78h-6.94V625h-6.94v11.8h-6.95v11.78H6.94v11.78H0v11.78h6.94v11.77h6.94v11.78h6.95v11.78h6.94Zm6.94-23.56h6.94v-11.78h6.94v-11.77h6.95v-11.78h-6.95v-11.78h-6.94V636.8h-6.94v11.78h-6.94v11.78h-6.94v11.78h6.94v11.77h6.94Zm0-23.55h6.94v-11.78h-6.94ZM0 707.47h6.94v-11.78H0Zm0-70.67h6.94V625H0Zm76.36 82.45h4.17v-23.56h-4.17Zm0-82.45h4.17v-23.56h-4.17Zm-62.48-23.56h6.95v-11.78h6.94v-11.77h6.94v-11.78h-6.94v-11.78h-6.94v-11.78h-6.95v11.78H6.94v11.78H0v11.78h6.94v11.77h6.94Zm0-23.55h6.95v-11.78h-6.95Zm41.66 23.55h6.94v-11.78h6.94v-11.77h6.94v-11.78h-6.94v-11.78h-6.94v-11.78h-6.94v11.78h-6.95v11.78h-6.94v11.78h6.94v11.77h6.95Zm0-23.55h6.94v-11.78h-6.94ZM0 542.57h6.94v-11.78H0Zm76.36 11.78h4.17v-23.56h-4.17ZM0 507.23h6.94V519h6.94v11.78h6.95v11.78h6.94v11.78h6.94v11.78h6.94v-11.77h6.94v-11.78h6.95v-11.78h6.94V519h6.94v-11.77h6.94v-11.77h4.17v-47.12h-4.17v-11.78h-6.94v-11.78h-6.94V413h-6.94v-11.77h-6.95v-11.78h-6.94v-30.63H27.77v7.07h-6.94v11.78h-6.95v11.78H6.94v11.78H0v35.33h6.94v-11.78h6.94V413h6.95v-11.77h6.94V413h6.94v11.78h6.94v11.78h6.94v11.78h6.95v11.78h6.94v11.78h-6.94v11.77h-6.95v11.79H27.77v-11.79h13.88V471.9h-6.94v-11.78h-6.94v-11.78h-6.94v11.78h-6.95v11.78H6.94v11.77H0Zm0-47.11h6.94v-11.78H0Zm76.36-35.34h4.17V413h-4.17Zm-13.88-23.55h6.94v-11.78h6.94v-11.78h4.17v-18.85h-4.17v7.07h-6.94v11.78h-6.94Zm-13.89-35.34h6.95v-7.07h-6.95Z"
            className="Belarus_svg__cls-2"
          />
        </g>
        <g id="Belarus_svg__q-4" data-name="q">
          <path
            d="M131.9 719.25h-20.83v-11.78h-6.94v-11.78h-6.94v-11.78h-6.94v-11.77h-7v-11.78h7v-11.78h6.94V636.8h6.94V625h6.94v-11.76h7v-11.78H125v11.78h6.94V625h6.94v11.8h6.94v11.78h6.95v11.78h6.94v11.78h-6.94v11.77h-6.95v11.78h-6.94v11.78h-6.98Zm-6.9-23.56h-7v-11.78h-7v-11.77h-6.94v-11.78H111v-11.78h7V636.8h7v11.78h6.94v11.78h6.94v11.78h-6.98v11.77H125Zm0-23.55h-7v-11.78h7Zm34.71 35.33h-6.94v-11.78h6.94Zm0-70.67h-6.94V625h6.94ZM83.3 719.25h-4.16v-23.56h4.16Zm0-82.45h-4.16v-23.56h4.16Zm62.48-23.56h-6.94v-11.78h-6.94v-11.77H125v-11.78h6.94v-11.78h6.94v-11.78h6.94v11.78h6.95v11.78h6.94v11.78h-6.94v11.77h-6.95Zm0-23.55h-6.94v-11.78h6.94Zm-41.65 23.55h-6.94v-11.78h-6.94v-11.77h-7v-11.78h7v-11.78h6.94v-11.78h6.94v11.78h6.94v11.78h7v11.78h-7v11.77h-6.94Zm0-23.55h-6.94v-11.78h6.94Zm55.54-47.12h-6.94v-11.78h6.94ZM83.3 554.35h-4.16v-23.56h4.16ZM159.67 507.23h-6.94V519h-6.95v11.78h-6.94v11.78h-6.94v11.78H125v11.78h-7v-11.77h-7v-11.78h-6.94v-11.78h-6.87V519h-6.94v-11.77h-7v-11.77h-4.11v-47.12h4.16v-11.78h7v-11.78h6.94V413h6.94v-11.77h6.94v-11.78h7v-30.63h13.78v7.07h6.94v11.78h6.94v11.78h6.95v11.78h6.94v35.33h-6.94v-11.78h-6.95V413h-6.94v-11.77h-6.94V413H125v11.78h-7v11.78h-7v11.78h-6.94v11.78h-6.87v11.78h6.94v11.77h6.94v11.79h20.83v-11.79H118V471.9h7v-11.78h6.94v-11.78h6.94v11.78h6.94v11.78h6.95v11.77h6.94Zm0-47.11h-6.94v-11.78h6.94ZM83.3 424.78h-4.16V413h4.16Zm13.89-23.55h-6.94v-11.78h-7v-11.78h-4.11v-18.85h4.16v7.07h7v11.78h6.94Zm13.88-35.34h-6.94v-7.07h6.94Z"
            className="Belarus_svg__cls-2"
          />
        </g>
      </g>
    </g>
    <g id="Belarus_svg__Georgia">
      <g
        id="Belarus_svg__smallcross"
        style={{
          clipPath: "url(#Belarus_svg__clip-path)",
        }}
      >
        <path
          id="Belarus_svg__varm"
          d="M807.06 22.09c0-109.13 88.47-197.59 197.59-197.59S1202.23-87 1202.23 22.09v-531.68c0 109.12-88.46 197.58-197.58 197.58s-197.59-88.46-197.59-197.58Z"
          className="Belarus_svg__cls-6"
        />
      </g>
      <g
        id="Belarus_svg__smallcross-2"
        data-name="smallcross"
        style={{
          clipPath: "url(#Belarus_svg__clip-path-2)",
        }}
      >
        <path
          id="Belarus_svg__varm-2"
          d="M72.4 22.09C72.4-87 160.86-175.5 270-175.5S467.57-87 467.57 22.09v-531.68C467.57-400.47 379.11-312 270-312S72.4-400.47 72.4-509.59Z"
          className="Belarus_svg__cls-6"
          data-name="varm"
        />
      </g>
    </g>
  </svg>
);
export default SvgBelarus;
