import * as React from "react";
import type { SVGProps } from "react";
const SvgYemen = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.33h1280v720.67H0z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0-.33h1280v240.22H0z"
      style={{
        fill: "#ce1126",
      }}
    />
    <path d="M0 480.11h1280v240.22H0z" />
  </svg>
);
export default SvgYemen;
