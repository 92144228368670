import * as React from "react";
import type { SVGProps } from "react";
const SvgPhilippines = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Philippines_svg__cls-4{fill:#fcd116}"}</style>
    </defs>
    <g id="Philippines_svg__Philippines">
      <path
        d="M.81.4H1279.2v719.19H.81z"
        style={{
          fill: "#0038a8",
        }}
      />
      <path
        d="M.81 360H1279.2v359.6H.81z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        d="M553.67 360 .81 719.6V.4"
        style={{
          fill: "#fff",
        }}
      />
      <circle
        cx={199.42}
        cy={361.6}
        r={63.84}
        className="Philippines_svg__cls-4"
      />
      <g id="Philippines_svg__h">
        <path
          d="m64.65 361.6 8.29 8.29 126.48-8.29-126.48-8.29Z"
          className="Philippines_svg__cls-4"
        />
        <path
          id="Philippines_svg__g"
          d="m78.83 377.47 6.77 6.77 113.82-22.64Z"
          className="Philippines_svg__cls-4"
        />
        <path
          id="Philippines_svg__g-2"
          d="M78.83 345.72 85.6 339l113.82 22.6Z"
          className="Philippines_svg__cls-4"
          data-name="g"
        />
      </g>
      <g id="Philippines_svg__i">
        <g id="Philippines_svg__h-2" data-name="h">
          <path
            d="M104.12 266.3V278l95.3 83.58-83.58-95.3Z"
            className="Philippines_svg__cls-4"
          />
          <path
            id="Philippines_svg__g-3"
            d="M102.92 287.56v9.56l96.5 64.48Z"
            className="Philippines_svg__cls-4"
            data-name="g"
          />
          <path
            id="Philippines_svg__g-4"
            d="M125.38 265.11h9.56l64.48 96.49Z"
            className="Philippines_svg__cls-4"
            data-name="g"
          />
        </g>
      </g>
      <g id="Philippines_svg__j">
        <g id="Philippines_svg__h-3" data-name="h">
          <path
            d="m199.42 226.83-8.29 8.29 8.29 126.48 8.29-126.48Z"
            className="Philippines_svg__cls-4"
          />
          <path
            id="Philippines_svg__g-5"
            d="m183.54 241-6.76 6.77 22.64 113.83Z"
            className="Philippines_svg__cls-4"
            data-name="g"
          />
          <path
            id="Philippines_svg__g-6"
            d="m215.29 241 6.77 6.77-22.64 113.83Z"
            className="Philippines_svg__cls-4"
            data-name="g"
          />
        </g>
        <g id="Philippines_svg__i-2" data-name="i">
          <g id="Philippines_svg__h-4" data-name="h">
            <path
              d="M294.71 266.3H283l-83.57 95.3 95.28-83.6Z"
              className="Philippines_svg__cls-4"
            />
            <path
              id="Philippines_svg__g-7"
              d="M273.46 265.11h-9.57l-64.47 96.49Z"
              className="Philippines_svg__cls-4"
              data-name="g"
            />
            <path
              id="Philippines_svg__g-8"
              d="M295.91 287.56v9.56l-96.49 64.48Z"
              className="Philippines_svg__cls-4"
              data-name="g"
            />
          </g>
        </g>
      </g>
      <g id="Philippines_svg__h-5" data-name="h">
        <path
          d="m334.19 361.6-8.29-8.29-126.48 8.29 126.48 8.29Z"
          className="Philippines_svg__cls-4"
        />
        <path
          id="Philippines_svg__g-9"
          d="m320 345.72-6.76-6.72-113.82 22.6Z"
          className="Philippines_svg__cls-4"
          data-name="g"
        />
        <path
          id="Philippines_svg__g-10"
          d="m320 377.47-6.76 6.77-113.82-22.64Z"
          className="Philippines_svg__cls-4"
          data-name="g"
        />
      </g>
      <g id="Philippines_svg__i-3" data-name="i">
        <g id="Philippines_svg__h-6" data-name="h">
          <path
            d="M294.71 456.89v-11.72l-95.29-83.57L283 456.89Z"
            className="Philippines_svg__cls-4"
          />
          <path
            id="Philippines_svg__g-11"
            d="M295.91 435.64v-9.57l-96.49-64.47Z"
            className="Philippines_svg__cls-4"
            data-name="g"
          />
          <path
            id="Philippines_svg__g-12"
            d="M273.46 458.09h-9.57l-64.47-96.49Z"
            className="Philippines_svg__cls-4"
            data-name="g"
          />
        </g>
      </g>
      <g id="Philippines_svg__j-2" data-name="j">
        <g id="Philippines_svg__h-7" data-name="h">
          <path
            d="m199.42 496.37 8.29-8.29-8.29-126.48-8.29 126.48Z"
            className="Philippines_svg__cls-4"
          />
          <path
            id="Philippines_svg__g-13"
            d="m215.29 482.18 6.77-6.76-22.64-113.82Z"
            className="Philippines_svg__cls-4"
            data-name="g"
          />
          <path
            id="Philippines_svg__g-14"
            d="m183.54 482.18-6.76-6.76 22.64-113.82Z"
            className="Philippines_svg__cls-4"
            data-name="g"
          />
        </g>
        <g id="Philippines_svg__i-4" data-name="i">
          <g id="Philippines_svg__h-8" data-name="h">
            <path
              d="M104.12 456.89h11.72l83.58-95.29-95.3 83.57Z"
              className="Philippines_svg__cls-4"
            />
            <path
              id="Philippines_svg__g-15"
              d="M125.38 458.09h9.56l64.48-96.49Z"
              className="Philippines_svg__cls-4"
              data-name="g"
            />
            <path
              id="Philippines_svg__g-16"
              d="M102.92 435.64v-9.57l96.5-64.47Z"
              className="Philippines_svg__cls-4"
              data-name="g"
            />
          </g>
        </g>
      </g>
      <g id="Philippines_svg__s">
        <path
          id="Philippines_svg__t"
          d="m489.83 361.6-24 8.33-.52 25.4L450 375.09l-24.31 7.35 14.5-20.84Z"
          className="Philippines_svg__cls-4"
        />
        <path
          id="Philippines_svg__t-2"
          d="m489.83 361.6-24-8.34-.52-25.39L450 348.1l-24.31-7.35 14.5 20.85Z"
          className="Philippines_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Philippines_svg__s-2" data-name="s">
        <path
          id="Philippines_svg__t-3"
          d="m32.73 625.5 4.77-24.94-21.73-13.14L41 584.25l5.78-24.73 10.8 23Z"
          className="Philippines_svg__cls-4"
          data-name="t"
        />
        <path
          id="Philippines_svg__t-4"
          d="m32.73 625.5 19.21-16.6 22.25 12.25-9.85-23.41 18.52-17.38-25.31 2.14Z"
          className="Philippines_svg__cls-4"
          data-name="t"
        />
      </g>
      <g id="Philippines_svg__s-3" data-name="s">
        <path
          id="Philippines_svg__t-5"
          d="m32.73 97.69 19.21 16.61 22.25-12.25-9.85 23.4 18.52 17.38-25.31-2.14Z"
          className="Philippines_svg__cls-4"
          data-name="t"
        />
        <path
          id="Philippines_svg__t-6"
          d="m32.73 97.69 4.77 24.94-21.73 13.15L41 139l5.78 24.73 10.8-23Z"
          className="Philippines_svg__cls-4"
          data-name="t"
        />
      </g>
    </g>
  </svg>
);
export default SvgPhilippines;
