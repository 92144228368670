import * as React from "react";
import type { SVGProps } from "react";
const SvgBurkinaFaso = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Burkina_Faso_svg__cls-3{fill:#fcd116}"}</style>
    </defs>
    <g id="Burkina_Faso_svg__Burkina_Faso" data-name="Burkina Faso">
      <path
        d="M0-.33h1280v720.67H0z"
        style={{
          fill: "#009e49",
        }}
      />
      <path
        d="M0-.33h1280V360H0z"
        style={{
          fill: "#ef2b2d",
        }}
      />
      <g id="Burkina_Faso_svg__c">
        <path
          id="Burkina_Faso_svg__t"
          d="m640.5 239.89-37.12 114.23 57.12 18.56"
          className="Burkina_Faso_svg__cls-3"
        />
        <path
          id="Burkina_Faso_svg__t-2"
          d="m640.5 239.89 37.12 114.23-57.12 18.56"
          className="Burkina_Faso_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Burkina_Faso_svg__c-2" data-name="c">
        <path
          id="Burkina_Faso_svg__t-3"
          d="M754.73 322.88H634.62v60.06"
          className="Burkina_Faso_svg__cls-3"
          data-name="t"
        />
        <path
          id="Burkina_Faso_svg__t-4"
          d="m754.73 322.88-97.17 70.6-35.3-48.58"
          className="Burkina_Faso_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Burkina_Faso_svg__c-3" data-name="c">
        <path
          id="Burkina_Faso_svg__t-5"
          d="M711.1 457.17 674 342.94l-57.13 18.56"
          className="Burkina_Faso_svg__cls-3"
          data-name="t"
        />
        <path
          id="Burkina_Faso_svg__t-6"
          d="m711.1 457.17-97.17-70.6 35.3-48.57"
          className="Burkina_Faso_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Burkina_Faso_svg__c-4" data-name="c">
        <path
          id="Burkina_Faso_svg__t-7"
          d="m569.9 457.17 97.17-70.6-35.3-48.57"
          className="Burkina_Faso_svg__cls-3"
          data-name="t"
        />
        <path
          id="Burkina_Faso_svg__t-8"
          d="M569.9 457.17 607 342.94l57.11 18.56"
          className="Burkina_Faso_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Burkina_Faso_svg__c-5" data-name="c">
        <path
          id="Burkina_Faso_svg__t-9"
          d="m526.27 322.88 97.17 70.6 35.3-48.58"
          className="Burkina_Faso_svg__cls-3"
          data-name="t"
        />
        <path
          id="Burkina_Faso_svg__t-10"
          d="M526.27 322.88h120.11v60.06"
          className="Burkina_Faso_svg__cls-3"
          data-name="t"
        />
      </g>
    </g>
  </svg>
);
export default SvgBurkinaFaso;
