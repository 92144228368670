import * as React from "react";
import type { SVGProps } from "react";
const SvgCuba = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Cuba_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Cuba_svg__Cuba">
      <path
        id="Cuba_svg__blue_stripes"
        d="M0-.25h1280v720.5H0z"
        data-name="blue stripes"
        style={{
          fill: "#002a8f",
        }}
      />
      <path
        id="Cuba_svg__white_stripe_1"
        d="M0 143.85h1280v144.1H0z"
        className="Cuba_svg__cls-2"
        data-name="white stripe 1"
      />
      <path
        id="Cuba_svg__white_stripe_2"
        d="M0 432.05h1280v144.1H0z"
        className="Cuba_svg__cls-2"
        data-name="white stripe 2"
      />
      <path
        id="Cuba_svg__triangle"
        d="M0-.25v720.5L624 360Z"
        style={{
          fill: "#cf142b",
        }}
      />
      <path
        id="Cuba_svg__star"
        d="m208 251.92-24.26 74.7H105.2l63.5 46.16-24.26 74.64L208 401.26l63.55 46.16-24.26-74.64 63.49-46.16h-78.53Z"
        className="Cuba_svg__cls-2"
      />
    </g>
  </svg>
);
export default SvgCuba;
