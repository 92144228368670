import * as React from "react";
import type { SVGProps } from "react";
const SvgAustralia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Australia_svg__Australia"
    viewBox="0 0 1280 720"
    {...props}
  >
    <defs>
      <style>
        {".Australia_svg__cls-2{fill:#fff}.Australia_svg__cls-3{fill:#ea0020}"}
      </style>
    </defs>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#002776",
      }}
    />
    <path
      d="m319.74 445.45 19.5 60.62 59.5-22.54-35.22 52.98 54.71 32.46-63.38 5.53 8.72 62.99-43.83-46.07-43.83 46.07 8.75-62.99-63.38-5.53 54.7-32.46-35.21-52.98 59.5 22.54zM993.22 536.51l9.29 28.83 28.31-10.7-16.74 25.21 26.06 15.48-30.21 2.62 4.17 29.99-20.88-21.97-20.86 21.97 4.14-29.99-30.18-2.62 26.06-15.48-16.77-25.21 28.35 10.7zM824.86 269.94l9.29 28.85 28.32-10.76-16.77 25.27 26.05 15.41-30.17 2.64 4.15 29.98-20.87-21.92-20.89 21.92 4.17-29.98-30.17-2.64L804 313.3l-16.75-25.27 28.32 10.76zM993.22 87.51l9.29 28.85 28.31-10.7-16.74 25.22 26.06 15.47-30.21 2.62 4.17 30-20.88-21.94-20.86 21.94 4.14-30-30.18-2.62 26.06-15.47-16.77-25.22 28.35 10.7zM1142.9 225.04l9.26 28.82 28.34-10.7-16.77 25.21 26.06 15.45-30.19 2.65 4.16 30.02-20.86-21.97-20.89 21.97 4.17-30.02-30.2-2.65 26.06-15.45-16.77-25.21 28.35 10.7zM1060.59 360.11l7.33 17.99 19.34 1.41-14.81 12.54 4.62 18.84-16.48-10.22-16.52 10.22 4.65-18.84-14.83-12.54 19.37-1.41zM266.65 0v120.12H0v120.19h266.65V360.5h106.68V240.31H640V120.12H373.33V0z"
      className="Australia_svg__cls-2"
    />
    <path
      d="M0 0v40.28L568.43 360.5h71.54v-40.34L71.57 0z"
      className="Australia_svg__cls-2"
    />
    <path
      d="M568.43 0 0 320.16v40.34h71.57l568.4-320.22V0z"
      className="Australia_svg__cls-2"
    />
    <path
      d="M287.99 0v144.19H0v72.06h287.99V360.5h64.02V216.25h287.96v-72.06H352.01V0z"
      className="Australia_svg__cls-3"
    />
    <path
      d="M0 0v26.89l165.63 93.23h47.71Zm592.29 0L379 120.12h47.69L640 0ZM213.34 240.31 0 360.5h47.71L261 240.31Zm213.32 0L640 360.5v-26.89l-165.63-93.3Z"
      className="Australia_svg__cls-3"
    />
  </svg>
);
export default SvgAustralia;
