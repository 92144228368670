import * as React from "react";
import type { SVGProps } from "react";

const SvgTurksAndCaicosIslands = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20" {...props}>
    {/* Blue Background */}
    <rect width="30" height="20" fill="#0033a0" />
    
    {/* Red Cross */}
    <rect x="10" y="0" width="2" height="20" fill="#c8102e" />
    <rect x="0" y="8" width="30" height="2" fill="#c8102e" />
    
    {/* Union Jack */}
    <rect x="1" y="1" width="2" height="18" fill="#ffffff" />
    <rect x="0" y="2" width="2" height="16" fill="#0033a0" />
    <rect x="0" y="7" width="2" height="2" fill="#ffffff" />
    <rect x="1" y="0" width="2" height="2" fill="#0033a0" />
  </svg>
);

export default SvgTurksAndCaicosIslands;