import * as React from "react";
import type { SVGProps } from "react";
const SvgIceland = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Iceland_svg__cls-2{fill:#fff}.Iceland_svg__cls-3{fill:#d62a28}"}
      </style>
    </defs>
    <g id="Iceland_svg__Iceland">
      <path
        d="M0-.3h1280v720.6H0"
        style={{
          fill: "#22408e",
        }}
      />
      <path d="M358.4-.3h204.8v720.6H358.4" className="Iceland_svg__cls-2" />
      <path d="M0 240h1280v204.8H0" className="Iceland_svg__cls-2" />
      <path d="M409.6-.3H512v720.6H409.6" className="Iceland_svg__cls-3" />
      <path d="M0 291.2h1280v102.4H0" className="Iceland_svg__cls-3" />
    </g>
  </svg>
);
export default SvgIceland;
