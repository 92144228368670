import * as React from "react";
import type { SVGProps } from "react";
const SvgItaly = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 .79h440.43v720.6H0z"
      style={{
        fill: "#019147",
      }}
    />
    <path
      d="M399.14.79h440.43v720.6H399.14z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M839.57.79H1280v720.6H839.57z"
      style={{
        fill: "#cd2a37",
      }}
    />
  </svg>
);
export default SvgItaly;
