import * as React from "react";
import type { SVGProps } from "react";
const SvgEastTimor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".East_Timor_svg__cls-3{fill:#fff}"}</style>
    </defs>
    <g id="East_Timor_svg__East_Timor">
      <path
        d="M1280 720.5V-.5H0v721Z"
        style={{
          fill: "#dc241f",
        }}
      />
      <path
        d="M0-.5v721L721 360Z"
        style={{
          fill: "#ffc726",
        }}
      />
      <path d="M0-.5v721L480.67 360Z" />
      <path
        id="East_Timor_svg__o"
        d="m123.82 247.15 18.79 124.76L202 363Z"
        className="East_Timor_svg__cls-3"
      />
      <path
        id="East_Timor_svg__o-2"
        d="M123.82 247.15 212.36 337l-42.8 42.16Z"
        className="East_Timor_svg__cls-3"
        data-name="o"
      />
      <path
        id="East_Timor_svg__o-3"
        d="m270.14 271.46-112.85 56.43 26.87 53.74Z"
        className="East_Timor_svg__cls-3"
        data-name="o"
      />
      <path
        id="East_Timor_svg__o-4"
        d="M270.14 271.46 212 383.45l-53.33-27.69Z"
        className="East_Timor_svg__cls-3"
        data-name="o"
      />
      <path
        id="East_Timor_svg__o-5"
        d="M55.48 378.79 180 399.48l9.85-59.28Z"
        className="East_Timor_svg__cls-3"
        data-name="o"
      />
      <path
        id="East_Timor_svg__o-6"
        d="m55.48 378.79 112.86-56.43 26.87 53.74Z"
        className="East_Timor_svg__cls-3"
        data-name="o"
      />
      <path
        id="East_Timor_svg__o-7"
        d="m292.23 418.13-88.53-89.89-42.81 42.16Z"
        className="East_Timor_svg__cls-3"
        data-name="o"
      />
      <path
        id="East_Timor_svg__o-8"
        d="m292.23 418.13-124.46-20.68 9.85-59.27Z"
        className="East_Timor_svg__cls-3"
        data-name="o"
      />
      <path
        id="East_Timor_svg__o-9"
        d="m159.57 484.47 58.13-112-53.33-27.67Z"
        className="East_Timor_svg__cls-3"
        data-name="o"
      />
      <path
        id="East_Timor_svg__o-10"
        d="m159.57 484.47-18.8-124.77 59.42-8.95Z"
        className="East_Timor_svg__cls-3"
        data-name="o"
      />
    </g>
  </svg>
);
export default SvgEastTimor;
