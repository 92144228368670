import * as React from "react";
import type { SVGProps } from "react";

const SvgVirginIslandsUs = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".cls-2{fill:#fc0}"}</style>
    </defs>
    <g id="VirginIslandsUs_svg__VirginIslandsUs">
      {/* Flag background */}
      <rect width="1280" height="720" fill="#fff" />

      {/* US Virgin Islands Coat of Arms */}
      <g id="VirginIslandsUs_svg__CoatOfArms">
        {/* Eagle body */}
        <path
          className="cls-2"
          d="M640 200l-60 40 60 60 60-60z"
        />

        {/* Olive branch in the eagle's right claw */}
        <path
          fill="#008000"
          d="M400 350l20-10-5 10-15 10z"
        />

        {/* Arrows in the eagle's left claw */}
        <path
          fill="#A3A3A3"
          d="M880 350l-20-10 5 10 15 10z"
        />
      </g>

      {/* "V" and "I" text */}
      <text
        x="200"
        y="600"
        fontSize="200"
        fontWeight="bold"
        fill="#002868"
      >
        V
      </text>
      <text
        x="1000"
        y="600"
        fontSize="200"
        fontWeight="bold"
        fill="#002868"
      >
        I
      </text>
    </g>
  </svg>
);

export default SvgVirginIslandsUs;