import * as React from "react";
import type { SVGProps } from "react";
const SvgIndia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".India_svg__cls-2{fill:#fff}.India_svg__cls-4{fill:#008}"}
      </style>
    </defs>
    <g id="India_svg__India">
      <path
        d="M0 .18h1280v719.64H0z"
        style={{
          fill: "#f93",
        }}
      />
      <path d="M0 240.06h1280v239.88H0z" className="India_svg__cls-2" />
      <path
        d="M0 479.94h1280v239.88H0z"
        style={{
          fill: "#128807",
        }}
      />
      <circle cx={640} cy={360} r={95.95} className="India_svg__cls-4" />
      <circle cx={640} cy={360} r={83.96} className="India_svg__cls-2" />
      <circle cx={640} cy={360} r={16.79} className="India_svg__cls-4" />
      <g id="India_svg__b">
        <g id="India_svg__a">
          <circle
            cx={723.24}
            cy={370.96}
            r={4.2}
            className="India_svg__cls-4"
          />
          <path
            d="m640 444 2.88-50.38-2.88-24-2.88 24Z"
            className="India_svg__cls-4"
          />
        </g>
        <g id="India_svg__a-2" data-name="a">
          <circle
            cx={717.57}
            cy={392.13}
            r={4.2}
            className="India_svg__cls-4"
          />
          <path
            d="m618.27 441.1 15.82-47.92 3.43-23.91-9 22.42Z"
            className="India_svg__cls-4"
          />
        </g>
      </g>
      <g id="India_svg__c">
        <g id="India_svg__b-2" data-name="b">
          <g id="India_svg__a-3" data-name="a">
            <circle
              cx={706.61}
              cy={411.11}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m598 432.71 27.68-42.19 9.5-22.21-14.48 19.33Z"
              className="India_svg__cls-4"
            />
          </g>
          <g id="India_svg__a-4" data-name="a">
            <circle
              cx={691.11}
              cy={426.61}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m580.63 419.37 37.66-33.59 14.93-19-19 14.93Z"
              className="India_svg__cls-4"
            />
          </g>
        </g>
      </g>
      <g id="India_svg__d">
        <g id="India_svg__b-3" data-name="b">
          <g id="India_svg__a-5" data-name="a">
            <circle
              cx={672.13}
              cy={437.57}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m567.29 402 45.07-22.7 19.33-14.48-22.21 9.5Z"
              className="India_svg__cls-4"
            />
          </g>
          <g id="India_svg__a-6" data-name="a">
            <circle
              cx={650.96}
              cy={443.24}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m558.9 381.73 49.41-10.26 22.42-9-23.91 3.43Z"
              className="India_svg__cls-4"
            />
          </g>
        </g>
        <g id="India_svg__c-2" data-name="c">
          <g id="India_svg__b-4" data-name="b">
            <g id="India_svg__a-7" data-name="a">
              <circle
                cx={629.04}
                cy={443.24}
                r={4.2}
                className="India_svg__cls-4"
              />
              <path
                d="m556 360 50.38 2.88 24-2.88-24-2.88Z"
                className="India_svg__cls-4"
              />
            </g>
            <g id="India_svg__a-8" data-name="a">
              <circle
                cx={607.87}
                cy={437.57}
                r={4.2}
                className="India_svg__cls-4"
              />
              <path
                d="m558.9 338.27 47.92 15.82 23.91 3.43-22.42-9Z"
                className="India_svg__cls-4"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="India_svg__b-5" data-name="b">
        <g id="India_svg__a-9" data-name="a">
          <circle
            cx={588.89}
            cy={426.61}
            r={4.2}
            className="India_svg__cls-4"
          />
          <path
            d="m567.29 318 42.19 27.68 22.21 9.5-19.33-14.48Z"
            className="India_svg__cls-4"
          />
        </g>
        <g id="India_svg__a-10" data-name="a">
          <circle
            cx={573.39}
            cy={411.11}
            r={4.2}
            className="India_svg__cls-4"
          />
          <path
            d="m580.63 300.63 33.59 37.66 19 14.93-14.93-19Z"
            className="India_svg__cls-4"
          />
        </g>
      </g>
      <g id="India_svg__c-3" data-name="c">
        <g id="India_svg__b-6" data-name="b">
          <g id="India_svg__a-11" data-name="a">
            <circle
              cx={562.43}
              cy={392.13}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m598 287.29 22.7 45.07 14.48 19.33-9.5-22.21Z"
              className="India_svg__cls-4"
            />
          </g>
          <g id="India_svg__a-12" data-name="a">
            <circle
              cx={556.76}
              cy={370.96}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m618.27 278.9 10.26 49.41 9 22.42-3.43-23.91Z"
              className="India_svg__cls-4"
            />
          </g>
        </g>
      </g>
      <g id="India_svg__d-2" data-name="d">
        <g id="India_svg__b-7" data-name="b">
          <g id="India_svg__a-13" data-name="a">
            <circle
              cx={556.76}
              cy={349.04}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m640 276-2.88 50.38 2.88 24 2.88-24Z"
              className="India_svg__cls-4"
            />
          </g>
          <g id="India_svg__a-14" data-name="a">
            <circle
              cx={562.43}
              cy={327.87}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m661.73 278.9-15.82 47.92-3.43 23.91 9-22.42Z"
              className="India_svg__cls-4"
            />
          </g>
        </g>
        <g id="India_svg__c-4" data-name="c">
          <g id="India_svg__b-8" data-name="b">
            <g id="India_svg__a-15" data-name="a">
              <circle
                cx={573.39}
                cy={308.89}
                r={4.2}
                className="India_svg__cls-4"
              />
              <path
                d="m682 287.29-27.7 42.19-9.5 22.21 14.48-19.33Z"
                className="India_svg__cls-4"
              />
            </g>
            <g id="India_svg__a-16" data-name="a">
              <circle
                cx={588.89}
                cy={293.39}
                r={4.2}
                className="India_svg__cls-4"
              />
              <path
                d="m699.37 300.63-37.66 33.59-14.93 19 19-14.93Z"
                className="India_svg__cls-4"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="India_svg__b-9" data-name="b">
        <g id="India_svg__a-17" data-name="a">
          <circle
            cx={607.87}
            cy={282.43}
            r={4.2}
            className="India_svg__cls-4"
          />
          <path
            d="m712.71 318-45.07 22.7-19.33 14.5 22.21-9.5Z"
            className="India_svg__cls-4"
          />
        </g>
        <g id="India_svg__a-18" data-name="a">
          <circle
            cx={629.04}
            cy={276.76}
            r={4.2}
            className="India_svg__cls-4"
          />
          <path
            d="m721.1 338.27-49.41 10.26-22.42 9 23.91-3.43Z"
            className="India_svg__cls-4"
          />
        </g>
      </g>
      <g id="India_svg__c-5" data-name="c">
        <g id="India_svg__b-10" data-name="b">
          <g id="India_svg__a-19" data-name="a">
            <circle
              cx={650.96}
              cy={276.76}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m724 360-50.38-2.88-24 2.88 24 2.88Z"
              className="India_svg__cls-4"
            />
          </g>
          <g id="India_svg__a-20" data-name="a">
            <circle
              cx={672.13}
              cy={282.43}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m721.1 381.73-47.92-15.82-23.91-3.43 22.42 9Z"
              className="India_svg__cls-4"
            />
          </g>
        </g>
      </g>
      <g id="India_svg__d-3" data-name="d">
        <g id="India_svg__b-11" data-name="b">
          <g id="India_svg__a-21" data-name="a">
            <circle
              cx={691.11}
              cy={293.39}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m712.71 402-42.19-27.7-22.21-9.5 19.33 14.48Z"
              className="India_svg__cls-4"
            />
          </g>
          <g id="India_svg__a-22" data-name="a">
            <circle
              cx={706.61}
              cy={308.89}
              r={4.2}
              className="India_svg__cls-4"
            />
            <path
              d="m699.37 419.37-33.59-37.66-19-14.93 14.93 19Z"
              className="India_svg__cls-4"
            />
          </g>
        </g>
        <g id="India_svg__c-6" data-name="c">
          <g id="India_svg__b-12" data-name="b">
            <g id="India_svg__a-23" data-name="a">
              <circle
                cx={717.57}
                cy={327.87}
                r={4.2}
                className="India_svg__cls-4"
              />
              <path
                d="m682 432.71-22.7-45.07-14.5-19.33 9.5 22.21Z"
                className="India_svg__cls-4"
              />
            </g>
            <g id="India_svg__a-24" data-name="a">
              <circle
                cx={723.24}
                cy={349.04}
                r={4.2}
                className="India_svg__cls-4"
              />
              <path
                d="m661.73 441.1-10.26-49.41-9-22.42 3.43 23.91Z"
                className="India_svg__cls-4"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgIndia;
