import * as React from "react";
import type { SVGProps } from "react";

const SvgHongKong = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 768" {...props}>
    {/* Red background */}
    <rect width="1024" height="768" fill="#c8102e" />
    
    {/* White flower */}
    <g transform="translate(512, 384)">
      <g stroke="#c8102e" strokeWidth="20">
        <path
          d="M0,-160 L55,-55 L155,-55 L70,0 L120,100 L0,60 L-120,100 L-70,0 L-155,-55 L-55,-55 Z"
          fill="#fff"
          transform="rotate(0)"
        />
        <path
          d="M0,-160 L55,-55 L155,-55 L70,0 L120,100 L0,60 L-120,100 L-70,0 L-155,-55 L-55,-55 Z"
          fill="#fff"
          transform="rotate(72)"
        />
        <path
          d="M0,-160 L55,-55 L155,-55 L70,0 L120,100 L0,60 L-120,100 L-70,0 L-155,-55 L-55,-55 Z"
          fill="#fff"
          transform="rotate(144)"
        />
        <path
          d="M0,-160 L55,-55 L155,-55 L70,0 L120,100 L0,60 L-120,100 L-70,0 L-155,-55 L-55,-55 Z"
          fill="#fff"
          transform="rotate(216)"
        />
        <path
          d="M0,-160 L55,-55 L155,-55 L70,0 L120,100 L0,60 L-120,100 L-70,0 L-155,-55 L-55,-55 Z"
          fill="#fff"
          transform="rotate(288)"
        />
      </g>
      <circle cx="0" cy="60" r="30" fill="#c8102e" />
    </g>
  </svg>
);

export default SvgHongKong;