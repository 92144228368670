import * as React from "react";
import type { SVGProps } from "react";
const SvgPalestine = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path d="M0 0h1280v240H0z" />
    <path
      d="M0 240h1280v240H0z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0 480h1280v240H0z"
      style={{
        fill: "#007a3d",
      }}
    />
    <path
      d="m0 0 426.67 360L0 720Z"
      style={{
        fill: "#ce1126",
      }}
    />
  </svg>
);
export default SvgPalestine;
