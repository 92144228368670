import * as React from "react";
import type { SVGProps } from "react";
const SvgCanada = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Canada_svg__cls-1{fill:red}"}</style>
    </defs>
    <g id="Canada_svg__Canada">
      <path d="M0 0h1280v720H0z" className="Canada_svg__cls-1" />
      <path
        d="M320 0h640v720H320z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="m640 100-43.66 81.44c-5 8.85-13.84 8-22.71 3.09L542 168.16l23.56 125.09c5 22.85-10.94 22.85-18.78 13l-55.17-61.76-9 31.36c-1 4.12-5.57 8.45-12.39 7.42l-69.72-14.7 18.33 66.62c3.92 14.82 7 21-4 24.87L390 371.74l120.09 97.55a15.72 15.72 0 0 1 5.46 16.34L505 520.12c41.35-4.77 78.4-11.94 119.77-16.36 3.65-.39 9.77 5.64 9.74 9.87L629.07 640h20.11L646 513.91c0-4.24 5.53-10.54 9.18-10.15 41.37 4.42 78.42 11.59 119.77 16.36l-10.51-34.49a15.72 15.72 0 0 1 5.46-16.34L890 371.74l-24.87-11.68c-10.94-3.91-7.88-10.05-4-24.87l18.33-66.62-69.76 14.67c-6.82 1-11.36-3.3-12.39-7.42l-9-31.36-55.17 61.76c-7.84 9.88-23.74 9.88-18.78-13L738 168.16l-31.62 16.37c-8.87 4.94-17.75 5.76-22.71-3.09"
        className="Canada_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgCanada;
