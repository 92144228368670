import * as React from "react";
import type { SVGProps } from "react";
const SvgRepublicOfCongo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <g data-name="Republic of Congo">
      <path
        d="M0 0h1280v720H0z"
        data-name="green area"
        style={{
          fill: "#009543",
        }}
      />
      <path
        d="M0 720 853.33 0H1280v720Z"
        data-name="yellow area"
        style={{
          fill: "#fbde4a",
        }}
      />
      <path
        d="M1280 0v720H426.67Z"
        data-name="red area"
        style={{
          fill: "#dc241f",
        }}
      />
    </g>
  </svg>
);
export default SvgRepublicOfCongo;
