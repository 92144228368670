import * as React from "react";
import type { SVGProps } from "react";
const SvgCreateShipment = (props: SVGProps<SVGSVGElement>) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="path-1-inside-1_224_2281" fill="white">
    <path d="M50 84C31.2 84 16 68.8 16 50C16 31.2 31.2 16 50 16C68.8 16 84 31.2 84 50C84 68.8 68.8 84 50 84ZM50 20C33.4 20 20 33.4 20 50C20 66.6 33.4 80 50 80C66.6 80 80 66.6 80 50C80 33.4 66.6 20 50 20Z"/>
    </mask>
    <path d="M50 84C31.2 84 16 68.8 16 50C16 31.2 31.2 16 50 16C68.8 16 84 31.2 84 50C84 68.8 68.8 84 50 84ZM50 20C33.4 20 20 33.4 20 50C20 66.6 33.4 80 50 80C66.6 80 80 66.6 80 50C80 33.4 66.6 20 50 20Z" fill="#38BA9C"/>
    <path d="M50 80C33.4091 80 20 66.5909 20 50H12C12 71.0091 28.9909 88 50 88V80ZM20 50C20 33.4091 33.4091 20 50 20V12C28.9909 12 12 28.9909 12 50H20ZM50 20C66.5909 20 80 33.4091 80 50H88C88 28.9909 71.0091 12 50 12V20ZM80 50C80 66.5909 66.5909 80 50 80V88C71.0091 88 88 71.0091 88 50H80ZM50 16C31.1909 16 16 31.1909 16 50H24C24 35.6091 35.6091 24 50 24V16ZM16 50C16 68.8091 31.1909 84 50 84V76C35.6091 76 24 64.3909 24 50H16ZM50 84C68.8091 84 84 68.8091 84 50H76C76 64.3909 64.3909 76 50 76V84ZM84 50C84 31.1909 68.8091 16 50 16V24C64.3909 24 76 35.6091 76 50H84Z" fill="#38BA9C" mask="url(#path-1-inside-1_224_2281)"/>
    <path d="M32 48H68V52H32V48Z" fill="#38BA9C"/>
    <mask id="path-4-inside-2_224_2281" fill="white">
    <path d="M48 32H52V68H48V32Z"/>
    </mask>
    <path d="M48 32H52V68H48V32Z" fill="#38BA9C"/>
    <path d="M48 32V28H44V32H48ZM52 32H56V28H52V32ZM52 68V72H56V68H52ZM48 68H44V72H48V68ZM48 36H52V28H48V36ZM48 32V68H56V32H48ZM52 64H48V72H52V64ZM52 68V32H44V68H52Z" fill="#38BA9C" mask="url(#path-4-inside-2_224_2281)"/>
  </svg>
)

export default SvgCreateShipment;





