import * as React from "react";
import type { SVGProps } from "react";
const SvgLibya = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#239e46",
      }}
    />
    <path d="M0 0h1280v540H0z" />
    <path
      d="M0 0h1280v180H0z"
      style={{
        fill: "#e70013",
      }}
    />
    <path
      d="M696.58 296.42a69.51 69.51 0 1 0 0 113.16 80 80 0 1 1 0-113.16M678.91 353l107.65-34.94L720 409.58V296.42l66.56 91.52Z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgLibya;
