import * as React from "react";
import type { SVGProps } from "react";
const SvgPanama = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Panama_svg__cls-2{fill:#005293}.Panama_svg__cls-3{fill:#d21034}"}
      </style>
    </defs>
    <g id="Panama_svg__Panama">
      <path
        d="M.48-.68h1279.03v721.36H.48z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        id="Panama_svg__t"
        d="m321 89.49-27.86 85.76L336 189.18Z"
        className="Panama_svg__cls-2"
      />
      <path
        id="Panama_svg__t-2"
        d="m321 89.49 27.87 85.76L306 189.18Z"
        className="Panama_svg__cls-2"
        data-name="t"
      />
      <path
        id="Panama_svg__t-3"
        d="M406.75 151.8h-90.17v45.08Z"
        className="Panama_svg__cls-2"
        data-name="t"
      />
      <path
        id="Panama_svg__t-4"
        d="m406.75 151.8-72.95 53-26.5-36.48Z"
        className="Panama_svg__cls-2"
        data-name="t"
      />
      <path
        id="Panama_svg__t-5"
        d="m374 252.61-27.86-85.76-42.88 13.94Z"
        className="Panama_svg__cls-2"
        data-name="t"
      />
      <path
        id="Panama_svg__t-6"
        d="m374 252.61-72.95-53 26.5-36.47Z"
        className="Panama_svg__cls-2"
        data-name="t"
      />
      <path
        id="Panama_svg__t-7"
        d="m268 252.61 72.95-53-26.5-36.47Z"
        className="Panama_svg__cls-2"
        data-name="t"
      />
      <path
        id="Panama_svg__t-8"
        d="m268 252.61 27.87-85.76 42.87 13.94Z"
        className="Panama_svg__cls-2"
        data-name="t"
      />
      <g id="Panama_svg__c">
        <path
          id="Panama_svg__t-9"
          d="m235.24 151.8 72.94 53 26.5-36.48Z"
          className="Panama_svg__cls-2"
          data-name="t"
        />
        <path
          id="Panama_svg__t-10"
          d="M235.24 151.8h90.16v45.08Z"
          className="Panama_svg__cls-2"
          data-name="t"
        />
      </g>
      <path d="M.48 360H640v360.68H.48z" className="Panama_svg__cls-2" />
      <path d="M640-.68h639.52V360H640z" className="Panama_svg__cls-3" />
      <g id="Panama_svg__c-2" data-name="c">
        <path
          id="Panama_svg__t-11"
          d="m962 450.17-27.87 85.76L977 549.86Z"
          className="Panama_svg__cls-3"
          data-name="t"
        />
        <path
          id="Panama_svg__t-12"
          d="m962 450.17 27.86 85.76L947 549.86Z"
          className="Panama_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Panama_svg__c-3" data-name="c">
        <path
          id="Panama_svg__t-13"
          d="M1047.76 512.47H957.6v45.09Z"
          className="Panama_svg__cls-3"
          data-name="t"
        />
        <path
          id="Panama_svg__t-14"
          d="m1047.76 512.47-72.94 53-26.5-36.47Z"
          className="Panama_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Panama_svg__c-4" data-name="c">
        <path
          id="Panama_svg__t-15"
          d="m1015 613.29-27.87-85.76-42.87 13.93Z"
          className="Panama_svg__cls-3"
          data-name="t"
        />
        <path
          id="Panama_svg__t-16"
          d="m1015 613.29-73-53 26.5-36.48Z"
          className="Panama_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Panama_svg__c-5" data-name="c">
        <path
          id="Panama_svg__t-17"
          d="m909 613.29 73-53-26.5-36.48Z"
          className="Panama_svg__cls-3"
          data-name="t"
        />
        <path
          id="Panama_svg__t-18"
          d="m909 613.29 27.86-85.76 42.88 13.93Z"
          className="Panama_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Panama_svg__star">
        <g id="Panama_svg__c-6" data-name="c">
          <path
            id="Panama_svg__t-19"
            d="m876.25 512.47 73 53L975.7 529Z"
            className="Panama_svg__cls-3"
            data-name="t"
          />
          <path
            id="Panama_svg__t-20"
            d="M876.25 512.47h90.17v45.09Z"
            className="Panama_svg__cls-3"
            data-name="t"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgPanama;
