import * as React from "react";
import type { SVGProps } from "react";
const SvgVietnam = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Vietnam_svg__cls-2{fill:#ff0}"}</style>
    </defs>
    <g id="Vietnam_svg__Vietnam">
      <path
        d="M0 .75h1280v718.5H0z"
        style={{
          fill: "#da251d",
        }}
      />
      <g id="Vietnam_svg__g">
        <path
          id="Vietnam_svg__g1"
          d="m640 216.3-44.41 136.67 91.12 29.6"
          className="Vietnam_svg__cls-2"
        />
        <path
          id="Vietnam_svg__g1-2"
          d="m640 216.3 44.41 136.67-91.12 29.6"
          className="Vietnam_svg__cls-2"
          data-name="g1"
        />
      </g>
      <g id="Vietnam_svg__g-2" data-name="g">
        <path
          id="Vietnam_svg__g1-3"
          d="M776.67 315.59h-143.7v95.8"
          className="Vietnam_svg__cls-2"
          data-name="g1"
        />
        <path
          id="Vietnam_svg__g1-4"
          d="m776.67 315.59-116.26 84.47-56.31-77.5"
          className="Vietnam_svg__cls-2"
          data-name="g1"
        />
      </g>
      <g id="Vietnam_svg__g2">
        <g id="Vietnam_svg__g-3" data-name="g">
          <path
            id="Vietnam_svg__g1-5"
            d="m724.47 476.26-44.41-136.67-91.11 29.6"
            className="Vietnam_svg__cls-2"
            data-name="g1"
          />
          <path
            id="Vietnam_svg__g1-6"
            d="m724.47 476.26-116.26-84.47 56.31-77.5"
            className="Vietnam_svg__cls-2"
            data-name="g1"
          />
        </g>
      </g>
      <g id="Vietnam_svg__g-4" data-name="g">
        <path
          id="Vietnam_svg__g1-7"
          d="M503.33 315.59h143.7v95.8"
          className="Vietnam_svg__cls-2"
          data-name="g1"
        />
        <path
          id="Vietnam_svg__g1-8"
          d="m503.33 315.59 116.26 84.47 56.31-77.5"
          className="Vietnam_svg__cls-2"
          data-name="g1"
        />
      </g>
      <g id="Vietnam_svg__g2-2" data-name="g2">
        <g id="Vietnam_svg__g-5" data-name="g">
          <path
            id="Vietnam_svg__g1-9"
            d="m555.53 476.26 44.41-136.67 91.11 29.6"
            className="Vietnam_svg__cls-2"
            data-name="g1"
          />
          <path
            id="Vietnam_svg__g1-10"
            d="m555.53 476.26 116.26-84.47-56.31-77.5"
            className="Vietnam_svg__cls-2"
            data-name="g1"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgVietnam;
