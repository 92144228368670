import * as React from "react";
import type { SVGProps } from "react";
const SvgUnitedKingdom = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".United_Kingdom_svg__cls-3{fill:#e31f26}"}</style>
    </defs>
    <g id="United_Kingdom_svg__United_Kingdom" data-name="United Kingdom">
      <path
        d="M-.28 0v720h1280V0Z"
        style={{
          fill: "#00247d",
        }}
      />
      <path
        d="M-.28 0h148.12l383.98 219.1V0h214.49v219.1L1134.79 0h144.93l.56 81.1-280.49 157.5h280.49v241.48H999.79l279.93 159V720h-141.93L749.31 504.08l-3 215.92H531.82V501.08L144.84 720H-.28v-79.42l280.11-159-280.11-3V240.1h280.11L-.28 81.1z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M-.28 286.59h1280.56v145.49H-.28z"
        className="United_Kingdom_svg__cls-3"
      />
      <path
        d="M575.31 0H702.8v720H575.31zM-.28 0l425.6 238.6h-98.99L-.28 55.6zM1179.79 0 761.31 238.6h92.99L1279.72 0zM1280.28 667.57 951.8 480.08h-96L1279.72 720zM95.34 720l421.48-239.92h-91.5L-.28 720z"
        className="United_Kingdom_svg__cls-3"
      />
    </g>
  </svg>
);
export default SvgUnitedKingdom;
