import * as React from "react";
import type { SVGProps } from "react";
const SvgSingapore = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Singapore_svg__cls-1{fill:#ed2939}.Singapore_svg__cls-2{fill:#fff}"}
      </style>
    </defs>
    <g id="Singapore_svg__Singapore">
      <path d="M-.23.18h1280.46V360H-.23z" className="Singapore_svg__cls-1" />
      <path
        d="M-.23 360h1280.46v359.82H-.23zM370 180.09A135.31 135.31 0 1 1 234.7 44.78 135.31 135.31 0 0 1 370 180.09"
        className="Singapore_svg__cls-2"
      />
      <path
        d="M412.52 180.09A127.87 127.87 0 1 1 284.65 52.22a127.87 127.87 0 0 1 127.87 127.87"
        className="Singapore_svg__cls-1"
      />
      <path
        id="Singapore_svg__star"
        d="m251.44 183.36-18.38-14.11-18.37 14.11 7.07-22.74-18.44-14h22.76l7-22.77 7 22.76 22.76-.05-18.44 14Z"
        className="Singapore_svg__cls-2"
      />
      <path
        id="Singapore_svg__star-2"
        d="m395.67 183.36-18.38-14.11-18.38 14.11 7.09-22.74-18.44-14h22.75l7-22.77 7 22.76 22.69-.01-18.43 14Z"
        className="Singapore_svg__cls-2"
        data-name="star"
      />
      <path
        id="Singapore_svg__star-3"
        d="m323.63 129.83-18.38-14.1-18.38 14.11L294 107.1l-18.44-14h22.75l7-22.77 7 22.77 22.69-.03-18.43 14Z"
        className="Singapore_svg__cls-2"
        data-name="star"
      />
      <path
        id="Singapore_svg__star-4"
        d="m278.43 269-18.38-14.1-18.38 14.1 7.08-22.74-18.44-14 22.75.05 7-22.77 7 22.77 22.75-.06-18.43 14Z"
        className="Singapore_svg__cls-2"
        data-name="star"
      />
      <path
        id="Singapore_svg__star-5"
        d="m368.68 269-18.38-14.1-18.37 14.1 7.07-22.73-18.44-14 22.75.05 7-22.77 7 22.77 22.75-.06-18.43 14Z"
        className="Singapore_svg__cls-2"
        data-name="star"
      />
    </g>
  </svg>
);
export default SvgSingapore;
