import * as React from "react";
import type { SVGProps } from "react";
const SvgIsrael = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Israel_svg__cls-2{fill:#0038b8}.Israel_svg__cls-3{fill-opacity:0;stroke:#0038b8;stroke-width:16px}"
        }
      </style>
    </defs>
    <g id="Israel_svg__Israel">
      <path
        d="M0-.36h1280v720.73H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M0 67.2h1280v112.61H0zM0 540.18h1280v112.61H0z"
        className="Israel_svg__cls-2"
      />
      <path
        id="Israel_svg__triangle"
        d="m639.5 228.73-113.68 196.9h227.36z"
        className="Israel_svg__cls-3"
      />
      <path
        id="Israel_svg__triangle-2"
        d="m639.5 491.27 113.68-196.9H525.82z"
        className="Israel_svg__cls-3"
        data-name="triangle"
      />
    </g>
  </svg>
);
export default SvgIsrael;
