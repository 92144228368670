import * as React from "react";
import type { SVGProps } from "react";
const SvgBangladesh = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.05h1280v720.1H0z"
      style={{
        fill: "#006a4e",
      }}
    />
    <circle
      cx={579.99}
      cy={360}
      r={240.03}
      style={{
        fill: "#f42a41",
      }}
    />
  </svg>
);
export default SvgBangladesh;
