import * as React from "react";
import type { SVGProps } from "react";
const SvgDjibouti = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.67h1280v721.33H0z"
      style={{
        fill: "#6ab2e7",
      }}
    />
    <path
      d="M0 360h1280v360.67H0z"
      style={{
        fill: "#12ad2b",
      }}
    />
    <path
      d="M0-.67v721.34l369.5-180.34L739 360 369.5 179.67Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m279.07 269.83 22.38 68.88h72.43l-58.59 42.58 22.38 68.88-58.6-42.57-58.59 42.57 22.38-68.88-58.59-42.58h72.42Z"
      style={{
        fill: "#d7141a",
      }}
    />
  </svg>
);
export default SvgDjibouti;
