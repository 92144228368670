import * as React from "react";
import type { SVGProps } from "react";

const SvgGuernsey = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 800" {...props}>
    {/* White background */}
    <rect width="1200" height="800" fill="#fff" />
    
    {/* Red cross */}
    <rect x="0" y="300" width="1200" height="200" fill="#ff0000" />
    <rect x="300" y="0" width="200" height="800" fill="#ff0000" />
    
    {/* Yellow cross in the center */}
    <rect x="470" y="0" width="60" height="800" fill="#ffd700" />
    <rect x="0" y="470" width="1200" height="60" fill="#ffd700" />
    
    {/* Green squares in the corners */}
    <rect x="0" y="0" width="100" height="100" fill="#008000" />
    <rect x="1100" y="0" width="100" height="100" fill="#008000" />
    <rect x="0" y="700" width="100" height="100" fill="#008000" />
    <rect x="1100" y="700" width="100" height="100" fill="#008000" />
  </svg>
);

export default SvgGuernsey;