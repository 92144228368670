import * as React from "react";
import type { SVGProps } from "react";
const SvgDominica = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Dominica_svg__cls-5{fill:#006b3f}.Dominica_svg__cls-2{fill:#fff}.Dominica_svg__cls-3,.Dominica_svg__cls-7{fill:#fcd116}.Dominica_svg__cls-10{fill:#d41c30}.Dominica_svg__cls-10,.Dominica_svg__cls-5,.Dominica_svg__cls-6,.Dominica_svg__cls-7,.Dominica_svg__cls-8,.Dominica_svg__cls-9{stroke:#000}.Dominica_svg__cls-6{fill:#9c4a00}.Dominica_svg__cls-8{fill:#009543}.Dominica_svg__cls-9{fill:#9461c9}"
        }
      </style>
    </defs>
    <g id="Dominica_svg__Dominica">
      <path
        d="M0-.4h1280v720.81H0z"
        style={{
          fill: "#006b3f",
        }}
      />
      <path d="M0 390.03h1280v60.07H0z" className="Dominica_svg__cls-2" />
      <path d="M0 329.97h1280v60.07H0z" />
      <path d="M0 269.9h1280v60.07H0z" className="Dominica_svg__cls-3" />
      <path
        d="M542.99-.4h60.07v720.81h-60.07z"
        className="Dominica_svg__cls-3"
      />
      <path d="M603.06-.4h60.07v720.81h-60.07z" />
      <path
        d="M663.13-.4h60.07v720.81h-60.07z"
        className="Dominica_svg__cls-2"
      />
      <path
        d="M813.29 360a180.2 180.2 0 0 1-360.39 2.94V360a180.2 180.2 0 1 1 360.39-2.94q.02 1.47 0 2.94"
        style={{
          fill: "#d41c30",
        }}
      />
      <path
        d="M627.32 217.75c0-.27 5.77-16.48 5.77-16.48l5.25 16.21s17.6.53 17.6.27S642 228.38 642 228.38s6.57 18.61 6.31 18.08-15.24-11.16-15.24-11.16-15.23 10.63-15 10.63 5.78-17.55 5.78-17.55L610.24 218ZM638.87 502.66c0 .26-5.78 16.48-5.78 16.48l-5.25-16.22s-17.6-.52-17.6-.26S624.17 492 624.17 492s-6.57-18.61-6.31-18.08 15.23 11.17 15.23 11.17 15.24-10.64 15-10.64-5.81 17.55-5.81 17.55l13.66 10.37ZM716.85 258.44c0 .26-5.78 16.48-5.78 16.48l-5.26-16.22s-17.59-.53-17.59-.26 13.91-10.63 13.91-10.63-6.55-18.61-6.29-18.08 15.23 11.16 15.23 11.16 15.24-10.64 15-10.64-5.78 17.56-5.78 17.56l13.66 10.36ZM767.42 406.26c0 .27-5.78 16.49-5.78 16.49l-5.25-16.22s-17.6-.53-17.6-.27 13.92-10.63 13.92-10.63-6.57-18.61-6.31-18.08 15.24 11.16 15.24 11.16 15.23-10.63 15-10.63-5.78 17.55-5.78 17.55L784.49 406ZM510.3 410.49c0 .27-5.77 16.47-5.77 16.47l-5.25-16.2s-17.6-.54-17.6-.27 13.92-10.64 13.92-10.64-6.57-18.61-6.31-18.07 15.24 11.16 15.24 11.16 15.23-10.63 15-10.63-5.77 17.54-5.77 17.54l13.66 10.37-17.08.27ZM557 260.17c0 .26-5.78 16.48-5.78 16.48L546 260.43s-17.59-.53-17.59-.26 13.91-10.63 13.91-10.63-6.56-18.61-6.29-18.08 15.23 11.16 15.23 11.16 15.23-10.64 15-10.64-5.78 17.56-5.78 17.56l13.66 10.36ZM497.73 314.18c0-.26 5.77-16.48 5.77-16.48l5.25 16.22s17.6.53 17.6.26-13.92 10.63-13.92 10.63 6.57 18.61 6.31 18.08-15.24-11.16-15.24-11.16-15.23 10.63-15 10.63 5.77-17.55 5.77-17.55l-13.65-10.36ZM548 461.74c0-.27 5.77-16.48 5.77-16.48l5.23 16.21s17.6.54 17.6.27-13.91 10.63-13.91 10.63 6.56 18.61 6.3 18.07-15.24-11.16-15.24-11.16-15.23 10.63-15 10.63 5.77-17.54 5.77-17.54L530.9 462ZM755 310.25c0-.27 5.77-16.48 5.77-16.48L766 310s17.6.54 17.6.27-13.92 10.63-13.92 10.63 6.54 18.59 6.32 18.1-15.24-11.16-15.24-11.16-15.23 10.63-15 10.63 5.77-17.54 5.77-17.54l-13.66-10.36ZM708.12 460.35c0-.27 5.78-16.48 5.78-16.48l5.25 16.21s17.6.53 17.6.27S722.82 471 722.82 471s6.57 18.61 6.31 18.08-15.23-11.17-15.23-11.17-15.24 10.63-15 10.63 5.81-17.54 5.81-17.54l-13.66-10.37Z"
        className="Dominica_svg__cls-5"
      />
      <ellipse
        cx={657.8}
        cy={407.67}
        className="Dominica_svg__cls-5"
        rx={10.77}
        ry={53.1}
        transform="rotate(-4.01 656.98 407.238)"
      />
      <ellipse
        cx={668.37}
        cy={407.55}
        className="Dominica_svg__cls-5"
        rx={11.39}
        ry={53.1}
        transform="rotate(-4.02 668.531 407.602)"
      />
      <ellipse
        cx={663.36}
        cy={410.39}
        className="Dominica_svg__cls-5"
        rx={9.11}
        ry={58.69}
        transform="rotate(-3.94 662.926 410.072)"
      />
      <path
        d="M580 435.16c9.2-.38 5.2-5.3 9.85-7.92s11.25-.89 13.23 2.38.4 6.49 3 6.53 72-2.87 74.49-.13 2.92 8.14.33 10.24-90.39 3.11-93.41.89S580 435.7 580 435.16Z"
        className="Dominica_svg__cls-6"
      />
      <path
        d="M629 441.93c-.17 6.93 10.09 5 10.23 10.76-.31 6.15-17.21.38-17.37-10.86.71-11.22 17.25-16.77 17.45-10.36.19 5.33-10.11 3.53-10.31 10.46Z"
        className="Dominica_svg__cls-7"
      />
      <path
        d="M635.09 442c-.16 6.93 10.1 5 10.24 10.76-.31 6.15-17.21.38-17.37-10.86.7-11.22 17.24-16.77 17.45-10.36.15 5.34-10.15 3.53-10.32 10.46Z"
        className="Dominica_svg__cls-7"
      />
      <path
        d="M641.59 441.76c-.17 6.94 10.09 5 10.23 10.77-.3 6.14-17.2.37-17.37-10.86.71-11.23 17.25-16.78 17.45-10.36.15 5.32-10.14 3.51-10.31 10.45Z"
        className="Dominica_svg__cls-7"
      />
      <path
        d="M647.64 441.84c-.16 6.94 10.1 5 10.24 10.77-.31 6.14-17.21.37-17.37-10.86.7-11.23 17.24-16.78 17.45-10.36.15 5.32-10.15 3.51-10.32 10.45Z"
        className="Dominica_svg__cls-7"
      />
      <ellipse
        cx={676.53}
        cy={440.96}
        className="Dominica_svg__cls-6"
        rx={5.95}
        ry={5.86}
        transform="rotate(-78.06 676.48 440.966)"
      />
      <ellipse
        cx={641.34}
        cy={421.05}
        className="Dominica_svg__cls-5"
        rx={5.24}
        ry={11.53}
        transform="rotate(-5.46 641.4 421.017)"
      />
      <ellipse
        cx={632.5}
        cy={420.35}
        className="Dominica_svg__cls-5"
        rx={5.24}
        ry={11.53}
        transform="rotate(-5.46 632.498 420.382)"
      />
      <ellipse
        cx={626.17}
        cy={409.04}
        className="Dominica_svg__cls-5"
        rx={5.24}
        ry={11.53}
        transform="rotate(-5.46 626.207 409.047)"
      />
      <ellipse
        cx={635.13}
        cy={408.55}
        className="Dominica_svg__cls-5"
        rx={5.04}
        ry={12.72}
        transform="rotate(-5.52 635.233 408.613)"
      />
      <ellipse
        cx={647.49}
        cy={408.66}
        className="Dominica_svg__cls-8"
        rx={5.04}
        ry={11.93}
        transform="rotate(-5.49 647.987 408.922)"
      />
      <ellipse
        cx={640.7}
        cy={404.57}
        className="Dominica_svg__cls-8"
        rx={5.04}
        ry={11.93}
        transform="rotate(-5.49 641.18 404.833)"
      />
      <ellipse
        cx={632.13}
        cy={398.66}
        className="Dominica_svg__cls-8"
        rx={5.04}
        ry={11.93}
        transform="translate(-35.27 62.36)"
      />
      <ellipse
        cx={624.19}
        cy={395.09}
        className="Dominica_svg__cls-5"
        rx={5.04}
        ry={11.93}
        transform="rotate(-5.49 624.701 395.367)"
      />
      <path
        d="M615.39 296.71s-17.6 15.28-15.63 56.63c2.22 41.59 38.92 61.14 38.92 61.14s9.35-11.8 8.27-45.22c-2.63-48.86-20-70.44-20-70.44l-11.6-2.11Z"
        className="Dominica_svg__cls-9"
      />
      <ellipse
        cx={655.05}
        cy={422.38}
        className="Dominica_svg__cls-5"
        rx={11.53}
        ry={5.24}
        transform="rotate(-85.19 655.078 422.376)"
      />
      <ellipse
        cx={646.47}
        cy={420.12}
        className="Dominica_svg__cls-5"
        rx={11.53}
        ry={5.24}
        transform="rotate(-85.19 646.508 420.117)"
      />
      <ellipse
        cx={642.26}
        cy={407.86}
        className="Dominica_svg__cls-5"
        rx={11.53}
        ry={5.24}
        transform="rotate(-85.19 642.296 407.855)"
      />
      <ellipse
        cx={651.16}
        cy={408.97}
        className="Dominica_svg__cls-5"
        rx={12.72}
        ry={5.04}
        transform="rotate(-85.25 651.206 408.966)"
      />
      <ellipse
        cx={663.31}
        cy={411.29}
        className="Dominica_svg__cls-5"
        rx={11.93}
        ry={5.04}
        transform="rotate(-85.23 663.285 411.294)"
      />
      <ellipse
        cx={657.35}
        cy={406.05}
        className="Dominica_svg__cls-5"
        rx={11.93}
        ry={5.04}
        transform="rotate(-85.23 657.328 406.054)"
      />
      <ellipse
        cx={649.97}
        cy={398.71}
        className="Dominica_svg__cls-5"
        rx={11.93}
        ry={5.04}
        transform="rotate(-85.23 649.952 398.715)"
      />
      <ellipse
        cx={642.8}
        cy={393.78}
        className="Dominica_svg__cls-5"
        rx={11.93}
        ry={5.04}
        transform="rotate(-85.23 642.78 393.782)"
      />
      <ellipse
        cx={652.73}
        cy={367.76}
        className="Dominica_svg__cls-10"
        rx={14.84}
        ry={45.61}
        transform="rotate(-23.46 652.714 367.75)"
      />
      <ellipse
        cx={641.3}
        cy={324.91}
        className="Dominica_svg__cls-8"
        rx={5.72}
        ry={6.48}
        transform="rotate(-88.98 641.305 324.91)"
      />
      <ellipse
        cx={646.17}
        cy={316.82}
        className="Dominica_svg__cls-8"
        rx={5.72}
        ry={5.8}
        transform="rotate(-88.98 646.177 316.825)"
      />
      <ellipse
        cx={638.35}
        cy={316.68}
        className="Dominica_svg__cls-8"
        rx={5.72}
        ry={5.8}
        transform="rotate(-88.9 639.376 316.239)"
      />
      <ellipse
        cx={640.07}
        cy={310.76}
        className="Dominica_svg__cls-5"
        rx={5.37}
        ry={6.07}
        transform="rotate(-88.98 640.074 310.76)"
      />
      <ellipse
        cx={639.38}
        cy={303.97}
        className="Dominica_svg__cls-5"
        rx={5.37}
        ry={6.07}
        transform="rotate(-88.98 639.378 303.974)"
      />
      <ellipse
        cx={630.87}
        cy={312.11}
        className="Dominica_svg__cls-5"
        rx={5.72}
        ry={5.8}
        transform="rotate(-88.98 630.863 312.113)"
      />
      <ellipse
        cx={632.62}
        cy={304.79}
        className="Dominica_svg__cls-5"
        rx={5.6}
        ry={5.8}
        transform="rotate(-88.98 632.615 304.791)"
      />
      <ellipse
        cx={623.03}
        cy={304.97}
        className="Dominica_svg__cls-5"
        rx={5.72}
        ry={6.48}
        transform="rotate(-88.98 623.03 304.968)"
      />
      <ellipse
        cx={634.94}
        cy={295.49}
        className="Dominica_svg__cls-5"
        rx={5.37}
        ry={6.07}
        transform="rotate(-88.9 635.945 295.026)"
      />
      <ellipse
        cx={626.82}
        cy={296.51}
        className="Dominica_svg__cls-5"
        rx={5.6}
        ry={5.8}
        transform="rotate(-88.98 626.825 296.513)"
      />
      <ellipse
        cx={614.52}
        cy={305.28}
        className="Dominica_svg__cls-5"
        rx={5.72}
        ry={5.8}
        transform="rotate(-88.98 614.516 305.286)"
      />
      <ellipse
        cx={616.53}
        cy={301.02}
        className="Dominica_svg__cls-5"
        rx={5.6}
        ry={5.8}
        transform="rotate(-88.98 616.532 301.014)"
      />
      <ellipse
        cx={619}
        cy={296.3}
        className="Dominica_svg__cls-5"
        rx={5.6}
        ry={5.8}
        transform="rotate(-88.98 618.992 296.3)"
      />
      <ellipse
        cx={657.05}
        cy={360.66}
        className="Dominica_svg__cls-5"
        rx={18.8}
        ry={54.34}
        transform="rotate(-23.78 656.958 360.63)"
      />
      <ellipse
        cx={672.55}
        cy={369.38}
        className="Dominica_svg__cls-5"
        rx={3.64}
        ry={11.38}
        transform="rotate(-23.92 672.6 369.402)"
      />
      <path
        d="M670.89 369.37c15.39 18.74 13.45 30.64 11.84 40.7-4.83-11.6-7.47-18.91-22.86-37.65s-7.71-31.9-6.66-38.83c2.32 6 2.29 17.04 17.68 35.78Z"
        className="Dominica_svg__cls-5"
      />
      <path
        d="M672.28 384.5c15.39 18.74 13.44 30.64 11.84 40.71-4.84-11.61-7.48-18.92-22.87-37.66s-7.71-31.89-6.65-38.82c2.31 6 2.28 17.03 17.68 35.77Z"
        className="Dominica_svg__cls-5"
      />
      <path
        d="M672.74 396.7c15.76 18.4 14.06 30.34 12.65 40.44-5.06-11.5-7.85-18.75-23.61-37.16s-8.35-31.73-7.43-38.68c2.43 5.95 2.65 16.98 18.39 35.4Z"
        className="Dominica_svg__cls-5"
      />
      <ellipse
        cx={661.81}
        cy={351.71}
        className="Dominica_svg__cls-5"
        rx={6.63}
        ry={20.27}
        transform="rotate(-25.01 661.728 351.693)"
      />
      <ellipse
        cx={655.7}
        cy={362.11}
        className="Dominica_svg__cls-5"
        rx={5.79}
        ry={16.65}
        transform="rotate(-24.35 655.555 362.072)"
      />
      <ellipse
        cx={648.4}
        cy={369.19}
        className="Dominica_svg__cls-5"
        rx={5.79}
        ry={16.65}
        transform="rotate(-23.16 648.26 369.141)"
      />
      <ellipse
        cx={654.44}
        cy={343.58}
        className="Dominica_svg__cls-5"
        rx={4.63}
        ry={12.74}
        transform="rotate(-27.97 654.378 343.566)"
      />
      <ellipse
        cx={648.26}
        cy={348.32}
        className="Dominica_svg__cls-5"
        rx={4.64}
        ry={12.7}
        transform="rotate(-24.45 648.21 348.32)"
      />
      <path
        d="M643.45 341.06c4.55 11.12 6.39 21.11 4.11 22.32s-7.83-6.85-12.38-18"
        className="Dominica_svg__cls-5"
      />
      <ellipse
        cx={647.51}
        cy={335.26}
        className="Dominica_svg__cls-5"
        rx={4.64}
        ry={12.7}
        transform="rotate(-24.45 647.465 335.258)"
      />
      <ellipse
        cx={639.89}
        cy={338.93}
        className="Dominica_svg__cls-5"
        rx={4.65}
        ry={12.68}
        transform="rotate(-23.18 639.782 338.9)"
      />
      <path
        d="M635.53 326c4.42 7.51 6.79 14.52 5.31 15.66s-6.28-4.05-10.7-11.56"
        className="Dominica_svg__cls-5"
      />
      <path
        d="M638.59 321c4.42 7.52 6.79 14.53 5.31 15.67s-6.28-4.05-10.7-11.56M652.33 322c6.16 10.26 9.47 19.85 7.39 21.4s-8.75-5.53-14.91-15.79"
        className="Dominica_svg__cls-5"
      />
      <path
        d="M643.25 316.65c4.43 8.3 6.83 16 5.34 17.28s-6.29-4.47-10.72-12.77"
        className="Dominica_svg__cls-5"
      />
      <path
        d="M642.85 304.63c-3 3-.06 8.56 2.65 10.58 3 2.75 20.12 20.76 32.16 5.61-9.66-.17-27.66-22.82-34.81-16.19Z"
        className="Dominica_svg__cls-9"
      />
      <path
        d="M640.75 295.75c-3 3-.07 8.55 2.64 10.57 3 2.75 20.12 20.77 32.16 5.61-9.65-.17-27.66-22.83-34.8-16.18Z"
        className="Dominica_svg__cls-9"
      />
      <path
        d="M638.05 287.23c-3 3-.06 8.56 2.65 10.57 3 2.76 20.11 20.77 32.15 5.61-9.65-.17-27.66-22.83-34.8-16.18Z"
        className="Dominica_svg__cls-9"
      />
      <path
        d="M632.18 277.48c-3 3-.06 8.56 2.65 10.58 3 2.75 20.11 20.76 32.16 5.61-9.65-.18-27.66-22.83-34.81-16.19Z"
        className="Dominica_svg__cls-9"
      />
      <path
        d="M611.14 268.16c6.11-4.54 17.32-5.87 26.05-1.42 7.27 3.56 19.4 18.11 28.51 21.54-6.41 1.15-10.32.18-14.42-1.79-8.09 5.86-11 6.74-19.23 7.9-11.47 1.65-21-1.34-24.34-7.32-3.41-5.65-1.94-14.7 3.43-18.91Z"
        className="Dominica_svg__cls-9"
      />
      <ellipse
        cx={624.09}
        cy={277.22}
        className="Dominica_svg__cls-10"
        rx={3.05}
        ry={5.26}
        transform="rotate(-88.98 624.085 277.22)"
      />
      <ellipse
        cx={608.06}
        cy={289.55}
        className="Dominica_svg__cls-7"
        rx={7.33}
        ry={4.47}
        transform="rotate(-20.44 608.004 289.528)"
      />
      <ellipse
        cx={623.82}
        cy={277.01}
        rx={1.63}
        ry={1.89}
        transform="rotate(-88.9 624.788 276.543)"
      />
      <path
        d="M612.52 281.29c.48 2.35-1.26 5.51-5 6.63-3.84 1.59-6.75 5.19-8.43 11.08-5.84-13.43-2.13-19.12 4.07-20.5 5.31-1.7 8.52-1.5 9.36 2.79Z"
        className="Dominica_svg__cls-7"
      />
      <ellipse
        cx={606.89}
        cy={282.31}
        rx={1.34}
        ry={1.37}
        transform="rotate(-6.47 606.768 282.248)"
      />
    </g>
  </svg>
);
export default SvgDominica;
