import * as React from "react";
import type { SVGProps } from "react";
const SvgThailand = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.67h1280v721.33H0z"
      style={{
        fill: "#ed1c24",
      }}
    />
    <path
      d="M0 119.56h1280v480.89H0z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0 239.78h1280v240.44H0z"
      style={{
        fill: "#241d4f",
      }}
    />
  </svg>
);
export default SvgThailand;
