import * as React from "react";
import type { SVGProps } from "react";
const SvgNamibia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <clipPath id="Namibia_svg__a">
        <path
          d="M0-.49h1280V720.5H0z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
    </defs>
    <path
      d="M0-.49v720.98L1280-.49z"
      style={{
        fill: "#003580",
      }}
    />
    <path
      d="M1280-.49 0 720.49h1280z"
      style={{
        fill: "#009543",
      }}
    />
    <g
      style={{
        clipPath: "url(#Namibia_svg__a)",
      }}
    >
      <path
        d="M67.03 831.45-67.03 609.53l1280-720.98 134.06 221.92z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M50.27 803.71-50.27 637.28l1280-720.99 100.54 166.43z"
        style={{
          fill: "#c0003b",
        }}
      />
    </g>
    <path
      d="m364.9 257.88-53.07-9.12 9.11 53.08-41.44-34.41-18.66 50.52-18.66-50.52-41.42 34.41 9.12-53.08-53.11 9.12 34.45-41.44-50.53-18.66 50.53-18.66-34.45-41.42 53.11 9.12-9.12-53.11 41.42 34.45 18.66-50.53 18.66 50.53 41.44-34.45-9.11 53.11 53.07-9.12-34.41 41.42 50.52 18.66-50.52 18.66z"
      style={{
        fill: "#ffce00",
      }}
    />
    <path
      d="M333 197.78a72.1 72.1 0 1 1-72.11-72.09A72.13 72.13 0 0 1 333 197.78"
      style={{
        fill: "#1f357e",
      }}
    />
    <path
      d="M320.94 197.78a60.09 60.09 0 1 1-60.1-60.08 60.1 60.1 0 0 1 60.1 60.08"
      style={{
        fill: "#f5cd28",
      }}
    />
  </svg>
);
export default SvgNamibia;
