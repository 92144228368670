import * as React from "react";
import type { SVGProps } from "react";
const SvgLiberia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Liberia_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Liberia_svg__Liberia">
      <path
        d="M314 .08 0 392.73v65.44l33.93 32.71L0 523.61v65.43l33.93 32.73L0 654.48v65.44h1280v-65.44l-33.93-32.71 33.93-32.73v-65.43l-33.93-32.73 33.93-32.71v-65.44L1246.07 360l33.93-32.71v-65.46l-33.93-32.71 33.93-32.71v-65.45l-33.93-32.72L1280 65.52V.08z"
        style={{
          fill: "#bf0a30",
        }}
      />
      <path
        d="M0 654.48h1280V589H0ZM0 523.6h1280v-65.43H0ZM317 65.52 0 327.29v65.44h1280v-65.44H319l-47.48-65.46H1280v-65.42H271.52V131H1280V65.52Z"
        className="Liberia_svg__cls-2"
      />
      <path
        d="M0 .08h327.18v327.21H0z"
        style={{
          fill: "#002868",
        }}
      />
      <path
        d="m163.59 65.52-30.34 93.35 56.01 18.21z"
        className="Liberia_svg__cls-2"
      />
      <path
        d="m163.59 65.52 30.34 93.35-56.01 18.21z"
        className="Liberia_svg__cls-2"
      />
      <path d="M256.95 133.35h-98.17v58.9z" className="Liberia_svg__cls-2" />
      <path
        d="m256.95 133.35-79.42 57.7-34.63-47.65z"
        className="Liberia_svg__cls-2"
      />
      <path
        d="m221.28 243.09-30.34-93.36-56 18.21z"
        className="Liberia_svg__cls-2"
      />
      <path
        d="m221.28 243.09-79.39-57.69 34.6-47.65z"
        className="Liberia_svg__cls-2"
      />
      <path
        d="m105.9 243.09 79.39-57.69-34.59-47.65z"
        className="Liberia_svg__cls-2"
      />
      <path
        d="m105.9 243.09 30.34-93.36 56.01 18.21z"
        className="Liberia_svg__cls-2"
      />
      <path
        d="m70.23 133.35 79.42 57.7 34.63-47.65z"
        className="Liberia_svg__cls-2"
      />
      <path d="M70.23 133.35h98.17v58.9z" className="Liberia_svg__cls-2" />
    </g>
  </svg>
);
export default SvgLiberia;
