import * as React from "react";
import type { SVGProps } from "react";
const SvgSaintKittsAndNevis = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Saint_Kitts_and_Nevis_svg__cls-4{fill:#f3d02f}"}</style>
    </defs>
    <g
      id="Saint_Kitts_and_Nevis_svg__Saint_Kitts_and_Nevis"
      data-name="Saint Kitts and Nevis"
    >
      <path
        d="M0-.21h1235v720.43H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M0-.21v564.08L876.94-.21z"
        style={{
          fill: "#419e4b",
        }}
      />
      <path
        d="M403.06 720.21H1280V156.13z"
        style={{
          fill: "#bc0030",
        }}
      />
      <path
        d="M876.94-.21 0 563.87v59.38L969.26-.21zM310.74 720.21h92.32L1280 156.13V96.75z"
        className="Saint_Kitts_and_Nevis_svg__cls-4"
      />
      <path d="m394.14 533.77-22.9-.88-55.61-2.18L381 488.66l-21.25-73 61.65 47 65.37-42-19.3 50.34-8 20.73 61.64 47-78.5-3.06-27.26 71.09Zm440.24-283.18-22.9-.88-55.6-2.16 65.37-42.05-21.25-73 61.64 47L927 137.47l-19.33 50.34-7.93 20.75 61.64 47-78.5-3.06-27.26 71.09ZM969.23-.21 0 623.25v97h310.74L1280 96.75v-97Z" />
    </g>
  </svg>
);
export default SvgSaintKittsAndNevis;
