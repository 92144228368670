import * as React from "react";
import type { SVGProps } from "react";
const SvgParaguay = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Paraguay_svg__cls-5{stroke:#000;fill:#009b3a;stroke-width:.3px}"}
      </style>
    </defs>
    <g id="Paraguay_svg__Paraguay">
      <path
        id="Paraguay_svg__rect2259"
        d="M0 .06h1280v719.88H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        id="Paraguay_svg__rect2260"
        d="M0 479.98h1280v239.96H0z"
        style={{
          fill: "#0038a8",
        }}
      />
      <path
        id="Paraguay_svg__rect2261"
        d="M0 .06h1280v239.96H0z"
        style={{
          fill: "#d52b1e",
        }}
      />
      <path
        id="Paraguay_svg__path5487"
        d="M732 360a92.05 92.05 0 0 1-184.1 0v0a92.05 92.05 0 1 1 184.1 0Z"
        style={{
          fill: "none",
          strokeLinecap: "round",
          stroke: "#000",
          strokeWidth: "3.1px",
        }}
      />
      <g id="Paraguay_svg__g5320">
        <path
          id="Paraguay_svg__path774-1"
          d="M652.07 317s35 15.08 28.9 46.26-25.61 31-33.26 34.41-12.92 8.37-15.93 7.69a31.6 31.6 0 0 1-7-3s-.31 5.12 5.83 6.05 14.21-8.08 18.08-9.08 27.68-3.59 33.47-35.31c6.75-33.86-29.88-46.02-30.09-47.02Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path780-7"
          d="M669.75 323.65c.66 2.57.67 4.7 0 4.75s-1.69-2-2.34-4.57c-.66-2.57-.67-4.69 0-4.74s1.69 1.99 2.34 4.56Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path778-4"
          d="M668.53 324c1.22 2.45 1.74 4.61 1.16 4.82s-2-1.6-3.24-4.05c-1.22-2.45-1.73-4.61-1.16-4.82s2 1.6 3.24 4.05Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path779-0"
          d="M665 326.29c2.48.73 4.45 1.85 4.39 2.5s-2.13.6-4.61-.13-4.45-1.84-4.39-2.5 2.13-.6 4.61.13Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path777-9"
          d="M665.55 325c2.26 1.51 3.87 3.19 3.6 3.74s-2.33-.22-4.59-1.74c-2.27-1.51-3.88-3.19-3.6-3.74s2.33.22 4.59 1.74Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path776-4"
          d="M667 324.41c1.92 2 3.14 4 2.72 4.39s-2.32-.84-4.24-2.83-3.14-4-2.72-4.4 2.35.84 4.24 2.84Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path781-8"
          d="M669.48 328.8a15 15 0 0 1-1.67.87c-.31.11-.73-.41-1.62.05a3.87 3.87 0 0 0-1.7 1.78c-.19.49.25.7 1.33.17s1.37-1.35 1.89-1.68a13 13 0 0 1 1.89-1c.21-.47.91-1.48 1.27-2.05s1.78-1.54 2.29-2.65c.45-.91.28-1.7-.32-1.37a5.64 5.64 0 0 0-1.74 1.91c-.37.67-.05 1.51-.46 2.13-.31.45-1.08 1.82-1.16 1.82Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path790-8"
          d="M680.32 332.39c0 4-.63 7-1.53 6.82s-1.67-3.6-1.73-7.57c-.06-4 .63-7 1.52-6.82s1.68 3.6 1.73 7.58Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path791-2"
          d="M678.52 332.31c.87 4.05.87 7.38 0 7.42s-2.27-3.21-3.14-7.26-.87-7.38 0-7.42 2.27 3.21 3.14 7.26Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path792-4"
          d="M672.91 334.06c3.2 2.15 5.56 4.64 5.26 5.55s-3.12-.09-6.32-2.24-5.56-4.64-5.26-5.55 3.12.09 6.32 2.24Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path793-5"
          d="M674.05 332.47c2.63 3.18 4.3 6.3 3.74 7s-3.15-1.36-5.78-4.54c-2.63-3.19-4.3-6.3-3.72-7s3.13 1.36 5.76 4.54Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path794-5"
          d="M676.3 332.25c2 3.72 3 7.09 2.32 7.53s-2.93-2.22-4.93-5.93c-2-3.72-3-7.09-2.32-7.53s2.93 2.22 4.93 5.94Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path795-1"
          d="M678.23 339.64a20 20 0 0 1-2.59.51c-.47 0-.88-.92-2.27-.67a5 5 0 0 0-2.94 1.8c-.43.61.12 1.11 1.79.84s2.33-1.33 3.16-1.57a17.6 17.6 0 0 1 2.94-.56c.46-.57 1.76-1.7 2.45-2.37s3-1.39 4.05-2.75c.93-1.12.95-2.31 0-2.11a7.2 7.2 0 0 0-3.05 1.95c-.72.79-.57 2.14-1.33 2.85-.59.5-2.11 2.12-2.22 2.08Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path797-7"
          d="M686 346.9c-.66 3.94-1.88 6.82-2.71 6.43s-1-3.88-.33-7.82 1.88-6.82 2.72-6.43 1 3.89.32 7.82Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path798-1"
          d="M684.29 346.48c.11 4.17-.48 7.45-1.34 7.33s-1.65-3.6-1.77-7.77.48-7.45 1.34-7.33 1.65 3.6 1.76 7.77Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path799-1"
          d="M678.49 347.13c2.74 2.74 4.59 5.64 4.13 6.49s-3-.69-5.78-3.43c-2.74-2.74-4.59-5.65-4.13-6.49s3 .69 5.78 3.43Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path800-5"
          d="M679.88 345.77c2 3.65 3.07 7.06 2.4 7.61s-2.83-1.95-4.83-5.6-3.07-7.06-2.4-7.61 2.83 2 4.83 5.6Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path801-2"
          d="M682.12 346c1.29 4.06 1.69 7.59.91 7.88s-2.46-2.75-3.75-6.81c-1.28-4.06-1.69-7.59-.9-7.88s2.46 2.75 3.74 6.81Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path802-7"
          d="M682.68 353.66a21 21 0 0 1-2.63 0c-.46-.07-.69-1.08-2.09-1.09a4.87 4.87 0 0 0-3.2 1.21c-.54.52-.09 1.12 1.59 1.17s2.53-.86 3.38-.93a14.8 14.8 0 0 1 3 0c.54-.49 2-1.35 2.81-1.88s3.16-.81 4.46-1.94c1.11-.92 1.35-2.1.39-2.08a7 7 0 0 0-3.33 1.35c-.85.63-.95 2-1.82 2.55-.67.38-2.44 1.68-2.54 1.63Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path804-6"
          d="M686.45 363.11c-2.13 3.37-4.35 5.54-5 4.84s.62-4 2.74-7.39c2.12-3.38 4.34-5.55 5-4.84s-.61 4-2.74 7.38Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path805-1"
          d="M685 362c-1.51 3.92-3.33 6.72-4.06 6.26s-.1-4 1.41-7.93c1.52-3.92 3.33-6.71 4.06-6.23s.14 3.99-1.41 7.9Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path806-4"
          d="M679.54 360.2c1.41 3.68 2 7.13 1.22 7.72s-2.49-1.91-3.91-5.58-2-7.13-1.22-7.72 2.49 1.91 3.91 5.58Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path807-2"
          d="M681.33 359.52c.39 4.21 0 7.81-.79 8s-1.81-3-2.2-7.21 0-7.81.79-8 1.81 3 2.2 7.21Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path808-3"
          d="M683.27 360.65c-.42 4.29-1.42 7.72-2.24 7.66s-1.16-3.59-.74-7.88 1.41-7.72 2.24-7.66 1.15 3.59.74 7.88Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path809-2"
          d="M680.79 368a22 22 0 0 1-2.37-1.08c-.39-.26-.21-1.29-1.47-1.88a4.75 4.75 0 0 0-3.37-.21c-.69.27-.51 1 1 1.75s2.62.24 3.42.53a16.6 16.6 0 0 1 2.69 1.24c.68-.22 2.35-.41 3.27-.57s3.17.55 4.79 0c1.36-.4 2-1.39 1.16-1.77a6.8 6.8 0 0 0-3.54-.13c-1 .24-1.62 1.47-2.63 1.62-.75.08-2.86.55-2.94.46Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path811-2"
          d="M682.08 379.53c-3.45 3-6.61 4.55-7.05 3.55s2-4.2 5.45-7.15c3.45-3 6.6-4.55 7-3.55s-2 4.2-5.45 7.16Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path812-1"
          d="M680.9 377.8c-2.95 3.77-5.88 6.21-6.53 5.43s1.21-4.46 4.16-8.23c3-3.78 5.88-6.21 6.53-5.44s-1.21 4.46-4.16 8.24Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path813-6"
          d="M675.43 373.83c.35 4.56-.18 8.58-1.19 9s-2.11-3-2.47-7.56c-.35-4.56.18-8.58 1.19-9s2.11 3 2.47 7.56Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path814-8"
          d="M677.62 373.72c-1 4.79-2.52 8.63-3.5 8.59s-1-4 0-8.75c.95-4.79 2.52-8.63 3.5-8.59s.96 3.96 0 8.75Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path815-5"
          d="M679.39 375.67c-1.87 4.59-4.1 8-5 7.65s-.09-4.38 1.77-9 4.1-8 5-7.64.09 4.41-1.77 8.99Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path816-7"
          d="M674.25 382.88a26 26 0 0 1-2.26-2.06c-.34-.42.2-1.49-1-2.6a5.54 5.54 0 0 0-3.64-1.45c-.84.05-.89.93.51 2.29s2.81 1.22 3.6 1.82a20 20 0 0 1 2.55 2.34c.82 0 2.72.4 3.79.56s3.31 1.76 5.26 1.78c1.63.05 2.7-.8 1.86-1.54a8 8 0 0 0-3.85-1.42c-1.2-.1-2.28 1-3.43.83-.86-.18-3.34-.43-3.39-.55Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path818-6"
          d="M670.82 393.91c-5 .81-8.86.5-8.75-.7s4.22-2.84 9.16-3.66c5-.82 8.87-.51 8.75.7s-4.21 2.84-9.16 3.65Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path819-1"
          d="M670.63 391.57c-4.91 1.88-9.06 2.5-9.26 1.39s3.62-3.55 8.53-5.43c4.91-1.89 9.05-2.51 9.25-1.39s-3.62 3.54-8.53 5.43Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path820-8"
          d="M667.52 384.64c-2.16 4.54-4.87 8.05-6 7.83s-.4-4.08 1.76-8.63 4.86-8 6-7.83.39 4.08-1.76 8.63Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path821-9"
          d="M669.69 385.8c-3.53 4-7.15 6.75-8.07 6.13s1.2-4.34 4.73-8.34 7.15-6.75 8.08-6.14-1.19 4.34-4.73 8.34Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path822-2"
          d="M670.33 388.68c-4.3 3.28-8.33 5.25-9 4.4s2.29-4.21 6.6-7.5 8.32-5.25 9-4.38-2.3 4.2-6.6 7.48Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path823-7"
          d="M661.44 392.56a26 26 0 0 1-1.06-3.26c-.1-.6 1-1.31.45-3.06a6.35 6.35 0 0 0-2.72-3.48c-.84-.44-1.36.37-.75 2.48s2 2.77 2.47 3.8a21.4 21.4 0 0 1 1.18 3.71c.8.48 2.42 1.95 3.36 2.72s2.24 3.59 4.11 4.73c1.54 1 3 .8 2.63-.38a9.2 9.2 0 0 0-2.94-3.58c-1.1-.79-2.76-.34-3.77-1.2-.72-.67-2.98-2.33-2.96-2.48Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path832-9"
          d="M656 402c-5.09.08-9.2-1-9.18-2.51s4.12-2.7 9.19-2.78 9.19 1.06 9.18 2.52-4.08 2.66-9.19 2.77Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path833-5"
          d="M654.82 393.69c-3.22 4.16-6.71 6.8-7.79 5.89s.65-5 3.87-9.17c3.22-4.15 6.7-6.79 7.79-5.89s-.69 5.02-3.87 9.17Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path831-4"
          d="M656.12 397.92c-4.73 2-9 2.59-9.49 1.24s2.9-4.09 7.62-6.13 9-2.59 9.48-1.24-2.89 4.09-7.61 6.13Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path839-3"
          d="M658.62 317.81c2.34 2.11 3.75 4.08 3.15 4.41s-3-1.12-5.32-3.23-3.75-4.08-3.15-4.4 3 1.11 5.32 3.22Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path840-1"
          d="M655.8 320.18c3.16.41 5.82 1.28 6 1.93s-2.35.86-5.51.45c-3.17-.42-5.83-1.28-6-1.94s2.34-.85 5.51-.44Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path841-2"
          d="M656.93 318.69c3 1.51 5.15 3.16 4.83 3.68s-3-.28-6-1.8-5.13-3.17-4.81-3.69 3.05.29 5.98 1.81Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path673-3"
          d="M615.45 393a127 127 0 0 1 14.42 4.09c6.7 2.44 16.89 10.8 19.63 11.28 3.07.14 7.33-.91 9-5.35-4.73 1.27-7.57 3.18-12.09.7-1.59-.6-6.82-5.36-12.54-7.91s-17.28-5.45-17.28-5.45Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path655-3"
          d="M623.67 323.27c0-.06 8.88-4.68 8.88-4.68l-9.79 2.5-1.14 1.72Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path656-4"
          d="M632.1 325.43c-3.44-.62-8.73-1.78-12.09-2.05 2.32-2.58 5.53-6.93 7.5-9.61-1.3 2.55-3.35 5.92-4.16 8.36 2.37 1.28 6.1 2.28 8.75 3.3Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path657-1"
          d="M622.07 323.81c0-.06 7.89-6.1 7.89-6.1l-9.17 4.09-.81 1.9Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path658-1"
          d="M630.5 325.34c-3.46-.66-8.79-1.87-12.17-2.16 2.29-2.66 5.42-7.15 7.35-9.9-1.26 2.63-3.25 6.1-4 8.62 2.39 1.33 6.14 2.37 8.82 3.44Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path659-3"
          d="M620.76 324.31c0-.07 9.29-3.9 9.29-3.9l-10 1.65-1.29 1.61Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path660-8"
          d="M629.46 325.42c-3.5-.3-8.91-.95-12.29-.88 2-2.94 4.52-7.85 6.11-10.86-.94 2.81-2.5 6.55-3 9.2 2.57 1.12 6.41 1.74 9.18 2.54Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path661-7"
          d="M619.82 324.35c-.05-.06 6.29-7.67 6.29-7.67l-8 6-.37 2Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path662-4"
          d="M629.4 325.92c-3.75-.07-9.57-.4-13.16-.08 1.78-3.36 4-8.89 5.4-12.29-.71 3.13-2 7.33-2.22 10.25 2.81 1 7 1.45 10 2.12Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path663-2"
          d="M618.54 326.17c0-.07 7.65-7.34 7.65-7.34l-9.25 5.17-.64 2.19h2.24Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path638-7"
          d="M627.73 326.17c-4 0-10.24-.2-14.17.24 2.61-3.74 6.17-9.9 8.37-13.68-1.42 3.47-3.68 8.13-4.52 11.35 2.81 1.04 7.19 1.43 10.32 2.09Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path639-7"
          d="M616 326.71c0-.07 9.76-8.27 9.76-8.27l-11 5.87-1.14 2.43h2.41Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path640-9"
          d="M626.27 326.42c-4 .57-10.17 1.22-14 2.2 2-4 4.7-10.51 6.33-14.5-.9 3.58-2.47 8.43-2.85 11.69 2.93.63 7.33.4 10.52.61Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path641-3"
          d="M614.67 328.34c-.06-.06 7.52-10.42 7.52-10.42l-9.39 8.49-.5 2.6Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path642-1"
          d="M624.07 327.65c-4 .37-10.24.71-14.12 1.49 2.27-3.89 5.28-10.25 7.14-14.15-1.11 3.53-3 8.29-3.5 11.53 2.89.77 7.3.76 10.48 1.13Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path643-9"
          d="M613.31 329.44c0-.07 9.58-8.29 9.58-8.29L612 327l-1.1 2.45Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path644-8"
          d="M622.89 328.07c-4 .85-10.23 1.92-14.06 3.15 1.88-4.09 4.23-10.68 5.68-14.73-.74 3.6-2.08 8.48-2.3 11.72 3 .42 7.44-.12 10.68-.14Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path645-6"
          d="M611.71 329.87c-.07-.05 6.12-11.36 6.12-11.36l-8.28 9.82-.14 2.6z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path622-5"
          d="M622.89 328.71c-4.3 1.12-11 2.59-15.06 4.13 1.56-4.66 3.34-12.12 4.45-16.7-.38 4-1.26 9.56-1.1 13.19 3.35.33 8.15-.46 11.71-.62Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path623-0"
          d="M610.51 332.5c-.07-.07 7.7-11.39 7.7-11.39l-9.85 9.2-.42 2.88Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path624-2"
          d="M621.18 330c-4.22 1.34-10.82 3.15-14.79 4.89 1.34-4.68 2.76-12.15 3.64-16.74-.18 4-.79 9.52-.47 13.1 3.35.15 8.09-.89 11.62-1.25Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path625-8"
          d="M609 334.17c-.08-.05 5.86-12.57 5.86-12.57l-8.33 10.83v2.89Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path626-6"
          d="M619.57 331.72c-4.33 1-11.06 2.25-15.17 3.66 1.75-4.56 3.85-11.88 5.15-16.39-.55 4-1.66 9.43-1.66 13 3.32.44 8.11-.21 11.68-.27Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path627-0"
          d="M607.57 335.38c-.05-.08 9.58-9.84 9.58-9.84l-11.29 7.29-.92 2.76Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path628-2"
          d="M617.64 333.43c-4.29.57-10.94 1.19-15 2.22 2-4.46 4.49-11.71 6-16.17-.78 4-2.2 9.43-2.42 13.08 3.21.76 7.95.58 11.42.87Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path629-4"
          d="M605.68 334.4c-.07-.06 6.51-12.31 6.51-12.31l-8.83 10.36-.14 2.93 2.46-1Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path551-8"
          d="M618.18 332c-4.66 1.91-11.95 4.57-16.32 6.92 1.43-5.53 2.94-14.31 3.88-19.7-.17 4.67-.8 11.1-.41 15.21 3.67-.14 8.95-1.75 12.85-2.43Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path553-6"
          d="M604.78 338.11c-.07-.06 7.8-14.12 7.8-14.12l-10.29 11.95-.29 3.34Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path560-5"
          d="M616.38 333.7c-4.57 2.15-11.72 5.19-16 7.76 1.18-5.54 2.3-14.26 3-19.63 0 4.61-.31 11 .27 15 3.71-.32 8.87-2.21 12.73-3.13Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path561-0"
          d="M603.18 340.25c-.09-.05 5.69-15.2 5.69-15.2l-8.52 13.58.22 3.29Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path562-9"
          d="M614.39 335c-4.56 2.19-11.7 5.28-15.94 7.88 1.14-5.54 2.21-14.27 2.87-19.64.07 4.61-.24 11 .36 15 3.7-.39 8.85-2.32 12.71-3.24Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path563-0"
          d="M601.91 342.06c-.07-.07 8.8-13.4 8.8-13.4l-11.1 11-.54 3.32Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path564-0"
          d="M614 334.28c-4.07 3.24-10.5 8-14.17 11.58 0-5.59-.75-14.22-1.2-19.52 1 4.39 2 10.56 3.45 14.25 3.55-1.27 8.25-4.42 11.92-6.31Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path565-6"
          d="M602.33 342.71c-.1 0 1-16.06 1-16.06l-4.24 15.62 1.18 2.94Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path566-1"
          d="M612.8 335.76c-4.07 3.22-10.52 8-14.2 11.51 0-5.6-.7-14.23-1.13-19.53 1 4.39 2 10.57 3.4 14.27 3.58-1.26 8.3-4.38 11.93-6.25Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path567-3"
          d="M601.31 345.43c-.09 0 4.1-15.69 4.1-15.69l-7.1 14.43.56 3.22Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path568-8"
          d="M612.35 336.14c-3.85 3.8-10 9.43-13.39 13.51-.61-5.74-2.3-14.51-3.34-19.9 1.53 4.39 3.23 10.6 5.1 14.23 3.56-1.72 8.08-5.48 11.63-7.84Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path569-9"
          d="M601.33 347.43c-.1 0 .77-16.69.77-16.69L598 347l1.27 3.06Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path570-3"
          d="M611.13 338.91c-4.12 3.41-10.65 8.42-14.36 12.16-.13-5.81-1.09-14.77-1.67-20.27 1.14 4.55 2.32 10.95 3.88 14.78 3.66-1.36 8.45-4.68 12.15-6.67Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path571-4"
          d="M600.1 349.34c-.09 0 5.81-15.69 5.81-15.69l-8.71 13.9.23 3.45Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path572-4"
          d="M611.06 339c-3.65 3.86-9.46 9.59-12.69 13.74-.57-5.82-2.19-14.7-3.17-20.17 1.45 4.45 3.07 10.74 4.84 14.42 3.38-1.78 7.66-5.62 11.02-7.99Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path573-6"
          d="M600.33 350.14c-.1 0-.69-16.59-.69-16.59L597 350.07l1.5 2.93Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path574-0"
          d="M609.27 340.21c-3.53 3.89-9.16 9.67-12.26 13.85-.75-5.82-2.62-14.7-3.77-20.16 1.58 4.44 3.38 10.73 5.26 14.39 3.32-1.78 7.5-5.66 10.77-8.08Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path575-6"
          d="M599.4 351.79c-.1 0 1.85-16.86 1.85-16.86l-4.94 15.94 1 3.28Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path576-6"
          d="M608.51 340.87c-3.23 4.21-8.41 10.51-11.19 15-1.18-5.72-3.73-14.36-5.28-19.68 1.91 4.26 4.19 10.34 6.34 13.8 3.18-2.13 7.04-6.39 10.13-9.12Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path577-1"
          d="M599.33 353.36c-.1 0-1.07-16.87-1.07-16.87l-2.13 16.74 1.53 2.95Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path578-8"
          d="M608.18 341.84c-3 4.41-7.92 11-10.49 15.66-1.48-5.69-4.47-14.27-6.3-19.54 2.13 4.19 4.73 10.19 7.06 13.57 3.09-2.28 6.76-6.78 9.73-9.69Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path579-4"
          d="M600.38 354.94c-.09 0 1.72-16.95 1.72-16.95l-4.83 15.94 1 3.33Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path580-9"
          d="M607.56 343.41c-2.61 4.81-6.87 12.07-9 17-2-5.46-5.73-13.56-8-18.55 2.49 3.87 5.61 9.47 8.24 12.5 2.84-2.67 6.07-7.64 8.76-10.95Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path581-6"
          d="M599.79 356.34c-.09 0-4.74-16.08-4.74-16.08l1.61 16.95 2.13 2.39Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path582-3"
          d="M607.47 345.28c-2.72 4.8-7.16 12-9.38 17-2-5.44-5.78-13.53-8.1-18.5 2.53 3.86 5.69 9.45 8.37 12.46 2.96-2.69 6.32-7.65 9.11-10.96Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path583-7"
          d="M600 359.37c-.1 0-1.74-16.91-1.74-16.91l-1.57 16.91 1.69 2.9Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path584-8"
          d="M607.74 346.76c-2.79 4.73-7.35 11.86-9.65 16.76-1.91-5.49-5.56-13.66-7.8-18.69 2.47 3.92 5.54 9.58 8.16 12.67 3.01-2.62 6.45-7.5 9.29-10.74Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path585-8"
          d="M599.84 360.72c-.1 0-3.14-16.6-3.14-16.6l-.15 17 1.93 2.68Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path586-2"
          d="M606.54 349.15c-3 4.54-7.9 11.34-10.43 16.07-1.65-5.61-4.9-14-6.9-19.2 2.29 4.08 5.08 10 7.55 13.21 3.12-2.41 6.79-7.04 9.78-10.08Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path587-9"
          d="M598.39 362.57c-.1 0 1.4-17 1.4-17l-4.62 16.09 1.11 3.28Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path588-1"
          d="M606.72 349.58c-2.58 4.91-6.8 12.33-8.88 17.37-2.15-5.35-6.17-13.27-8.63-18.14 2.64 3.75 6 9.2 8.72 12.1 2.89-2.81 6.07-7.91 8.79-11.33Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path589-3"
          d="M597.81 364c-.1 0-5.21-15.81-5.21-15.81l2 16.82 2.24 2.28Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path590-5"
          d="M606 350.84c-1.81 5.39-4.85 13.6-6.14 19-2.92-4.79-8.07-11.69-11.22-15.94 3.16 3.11 7.25 7.74 10.41 10 2.43-3.4 4.83-9.11 6.95-13.06Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path591-9"
          d="M602.45 365.28c-.09 0-5.64-15.6-5.64-15.6l2.47 16.73 2.3 2.19Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path592-8"
          d="M606 352.49c-1.77 5.4-4.75 13.64-6 19.06-3-4.75-8.16-11.6-11.34-15.81 3.19 3.07 7.31 7.65 10.48 9.87 2.37-3.42 4.73-9.16 6.86-13.12Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path593-4"
          d="M602.5 367.94c-.1.05-7.26-14.59-7.26-14.59l4.23 16.2 2.52 1.82Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path594-0"
          d="M605.43 353.85c-1.13 5.61-3.14 14.21-3.76 19.77-3.48-4.29-9.42-10.34-13.07-14.06 3.52 2.59 8.14 6.54 11.53 8.29 1.98-3.73 3.66-9.76 5.3-14Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path595-7"
          d="M603 370.22c-.1 0-3.91-16.35-3.91-16.35l.64 17 2 2.54Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path596-6"
          d="M605.45 355.59c-.6 5.74-1.79 14.57-1.89 20.18-3.85-3.8-10.31-9-14.27-12.23 3.73 2.1 8.68 5.42 12.21 6.7 1.62-3.98 2.72-10.24 3.95-14.65Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path597-3"
          d="M603.16 371.43c-.08.07-10.34-11.81-10.34-11.81l7.79 14.3 2.85 1Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path598-6"
          d="M605.66 357.6c-.61 5.74-1.84 14.56-2 20.17-3.84-3.83-10.28-9.08-14.23-12.31 3.72 2.12 8.66 5.46 12.19 6.76 1.67-3.97 2.8-10.22 4.04-14.62Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path599-1"
          d="M604.32 374.16c-.09 0-8-14.23-8-14.23l5 16 2.61 1.71Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path600-5"
          d="M606.13 360c-.38 5.77-1.23 14.66-1.12 20.27-4-3.58-10.63-8.44-14.71-11.44 3.8 1.9 8.87 4.94 12.45 6 1.46-4.08 2.33-10.37 3.38-14.83Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path601-4"
          d="M605.32 376.76c-.08.08-9.81-12.44-9.81-12.44l7.15 14.77 2.81 1.15Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path602-2"
          d="M606.24 362.61c-.35 5.78-1.15 14.68-1 20.29-4-3.55-10.68-8.35-14.78-11.31 3.82 1.86 8.9 4.86 12.48 5.91 1.44-4.08 2.27-10.37 3.3-14.89Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path603-0"
          d="M606.21 378.88c-.09 0-6.88-15-6.88-15l3.81 16.42 2.47 2Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path604-9"
          d="M606.5 364.33c.19 5.79.22 14.76.89 20.33-4.31-3-11.37-6.88-15.71-9.27 4 1.34 9.28 3.63 12.93 4.2 1.05-4.25 1.29-10.59 1.89-15.26Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path605-7"
          d="M607.41 378.3c-.06.1-12.57-8.6-12.57-8.6l10.66 11.74 3 .15Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path606-3"
          d="M606.89 365c.5 5.77 1 14.7 2 20.22-4.48-2.82-11.75-6.42-16.22-8.64 4 1.18 9.48 3.28 13.16 3.7.8-4.28.7-10.68 1.06-15.28Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path607-7"
          d="M608.77 381.5c-.08.08-10.48-12-10.48-12l7.94 14.45 2.86 1Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path608-2"
          d="M607.8 367.16c.74 5.74 1.62 14.65 2.81 20.12-4.58-2.55-12-5.72-16.53-7.67 4.07.94 9.59 2.71 13.28 2.91.64-4.33.28-10.7.44-15.36Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path609-6"
          d="M610.86 383.53c-.06.09-12.47-8.81-12.47-8.81l10.52 11.91 3 .2Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path610-0"
          d="M608.19 369.6c1.12 5.67 2.58 14.51 4.13 19.87-4.72-2.13-12.28-4.59-16.93-6.12 4.11.56 9.71 1.8 13.39 1.66.35-4.4-.44-10.73-.59-15.41Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path611-1"
          d="M612.24 385.28c-.08.07-10.32-12.23-10.32-12.23l7.75 14.6 2.85 1.1Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path612-6"
          d="M608.87 371.17c1.64 5.51 3.91 14.15 5.94 19.29-4.86-1.49-12.56-2.94-17.3-3.84 4.11 0 9.76.5 13.38-.14-.06-4.48-1.43-10.66-2.02-15.31Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path613-5"
          d="M612.79 385.31c0 .11-14.41-3.09-14.41-3.09l13.7 6.82 2.77-1.05Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path614-7"
          d="M609.82 372.88c1.62 5.53 3.86 14.17 5.87 19.33-4.86-1.52-12.56-3-17.29-3.94 4.11 0 9.76.55 13.38-.07 0-4.43-1.39-10.66-2-15.32Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path615-5"
          d="M615.15 386.83c0 .1-13.79-6.66-13.79-6.66l12.37 10 3-.27Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path616-4"
          d="M609.11 373.86c2.74 5 6.74 13 9.79 17.56-5-.39-12.88-.11-17.68.06 4-.9 9.62-1.67 13-3.09-1-4.33-3.61-10.11-5.14-14.53Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path617-1"
          d="M617.32 388.34c0 .11-14.33-4-14.33-4l13.44 7.66 2.83-.86Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path618-2"
          d="M610.59 376c2.77 5 6.82 12.93 9.89 17.49-5.05-.34-12.86 0-17.67.21 4-.93 9.61-1.74 13-3.2-1-4.33-3.66-10.09-5.22-14.5Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path619-0"
          d="M619.13 389.52c0 .09-13.35-8-13.35-8l11.67 11.16h3l-1.32-3.2Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path620-0"
          d="M611.7 377.25c3.21 4.68 7.95 12.11 11.41 16.29-5 .32-12.63 1.68-17.33 2.51 3.84-1.45 9.27-3 12.46-4.91-1.41-4.21-4.58-9.66-6.54-13.89Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path621-1"
          d="M620.11 390.68c0 .12-14.41-.33-14.41-.33l14.3 4.21 2.53-1.58Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path664-4"
          d="m628.47 320.86 10.36-1.73-10.31.24-1.8.93Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path665-6"
          d="M634.16 323.08c-3-.91-7.55-2.43-10.64-3.11 3.28-1.3 8.14-3.61 11.13-5-2.3 1.44-5.65 3.29-7.43 4.73 1.72 1.14 4.85 2.3 6.94 3.38Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path666-0"
          d="M628 321.22c0-.05 10-2.76 10-2.76l-10.39 1.33-1.57 1.07Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path667-7"
          d="M634.64 323.8c-3.52-.33-8.91-1-12.38-1 2.62-2.31 6.28-6.18 8.54-8.56-1.55 2.23-3.94 5.2-5 7.31 2.39.91 6.2 1.54 8.84 2.25Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path668-1"
          d="m624.29 321.92 10.43-1.17-10.16-.33-1.9.84Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path669-7"
          d="M632 324.2c-3.2-.61-8-1.68-11.29-2.06 3.08-1.51 7.55-4.1 10.31-5.69a80 80 0 0 0-6.63 5.1c1.96.96 5.32 1.83 7.61 2.65Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path670-7"
          d="m623.38 321.82 9.18-3.95-10 2.65-1.21 1.23Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path671-7"
          d="M631.71 325.37c-2.66-1.1-6.74-3-9.37-3.74 2-1.89 4.91-5.21 6.68-7.25a59 59 0 0 0-3.94 6.69 73 73 0 0 0 6.63 4.3Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path672-7"
          d="M623.9 322.51c0-.06 7.14-3.09 7.14-3.09l-7.77 1.06-1 1.39Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1609-3"
          d="M617.93 389.36c0 .12-14.8.12-14.8.12l15 3.66 2.46-1.61-2.64-2.17Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1610-3"
          d="M620.59 390.33c0 .11-15-3.5-15-3.5l14.41 7.08 2.83-.91Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1611-5"
          d="M623.06 391.33c0 .11-14.92-.75-14.92-.75l14.92 4.49 2.55-1.45Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1612-9"
          d="M613.59 380.78c3.88 4.28 9.7 11.09 13.77 14.87-5 .75-12.56 2.78-17.2 4 3.69-1.78 9-3.78 11.94-5.92-2-3.97-5.95-9-8.51-12.95Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1613-9"
          d="M625.11 392.08c0 .11-14.91-4.88-14.91-4.88l14 8.33 2.94-.61Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1614-8"
          d="M615 381.76c4.23 3.86 10.61 10.05 15 13.38-4.8 1.38-11.94 4.36-16.33 6.18 3.41-2.25 8.34-4.92 11-7.46-2.42-3.79-6.79-8.41-9.67-12.1Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1615-1"
          d="M626.34 393c0 .11-14.15 2.78-14.15 2.78l14.91 1 2.1-2.08Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1616-8"
          d="M623.38 392.12c0 .11-14.76 1.13-14.76 1.13l15.23 2.62 2.33-1.78Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1617-2"
          d="M626.1 392.9c0 .11-15.26-2.45-15.26-2.45l14.94 6.05 2.75-1.1Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1618-6"
          d="M628.65 393.72c0 .12-14.95.28-14.95.28l15.23 3.46 2.44-1.63Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1619-6"
          d="M618.4 383.86c4.2 4 10.51 10.39 14.86 13.87-4.94 1.1-12.32 3.64-16.87 5.19 3.55-2 8.68-4.38 11.48-6.72-2.3-3.83-6.61-8.57-9.47-12.34Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1620-0"
          d="M630.75 394.33c0 .11-15.25-3.83-15.25-3.83l14.61 7.33L633 397Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1621-3"
          d="M619.85 384.75c4.52 3.55 11.35 9.25 15.95 12.25-4.69 1.71-11.59 5.18-15.83 7.3 3.23-2.48 7.95-5.49 10.43-8.21-2.65-3.57-7.4-7.86-10.55-11.34Z"
          className="Paraguay_svg__cls-5"
        />
        <path
          id="Paraguay_svg__path1622-8"
          d="M632.05 395.17c0 .11-13.92 3.75-13.92 3.75h15l1.95-2.21-3-1.52Z"
          className="Paraguay_svg__cls-5"
        />
      </g>
      <path
        id="Paraguay_svg__path4809"
        d="m651.27 374.23-11.37-7.86-11.37 7.86 4.27-12.57-9.95-7.86h12.79l4.26-12.57 4.26 12.57H657l-10 7.86Z"
        style={{
          stroke: "#000",
          fill: "#fedf00",
          strokeWidth: ".54px",
        }}
      />
      <g id="Paraguay_svg__text5478">
        <path
          id="Paraguay_svg__path5506"
          d="m590.88 395.52-10.67 6.33-3.27-5.49a9.2 9.2 0 0 1-1.12-2.5A2.9 2.9 0 0 1 576 392a3.5 3.5 0 0 1 1.44-1.52 3.45 3.45 0 0 1 1.76-.53 3.5 3.5 0 0 1 1.66.42 4.4 4.4 0 0 1 1.11.91 4.1 4.1 0 0 1-.12-1.09 4 4 0 0 1 .25-.86 4.6 4.6 0 0 1 .39-.86l2.14-3.43 2.22 3.73-2.22 3.69a3.1 3.1 0 0 0-.47 1.09 1.6 1.6 0 0 0 .24 1l.17.3 4.33-2.58Zm-8.32.45-.82-1.39a5.4 5.4 0 0 0-.67-.78 1 1 0 0 0-.65-.34 1.14 1.14 0 0 0-.74.16 1.1 1.1 0 0 0-.6.8 2.3 2.3 0 0 0 .45 1.39l.86 1.45Z"
        />
        <path
          id="Paraguay_svg__path5508"
          d="m572.52 387.72-3.25-9.72 2.52-.83 2 6.1 1.87-.62-1.85-5.65 2.4-.8 1.88 5.66 2.32-.77-2.09-6.28 2.67-.89 3.3 9.93Z"
        />
        <path
          id="Paraguay_svg__path5510"
          d="m568.26 373.23-.73-6.34a4.33 4.33 0 0 1 .63-3.21 3.73 3.73 0 0 1 2.68-1.35 3.84 3.84 0 0 1 3 .79 4.86 4.86 0 0 1 1.44 3.31l.24 2.08 4.58-.52.44 3.83Zm4.82-4.43-.11-.94a2.45 2.45 0 0 0-.56-1.5 1.22 1.22 0 0 0-1-.33 1.35 1.35 0 0 0-.94.5 2 2 0 0 0-.24 1.5l.12 1.08Z"
        />
        <path
          id="Paraguay_svg__path5512"
          d="m567.86 349.93.41-3.8 7.36.79a6.4 6.4 0 0 1 2 .56 4.6 4.6 0 0 1 1.59 1.26 4.3 4.3 0 0 1 .85 1.63 7.7 7.7 0 0 1 .13 2.72 18 18 0 0 1-.34 2 5.2 5.2 0 0 1-.69 1.73A4.6 4.6 0 0 1 578 358a3.8 3.8 0 0 1-1.51.66 7.7 7.7 0 0 1-2.13.13L567 358l.41-3.8 7.53.81a2.18 2.18 0 0 0 1.64-.39 2.41 2.41 0 0 0 .34-3.1 2.18 2.18 0 0 0-1.52-.73Z"
        />
        <path
          id="Paraguay_svg__path5514"
          d="m569.52 340.83 2.37-6.78a3.94 3.94 0 0 1 1.75-2.31 2.88 2.88 0 0 1 2.4-.18 2.77 2.77 0 0 1 1.55 1.27 3 3 0 0 1 .34 1.54 3.5 3.5 0 0 1 1.7-1.5 3.05 3.05 0 0 1 2.06.06 3.32 3.32 0 0 1 2.24 2.55 3.9 3.9 0 0 1-.1 1.51c-.18.86-.31 1.42-.4 1.68l-2.19 6.25Zm5.88-2.05.55-1.57a2 2 0 0 0 .12-1.29 1.15 1.15 0 0 0-.73-.62 1.08 1.08 0 0 0-.92.05 1.87 1.87 0 0 0-.69 1.06l-.56 1.59Zm4.6 1.61.64-1.85a2.08 2.08 0 0 0 .13-1.43 1.19 1.19 0 0 0-.75-.7 1.1 1.1 0 0 0-1 .09 2.25 2.25 0 0 0-.78 1.22l-.64 1.84Z"
        />
        <path
          id="Paraguay_svg__path5516"
          d="m575.4 325.9 2-3.28 8 4.83 3.1-5.12 2.61 1.58-5.11 8.41Z"
        />
        <path
          id="Paraguay_svg__path5518"
          d="m582.89 314.54 2.46-3 9.54 7.94-2.46 3Z"
        />
        <path
          id="Paraguay_svg__path5520"
          d="m599.58 308.13 3.21-1.42a6.2 6.2 0 0 1 .73 2.49 4.6 4.6 0 0 1-.44 2.26 7 7 0 0 1-1.77 2.16 8 8 0 0 1-2.89 1.71 5.3 5.3 0 0 1-3-.13 7 7 0 0 1-3.18-2.27 6.44 6.44 0 0 1-1.84-4.66 7.42 7.42 0 0 1 5.85-6 5.52 5.52 0 0 1 3.41.91l-2.11 2.82a2.9 2.9 0 0 0-.82-.39 2.3 2.3 0 0 0-1 0 2.2 2.2 0 0 0-.94.5 2.27 2.27 0 0 0-.84 2.1 4.6 4.6 0 0 0 1.2 2.2 4.72 4.72 0 0 0 2.32 1.74 2.48 2.48 0 0 0 2.7-2.12 4 4 0 0 0-.59-1.9"
        />
        <path
          id="Paraguay_svg__path5522"
          d="m612.81 303.37-3.81 2.06.43 2.09-3.46 1.84-1.72-13.14 3.69-2 10 8.76-3.54 1.88Zm-2-2-3.31-3.29.9 4.58Z"
        />
        <path
          id="Paraguay_svg__path5524"
          d="m622.56 288.67 5.62-1a6.7 6.7 0 0 1 2.76 0 4.55 4.55 0 0 1 1.91 1 5.6 5.6 0 0 1 1.31 1.8 9.4 9.4 0 0 1 .71 2.32 8.5 8.5 0 0 1 .08 3.11 5.1 5.1 0 0 1-.93 2 4 4 0 0 1-1.53 1.26 8.9 8.9 0 0 1-2.2.73l-5.61 1Zm4.26 2.11 1.16 6.69.92-.16a4 4 0 0 0 1.64-.56 1.8 1.8 0 0 0 .63-1.05 6.1 6.1 0 0 0-.08-2.17A4.22 4.22 0 0 0 630 291a2.73 2.73 0 0 0-2.22-.35Z"
        />
        <path
          id="Paraguay_svg__path5526"
          d="m638.5 286.53 10.27.44-.11 2.65-6.43-.28-.08 2 6 .26-.15 2.5-6-.26-.11 2.45 6.62.28-.12 2.81-10.39-.45Z"
        />
        <path
          id="Paraguay_svg__path5528"
          d="m653.45 287.71 3.72.91-2.17 9.09 5.81 1.42-.72 3-9.54-2.33Z"
        />
        <path
          id="Paraguay_svg__path5530"
          d="m673.36 294.31 5.49 3.25A4.33 4.33 0 0 1 681 300a3.7 3.7 0 0 1-.54 2.95 3.86 3.86 0 0 1-2.47 2 4.93 4.93 0 0 1-3.51-.85l-1.8-1.07-2.35 4-3.31-2Zm.63 6.52.81.48a2.42 2.42 0 0 0 1.53.46 1.25 1.25 0 0 0 .89-.62 1.36 1.36 0 0 0 .17-1 2 2 0 0 0-1-1.1l-.94-.56Z"
        />
        <path
          id="Paraguay_svg__path5532"
          d="m684.74 315.29-3.2-3-1.83 1.09-2.87-2.67 11.87-5.91 3.06 2.84-5 12.27-2.94-2.73Zm1.24-2.51 2-4.2-4 2.34Z"
        />
        <path
          id="Paraguay_svg__path5534"
          d="m687.47 321 10.24-7 3.62 5.27a9.1 9.1 0 0 1 1.28 2.41 2.86 2.86 0 0 1-.07 1.89 3.4 3.4 0 0 1-1.33 1.61 3.32 3.32 0 0 1-3.41.34 4.8 4.8 0 0 1-1.17-.83 3.5 3.5 0 0 1 .19 1.08 3.8 3.8 0 0 1-.19.87 4.4 4.4 0 0 1-.33.88l-1.92 3.57-2.45-3.58 2-3.83a2.7 2.7 0 0 0 .39-1.12 1.53 1.53 0 0 0-.29-.93l-.19-.28-4.16 2.85Zm8.27-1 .92 1.33a5 5 0 0 0 .71.75 1.05 1.05 0 0 0 .68.29 1.22 1.22 0 0 0 .73-.21 1.14 1.14 0 0 0 .54-.84 2.36 2.36 0 0 0-.54-1.36l-1-1.39Z"
        />
        <path
          id="Paraguay_svg__path5536"
          d="m699.59 338.63-1.59-4.06-2.12.2-1.46-3.64 13.26-.27 1.55 3.89-9.8 8.93-1.52-3.68Zm2.2-1.75 3.63-2.92-4.65.38Z"
        />
        <path
          id="Paraguay_svg__path5538"
          d="m705 350.44 2.55-.38.88 5.86-5.23.78a11.6 11.6 0 0 1-2-2.74 9.8 9.8 0 0 1-.87-3 7.5 7.5 0 0 1 .21-3.67 5.15 5.15 0 0 1 1.9-2.47 7.3 7.3 0 0 1 3.27-1.26 7.2 7.2 0 0 1 3.62.3 5.5 5.5 0 0 1 2.62 2.09 8.1 8.1 0 0 1 1.09 3.27 9.2 9.2 0 0 1 .09 3.1 4 4 0 0 1-.89 1.85 5.2 5.2 0 0 1-1.81 1.29l-1.21-3.56a2 2 0 0 0 .95-.93 2.44 2.44 0 0 0 .17-1.43 2.55 2.55 0 0 0-1.17-1.86 4.24 4.24 0 0 0-2.85-.33 4.36 4.36 0 0 0-2.74 1.18 2.67 2.67 0 0 0-.54 2.21 4 4 0 0 0 .37 1.19 6.4 6.4 0 0 0 .82 1.23l1.16-.17Z"
        />
        <path
          id="Paraguay_svg__path5540"
          d="m713.12 368.46-.34 3.81-7.37-.65a6.3 6.3 0 0 1-2-.53 4.5 4.5 0 0 1-1.61-1.22 4.3 4.3 0 0 1-.88-1.62 7.8 7.8 0 0 1-.18-2.71c.06-.61.16-1.26.3-2a5.2 5.2 0 0 1 .66-1.74 4.6 4.6 0 0 1 1.19-1.22 3.9 3.9 0 0 1 1.5-.68 7 7 0 0 1 2.12-.17l7.37.65-.34 3.82-7.55-.68a2.16 2.16 0 0 0-1.63.42 2.13 2.13 0 0 0-.71 1.51 2.1 2.1 0 0 0 .43 1.6 2.17 2.17 0 0 0 1.54.7Z"
        />
        <path
          id="Paraguay_svg__path5542"
          d="m699.4 381.6 1.37-4.15-1.77-1.21 1.22-3.72 10.34 8.31-1.31 4-13.25.56 1.26-3.82Zm2.81.08 4.66.09-3.81-2.69Z"
        />
        <path
          id="Paraguay_svg__path5544"
          d="m706.65 391.76-2.19 3.65h-4.89l2.31 4.31-2.18 3.63-3.7-7.85-4.46-2.67 2-3.3 4.45 2.67Z"
        />
      </g>
      <path
        id="Paraguay_svg__path5494"
        d="M715.53 360a75.63 75.63 0 1 1-151.26 0v0a75.63 75.63 0 0 1 151.26 0Z"
        style={{
          fill: "none",
          strokeLinecap: "round",
          stroke: "#000",
          strokeWidth: "1.68px",
        }}
      />
      <path
        id="Paraguay_svg__path5496"
        d="M697.83 360A57.93 57.93 0 1 1 582 360h0a57.93 57.93 0 1 1 115.86 0Z"
        style={{
          fill: "none",
          strokeLinecap: "round",
          stroke: "#000",
          strokeWidth: "2.01px",
        }}
      />
    </g>
  </svg>
);
export default SvgParaguay;
