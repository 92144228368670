import * as React from "react";
import type { SVGProps } from "react";
const SvgGrenada = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Grenada_svg__cls-1{fill:#ce1126}.Grenada_svg__cls-3{fill:#fcd116}.Grenada_svg__cls-4{fill:#f8d513}"
        }
      </style>
    </defs>
    <g id="Grenada_svg__Grenada">
      <path d="M0 0h1280v720H0z" className="Grenada_svg__cls-1" />
      <path
        d="M140.8 100.8h998.4v518.4H140.8z"
        style={{
          fill: "#007a5e",
        }}
      />
      <path
        d="M140.8 100.8h998.4L140.8 619.2h998.4Z"
        className="Grenada_svg__cls-3"
      />
      <circle cx={640} cy={360} r={86.4} className="Grenada_svg__cls-1" />
      <g id="Grenada_svg__c">
        <path
          id="Grenada_svg__t"
          d="m640 280.8-24.47 75.32 37.66 12.24"
          className="Grenada_svg__cls-3"
        />
        <path
          id="Grenada_svg__t-2"
          d="m640 280.8 24.47 75.32-37.66 12.24"
          className="Grenada_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Grenada_svg__c-2" data-name="c">
        <path
          id="Grenada_svg__t-3"
          d="M715.32 335.53h-79.2v39.6"
          className="Grenada_svg__cls-3"
          data-name="t"
        />
        <path
          id="Grenada_svg__t-4"
          d="m715.32 335.53-64.07 46.55L628 350"
          className="Grenada_svg__cls-3"
          data-name="t"
        />
      </g>
      <path
        id="Grenada_svg__t-5"
        d="m686.55 424.07-24.47-75.32L624.42 361"
        className="Grenada_svg__cls-3"
        data-name="t"
      />
      <path
        id="Grenada_svg__t-6"
        d="m686.55 424.07-64.07-46.55 23.27-32"
        className="Grenada_svg__cls-3"
        data-name="t"
      />
      <g id="Grenada_svg__c-3" data-name="c">
        <path
          id="Grenada_svg__t-7"
          d="m593.45 424.07 64.07-46.55-23.27-32"
          className="Grenada_svg__cls-3"
          data-name="t"
        />
        <path
          id="Grenada_svg__t-8"
          d="m593.45 424.07 24.47-75.32L655.58 361"
          className="Grenada_svg__cls-3"
          data-name="t"
        />
      </g>
      <g id="Grenada_svg__s">
        <g id="Grenada_svg__c-4" data-name="c">
          <path
            id="Grenada_svg__t-9"
            d="m564.68 335.53 64.07 46.55L652 350"
            className="Grenada_svg__cls-3"
            data-name="t"
          />
          <path
            id="Grenada_svg__t-10"
            d="M564.68 335.53h79.2v39.6"
            className="Grenada_svg__cls-3"
            data-name="t"
          />
        </g>
      </g>
      <g id="Grenada_svg__flame">
        <path
          d="M201.06 360c10.81 20.14 31.83 42 50.2 49.75.29-20.45-7-46.65-16.89-67.53Z"
          className="Grenada_svg__cls-1"
        />
        <path
          d="M182.73 290.9c15.67 32.66-40.64 110.59 53.17 128.85-11.57-15.79-19-41.85-16.21-62.78 19.68 7.49 40.39 29.4 48.76 46.15C293 314 205.48 319.39 182.73 290.9"
          className="Grenada_svg__cls-3"
        />
      </g>
      <path
        d="M835.37 35.67h34.32L880.29 3l10.6 32.64h34.32l-27.76 20.2 10.6 32.64-27.76-20.17-27.76 20.17 10.6-32.64ZM595.08 35.67h34.32L640 3l10.6 32.64h34.32l-27.76 20.2 10.6 32.64L640 68.31l-27.76 20.17 10.6-32.64ZM355.37 35.67h34.32L400.29 3l10.6 32.64h34.32l-27.76 20.2 10.6 32.64-27.76-20.17-27.76 20.17 10.6-32.64ZM835.08 654.75h34.32l10.6-32.64 10.6 32.64h34.32l-27.76 20.17 10.6 32.64L880 687.39l-27.76 20.17 10.6-32.64ZM594.79 654.75h34.32l10.6-32.64 10.6 32.64h34.32l-27.76 20.17 10.6 32.64-27.76-20.17L612 707.56l10.6-32.64ZM355.08 654.75h34.32l10.6-32.64 10.6 32.64h34.32l-27.76 20.17 10.6 32.64L400 687.39l-27.76 20.17 10.6-32.64Z"
        className="Grenada_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgGrenada;
