import * as React from "react";
import type { SVGProps } from "react";
const SvgHonduras = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Honduras_svg__cls-1{fill:#0073cf}"}</style>
    </defs>
    <g id="Honduras_svg__Honduras">
      <path d="M0 0h1280v720H0z" className="Honduras_svg__cls-1" />
      <path
        d="M0 240h1280v240H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M604.58 348.28h27.06l8.36-25.74 8.36 25.74h27.06l-21.89 15.9 8.36 25.74L640 374l-21.89 15.9 8.36-25.74ZM427.26 291.78h27.06l8.36-25.78 8.32 25.78h27.07l-21.9 15.91 8.36 25.74-21.89-15.91-21.89 15.91 8.36-25.74ZM427.26 398.45h27.06l8.36-25.74 8.32 25.74h27.07l-21.9 15.9 8.36 25.74-21.89-15.9-21.89 15.9 8.36-25.74ZM781.89 291.78H809l8.32-25.78 8.36 25.74h27.06l-21.89 15.91 8.36 25.74-21.89-15.91-21.89 15.91 8.36-25.74ZM781.89 398.73H809l8.32-25.73 8.36 25.74h27.06l-21.89 15.9 8.36 25.74-21.89-15.9-21.89 15.9 8.36-25.74Z"
        className="Honduras_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgHonduras;
