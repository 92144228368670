import * as React from "react";
import type { SVGProps } from "react";
const SvgVenezuela = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Venezuela_svg__cls-4{fill:#fff}"}</style>
    </defs>
    <g id="Venezuela_svg__Venezuela">
      <path
        d="M0 0h1280v239.99H0z"
        style={{
          fill: "#fc0",
        }}
      />
      <path
        d="M0 239.99h1280v240.02H0z"
        style={{
          fill: "#00247d",
        }}
      />
      <path
        d="M0 480.01h1280V720H0z"
        style={{
          fill: "#cf142b",
        }}
      />
      <path
        d="m586.42 325.45 9.59-68.33 32.43 60.94-62.05-30.25 67.96-12zM651.83 318.06l32.4-60.94 9.62 68.33-47.96-49.64 67.99 12zM527.47 354.81l-14.33-67.52 51.28 46.19-68.63-7.21 59.76-34.52zM715.85 333.48l51.29-46.19-14.37 67.52-28.05-63.06 59.75 34.52zM482.12 402.52 445.57 344l63.99 25.85-66.97 16.7 44.36-52.86zM773.64 369.85 837.63 344l-36.55 58.52-4.83-68.83 44.37 52.86zM455.83 462.88l-54.37-42.48 68.98 2.4-57.24 38.6 23.62-64.85zM811.77 422.8l68.97-2.4-54.36 42.48 19.01-66.33 23.62 64.85z"
        className="Venezuela_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgVenezuela;
