import * as React from "react";
import type { SVGProps } from "react";
const SvgMozambique = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Mozambique_svg__cls-1{fill:#fff}.Mozambique_svg__cls-3{fill:#fce100}.Mozambique_svg__cls-7{stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.25px}"
        }
      </style>
    </defs>
    <g id="Mozambique_svg__Mozambique">
      <path d="M0 0h1280v720H0z" />
      <path d="M0 0h1280v247.5H0z" className="Mozambique_svg__cls-1" />
      <path
        d="M0 0h1280v225H0z"
        style={{
          fill: "#007168",
        }}
      />
      <path d="M0 472.2h1280V720H0z" className="Mozambique_svg__cls-1" />
      <path d="M0 495h1280v225H0z" className="Mozambique_svg__cls-3" />
      <path
        d="M0 0v720l472.5-360Z"
        style={{
          fill: "#d21034",
        }}
      />
      <path
        d="m245.24 495-87.78-64.27L69.79 495l34-103.35-88.23-63.51 108.8.4L157.49 225l33.24 103.6 108.71-.49-88.25 63.63Z"
        className="Mozambique_svg__cls-3"
      />
      <path
        d="M81.27 422.91h63.24c5.09 5.71 16.44 8 27.25-.07 19.82-10.85 58.17.07 58.17.07l7.55-8-18.41-60.41-6.6-7.08s-14.16-8.49-41.06-5.66-36.34-.94-36.34-.94-23.6 2.83-30.2 6.13c-.73.59-7.56 7.55-7.56 7.55Z"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.25px",
        }}
      />
      <path
        d="M94 407.34s60.41-7.55 77.87 15.57c-9.86 6.75-18.54 7.31-27.84.48 1.48-2.47 21.7-23.6 73.15-16.52"
        style={{
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.25px",
          fill: "none",
        }}
      />
      <path
        d="m158.67 346.93-.48 66.55m54.28-65.6 11.32 52.85m-118.3-53.68L99.2 374.3M40.2 430.16l14.87 17.44a4.28 4.28 0 0 0 4.85 0l22-26.45 9.26-11.46A7.26 7.26 0 0 0 93 404.4l17.7-15.73c1.44.1 2.31.26 3.75.36-1.71-.44-3-1.27-1.62-3.15l4-3.08 3.09 4s-4.41 5.73-4.85 5.73h-4.85l-9.26 8.38 4 3.58 6.09 16.7 7.5-5.29-4.85-17.19 10.58-11.47-4-6.17 2.64-3.52s36.62 23.05 50.73 16.88c.38.14.85-16.44.85-16.44s-37.91-4-38.79-11.46 8.37-8.38 8.37-8.38l-4-5.73.89-3.09 6.61 8.38 15-12.78 88.16 100.5c4.81-1.95 5.84-3.13 6.17-7.93-.12-.12-86.4-99.19-86.4-99.19l6.61-7a5.19 5.19 0 0 0 1.77-4.41l10.19-8.9a12.52 12.52 0 0 1 6.61 5.29l27.87-23.63a4.41 4.41 0 0 0 4.48.65l46.08-44.24-50.22 35.48-1.76-1.33c0-1.46 1.82-1.83 0-4.4-1.95-2.35-4.85 2.2-5.29 2.2s-7.27-2.41-8.77-5.47l-.49 8.12-12.78 11.9-9.7-.44L171 315.79l-1.76 5.29 2.2 4.41s-7.44 6.61-7.44 6.17-1.52-2-1.58-2.14l6.43-5.79.88-4-2.15-3.35c-.65.47-8.87 9.08-9.31 8.2-.74-.82-23.8-26.89-23.8-26.89l1.32-4.85-15-16.31c-5.46-1.89-14.11-2.2-15.87 9.7-1.37 2.79-12.78.44-12.78.44L85.93 288 51.1 337.39l19.4 23.36 39.67-50.25 1.18-14.23 8.32 9.3c2.78.36 5.42.39 7.94-.88l23.5 26.24-3.91 3.82c1.32 1.47 2.23 2.4 3.55 3.87 1.33-.88 2.59-1.94 3.91-2.82.44.59 1.18 1.71 1.62 2.3-2 1.07-3.35 2.5-5.32 3.57-3.15-2.06-6.2-4.62-6-8.69l-13.22 11-.44 2.21-39.2 32.64-3.53.44-.88 10.14 25.57-21.16v-3.09l2.64 2.21 19.84-15.87s1.32 1.76.88 1.76S119 369.13 119 369.13l-.44 1.76-3.09 2.65-1.76-1.32-23.82 21.15h-3.52L73.14 406.6c-3.41.3-6.36.66-9.26 2.64Z"
        className="Mozambique_svg__cls-7"
      />
    </g>
  </svg>
);
export default SvgMozambique;
