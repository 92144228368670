import * as React from "react";
import type { SVGProps } from "react";
const SvgNorway = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Norway_svg__cls-2{fill:#fff}.Norway_svg__cls-3{fill:#212f64}"}
      </style>
    </defs>
    <g id="Norway_svg__Norway">
      <path
        d="M0-.04h1280v720.07H0z"
        style={{
          fill: "#ed2d2f",
        }}
      />
      <path
        d="M340.03-.04h180.02v720.07H340.03z"
        className="Norway_svg__cls-2"
      />
      <path d="M0 269.99h1280v180.02H0z" className="Norway_svg__cls-2" />
      <path
        d="M385.03-.04h90.01v720.07h-90.01z"
        className="Norway_svg__cls-3"
      />
      <path d="M0 315h1280v90.01H0z" className="Norway_svg__cls-3" />
    </g>
  </svg>
);
export default SvgNorway;
