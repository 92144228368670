import * as React from "react";
import type { SVGProps } from "react";
const SvgSouthAfrica = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <clipPath id="South_Africa_svg__clip-path">
        <path
          d="M0 819.51h1280v720.99H0z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <style>{".South_Africa_svg__cls-3{fill:#fff}"}</style>
    </defs>
    <path
      id="South_Africa_svg__Namibia"
      d="m67.03 1651.45-134.06-221.92 1280-720.98 134.06 221.92z"
      className="South_Africa_svg__cls-3"
      style={{
        clipPath: "url(#South_Africa_svg__clip-path)",
      }}
    />
    <g id="South_Africa_svg__South_Africa" data-name="South Africa">
      <path
        d="m224.33 0 373.34 239.99H1280V0z"
        style={{
          fill: "#de3831",
        }}
      />
      <path
        d="M672.35 288.01H1280v-48.02H597.67zM597.67 480.01H1280v-48.02H672.35z"
        className="South_Africa_svg__cls-3"
      />
      <path
        d="M597.67 480.01 224.33 720H1280V480.01z"
        style={{
          fill: "#002395",
        }}
      />
      <path d="M0 239.99h148.98L0 144.21zM0 239.99v240.02h148.98L335.65 360 148.98 239.99zM0 480.01v95.78l148.98-95.78z" />
      <path
        d="M0 144.21 335.65 360 0 575.79v57.67L425.39 360 0 86.54z"
        style={{
          fill: "#f2b126",
        }}
      />
      <path
        d="M0 0v86.54L425.39 360 0 633.46V720h134.59l448.02-288.01H1280V288.01H582.61L134.59 0z"
        style={{
          fill: "#007a4d",
        }}
      />
    </g>
  </svg>
);
export default SvgSouthAfrica;
