import * as React from "react";
import type { SVGProps } from "react";
const SvgUganda = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Uganda_svg__cls-1{fill:#fcdc04}.Uganda_svg__cls-2,.Uganda_svg__cls-4{fill:#d90000}.Uganda_svg__cls-3{fill:#fff}.Uganda_svg__cls-4{stroke:#000}.Uganda_svg__cls-7{fill:#9ca69c;stroke:#9ca69c}"
        }
      </style>
    </defs>
    <g id="Uganda_svg__Uganda">
      <path d="M0 0h1280v720H0z" />
      <path d="M0 120h1280v120H0z" className="Uganda_svg__cls-1" />
      <path d="M0 240h1280v120H0z" className="Uganda_svg__cls-2" />
      <path d="M0 480h1280v120H0z" className="Uganda_svg__cls-1" />
      <path d="M0 600h1280v120H0z" className="Uganda_svg__cls-2" />
      <circle cx={640} cy={360} r={112.2} className="Uganda_svg__cls-3" />
      <path
        d="M620.22 274.07 613 260.66c2.81-2.8 7.53-5 15.06-5 0 .51-.76 14.68-.76 14.68Z"
        className="Uganda_svg__cls-4"
      />
      <path
        d="m627.25 270.49 1-14.8s15.06-.9 23.23 8.93c.13-.13-8 11.49-8 11.49Z"
        style={{
          fill: "#fcdc04",
          stroke: "#000",
        }}
      />
      <path
        d="m643.71 276 7.41-11.49c5 5.24 7 8.94 7.79 14.56.12.13-11.75 2.93-11.75 2.81s-3.32-5.77-3.45-5.88Z"
        className="Uganda_svg__cls-4"
      />
      <path
        d="M623.93 465.69s13.91-16 41-12.51c-4.09-6.64-17.24-5.88-17.24-5.88s-4.08-31-.89-32.55 16.72.13 16.72.13c1.79 0 4.85-4.85 2.43-7.92s-9.58-14.8-6.64-17.1 18.89 1.27 18.89 1.27l-45.06-57.7s-4.6-21.7 4.59-32.17c11.11-9.19 10-19.15 9.58-19-1.53-10.09-16.85-17.36-27.19-8-6.13 7.4-2.05 13-2.05 13s-16.08 4.34-16.72 7.15 18.13-.51 18.13-.51l-1.79 12.89s-36.51 33.19-8.55 61.92a6.1 6.1 0 0 0 .89-1.28s9.83 12.13 20.17 14.81c9.7 10 8.81 8.42 8.81 8.42s1.91 15.71.13 18.77c-2.43-.77-27.19-1.66-30.9-.26-3.32 1-16.08.39-12.89 21.2 2.43-5.62 4.6-10.6 4.6-10.6s-.39 7.53 2.68 10.21a23.6 23.6 0 0 1 2.93-13.27s.64 8.68 2.56 9.95 1.91-14 12.51-12.76a120 120 0 0 0 18.25.89s3.58 30 2.43 32.81c-7.66-1.79-25.92.77-27.07 5.36 10.73-.64 15.71.64 15.71.64s-8.7 7.62-6.02 12.09Z"
        style={{
          stroke: "#000",
        }}
      />
      <path
        d="M628.23 302s-26.57 29.28-15.11 51.69c.61-3.12.35-5.09.73-5-.64-.38 3.3 2.7 3 2.1.08-1.62-1.2-5.09-1.2-5.09l3.59.9-2.09-3.9 5.09.6s-1.8-4.79-1.2-4.79 4.19.3 4.19.3c-7.55-13.53-.42-24.81 3-36.81ZM637.46 276.88s1.4 10.08-4.09 13c-.89.64-4.34 1.79-3.83 3.83.64 2.81 2.17 2.3 4.34 1.79 5.75-1.01 12.39-13.26 3.58-18.62Z"
        className="Uganda_svg__cls-7"
      />
      <circle cx={625.6} cy={285.6} r={2.16} className="Uganda_svg__cls-3" />
      <path
        d="M619.08 294c-1.41 1.15-8.81 8.81-1.54 11.62 7.54-2 5.49-3.45 7.15-5.11.05-3.46-3.69-4.36-5.61-6.51Z"
        className="Uganda_svg__cls-4"
      />
      <path
        d="M635.16 366.37c-.38 1.66-2 7.78.26 12.51 6.38-2.68 9.31-1.92 11.49-.51-5.24-4.22-7.28-6-11.75-12Z"
        className="Uganda_svg__cls-7"
      />
      <path
        d="m646.14 395.35.38 14.29s5 .9 7.28 0-.13-9.95-7.66-14.29Z"
        style={{
          fill: "#fff",
          stroke: "#fff",
        }}
      />
      <path
        d="M682.27 397.13s-9.19-22.21-32.68-27.83-20.43-30.63-18.52-32.17c1-2.17 1.79-5.49 8.56-2.29s37.91 18.89 42.38 19.65.64 43.15.26 42.64Z"
        style={{
          stroke: "#000",
          fill: "#9ca69c",
        }}
      />
      <path
        d="M659.93 369.18c-.39.25 31.4 18.76 21.83 34.72 9.06-6 6.12-16.47 6.12-16.47s7.41 19.28-10.59 28.73c1.91 1.66 3.19 1.27 3.19 1.27l-3.06 3.07s-1.41 2.29 10.72-3.58c-3.32 2.68-3.57 4.6-3.57 4.6s.89 2.55 8.8-4.34c-6.38 6.89-7.78 10.46-7.78 10.34 17.23-1.54 54.76-57.58-11.88-74.17 3.58 3.7 3.07 3.19 3.07 3.19Z"
        className="Uganda_svg__cls-4"
      />
      <path
        d="M661.33 363.69c4.34 3.06 5.87 4.08 6.38 5.61a28.9 28.9 0 0 0-7.53-.63s-8.55-8.17-10.08-8.81c-1.15 0-7.79-4.22-7.79-4.22-3.32-1.66-6.38-13.14 5.87-9.83 12.64 6 14.43 6.52 14.43 6.52l15.06 4.72q4.35 4.84 8.68 9.7S670.91 359.09 669 359a29.3 29.3 0 0 1 6.64 8.17 106 106 0 0 0-14.31-3.48Z"
        style={{
          fill: "#fff",
          stroke: "#000",
        }}
      />
      <path
        d="M601.2 295.13s14.81-3.57 16.6-3.06"
        style={{
          stroke: "#fff",
        }}
      />
    </g>
  </svg>
);
export default SvgUganda;
