import * as React from "react";
import type { SVGProps } from "react";
const SvgTuvalu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Tuvalu_svg__cls-2{fill:#f3d02f}.Tuvalu_svg__cls-2,.Tuvalu_svg__cls-6{fill-rule:evenodd}.Tuvalu_svg__cls-4{fill:#fff}.Tuvalu_svg__cls-5,.Tuvalu_svg__cls-6{fill:#c0003b}"
        }
      </style>
    </defs>
    <g id="Tuvalu_svg__Tuvalu">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#80a7d8",
        }}
      />
      <path
        d="m1205.91 177.88-35.53-24.34-35.56 24.33 13.62-39.33-35.56-24.28 43.94.04 13.56-39.36 13.54 39.36 43.96-.03-35.59 24.29zM1095.61 491.55l-35.53-24.35-35.56 24.35 13.62-39.33-35.56-24.29 43.94.03 13.56-39.34 13.53 39.34 43.97-.03-35.59 24.29zM1205.91 420.54l-35.53-24.34-35.56 24.34 13.62-39.33-35.56-24.28 43.94.03 13.56-39.34 13.54 39.34 43.96-.03-35.59 24.28zM731.61 648.04l-35.53-24.34-35.56 24.34 13.62-39.33-35.56-24.28 43.94.03 13.56-39.36 13.53 39.36 43.97-.03-35.59 24.28zM787.4 398.95l35.53 24.35 35.53-24.34-13.62 39.33 35.59 24.29-43.97-.04-13.53 39.35-13.56-39.35-43.94.02 35.59-24.27zM917 219.72l35.53 24.34 35.53-24.33-13.62 39.33 35.59 24.29-43.96-.05-13.54 39.34-13.56-39.34-43.94.03 35.59-24.28zM1025.91 182.49l35.56 24.35 35.53-24.35-13.62 39.33 35.56 24.28-43.93-.02-13.57 39.34-13.53-39.34-43.97.02 35.59-24.28zM917 492.72l35.53 24.34 35.53-24.33-13.62 39.33 35.59 24.29-43.96-.05-13.54 39.34-13.56-39.35-43.94.04 35.59-24.28zM787.4 524.44l35.53 24.33 35.53-24.33-13.62 39.33 35.59 24.28-43.97-.03-13.53 39.33-13.56-39.34-43.94.03 35.59-24.27z"
        className="Tuvalu_svg__cls-2"
      />
      <path
        d="M0 0h617.15v308.57H0z"
        style={{
          fill: "#1b257d",
        }}
      />
      <path
        d="M548.14 0 0 274.07v34.5h69L617.15 34.49V0z"
        className="Tuvalu_svg__cls-4"
      />
      <path
        d="M0 0v34.49l548.14 274.08h69.01v-34.5L69 0z"
        className="Tuvalu_svg__cls-4"
      />
      <path
        d="M257.15 0v102.85H0v102.86h257.15v102.86H360V205.71h257.15V102.85H360V0z"
        className="Tuvalu_svg__cls-4"
      />
      <path
        d="M277.72 0v123.43H0v61.71h277.72v123.43h61.7V185.14h277.73v-61.71H339.42V0z"
        className="Tuvalu_svg__cls-5"
      />
      <path
        d="M365.42 102.85h46.02L617.15 0h-46z"
        className="Tuvalu_svg__cls-6"
      />
      <path d="m0 23 159.71 79.85h46L0 0z" className="Tuvalu_svg__cls-5" />
      <path
        d="M617.15 308.57v-22.99l-159.72-79.87h-45.99z"
        className="Tuvalu_svg__cls-6"
      />
      <path
        d="M205.71 205.71 0 308.57h45.99L251.7 205.71z"
        className="Tuvalu_svg__cls-5"
      />
    </g>
  </svg>
);
export default SvgTuvalu;
