import * as React from "react";
import type { SVGProps } from "react";
const SvgBotswana = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#75aadb",
      }}
    />
    <path
      d="M0 270h1280v180H0z"
      style={{
        fill: "#fff",
      }}
    />
    <path d="M0 300h1280v120H0z" />
  </svg>
);
export default SvgBotswana;
