import * as React from "react";
import type { SVGProps } from "react";

const SvgMacau = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 768" {...props}>
    {/* Green Background */}
    <rect width="1024" height="768" fill="#009e49" />
    
    {/* Lotus Flower */}
    <g fill="#fff">
      <path d="M512 281c-19.2 0-37.7 7.8-51.5 21.8-13.8 13.9-21.5 33.1-21.5 53.4s7.7 39.6 21.5 53.4c13.8 13.9 32.3 21.8 51.5 21.8s37.7-7.8 51.5-21.8c13.8-13.9 21.5-33.1 21.5-53.4s-7.7-39.6-21.5-53.4c-13.8-13.9-32.3-21.8-51.5-21.8zM512 404c-15.4 0-30.5-6.1-41.8-17.2-11.3-11.1-17.8-26.2-17.8-41.6s6.5-30.5 17.8-41.6c11.3-11.1 26.4-17.2 41.8-17.2s30.5 6.1 41.8 17.2c11.3 11.1 17.8 26.2 17.8 41.6s-6.5 30.5-17.8 41.6c-11.3 11.1-26.4 17.2-41.8 17.2z"/>
      <path d="M512 313c-12.6 0-24.6 4.9-33.5 13.8-8.9 8.9-13.8 20.9-13.8 33.5s4.9 24.6 13.8 33.5c8.9 8.9 20.9 13.8 33.5 13.8s24.6-4.9 33.5-13.8c8.9-8.9 13.8-20.9 13.8-33.5s-4.9-24.6-13.8-33.5c-8.9-8.9-20.9-13.8-33.5-13.8z"/>
    </g>

    {/* Stars */}
    <g fill="#fff">
      <path d="M512 64l-25.5 51.7-56.6 8.3 41 40.1-9.7 56.6 50.8-26.7 50.8 26.7-9.7-56.6 41-40.1-56.6-8.3z"/>
      <path d="M712 128l-25.5 51.7-56.6 8.3 41 40.1-9.7 56.6 50.8-26.7 50.8 26.7-9.7-56.6 41-40.1-56.6-8.3z"/>
      <path d="M314 128l-25.5 51.7-56.6 8.3 41 40.1-9.7 56.6 50.8-26.7 50.8 26.7-9.7-56.6 41-40.1-56.6-8.3z"/>
    </g>
  </svg>
);

export default SvgMacau;