import * as React from "react";
import type { SVGProps } from "react";
const SvgOman = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Oman_svg__cls-20{fill:#db161b}.Oman_svg__cls-10,.Oman_svg__cls-11,.Oman_svg__cls-16,.Oman_svg__cls-17,.Oman_svg__cls-18,.Oman_svg__cls-19,.Oman_svg__cls-22,.Oman_svg__cls-23,.Oman_svg__cls-24,.Oman_svg__cls-27,.Oman_svg__cls-29,.Oman_svg__cls-31,.Oman_svg__cls-32,.Oman_svg__cls-4,.Oman_svg__cls-5,.Oman_svg__cls-6,.Oman_svg__cls-7,.Oman_svg__cls-8,.Oman_svg__cls-9{fill:#fff}.Oman_svg__cls-10,.Oman_svg__cls-11,.Oman_svg__cls-12,.Oman_svg__cls-13,.Oman_svg__cls-14,.Oman_svg__cls-15,.Oman_svg__cls-16,.Oman_svg__cls-17,.Oman_svg__cls-18,.Oman_svg__cls-19,.Oman_svg__cls-20,.Oman_svg__cls-22,.Oman_svg__cls-23,.Oman_svg__cls-24,.Oman_svg__cls-27,.Oman_svg__cls-29,.Oman_svg__cls-31,.Oman_svg__cls-32,.Oman_svg__cls-4,.Oman_svg__cls-5,.Oman_svg__cls-6,.Oman_svg__cls-7,.Oman_svg__cls-8,.Oman_svg__cls-9{stroke:#db161b}.Oman_svg__cls-4{stroke-width:.28px}.Oman_svg__cls-5{stroke-width:.26px}.Oman_svg__cls-6{stroke-width:.17px}.Oman_svg__cls-8{stroke-width:.26px}.Oman_svg__cls-9{stroke-width:.42px}.Oman_svg__cls-10,.Oman_svg__cls-11,.Oman_svg__cls-15{stroke-width:.33px}.Oman_svg__cls-12,.Oman_svg__cls-13,.Oman_svg__cls-14,.Oman_svg__cls-15{fill:none}.Oman_svg__cls-12,.Oman_svg__cls-13,.Oman_svg__cls-14{stroke-width:.33px}.Oman_svg__cls-16{stroke-width:.34px}.Oman_svg__cls-17{stroke-width:.29px}.Oman_svg__cls-18,.Oman_svg__cls-19{stroke-width:.33px}.Oman_svg__cls-22{stroke-width:.26px}.Oman_svg__cls-23,.Oman_svg__cls-24{stroke-width:4.46px}.Oman_svg__cls-27,.Oman_svg__cls-29{stroke-width:.27px}.Oman_svg__cls-31,.Oman_svg__cls-32{stroke-width:.33px}"
        }
      </style>
    </defs>
    <g id="Oman_svg__Oman">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#db161b",
        }}
      />
      <path
        d="M320 0h960v240H320z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M320 480h960v240H320z"
        style={{
          fill: "green",
        }}
      />
      <g id="Oman_svg__a">
        <path
          d="M73.67 109.48h34.39a3.33 3.33 0 0 1 3.43 3.22v16.2a3.32 3.32 0 0 1-3.43 3.21H73.67a3.33 3.33 0 0 1-3.43-3.21v-16.2a3.33 3.33 0 0 1 3.43-3.22Z"
          className="Oman_svg__cls-4"
        />
        <path
          d="M74.56 110.42h32.62a3.11 3.11 0 0 1 3.25 3v14.85a3.11 3.11 0 0 1-3.25 2.95H74.56a3.12 3.12 0 0 1-3.26-2.95v-14.9a3.12 3.12 0 0 1 3.26-2.95Z"
          className="Oman_svg__cls-5"
        />
        <path
          d="m89.5 116.09 2 .06.09.9 1.62.82 1.26-1 1.53.82-1.44.9.36 1.24 1.79-.07v1.66l-1.43-.08-.72 1 1.62 1.17-1.26 1-1.35-1-2 .41.09 1.52-2.15.13-.27-1.45-1.8-.75-1 1-1.52-.76.89-1.17-1.07-.76h-1.53l-.09-2.13 1.53.14 1.07-1.24-1.25-1 1.61-1.11 1.17.9 2-.27Z"
          className="Oman_svg__cls-6"
        />
        <ellipse
          cx={90.1}
          cy={120.55}
          className="Oman_svg__cls-7"
          rx={2.17}
          ry={1.86}
        />
        <path
          d="m76.59 111.37-3.17 2.83M78.71 111.37l-3.18 2.83M80.82 111.37l-3.17 2.83M82.94 111.37l-3.18 2.83M85.05 111.37l-3.17 2.83M87.17 111.37 84 114.2M89.28 111.37l-3.17 2.83M91.4 111.37l-3.18 2.83M93.51 111.37l-3.17 2.83M95.63 111.37l-3.18 2.83M97.74 111.37l-3.17 2.83M99.86 111.37l-3.18 2.83M91.4 111.37l-3.18 2.83M102 111.37l-3.2 2.83M104.09 111.37l-3.17 2.83M106.2 111.37l-3.2 2.83M108.32 111.37l-3.17 2.83M105.15 111.37l3.17 2.83M103 111.37l3.17 2.83M100.92 111.37l3.17 2.83M98.8 111.37l3.2 2.83M96.68 111.37l3.18 2.83M94.57 111.37l3.17 2.83M92.45 111.37l3.18 2.83M90.34 111.37l3.17 2.83M88.22 111.37l3.18 2.83M86.11 111.37l3.17 2.83M84 111.37l3.18 2.83M81.88 111.37l3.17 2.83M90.34 111.37l3.17 2.83M79.76 111.37l3.18 2.83M77.65 111.37l3.17 2.83M75.53 111.37l3.18 2.83M73.42 111.37l3.17 2.83M76.59 127.39l-3.17 2.84M78.71 127.39l-3.18 2.84M80.82 127.39l-3.17 2.84M82.94 127.39l-3.18 2.84M85.05 127.39l-3.17 2.84M87.17 127.39 84 130.23M89.28 127.39l-3.17 2.84M91.4 127.39l-3.18 2.84M93.51 127.39l-3.17 2.84M95.63 127.39l-3.18 2.84M97.74 127.39l-3.17 2.84M99.86 127.39l-3.18 2.84M91.4 127.39l-3.18 2.84M102 127.39l-3.17 2.84M104.09 127.39l-3.17 2.84M106.2 127.39l-3.2 2.84M108.32 127.39l-3.17 2.84M105.15 127.39l3.17 2.84M103 127.39l3.17 2.84M100.92 127.39l3.17 2.84M98.8 127.39l3.17 2.84M96.68 127.39l3.18 2.84M94.57 127.39l3.17 2.84M92.45 127.39l3.18 2.84M90.34 127.39l3.17 2.84M88.22 127.39l3.18 2.84M86.11 127.39l3.17 2.84M84 127.39l3.18 2.84M81.88 127.39l3.17 2.84M90.34 127.39l3.17 2.84M79.76 127.39l3.18 2.84M77.65 127.39l3.17 2.84M75.53 127.39l3.18 2.84M73.42 127.39l3.17 2.84"
          className="Oman_svg__cls-8"
        />
      </g>
      <g id="Oman_svg__a-2" data-name="a">
        <path
          d="M246.33 109.48h-34.39a3.33 3.33 0 0 0-3.43 3.22v16.2a3.32 3.32 0 0 0 3.43 3.21h34.39a3.33 3.33 0 0 0 3.43-3.21v-16.2a3.33 3.33 0 0 0-3.43-3.22Z"
          className="Oman_svg__cls-4"
        />
        <path
          d="M245.44 110.42h-32.62a3.11 3.11 0 0 0-3.25 3v14.85a3.11 3.11 0 0 0 3.25 2.95h32.62a3.12 3.12 0 0 0 3.26-2.95v-14.9a3.12 3.12 0 0 0-3.26-2.95Z"
          className="Oman_svg__cls-5"
        />
        <path
          d="m230.5 116.09-2 .06-.09.9-1.62.82-1.26-1-1.53.82 1.44.9-.36 1.24-1.79-.07v1.66l1.43-.08.72 1-1.62 1.17 1.26 1 1.35-1 2 .41-.09 1.52 2.15.13.27-1.45 1.8-.75 1 1 1.52-.76-.89-1.17 1.07-.76h1.53l.09-2.13-1.53.14-1.07-1.24 1.25-1-1.61-1.11-1.17.9-2-.27Z"
          className="Oman_svg__cls-6"
        />
        <ellipse
          cx={229.9}
          cy={120.55}
          className="Oman_svg__cls-7"
          rx={2.17}
          ry={1.86}
        />
        <path
          d="m243.41 111.37 3.17 2.83M241.29 111.37l3.18 2.83M239.18 111.37l3.17 2.83M237.06 111.37l3.18 2.83M235 111.37l3.17 2.83M232.83 111.37l3.17 2.83M230.72 111.37l3.17 2.83M228.6 111.37l3.18 2.83M226.49 111.37l3.17 2.83M224.37 111.37l3.18 2.83M222.26 111.37l3.17 2.83M220.14 111.37l3.18 2.83M228.6 111.37l3.18 2.83M218 111.37l3.17 2.83M215.91 111.37l3.17 2.83M213.8 111.37l3.2 2.83M211.68 111.37l3.17 2.83M214.85 111.37l-3.17 2.83M217 111.37l-3.17 2.83M219.08 111.37l-3.17 2.83M221.2 111.37l-3.2 2.83M223.32 111.37l-3.18 2.83M225.43 111.37l-3.17 2.83M227.55 111.37l-3.18 2.83M229.66 111.37l-3.17 2.83M231.78 111.37l-3.18 2.83M233.89 111.37l-3.17 2.83M236 111.37l-3.18 2.83M238.12 111.37 235 114.2M229.66 111.37l-3.17 2.83M240.24 111.37l-3.18 2.83M242.35 111.37l-3.17 2.83M244.47 111.37l-3.18 2.83M246.58 111.37l-3.17 2.83M243.41 127.39l3.17 2.84M241.29 127.39l3.18 2.84M239.18 127.39l3.17 2.84M237.06 127.39l3.18 2.84M235 127.39l3.17 2.84M232.83 127.39l3.18 2.84M230.72 127.39l3.17 2.84M228.6 127.39l3.18 2.84M226.49 127.39l3.17 2.84M224.37 127.39l3.18 2.84M222.26 127.39l3.17 2.84M220.14 127.39l3.18 2.84M228.6 127.39l3.18 2.84M218 127.39l3.17 2.84M215.91 127.39l3.17 2.84M213.8 127.39l3.17 2.84M211.68 127.39l3.17 2.84M214.85 127.39l-3.17 2.84M217 127.39l-3.17 2.84M219.08 127.39l-3.17 2.84M221.2 127.39l-3.2 2.84M223.32 127.39l-3.18 2.84M225.43 127.39l-3.17 2.84M227.55 127.39l-3.18 2.84M229.66 127.39l-3.17 2.84M231.78 127.39l-3.18 2.84M233.89 127.39l-3.17 2.84M236 127.39l-3.18 2.84M238.12 127.39l-3.12 2.84M229.66 127.39l-3.17 2.84M240.24 127.39l-3.18 2.84M242.35 127.39l-3.17 2.84M244.47 127.39l-3.18 2.84M246.58 127.39l-3.17 2.84"
          className="Oman_svg__cls-8"
        />
      </g>
      <g id="Oman_svg__b">
        <path
          d="M134.94 156.93C83.62 207.11 61.73 213.46 72 217.5s43.33-21.85 77.72-56.26 55.44-65 45.17-69.07-5.36 11.38-59.95 64.76Z"
          className="Oman_svg__cls-9"
        />
        <path
          d="m91.74 193.4 18 4.71c-16.92 16-36 22.64-41.25 19.81s9.51-9.43 23.25-24.52Z"
          className="Oman_svg__cls-10"
        />
        <path
          d="m91.74 195.29 14.81 3.77c-16.48 15.41-32.79 19.8-37 17.92s8.8-7.19 22.19-21.69Z"
          className="Oman_svg__cls-11"
        />
        <path
          d="M147 154.73c0 .25-.16 1.32-.27 1.75a9.3 9.3 0 0 1-.13 1.51c-.17.41-.14.78-.66.93a2 2 0 0 1-1 .82 6 6 0 0 1-1.32.7 2.7 2.7 0 0 1-1.57.11c-.47-.28-.8-.57-1.18-.81-.1-.37-.17-.91-.27-1.28a5.4 5.4 0 0 1 .27 1.51 2.7 2.7 0 0 1-.52 1.28M142.11 162.53l-.13-.12c.31.28.19.15-.53-.22a5.8 5.8 0 0 0-1.71-.47h-1.83a6 6 0 0 0-1.71.23c-.37.33-.93.51-1.31.81a2.15 2.15 0 0 0-.79.93c-.36.32-.6.81-.93 1.06-.14.53-.44.51-.65.92a2.12 2.12 0 0 1 1.44.46c.56.26-.46-.24-.65-.46a3.3 3.3 0 0 0-1.18-.23c-.6-.17-1 .14-1.58.23a1.46 1.46 0 0 1-.92.24M135 168.35a5.6 5.6 0 0 1-2 0 1.93 1.93 0 0 1-1.19-.82c-.16-.31 0 .57 0 .94-.05.68-.29.69-.65 1.16-.38.22-.79.52-1.18.7a4.4 4.4 0 0 1-1.19.58 5.2 5.2 0 0 0-1.18.58c-.44.28-.4.62-.65 1a1.38 1.38 0 0 0 .52 1.18 3.1 3.1 0 0 0 .79 1c.32.54.68.78-.14.93-.45.17-.69 0-1.17 0M126.74 176.49c-.32-.06-1.47-.4-2-.58h-1.84a4 4 0 0 0-1.45.46 3.5 3.5 0 0 1-1.18.82 9 9 0 0 0-1 1.28A4.2 4.2 0 0 0 119 180a13 13 0 0 0 .26 1.4 3.46 3.46 0 0 0 .79 1.38.31.31 0 0 0 .26.24"
          className="Oman_svg__cls-12"
        />
        <path
          d="M120.48 184.53s-.18.3-.37.56a1.12 1.12 0 0 1-1.12.46c-.58 0-.53-.23-.93-.38a9 9 0 0 1-.65-.91 8 8 0 0 0-.47-.83c-.17-.27-.39-.48-.57-.73a1.2 1.2 0 0 0-.46-.18c.54.08.58.26.93.54a9 9 0 0 1 .47.83 7 7 0 0 1 .18 1.2 3 3 0 0 1-.18 1.28 4 4 0 0 1-.65.65 5 5 0 0 0-.66.72l-.83.56a2.5 2.5 0 0 1-1 .37 2.65 2.65 0 0 1-1.12-.37c-.35-.26-.63-.42-.93-.64a2.9 2.9 0 0 1-.66-.74 1.12 1.12 0 0 0-1.12.18 2.6 2.6 0 0 0-.65.64c-.16.08-.4.3-.56.38"
          className="Oman_svg__cls-13"
        />
        <path
          d="M110.21 188.33c.15.06.68.44.93.58a6.7 6.7 0 0 1 .45 1.26 6.5 6.5 0 0 1 .12 1.61 8 8 0 0 1-.57 1.48 10 10 0 0 1-1 .69 9.5 9.5 0 0 1-1.5.12 2.3 2.3 0 0 1-1.4 0 4.3 4.3 0 0 1-1.27-.46c-.43-.24-.7-.47-1.16-.69-.23-.33-.59-.63-.93-1a5 5 0 0 1-.7-1c.17.49.43.92.58 1.37a2.5 2.5 0 0 1 .47 1.26 5.3 5.3 0 0 1 .11 1.6 5.9 5.9 0 0 1-.23 1.38v.35"
          className="Oman_svg__cls-14"
        />
        <path
          d="M93.06 198.65v.17c0-.45 0-.23.18.66a2.07 2.07 0 0 0 1.87.33c.6-.44.58-.78 1.68-1a4 4 0 0 1 2 .66 2.74 2.74 0 0 1 1.49 1.16c.24.57.36 1-.37 1.16a4.7 4.7 0 0 1-2 .67c-.86 0-1.48-.09-1.87.34-.59.54-.56.87-.56 1.82a10.6 10.6 0 0 0-.37 1.5 3.5 3.5 0 0 1-1.68.33c-.2-.51-.42-1.44-.56-2a4.5 4.5 0 0 0-.56-1.84 5 5 0 0 0-1.3-1.16 2.37 2.37 0 0 0-1.12-.66c.73.33.83.69.93 1.49a3.4 3.4 0 0 1-1.87.51 1.82 1.82 0 0 1-1.68-.84 9 9 0 0 1-.55 1.84 17 17 0 0 0-.94 1.65c.17.81.52.84.56 1.84.24.65.22 1.27.75 1.66.23.26.41.31.93.33a4.5 4.5 0 0 1-2.42-.5c-.56-.3-1-.68-1.5-1a7.7 7.7 0 0 0-2.24-1c-.86 0-1.48-.08-1.86.34a2.92 2.92 0 0 0-1.12 1.66c-.17.86-.24 1.44.19 2a1.47 1.47 0 0 1 0 1.67c-.44.54-1.08.61-2.06.83a7 7 0 0 1-1.86-.33"
          className="Oman_svg__cls-15"
        />
        <path
          d="M193.5 111.64c22.78-39.32 37.89-72.77 33.73-74.64s-26 28.44-48.77 67.75Z"
          className="Oman_svg__cls-16"
        />
        <path
          d="M192 110.94C214 73 229.1 41 225.78 39.47s-23.84 28-45.81 65.93"
          className="Oman_svg__cls-17"
        />
        <path
          d="M188.22 91.16c-2.67 4.43-5.5 9.5-8.25 14.24l12 5.54c2.74-4.74 5.74-9.73 8.25-14.24Z"
          className="Oman_svg__cls-18"
        />
        <path
          d="M189.24 92.69c-2.67 4.43-4.54 7.83-7.28 12.57l9 4.15c2.75-4.74 4.77-8.06 7.28-12.57Z"
          className="Oman_svg__cls-18"
        />
        <path
          d="m195.78 97.82-6.01-2.76-5.34 9.21 6.01 2.77z"
          className="Oman_svg__cls-19"
        />
        <path
          d="m195.79 97.83-11.35 6.44M190.44 107l-.67-12M223 50.91l-6-2.77"
          className="Oman_svg__cls-18"
        />
        <ellipse
          cx={222.59}
          cy={45.08}
          className="Oman_svg__cls-20"
          rx={1.75}
          ry={1.54}
          transform="rotate(-13.21 222.68 45.083)"
        />
      </g>
      <g id="Oman_svg__b-2" data-name="b">
        <path
          d="M185.06 156.93c51.32 50.18 73.21 56.53 62.94 60.57s-43.33-21.85-77.72-56.26-55.44-65-45.17-69.07 5.36 11.38 59.95 64.76Z"
          className="Oman_svg__cls-9"
        />
        <path
          d="m228.26 193.4-18 4.71c16.92 16 36 22.64 41.25 19.81s-9.51-9.43-23.25-24.52Z"
          className="Oman_svg__cls-10"
        />
        <path
          d="m228.26 195.29-14.81 3.77c16.48 15.41 32.79 19.8 37 17.92s-8.8-7.19-22.19-21.69Z"
          className="Oman_svg__cls-11"
        />
        <path
          d="M173 154.73c0 .25.16 1.32.27 1.75a9.3 9.3 0 0 0 .13 1.51c.17.41.14.78.66.93a2 2 0 0 0 1 .82 6 6 0 0 0 1.32.7 2.7 2.7 0 0 0 1.57.11c.47-.28.8-.57 1.18-.81.1-.37.17-.91.27-1.28a5.4 5.4 0 0 0-.27 1.51 2.7 2.7 0 0 0 .52 1.28M177.89 162.53l.13-.12c-.31.28-.19.15.53-.22a5.8 5.8 0 0 1 1.71-.47h1.83a6 6 0 0 1 1.71.23c.37.33.93.51 1.31.81a2.15 2.15 0 0 1 .79.93c.36.32.6.81.93 1.06.14.53.44.51.65.92a2.12 2.12 0 0 0-1.44.46c-.56.26.46-.24.65-.46a3.3 3.3 0 0 1 1.18-.23c.6-.17 1 .14 1.58.23a1.46 1.46 0 0 0 .92.24M185 168.35a5.6 5.6 0 0 0 2 0 1.93 1.93 0 0 0 1.19-.82c.16-.31 0 .57 0 .94.05.68.29.69.65 1.16.38.22.79.52 1.18.7a4.4 4.4 0 0 0 1.19.58 5.2 5.2 0 0 1 1.18.58c.44.28.4.62.65 1a1.38 1.38 0 0 1-.52 1.18 3.1 3.1 0 0 1-.79 1c-.32.54-.68.78.14.93.45.17.69 0 1.17 0M193.26 176.49c.32-.06 1.47-.4 2-.58h1.84a4 4 0 0 1 1.45.46 3.5 3.5 0 0 0 1.18.82 9 9 0 0 1 1.05 1.28A4.2 4.2 0 0 1 201 180a13 13 0 0 1-.26 1.4 3.46 3.46 0 0 1-.79 1.38.31.31 0 0 1-.26.24"
          className="Oman_svg__cls-12"
        />
        <path
          d="M199.52 184.53s.18.3.37.56a1.12 1.12 0 0 0 1.12.46c.58 0 .53-.23.93-.38a9 9 0 0 0 .65-.91 8 8 0 0 1 .47-.83c.17-.27.39-.48.57-.73a1.2 1.2 0 0 1 .46-.18c-.54.08-.58.26-.93.54a9 9 0 0 0-.47.83 7 7 0 0 0-.18 1.2 3 3 0 0 0 .18 1.28 4 4 0 0 0 .65.65 5 5 0 0 1 .66.72l.83.56a2.5 2.5 0 0 0 1 .37 2.65 2.65 0 0 0 1.12-.37c.35-.26.63-.42.93-.64a2.9 2.9 0 0 0 .66-.74 1.12 1.12 0 0 1 1.12.18 2.6 2.6 0 0 1 .65.64c.16.08.4.3.56.38"
          className="Oman_svg__cls-13"
        />
        <path
          d="M209.79 188.33c-.15.06-.68.44-.93.58a6.7 6.7 0 0 0-.45 1.26 6.5 6.5 0 0 0-.12 1.61 8 8 0 0 0 .57 1.48 10 10 0 0 0 1 .69 9.5 9.5 0 0 0 1.5.12 2.3 2.3 0 0 0 1.4 0 4.3 4.3 0 0 0 1.27-.46c.43-.24.7-.47 1.16-.69.23-.33.59-.63.93-1a5 5 0 0 0 .7-1c-.17.49-.43.92-.58 1.37a2.5 2.5 0 0 0-.47 1.26 5.3 5.3 0 0 0-.11 1.6 5.9 5.9 0 0 0 .23 1.38v.35"
          className="Oman_svg__cls-14"
        />
        <path
          d="M226.94 198.65v.17c0-.45 0-.23-.18.66a2.07 2.07 0 0 1-1.87.33c-.6-.44-.58-.78-1.68-1a4 4 0 0 0-2.05.66 2.74 2.74 0 0 0-1.49 1.16c-.24.57-.36 1 .37 1.16a4.7 4.7 0 0 0 2.05.67c.86 0 1.48-.09 1.87.34.59.54.56.87.56 1.82a10.6 10.6 0 0 1 .37 1.5 3.5 3.5 0 0 0 1.68.33c.2-.51.42-1.44.56-2a4.5 4.5 0 0 1 .56-1.84 5 5 0 0 1 1.3-1.16 2.37 2.37 0 0 1 1.12-.66c-.73.33-.83.69-.93 1.49a3.4 3.4 0 0 0 1.87.51 1.82 1.82 0 0 0 1.68-.84 9 9 0 0 0 .55 1.84 17 17 0 0 1 .94 1.65c-.17.81-.52.84-.56 1.84-.24.65-.22 1.27-.75 1.66-.23.26-.41.31-.93.33a4.5 4.5 0 0 0 2.42-.5c.56-.3 1-.68 1.5-1a7.7 7.7 0 0 1 2.24-1c.86 0 1.48-.08 1.86.34a2.92 2.92 0 0 1 1.12 1.66c.17.86.24 1.44-.19 2a1.47 1.47 0 0 0 0 1.67c.44.54 1.08.61 2.06.83a7 7 0 0 0 1.86-.33"
          className="Oman_svg__cls-15"
        />
        <path
          d="M126.5 111.64C103.72 72.32 88.61 38.87 92.77 37s26 28.44 48.77 67.75Z"
          className="Oman_svg__cls-16"
        />
        <path
          d="M128 110.94C106 73 90.9 41 94.22 39.47s23.84 28 45.81 65.93"
          className="Oman_svg__cls-17"
        />
        <path
          d="M131.78 91.16c2.67 4.43 5.5 9.5 8.25 14.24l-12 5.54c-2.74-4.74-5.74-9.73-8.25-14.24Z"
          className="Oman_svg__cls-18"
        />
        <path
          d="M130.76 92.69c2.67 4.43 4.54 7.83 7.28 12.57l-9 4.15c-2.75-4.74-4.77-8.06-7.28-12.57Z"
          className="Oman_svg__cls-18"
        />
        <path
          d="m124.22 97.82 6.01-2.76 5.34 9.21-6.01 2.77z"
          className="Oman_svg__cls-19"
        />
        <path
          d="m124.21 97.83 11.35 6.44M129.56 107l.67-12M97 50.91l6-2.77"
          className="Oman_svg__cls-18"
        />
        <ellipse
          cx={97.41}
          cy={45.08}
          className="Oman_svg__cls-20"
          rx={1.54}
          ry={1.75}
          transform="rotate(-76.79 97.408 45.081)"
        />
      </g>
      <path
        d="M144.28 90.62c0 33 .19 45.44-2.24 47.15s-66.51 0-66.51 9.43 52.89 18.86 68.75 18.86q31.72 0 31.73-28.29V90.62Z"
        style={{
          fill: "#fff",
          stroke: "#db161b",
          strokeWidth: ".31px",
        }}
      />
      <path
        d="M144.28 100.05v4.72a107.5 107.5 0 0 0 31.73 0v-4.72a107.5 107.5 0 0 1-31.73 0ZM144.28 90.62v4.72a107.5 107.5 0 0 0 31.73 0v-4.72a107.5 107.5 0 0 1-31.73 0Z"
        className="Oman_svg__cls-22"
      />
      <path
        d="M144.28 95.34v4.71a107.5 107.5 0 0 0 31.73 0v-4.71a107.5 107.5 0 0 1-31.73 0ZM144.28 104.77v4.71a107.5 107.5 0 0 0 31.73 0v-4.71a107.5 107.5 0 0 1-31.73 0Z"
        className="Oman_svg__cls-22"
      />
      <ellipse
        cx={145.72}
        cy={98.02}
        className="Oman_svg__cls-23"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={151.49}
        cy={98.53}
        className="Oman_svg__cls-23"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={157.26}
        cy={99.03}
        className="Oman_svg__cls-24"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={163.03}
        cy={99.03}
        className="Oman_svg__cls-24"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={168.79}
        cy={98.53}
        className="Oman_svg__cls-23"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={174.56}
        cy={98.02}
        className="Oman_svg__cls-23"
        rx={1.44}
        ry={1.02}
      />
      <path
        d="M144.28 114.2 176 139.66M147.45 114.2 176 136.82M151.68 115.14 176 134M154.86 115.14l21.15 16M158 115.14l18 13.2M161.19 115.14 176 125.51M164.37 115.14l11.63 7.54M167.54 115.14l8.46 5.66M170.72 115.14l5.29 3.77M173.89 115.14 176 117M144.28 117 176 142.49M176 114.2l-34.9 28.29M172.83 114.2l-30.66 24.51M168.61 115.14 144.28 134M165.43 115.14l-21.15 16M162.26 115.14l-18 13.2M159.08 115.14l-14.8 10.37M155.91 115.14l-11.63 7.54M152.74 115.14l-8.46 5.66M149.57 115.14l-5.29 3.77M146.39 115.14l-2.11 1.86M176 117l-34.9 28.29M176 119.85l-34.9 28.29M176 122.68 141.11 151M176 125.51l-34.9 28.29M176 128.34l-34.9 28.29M176 131.17l-34.9 28.29M176 134l-34.9 28.29M176 136.83l-34.9 28.29M176 139.66l-32.79 26.4M176 142.49l-29.62 23.57M174.94 146.26l-23.26 18.86M173.89 150l-19 15.09M171.78 154.74l-9.52 7.54M144.28 119.85l30.66 24.52M144.28 122.68l30.66 24.52M144.28 125.51l29.61 23.58M144.28 128.34l29.61 23.57M144.28 131.17l28.55 22.63M143.22 133.06l28.56 22.63M143.22 135.88l26.44 20.75M142.17 137.77l26.44 20.75M141.11 139.66l25.38 19.8M141.11 142.49l24.32 18.85M141.11 145.31l21.15 17"
        className="Oman_svg__cls-8"
      />
      <path
        d="M144.28 109.48v4.72a107.5 107.5 0 0 0 31.73 0v-4.72a107.5 107.5 0 0 1-31.73 0Z"
        className="Oman_svg__cls-22"
      />
      <path
        d="m141.11 148.14 19 15.09M141.11 151 158 164.17M141.11 153.8l14.8 11.32M141.11 156.63l10.57 8.49M141.11 159.46l8.46 6.6M141.11 162.28l5.28 3.78"
        className="Oman_svg__cls-8"
      />
      <ellipse
        cx={145.72}
        cy={112.16}
        className="Oman_svg__cls-23"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={151.49}
        cy={112.67}
        className="Oman_svg__cls-23"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={157.26}
        cy={113.18}
        className="Oman_svg__cls-24"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={163.03}
        cy={113.18}
        className="Oman_svg__cls-24"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={168.79}
        cy={112.67}
        className="Oman_svg__cls-23"
        rx={1.44}
        ry={1.02}
      />
      <ellipse
        cx={174.56}
        cy={112.16}
        className="Oman_svg__cls-23"
        rx={1.44}
        ry={1.02}
      />
      <path
        d="m98.8 158.52 38.07-19.81M96.68 157.57l36-18.86M95.63 155.69l32.79-17M94.57 153.8l28.56-15.09M92.45 151.91l26.44-13.2M93.51 149.09l18-9.43M92.45 147.2l13.75-7.54M92.45 144.37l9.52-4.71M102 159.46l36-18.86M105.15 160.41 139 142.49M108.32 161.34l31.72-17M111.49 162.28l26.44-14.14M115.72 162.28 139 150M118.89 163.23 139 152.85M122.07 164.17l15.86-8.48M126.29 164.17l12.7-6.6M130.53 165.12l8.46-4.71M134.76 165.12l5.28-2.84"
        className="Oman_svg__cls-8"
      />
      <path
        d="M142.17 137.77h-5.3a76.5 76.5 0 0 0 0 28.29h5.3a75.6 75.6 0 0 1 0-28.29Z"
        className="Oman_svg__cls-22"
      />
      <path
        d="m79.76 151 12.69-9.43M79.76 144.37l12.69 11.32"
        className="Oman_svg__cls-8"
      />
      <path
        d="m98.8 139.66-6.35.94a34.6 34.6 0 0 0 0 17l6.35 1.89a46.9 46.9 0 0 1 0-19.83Z"
        style={{
          fill: "#fff",
          stroke: "#db161b",
          strokeWidth: ".19px",
        }}
      />
      <path
        d="M176 92.51v-4.76c-4.75 0-7.93-2.79-7.93-5.66h-15.84c0 2.83-3.17 5.66-7.92 5.66v4.72Z"
        style={{
          fill: "#fff",
          stroke: "#db161b",
          strokeWidth: ".32px",
        }}
      />
      <path
        d="M153.33 76.48h13.58v5.66h-13.58zM154.46 70.82h11.32v5.66h-11.32zM154.46 65.16h11.32v5.66h-11.32zM155.59 57.62h9.06v7.54h-9.06z"
        className="Oman_svg__cls-27"
      />
      <path
        d="M155.21 51.02h9.84v6.6h-9.84z"
        style={{
          fill: "#fff",
          stroke: "#db161b",
          strokeWidth: ".28px",
        }}
      />
      <ellipse
        cx={160.67}
        cy={44.89}
        className="Oman_svg__cls-7"
        rx={13.11}
        ry={8.96}
      />
      <path
        d="M167.23 44.42c0 2.47 3.28 6.6 3.28 6.6a18.7 18.7 0 0 1-9.84 2.83c-3.61 0-8.13-.68-9.83-2.83 0 0 3.27-4.13 3.27-6.6s-3.27-5.66-3.27-5.66a18.5 18.5 0 0 1 19.67 0s-3.28 3.18-3.28 5.66Z"
        className="Oman_svg__cls-29"
      />
      <path
        d="M165.05 44.42c0 2.47 3.27 7.54 3.27 7.54-2.37 1.62-4 1.89-7.65 1.89s-5.94.27-7.65-1.89c0 0 3.28-5.07 3.28-7.54s-3.28-6.6-3.28-6.6c2.37-1.63 4-1.89 7.65-1.89s5.28.26 7.65 1.89c0 0-3.27 4.12-3.27 6.6Z"
        className="Oman_svg__cls-29"
      />
      <ellipse
        cx={147.56}
        cy={45.36}
        className="Oman_svg__cls-7"
        rx={3.28}
        ry={2.83}
      />
      <ellipse
        cx={173.78}
        cy={45.36}
        className="Oman_svg__cls-7"
        rx={3.28}
        ry={2.83}
      />
      <ellipse
        cx={160.67}
        cy={45.36}
        className="Oman_svg__cls-7"
        rx={3.28}
        ry={2.83}
      />
      <ellipse
        cx={160.67}
        cy={35.93}
        className="Oman_svg__cls-7"
        rx={3.28}
        ry={2.83}
      />
      <ellipse
        cx={151.07}
        cy={89.68}
        className="Oman_svg__cls-7"
        rx={3.4}
        ry={2.83}
      />
      <ellipse
        cx={169.18}
        cy={89.68}
        className="Oman_svg__cls-7"
        rx={3.4}
        ry={2.83}
      />
      <path
        d="M152.2 82.13H168l-7.91 8.49Z"
        style={{
          fill: "#fff",
          stroke: "#db161b",
          strokeWidth: ".27px",
        }}
      />
      <ellipse
        cx={160.12}
        cy={88.74}
        className="Oman_svg__cls-7"
        rx={4.53}
        ry={3.77}
      />
      <path
        d="M144 117h21.68a1.19 1.19 0 0 1 1.32 1v3.65a1.19 1.19 0 0 1-1.32 1H144a1.19 1.19 0 0 1-1.32-1V118a1.19 1.19 0 0 1 1.32-1Z"
        className="Oman_svg__cls-31"
      />
      <path
        d="M174.42 112.32a5.11 5.11 0 0 0-5.29 4.93v4.29a5.3 5.3 0 0 0 10.58 0v-4.29a5.11 5.11 0 0 0-5.29-4.93Zm0 1.89c-1.76 0-3.18 1.76-3.18 3.94v2.49c0 2.19 1.42 3.94 3.18 3.94s3.17-1.75 3.17-3.94v-2.49c0-2.15-1.41-3.94-3.17-3.94ZM146.92 113.27c-1.76 0-3.17 1.75-3.17 3.94v4.37c0 2.19 1.41 4 3.17 4s3.17-1.76 3.17-4v-4.37c0-2.21-1.41-3.94-3.17-3.94Zm0-1.89a5.11 5.11 0 0 0-5.29 4.93v6.18a5.3 5.3 0 0 0 10.58 0v-6.18a5.1 5.1 0 0 0-5.29-4.93Z"
        className="Oman_svg__cls-10"
      />
      <path
        d="M138.46 115.15c-1.76 0-3.17 1.76-3.17 3.94v1c0 2.18 1.41 3.94 3.17 3.94s3.17-1.76 3.17-3.94v-1c0-2.18-1.41-3.94-3.17-3.94Zm0-1.88a5.11 5.11 0 0 0-5.29 4.93v2.8a5.3 5.3 0 0 0 10.58 0v-2.8a5.11 5.11 0 0 0-5.29-4.93Z"
        className="Oman_svg__cls-10"
      />
      <path
        d="M125.77 112.54c-3.17 0-5.71 2-5.71 4.41v4.88c0 2.45 2.54 4.41 5.71 4.41s5.71-2 5.71-4.41V117c0-2.49-2.54-4.46-5.71-4.46Zm0-2.11c-5.27 0-9.51 2.47-9.51 5.52v6.9c0 3.05 4.24 5.5 9.51 5.5s9.52-2.45 9.52-5.5V116c0-3.1-4.29-5.57-9.52-5.57ZM194.52 113.48c-3.17 0-5.72 2-5.72 4.42v4.88c0 2.45 2.55 4.41 5.72 4.41s5.7-2 5.7-4.41v-4.88c0-2.44-2.55-4.42-5.7-4.42Zm0-2.1c-5.28 0-9.53 2.45-9.53 5.51v6.9c0 3 4.25 5.5 9.53 5.5s9.51-2.45 9.51-5.5v-6.9c-.03-3.06-4.24-5.51-9.51-5.51Z"
        className="Oman_svg__cls-32"
      />
      <path
        d="M177.22 117h11.32c.38 0 .68.45.68 1v3.65c0 .55-.3 1-.68 1h-11.32c-.38 0-.69-.45-.69-1V118c0-.52.31-1 .69-1ZM165.31 116.1h6.6c.22 0 .4.59.4 1.33v4.88c0 .73-.18 1.33-.4 1.33h-6.6c-.22 0-.4-.6-.4-1.33v-4.88c0-.74.18-1.33.4-1.33ZM130.41 116.1H137c.22 0 .4.59.4 1.33v4.88c0 .73-.18 1.33-.4 1.33h-6.6c-.22 0-.4-.6-.4-1.33v-4.88c0-.74.19-1.33.41-1.33ZM107.53 115.15h13.2c.45 0 .81.74.81 1.66v6.1c0 .92-.36 1.67-.81 1.67h-13.2c-.44 0-.8-.75-.8-1.67v-6.1c0-.92.36-1.66.8-1.66ZM199.49 116.1h12.25c.42 0 .75.74.75 1.66v6.1c0 .92-.33 1.67-.75 1.67h-12.25c-.41 0-.75-.75-.75-1.67v-6.1c0-.92.34-1.66.75-1.66Z"
        className="Oman_svg__cls-31"
      />
    </g>
  </svg>
);
export default SvgOman;
