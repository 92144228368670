import * as React from "react";
import type { SVGProps } from "react";
const SvgQatar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.43h1280v720.87H0z"
      style={{
        fill: "#8d1b3d",
      }}
    />
    <path
      d="M0-.43v720.86h396.67l146.51-40-146.51-40.1 146.51-40-146.51-40 146.51-40.1-146.51-40 146.51-40L396.67 400l146.51-40-146.51-40 146.51-40.1-146.51-40 146.51-40-146.51-40.1 146.51-40-146.51-40 146.51-40.1L396.67-.43Z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgQatar;
