import * as React from "react";
import type { SVGProps } from "react";
const SvgPeru = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#d91023",
      }}
    />
    <path
      d="M426.67 0h426.67v720H426.67z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgPeru;
