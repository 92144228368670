import * as React from "react";
import type { SVGProps } from "react";
const SvgGeorgia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <clipPath id="Georgia_svg__clip-path">
        <path
          d="M-121.59 514.82C84.75 514.82 252 347.54 252 141.21S84.75-232.41-121.59-232.41h783.15C455.22-232.41 288-65.13 288 141.21s167.22 373.61 373.56 373.61Z"
          className="Georgia_svg__cls-1"
        />
      </clipPath>
      <clipPath id="Georgia_svg__clip-path-2">
        <path
          d="M-103.63-250.37C-103.63-44 63.65 123.24 270 123.24S643.6-44 643.6-250.37v783.15c0-206.34-167.28-373.61-373.61-373.61s-373.62 167.27-373.62 373.61Z"
          className="Georgia_svg__cls-1"
        />
      </clipPath>
      <clipPath id="Georgia_svg__clip-path-3">
        <path
          d="M613.07 949.86c206.34 0 373.62-167.27 373.62-373.61S819.41 202.63 613.07 202.63h783.15c-206.34 0-373.61 167.28-373.61 373.62s167.27 373.61 373.61 373.61Z"
          className="Georgia_svg__cls-1"
        />
      </clipPath>
      <clipPath id="Georgia_svg__clip-path-4">
        <path
          d="M631 184.67c0 206.33 167.31 373.61 373.65 373.61S1378.26 391 1378.26 184.67v783.15c0-206.34-167.27-373.61-373.61-373.61S631 761.48 631 967.82Z"
          className="Georgia_svg__cls-1"
        />
      </clipPath>
      <clipPath id="Georgia_svg__clip-path-5">
        <path
          d="M613.07 514.82c206.34 0 373.62-167.28 373.62-373.61S819.41-232.41 613.07-232.41h783.15c-206.34 0-373.61 167.28-373.61 373.62s167.27 373.61 373.61 373.61Z"
          className="Georgia_svg__cls-1"
        />
      </clipPath>
      <clipPath id="Georgia_svg__clip-path-6">
        <path
          d="M631-250.37C631-44 798.31 123.24 1004.65 123.24S1378.26-44 1378.26-250.37v783.15c0-206.34-167.27-373.61-373.61-373.61S631 326.44 631 532.78Z"
          className="Georgia_svg__cls-1"
        />
      </clipPath>
      <clipPath id="Georgia_svg__clip-path-7">
        <path
          d="M-121.59 949.86C84.75 949.86 252 782.59 252 576.25S84.75 202.63-121.59 202.63h783.15C455.22 202.63 288 369.91 288 576.25s167.22 373.61 373.56 373.61Z"
          className="Georgia_svg__cls-1"
        />
      </clipPath>
      <clipPath id="Georgia_svg__clip-path-8">
        <path
          d="M-103.63 184.67C-103.63 391 63.65 558.28 270 558.28S643.6 391 643.6 184.67v783.15c0-206.34-167.28-373.61-373.61-373.61s-373.62 167.27-373.62 373.61Z"
          className="Georgia_svg__cls-1"
        />
      </clipPath>
      <style>
        {".Georgia_svg__cls-1{fill:none}.Georgia_svg__cls-3{fill:#ec2024}"}
      </style>
    </defs>
    <g id="Georgia_svg__Georgia">
      <path
        d="M.56.48h1280v718.48H.56z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M555.23-1.14v288H.56v144h554.67v288H725.9v-288h554.66v-144H725.9v-288Z"
        className="Georgia_svg__cls-3"
      />
      <g id="Georgia_svg__smallcross">
        <g
          style={{
            clipPath: "url(#Georgia_svg__clip-path)",
          }}
        >
          <path
            id="Georgia_svg__varm"
            d="M72.4 407c0-109.12 88.46-197.58 197.59-197.58S467.57 297.92 467.57 407v-531.63C467.57-15.51 379.11 73 270 73S72.4-15.51 72.4-124.63Z"
            className="Georgia_svg__cls-3"
          />
        </g>
        <g
          style={{
            clipPath: "url(#Georgia_svg__clip-path-2)",
          }}
        >
          <path
            id="Georgia_svg__varm-2"
            d="M4.15-56.38c109.12 0 197.58 88.46 197.58 197.59S113.27 338.79 4.15 338.79h531.67c-109.12 0-197.58-88.46-197.58-197.58S426.7-56.38 535.82-56.38Z"
            className="Georgia_svg__cls-3"
            data-name="varm"
          />
        </g>
      </g>
      <g id="Georgia_svg__smallcross-2" data-name="smallcross">
        <g
          style={{
            clipPath: "url(#Georgia_svg__clip-path-3)",
          }}
        >
          <path
            id="Georgia_svg__varm-3"
            d="M807.06 842.09c0-109.13 88.47-197.59 197.59-197.59s197.58 88.5 197.58 197.59V310.41c0 109.12-88.46 197.58-197.58 197.58s-197.59-88.46-197.59-197.58Z"
            className="Georgia_svg__cls-3"
            data-name="varm"
          />
        </g>
        <g
          style={{
            clipPath: "url(#Georgia_svg__clip-path-4)",
          }}
        >
          <path
            id="Georgia_svg__varm-4"
            d="M738.81 378.66c109.12 0 197.58 88.47 197.58 197.59s-88.46 197.58-197.58 197.58h531.68c-109.13 0-197.59-88.46-197.59-197.58s88.46-197.59 197.59-197.59Z"
            className="Georgia_svg__cls-3"
            data-name="varm"
          />
        </g>
      </g>
      <g id="Georgia_svg__smallcross-3" data-name="smallcross">
        <g
          style={{
            clipPath: "url(#Georgia_svg__clip-path-5)",
          }}
        >
          <path
            id="Georgia_svg__varm-5"
            d="M807.06 407c0-109.12 88.47-197.58 197.59-197.58s197.58 88.5 197.58 197.58v-531.63c0 109.12-88.46 197.63-197.58 197.63S807.06-15.51 807.06-124.63Z"
            className="Georgia_svg__cls-3"
            data-name="varm"
          />
        </g>
        <g
          style={{
            clipPath: "url(#Georgia_svg__clip-path-6)",
          }}
        >
          <path
            id="Georgia_svg__varm-6"
            d="M738.81-56.38c109.12 0 197.58 88.46 197.58 197.59s-88.46 197.58-197.58 197.58h531.68c-109.13 0-197.59-88.46-197.59-197.58s88.46-197.59 197.59-197.59Z"
            className="Georgia_svg__cls-3"
            data-name="varm"
          />
        </g>
      </g>
      <g id="Georgia_svg__smallcross-4" data-name="smallcross">
        <g
          style={{
            clipPath: "url(#Georgia_svg__clip-path-7)",
          }}
        >
          <path
            id="Georgia_svg__varm-7"
            d="M72.4 842.09C72.4 733 160.86 644.5 270 644.5S467.57 733 467.57 842.09V310.41C467.57 419.53 379.11 508 270 508S72.4 419.53 72.4 310.41Z"
            className="Georgia_svg__cls-3"
            data-name="varm"
          />
        </g>
        <g
          style={{
            clipPath: "url(#Georgia_svg__clip-path-8)",
          }}
        >
          <path
            id="Georgia_svg__varm-8"
            d="M4.15 378.66c109.12 0 197.58 88.47 197.58 197.59S113.27 773.83 4.15 773.83h531.67c-109.12 0-197.58-88.46-197.58-197.58s88.46-197.59 197.58-197.59Z"
            className="Georgia_svg__cls-3"
            data-name="varm"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgGeorgia;
