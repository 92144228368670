import * as React from "react";
import type { SVGProps } from "react";
const SvgCapeVerde = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Cape_Verde_svg__cls-4{fill:#f9d616}"}</style>
    </defs>
    <g id="Cape_Verde_svg__Cape_Verde" data-name="Cape Verde">
      <path
        d="M.34-.09h1279.32V720.1H.34z"
        style={{
          fill: "#003893",
        }}
      />
      <path
        d="M.34 360h1279.32v180.05H.34z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M.34 420.02h1279.32v60.02H.34z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        d="M252.81 494.13h24.91l7.7-23.7 7.69 23.7H318l-20.15 14.64 7.7 23.69-20.15-14.64-20.16 14.64 7.7-23.69ZM252.81 382.06h24.91l7.7-23.69 7.69 23.69H318l-20.13 14.64 7.7 23.69-20.15-14.64-20.16 14.64L273 396.7ZM318 291.64h24.91l7.7-23.7 7.69 23.7h24.92l-20.15 14.64 7.71 23.72-20.15-14.64L330.48 330l7.69-23.69ZM423.65 256.43h24.91l7.7-23.69 7.74 23.69h24.91l-20.15 14.64 7.7 23.69-20.15-14.64-20.15 14.64 7.69-23.69ZM528.48 291.64h24.91l7.69-23.7 7.7 23.7h24.91l-20.15 14.64 7.7 23.69-20.16-14.64L540.93 330l7.7-23.69ZM593.69 382.06h24.91l7.7-23.69 7.7 23.69h24.92l-20.17 14.64 7.7 23.69-20.15-14.64-20.15 14.64 7.69-23.69ZM596.89 494.13h24.91l7.7-23.7 7.69 23.7h24.92L642 508.77l7.7 23.69-20.2-14.64-20.15 14.64 7.65-23.69ZM531.68 583h24.91l7.69-23.69L572 583h24.91l-20.15 14.64 7.7 23.69-20.16-14.64-20.15 14.64 7.7-23.69ZM423.65 617.36h24.91l7.7-23.7 7.69 23.7h24.91L468.71 632l7.7 23.69-20.15-14.64-20.15 14.64L443.8 632ZM318 583h24.91l7.7-23.69 7.71 23.69h24.92l-20.15 14.64 7.69 23.69-20.15-14.64-20.15 14.64 7.69-23.69Z"
        className="Cape_Verde_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgCapeVerde;
