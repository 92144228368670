import * as React from "react";
import type { SVGProps } from "react";
const SvgBelize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1280 720"
    {...props}
  >
    <defs>
      <radialGradient
        id="Belize_svg__radial-gradient"
        cx={-135.32}
        cy={-4255.53}
        r={35.36}
        gradientTransform="matrix(.39 0 0 -.45 586.29 -1674.76)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ffef5d" />
        <stop offset={1} stopColor="#ea5700" />
      </radialGradient>
      <radialGradient
        xlinkHref="#Belize_svg__radial-gradient"
        id="Belize_svg__radial-gradient-2"
        cx={-129.42}
        cy={-6974.46}
        r={160.37}
        gradientTransform="matrix(.39 0 0 -.26 586.29 -1541.8)"
      />
      <radialGradient
        id="Belize_svg__radial-gradient-3"
        cx={399.96}
        cy={-4351.96}
        r={36.69}
        gradientTransform="matrix(.39 0 0 -.44 586.29 -1669.83)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#952d1a" />
        <stop offset={1} stopColor="#570a00" />
      </radialGradient>
      <radialGradient
        id="Belize_svg__radial-gradient-4"
        cx={393.74}
        cy={-6971.96}
        r={160.47}
        gradientTransform="matrix(.39 0 0 -.26 586.29 -1541.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#952c1a" />
        <stop offset={1} stopColor="#570a00" />
      </radialGradient>
      <style>
        {
          ".Belize_svg__cls-3{fill:#fff}.Belize_svg__cls-10,.Belize_svg__cls-14,.Belize_svg__cls-17,.Belize_svg__cls-21,.Belize_svg__cls-22,.Belize_svg__cls-23,.Belize_svg__cls-26,.Belize_svg__cls-28,.Belize_svg__cls-32,.Belize_svg__cls-45,.Belize_svg__cls-8{fill:none}.Belize_svg__cls-5{fill:#007f00}.Belize_svg__cls-6{fill:#005800}.Belize_svg__cls-20,.Belize_svg__cls-24,.Belize_svg__cls-7{fill:#289400}.Belize_svg__cls-8{stroke:#060}.Belize_svg__cls-10,.Belize_svg__cls-20,.Belize_svg__cls-21,.Belize_svg__cls-22,.Belize_svg__cls-8{stroke-width:.51px}.Belize_svg__cls-10,.Belize_svg__cls-14,.Belize_svg__cls-32,.Belize_svg__cls-45,.Belize_svg__cls-49{stroke:#000}.Belize_svg__cls-11{fill:#730000}.Belize_svg__cls-12{fill:#ff0018}.Belize_svg__cls-14,.Belize_svg__cls-26{stroke-width:.71px}.Belize_svg__cls-17,.Belize_svg__cls-28{stroke:#7e4b7e}.Belize_svg__cls-17{stroke-width:.92px}.Belize_svg__cls-18{fill:#7e4b7e}.Belize_svg__cls-20,.Belize_svg__cls-21{stroke:#030}.Belize_svg__cls-22,.Belize_svg__cls-24{stroke:#004b00}.Belize_svg__cls-24{stroke-width:1.3px}.Belize_svg__cls-25{fill:#69f}.Belize_svg__cls-26{stroke:#fff}.Belize_svg__cls-28{stroke-width:.81px}.Belize_svg__cls-32{stroke-width:.31px}.Belize_svg__cls-34{fill:#9b5f00}.Belize_svg__cls-35{fill:#ffa54b}.Belize_svg__cls-40{fill:#5ac800}.Belize_svg__cls-45{stroke-width:.51px}.Belize_svg__cls-49{stroke-width:.7px}"
        }
      </style>
    </defs>
    <g id="Belize_svg__Belize">
      <path
        id="Belize_svg__rect1544"
        d="M0 .79h1280V719.2H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        id="Belize_svg__rect1545"
        d="M0 52.11h1280v615.78H0z"
        style={{
          fill: "#003f87",
        }}
      />
      <path
        id="Belize_svg__path1543"
        d="M921.17 360c0 155.87-126.36 282.23-282.23 282.23S356.7 515.87 356.7 360 483.06 77.77 638.94 77.77 921.17 204.13 921.17 360"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path558"
        d="M872.34 358.62c0 129.25-104.69 234-233.84 234s-233.83-104.77-233.83-234 104.69-234 233.83-234 233.84 104.75 233.84 234Z"
        style={{
          fill: "none",
          stroke: "#552300",
          strokeWidth: "6.25px",
        }}
      />
      <g id="Belize_svg__g1450-2">
        <path
          id="Belize_svg__path560-5"
          d="M611.91 110.34c21.16-22.7 40.29 3.52 39.78 15.81s-21.85 39.39-41.16 17.57c20 5.13 26-17.24 36.1-17.46-10.12.22-14.26-21.93-34.72-15.92"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1448-5"
          d="M610.53 143.72c20 5.13 25-17 35.13-17.19-5.9 7.05-21.31 25.03-35.13 17.19"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1449-2"
          d="M611.91 110.34c21.16-22.7 40.29 3.52 39.78 15.81-17.18-29.94-22.55-25.32-39.78-15.81"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1551-1">
        <path
          id="Belize_svg__path1552-4"
          d="M391.69 288.32c-11.12-28.88 21-34.27 31.91-28.37s25.89 36.89-2.37 44.4c13.5-15.52-4.05-30.76.23-39.85-4.28 9.09-26.12 2.96-29.77 23.82"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1553-8"
          d="M421.23 304.35c13.5-15.52-4.24-29.78 0-38.88 3.77 8.38 13.2 30.06 0 38.88"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1554-6"
          d="M391.69 288.32c-11.12-28.88 21-34.27 31.91-28.37-34.6 2.05-32.86 8.83-31.91 28.37"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1555-4">
        <path
          id="Belize_svg__path1556-6"
          d="M866.36 259.77c29 11.36 11.27 38.55-.46 42.52s-44.91-6-31.41-31.71c2.43 20.36 25.61 17.79 29.48 27.07-3.87-9.28 15.43-21.12 2.39-37.88"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1557-8"
          d="M834.49 270.58c2.43 20.36 25 17 28.88 26.28-8.77-2.86-31.23-10.66-28.88-26.28"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1558-9"
          d="M866.36 259.77c29 11.36 11.27 38.55-.46 42.52 21.9-26.72 15.62-30.02.46-42.52"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1563-8">
        <path
          id="Belize_svg__path1564-3"
          d="M487.47 565.67c-31-3.08-21.44-34-11.26-41s44.85-6.43 38.94 22c-7.93-18.92-29.53-10.19-35.8-18.07 6.27 7.86-9.04 24.48 8.12 37.07"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1565-5"
          d="M515.15 546.65c-7.93-18.93-28.72-9.59-35-17.47 9.23.43 32.97 1.82 35 17.47"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1566-5"
          d="M487.47 565.67c-31-3.08-21.44-34-11.26-41-13.72 31.61-6.77 33.08 11.26 41"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1571-1">
        <path
          id="Belize_svg__path1572-5"
          d="M800.77 543.27c.44 30.91-31.63 25-39.79 15.76s-11.54-43.48 17.6-40.89c-18.05 10-6.84 30.27-14 37.35 7.16-7.08 25.54 6.13 36.19-12.22"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1573-5"
          d="M778.58 518.14c-18.05 10-6.33 29.41-13.5 36.5-.63-9.15-1.95-32.72 13.5-36.5"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1574-8"
          d="M800.77 543.27c.44 30.91-31.63 25-39.79 15.76 33.23 9.97 33.92 2.91 39.79-15.76"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1575-4">
        <path
          id="Belize_svg__path1576-0"
          d="M414.27 229.59c-2.83-30.8 29.6-27.35 38.46-18.75s14.86 42.48-14.4 42.12c17.23-11.32 4.49-30.71 11.1-38.31-6.61 7.6-26-4.17-35.16 14.94"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1577-5"
          d="M438.33 253c17.23-11.32 4-29.81 10.65-37.42 1.33 9.04 4.47 32.42-10.65 37.42"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1578-4"
          d="M414.27 229.59c-2.83-30.8 29.6-27.35 38.46-18.75-33.91-7.37-34.04-.32-38.46 18.75"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1579-7">
        <path
          id="Belize_svg__path1580-2"
          d="M447.49 518.46c-30.73-5.15-19.08-35.39-8.44-41.69s45.18-3.41 37.35 24.57c-6.63-19.42-28.76-12.15-34.49-20.43 5.73 8.29-10.69 23.84 5.58 37.55"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1581-1"
          d="M476.4 501.34c-6.63-19.42-28-11.49-33.73-19.77 9.18 1.04 32.77 4.01 33.73 19.77"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1582-4"
          d="M447.49 518.46c-30.73-5.15-19.08-35.39-8.44-41.69-15.84 30.65-9.05 32.58 8.44 41.69"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1583-9">
        <path
          id="Belize_svg__path1584-9"
          d="M690.26 111.61c27.9-13.76 36.4 17.49 31.53 28.8s-34.52 29.12-44.77 1.92c16.87 11.84 30.46-7 40-3.6-9.54-3.36-5.48-25.52-26.76-27.12"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1585-0"
          d="M677 142.33c16.87 11.84 29.46-7.05 39-3.69-8 4.51-28.86 15.89-39 3.69"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1586-3"
          d="M690.26 111.61c27.9-13.76 36.4 17.49 31.53 28.8-5.34-34.04-12.02-31.61-31.53-28.8"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1587-0">
        <path
          id="Belize_svg__path1588-3"
          d="M749.63 582c-13.83 27.68-39.63 7.89-42.63-4s9.74-43.91 34.48-28.4c-20.65.7-20 23.83-29.66 26.88 9.62-3.09 19.89 16.99 37.81 5.52"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1589-5"
          d="M741.45 549.56c-20.65.7-19.17 23.3-28.8 26.35 3.65-8.42 13.35-29.99 28.8-26.35"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1590-0"
          d="M749.63 582c-13.83 27.68-39.63 7.89-42.63-4 25 23.86 28.81 17.93 42.63 4"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1591-7">
        <path
          id="Belize_svg__path1592-6"
          d="M885.11 314.57c23.44 20.37-2.45 40.07-14.84 39.9s-40.27-20.6-18.83-40.36c-4.61 20 18.08 25.31 18.58 35.34-.5-10.03 21.7-14.74 15.09-34.88"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1593-7"
          d="M851.44 314.11c-4.61 20 17.78 24.36 18.28 34.39-7.26-5.67-25.79-20.5-18.28-34.39"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1594-8"
          d="M885.11 314.57c23.44 20.37-2.45 40.07-14.84 39.9 29.73-17.86 24.88-23.06 14.84-39.9"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1635-3">
        <path
          id="Belize_svg__path1636-6"
          d="M491.52 151.71c7-30.13 36.72-16.81 42.43-5.89s.76 45-26.92 35.55c19.92-5.42 13.92-27.78 22.59-33-8.62 5.22-23.35-11.96-38.1 3.34"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1637-4"
          d="M507 181.37c20-5.37 13.25-27.07 21.91-32.25-1.59 9.03-5.91 32.23-21.91 32.25"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1638-4"
          d="M491.52 151.71c7-30.13 36.72-16.81 42.43-5.89-29.89-17.51-32.24-10.82-42.43 5.89"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1639-1">
        <path
          id="Belize_svg__path1640-9"
          d="M399.37 409.9c-27.3-14.91-6.27-39.65 5.87-42.13s43.79 11.55 27.11 35.41c.19-20.5-23.13-20.87-25.79-30.56 2.66 9.69-18 19.02-7.19 37.28"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1641-5"
          d="M432.35 403.18c.19-20.5-22.63-20-25.29-29.69 8.33 3.98 29.62 14.51 25.29 29.69"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1642-5"
          d="M399.37 409.9c-27.3-14.91-6.27-39.65 5.87-42.13-25.13 23.77-19.32 27.83-5.87 42.13"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1643-7">
        <path
          id="Belize_svg__path1644-4"
          d="M793.83 171c30.83-4.43 29.08 27.9 20.89 37.13s-42 17-43.14-12c12.31 16.47 31.13 2.82 39.14 9-8-6.2 2.82-26-16.89-34.13"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1645-7"
          d="M771.59 196.11c12.3 16.47 30.21 2.43 38.21 8.57-9.06 1.8-32.44 6.15-38.21-8.57"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1646-3"
          d="M793.83 171c30.83-4.43 29.08 27.9 20.89 37.13 5.63-33.97-1.47-33.74-20.89-37.13"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1647-4">
        <path
          id="Belize_svg__path1648-0"
          d="M618.43 611.62c-28.45 12.62-35.65-19-30.31-30.06s35.7-27.69 44.81-.09c-16.36-12.52-30.73 5.71-40.12 2 9.39 3.71 4.42 25.69 25.62 28.15"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1649-8"
          d="M632.93 581.47c-16.36-12.52-29.73 5.84-39.12 2.09 8.23-4.17 29.52-14.69 39.12-2.09"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1650-8"
          d="M618.43 611.62c-28.45 12.62-35.65-19-30.31-30.06 3.93 34.23 10.7 32.08 30.31 30.06"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1651-1">
        <path
          id="Belize_svg__path1652-5"
          d="M873.67 439.15c12.84 28.17-18.94 35.46-30.15 30.22s-28.07-35.3-.31-44.47c-12.53 16.3 5.91 30.46 2.18 39.8 3.73-9.34 25.9-4.51 28.28-25.55"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1653-2"
          d="M843.21 424.9c-12.53 16.29 6 29.47 2.31 38.81-4.26-8.14-14.95-29.22-2.31-38.81"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1654-3"
          d="M873.67 439.15c12.84 28.17-18.94 35.46-30.15 30.22 34.48-4.07 32.27-10.78 30.15-30.22"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1655-2">
        <path
          id="Belize_svg__path1656-4"
          d="M387 348.41c-17.87-25.33 12-38.33 24-35.23s34.17 29.53 8.59 43.64c9.27-18.32-11.49-28.82-9.59-38.71-1.9 9.89-24.58 9.17-23 30.3"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1657-6"
          d="M419.58 356.82c9.28-18.32-11.41-27.86-9.49-37.72 5.69 7.22 20.13 25.98 9.49 37.72"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1658-6"
          d="M387 348.41c-17.87-25.33 12-38.33 24-35.23-33.12 10.31-29.71 16.5-24 35.23"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1659-6">
        <path
          id="Belize_svg__path1660-2"
          d="M555.06 592.89c-29.24 10.68-34.27-21.31-28.19-32s37.5-25.24 44.71 2.9c-15.47-13.58-31 3.65-40.16-.72 9.12 4.34 2.66 25.93 23.64 29.82"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1661-9"
          d="M571.58 563.77c-15.47-13.58-30.06 3.84-39.17-.53 8.49-3.62 30.45-12.68 39.17.53"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1662-0"
          d="M555.06 592.89c-29.24 10.68-34.27-21.31-28.19-32 1.59 34.38 8.49 32.7 28.19 32"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1663-4">
        <path
          id="Belize_svg__path1664-9"
          d="M546.63 122.84c17.33-25.7 40.35-2.77 41.78 9.44S573 174.58 550.52 156c20.57 2 23-21.06 32.91-22.85-10 1.78-17.53-19.45-36.8-10.34Z"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1665-3"
          d="M550.52 156c20.57 1.95 22-20.65 32-22.44-4.72 7.92-17.11 28.06-32 22.44"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1666-1"
          d="M546.63 122.84c17.33-25.7 40.35-2.77 41.78 9.44-21.68-26.9-26.26-21.51-41.78-9.44"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1667-1">
        <path
          id="Belize_svg__path1668-9"
          d="M846.22 500.26c1.87 30.86-30.44 26.42-39 17.55s-13.54-42.91 15.7-41.65c-17.58 10.78-5.45 30.55-12.29 37.95 6.83-7.4 25.8 4.96 35.59-13.85"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1669-9"
          d="M822.9 476.16c-17.58 10.79-5 29.67-11.81 37.07-1.09-9.11-3.46-32.59 11.81-37.07"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1670-5"
          d="M846.22 500.26c1.87 30.86-30.44 26.42-39 17.55 33.64 8.41 34 1.37 39-17.55"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1671-5">
        <path
          id="Belize_svg__path1672-5"
          d="M838.27 209.24c30.54 6.12 17.93 36 7.1 41.94s-45.27 2-36.54-25.74c6 19.62 28.36 13.05 33.81 21.51-5.45-8.46 11.45-23.49-4.37-37.71"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1673-6"
          d="M808.83 225.44c6 19.62 27.62 12.37 33.08 20.83-9.14-1.33-32.63-5.04-33.08-20.83"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1674-0"
          d="M838.27 209.24c30.54 6.12 17.93 36 7.1 41.94 16.82-30.13 10-32.28-7.1-41.94"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1675-5">
        <path
          id="Belize_svg__path1676-9"
          d="M451.49 187.49c.3-30.92 32.22-24.26 40.16-14.83s10.5 43.75-18.57 40.47c18.28-9.55 7.56-30.1 14.9-37-7.34 6.87-25.4-6.74-36.49 11.36"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1677-4"
          d="M473.08 213.13c18.28-9.55 7-29.25 14.37-36.17.4 9.17 1.16 32.76-14.37 36.17"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1678-6"
          d="M451.49 187.49c.3-30.92 32.22-24.26 40.16-14.83-32.99-10.66-33.84-3.66-40.16 14.83"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1679-3">
        <path
          id="Belize_svg__path1680-4"
          d="M412.86 466.19c-27.8-14-7.63-39.41 4.41-42.3s44.17 10.06 28.32 34.47c-.52-20.5-23.84-20.07-26.82-29.67 3 9.6-17.34 19.62-5.91 37.5"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1681-9"
          d="M445.59 458.36c-.52-20.5-23.31-19.23-26.3-28.82 8.46 3.7 30.1 13.46 26.3 28.82"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1682-0"
          d="M412.86 466.19c-27.8-14-7.63-39.41 4.41-42.3-24.27 24.6-18.35 28.46-4.41 42.3"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1683-5">
        <path
          id="Belize_svg__path1684-3"
          d="M746.69 137c29.15-10.92 34.45 21 28.46 31.79s-37.28 25.54-44.73-2.54c15.58 13.46 31-3.9 40.16.4-9.15-4.3-2.88-25.94-23.89-29.65"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1685-9"
          d="M730.42 166.26c15.58 13.46 30-4.08 39.17.21-8.46 3.69-30.34 12.93-39.17-.21"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1686-1"
          d="M746.69 137c29.15-10.92 34.45 21 28.46 31.79-1.88-34.39-8.76-32.64-28.46-31.79"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1687-2">
        <path
          id="Belize_svg__path1688-8"
          d="M684.83 608.16c-22.66 21.25-39.94-6.18-38.59-18.4s24.5-37.87 42.27-14.83c-19.61-6.43-27.11 15.5-37.22 15.05 10.11.45 12.72 22.82 33.54 18.18"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1689-8"
          d="M688.51 574.93c-19.61-6.43-26.12 15.29-36.23 14.84 6.37-6.65 22.98-23.57 36.23-14.84"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1690-1"
          d="M684.83 608.16c-22.66 21.25-39.94-6.18-38.59-18.4 15.08 31 20.76 26.75 38.59 18.4"
          className="Belize_svg__cls-6"
        />
      </g>
      <g id="Belize_svg__g1691-8">
        <path
          id="Belize_svg__path1692-6"
          d="M883.46 371.68c21.27 22.6-6.48 39.62-18.79 38.22s-38-24.51-14.66-42c-6.61 19.43 15.43 27 14.92 37 .51-10 23.07-12.51 18.53-33.2Z"
          className="Belize_svg__cls-5"
        />
        <path
          id="Belize_svg__path1693-0"
          d="M850 367.87c-6.61 19.43 15.23 26 14.72 36-6.65-6.32-23.59-22.87-14.72-36"
          className="Belize_svg__cls-6"
        />
        <path
          id="Belize_svg__path1694-2"
          d="M883.46 371.68c21.27 22.6-6.48 39.62-18.79 38.22 31.33-14.82 27.09-20.47 18.79-38.22"
          className="Belize_svg__cls-6"
        />
      </g>
      <path
        id="Belize_svg__path18"
        d="M484.79 454.87c2.19 1 0-7.17.73-10.24s7.16 7.9 10.23 9.36c3.22 1.6 22.81-7.75 22.81-7.75l4.67-8.77 2.49 7.16s5.41-6.28 7-8.63 0 4.68-1.61 7 4.68 3.21 3.94 7.89 6.29-3.21 6.29-3.21l18.13 1.6s3.07-11 7.89-17.25l1.46 7 7.46-7.6c8.33 21.93 25.87 45.76 61.83 58.77 33.77-12.28 51.16-34.07 60.22-54.83l10.09 10.38s7.89-2.33 9.36-3.21c1.61-.73 18.56 6 18.56 6a25 25 0 0 1 4.68 2.35c1.61 1.16.44-5.12.44-5.12s8.62 1.6 7.89 6.28c0 0 1.9-4.38 3.51-5.12s5.41 3.95 6.28 5.56 19.3 3.8 21.93 5.41c0 0 2 2.34 2 3.95 0 0 4-.44 7-.44a4.39 4.39 0 0 0-2.34 3.65 5.81 5.81 0 0 1-3.51 5.41c-2.34 1.17 0 2.05 3.07 2.34s0 4.39-6.29 6.73c0 0-1.46 3.5 1.17 4.68 0 0-5.41.44-6.28-1.17 0 0-1.9 3.66-1.9 5.56a76 76 0 0 1-7.46-2.79s-2.34 2.35-.73 3.51c0 0-10.67-1.17-12.72-5.12 0 0-3.79 1.17-3.06 2.49 0 0-7.46-5.12-9.8-5.12l-1.61 2.63s-5.11-3.07-6.72-3.07-.29 2.34-.29 2.34l-7.16-4.39-2.78 4.39a82 82 0 0 1-7.46-3.95c-1.46-1.17-1.89 2.34-3.07 2.78 0 0-2.33-3.22-5.55-2.78 0 0-1.17 3.95-.73 5.12 0 0-9.06-1.9-10.67-2.34s-.73 1.9 0 3.51c0 0-6.72-1.9-8.19-3.07l-.43 3.07s-5.56-.44-6.73-1.61l-3.51 4.83h-5.02l-1.17 4.23s-9.79-2.77-10.67-1.17.44 3.95.44 3.95-7.9 0-8.63-1.16l-.73 3.5s-9.94-3.07-12.28-1.61c0 0-3.07 3.22-5.11 3.95-1.9.88 0-1.17 0-1.17l-3.07-4.24s-3.51 1.9-5.56 1.17c-1.9-.87-8.18 3.07-10.23 1.9 0 0 0-2.78-1.61-3.51-1.46-.73-4.24-.73-5.41 1.17 0 0-1.16-1.61-3.21-4.67-1.9-3.22-4.68.29-4.68.29l-.44-3.8a42 42 0 0 1-5.41-.44 4.71 4.71 0 0 1-3.95-3.21s-8.32.87-9.49.43l2-1.9s-11.4 2.34-13.74 1.47 2.64-3.95 2.64-3.95-10.53-2.63-12.58-3.07-13.3 1.89-14.91 1.89-2.34-2.63-2.34-2.63a18.7 18.7 0 0 1-5.11 3.08s-1.9-4.68-.73-5.12c0 0-7.46 2.05-7.9 4 0 0-1.89-3.07-1.61-4.39 0 0-3.5 5.56-5.11 6.73s-.29-2.79-.29-2.79-4.39 5.56-6 6V480s-12.57 6.73-16.08 5.85c0 0 0-2.78 1.17-3.51a62 62 0 0 1-7.89-.44c-1.9-.29-4.24-1.16-6.28-1.6s2.77-2.35 6-1.47c0 0-8.77-3.21-11.4-1.61 0 0-1.17-3.21 3.5-4.38 0 0-6-5.85-9.5-5.41 0 0 4-2.34 7.16-1.17 0 0-3.65-9.95-6-11.11 0 0 8.33-1.46 10.68-.29"
        className="Belize_svg__cls-7"
      />
      <path
        id="Belize_svg__path20"
        d="M484.79 454.87c2.19 1 0-7.17.73-10.24s7.16 7.9 10.23 9.36c3.22 1.61 22.8-7.75 22.8-7.75l4.68-8.77 2.49 7.16s5.4-6.28 7-8.62 0 4.68-1.61 7 4.68 3.21 4 7.89 6.29-3.21 6.29-3.21l18.12 1.6s3.07-11 7.89-17.25l1.47 7 7.45-7.6c8.33 21.93 25.88 45.76 61.83 58.77 33.77-12.28 51.17-34.06 60.23-54.83l10.08 10.39s7.9-2.34 9.36-3.22c1.61-.73 18.56 6 18.56 6a26 26 0 0 1 4.68 2.34c1.61 1.17.44-5.12.44-5.12s8.62 1.61 7.89 6.29c0 0 1.9-4.39 3.51-5.12s5.41 3.95 6.29 5.56c.73 1.46 19.29 3.8 21.92 5.41 0 0 2 2.34 2 3.95 0 0 4-.44 7-.44a4.37 4.37 0 0 0-2.34 3.65 5.81 5.81 0 0 1-3.51 5.41c-2.34 1.17 0 2 3.07 2.34s0 4.38-6.29 6.73c0 0-1.46 3.5 1.17 4.67 0 0-5.41.44-6.28-1.17 0 0-1.9 3.66-1.9 5.56a78 78 0 0 1-7.45-2.78s-2.34 2.34-.74 3.51c0 0-10.66-1.17-12.71-5.12 0 0-3.8 1.17-3.07 2.49 0 0-7.46-5.12-9.8-5.12l-1.6 2.63s-5.12-3.07-6.73-3.07-.29 2.34-.29 2.34l-7.16-4.39-2.78 4.39a81 81 0 0 1-7.45-3.95c-1.47-1.16-1.9 2.34-3.07 2.78 0 0-2.34-3.21-5.56-2.78 0 0-1.17 3.95-.73 5.12 0 0-9.06-1.9-10.67-2.34s-.73 1.9 0 3.51c0 0-6.72-1.9-8.18-3.07l-.44 3.07s-5.55-.44-6.72-1.61l-3.51 4.82h-5.12l-1.17 4.24s-9.79-2.77-10.67-1.17.44 4 .44 4-7.89 0-8.62-1.17l-.73 3.51s-9.94-3.07-12.28-1.61c0 0-3.07 3.22-5.12 3.95-1.89.87 0-1.17 0-1.17l-3.07-4.24s-3.5 1.9-5.55 1.17c-1.9-.88-8.19 3.07-10.24 1.9 0 0 0-2.79-1.6-3.51s-4.24-.73-5.41 1.17c0 0-1.17-1.61-3.22-4.68-1.9-3.22-4.68.29-4.68.29l-.43-3.8a42 42 0 0 1-5.41-.44 4.69 4.69 0 0 1-3.95-3.22s-8.33.88-9.5.44l2-1.9s-11.4 2.34-13.74 1.47 2.64-3.95 2.64-3.95-10.53-2.63-12.58-3.07-13.3 1.89-14.91 1.89-2.34-2.63-2.34-2.63a19 19 0 0 1-5.12 3.08s-1.9-4.68-.72-5.12c0 0-7.46 2-7.9 4 0 0-1.9-3.07-1.61-4.39 0 0-3.5 5.56-5.11 6.72s-.3-2.77-.3-2.77-4.38 5.55-6 6V480s-12.57 6.73-16.08 5.85c0 0 0-2.78 1.17-3.51a62 62 0 0 1-7.89-.44c-1.9-.29-4.24-1.16-6.29-1.6s2.78-2.34 6-1.47c0 0-8.77-3.21-11.41-1.61 0 0-1.17-3.21 3.51-4.38 0 0-6-5.85-9.51-5.41 0 0 3.95-2.34 7.17-1.17 0 0-3.66-9.94-6-11.11 0 0 8.34-1.46 10.68-.29Z"
        className="Belize_svg__cls-8"
      />
      <path
        id="Belize_svg__path22"
        d="M583.75 447a105 105 0 0 1-8.92-19.44l63.3-55.71 63.3 55.56a97.4 97.4 0 0 1-9.21 19.89l-.51.8c-1.16-.43-4.76-1.83-4.76-1.83s-2 1.9-3.65 2.05c-2.19.29-4.09-1.9-4.09-1.9s-2.78 2.34-5.56 1.9a5.48 5.48 0 0 1-3.94-2.05s-2.93 2.34-5 1.9a7.4 7.4 0 0 1-3.22-1.76 4.86 4.86 0 0 1-4.24 1.76 15.8 15.8 0 0 1-4.53-1.76 28.3 28.3 0 0 1-6 1.9c-2.34.45-4.39-2-4.39-2s-3.07 2-4.23 2-5.56-2-5.56-2-4.53 1.46-6.14 1.46-6-1.9-6-1.9a35 35 0 0 1-6.14 1.17 17 17 0 0 1-5-1.17s-3.5 1.61-5.4 1.17a20 20 0 0 1-3.66-1.17s-3.8 1.61-6.29 1.17a20 20 0 0 1-5.11-2s-.44 1.16-5 2h-.15"
        style={{
          fill: "#9dd7ff",
        }}
      />
      <path
        id="Belize_svg__path24"
        d="M655 415.1s12.57 7.16 0 20.91"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path26"
        d="M597.29 433.67s3.51 1.9 13 5.26c8 2.78 17.39 4.09 31 4.09 27.62 0 27.18-4.68 27.18-4.68l-9.79 9.94s-40 2.61-46.49-2.48c-2.77-1.46-8.77-6.29-14.91-12.14"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path28"
        d="M597.29 433.67c4.76 2.58 3.15 1.77 13 5.26 8 2.78 17.39 4.09 31 4.09 11.9 0 18.59-.94 22.36-2s4.83-2.7 4.83-2.7l-9.79 10s-39.36 2.12-46.49-2.49c-2.77-1.46-8.77-6.28-14.9-12.13Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path30"
        d="m598.47 434.4 18.12-17.25-2 23.1"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path32"
        d="M624.05 442.29V403.7"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path34"
        d="M655 442.29v-40.93"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path36"
        d="M639.4 443v-44"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path38"
        d="m624.05 405.74-8.19 2.63 8.19.88z"
        className="Belize_svg__cls-12"
      />
      <path
        id="Belize_svg__path40"
        d="m624.05 405.74-8.19 2.64 8.19.87v-3.51"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path42"
        d="m639.4 399.75-8.63 3.25 8.63.3v-3.51"
        className="Belize_svg__cls-12"
      />
      <path
        id="Belize_svg__path44"
        d="m639.4 399.75-8.63 3.25 8.63.29v-3.51"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path46"
        d="m655 402.09-8.63 3.21 8.63.44z"
        className="Belize_svg__cls-12"
      />
      <path
        id="Belize_svg__path48"
        d="m655 402.09-8.63 3.21 8.63.44v-3.66Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path50"
        d="m601.24 432.06 15.35-14.91-.73 8.91s-7.89 5.12-7.89 8.77l-6.73-2.78"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path52"
        d="m601.24 432.06 15.35-14.91-.73 8.91s-7.89 5.12-7.89 8.77l-6.73-2.77"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path54"
        d="M609.43 435.12a13.9 13.9 0 0 1 6-7l-.44 9.36-5.55-2.34"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path56"
        d="M609.43 435.13a13.9 13.9 0 0 1 6-7l-.44 9.36-5.55-2.34Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path58"
        d="M631.5 409.25a41.6 41.6 0 0 1 11 0s-.73 6.29 0 7.46c0 0-8.63-2-12.14 1.17 0 0-1.17-5.85 1.17-8.63"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path60"
        d="M631.5 409.25a41.6 41.6 0 0 1 11 0s-.74 6.29 0 7.46c0 0-8.63-2-12.14 1.17 0 0-1.17-5.85 1.17-8.63Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path62"
        d="M618.2 410.86a41.5 41.5 0 0 1 11 0s-1.16 7-.44 8.18c0 0-7-3.94-10.52-.73 0 0-2.34-4.82 0-7.45"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path64"
        d="M618.2 410.86a41.5 41.5 0 0 1 11 0s-1.17 7-.44 8.19c0 0-7-3.95-10.52-.74-.04 0-2.38-4.82-.04-7.45Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path66"
        d="M617.76 419a49.5 49.5 0 0 1 11 0s-.73 6.29 0 7.46c0 0-8.19-1.9-11.7 1.17 0 0-1.61-5.85.73-8.63"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path68"
        d="M617.76 419a49.5 49.5 0 0 1 11 0s-.73 6.29 0 7.46c0 0-8.19-1.9-11.7 1.17 0 0-1.61-5.85.73-8.63Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path70"
        d="M630.33 417.88s9.07-1.17 13.74-.44c0 0-1.9 5.12.44 8.62 0 0-9.79-2.63-13.45.44 0 0-3.07-5.85-.73-8.62"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path72"
        d="M630.33 417.88s9.07-1.17 13.74-.44c0 0-1.9 5.12.44 8.62 0 0-9.79-2.63-13.45.44 0 0-3.06-5.85-.73-8.62Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path74"
        d="M617 427.67a84 84 0 0 1 12.13.88s-1.16 8.92-.44 10.09c0 0-9.79-2.34-11.69.87 0 0-3.22-8.18 0-11.84"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path76"
        d="M617 427.67a84 84 0 0 1 12.13.88s-1.17 8.92-.44 10.09c0 0-9.79-2.34-11.69.87 0 0-3.22-8.18 0-11.84Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path78"
        d="M630.77 427.67s10.52-.73 15.35 0c0 0-2.78 6.73.29 12.58 0 0-13.74-3.95-15.64-.74 0 0-3.21-8.18 0-11.84"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path80"
        d="M630.77 427.67s10.52-.73 15.35 0c0 0-2.78 6.73.29 12.58 0 0-13.74-3.95-15.64-.74 0 0-3.21-8.18 0-11.84Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path82"
        d="M646.85 409.25a41.6 41.6 0 0 1 11 0s-.73 6.29 0 7.46c0 0-8.63-2-12.14 1.17 0 0-1.17-5.85 1.17-8.63"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path84"
        d="M646.85 409.25a41.5 41.5 0 0 1 11 0s-.72 6.29 0 7.46c0 0-8.62-2-12.13 1.17 0 0-1.17-5.85 1.17-8.63Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path86"
        d="M646.85 417.88a49.5 49.5 0 0 1 11 0s-.73 6.28 0 7.45c0 0-8.63-1.9-12.14 1.17 0 0-1.17-5.85 1.17-8.62"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path88"
        d="M646.85 417.88a49.4 49.4 0 0 1 11 0s-.72 6.28 0 7.45c0 0-8.62-1.9-12.13 1.17 0 0-1.17-5.85 1.17-8.62Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path90"
        d="M647.58 426.94a42 42 0 0 1 11.11 0s-1.61 9.79-.88 11c0 0-7.16-3.37-10.52-.44 0 0-2-7.75.29-10.53"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path92"
        d="M647.58 426.94a42.5 42.5 0 0 1 11.11 0s-1.61 9.8-.88 11c0 0-7.16-3.37-10.52-.44 0 0-2-7.75.29-10.53Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path94"
        d="M660.15 420.65c-.44-1.75 5.12-1.61 5.12-1.61s1.61 5.86 0 9.51l-4.68-.88a21.5 21.5 0 0 0-.44-7"
        className="Belize_svg__cls-12"
      />
      <path
        id="Belize_svg__path96"
        d="M660.15 420.66c-.44-1.76 5.12-1.61 5.12-1.61s1.61 5.85 0 9.5l-4.68-.88a21.5 21.5 0 0 0-.44-7Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path98"
        d="M660.59 427.67s2 7.46 4.68 8.34l-3.51 4.24s-4.24-3.07-4.68-6.58c0 0 2.78-3.22 3.51-6"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path100"
        d="M660.59 427.67s2 7.46 4.68 8.34l-3.51 4.24s-4.24-3.07-4.67-6.58c0 0 2.77-3.22 3.5-6Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path102"
        d="M691.92 447.85c-10.08 16.81-26.6 32.6-53.79 42.39-27.63-9.93-44.44-26.45-54.38-43.12l.15-.15c4.53-.88 5-2.05 5-2.05A19.9 19.9 0 0 0 594 447c2.48.43 6.28-1.17 6.28-1.17a20 20 0 0 0 3.66 1.17c1.9.43 5.41-1.17 5.41-1.17a17 17 0 0 0 5 1.17 35 35 0 0 0 6.14-1.17s4.53 1.9 6 1.9c1.61 0 6.14-1.47 6.14-1.47s4.39 2 5.56 2 4.24-2 4.24-2 2 2.49 4.38 2a28.3 28.3 0 0 0 6-1.9 15.8 15.8 0 0 0 4.53 1.76 4.86 4.86 0 0 0 4.24-1.76 7.4 7.4 0 0 0 3.22 1.76c2 .44 5-1.9 5-1.9a5.5 5.5 0 0 0 3.95 2c2.77.44 5.55-1.89 5.55-1.89s1.9 2.19 4.09 1.89c1.61-.14 3.66-2 3.66-2s3.65 1.16 5 1.61"
        style={{
          fill: "#006ac8",
        }}
      />
      <path
        id="Belize_svg__path104"
        d="M689.44 451.65c-2.19.15-2.49-1.32-2.49-1.32s-2.92 2.49-5.7 2.05A6.45 6.45 0 0 1 677 450s-3.07 2.49-5.11 2a6.86 6.86 0 0 1-3.51-1.76s-1.9 2.2-4.39 1.76a12.1 12.1 0 0 1-4.67-1.76 41 41 0 0 1-6.44 2.05c-2.48.44-4.53-2.34-4.53-2.34s-5.7 1.91-6.87 1.91-5.41-1.91-5.41-1.91-2.77 1.76-4.38 1.76-6.43-2.05-6.43-2.05a37 37 0 0 1-6.44 1.17 18.6 18.6 0 0 1-5.11-1.17s-3.8 1.61-5.85 1.17a20 20 0 0 1-3.66-1.17s-4.09 1.61-6.57 1.17a21.5 21.5 0 0 1-5.41-2s-.44 1-5.26 2h-.15"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path114"
        d="M638.13 307.78v64l-63.3 56a126.3 126.3 0 0 1-6.58-40.65v-79.35z"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path116"
        d="M638.13 307.79v64l-63.3 56a126 126 0 0 1-6.58-40.64v-79.36h69.88"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path118"
        d="M638.13 307.78v64l63.15 56a126 126 0 0 0 6.58-40.65v-79.35z"
        style={{
          fill: "#ffd83c",
        }}
      />
      <path
        id="Belize_svg__path120"
        d="M638.13 307.79v64l63.15 56a126.2 126.2 0 0 0 6.57-40.64v-79.36h-69.72"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path134"
        d="M607.21 330.14c.31-.6 1-2.12 1.71-3.77.28-.66 5.67-9.26 8.36-9.52 0 0 16.78 12.21 16.79 12.37.06.66-7.27 11.63-7.91 10.8 0-.02-19.66-8.26-18.95-9.88Z"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: ".57px",
        }}
      />
      <path
        id="Belize_svg__path138"
        d="M699.23 381.18c-2.48-2.92-4.53-6-8-9.79L653 324.46l4-3.8 34.35 40.2a178 178 0 0 1 12.72 14.76 4.18 4.18 0 0 1-1.3 4.17 6.9 6.9 0 0 1-3.54 1.39"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path140"
        d="M702.79 379.89a8.6 8.6 0 0 1-3.56 1.29c-2.48-2.93-4.53-6-8-9.79L653 324.45l3.94-3.8 34.35 40.21a175 175 0 0 1 12.73 14.76s.5 2.92-1.27 4.27Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path142"
        d="m666.05 330.45-6.14-7.31s-4.68.58-5.55 3.07c0 0-3.37 5.55-12.28 6.14a26.8 26.8 0 0 0 12.86 15.93s3.8-10.67 7.46-12.57c0 0 3.8-2.78 3.65-5.27"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path144"
        d="m666.05 330.45-6.14-7.31s-4.68.58-5.55 3.07c0 0-3.37 5.56-12.28 6.14a26.8 26.8 0 0 0 12.86 15.93s3.8-10.67 7.45-12.57c0 0 3.8-2.78 3.66-5.26Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path146"
        d="M655.23 327.53c1.61 1.6 6.14 7.45 6.14 7.45"
        className="Belize_svg__cls-17"
      />
      <path
        id="Belize_svg__path148"
        d="M657.28 330.74c-.29.88-.88 3.36-2.19 4.68"
        className="Belize_svg__cls-17"
      />
      <path
        id="Belize_svg__path150"
        d="M658 331.33a6.82 6.82 0 0 1-2.19 4.38"
        className="Belize_svg__cls-17"
      />
      <path
        id="Belize_svg__path152"
        d="M658.74 332.2a4.93 4.93 0 0 1-1.17 2.49"
        className="Belize_svg__cls-17"
      />
      <path
        id="Belize_svg__path154"
        d="M659.18 333.08c0 .73-.44.88-.58 1.32"
        className="Belize_svg__cls-17"
      />
      <path
        id="Belize_svg__path156"
        d="M653.77 330.3a35.5 35.5 0 0 1-7.16 4.24"
        className="Belize_svg__cls-17"
      />
      <path
        id="Belize_svg__path158"
        d="M652.6 331.91a21.2 21.2 0 0 1-4.68 3.22"
        className="Belize_svg__cls-17"
      />
      <path
        id="Belize_svg__path160"
        d="M652.16 333.66c-.87.59-2.33 2-3.5 2.49"
        className="Belize_svg__cls-17"
      />
      <path
        id="Belize_svg__path162"
        d="M651.28 335.42a8.2 8.2 0 0 1-2.19 1.75"
        className="Belize_svg__cls-17"
      />
      <path
        id="Belize_svg__path164"
        d="m649.83 363.78 43.26-43 10.67 11.4s-2.19-.87-3.07-.3.88 2.78.88 2.78-2.05-.73-2.92-.14c-1 .58 0 3.07 0 3.07s-1.76-1.32-2.64-.74 0 3.37 0 3.37-2.33-.73-2.92-.15 0 3.07 0 3.07-2.34-1.17-3.51 0 .59 2.78.59 2.78-2.92-1.17-4.1.58a2.91 2.91 0 0 0 .15 3.36s-2.34-1.16-2.92-.58 0 3.36 0 3.36-2-1.17-3.36.29a2.08 2.08 0 0 0 .14 2.93s-1.89-1.17-2.92-.59.29 3.36.29 3.36-1.89-1.32-3.21 0a2.17 2.17 0 0 0 0 2.92 2.27 2.27 0 0 0-3.21 3.22s-2.34-1.17-3.22 0 .29 2.78.29 2.78-1.75-1-2.92-.44 0 3.21 0 3.21-1.75-1.9-2.48-.87c-.88 1 .14 3.51.14 3.51s-1.6-1.91-2.48-.88c-1 .88.58 3.07.58 3.07s-2.92-1.31-2.19-1.31c.15 0-8.92-10.09-8.92-10.09"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path166"
        d="m649.83 363.78 43.26-43 10.67 11.4s-2.19-.87-3.07-.29.88 2.78.88 2.78-2.05-.73-2.92-.15c-1 .58 0 3.07 0 3.07s-1.76-1.32-2.64-.73 0 3.36 0 3.36-2.33-.73-2.92-.15 0 3.07 0 3.07-2.34-1.17-3.51 0 .59 2.78.59 2.78-2.92-1.17-4.09.58a2.92 2.92 0 0 0 .14 3.37s-2.34-1.17-2.92-.59 0 3.36 0 3.36-2-1.17-3.36.3a2.06 2.06 0 0 0 .15 2.92s-1.9-1.17-2.93-.59.29 3.36.29 3.36-1.89-1.31-3.21 0a2.18 2.18 0 0 0 0 2.93 2.27 2.27 0 0 0-3.22 3.21s-2.33-1.16-3.21 0 .29 2.78.29 2.78-1.75-1-2.92-.44 0 3.22 0 3.22-1.75-1.9-2.48-.88c-.88 1 .14 3.51.14 3.51s-1.61-1.9-2.48-.87c-1 .87.58 3.07.58 3.07s-2.92-1.32-2.19-1.32c.15 0-8.92-10.09-8.92-10.09Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path168"
        d="m690.61 324.31-3.36 3.36.73 11.55s.73-3.36 1.75-1c0 0 .29-2.77 1-2.33-.58-.3-.14-11.55-.14-11.55"
        className="Belize_svg__cls-18"
      />
      <path
        id="Belize_svg__path170"
        d="m684.32 330.59-2.48 2.34.87 11.55s.74-4.38 1.76-.88c0 0-.15-2.92-.15-3.94z"
        className="Belize_svg__cls-18"
      />
      <path
        id="Belize_svg__path172"
        d="m658.16 356.47-2.34 2.53.58 7s.73-4.53 1.76-1c0 0-.3-2.92-.3-3.95 0 .73.3-4.53.3-4.53"
        className="Belize_svg__cls-18"
      />
      <path
        id="Belize_svg__path174"
        d="M650.7 362.9c-.88-.29-1.75 0-3.07.73-1.17.74-2.63.45-2.48-1 .14-1.31 0-1.17 1.6-2.19a9.4 9.4 0 0 1 7.31-.73l-3.36 3.21"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path176"
        d="M650.7 362.91c-.88-.3-1.75 0-3.07.73-1.17.73-2.63.44-2.48-1 .14-1.31 0-1.16 1.6-2.19a9.4 9.4 0 0 1 7.31-.73l-3.36 3.22"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path178"
        d="M692.36 321.38a3.24 3.24 0 0 1 .44-3.36c1-1.17 1-3.22-1.46-2.19s-3.37 3.8-1.32 8l2.34-2.49"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path180"
        d="M692.36 321.38a3.25 3.25 0 0 1 .44-3.36c1-1.17 1-3.22-1.47-2.19s-3.36 3.8-1.31 8l2.34-2.49Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path184"
        d="M806.53 278.84c.73.29 2.78-2.93.73-4.39 0 0-.73-2.78-3.07-2.93 0 0-1.9-2.77-4.68-2.33 0 0-3.07-2.19-4.24-2.05-1.32.3-4.24 3.22-4.39 4.24a5.18 5.18 0 0 0 .73 3.51c.88.74 1.47-.15 1.61-1.46a3 3 0 0 0 1.17 3.22c1.75 1 1.61.14 1.75-1 0 0 .44 2.34 1.61 2.78s1.9-.59 2.78-.59c0 0-1.46 1.17-.44 1.9a2.22 2.22 0 0 0 2.93-.14c.87-.59.44-2.49 3.51-.73"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path186"
        d="M806.53 278.84c.73.29 2.78-2.92.73-4.39 0 0-.73-2.77-3.07-2.92 0 0-1.9-2.78-4.68-2.34 0 0-3.07-2.19-4.24-2s-4.24 3.21-4.38 4.24a5.1 5.1 0 0 0 .73 3.5c.87.74 1.46-.15 1.6-1.46a3 3 0 0 0 1.17 3.22c1.76 1 1.61.14 1.76-1 0 0 .44 2.33 1.6 2.77s1.9-.59 2.78-.59c0 0-1.46 1.18-.43 1.91a2.22 2.22 0 0 0 2.92-.15c.88-.58.44-2.48 3.51-.73Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path188"
        d="M793.37 273.43c.73-2.49 3.22-4.53 5.85-4.1"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path190"
        d="M796.15 275.63a9 9 0 0 1 .88-1.76c1.31-2.19 4.23-2.19 6.57-2.64"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path192"
        d="M800 277.67c.87-.59 1.17-1.61 2-2a7.55 7.55 0 0 1 4.67-1"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path194"
        d="M682.42 180.88s.88-2 2.49-.87 1.89 6.28 1.89 6.28l-2.34 1.17s1.32-1.9-2-6.58"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path196"
        d="M682.42 180.88s.88-2 2.49-.87 1.89 6.28 1.89 6.28l-2.34 1.17s1.32-1.9-2-6.58Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path198"
        d="M669.12 226.79s1.17-1.61-.44-5.12l2.34-.73a9.7 9.7 0 0 1-.29 5.85z"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path200"
        d="M669.12 226.79s1.17-1.6-.44-5.12l2.34-.73a9.7 9.7 0 0 1-.29 5.85h-1.61"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path202"
        d="M686.66 179a7.5 7.5 0 0 1-3.36-1.31z"
        style={{
          fill: "#6f6",
        }}
      />
      <path
        id="Belize_svg__path204"
        d="M686.66 179a7.5 7.5 0 0 1-3.36-1.31"
        className="Belize_svg__cls-8"
      />
      <path
        id="Belize_svg__path206"
        d="m709.61 218.75-5.55 3.36 2.77 1.32 6.73-4-3.95-.73"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path208"
        d="m709.61 218.75-5.55 3.36 2.78 1.32 6.72-4-3.95-.73"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path210"
        d="M635.21 240.53c-1.47 1.17-1.61 3.22-2.49 4.39s-2.92 2.77-3.07 4.53a9.5 9.5 0 0 0 .29 5.26c.88 2.34 4.53 12.72 3.07 20.62s-1 11.25-.43 12.42 2.34 4.39 3.36 4.39c2.34-.29 1.31-1 2.19-2.49 2.19-3.8 0-33.18-1-41.37a40.9 40.9 0 0 0-4-12.57c-.29-.74-.88-2.63-1.32-3.81-2.19-5.26-4.82-9.79-4.82-20.76 0 0-7.16 1.61-13.3-12.28-3.21-7-7.89-6.29-13.45-8.62l2.49-1.61s4.67 2.34 7 2.34 1.61-4.68 1.61-4.68l2.34-2.34v10.24c0 4.68 6.44 11.11 10.23 13 2.34 1.17 5.41-3.65 4.68-8.33a49.5 49.5 0 0 1 0-11l3.21 1.9s-1.16 2-.87 3.21 2-1.16 3.22-2.34c0 0 .29.3 1.46.3 0 0-2.34 3.94-3.51 6-1.17 1.9-1.17 9.36-1.17 12.13s1 9.22 1 11.26c0 1.47 2.77 7.46 5 11.11.73 1.47 2.48 5.27 2.48 5.27 1.76 2.78 1.9 4.68 2.92 7 .59 1.18 1.61 4.54 2.34 10.39.29 1.46.58 7 1 10.67.44 3.51 3.37.73 6.87-1.75 3.51-2.63 3.51-8.92 3.51-8.92s2.78-2.34 2.34-1 1.46 2.92 1.46 2.92c-2.19 5-.87 4.39 1.32 4.24s9.79-6 9.79-6c1-.3 5.56-.3 4.82 0s-2 2.63-2 2.63l5-.59c-1.76 1.32-8.34 5.12-9.79 6a139 139 0 0 1-12.87 6.14c-3.36 1.46-9.94 4.68-10.23 8s.44 12.42 0 15.35c-.29 2.92-1.47 15.94-1.47 15.94h-15.65s3.07-11.41-.14-14.48-10.24-12.57-14.91-15.79c-4.68-3.07 3.07-.73 3.07-.73v-3.21l7.21 8.69s12.42-11 0-25.87c-4-4.68-7.89-11.85-11-12.57-3.21-.89-4.82-1.62-10.23 0s-18.86 1.6-24.41-7.17l4.68-.73 4-1.61s-1.61 5.56 9.49 5.56 12.57 0 11.7-10.24l3.21-1.61 2.34 2.34s-1.6 5.56-.73 7.9 4.68 4 7.75 6.28a4.86 4.86 0 0 0 3.22 1.17c1.31-.15 1.6-.15 3.36-1.9 2.63-2.92 2.05-2.78 4.68-5.12 0 0 1.31-2 1.6-2.33l1.61 4.23"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path212"
        d="M635.21 240.53c-1.47 1.17-1.61 3.22-2.49 4.39s-2.92 2.77-3.07 4.53a9.54 9.54 0 0 0 .3 5.27c.87 2.33 4.52 12.71 3.06 20.61s-1 11.25-.43 12.43 2.34 4.38 3.36 4.38c2.34-.29 1.31-1 2.19-2.48 2.19-3.81 0-33.19-1-41.38a40.6 40.6 0 0 0-4-12.57c-.29-.74-.87-2.63-1.31-3.8-2.2-5.27-4.83-9.8-4.83-20.77 0 0-7.16 1.61-13.3-12.28-3.21-7-7.89-6.28-13.45-8.62l2.49-1.61s4.68 2.34 7 2.34 1.61-4.68 1.61-4.68l2.33-2.34v10.24c0 4.67 6.44 11.11 10.24 13 2.34 1.16 5.41-3.66 4.68-8.34a48.8 48.8 0 0 1 0-11l3.21 1.9s-1.17 2-.87 3.21 2-1.16 3.21-2.34c0 0 .29.3 1.46.3 0 0-2.34 3.94-3.51 6-1.16 1.9-1.16 9.36-1.16 12.14s1 9.21 1 11.25c0 1.47 2.78 7.46 5 11.12.73 1.46 2.49 5.26 2.49 5.26 1.75 2.78 1.89 4.68 2.92 7 .58 1.17 1.61 4.53 2.34 10.38.29 1.46.58 7 1 10.67s3.37.73 6.87-1.75c3.51-2.63 3.51-8.92 3.51-8.92s2.78-2.34 2.34-1 1.46 2.92 1.46 2.92c-2.19 5-.87 4.39 1.32 4.24s9.79-6 9.79-6c1-.29 5.56-.29 4.82 0s-2 2.64-2 2.64l5-.59c-1.76 1.32-8.34 5.12-9.79 6a135 135 0 0 1-12.87 6.15c-3.36 1.46-9.94 4.67-10.23 8s.44 12.42 0 15.35c-.29 2.92-1.46 15.93-1.46 15.93h-15.65s3.07-11.4-.15-14.47c-3.07-3.21-10.24-12.57-14.91-15.79-4.68-3.07 3.07-.73 3.07-.73v-3.22l7.2 8.7s12.42-11 0-25.88c-4-4.67-7.9-11.84-11-12.57-3.21-.88-4.82-1.61-10.23 0s-18.86 1.61-24.41-7.17l4.68-.73 3.94-1.61s-1.61 5.56 9.5 5.56 12.57 0 11.7-10.23l3.21-1.61 2.34 2.34s-1.61 5.56-.73 7.89 4.68 3.95 7.75 6.29a4.86 4.86 0 0 0 3.22 1.17c1.31-.15 1.6-.15 3.36-1.9 2.63-2.93 2-2.78 4.68-5.12 0 0 1.31-2 1.6-2.34l1.61 4.24Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path214"
        d="M637.54 232.78a31.7 31.7 0 0 0 2.78-4.67c1.76-2.93 3.8-4.68 6.14-8.34a16.44 16.44 0 0 0 2.19-11.84l2.05 1.17.14 5.55a35 35 0 0 1 3.95-2.92l1.17.58A125.3 125.3 0 0 0 639 236z"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path216"
        d="M637.54 232.78a31.7 31.7 0 0 0 2.78-4.67c1.76-2.93 3.8-4.68 6.14-8.34a16.43 16.43 0 0 0 2.19-11.84l2.05 1.17.15 5.56a33.6 33.6 0 0 1 3.95-2.93l1.16.58A125.3 125.3 0 0 0 639 236l-1.46-3.21Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path218"
        d="M560.65 202.37c1.17 2 2.34 6 5.85 9.07l2.34-.73s-5.41-6.29-5.41-9.07z"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path220"
        d="M560.65 202.38c1.17 2 2.34 6 5.85 9.06l2.34-.73s-5.41-6.29-5.41-9.06l-2.78.73"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path222"
        d="M574.39 198.86s.73 7.46-1.17 10.24l2.35-1.17s1.6-5.12 1.16-7.89z"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path224"
        d="M574.39 198.86s.73 7.46-1.16 10.24l2.34-1.17s1.6-5.11 1.16-7.89l-2.34-1.17Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path226"
        d="M651.43 175.33s-.43 10.52 1.57 16.95l2.78-.87s-.44-1.9 1.89-2.78c2.34-.73 7.9-3.07 10.67-5.41l-.87-2.34s-4.24 3.37-6.29 4.24c-1.89.73-3.06 1.61-4.67 1.17s-3.51-3.07-2.34-9.06l-2.78-1.9"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path228"
        d="M651.43 175.33s-.44 10.52 1.61 17l2.78-.88s-.44-1.9 1.89-2.78c2.35-.73 7.9-3.07 10.67-5.41l-.87-2.34s-4.24 3.37-6.29 4.24c-1.89.73-3.06 1.61-4.67 1.17s-3.51-3.07-2.34-9.06l-2.78-1.9"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path230"
        d="M682.42 224.45s-.73 7.46-.29 10.67l2.48.59s-.58-8.92 1-9.65l-3.22-1.61"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path232"
        d="M682.42 224.45s-.73 7.46-.29 10.68l2.48.58s-.58-8.92 1-9.65l-3.22-1.61"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path234"
        d="M693.53 230s-2.78 4.83-6 5.26l5.12-1a15.1 15.1 0 0 0 3.65-3.06z"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path236"
        d="M693.53 230s-2.78 4.83-6 5.27l5.12-1a15.1 15.1 0 0 0 3.65-3.06l-2.77-1.21"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path240"
        d="m648.8 207.93 1.9 1.17 2 .29s2.19 2.05 4.67 4c1.9 1.61 1.9-.29 1.9-.29l1.17 4.68h1.61a45 45 0 0 0-.44 4.67l2.78-2.34 3.21 2.78s2.34-2 3.51-2a1.84 1.84 0 0 0 1.61-1.17h.15c-7.75-3.66-11.11-7.46-8.77-11.41 3.8-6.57 10.08-2.34 12.13-1.46L704.64 222s1.9-1.17.58-2.35a5.83 5.83 0 0 1-1.61-5.26c.44-1.16 3.66 3.66 5.26 4.1s5.26 1 5.26 1L714 218s4.67 3.21 4.67 4.82c0 0 3.37-1.75 2.19-2.78s5.12 2.49 5.56 4.39l1.31-1.46c-.14 0 .44-4.68 2-6.87a7.17 7.17 0 0 1 3.07-2.78s1.46-2.49 2.92-2.49a7.3 7.3 0 0 1-2-2.19 5.3 5.3 0 0 0-2.49-1.75 24 24 0 0 0 2.78-2.34 2.3 2.3 0 0 1 1.17-1s-3.37-2.77-5.7-3.21c0 0-.15-3.8-3.07-4.1h1.31s-2.19-4.09-4.24-4.23a5.37 5.37 0 0 1 2.49-1.61s-2.49-3.22-5.85-2l2.19-.87s-1.6-1.75-6.57-.88a3.8 3.8 0 0 0-1.75-3.65s-1.9.14-1.9 1.6a6.62 6.62 0 0 0-4-1.6c-2.19 0 .73 2.34.73 2.34s-2.92-1.91-4.68-1.32c0 0 .73-3.36-1.17-5.12 0 0-2.19 1.17-2.78 2.63l-.58-1.46s-2.92 3.51-3.51 2.49a13 13 0 0 1-.87-3.07l-1.75 2.63.29-3.66s-2.05 1.17-3.66 5c0 0-2 1.9-2 3.65 0 0-1.61-1.89-3.07 0-1.32 2 0 1.62 0 1.62a3.67 3.67 0 0 1-2 1l.29 1.16a5.3 5.3 0 0 0-3.36-.58l.73 1.75s-3.8.3-3.94-2.77c0 0-3.22-1.61-3.66.15 0 0-2.63 0-2.34 1.46a3.53 3.53 0 0 0-2.05-1.75c-1.46-.44-.29 1.89-.29 1.89a4.42 4.42 0 0 0-2.19-2.63c-1.75-.72-.29 1.61-.29 1.61s-2.34-1.9-1.17-3.36c0 0-2.19 2-1.17 3.95l-1.46 1s-.73-.73-.59-1.32S659 192 659 192s-1.61-3.8 0-3.8a3.3 3.3 0 0 0-3.21 3.22 17.5 17.5 0 0 0-3.66.72c-1.9.59 1.32 1.91 1.32 1.91s-2.78-.59-4.39.29l1 1.31s-1-1-3.5-.15c-2.64.73.58 1.17.58 1.17s-2.63-.58-4 0 .44 1.61.44 1.61-4.82-1.46-5.55-1 1.6 2.78 1.6 2.78-2.92.73-3.5 2.92l2.63 1a4.37 4.37 0 0 0-1.76 1.75c-.58 1.17 1.76.58 1.76.58s-3.21 2.34-3.21 4.1a4.29 4.29 0 0 0 4.09-1c1.75-1.75.58-1.16.58-1.16l.15 1.16s3.36-1.46 3.36-2.33-.58 1.17-.58 1.17l3.36-1.76 2.19 1.46Z"
        className="Belize_svg__cls-20"
      />
      <path
        id="Belize_svg__path244"
        d="m646 162.32-.73-3.22a35.8 35.8 0 0 1 7.45.87l1.32-3.06a23 23 0 0 1 4.68 2.77s6.87-1.75 8.92-1l-.88 3.22s6.28.43 8.33 1.16c1.9.73-.87 2.34-.87 2.34s7.16.88 10.23 2.78 3.22 3.51 1.61 3.94l3.94 6.29s-6.28.73-7.45 2.34-2.34-1.61-2.34-1.61l-1.17 4.39a36 36 0 0 1-5.55-3.51s-1.9 2.34-.73 3.51c0 0-5.12 0-7.17-4.68 0 0-3.07-.44-3.07 2.34 0 0-3.5-3.95-4.38-5.12l-3.95 1.17a6.17 6.17 0 0 0-2.63-2.05c-1.61-.29-.44 2.05-.44 2.05l-2.34-2.78-2.34-2.77s-2 2.77-3.65 2.77-1.47-1.9-1.17-3.21-4.68-1.46-5.12 0c0 0-1.46-5.85 4-7.46l5.55-1.46Z"
        className="Belize_svg__cls-20"
      />
      <path
        id="Belize_svg__path248"
        d="m617.66 182.34-2.48 2.34-1.46-.73-2.93 3.22-.58-1.76-1.61 2.59-2.48-.87-1.17 1.9-2.19-.44a14 14 0 0 1-3.07 2.19c-1.17.3 0-2.63 0-2.63s-1.76 2.93-3.07 2.93-.73-1.32-.73-1.32-.73 1.89-2.19 2.19c-1.32.15-.74-1.46-.74-1.46l-2.34 1.75v-1.46l-1-.44s.58-2.63 1.61-3.21-1-2.34-1-2.34l1-1.17s-2.93-.73-2.93-.15c0 0 1.47-3.94 3.51-4.68l.15-4.38a8.6 8.6 0 0 1 3.95-1.9c2-.29-.59-3.36-.59-3.36l4.68-2.78.58-3.36 7.16.58 1.47-3.07 5.7 1.46 4.82-2.48 3.51 4.09 5.26.44s-.15 3.65-.15 4.68c0 0 6.29 1.9 7 2.49a40 40 0 0 0-1.17 4.53s4.39-1.17 5.7-.88c0 0 .44 4.09.59 5.12 0 0 7.45 2.34 7.89 3.65a9.5 9.5 0 0 0-2.48 2.34 56 56 0 0 1 .87 6l-.58.88s-2.2-2-3.95-2.34l-1.47 3.22-2.63-1.72-1.6.15s-1.32-1.61-2.34-1.61l-.15-1.46-2 1.32s-3.21-1.61-3.21-2.64c0-.87.14-2.34.14-2.34l-3.36 2.34a6.7 6.7 0 0 1-.58-2.48c.15-.73-1.31 1.75-1.31 1.75s-1.76-3.07-1.61-3.65l-.44 2.49s-3.51-1.17-4-3.51Z"
        className="Belize_svg__cls-21"
      />
      <path
        id="Belize_svg__path252"
        d="M570.88 196.67s6.15 3.08 6.15 4.53c0 0 1.6-3.21.73-4.38l2.34 1.61s.72-2.92-.15-3.8c-.73-.73 5.26-3.22 6.57.73 0 0 3.66-3.8-3.36-7 0 0-.58-2.34 3.66-1.75a3.37 3.37 0 0 0-1.32-2.05h4.09a14 14 0 0 0-6.13-2.63c-3.51-.59-1.32-1-1.47-1.76a12.7 12.7 0 0 1 0-3.36 5 5 0 0 0-3.65.15l.58-.74s-3.8-1-5.26-.14a3.13 3.13 0 0 1-1.9-3.22 3.43 3.43 0 0 0-3.21 2.19 7.24 7.24 0 0 1-4.83 2.34 11.5 11.5 0 0 1-2.63-1.17l-1.75 4.1-2.78-.15.88 1.61s-4.24-1.17-5.56 0c0 0 1.61 2.48 1.17 4.24 0 0-3.36.58-4.09 1.17s1.16 1 1.16 1-3.8 1.17-4.68 2.34l1.47 1.16-1.76 2.31 1.47.29s-2.49.73-3.8 1.17 2.34.73 2.34.73l-1.75 1.6 2.77.44s-2.19 5.85-2.34 8.63c0 0 3.37-4.1 4.24-4.39l-2.63 4.39s5-1.61 5.41-2.34c.44-.88.88-1.61.88-1.61l.15 2.49a22 22 0 0 0 2.19-3.66c.14-1 3.07 1 3.07 1v-1.46s2.63.87 3.5 1c1 .15 1.17-.44 1.17-.44l2.49-.29-.44 2a6.7 6.7 0 0 0 2.19-2.33c.29-1.17 1.16 0 1.16 0l1.17-4.68h2.49Z"
        className="Belize_svg__cls-21"
      />
      <path
        id="Belize_svg__path256"
        d="m550.86 217.43 2.34 4.83c-1.9.14-4.68 2.33-4.68 2.33.44 8-1.61 7.61-1.61 7.61.58 1.46-.37 4.17-.37 4.17l11.63-7 .14-.73 13.75-6.44.43.73 4-2.19 2.78 7.31-2.78 1.61c.15 2.48 3.07 6.87 3.07 6.87l3.66-.87 3.94-1.47s.15 2.19.15 1.47 4.24-2.05 4.24-2.05.29 2.05 1.9 1.9 4.09-3.66 4.09-3.66l3.07 1.18a7.8 7.8 0 0 0 1-3.07c0-1.17 3.8-1.17 3.8-1.17l2.64 1.89 3.36-2.77s1 2 2.34 2.34c1.46.14 5.11 2.92 5.11 2.34a24.5 24.5 0 0 1 1.61-3.8l6.14 2.48a34 34 0 0 0-2.92-6.14s2.92-2.33 2.34-2.92-2.34 0-2.34 0l1.75-3.51h-2.34l.73-.87-3.8-1.61 2-4.09c-.88-1-11-3.08-11-3.08l2.33-2.19s-1.17-1.75-2.33-1.75-7.31.15-7.61-.43-2.63-2.79-2.63-2.79l-3.8 2.79-1.9-.59-3.36 1.32s0 1.89-.44.58-3.51-4.24-3.51-4.24c-.58 1-4.53 5.7-4.53 5.7s-.58 2.49-1.9.73-4.53-3.66-5-2.19-3.21 3.06-3.8 2.63-3.8-.15-4.1.73c-.14 1-3.65 3.65-3.65 3.65-2.19-1-5.7-.43-5.7-.43a8.4 8.4 0 0 0-1.17 2.92l-7.16 1.9Z"
        className="Belize_svg__cls-21"
      />
      <path
        id="Belize_svg__path260"
        d="M616.93 276.65v-3.08l3.51 2.35 2-2.35 2.63 2.63 1.61-2.63v4.25l3.94-3.51 2.34 1V272s2.78-4.68 1.61-5.56l2.34-.73a65 65 0 0 0-5.12-4.68 5.26 5.26 0 0 0-2.78-.87s-3.07-3.08-5.11-3.95a2.67 2.67 0 0 0-3.07.87s-1.17-2.33-3.22-2.77c-1.9-.44-1.9 1.6-1.9 1.6a11 11 0 0 0-3.36-2.19c-1.9-.87-.58 1.76-.58 1.76l-6.73-2 .88 2-6.29-1.61.73 2.34-4.38-.73-.3 1.9-6.28 1.17 1.17 2-4-1.6v2.77l-4.38 1.61 1.6 1.47s-5.84 2.77-7 3.65 2.34 2.34 2.34 2.34l-6.29-.88 2.34 2.05-5.55 5.12 3.94 2.78-2.34 3.5 5.85 1.17 2.34 3.95 4.82-.88v4.4l7-5.55s5.12 4.53 5.55 3.22a37 37 0 0 0 .3-5.12h2.48v-4l1.9 1.61 3.21-4.68 4.24 5.85 3.22-7.45 4.67.73Z"
        className="Belize_svg__cls-21"
      />
      <path
        id="Belize_svg__path264"
        d="m645.59 259-.73-6.73-2.05 2.05.87-6.29-3.21-.59 1.17-6.13-2.34-1.18.58-2.92 2.34-1.75 4.24-4.09 9.79.15 1.17-3.22s5.85 0 7 1.17 4.82-2.63 4.82-2.63h1.46l1.61 3.06s5.12-.73 7.46.88l-2.78 3.8 5.11.44 3.51.73 3.66-1.17 4.68-.3 1.16 1.91h4.39l-1.46 1.75 5.7 2.63-1.17 1.17a20.4 20.4 0 0 0 5.55 1.17h1.17s1.17 2.78-.44 3.95c0 0 1.61 3.94 5.12 4.68s3.51.73 3.94 1.6a3.82 3.82 0 0 1-3.94 4.68v4s-3.07 2.78-3.51 3.95l-2.34-1.61s-2 2.34-2 3.95c0 0-5.12-6.73-7-7-2.05-.43-1.61 3.81-1.61 3.81l-5.55-4.68-.73 3.21-4.68-3.94-2.78 1.89-5-5.11-4.83.59 1.9-2.49H670l-1.17-5.11-.88 4.23-3.5-2.33-4.53 5a8 8 0 0 1-1.32-2.93c-.73-2.34-2.78 2.78-2.78 2.78l-1.61-3.66-1.9 3.66s-1.61-2.78-3.21-3.66c-1.46-.73-3.07 4.39-3.51 6.73Z"
        className="Belize_svg__cls-21"
      />
      <path
        id="Belize_svg__path268"
        d="m646.46 274.3.29-2.33 8.34-3.22 7-2.63 3.51-1.61 1.61 2.78 2-4 3.22 4.09 1.75-2.19 2.78 2.05h2.34l.44 3.07s5.85.44 6.28-.73 1.17 3.95 1.17 3.95l5.55.73 1.17 6.28s2.78 5.85 1.9 8.19c-.73 2.49-3.07-3.07-3.07-3.07a41 41 0 0 1-.88 5.56c-.29 1.16-7.45-1.17-7.45-1.17l-3.07 5.84s-3.21-4.67-4.68-4.67-1.61 4.67-1.61 4.67-4.67-5.55-6.72-7.89-2.34 2.34-2.34 2.34-3.07-5.12-3.07-6.58c0-1.61-2.78-3.22-2.78-3.22s-4.82 5.56-8.33 7c0 0 .44-3.51-.73-4.68s-4.68 2.34-4.68 2.34a14.38 14.38 0 0 1 0-11Z"
        className="Belize_svg__cls-21"
      />
      <path
        id="Belize_svg__path270"
        d="m685.05 187.17.73 2.92a19 19 0 0 1 4.39-.44 15.2 15.2 0 0 1-1 3.37s3.5-.59 5.11.43l-.73 1.75a6.6 6.6 0 0 1 4.1 1.76l-3.83 3.04s6.14 2.19 7.31 3.95c0 0-3.94 2.77-4.53 2.63-.58-.3-.14 1.17.88 2.48l-3.36-.14s1.31 3.07 1.16 4.24-5.55.87-5.55.87"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path272"
        d="M710.78 206.17s1-5.84-.58-7.31c0 0 3.8 3.22 6.28 3.37 2.64.15-.14-4.83-.14-4.83a37 37 0 0 0 5.41 2.34c1.75.3-1.32-3.65-1.32-3.65s1-1.91 3.94-.15"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path274"
        d="M656.4 172.7a3.24 3.24 0 0 0-.44-2c-3.5-4.83 3.66 3.66 5.12 3.95"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path276"
        d="M667.81 174.6s2.33 1.75 3.5 1.75-.73-4.68-1.31-5.26 4.24 1.9 6 2.19c1.76.44 2-2.92 2-2.92s5.41.87 6.58 1.61"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path278"
        d="M647 170.94s-.43-3.07-.58-4.39"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path280"
        d="M610.21 185.56c-.29-.58 1.47-3.21.73-4.38a4.9 4.9 0 0 0 2 1c.87.15.58-3.36.58-3.36l1.17.43s3.07-1.46 2.48-2.63 2 1.75 2 1.75 1.75-1.75 1.75-2.48 2.05 1.75 2.05 1.75 2.19-.44 2.19-1.75 2.78.44 2.78.44 1.16-1.61 1-2.64"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path282"
        d="M599.54 188.34s1.31-3.07-.15-5.12"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path284"
        d="M564 198c.15-1.46.88-5.41.15-6s1.47.59 3.22 1"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path286"
        d="M581.27 192.43c-1.76-1.75-2.78-2.63-5.56-2.63"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path288"
        d="M551.59 202.22c.44-1 1.17-3.5.73-5"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path292"
        d="m589.74 274.74 1.9-3.94 2.34 5.55 1.61-3.51.58 1.17 3.51-3.8 3.8 5.7"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path294"
        d="m664.44 251.2 2.49-5 3.36 1.75 1.61-4.82 3.51 2.19 2-4.53 3.95 1 .29-3.21s2.78 1.6 3.51 2.77 0-3.94 0-3.94l3.21 1.46s2.34-2.34-.87-3.66"
        className="Belize_svg__cls-22"
      />
      <path
        id="Belize_svg__path296"
        d="m674.24 244.92 3.5 5.12 2.93-3.37 2.63 5.7 3.36-2 4.82 2.78 2.19-1.76 5.26 3.8 2.2-4.53 4.68 1.47 3.06-5.26"
        className="Belize_svg__cls-22"
      />
      <g id="Belize_svg__g3007">
        <path
          id="Belize_svg__path254"
          d="m550.86 217.43 2.34 4.82c-1.9.15-4.68 2.34-4.68 2.34.44 8-1.61 7.61-1.61 7.61.59 1.46-.25 5-.25 5l11.51-7.78.15-.73 13.73-6.44.44.73 4-2.19 2.78 7.31-2.78 1.61c.15 2.48 3.07 6.87 3.07 6.87l3.65-.88 4-1.46s.15 2.19.15 1.46 4.24-2 4.24-2 .29 2 1.89 1.9 4.1-3.66 4.1-3.66l3.07 1.17a7.8 7.8 0 0 0 1-3.07c0-1.17 3.8-1.17 3.8-1.17l2.63 1.9 3.36-2.78s1 2.05 2.34 2.35c1.46.14 5.12 2.92 5.12 2.33a25 25 0 0 1 1.61-3.8l6.13 2.49a34 34 0 0 0-2.92-6.14s2.92-2.34 2.34-2.93-2.34 0-2.34 0l1.75-3.5h-2.33l.73-.88-3.88-1.65 2-4.09c-.88-1-11-3.07-11-3.07l2.33-2.19s-1.17-1.75-2.33-1.75-7.31.14-7.61-.44c-.14-.59-2.63-2.78-2.63-2.78l-3.8 2.78-1.9-.59-3.36 1.32s0 1.9-.44.58c-.44-1.17-3.51-4.24-3.51-4.24-.58 1-4.53 5.7-4.53 5.7s-.58 2.49-1.9.73-4.53-3.65-5-2.19-3.21 3.07-3.8 2.63-3.8-.14-4.09.73c-.15 1-3.66 3.66-3.66 3.66-2.19-1-5.7-.44-5.7-.44a8.7 8.7 0 0 0-1.17 2.92l-7.16 1.9"
          className="Belize_svg__cls-7"
        />
        <path
          id="Belize_svg__path266"
          d="m646.46 274.3.29-2.33 8.34-3.22 7-2.63 3.51-1.61 1.61 2.78 2-4 3.22 4.09 1.75-2.19 2.78 2h2.34l.43 3.08s5.85.43 6.29-.73 1.17 3.95 1.17 3.95l5.56.72 1.16 6.29s2.78 5.85 1.9 8.19-3.07-3.07-3.07-3.07a40 40 0 0 1-.87 5.55c-.29 1.17-7.46-1.17-7.46-1.17l-3.07 5.85s-3.21-4.68-4.67-4.68c-1.61 0-1.61 4.68-1.61 4.68s-4.68-5.55-6.73-7.89-2.34 2.34-2.34 2.34-3.06-5.12-3.06-6.58c0-1.61-2.78-3.22-2.78-3.22s-4.82 5.56-8.33 7c0 0 .44-3.51-.73-4.68s-4.68 2.34-4.68 2.34a14.38 14.38 0 0 1 0-11"
          className="Belize_svg__cls-7"
        />
        <g id="Belize_svg__g2997">
          <path
            id="Belize_svg__path238"
            d="m648.8 207.93 1.9 1.17 2 .29s2.19 2 4.68 4c1.89 1.61 1.89-.29 1.89-.29l1.17 4.67h1.61a46 46 0 0 0-.44 4.68l2.78-2.34 3.21 2.78s2.34-2 3.51-2a1.84 1.84 0 0 0 1.61-1.17h.14c-7.74-3.66-11.1-7.46-8.77-11.41 3.8-6.57 10.09-2.34 12.14-1.46L704.64 222s1.9-1.16.58-2.33a5.86 5.86 0 0 1-1.61-5.27c.44-1.16 3.65 3.66 5.27 4.1s5.26 1 5.26 1L714 218s4.68 3.22 4.68 4.83c0 0 3.36-1.75 2.19-2.78s5.12 2.49 5.56 4.39l1.31-1.47c-.14 0 .44-4.68 2-6.87a7.17 7.17 0 0 1 3.07-2.78s1.46-2.48 2.92-2.48a7.4 7.4 0 0 1-2-2.2 5.4 5.4 0 0 0-2.49-1.75 23 23 0 0 0 2.78-2.34 2.3 2.3 0 0 1 1.17-1s-3.36-2.78-5.7-3.22c0 0-.15-3.8-3.07-4.09h1.31s-2.19-4.09-4.23-4.24a5.3 5.3 0 0 1 2.48-1.6s-2.48-3.22-5.85-2l2.2-.88s-1.61-1.75-6.58-.88a3.78 3.78 0 0 0-1.75-3.65s-1.9.15-1.9 1.61a6.6 6.6 0 0 0-4-1.61c-2.19 0 .73 2.34.73 2.34s-2.92-1.9-4.68-1.32c0 0 .73-3.36-1.17-5.11 0 0-2.19 1.16-2.77 2.63l-.59-1.47s-2.92 3.52-3.51 2.49a12.5 12.5 0 0 1-.87-3.07l-1.76 2.63.3-3.65s-2.05 1.17-3.66 5c0 0-2 1.9-2 3.65 0 0-1.6-1.89-3.06 0-1.32 2 0 1.61 0 1.61a3.64 3.64 0 0 1-2 1l.29 1.16a5.32 5.32 0 0 0-3.36-.58l.73 1.75s-3.8.29-3.95-2.77c0 0-3.21-1.61-3.65.14 0 0-2.63 0-2.34 1.46a3.53 3.53 0 0 0-2.05-1.75c-1.46-.44-.29 1.9-.29 1.9a4.43 4.43 0 0 0-2.19-2.64c-1.76-.72-.3 1.61-.3 1.61s-2.33-1.9-1.16-3.36c0 0-2.2 2-1.17 3.95l-1.47 1s-.72-.72-.58-1.31S659 192 659 192s-1.6-3.8 0-3.8a3.3 3.3 0 0 0-3.21 3.22 17.4 17.4 0 0 0-3.65.72c-1.9.59 1.31 1.91 1.31 1.91s-2.77-.59-4.38.29l1 1.31s-1-1-3.51-.14c-2.63.73.59 1.17.59 1.17s-2.64-.59-4 0 .44 1.6.44 1.6-4.83-1.46-5.56-1 1.61 2.78 1.61 2.78-2.92.73-3.51 2.92l2.63 1a4.37 4.37 0 0 0-1.75 1.75c-.58 1.17 1.75.59 1.75.59s-3.21 2.33-3.21 4.09a4.32 4.32 0 0 0 4.09-1c1.76-1.75.59-1.16.59-1.16l.14 1.16s3.36-1.46 3.36-2.33-.58 1.16-.58 1.16l3.36-1.75 2.19 1.46"
            className="Belize_svg__cls-23"
          />
          <path
            id="Belize_svg__path242"
            d="m646 162.31-.73-3.21a36 36 0 0 1 7.46.87l1.31-3.06a23 23 0 0 1 4.68 2.77s6.87-1.75 8.92-1l-.88 3.22s6.29.43 8.33 1.16-.87 2.35-.87 2.35 7.16.87 10.23 2.77 3.21 3.51 1.61 4l3.94 6.22s-6.28.73-7.45 2.34-2.34-1.61-2.34-1.61l-1.17 4.38a36 36 0 0 1-5.54-3.51s-1.9 2.34-.73 3.51c0 0-5.11 0-7.16-4.68 0 0-3.07-.43-3.07 2.34 0 0-3.51-3.94-4.39-5.12l-3.94 1.18a6.17 6.17 0 0 0-2.63-2c-1.61-.3-.44 2-.44 2l-2.34-2.78-2.34-2.78s-2.05 2.78-3.65 2.78-1.47-1.91-1.17-3.22-4.68-1.46-5.12 0c0 0-1.46-5.84 3.94-7.46l5.56-1.46"
            className="Belize_svg__cls-23"
          />
          <path
            id="Belize_svg__path246"
            d="m617.66 182.34-2.48 2.34-1.46-.73-2.93 3.22-.58-1.76-1.61 2.59-2.49-.87-1.16 1.9-2.2-.44a13.6 13.6 0 0 1-3.07 2.19c-1.17.29 0-2.63 0-2.63s-1.75 2.92-3.07 2.92c-1.16 0-.73-1.31-.73-1.31s-.72 1.89-2.19 2.19c-1.31.14-.73-1.46-.73-1.46l-2.34 1.75v-1.46l-1-.44s.58-2.63 1.61-3.21-1-2.34-1-2.34l1-1.17s-2.93-.73-2.93-.15c0 0 1.47-3.95 3.51-4.68l.15-4.38a8.6 8.6 0 0 1 3.95-1.9c2-.29-.59-3.36-.59-3.36l4.68-2.78.59-3.36 7.16.58 1.46-3.07 5.7 1.46 4.82-2.48 3.51 4.09 5.26.44s-.14 3.66-.14 4.68c0 0 6.28 1.9 7 2.48a40 40 0 0 0-1.17 4.54s4.39-1.17 5.71-.88c0 0 .44 4.09.58 5.12 0 0 7.46 2.34 7.89 3.66a9.8 9.8 0 0 0-2.48 2.33 56 56 0 0 1 .88 6l-.59.87s-2.19-2-3.95-2.33l-1.46 3.21-2.64-1.71-1.61.14s-1.32-1.6-2.34-1.6l-.15-1.47-2 1.31s-3.21-1.6-3.21-2.63c0-.87.14-2.33.14-2.33l-3.36 2.33a6.7 6.7 0 0 1-.58-2.48c.14-.73-1.32 1.76-1.32 1.76s-1.75-3.08-1.61-3.66l-.44 2.49s-3.51-1.18-3.94-3.51"
            className="Belize_svg__cls-7"
          />
          <path
            id="Belize_svg__path250"
            d="M570.89 196.67s6.14 3.07 6.14 4.53c0 0 1.6-3.21.73-4.38l2.34 1.61s.73-2.93-.15-3.81 5.26-3.21 6.58.74c0 0 3.65-3.8-3.37-7 0 0-.58-2.34 3.66-1.75a3.35 3.35 0 0 0-1.32-2.05h4.1a14 14 0 0 0-6.14-2.63c-3.51-.59-1.32-1-1.46-1.76a12.3 12.3 0 0 1 0-3.36 5.07 5.07 0 0 0-3.66.15l.59-.74s-3.81-1-5.27-.14a3.13 3.13 0 0 1-1.9-3.22 3.43 3.43 0 0 0-3.21 2.2 7.24 7.24 0 0 1-4.83 2.34 11.5 11.5 0 0 1-2.63-1.18l-1.75 4.1-2.78-.15.88 1.61s-4.24-1.17-5.56 0c0 0 1.61 2.49 1.17 4.24 0 0-3.36.58-4.09 1.17s1.17 1 1.17 1-3.8 1.17-4.68 2.34l1.46 1.17-1.75 2.3 1.46.29s-2.48.73-3.8 1.17 2.34.73 2.34.73l-1.75 1.61 2.78.43s-2.2 5.85-2.34 8.63c0 0 3.36-4.09 4.24-4.38l-2.63 4.38s5-1.6 5.41-2.34.87-1.61.87-1.61l.15 2.49a21 21 0 0 0 2.19-3.66c.15-1 3.07 1 3.07 1v-1.47s2.63.88 3.51 1 1.17-.44 1.17-.44l2.48-.3-.43 2.05a6.74 6.74 0 0 0 2.19-2.34c.29-1.17 1.17 0 1.17 0l1.17-4.68h2.48"
            className="Belize_svg__cls-7"
          />
          <path
            id="Belize_svg__path258"
            d="M616.93 276.65v-3.07l3.51 2.34 2-2.34 2.63 2.63 1.61-2.63v4.23l3.95-3.5 2.33 1V272s2.78-4.68 1.61-5.56l2.34-.73a63 63 0 0 0-5.12-4.68 5.17 5.17 0 0 0-2.77-.88s-3.07-3.07-5.12-3.95a2.67 2.67 0 0 0-3.07.88s-1.17-2.34-3.21-2.78-1.9 1.61-1.9 1.61a11 11 0 0 0-3.36-2.19c-1.9-.88-.59 1.75-.59 1.75l-6.72-2.05.87 2.05-6.28-1.6.73 2.33-4.39-.73-.29 1.9-6.28 1.17 1.17 2.05-4-1.61v2.78l-4.39 1.61 1.61 1.46s-5.85 2.78-7 3.65 2.34 2.35 2.34 2.35l-6.28-.88 2.34 2-5.56 5.12 3.95 2.78-2.34 3.51 5.85 1.17 2.34 3.95 4.82-.88v4.4l7-5.55s5.12 4.53 5.56 3.22a38 38 0 0 0 .29-5.12h2.49v-4l1.9 1.61 3.21-4.68 4.24 5.85 3.22-7.46 4.68.73"
            className="Belize_svg__cls-7"
          />
          <path
            id="Belize_svg__path262"
            d="m645.59 259-.73-6.72-2.05 2 .88-6.28-3.22-.58 1.17-6.15-2.34-1.17.58-2.92 2.34-1.75 4.24-4.09 9.8.14 1.16-3.21s5.85 0 7 1.17 4.83-2.64 4.83-2.64h1.46l1.61 3.07s5.11-.73 7.45.88l-2.78 3.8 5.12.44 3.5.73 3.66-1.17 4.67-.29 1.18 1.9h4.38l-1.46 1.75 5.7 2.63-1.17 1.17a20.4 20.4 0 0 0 5.55 1.17h1.17s1.17 2.78-.44 3.95c0 0 1.61 4 5.12 4.68s3.51.73 3.94 1.61a3.83 3.83 0 0 1-3.94 4.68v3.95s-3.07 2.77-3.51 3.94l-2.34-1.6s-2 2.33-2 3.94c0 0-5.11-6.72-7-7-2.05-.44-1.61 3.8-1.61 3.8l-5.56-4.68-.73 3.21-4.68-3.94-2.77 1.89-5-5.11-4.82.58 1.9-2.48H670l-1.17-5.12-.88 4.24-3.51-2.34-4.53 5a8.2 8.2 0 0 1-1.32-2.93c-.73-2.34-2.77 2.78-2.77 2.78l-1.61-3.65-1.9 3.65s-1.61-2.78-3.21-3.65-3.07 4.38-3.51 6.72"
            className="Belize_svg__cls-7"
          />
          <path
            id="Belize_svg__path290"
            d="M582.58 233.08s2-3.52 1.32-5.12 4.23 2.78 4.23 2.78 1.17-3.51.44-5.12 5.85 4.68 5.85 4.68 1.17-5.41 0-6.58 6.43 1.61 6.43 1.61 3.07-.88 1.9-2.49 5.12 2.34 5.12 2.34 1.46-3.94 0-5.41 4.68 1.9 4.68 1.9l-2.34-4.24 5.11.29-.44-4.67"
            className="Belize_svg__cls-24"
          />
          <path
            id="Belize_svg__path298"
            d="m660.2 280.59 3.36-1.61 2.93 2.93 3.21-5.12 3.95 3.8.58-3.8 4.24 1.76.88-2.35 4.68 2.49.43-3.51 2.34.74.44-2.34"
            className="Belize_svg__cls-24"
          />
        </g>
      </g>
      <path
        id="Belize_svg__path300"
        d="M638.13 542.44c32.89.3 52.33-11.7 63-18.86 25-16.81 30.25-17.4 34.35-17.11 4.68.45 11.54 2 11.84 6.15.44 6.28-9.8 9.06-15.64 9.06-6 0-21.64-3.95-21.64-3.95l-4.1 2.78c2.78 1.32 34.8 12 40.94 1.9s12.57-26.75 12.57-26.75a26.8 26.8 0 0 0-22.66-12.58c-15.79 0-31.14 9.95-41.37 17.4s-23.1 17.69-57.31 17.69-47.21-10.23-57.44-17.69-25.58-17.4-41.22-17.4a26.89 26.89 0 0 0-22.81 12.58s6.29 16.52 12.57 26.75 38.16-.58 40.93-1.9l-3.94-2.78s-15.79 3.95-21.64 3.95-16.23-2.78-15.79-9.06c.3-4.1 7.17-5.71 11.84-6.15 4.1-.29 9.36.3 34.35 17.11 10.68 7.16 30.12 18.71 63.16 18.86"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path302"
        d="M638.13 542.44c32.89.3 52.33-11.7 63-18.86 25-16.81 30.26-17.39 34.35-17.1 4.68.44 11.55 2 11.84 6.14.44 6.28-9.79 9.06-15.64 9.06s-21.63-3.94-21.63-3.94l-4.1 2.78c2.78 1.31 34.79 12 40.93 1.89s12.57-26.75 12.57-26.75a26.8 26.8 0 0 0-22.66-12.58c-15.78 0-31.13 9.95-41.37 17.4s-23.09 17.69-57.3 17.69-47.21-10.23-57.45-17.69-25.58-17.4-41.22-17.4a26.89 26.89 0 0 0-22.8 12.58s6.28 16.52 12.57 26.75c6.14 10.09 38.16-.58 40.93-1.89l-3.94-2.78s-15.79 3.94-21.64 3.94-16.22-2.77-15.79-9.06c.3-4.1 7.17-5.7 11.84-6.14 4.1-.29 9.36.29 34.36 17.1 10.67 7.17 30.11 18.72 63.15 18.86Z"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path304"
        d="M566.06 517.73s-15.64 3.95-21.49 3.95-16.22-2.77-15.78-9.06c.29-4.1 7.16-5.7 11.84-6.14 3.51-.3 8 .14 25.43 11.25"
        className="Belize_svg__cls-25"
      />
      <path
        id="Belize_svg__path306"
        d="M566.06 517.73s-15.64 3.95-21.48 3.95c-6 0-16.23-2.78-15.79-9.06.29-4.1 7.16-5.71 11.84-6.14 3.5-.3 8 .14 25.43 11.25Z"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path308"
        d="M710.05 517.73s15.64 3.95 21.64 3.95c5.84 0 16.08-2.77 15.64-9.06-.29-4.1-7.16-5.7-11.84-6.14-3.51-.3-8 .14-25.44 11.25"
        className="Belize_svg__cls-25"
      />
      <path
        id="Belize_svg__path310"
        d="M710.05 517.73s15.64 3.95 21.63 3.95 16.08-2.78 15.65-9.06c-.3-4.1-7.16-5.71-11.84-6.14-3.51-.3-8 .15-25.44 11.25Z"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path312"
        d="M585.21 503.85c-1.61-1.17-3.21-2.35-4.68-3.51-2.19-1.61-4.38-3.07-6.72-4.68 0 0 3.21-4.09 4.82-4.53 2.48-.88 5.55.43 6.14 5.7.44 4.09.44 7 .44 7"
        className="Belize_svg__cls-25"
      />
      <path
        id="Belize_svg__path314"
        d="M585.21 503.85c-1.61-1.17-3.21-2.35-4.68-3.51-2.19-1.61-4.38-3.07-6.72-4.68 0 0 3.21-4.09 4.82-4.53 2.48-.88 5.55.43 6.14 5.7.44 4.09.44 7 .44 7Z"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path316"
        d="M585.21 503.7s0-2.78-.44-6.87c-.58-5.26-3.65-6.58-6.14-5.85-1.6.58-4.82 4.53-4.82 4.53v.15a46 46 0 0 0-4.53-2.78c1.17-1.31 7.89-5.41 9.5-5.41s21.64 8.19 28.07 13.74c1.6 1.47 2.78 10.53 1.89 13.45a69.3 69.3 0 0 1-23.53-10.82z"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path318"
        d="M585.21 503.7s0-2.78-.44-6.87c-.58-5.26-3.65-6.58-6.14-5.85-1.61.59-4.82 4.53-4.82 4.53v.15a49 49 0 0 0-4.53-2.78c1.17-1.31 7.89-5.41 9.5-5.41s21.63 8.19 28.07 13.75c1.6 1.46 2.77 10.52 1.89 13.45a69.7 69.7 0 0 1-23.53-10.82"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path320"
        d="M575.56 524.17s-4.38 4.38-4.53 12.42c-.15 9.51 6.73 9.95 11.26 9.8 5.41-.15 9.79-3.22 9.65-13.31a109 109 0 0 1-16.38-8.91"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path322"
        d="M575.56 524.17s-4.38 4.38-4.53 12.42c-.14 9.5 6.73 9.94 11.26 9.8 5.41-.15 9.79-3.22 9.65-13.31a108.4 108.4 0 0 1-16.37-8.92Z"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path324"
        d="M579.22 526.36a17.35 17.35 0 0 0-4.1 9.65c-.29 4.68 2.78 7.31 6.73 7 5.55-.28 7.75-7.89 5.12-12.13l-7.75-4.53"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path326"
        d="M579.22 526.36a17.3 17.3 0 0 0-4.09 9.65c-.3 4.68 2.77 7.31 6.72 7 5.55-.28 7.75-7.89 5.12-12.13l-7.75-4.53"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path328"
        d="M574.54 545.07a13.1 13.1 0 0 0 7.75 1.32c5.41-.15 9.79-3.22 9.65-13.3a90.5 90.5 0 0 0 17.68 6 12.35 12.35 0 0 1 2 6.58c.44 3.66-2 7.6-5 7.31 0 0-15.35-2.78-24.7-4.38-5.41-1-7.45-3.51-7.45-3.51"
        className="Belize_svg__cls-25"
      />
      <path
        id="Belize_svg__path330"
        d="M574.54 545.08a13.2 13.2 0 0 0 7.75 1.31c5.41-.15 9.79-3.22 9.65-13.3a90.4 90.4 0 0 0 17.69 6 12.4 12.4 0 0 1 2 6.58c.44 3.66-2 7.6-5 7.31 0 0-15.36-2.78-24.71-4.38-5.41-1-7.46-3.51-7.46-3.51Z"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path332"
        d="M608.45 541.71s4.83 3.51-1 7.89"
        className="Belize_svg__cls-26"
      />
      <path
        id="Belize_svg__path334"
        d="m594 539.37 14.77 6.44"
        className="Belize_svg__cls-26"
      />
      <path
        id="Belize_svg__path336"
        d="m596.47 542.74 10.81 4.67"
        className="Belize_svg__cls-26"
      />
      <path
        id="Belize_svg__path338"
        d="m600.27 540 8.18 3.66"
        className="Belize_svg__cls-26"
      />
      <path
        id="Belize_svg__path340"
        d="m690.9 503.85 4.82-3.51c2-1.61 4.24-3.07 6.58-4.68 0 0-3.21-4.09-4.82-4.53-2.49-.88-5.41.43-6 5.7-.44 4.09-.59 7-.59 7"
        className="Belize_svg__cls-25"
      />
      <path
        id="Belize_svg__path342"
        d="m690.9 503.85 4.82-3.51c2-1.61 4.24-3.07 6.58-4.68 0 0-3.21-4.09-4.82-4.53-2.49-.88-5.41.43-6 5.7-.44 4.09-.59 7-.59 7Z"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path344"
        d="M690.9 503.7s.15-2.78.59-6.87c.58-5.26 3.5-6.58 6-5.85 1.61.58 4.82 4.53 4.82 4.53v.15a46 46 0 0 1 4.53-2.78c-1.17-1.31-7.89-5.41-9.35-5.41s-21.78 8.19-28.21 13.74c-1.47 1.47-2.64 10.53-1.9 13.45a68 68 0 0 0 23.53-10.82v-.14"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path346"
        d="M690.9 503.7s.15-2.78.59-6.87c.58-5.26 3.5-6.58 6-5.85 1.61.59 4.82 4.53 4.82 4.53v.15a48 48 0 0 1 4.53-2.78c-1.17-1.31-7.89-5.41-9.35-5.41s-21.78 8.19-28.21 13.75c-1.47 1.46-2.64 10.52-1.9 13.45a68.3 68.3 0 0 0 23.53-10.82"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path348"
        d="M700.55 524.17s4.39 4.38 4.53 12.42c.29 9.51-6.73 9.95-11.25 9.8-5.41-.15-9.65-3.22-9.65-13.31a109 109 0 0 0 16.37-8.91"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path350"
        d="M700.55 524.17s4.39 4.38 4.53 12.42c.29 9.5-6.73 9.94-11.26 9.8-5.4-.15-9.64-3.22-9.64-13.31a109 109 0 0 0 16.37-8.92Z"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path352"
        d="M696.89 526.36A17.35 17.35 0 0 1 701 536c.44 4.68-2.78 7.31-6.73 7-5.55-.28-7.6-7.89-5.11-12.13l7.74-4.53"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path354"
        d="M696.89 526.36A17.35 17.35 0 0 1 701 536c.44 4.68-2.78 7.31-6.73 7-5.55-.28-7.6-7.89-5.11-12.13l7.74-4.53"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path356"
        d="M701.57 545.07a13.1 13.1 0 0 1-7.75 1.32c-5.41-.15-9.65-3.22-9.65-13.3a91 91 0 0 1-17.54 6 11.7 11.7 0 0 0-2.19 6.58c-.29 3.66 2.19 7.6 5 7.31 0 0 15.49-2.78 24.7-4.38 5.41-1 7.46-3.51 7.46-3.51"
        className="Belize_svg__cls-25"
      />
      <path
        id="Belize_svg__path358"
        d="M701.57 545.08a13.2 13.2 0 0 1-7.75 1.31c-5.41-.15-9.64-3.22-9.64-13.3a91.4 91.4 0 0 1-17.54 6 11.73 11.73 0 0 0-2.2 6.58c-.29 3.66 2.2 7.6 5 7.31 0 0 15.5-2.78 24.71-4.38 5.41-1 7.45-3.51 7.45-3.51Z"
        className="Belize_svg__cls-14"
      />
      <path
        id="Belize_svg__path360"
        d="M667.81 541.71s-5 3.51.87 7.89"
        className="Belize_svg__cls-26"
      />
      <path
        id="Belize_svg__path362"
        d="m682.13 539.37-14.76 6.44"
        className="Belize_svg__cls-26"
      />
      <path
        id="Belize_svg__path364"
        d="M679.79 542.74 669 547.41"
        className="Belize_svg__cls-26"
      />
      <path
        id="Belize_svg__path366"
        d="m675.84 540-8 3.66"
        className="Belize_svg__cls-26"
      />
      <path
        id="Belize_svg__path390"
        d="M618.25 530.74c-.15 1.17-.3 3.37-.3 3.51a1.92 1.92 0 0 0 1.17.44 1.66 1.66 0 0 0 2.2-1.47 3 3 0 0 0-1.32-2.77 3.5 3.5 0 0 0-1.61-.59z"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path396"
        d="M632 530a3.7 3.7 0 0 0 1.75-.14 2.45 2.45 0 0 0 1.76-2.34c0-.88-.74-2-3.22-2.2a36 36 0 0 0-.29 4.24z"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path416"
        d="m472.65 283.37 106.42-55.12c.51-2.84-1.11-5.08-2.78-7.31-30.43 14.93-58.12 33-91.94 44-1.9.73-18.71 7.75-27.34 18.13a11.47 11.47 0 0 0-2.93 6.79s.21 4.64 1.18 5.9a4 4 0 0 0 1.84 2.29 2.6 2.6 0 0 0 1.68.24c.77.15 2 .14 3.79-2.94a78.3 78.3 0 0 1 10.08-12Z"
        style={{
          strokeWidth: ".51px",
          stroke: "#000",
          fill: "#730000",
        }}
      />
      <path
        id="Belize_svg__path418"
        d="m558.32 228.84 13.73-6.58s4.68 3.51 4.24 7.45c0 0 1 9.06 12.28 15.35a38.78 38.78 0 0 1-26.16 12.87s1.31-16.23-2.2-20.76c2-.64-.49-6.2-1.89-8.33"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path420"
        d="m558.32 228.84 13.73-6.58s4.68 3.51 4.24 7.46c0 0 1 9.06 12.28 15.34a38.78 38.78 0 0 1-26.16 12.87s1.91-15.63-1.6-20.16c0 0-.58.56-.33-.22.5-1.52-.33-6.73-2.47-7.88-.26-.14.17-.57.31-.83Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path422"
        d="M574.39 230.74c-2.92 1.17-12.42 6.14-12.42 6.14"
        className="Belize_svg__cls-28"
      />
      <path
        id="Belize_svg__path424"
        d="M569.86 233.66c-.14 1.47-1 5 0 7.46"
        className="Belize_svg__cls-28"
      />
      <path
        id="Belize_svg__path426"
        d="M568.55 234.1c0 .73-1.17 3.36.15 6.87"
        className="Belize_svg__cls-28"
      />
      <path
        id="Belize_svg__path428"
        d="M567.09 234.83c-.29 1.47-.59 2.34 0 3.95"
        className="Belize_svg__cls-28"
      />
      <path
        id="Belize_svg__path430"
        d="M565.92 235.71a4.15 4.15 0 0 0 0 1.9"
        className="Belize_svg__cls-28"
      />
      <path
        id="Belize_svg__path432"
        d="M574.54 235.27a56.2 56.2 0 0 0 6.73 9.79"
        className="Belize_svg__cls-28"
      />
      <path
        id="Belize_svg__path434"
        d="M575.12 238.05a29.5 29.5 0 0 0 4.24 7"
        className="Belize_svg__cls-28"
      />
      <path
        id="Belize_svg__path436"
        d="M574.83 240.53a25.5 25.5 0 0 0 2.93 5.56"
        className="Belize_svg__cls-28"
      />
      <path
        id="Belize_svg__path438"
        d="M574.83 243.46a9.4 9.4 0 0 0 1.76 3.65"
        className="Belize_svg__cls-28"
      />
      <path
        id="Belize_svg__path440"
        d="M520.31 221.53a51 51 0 0 0-.88 13.3c.15 3.07 1.61 6.58 2.92 9.65.88 2.05 2 4.38 3.95 5.55 5.12 3.37 9.94-.29 12.72-1.46a9.2 9.2 0 0 0 4-3.51c.73-1.75.43-3.06 1.16-6.43a3.7 3.7 0 0 0 1-.58c1.31-2.19 1-2.92 1.75-4.39s-.88-3.07-2.63-2.77c.87-2.78 1.46-5.86.58-8.49-.29-.87.73-3.21-.87-2.63-6.58 2.63-14.33 2-21.35 2-.58 0-1-1.17-1.9-1-.14 0-.29.43-.44.72"
        style={{
          fill: "url(#Belize_svg__radial-gradient)",
        }}
      />
      <path
        id="Belize_svg__path442"
        d="M520.31 221.53a51 51 0 0 0-.88 13.3c.15 3.07 1.61 6.58 2.92 9.65.88 2.05 2 4.39 3.95 5.56 5.12 3.36 9.94-.3 12.72-1.47a9.15 9.15 0 0 0 4-3.51c.73-1.75.43-3.06 1.16-6.43-.14.15 1.17-.58 1-.58 1.31-2.19 1-2.92 1.75-4.39s-.88-3.07-2.63-2.77c.87-2.78 1.46-5.86.58-8.49-.29-.87.73-3.21-.87-2.63-6.58 2.63-14.33 2-21.35 2-.58 0-1-1.17-1.9-1-.14 0-.29.44-.44.73Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path444"
        d="M519.72 224c-.73-.58-.87-1.9 0-4.68 1-2.77 0-2.92 1.61-4.53s1.17-2.34 2.19-2.77 4.1-.3 5.12-.74a13 13 0 0 1 4.23-1.31c2.2-.44 6.58-.3 8.63.87s3.66 4.09 5 5.12 2.34 5.26 2 11.4-1.61 5-1.61 5-1.76-2.92-3.22-.44a29 29 0 0 1 .3-4.09c.14-1.75-.88-5 .14-7.16 0 0-8.33 2.63-12.57 2.48s-10.52-1.31-10.81-2.34l-1 3.22"
      />
      <path
        id="Belize_svg__path446"
        d="M519.72 224c-.73-.58-.87-1.9 0-4.68s0-2.92 1.61-4.53 1.17-2.34 2.19-2.77 4.1-.3 5.12-.73a12.8 12.8 0 0 1 4.23-1.32c2.2-.44 6.58-.3 8.63.87s3.66 4.1 5 5.12c1.47 1 2.34 5.26 2 11.41s-1.61 5-1.61 5-1.75-2.92-3.21-.44a30 30 0 0 1 .29-4.09c.14-1.75-.88-5 .14-7.16 0 0-8.33 2.63-12.57 2.48s-10.52-1.32-10.81-2.34l-1 3.22"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path448"
        d="M524 218.9c-.44-.88-2.63-4.1-.87-5.12 1.6-.88.58 3.95 2.78 5 0 0-.73 2.19-1.9.14"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path450"
        d="M524 218.9c-.44-.88-2.63-4.1-.87-5.12s.58 3.94 2.78 5c0 0-.73 2.19-1.9.15Z"
        style={{
          fill: "none",
          strokeWidth: ".51px",
          stroke: "#fff",
        }}
      />
      <path
        id="Belize_svg__path452"
        d="M545.16 238.05c-.29.87-1 .29-1 .58-.58 3.07-.43 4.68-1.16 6.43-1 2.05-4.68 3.95-4.53 3.8-2.93.88-6.43 4.39-12.13 1.17a3.6 3.6 0 0 1-.88-.73 7.85 7.85 0 0 1-4.24 8.92c-2.05 1-22.66 11.84-22.66 11.84a13.37 13.37 0 0 0-.73 9.36c.15.88.44 1.61-.44 2.19a18.45 18.45 0 0 0-5.26 8.63s-4.39-1.9-4.39-4.68 1.76-4.24 2.2-6.29c.43-1.9 1.6-6.72 1-8.19s-3.22-2.19-4.24-2.77-2.05.58-1.9 1.9 1.17 1.16 2.34 1.46a12 12 0 0 0-1.17 4.82l-13.3 6.87s1.75 2.34 2.34 3.36 1.9 14.62 5.84 21.06 5.27 4.68 6.14 5.55 1.9 1.9 2.93 2.78c1 .73 5.55 1.17 6.43-1.31a12.73 12.73 0 0 1 4.53-6c2.78-2.19 8.48-7.75 8.48-10.24 0 0 3.36 9.65 2.92 17.7a159 159 0 0 0 23.23 2.74c11 .15 16.52-2.19 16.52-2.19s1.46-9.21.88-11c0 0 1.75 6 3.07 8 1.46 2.48 3.06 7.6 6.28 7.89s5.7-3.07 6-4.38v-9.65h12.43c0-.15 5.55 2 7.31 1.75s2.77-1.75 1.16-2.34l-.58-.43a10.2 10.2 0 0 1 3.66 1h6.13c.15-.15 0-.59-.15-.73-.43-1.76-3.65-3.22-6-4.24a30.6 30.6 0 0 0-6.72-2.05c-2.78-.29-4.68-.14-8.92.59-1.17.14-5.55.58-8.33 1.9-.73.44-1.47.58-1.17-1.32.44-2.05.29-11.26-1.9-15.21a10.76 10.76 0 0 1-1.17-7.45 120 120 0 0 0 0-12.72c-.44-2.34-3.65-5.56-9.5-6.29-5.55-.73-10.23-5.26-10.52-8.92-.44-3.65.59-10.38 1.17-13.3"
        style={{
          fill: "url(#Belize_svg__radial-gradient-2)",
        }}
      />
      <path
        id="Belize_svg__path454"
        d="M545.16 238.05c-.29.87-1 .29-1 .58-.58 3.08-.43 4.68-1.16 6.44-1 2-4.68 4-4.53 3.8-2.93.88-6.44 4.39-12.13 1.17a3.8 3.8 0 0 1-.88-.73 7.84 7.84 0 0 1-4.24 8.92c-2.05 1-22.66 11.84-22.66 11.84a13.35 13.35 0 0 0-.73 9.35c.15.88.44 1.61-.44 2.2a18.4 18.4 0 0 0-5.26 8.62s-4.38-1.9-4.38-4.67 1.75-4.24 2.19-6.29c.44-1.9 1.61-6.73 1-8.19s-3.21-2.2-4.23-2.78-2.05.58-1.9 1.9c.14 1.47 1.17 1.17 2.33 1.47a12.1 12.1 0 0 0-1.14 4.82l-13.3 6.87s1.75 2.34 2.33 3.36 1.9 14.62 5.85 21.06 5.26 4.67 6.14 5.55 1.9 1.9 2.92 2.78 5.56 1.16 6.44-1.32a12.76 12.76 0 0 1 4.53-6c2.77-2.2 8.47-7.75 8.47-10.24 0 0 3.37 9.65 2.92 17.69a158 158 0 0 0 23.22 2.75c11 .15 16.51-2.19 16.51-2.19s1.47-9.21.88-11c0 0 1.75 6 3.07 8.05 1.46 2.48 3.07 7.6 6.28 7.89 3.07.14 5.7-3.07 6-4.38v-9.65h12.42c0-.15 5.56 2 7.31 1.75s2.78-1.75 1.17-2.34l-.58-.44a10 10 0 0 1 3.65 1h6.14c.15-.15 0-.59-.14-.74-.44-1.75-3.66-3.21-6-4.24a31 31 0 0 0-6.73-2c-2.77-.29-4.67-.15-8.91.58-1.17.15-5.55.59-8.33 1.9-.73.45-1.47.59-1.17-1.31.44-2.05.29-11.26-1.9-15.21a10.76 10.76 0 0 1-1.17-7.45 120 120 0 0 0 0-12.72c-.44-2.34-3.65-5.55-9.5-6.28-5.55-.74-10.23-5.27-10.52-8.92-.44-3.66.58-10.39 1.17-13.31Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path456"
        d="M597.05 307.79a5.4 5.4 0 0 0-2.63-1.9l-2.92-1.46c-1-.59-2.49-.73-3.37-1.32"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path458"
        d="M588.72 306.91c-.44-.29-.73-.44-1.17-.73s-1-.15-1.46-.3c-.15 0-.15-.29-.29-.29a5 5 0 0 1-3.51-1.9"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path460"
        d="M492.1 290.24c1.31 1 4.52 3.22 4.82 7.46a15 15 0 0 0 1.46 4.38"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path462"
        d="M479.53 290.83c.73 2.48.87 10.67 8.62 19.3"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path464"
        d="M481 279.42c-.59 1.61-1.32 3.07.29 4.24"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path466"
        d="M502 299c1.61-3.66 6.58-3.37 6.58-6.87"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path468"
        d="M509.34 298.58c-.44-4.1-1.31-6.43-.58-12.43a52 52 0 0 0 0-11.7s2.34 11.26-5.26 13.46"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path470"
        d="M552.91 305.89a25.8 25.8 0 0 1 1.31-8.93c1.76-8-1.61-11.1-.29-13.15 1.17-1.75 2.92-5.41.58-12.72 0 0 4.1 15.06-6.43 15.06s-10.23-3.95-10.23-3.95"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path472"
        d="M508.76 274.45c0 3.07 0 12.14 13 12.14 3.94 0 8.19-2.78 10.52-4.68"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path474"
        d="M535.51 278a69 69 0 0 1-.44-9.94"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path476"
        d="M515.48 263.05a83 83 0 0 1 12.87-.88"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path478"
        d="M528.79 253.1c.73 3.22-.44 8.34 5.55 9.07"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path480"
        d="M540.63 251.93a30.5 30.5 0 0 0-1.17 6.29"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path482"
        d="M539.75 262.17c2.49 0 7.16-1.61 13-.44"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path484"
        d="M554.81 294.48c1.17 2.34 1.46 5.41 5.11 8.92"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path486"
        d="M514.31 287c.3 1.9-2.48 8.18-.44 13.3a17.7 17.7 0 0 1-1.61 15.94"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path488"
        d="M521.77 288.93c-1.17 2.33-3.21 4.68-3.65 6.72"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path490"
        d="M527.62 293.31c0 1.47-4 12.57-6.73 15.65 0 0 5.12 2 4.39 9.5"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path492"
        d="M535.8 288.2c0 1.9-.29 2.34 1.32 3.5"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path494"
        d="M540.63 291.7c1.9 2.34 7.89 9.36 6.58 13.31"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path496"
        d="M536.68 295.65c.44 1.46.44 9.07-.87 12.87"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path498"
        d="M532.74 308.23a4.55 4.55 0 0 0-4.39 3.94"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path500"
        d="M540.92 307.79c1.61-.44 4.82 2.33 4.82 10.23"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path502"
        d="M568.25 303.25c-3.06.88-4.38 2.49-3.5 1.61a28 28 0 0 1-3.07 2.19"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path504"
        d="M528.79 230.88c-.73 2.49-1 3.07-1.32 4.54"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path506"
        d="M542.68 237.76c.29.87.58.43 1.31.58"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path508"
        d="M544.57 232.64c.73-.88 1.76-.44 1.17 1.75"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path510"
        d="M516.51 285.27a1.49 1.49 0 0 0 1.61-1.32c0-.58-.73-1.17-1.61-1.17s-1.51.59-1.51 1.22a1.45 1.45 0 0 0 1.47 1.32"
      />
      <path
        id="Belize_svg__path512"
        d="M516.51 285.27a1.49 1.49 0 0 0 1.61-1.31c0-.59-.73-1.18-1.61-1.18a1.26 1.26 0 1 0 0 2.49Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path514"
        d="M549.69 285.71a1.45 1.45 0 0 0 1.47-1.32 1.34 1.34 0 0 0-1.47-1.17c-.87 0-1.61.44-1.61 1.17a1.49 1.49 0 0 0 1.61 1.32"
      />
      <path
        id="Belize_svg__path516"
        d="M549.69 285.71a1.44 1.44 0 0 0 1.46-1.32 1.33 1.33 0 0 0-1.46-1.16c-.87 0-1.61.44-1.61 1.16a1.49 1.49 0 0 0 1.61 1.32Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path518"
        d="M520.31 227.37c1.31.44 2.63.88 4 1.47 2.19.88.87 4.09.29 6.14-.29 1-1.47 2.77-.88 3.65.73 1.17 1.61.44 2.93.58 0-.43.87-.73 1.17-.73 1-.14 1.6.73 2.33.45.88-.45.74-1.76.44-2.49"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path520"
        d="M530.4 230c.14-.57 1.46-1.16 2.19-1.31 3.07-.58 5.26-1 7.75.73"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path522"
        d="M520.16 230.88c1-.14 2.2-1.31 4.54.44 1.61 1-2.64 2.05-1.9 1.9-1.9.29-2.49-.73-2.63-1.61 0-.29-.3-.73 0-.73"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path524"
        d="M520.16 230.88c1-.14 2.19-1.31 4.53.44 1.61 1-2.63 2.05-1.9 1.9-1.89.29-2.48-.73-2.62-1.61 0-.29-.3-.73 0-.73Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path526"
        d="M522.21 232.64a1.13 1.13 0 0 0 1-1.17 1.19 1.19 0 0 0-1-1.32 1.2 1.2 0 0 0-1.17 1.32 1.16 1.16 0 0 0 1.17 1.17"
      />
      <path
        id="Belize_svg__path528"
        d="M522.21 232.64a1.13 1.13 0 0 0 1-1.17 1.19 1.19 0 0 0-1-1.32 1.2 1.2 0 0 0-1.17 1.32 1.16 1.16 0 0 0 1.17 1.17Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path530"
        d="M532.15 245.36c-2.49.58-5.12 1-7.46-.3"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path532"
        d="M524.69 243.9c1.17-.59 1.61-1.17 3.07-.44 0-.15 1.61-.59 1.61-.59a5.4 5.4 0 0 1 3.07 1.17"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path534"
        d="M524.55 244.48c1.31-.44 4.82-.44 7.75.15"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path536"
        d="M532.15 232.05c1-2.05 2.63-1.6 4.09-1.16a6.2 6.2 0 0 1 2.34.87c-1.6.29-1.17.44-2.34 1s-2.48.88-3.36.3c-.29-.3-1-.73-1-1.32a.3.3 0 0 0 .29.29"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path538"
        d="M532.15 232.05c1-2.05 2.63-1.6 4.09-1.17a6.4 6.4 0 0 1 2.34.88c-1.61.29-1.17.44-2.34 1s-2.48.88-3.36.3c-.29-.3-1-.73-1-1.32a.3.3 0 0 0 .29.29Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path540"
        d="M534.63 232.93c.59 0 1-.58 1-1.32a1.15 1.15 0 0 0-1.17-1.16 1 1 0 0 0-1 1.16 1.28 1.28 0 0 0 1.16 1.32"
      />
      <path
        id="Belize_svg__path542"
        d="M534.63 232.93c.59 0 1-.58 1-1.32a1.15 1.15 0 0 0-1.17-1.16 1 1 0 0 0-1 1.16 1.28 1.28 0 0 0 1.17 1.32Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path544"
        d="M467.54 273.57c-.73.44-1.61-2.92.58-4.24 0 0 .58-2.77 2.92-3.06 0 0 1.9-2.64 4.68-2.34 0 0 3.22-2.2 4.39-1.9 1.17.14 4.09 3.07 4.24 4.09a4.22 4.22 0 0 1-.74 3.51c-.87.87-1.46-.15-1.6-1.32 0 0 .58 2.19-1.17 3.07-1.75 1-1.61.3-1.75-.87 0 0-.45 2.33-1.61 2.63s-1.9-.58-2.78-.58c0 0 1.46 1.16.44 2a2.38 2.38 0 0 1-2.92-.15c-.73-.73-1.61-2.63-4.68-.87"
        style={{
          fill: "#ffb366",
        }}
      />
      <path
        id="Belize_svg__path546"
        d="M467.54 273.57c-.73.44-1.61-2.92.58-4.24 0 0 .59-2.77 2.93-3.06 0 0 1.89-2.64 4.68-2.34 0 0 3.21-2.19 4.38-1.9s4.09 3.07 4.24 4.09a4.22 4.22 0 0 1-.74 3.51c-.87.87-1.46-.15-1.6-1.32 0 0 .58 2.19-1.17 3.07-1.75 1-1.61.3-1.75-.87 0 0-.45 2.34-1.61 2.63-1.17.44-1.9-.59-2.78-.59 0 0 1.46 1.17.44 2.05a2.4 2.4 0 0 1-2.93-.15c-.73-.73-1.61-2.63-4.68-.87Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path548"
        d="M481.86 268.17a5.71 5.71 0 0 0-5.84-4.09"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path550"
        d="M479.23 270.36c-.44-.59-.59-1.17-1-1.75-1.32-2.2-4.1-2.05-6.43-2.63"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path552"
        d="M475.29 272.55c-.88-.58-1.17-1.75-2.05-2.19-1.46-.58-2.92-1.31-4.68-1"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path554"
        d="M503.21 345.36c1.75-11.4 7.16-20.91 7.3-21.78a149 149 0 0 0 15.21 2 70.2 70.2 0 0 0 16.52.29c4.23-.44 9.79-1.17 9.79-1.17a20.2 20.2 0 0 1 1.9 7c.44 7.46 3.21 39.77 3.5 48.4 0 0 1.17 5.11 1.17 17.69 0 13.74 3.8 41.66 3.8 56.58l-3.8.73s-20.76 1.61-20.76-1.17c0-3.06-1.16-38.88 1.9-60.52a68 68 0 0 0-1.9-7.9c-1.16-3.51-6.28-14.47-6.28-16.81 0 0-8.19 23.09-8.63 27-.43 3.66-1.61 55.12-2.34 59.36 0 0-11.83 9.07-13.44 9.94s-13-4.82-13.3-6.29c-.44-1.6 1.16-19 6.57-47.66 2.05-10.24.88-12.13.88-14.92s.15-39.47 1.9-50.87"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path556"
        d="M503.21 345.36c1.75-11.4 7.16-20.91 7.3-21.78a145 145 0 0 0 15.2 2 70.2 70.2 0 0 0 16.52.29c4.24-.43 9.79-1.16 9.79-1.16a19.8 19.8 0 0 1 1.9 7c.44 7.46 3.22 39.77 3.51 48.39 0 0 1.17 5.12 1.17 17.69 0 13.75 3.8 41.67 3.8 56.59l-3.8.73s-20.76 1.61-20.76-1.17c0-3.07-1.17-38.89 1.9-60.53a69 69 0 0 0-1.9-7.89c-1.17-3.51-6.28-14.48-6.28-16.81 0 0-8.19 23.09-8.63 27.05-.43 3.65-1.61 55.11-2.34 59.35 0 0-11.84 9.07-13.44 9.95-1.61.73-13-4.83-13.31-6.29-.44-1.61 1.17-19 6.58-47.67 2.05-10.23.88-12.12.88-14.9s.15-39.48 1.9-50.89Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path4395"
        d="M513.88 327.09c-.44 1.9-3.22 10.52-10.23 15.64"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path4397"
        d="M524.11 328.69c-1.61 5.12-7.89 32.61-9.07 65.21"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path562"
        d="M522.94 395.8c-.88 3.22-2.34 6.29-5.12 8.34"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path564"
        d="M514.61 404.14c-.29 3.07-1.9 25.88-2.34 29.82"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path566"
        d="M511.1 438.35c-.73 1.6-4 14.91-4 26.75"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path568"
        d="M553.49 413.49c-.29 2.78-1.46 22.52 5.12 40.21"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path570"
        d="M532 330.16c-.44 3.21-1.17 24-1.17 25.58s2.34 3.95 3.51 4.39c0 0-2.34.73-2.78 8.63"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path572"
        d="M525.72 350.19c-.44 2.48-2.78 12.28-4.39 16.23"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path574"
        d="M540.19 329.86s7 1.47 7.45 0c0 0 2.34 42.41 5.56 65.94"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path576"
        d="M537.12 335.27c0 2.78-.87 23.69-.87 23.69"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path578"
        d="M539.75 362s2.77 4 2 5.84"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path580"
        d="M516.21 327.53c2.34.73 4.39 1.16 4.39 1.16"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path582"
        d="M528.06 331a51.5 51.5 0 0 0-.88 6.59"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path584"
        d="M539.75 393.46c.88 1.17 2 2.78 1.17 6.29"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path586"
        d="M509.49 323.28s0-4.67 1.76-7c0 0 5 1 12 1.75 7.17.73 12.87 1.32 18.13.73a97 97 0 0 0 10.67-1.9s1.46 6.87.73 7.9a89 89 0 0 1-12 1.46c-6.43.43-17.1-.59-20.75-1.17-4.82-.59-9.06-1.46-10.52-1.75"
        className="Belize_svg__cls-34"
      />
      <path
        id="Belize_svg__path588"
        d="M509.49 323.29s0-4.68 1.76-7c0 0 5 1 12 1.75s12.86 1.32 18.13.73a97 97 0 0 0 10.67-1.9s1.46 6.88.73 7.9a87 87 0 0 1-12 1.46c-6.43.43-17.1-.59-20.75-1.17-4.83-.58-9.07-1.46-10.53-1.75Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path590"
        d="m514 323.87.88-7 1.46.15-.29 7.31-2.05-.44"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path592"
        d="m514 323.87.87-7 1.47.15-.29 7.31-2.05-.44"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path594"
        d="m545.74 318 .15 7.89 1.76-.29-.15-8-1.76.44"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path596"
        d="m545.74 318 .15 7.9 1.76-.3-.15-8-1.76.44"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path598"
        d="m523.23 318-.58 7.6h1l1-7.6h-1.47"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path600"
        d="m523.23 318-.58 7.6h1l1-7.6h-1.46"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path602"
        d="M497.79 461.74a54 54 0 0 0-1.46 7.89c0 .74-.73 4.68 1.46 4.82 1.61.16 2-4.08 2-4.08s-1.46 4.67.44 5.25c2.63.59 2.92-5.11 2.92-5.11-.29 1.6-2 5.7.44 6.14 2.63.44 3.36-5.56 3.36-5.56s-2.19 6 .44 6.15 2.49-5.85 2.49-5.85-1.32 7.16 2.19 6.57c1.9-.29 2.34-2.78 2.78-4.24.58-2.19 1.9-5 .29-9.5-1.17-3.36-.73-4.24-.73-4.24s-4.1 3.08-7.31 5.12c-1.47.88-9.36-3.36-9.36-3.36"
        className="Belize_svg__cls-35"
      />
      <path
        id="Belize_svg__path604"
        d="M497.79 461.74a53.6 53.6 0 0 0-1.46 7.9c0 .73-.73 4.68 1.46 4.82 1.61.15 2-4.09 2-4.09s-1.46 4.67.44 5.26c2.63.58 2.92-5.12 2.92-5.12-.29 1.61-2 5.7.44 6.14 2.63.44 3.36-5.56 3.36-5.56s-2.19 6 .44 6.14 2.49-5.85 2.49-5.85-1.32 7.17 2.19 6.58c1.9-.29 2.34-2.77 2.78-4.24.58-2.19 1.9-5 .29-9.5-1.17-3.36-.73-4.24-.73-4.24s-4.09 3.08-7.31 5.12c-1.46.88-9.36-3.36-9.36-3.36Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path606"
        d="M540.48 455.45c-.73-.44-.59 2.64-.88 4.09s.3 4.68 5.27 4.39 9.64-1.46 12.72-.73a42 42 0 0 0 10.08 1 14.8 14.8 0 0 0 5.26-.73c1-.43 3.22.59 4.24.59s2.63-1.61 2.49-2.63c-.15-1.91-1.9-1.91-4.39-2.05a42 42 0 0 1-7.16-1.32c-1.61-.58-4.53-2.19-8.18-2.92 0 0-7 .29-8.48.29s-10.09.44-11 0"
        className="Belize_svg__cls-35"
      />
      <path
        id="Belize_svg__path608"
        d="M540.48 455.45c-.73-.44-.58 2.64-.87 4.09s.29 4.68 5.26 4.39 9.65-1.46 12.72-.73a42 42 0 0 0 10.08 1 14.8 14.8 0 0 0 5.26-.73c1-.43 3.22.59 4.24.59s2.63-1.61 2.49-2.63c-.15-1.91-1.9-1.91-4.39-2.05a41 41 0 0 1-7.16-1.32c-1.61-.58-4.53-2.19-8.19-2.92 0 0-7 .29-8.47.29s-10.09.44-11 0Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path612"
        d="m672.3 222.72 39.09 19.14c7.83 3.81 10.65.29 15.07 2.21l77.14 39.3c6.62 2.89 10.33 3.82 9.38 7.24-.16 5.44 2.57 5.52 3.32 5.58a5.5 5.5 0 0 0 4.23-1.68 8 8 0 0 0 .85-1 4.5 4.5 0 0 0 1-1.34c3.24-4.67 5-8.72 1.64-11.36-3.88-1.82-4.33 2.31-8.41 1.87 0 0-7.31-2.78-10-4.68-4.84-2.64-11.61-5.77-13.15-7.48-4.93-.93-42-21.78-63-32.25-2.25-1.89-3.48-8.86-7.9-11.19-1.3-1.89-29.74-14.87-44.61-22.3-5.48-2.05-8.55-3.45-12.49 3.27-3.45 6.6-2.61 10.2 7.81 14.71Z"
        style={{
          strokeWidth: ".51px",
          stroke: "#000",
          fill: "#b34b00",
        }}
      />
      <path
        id="Belize_svg__path614"
        d="m675.61 214.53 43.76 21.85"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path616"
        d="M755.81 221.53c1.31 4.82 1.6 6 1.6 7.6s-.73 2.05-.14 4c1 3.51-1 7.31-3.51 11.4-1.17 1.9-2 4.39-4 5.56-4.82 3.07-10.67-.59-13.74-1.91a5.35 5.35 0 0 1-2.92-3.07 44 44 0 0 1-1.17-5.7c0-.44.15-1.75-.87-1.31-1.76-2.05-1.47-2.92-2.19-4.39s1.31-3.07 2.92-2.77c-.88-2.78-1.32-5.86-.44-8.49.29-.87-.73-3.21.73-2.63 6.58 2.63 14.33 2 21.35 2 .58 0 1-1.17 1.89-1 .14 0 .29.44.44.73"
        style={{
          fill: "url(#Belize_svg__radial-gradient-3)",
        }}
      />
      <path
        id="Belize_svg__path4826"
        d="M617.9 333c-2.3-1.79-6.1-3.53-9.16-5.57l-1.38 2.88c1.11 1.24 15.07 7.73 17.36 9a53.7 53.7 0 0 0-6.82-6.31"
        style={{
          fill: "#ccb8c8",
        }}
      />
      <path
        id="Belize_svg__path618"
        d="M755.81 221.53c1.31 4.82 1.6 6 1.6 7.6s-.73 2.05-.14 4c1 3.51-1 7.31-3.51 11.4-1.17 1.9-2 4.39-3.94 5.56-4.83 3.07-10.68-.59-13.75-1.91a5.4 5.4 0 0 1-2.92-3.06 45 45 0 0 1-1.17-5.7c0-.44.15-1.76-.87-1.32-1.76-2.05-1.47-2.92-2.19-4.39s1.31-3.07 2.92-2.78c-.88-2.77-1.32-5.84-.44-8.48.29-.87-.73-3.21.73-2.62 6.58 2.62 14.33 2 21.34 2 .59 0 1-1.17 1.9-1 .14 0 .29.44.44.73Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path620"
        d="M756.39 224c.73-.58.29-3.36-.44-6.29-.29-1.46-1.46-2-3.07-3.65a6.85 6.85 0 0 0-3.22-2c-.87-.3-2.92-.3-3.94-.74s-3.21-.15-5.41.15c-2.92.44-4.53.29-6.73 1.46s-3.94 2.48-4.67 4.68c-.59 1.61-1.61 3.65-1.17 9.8.29 6.13 1.46 5 1.46 5s1.61-2.34 2.63-.88c0 0-.14-3.22-.44-5.12a18.8 18.8 0 0 1 .74-6.58 43.7 43.7 0 0 0 13 2.49c4.38 0 10.09-.44 10.23-1.46l1 3.21"
      />
      <path
        id="Belize_svg__path622"
        d="M756.39 224c.73-.58.29-3.36-.44-6.29-.29-1.46-1.46-2-3.07-3.65a6.9 6.9 0 0 0-3.21-2c-.88-.3-2.93-.3-3.95-.73s-3.21-.16-5.41.14c-2.92.44-4.53.29-6.72 1.46s-4 2.49-4.68 4.68c-.58 1.61-1.61 3.65-1.17 9.8.29 6.13 1.46 5 1.46 5s1.61-2.34 2.64-.88c0 0-.15-3.22-.44-5.12a18.7 18.7 0 0 1 .73-6.58 43.7 43.7 0 0 0 13 2.49c4.38 0 10.09-.44 10.23-1.46l1 3.21Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path624"
        d="M731.1 238.05c1-.44.73.87.87 1.31.44 1.9.44 3.95 1.17 5.7s1.17 2.2 2.63 2.93c2.34 1.31 9.21 5.12 14 2a4.5 4.5 0 0 0 1-.73 7.62 7.62 0 0 0 4.24 8.92c1.89 1 22.51 11.84 22.51 11.84a13.85 13.85 0 0 1 .87 9.36c-.29.88-.58 1.61.3 2.19 1.46 1 3.8 3.95 5.41 8.63 0 0 4.23-1.9 4.23-4.68s-1.75-4.24-2.19-6.29c-.29-1.9-1.46-6.72-.87-8.18s3.07-2.19 4.09-2.78 2.19.58 1.9 1.9-1.17 1.17-2.34 1.46c0 0 1.46 2.77 1.17 4.82l13.44 6.87s-1.75 2.34-2.34 3.36-2 14.62-6 21.06-5.26 4.68-6 5.55-2 1.91-3.07 2.78-5.41 1.17-6.43-1.31a12.9 12.9 0 0 0-4.53-6c-2.78-2.19-8.48-7.75-8.48-10.24 0 0-3.36 9.66-2.92 17.7a157 157 0 0 1-23.17 2.78c-11 .14-16.52-2.2-16.52-2.2s-1.46-9.21-.87-11a60 60 0 0 1-2.92 8c-1.61 2.48-3.22 7.6-6.29 7.89s-5.7-3.07-6.14-4.38v-9.65h-12.42c0-.15-5.41 2-7.31 1.75s-2.63-1.75-1.17-2.34l.59-.43a10.2 10.2 0 0 0-3.66 1h-6.13c0-.15.14-.59.14-.73.44-1.76 3.66-3.22 6.14-4.24s4.82-1.91 6.58-2.05c2.78-.29 4.68-.14 8.92.59 1.16.14 5.7.58 8.33 1.9.73.43 1.6.58 1.17-1.32-.44-2.05-1.76-8 .44-12 1.46-2.78 1.31-5.11 1.16-7.46s-1.6-8-1.6-10.52c0-8.19 6.28-11.55 11.84-13.31 6.14-1.89 10.23-3.95 9.94-7.6-.15-3.8.87-10.08.29-13"
        style={{
          fill: "url(#Belize_svg__radial-gradient-4)",
        }}
      />
      <path
        id="Belize_svg__path626"
        d="M731.1 238.05c1-.44.73.87.87 1.31.44 1.91.44 3.95 1.17 5.71.88 1.6 1.17 2.19 2.63 2.92 2.34 1.32 9.21 5.11 14 2a4.9 4.9 0 0 0 1-.73 7.62 7.62 0 0 0 4.24 8.92c1.89 1 22.51 11.84 22.51 11.84a13.8 13.8 0 0 1 .87 9.35c-.29.88-.58 1.61.29 2.2 1.47 1 3.81 3.94 5.42 8.62 0 0 4.23-1.9 4.23-4.68s-1.75-4.23-2.19-6.28c-.29-1.9-1.47-6.73-.88-8.19s3.07-2.19 4.1-2.78 2.19.58 1.9 1.9c-.15 1.47-1.17 1.17-2.34 1.47 0 0 1.46 2.77 1.17 4.82l13.44 6.87s-1.75 2.34-2.34 3.36-2 14.62-6 21-5.26 4.68-6 5.56-2 1.9-3.07 2.78-5.41 1.16-6.44-1.32a12.76 12.76 0 0 0-4.53-6c-2.78-2.2-8.47-7.75-8.47-10.24 0 0-3.37 9.65-2.93 17.69a156.5 156.5 0 0 1-23.16 2.85c-11 .15-16.52-2.19-16.52-2.19s-1.46-9.21-.87-11c0 0-1.75 6-2.93 8.05-1.6 2.48-3.21 7.6-6.28 7.89-3.21.14-5.7-3.07-6.14-4.39v-9.65h-12.42c0-.14-5.41 2.05-7.31 1.76s-2.63-1.76-1.17-2.34l.59-.44a10.2 10.2 0 0 0-3.66 1h-6.14c0-.14.15-.58.15-.73.44-1.75 3.66-3.21 6.14-4.24s4.82-1.9 6.58-2.05c2.78-.29 4.68-.14 8.92.59 1.16.15 5.7.58 8.33 1.9.73.44 1.61.59 1.17-1.32s-1.76-8 .44-12c1.46-2.78 1.31-5.12 1.17-7.46-.3-2.48-1.61-8-1.61-10.52 0-8.19 6.28-11.55 11.84-13.31 6.13-1.9 10.23-4 9.94-7.6-.15-3.8.87-10.09.29-13Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path628"
        d="M679.06 307.79a6.26 6.26 0 0 1 2.63-1.9c1-.44 2-1 2.92-1.46 1-.59 2.49-.73 3.37-1.32"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path630"
        d="M687.39 306.91c.44-.29.73-.44 1.17-.73s1-.15 1.61-.3c0 0 0-.29.14-.29a4.68 4.68 0 0 0 3.51-1.9"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path632"
        d="M784.16 290.24c-1.46 1-4.53 3.22-5 7.46a12.6 12.6 0 0 1-1.32 4.38"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path634"
        d="M796.73 290.83c-.87 2.48-1 10.67-8.77 19.3"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path636"
        d="M795.13 279.42c.58 1.61 1.31 3.07-.29 4.24"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path638"
        d="M774.07 299c-1.6-3.66-6.43-3.37-6.43-6.87"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path640"
        d="M766.77 298.58c.44-4.1 1.46-6.43.58-12.43-.15-1.76-.58-8.48 0-11.7 0 0-2.34 11.26 5.41 13.46"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path642"
        d="M723.21 305.89A28.6 28.6 0 0 0 722 297c-1.76-8 1.46-11.1.14-13.15-1.16-1.75-2.92-5.41-.58-12.72 0 0-4.09 15.06 6.44 15.06s10.23-3.95 10.23-3.95"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path644"
        d="M767.35 274.45c0 3.07 0 12.14-12.86 12.14-4 0-8.33-2.78-10.67-4.68"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path646"
        d="M740.6 278a69 69 0 0 0 .4-10"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path648"
        d="M760.78 263.05a85 85 0 0 0-13-.88"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path650"
        d="M747.32 253.1c-.72 3.22.45 8.34-5.55 9.07"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path652"
        d="M735.49 251.93c.87 2.35.87 4.68 1.31 6.29"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path654"
        d="M736.36 262.17a64.4 64.4 0 0 0-12.42.44"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path656"
        d="M721.45 294.48c-1.17 2.34-1.61 5.41-5.12 8.92"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path658"
        d="M761.94 287c-.44 1.9 2.34 8.18.29 13.3a18.21 18.21 0 0 0 1.61 15.94"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path660"
        d="M754.49 288.93c1.17 2.33 3.07 4.68 3.51 6.72"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path662"
        d="M748.5 293.31c0 1.47 3.94 12.57 6.72 15.65 0 0-5.11 2-4.38 9.5"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path664"
        d="M740.31 288.2c0 1.9.29 2.34-1.17 3.5"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path666"
        d="M735.49 291.7c-1.9 2.34-7.75 9.36-6.58 13.31"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path668"
        d="M739.43 295.65c-.29 1.46-.29 9.07.88 12.87"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path670"
        d="M743.38 308.23a4.55 4.55 0 0 1 4.38 3.94"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path672"
        d="M735.2 307.79c-1.61-.44-4.68 2.33-4.68 10.23"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path674"
        d="M707.86 303.25a11.3 11.3 0 0 1 3.5 1.61 23 23 0 0 0 3.22 2.19"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path676"
        d="M747.32 230.88c.88 2.49.15 1.76.59 3.36"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path678"
        d="M733.15 237.17c0 1.17-.88.88-1.47.88"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path680"
        d="M759.6 285.27a1.36 1.36 0 0 1-1.46-1.32 1.5 1.5 0 0 1 2.93 0 1.36 1.36 0 0 1-1.47 1.32"
      />
      <path
        id="Belize_svg__path682"
        d="M759.6 285.27a1.26 1.26 0 1 1 1.47-1.31 1.35 1.35 0 0 1-1.47 1.31Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path684"
        d="M726.57 285.71a1.49 1.49 0 0 1-1.61-1.32c0-.73.73-1.17 1.61-1.17a1.33 1.33 0 0 1 1.46 1.17 1.44 1.44 0 0 1-1.46 1.32"
      />
      <path
        id="Belize_svg__path686"
        d="M726.57 285.71a1.49 1.49 0 0 1-1.61-1.32c0-.72.73-1.16 1.61-1.16a1.33 1.33 0 0 1 1.46 1.16 1.44 1.44 0 0 1-1.46 1.32Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path688"
        d="M756.53 229c-1.31-1-3.8-.29-5 .15-2.19 1-1 3.22-.29 5.26.29 1 1.46 2.49.73 3.51s-1 .59-2.34.73c.15-.44-1.16 0-1.6 0-.88-.14-1.32.58-2 .29s-.73-1.75-.44-2.48"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path690"
        d="M745.72 230c0-.57-1.9-1.16-4-1.31-3.21-.15-3.51.15-6 1.9"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path692"
        d="M756 230.88c-1-.14-1.46-1.31-4.53.44-1.61.88 2.63 2.05 2.05 1.9a1.88 1.88 0 0 0 2.48-1.61c0-.29.29-.73 0-.73"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path694"
        d="M756 230.88c-1-.14-1.46-1.31-4.53.44-1.61.88 2.63 2.05 2.05 1.9a1.88 1.88 0 0 0 2.48-1.61c0-.29.29-.73 0-.73Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path696"
        d="M754.05 232.64a1.16 1.16 0 0 1-1.17-1.17 1.2 1.2 0 0 1 1.17-1.32 1.19 1.19 0 0 1 1 1.32 1.13 1.13 0 0 1-1 1.17"
      />
      <path
        id="Belize_svg__path698"
        d="M754.05 232.64a1.16 1.16 0 0 1-1.17-1.17 1.2 1.2 0 0 1 1.17-1.32 1.19 1.19 0 0 1 1 1.32 1.13 1.13 0 0 1-1 1.17Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path700"
        d="M752.29 243.46c-.58-.88-1.46-1.32-3.07-.59 0-.29-2.19-.58-2.19-.58a6.5 6.5 0 0 0-3.07 1"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path702"
        d="M752 244.19c-1.31-.88-4.67-.29-8-.29"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path704"
        d="M744.25 245.06c2.49.74 5 1.17 7.46 0"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path706"
        d="M744 232.05c0-1.16-2.63-1.6-4.09-1.16a5.6 5.6 0 0 0-2.19.87c1.46.29 1 .44 2.19 1 1.32.59 1.61 1.17 3.36.3a2.1 2.1 0 0 0 1.17-1.32c-.14.15-.29.29-.44.29"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path708"
        d="M744 232.05c0-1.17-2.63-1.6-4.09-1.17a5.8 5.8 0 0 0-2.19.88c1.47.29 1 .44 2.19 1 1.32.59 1.61 1.17 3.36.3a2.1 2.1 0 0 0 1.17-1.32c-.14.15-.29.29-.44.29Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path710"
        d="M741.48 232.93a1.19 1.19 0 0 1-1-1.32 1.15 1.15 0 0 1 1.17-1.16 1 1 0 0 1 1 1.16 1.19 1.19 0 0 1-1.17 1.32"
      />
      <path
        id="Belize_svg__path712"
        d="M741.48 232.93c-.58 0-1-.58-1-1.32a1.14 1.14 0 0 1 1.16-1.16 1 1 0 0 1 1 1.16 1.19 1.19 0 0 1-1.16 1.32Z"
        className="Belize_svg__cls-32"
      />
      <path
        id="Belize_svg__path714"
        d="M772.91 345.36c-1.76-11.4-7-20.91-7.31-21.78a141 141 0 0 1-15.06 2c-9.64 1-12.28.59-16.51.29-4.39-.44-9.95-1.17-9.95-1.17a20 20 0 0 0-1.89 7c-.44 7.46-3.07 39.77-3.51 48.4 0 0-1.17 5.11-1.17 17.69 0 13.74-3.8 41.66-3.8 56.58l3.8.73s20.76 1.61 20.76-1.17c0-3.06 1.17-38.88-1.9-60.52a68 68 0 0 1 1.9-7.9c1.17-3.51 6.28-14.47 6.28-16.81 0 0 8.19 23.09 8.63 27 .44 3.66 1.6 55.12 2.48 59.36 0 0 11.7 9.07 13.31 9.94s13-4.82 13.3-6.29c.44-1.6-1.17-19-6.58-47.66-2.05-10.24-.88-12.13-.88-14.92s-.14-39.47-1.9-50.87"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path716"
        d="M772.91 345.36c-1.76-11.4-7-20.91-7.31-21.78a137 137 0 0 1-15.06 2c-9.64 1-12.28.59-16.51.29-4.39-.43-9.94-1.16-9.94-1.16a19.8 19.8 0 0 0-1.9 7c-.44 7.46-3.07 39.77-3.51 48.39 0 0-1.17 5.12-1.17 17.69 0 13.75-3.8 41.67-3.8 56.59l3.8.73s20.76 1.61 20.76-1.17c0-3.07 1.17-38.89-1.9-60.53a69 69 0 0 1 1.9-7.89c1.17-3.51 6.28-14.48 6.28-16.81 0 0 8.19 23.09 8.63 27.05.44 3.65 1.61 55.11 2.49 59.35 0 0 11.69 9.07 13.3 9.95 1.61.73 13-4.83 13.3-6.29.44-1.61-1.17-19-6.58-47.67-2-10.23-.87-12.12-.87-14.9s-.15-39.48-1.9-50.89Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path718"
        d="M762.24 327.09c.43 1.9 3.21 10.52 10.23 15.64"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path720"
        d="M752 328.69c1.61 5.12 7.9 32.61 9.07 65.21"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path722"
        d="M753.17 395.8a15.57 15.57 0 0 0 5.12 8.34"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path724"
        d="M761.51 404.14c.43 3.07 1.89 25.88 2.34 29.82"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path726"
        d="M765 438.35c.87 1.6 3.94 14.91 3.94 26.75"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path728"
        d="M722.62 413.49c.29 2.78 1.46 22.52-5.12 40.21"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path730"
        d="M744.25 330.16c.3 3.21 1.17 24 1.17 25.58s-2.33 3.95-3.65 4.39c0 0 2.48.73 2.78 8.63"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path732"
        d="M750.54 350.19a104 104 0 0 0 4.24 16.23"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path734"
        d="M735.93 329.86s-7 1.47-7.46 0c0 0-2.34 42.41-5.55 65.94"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path736"
        d="M739.14 335.27c0 2.78.73 23.69.73 23.69"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path738"
        d="M736.36 362s-2.78 4-2 5.84"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path740"
        d="M759.9 327.53c-2.34.73-4.24 1.16-4.24 1.16"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path742"
        d="M748.2 331c.3 1.17.73 6.59.73 6.59"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path744"
        d="M736.36 393.46c-.87 1.17-2 2.78-1.16 6.29"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path746"
        d="M766.62 323.28s0-4.67-1.75-7c0 0-5 1-12 1.75s-12.86 1.32-18.13.73a97 97 0 0 1-10.67-1.9s-1.46 6.87-.73 7.9a89 89 0 0 0 12 1.46c6.43.43 17.1-.59 20.9-1.17 4.68-.59 8.92-1.46 10.38-1.75"
        className="Belize_svg__cls-34"
      />
      <path
        id="Belize_svg__path748"
        d="M766.62 323.29s0-4.68-1.75-7c0 0-5 1-12 1.75s-12.86 1.32-18.12.73a97 97 0 0 1-10.67-1.9s-1.47 6.88-.73 7.9a87 87 0 0 0 12 1.46c6.44.43 17.11-.59 20.91-1.17 4.67-.59 8.91-1.46 10.38-1.75Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path750"
        d="m762.09 323.87-.73-7-1.61.15.44 7.31 1.9-.44"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path752"
        d="m762.09 323.87-.73-7-1.61.15.44 7.31 1.9-.44"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path754"
        d="m730.52 318-.3 7.89-1.75-.29.15-8 1.9.44"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path756"
        d="m730.52 318-.3 7.9-1.75-.3.15-8 1.9.44"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path758"
        d="m752.88 318 .59 7.6h-1l-1-7.6h1.46"
        className="Belize_svg__cls-3"
      />
      <path
        id="Belize_svg__path760"
        d="m752.88 318 .59 7.48-1.35.15-.7-7.63Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path762"
        d="M778.46 461.74a55 55 0 0 1 1.32 7.89c0 .74.73 4.68-1.47 4.82-1.46.16-2-4.08-2-4.08s1.46 4.67-.44 5.25c-2.49.59-2.93-5.11-2.93-5.11.44 1.6 2.2 5.7-.43 6.14s-3.37-5.56-3.37-5.56 2.19 6-.29 6.15-2.63-5.85-2.63-5.85 1.46 7.16-2.19 6.57c-1.9-.29-2.19-2.78-2.64-4.24-.58-2.19-2-5-.43-9.5 1.16-3.36.73-4.24.73-4.24s4.23 3.08 7.31 5.12c1.46.88 9.5-3.36 9.5-3.36"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path764"
        d="M778.46 461.74a55 55 0 0 1 1.32 7.9c0 .73.73 4.68-1.47 4.82-1.46.15-2-4.09-2-4.09s1.46 4.67-.44 5.26c-2.49.58-2.92-5.12-2.92-5.12.43 1.61 2.19 5.7-.44 6.14s-3.37-5.56-3.37-5.56 2.2 6-.29 6.14-2.63-5.85-2.63-5.85 1.46 7.17-2.19 6.58c-1.9-.29-2.19-2.77-2.63-4.24-.59-2.19-2.05-5-.44-9.5 1.17-3.36.73-4.24.73-4.24s4.23 3.08 7.31 5.12c1.46.88 9.5-3.36 9.5-3.36Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path766"
        d="M735.63 455.45c.73-.44.73 2.64.88 4.09s-.15 4.68-5.12 4.39-9.79-1.46-12.86-.73a42 42 0 0 1-10.09 1 13.4 13.4 0 0 1-5.12-.73c-1-.43-3.36.59-4.38.59s-2.64-1.61-2.49-2.63c.3-1.91 1.9-1.91 4.39-2.05a39.7 39.7 0 0 0 7.16-1.32 43.2 43.2 0 0 1 8.33-2.92s7 .29 8.48.29 10.09.44 10.82 0"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path768"
        d="M735.63 455.45c.73-.44.73 2.64.88 4.09s-.15 4.68-5.12 4.39-9.79-1.46-12.86-.73a42 42 0 0 1-10.09 1 13.5 13.5 0 0 1-5.12-.73c-1-.43-3.36.59-4.38.59s-2.64-1.61-2.49-2.63c.3-1.91 1.9-1.91 4.39-2.05a39 39 0 0 0 7.16-1.32 43.2 43.2 0 0 1 8.33-2.92s7 .29 8.48.29 10.09.44 10.82 0Z"
        className="Belize_svg__cls-10"
      />
      <path
        id="Belize_svg__path770"
        d="m517 465.83-.3-6.72 2.78 2.34.73-5.12c1.17-.3 3.07 2.05 3.07 2.05l-.29-5.12 4.24 4.68s-.29-3.95 1.16-6.29c0 0 3.22 2.78 3.22 6 0 2.79 2.78-3.94 2.78-3.94.73 2.34 2.34 7.45 2.34 7.45l1.89-2.78 1.61 5.85 3.51-5.12 2.78 6 6.28-.44.73 3.5 2.78-3.06 3.66 2.33a24 24 0 0 1 4.68-.87c1.6 0 3.5 2.05 3.5 2.05a9.8 9.8 0 0 1 2.34-2.79c1.17-.72 2.78 1.18 3.22 2.35l2.34-4.24s3.07 3.07 3.5 4.24c0 0 .44-3.51 1.17-4.68s1.9.44 2.78 1.9l1.9-6.29a6.1 6.1 0 0 1 3.51 2.78c1.6 2.34 1.6-3.51 1.6-3.51s3.51 4.68 4.39 7.9c0 0-24.21 8.31-41.28 6-20.09-2.68-36.64-6.48-36.64-6.48"
        className="Belize_svg__cls-40"
      />
      <path
        id="Belize_svg__path772"
        d="m517 465.83-.3-6.72 2.78 2.34.73-5.12c1.17-.29 3.07 2.05 3.07 2.05l-.29-5.12 4.24 4.68s-.29-3.95 1.16-6.28c0 0 3.22 2.77 3.22 6 0 2.78 2.78-3.95 2.78-3.95.73 2.34 2.34 7.46 2.34 7.46l1.89-2.78 1.61 5.84 3.51-5.11 2.78 6 6.28-.44.73 3.51 2.78-3.07 3.66 2.34a24 24 0 0 1 4.67-.87c1.61 0 3.51 2 3.51 2a9.7 9.7 0 0 1 2.34-2.77c1.17-.73 2.78 1.16 3.21 2.33l2.34-4.23s3.07 3.07 3.51 4.23c0 0 .44-3.5 1.17-4.68s1.9.45 2.78 1.91l1.9-6.29a6.1 6.1 0 0 1 3.5 2.78c1.61 2.33 1.61-3.51 1.61-3.51s3.51 4.68 4.39 7.89"
        className="Belize_svg__cls-8"
      />
      <path
        id="Belize_svg__path776"
        d="M682.86 465.1a16.2 16.2 0 0 1 2.78-4.83s3.07 3.66 3.07 4.83a21 21 0 0 0 2.48-1.61l2.34 2.34 2.78-1.9 2.63 2.34 4.83-2 1.9 2 7.45-.87 2.78 1.17 2.63-3.37 2.49 2.63 3.94-3.94a9.9 9.9 0 0 1 2.34 2.78s3.22-1.61 4-2.78c0 0 3.07-1.17 3.5.73 0 0 1.9-1.47 1.76-3.07s2.19-.44 2.92 1.6c0 0 .88-3.21 3.21-3.5a7.24 7.24 0 0 1 1.17 5.41s3.51-2.78 5.12-3.08a17.15 17.15 0 0 1-.44 7.47s3.65-6 4.39-3.95c.29 1.16 1.17 5.11.29 7.89l3.22-2.05 1.16 4 3.51-.73s-20.75 3.18-40.3 1.31-37.9-8.77-37.9-8.77"
        className="Belize_svg__cls-40"
      />
      <path
        id="Belize_svg__path778"
        d="M682.86 465.1a16.1 16.1 0 0 1 2.78-4.82s3.07 3.66 3.07 4.82a21 21 0 0 0 2.48-1.6l2.34 2.33 2.78-1.89 2.63 2.33 4.83-2 1.89 2 7.46-.87 2.78 1.17 2.63-3.36 2.48 2.63 4-3.95a10 10 0 0 1 2.34 2.78s3.21-1.61 3.94-2.78c0 0 3.07-1.17 3.51.73 0 0 1.9-1.46 1.75-3.07s2.19-.43 2.93 1.61c0 0 .87-3.21 3.21-3.51a7.26 7.26 0 0 1 1.17 5.41s3.51-2.77 5.11-3.07a17 17 0 0 1-.43 7.46s3.65-6 4.38-3.95c.3 1.17 1.17 5.12.3 7.89l3.21-2 1.17 3.95 3.51-.74"
        className="Belize_svg__cls-8"
      />
      <path
        id="Belize_svg__path110"
        d="M663 476.93c10.51-5.9 16.2-11.06 20.42-17.93-4 2.25-3.4 2.26-5.45 2a7.87 7.87 0 0 1-3.51-1.9s-1.9 2.19-4.39 1.9a14 14 0 0 1-4.67-1.9s-3.22 2.19-6.44 2.05a7.88 7.88 0 0 1-4.53-2.35s-5.7 1.9-6.87 1.9-5.41-1.9-5.41-1.9-2.78 1.76-4.38 1.76c-1.76 0-6.44-2-6.44-2a37 37 0 0 1-6.43 1.16 18.7 18.7 0 0 1-5.11-1.16s-3.66 1.6-5.85 1.16a19.6 19.6 0 0 1-3.66-1.16s-4.09 1.6-6.57 1.16a21 21 0 0 1-5.41-2s-.44 1.17-5.26 2c0 0 2.42-.64-.15.15 4.31 6.23 11.24 12.42 17.39 16.61a27.2 27.2 0 0 0 6.5-2.41 23.9 23.9 0 0 0 6 2.39c2.48.44 5.74-1.89 5.74-1.89a24.2 24.2 0 0 0 4.86 1.89c2 .44 4.58-1.39 4.58-1.39a24.7 24.7 0 0 0 5.85 1.35 26 26 0 0 0 6-1.37s3.72 2 5.47 2a8.7 8.7 0 0 0 3.64-1.26s1.06 1.31 4.13 1.16Z"
        className="Belize_svg__cls-40"
      />
      <path
        id="Belize_svg__path112"
        d="M638.4 490.05c14.3-5.95 16.88-7.57 25.53-12.86-1.5 0-5.89-1.38-4.3-1.83a6.36 6.36 0 0 1-3.52 1.59c-1.61 0-6.28-2-6.28-2a37.3 37.3 0 0 1-6.43 1.16 18.7 18.7 0 0 1-5.12-1.16s-3.8 1.6-5.85 1.16a19.4 19.4 0 0 1-3.65-1.16s-4.24 1.6-6.58 1.16a21.1 21.1 0 0 1-5.41-2.05s-3.6 1.91-5.86 2.13c6.91 5.06 14.19 10 27.47 13.9Z"
        style={{
          stroke: "#000",
          fill: "#ffd800",
          strokeWidth: ".61px",
        }}
      />
      <path
        id="Belize_svg__path108"
        d="M683.79 458.92s-3.41 2.23-5.46 1.94a8 8 0 0 1-3.51-1.9s-1.89 2.19-4.38 1.9a14 14 0 0 1-4.68-1.9s-3.21 2.19-6.43 2.05a7.85 7.85 0 0 1-4.53-2.34s-5.7 1.9-6.87 1.9-5.41-1.9-5.41-1.9-2.78 1.76-4.39 1.76-6.43-2.05-6.43-2.05a37 37 0 0 1-6.43 1.17 18.6 18.6 0 0 1-5.12-1.17s-3.66 1.6-5.85 1.17a20 20 0 0 1-3.65-1.17s-4.09 1.6-6.58 1.17a21.5 21.5 0 0 1-5.41-2.05s-.44 1.17-5.26 2.05l-.15.15"
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: ".61px",
        }}
      />
      <path
        id="Belize_svg__path4576"
        d="M668.4 479.61c1-.59-6.53 15.38-24.67 16.23-25.23 1.19-46.8-11.41-46.8-11.41s2.63-4.38 4.23-5.55c0 0 3.51 2.78 4.68 6.28a16 16 0 0 1 1.61-4.38s4.39 2 5.12 4.83c0 0 1.61-2.78 2.78-3.51 1.16-.88 2.34 3.06 2.34 5.41 0 0 2.77-2.93 4.38-3.08 0 0 3.07 1.47 3.95 6.59 0 0 2.92-2.78 3.94-2.35 0 0 3.51 2.35 2.63 5.56-.43 1.76 3.51-2 8.34-3.21l4.24 3.21s3.94-5.26 5.11-7c0 0 3.22 1.17 3.22 3.08 0 0 1.46-5.56 2.63-6.15s2.77 1.76 2.77 3.37c0 0 .45-4.24 2.78-5.12 0 0 2.34 1.61 2.34 3.22 0 0 2.48-4.83 4.38-6Z"
        className="Belize_svg__cls-40"
      />
      <path
        id="Belize_svg__path182"
        d="M707.86 307.79v79.38c0 15.79-2.19 78.66-69.73 103.08-67.68-24.42-69.88-87.28-69.88-103.08v-79.38h139.61"
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: "1.02px",
        }}
      />
      <path
        id="Belize_svg__path774"
        d="M596.81 484.47s2.63-4.38 4.23-5.55c0 0 3.51 2.78 4.68 6.28a16.5 16.5 0 0 1 1.61-4.38s4.39 2 5.12 4.82c0 0 1.61-2.78 2.78-3.5 1.17-.88 2.34 3.06 2.34 5.4 0 0 2.77-2.92 4.38-3.07 0 0 3.07 1.46 3.95 6.58 0 0 2.92-2.77 3.94-2.34 0 0 3.51 2.34 2.64 5.56-.44 1.75 3.5-2 8.33-3.22l4.24 3.22s3.94-5.26 5.11-7c0 0 3.22 1.17 3.22 3.07 0 0 1.46-5.56 2.63-6.14 1.17-.73 2.78 1.75 2.78 3.36 0 0 .44-4.24 2.78-5.11 0 0 2.34 1.6 2.34 3.21 0 0 2.48-4.82 4.38-6"
        className="Belize_svg__cls-8"
      />
      <path
        id="Belize_svg__path4040"
        d="M608.74 327.08c1.79 1.26 5.2 3.41 7.36 4.67 4.13 2.88 6.75 6 9.7 7.72"
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: ".4px",
        }}
      />
      <path
        id="Belize_svg__path184-4"
        d="M793.12 269.15c-1.29 1-2.64 2.58-2.74 3.28a5.1 5.1 0 0 0 .72 3.5c.79.66 1.36 0 1.57-1.12a2.77 2.77 0 0 0 1.21 2.87c1.75 1 1.61.14 1.75-1 0 0 .45 2.35 1.62 2.78s1.9-.58 2.78-.58c0 0-1.47 1.15-.44 1.88a2.19 2.19 0 0 0 2.91-.13c.88-.59.43-2.48 3.51-.72.38.15 1.15-.65 1.48-1.66l-11-8-3.32-1.12Z"
        className="Belize_svg__cls-11"
      />
      <path
        id="Belize_svg__path3605"
        d="M790.78 271.19c-2.16 3.71.72 5.94 1.67 3.76-.27 3.43 2.31 3.29 3.29 2.21.94 3.61 2.63 2 4.18 1.67-1.76 3.29 2.12 2.32 3 1.56 1.33-2 1.95-.4 2.93-.6l1.67-1.79"
        className="Belize_svg__cls-45"
      />
      <path
        id="Belize_svg__path4387"
        d="M796.1 269.81c-2.42 1.29-3.61 3.08-3.64 5.32"
        className="Belize_svg__cls-45"
      />
      <path
        id="Belize_svg__path4389"
        d="M799.44 272c-2.42 1.29-3.61 3.07-3.64 5.32"
        className="Belize_svg__cls-45"
      />
      <path
        id="Belize_svg__path4391"
        d="M803.56 274.77c-2.42 1.29-2.54 2.47-3.58 4.06"
        className="Belize_svg__cls-45"
      />
      <path
        id="Belize_svg__path4393"
        d="M628.71 373.42c2.84-2.32 4.47-4.54 3.52-6.27-1.07-1.49-2.74.93-4.62.36 0 0-2.84-1.76-3.61-3-1.66-1.84-3.79-3.5-5-5.08A199 199 0 0 0 605.34 344c-2.14-1.4-1.65-6.68-3.18-8.33-.13-1.14-14.88-15.74-20.15-21-2.13-1.56-3.25-2.58-7 .81-2.95 2.67-4 5.42-.14 8.73l19.19 17.45c2.8 2.73 6.72 3.22 8.36 4.63l19.51 19.95c2.45 2.12 3.63 3.63 2.24 5.44-1.54 3 .11 3.39.46 3.48a3.35 3.35 0 0 0 2.52-.59l1.55-1.16Z"
        style={{
          stroke: "#000",
          fill: "#b34b00",
          strokeWidth: ".51px",
        }}
      />
      <path
        id="Belize_svg__path4396"
        d="m580.06 321.1 20.05 19.81"
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: ".5px",
        }}
      />
      <path
        id="Belize_svg__path130"
        d="m574.74 379.37 38.2-47.28c.72-.65 4.26-.69 4.74 3l-36.18 49.28a9.6 9.6 0 0 1-6.76-5Z"
        style={{
          strokeWidth: ".51px",
          stroke: "#000",
          fill: "#782121",
        }}
      />
      <g id="Belize_svg__g3562">
        <path
          id="Belize_svg__path3497"
          d="M550.65 496.74a3.1 3.1 0 0 1 .41 1.06 3.8 3.8 0 0 1 0 1.24 3.52 3.52 0 0 1-1.74 2.46 4.5 4.5 0 0 1-3.32.5 5.5 5.5 0 0 1-1.62-.58 6.6 6.6 0 0 1-1.33-.89l-.5.57-.7-.14c.23-1.41.45-2.83.68-4.24l.71.14a11 11 0 0 0 .25 1.5 4.7 4.7 0 0 0 .56 1.33 3.4 3.4 0 0 0 .91 1 3.2 3.2 0 0 0 1.36.57 3.5 3.5 0 0 0 1.1.05 1.8 1.8 0 0 0 .81-.31 1.8 1.8 0 0 0 .55-.6 2.6 2.6 0 0 0 .31-.86 2.55 2.55 0 0 0-1.24-2.66c-.35-.22-.76-.48-1.22-.75s-.85-.52-1.18-.76a4.2 4.2 0 0 1-1.4-1.54 3.22 3.22 0 0 1-.21-2.1 3.1 3.1 0 0 1 .54-1.25 3.7 3.7 0 0 1 1-.93 3.9 3.9 0 0 1 1.35-.48 4.5 4.5 0 0 1 1.5 0 4.3 4.3 0 0 1 1.49.56 6.6 6.6 0 0 1 1.13.84l.48-.52.7.14c-.24 1.36-.47 2.73-.71 4.1l-.71-.14c0-.49-.1-1-.17-1.43a5.5 5.5 0 0 0-.42-1.28 2.71 2.71 0 0 0-2.05-1.54 2.08 2.08 0 0 0-1.53.27 1.66 1.66 0 0 0-.83 1.17 2.24 2.24 0 0 0 .13 1.4 2.66 2.66 0 0 0 .93 1.07 13 13 0 0 0 1.17.77c.38.23.75.46 1.1.7a8 8 0 0 1 .93.71 4 4 0 0 1 .71.83"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3499"
          d="M568.59 499.25a6 6 0 0 0-.7-.26 2.2 2.2 0 0 0-.83-.13 1.24 1.24 0 0 0-.77.53 9.3 9.3 0 0 0-.85 1.31l-2.64 4.78a3.7 3.7 0 0 1-1.26 1.37 3.86 3.86 0 0 1-1.62.55 5 5 0 0 1-1.64-.11 6.8 6.8 0 0 1-1.42-.55 6.5 6.5 0 0 1-1.64-1.24 4.6 4.6 0 0 1-.93-1.44 3.3 3.3 0 0 1 .15-2.78l3.59-6.5a1.4 1.4 0 0 0 .16-.51 1.14 1.14 0 0 0-.18-.53 2.4 2.4 0 0 0-.41-.45c-.18-.15-.33-.27-.46-.36.1-.18.21-.36.3-.55l4.64 2.56-.3.55a4 4 0 0 0-.59-.23 3 3 0 0 0-.56-.16.62.62 0 0 0-.52.12 1.3 1.3 0 0 0-.35.42q-1.68 3-3.35 6.07a5.3 5.3 0 0 0-.41 1 2.65 2.65 0 0 0-.11 1.12 2.5 2.5 0 0 0 .45 1.15 3.7 3.7 0 0 0 1.29 1.07 4.1 4.1 0 0 0 1.6.53 2.8 2.8 0 0 0 1.31-.2 2.9 2.9 0 0 0 .93-.63 3.7 3.7 0 0 0 .64-.84l2.49-4.51a8 8 0 0 0 .65-1.48 1.36 1.36 0 0 0 0-.91 2.3 2.3 0 0 0-.58-.72 5 5 0 0 0-.66-.52l.3-.55 4.55 2.51-.3.55"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3501"
          d="M575 510.42a3.2 3.2 0 0 1 .31 1.3 2.74 2.74 0 0 1-.41 1.45 2.9 2.9 0 0 1-1.09 1.13 3.2 3.2 0 0 1-1.4.41 4.2 4.2 0 0 1-1.48-.13 6.2 6.2 0 0 1-1.47-.6l-5.22-3 .32-.55a6 6 0 0 0 .58.24 2.1 2.1 0 0 0 .58.18.77.77 0 0 0 .51-.09 1.1 1.1 0 0 0 .36-.4l4.5-7.82a1.3 1.3 0 0 0 .18-.5.83.83 0 0 0-.17-.53 2.3 2.3 0 0 0-.42-.48 3.5 3.5 0 0 0-.44-.35c.1-.18.21-.36.32-.54l5.08 2.93a6 6 0 0 1 1.07.76 4 4 0 0 1 .79 1 2.5 2.5 0 0 1 .33 1.17 2.36 2.36 0 0 1-.34 1.29 2.7 2.7 0 0 1-.8.89 2.5 2.5 0 0 1-1 .44 3.7 3.7 0 0 1-1.11 0 5.2 5.2 0 0 1-1.19-.25.4.4 0 0 1 0 .07 7.7 7.7 0 0 1 .93.84 4.6 4.6 0 0 1 .76 1.11Zm-.94-2.72a2.1 2.1 0 0 0 .82-.49 4.6 4.6 0 0 0 .64-.86 2.24 2.24 0 0 0 .31-1.7 2.35 2.35 0 0 0-1.27-1.41c-.16-.1-.37-.22-.63-.35l-.63-.34c-.81 1.39-1.61 2.79-2.41 4.18l1 .56a3.5 3.5 0 0 0 1.18.47 1.9 1.9 0 0 0 1-.06Zm-.91 4.09a2.47 2.47 0 0 0 .25-2 3 3 0 0 0-1.52-1.7l-.84-.48-.52-.27-2.21 3.83a1 1 0 0 0 0 1 2.4 2.4 0 0 0 1 .87 2.6 2.6 0 0 0 2.15.36 3.25 3.25 0 0 0 1.75-1.61Z"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3503"
          d="M601.5 518.26a4.4 4.4 0 0 0-.72-.19 2.3 2.3 0 0 0-.84-.05 1.27 1.27 0 0 0-.72.61 8.4 8.4 0 0 0-.72 1.39l-2.14 5a3.67 3.67 0 0 1-1.12 1.49 3.8 3.8 0 0 1-1.56.71 4.8 4.8 0 0 1-1.64 0 5.9 5.9 0 0 1-1.47-.4 6.6 6.6 0 0 1-1.76-1.07 4.8 4.8 0 0 1-1.07-1.33 3.4 3.4 0 0 1-.37-1.43 3.14 3.14 0 0 1 .25-1.36c1-2.27 2-4.54 2.92-6.82a1.5 1.5 0 0 0 .12-.52 1.2 1.2 0 0 0-.24-.51 2.3 2.3 0 0 0-.45-.41c-.2-.13-.35-.24-.49-.31l.24-.57 4.87 2.08-.24.58a5 5 0 0 0-.61-.18 4 4 0 0 0-.57-.1.61.61 0 0 0-.51.17 1.4 1.4 0 0 0-.31.45l-2.73 6.38a5 5 0 0 0-.31 1 2.6 2.6 0 0 0 0 1.12 2.5 2.5 0 0 0 .56 1.1 3.74 3.74 0 0 0 1.4.93 4 4 0 0 0 1.64.37 2.7 2.7 0 0 0 1.28-.33 3.1 3.1 0 0 0 .88-.71 4 4 0 0 0 .55-.91c.68-1.58 1.35-3.15 2-4.73a7.6 7.6 0 0 0 .51-1.53 1.33 1.33 0 0 0-.07-.91 2.2 2.2 0 0 0-.64-.66 5.6 5.6 0 0 0-.72-.46c.09-.19.17-.38.25-.57l4.78 2.05c-.09.18-.17.37-.25.57"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3505"
          d="m615.24 534.4-5.4-1.56.18-.6.77.16a2.5 2.5 0 0 0 .68 0 1.4 1.4 0 0 0 .5-.27 1.1 1.1 0 0 0 .27-.48c.84-2.92 1.69-5.84 2.53-8.77h-.13c-2.36 3-4.77 6-7.14 9.06l-.42-.12c-.29-3.88-.66-7.75-.94-11.63h-.11c-.7 2.4-1.39 4.79-2.08 7.2a7.6 7.6 0 0 0-.33 1.62 1.55 1.55 0 0 0 .18.9 2.2 2.2 0 0 0 .69.57 4.7 4.7 0 0 0 .73.39l-.18.6-4.93-1.47c.06-.2.12-.4.17-.6.27 0 .56.08.86.1a1.43 1.43 0 0 0 .74-.09 1.25 1.25 0 0 0 .62-.61 8.5 8.5 0 0 0 .59-1.61c.59-2.05 1.19-4.11 1.78-6.17a2.6 2.6 0 0 0 .12-.91 1.4 1.4 0 0 0-.21-.66 1.75 1.75 0 0 0-.55-.53 3.3 3.3 0 0 0-.74-.35l.18-.6 4.16 1.2c.27 3.36.62 6.71.89 10.07 1.7-2.25 3.46-4.46 5.17-6.71a7 7 0 0 0 .54-.79 6 6 0 0 0 .3-.57l4 1.15-.18.6a5 5 0 0 0-.63-.1 3.4 3.4 0 0 0-.59 0 .65.65 0 0 0-.49.23 1.4 1.4 0 0 0-.25.48q-1.26 4.35-2.51 8.7a1.2 1.2 0 0 0 0 .52.9.9 0 0 0 .3.48 1.7 1.7 0 0 0 .5.31 6 6 0 0 0 .61.27l-.18.59"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3507"
          d="M628.3 530.43a3 3 0 0 1 .79 1.08 2.8 2.8 0 0 1 .19 1.5 3.1 3.1 0 0 1-.57 1.46 3.6 3.6 0 0 1-1.13.93 4.8 4.8 0 0 1-1.42.44 5.6 5.6 0 0 1-1.59 0l-6-.75c0-.21 0-.42.08-.63h.63a2.2 2.2 0 0 0 .61-.06.78.78 0 0 0 .43-.28 1.1 1.1 0 0 0 .18-.51c.38-3 .75-6 1.13-9a1.9 1.9 0 0 0 0-.54 1 1 0 0 0-.37-.42 2.4 2.4 0 0 0-.56-.27c-.22-.07-.4-.13-.54-.16 0-.21.05-.41.07-.62l5.83.73a6 6 0 0 1 1.28.3 3.8 3.8 0 0 1 1.11.61 2.5 2.5 0 0 1 .76.94 2.37 2.37 0 0 1 .17 1.32 2.7 2.7 0 0 1-.38 1.14 2.6 2.6 0 0 1-.79.8 3.7 3.7 0 0 1-1 .47 4.6 4.6 0 0 1-1.19.23v.08a7.4 7.4 0 0 1 1.18.42 4 4 0 0 1 1.12.72Zm-1.92-2.15a2.06 2.06 0 0 0 .57-.76 4 4 0 0 0 .26-1 2.2 2.2 0 0 0-.37-1.68 2.36 2.36 0 0 0-1.72-.81l-.71-.09-.73-.07-.6 4.8 1.12.14a3.8 3.8 0 0 0 1.28 0 1.9 1.9 0 0 0 .9-.45Zm.74 4.13a2.46 2.46 0 0 0-.52-1.91 3 3 0 0 0-2.06-1l-1-.11h-.59c-.19 1.46-.37 2.92-.56 4.39a1 1 0 0 0 .35.89 2.5 2.5 0 0 0 1.23.44 2.6 2.6 0 0 0 2.12-.51 3.3 3.3 0 0 0 1-2.16Z"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3509"
          d="M644.23 536.38h-3.43c-.74-1.19-1.44-2.18-2-3s-1.29-1.72-2-2.64h-1.3v4a1.5 1.5 0 0 0 .09.54.7.7 0 0 0 .43.36 3 3 0 0 0 .56.12l.68.06v.63h-5.37v-.63l.63-.06a2.3 2.3 0 0 0 .56-.12.72.72 0 0 0 .39-.33 1.1 1.1 0 0 0 .12-.57v-9.14a1.6 1.6 0 0 0-.09-.55.64.64 0 0 0-.42-.36 4 4 0 0 0-.58-.15 6 6 0 0 0-.61-.08v-.63h5.87a6.8 6.8 0 0 1 1.52.16 4.4 4.4 0 0 1 1.27.53 2.56 2.56 0 0 1 1.22 2.3 3.4 3.4 0 0 1-.22 1.28 2.6 2.6 0 0 1-.66 1 3.8 3.8 0 0 1-1 .66 8 8 0 0 1-1.24.48c.61.82 1.15 1.5 1.56 2.06s.93 1.24 1.52 2a4.8 4.8 0 0 0 .72.78 4 4 0 0 0 .63.41 1.7 1.7 0 0 0 .58.18c.24 0 .43.06.58.07v.63Zm-4.71-9.38a2.53 2.53 0 0 0-.66-1.81 2.5 2.5 0 0 0-1.87-.67h-1.53v5.38h1.18a2.83 2.83 0 0 0 2.06-.78 2.87 2.87 0 0 0 .82-2.12Z"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3511"
          d="m658.88 535.44-5.27.4c0-.21 0-.42-.05-.63a5.6 5.6 0 0 0 1.15-.26c.3-.12.44-.23.43-.36a2 2 0 0 0 0-.2.6.6 0 0 0-.07-.2c-.41-.91-.85-1.81-1.27-2.72l-4.45.33c-.13.43-.24.79-.32 1.11s-.16.61-.22.87a4 4 0 0 0-.11.63 2 2 0 0 0 0 .39c0 .23.22.4.6.5a4.4 4.4 0 0 0 1.29.14c0 .21 0 .41.05.63l-4.77.36c0-.21 0-.42-.05-.63a3 3 0 0 0 .57-.15 1.8 1.8 0 0 0 .56-.27 2.1 2.1 0 0 0 .51-.54 2.5 2.5 0 0 0 .33-.75c.51-1.62 1.1-3.43 1.71-5.45s1.18-3.81 1.66-5.38h.71c1.66 3.5 3.38 7 5 10.46a2.1 2.1 0 0 0 .34.54 1.9 1.9 0 0 0 .51.37 1.7 1.7 0 0 0 .55.17 2.5 2.5 0 0 0 .56 0v.64Zm-5.49-4.73c-.75-1.57-1.53-3.13-2.29-4.7-.49 1.67-1 3.32-1.53 5l3.82-.29Z"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3513"
          d="m676.91 520.81-.64.17a4.8 4.8 0 0 0-1.15-1.24 2 2 0 0 0-1.34-.55l-.64.12-.85.22-2 .56c.44 1.62.87 3.24 1.31 4.86l1.41-.39a2.8 2.8 0 0 0 1-.4 1.8 1.8 0 0 0 .49-.56 1.9 1.9 0 0 0 .15-.68 3.4 3.4 0 0 0 0-.9l.64-.18c.44 1.6.87 3.18 1.29 4.77-.21.06-.43.11-.64.18a4.4 4.4 0 0 0-.41-.81 2.4 2.4 0 0 0-.52-.6 1.27 1.27 0 0 0-.76-.26 3.4 3.4 0 0 0-1 .14l-1.4.39c.38 1.39.75 2.78 1.13 4.17a1.1 1.1 0 0 0 .25.47.85.85 0 0 0 .49.25 3 3 0 0 0 .6 0 5 5 0 0 0 .68-.09c.06.2.11.4.17.6l-5.32 1.45-.16-.61c.17 0 .4-.14.67-.25a2.3 2.3 0 0 0 .54-.25.76.76 0 0 0 .29-.43 1.2 1.2 0 0 0 0-.56l-2.37-8.73a1.4 1.4 0 0 0-.23-.48.8.8 0 0 0-.49-.24 4.5 4.5 0 0 0-1.34 0c0-.2-.11-.4-.16-.6l9.62-2.61.82 3"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3515"
          d="m691.08 519.73 1.38 3.47-9 4.33c-.09-.19-.18-.39-.27-.57l.53-.33a1.9 1.9 0 0 0 .46-.35.77.77 0 0 0 .21-.48 1.15 1.15 0 0 0-.14-.55c-1.3-2.7-2.61-5.39-3.91-8.09a1.5 1.5 0 0 0-.32-.44.84.84 0 0 0-.53-.15 2.5 2.5 0 0 0-.61.06 3.5 3.5 0 0 0-.61.16l-.27-.57 4.83-2.33.28.57a3.5 3.5 0 0 0-.55.35 4 4 0 0 0-.46.39.59.59 0 0 0-.21.51 1.3 1.3 0 0 0 .13.54q1.84 3.79 3.67 7.59a5.4 5.4 0 0 0 .44.78.84.84 0 0 0 .47.34 1.1 1.1 0 0 0 .64-.09 9 9 0 0 0 1.05-.45c.2-.09.39-.2.61-.31a5 5 0 0 0 .58-.36 5 5 0 0 0 .46-.39.9.9 0 0 0 .25-.38 8 8 0 0 0 .23-1.48 9 9 0 0 0 0-1.5l.58-.27"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3517"
          d="M697.78 507.3a6.3 6.3 0 0 1 2.19 1.13 7.7 7.7 0 0 1 1.8 2.1 7.5 7.5 0 0 1 1 2.58 6.2 6.2 0 0 1-.1 2.47 5.92 5.92 0 0 1-2.79 3.7 5.9 5.9 0 0 1-4.72.63 6 6 0 0 1-2.15-1.15 7.78 7.78 0 0 1-2.7-4.62 6.3 6.3 0 0 1 .09-2.46 6 6 0 0 1 2.79-3.72 6.1 6.1 0 0 1 2.29-.81 5.9 5.9 0 0 1 2.34.15Zm3.37 8.34a5.8 5.8 0 0 0-.29-1.94 11 11 0 0 0-1-2.07 10.4 10.4 0 0 0-1.42-1.91 5.7 5.7 0 0 0-1.59-1.2 3.54 3.54 0 0 0-1.66-.38 3.36 3.36 0 0 0-1.68.5 3.2 3.2 0 0 0-1.32 1.35 3.8 3.8 0 0 0-.4 1.74 6.3 6.3 0 0 0 .35 1.91 11.5 11.5 0 0 0 2.29 3.86 6 6 0 0 0 1.56 1.2 3.6 3.6 0 0 0 1.67.42 3.37 3.37 0 0 0 1.74-.51 3.4 3.4 0 0 0 1.71-3Z"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3519"
          d="m716.91 507.09-2.8 2c-1.29-.53-2.44-.94-3.42-1.29s-2.06-.67-3.16-1l-1.07.75 2.31 3.25a2 2 0 0 0 .38.38.75.75 0 0 0 .56 0 4 4 0 0 0 .53-.23c.24-.13.43-.25.59-.35.12.17.24.35.36.51l-4.38 3.11-.37-.51c.12-.09.27-.23.48-.41a2 2 0 0 0 .39-.43.7.7 0 0 0 .12-.49 1.1 1.1 0 0 0-.22-.53l-5.25-7.4a1.5 1.5 0 0 0-.39-.4.65.65 0 0 0-.57-.05 5 5 0 0 0-.57.21 5 5 0 0 0-.54.29l-.37-.51 4.79-3.39a7.4 7.4 0 0 1 1.32-.75 4.2 4.2 0 0 1 1.36-.31 2.59 2.59 0 0 1 2.32 1.17 3.4 3.4 0 0 1 .56 1.18 2.7 2.7 0 0 1 0 1.17 4 4 0 0 1-.41 1.09 7.6 7.6 0 0 1-.74 1.11c1 .31 1.81.55 2.47.77s1.48.47 2.42.79a3.5 3.5 0 0 0 1 .22 3.7 3.7 0 0 0 .75 0 2 2 0 0 0 .58-.2 3 3 0 0 0 .51-.28c.12.18.25.35.37.52Zm-9.25-4.9a2.5 2.5 0 0 0-1.59-1.09 2.53 2.53 0 0 0-1.91.53l-1.25.89 3.12 4.39 1-.68a2.83 2.83 0 0 0 1.22-1.83 2.8 2.8 0 0 0-.55-2.21Z"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3521"
          d="m727.39 499.18 1.05 3.55-9.9 3.67c-.07-.19-.14-.39-.22-.58a6 6 0 0 0 .65-.33 2.1 2.1 0 0 0 .51-.31.9.9 0 0 0 .25-.46 1 1 0 0 0-.08-.54l-3.14-8.46a1.4 1.4 0 0 0-.27-.46.82.82 0 0 0-.51-.19 3.2 3.2 0 0 0-.67 0 3.3 3.3 0 0 0-.66.13c-.07-.19-.15-.39-.22-.59l9.23-3.42 1.05 2.84-.63.23a4 4 0 0 0-1.22-1.07 1.9 1.9 0 0 0-1.36-.38 5 5 0 0 0-.63.18c-.25.08-.52.17-.83.29l-1.87.69q.86 2.34 1.73 4.67l1.34-.5a2.7 2.7 0 0 0 .91-.47 1.36 1.36 0 0 0 .37-.59 2.2 2.2 0 0 0 .08-.76 5 5 0 0 0-.11-.82l.63-.23 1.71 4.61-.63.23a5 5 0 0 0-.49-.78 2.6 2.6 0 0 0-.55-.53 1.35 1.35 0 0 0-.75-.2 3.3 3.3 0 0 0-.91.23l-1.35.5 1.31 3.53a3.6 3.6 0 0 0 .39.81.78.78 0 0 0 .46.35 1.2 1.2 0 0 0 .67 0c.26-.07.63-.18 1.09-.35l.73-.27a4.7 4.7 0 0 0 .75-.32 7 7 0 0 0 .63-.37 1 1 0 0 0 .38-.42 5.2 5.2 0 0 0 .37-1.51 8.3 8.3 0 0 0 .1-1.38l.61-.23"
          className="Belize_svg__cls-49"
        />
        <path
          id="Belize_svg__path3523"
          d="M738 490.47a6.1 6.1 0 0 1 1.58 1.9 7.8 7.8 0 0 1 .82 2.63 7.4 7.4 0 0 1-.12 2.75 6.3 6.3 0 0 1-1.06 2.24 5.8 5.8 0 0 1-1.75 1.55 6 6 0 0 1-2.26.76 5.8 5.8 0 0 1-2.5-.17 5.5 5.5 0 0 1-2.09-1.1 6.1 6.1 0 0 1-1.53-1.89 7.5 7.5 0 0 1-.8-2.54 7.2 7.2 0 0 1 .12-2.77 6.6 6.6 0 0 1 1.05-2.24 6 6 0 0 1 1.77-1.56 5.8 5.8 0 0 1 2.25-.76 5.95 5.95 0 0 1 4.52 1.2Zm-.16 9a6.1 6.1 0 0 0 .49-1.91 10.4 10.4 0 0 0-.08-2.27 10.2 10.2 0 0 0-.56-2.32 5.8 5.8 0 0 0-1-1.72 3.6 3.6 0 0 0-1.38-1 3.36 3.36 0 0 0-1.74-.2 3.14 3.14 0 0 0-1.75.73 3.8 3.8 0 0 0-1.05 1.44 6 6 0 0 0-.42 1.89 10.8 10.8 0 0 0 .6 4.45 5.7 5.7 0 0 0 1 1.71 3.55 3.55 0 0 0 1.37 1.05 3.4 3.4 0 0 0 1.8.21 3.5 3.5 0 0 0 1.65-.67 3.66 3.66 0 0 0 1.09-1.39Z"
          className="Belize_svg__cls-49"
        />
      </g>
      <path
        id="Belize_svg__path146-2"
        d="M625.92 337a44 44 0 0 1 2.39-4.19 13.9 13.9 0 0 1 2.7-3.05"
        data-name="path146"
        style={{
          fill: "none",
          stroke: "#7e4b7e",
          strokeWidth: ".92px",
          strokeLinecap: "square",
        }}
      />
    </g>
  </svg>
);
export default SvgBelize;
