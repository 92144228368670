import * as React from "react";
import type { SVGProps } from "react";
const SvgUnitedArabEmirates = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <g data-name="United Arab Emirates">
      <path
        d="M0 0h1280v240H0z"
        style={{
          fill: "#00732f",
        }}
      />
      <path
        d="M0 240h1280v240H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path d="M0 480h1280v240H0z" />
      <path
        d="M0 0h320v720H0z"
        style={{
          fill: "red",
        }}
      />
    </g>
  </svg>
);
export default SvgUnitedArabEmirates;
