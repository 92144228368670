import * as React from "react";
import type { SVGProps } from "react";
const SvgLaos = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#ce1126",
      }}
    />
    <path
      d="M0 180h1280v360H0z"
      style={{
        fill: "#002868",
      }}
    />
    <circle
      cx={640}
      cy={360}
      r={144}
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgLaos;
