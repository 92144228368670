import * as React from "react";
import type { SVGProps } from "react";
const SvgEgypt = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Egypt_svg__cls-1{fill:#fff}.Egypt_svg__cls-3{fill:#f0c727}.Egypt_svg__cls-4{fill:#f4d134}.Egypt_svg__cls-5{fill:none}"
        }
      </style>
    </defs>
    <g id="Egypt_svg__Egypt">
      <path d="M0 480.18h1280V720.5H0z" />
      <path d="M0 239.84h1280v240.34H0z" className="Egypt_svg__cls-1" />
      <path
        d="M0-.5h1280v240.34H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        d="M639.16 474.06c23.62 0 46.22-1.87 59.47-5.6 5.6-1 5.6-3.92 5.6-7.65 5.6-1.87 2.73-8.5 6.62-8.5-3.89 1-4.76-6.61-9.52-5.6 0-6.63-6.61-7.47-12.21-5.61-11.23 3.74-31.1 4.6-50 4.6-18.86-.86-38.55-.86-49.95-4.6-5.6-1.86-12.22-1-12.22 5.61-4.75-1-5.6 6.63-9.51 5.6 3.91 0 1 6.63 6.61 8.5 0 3.73 0 6.63 5.77 7.65 13.09 3.73 35.69 5.6 59.3 5.6Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M639.16 476.41c-25.4 0-47.26-2.08-59.93-5.69-6.53-1.13-7.36-4.9-7.47-8.38-2.88-1.67-3.56-4.51-4-6.31a14 14 0 0 0-.39-1.35l-.51-.09a2.35 2.35 0 0 1 .6-4.63 3 3 0 0 1 .35.13 2 2 0 0 1 .24 0c.38 0 1.34-1.14 1.84-1.78 1.12-1.45 2.61-3.36 5-3.87 1.23-5.57 7.9-7.89 15.06-5.54 9 3 23.64 3.51 39.1 4.07l10.21.41c24 0 40.08-1.47 49.12-4.48 7.27-2.41 14 0 15.11 5.79a8.6 8.6 0 0 1 4.93 3.62 8.5 8.5 0 0 0 1.66 1.77c.32 0 .64-.09.77-.09a2.36 2.36 0 0 1 .61 4.63 2.6 2.6 0 0 1-.4 0l-.21.09h-.08c.11 0-.08.83-.21 1.37-.44 1.8-1.12 4.64-4 6.31-.12 3.48-1 7.23-7.52 8.42-12.63 3.57-34.55 5.65-59.89 5.65Zm-67.3-23.23c.21.6.36 1.21.49 1.74.48 2 .81 3.11 2.48 3.66a2.35 2.35 0 0 1 1.61 2.23c0 3.67 0 4.66 3.83 5.33 12.34 3.51 34.28 5.57 58.89 5.57s46.56-2.06 58.84-5.51c3.88-.72 3.88-1.7 3.88-5.39a2.35 2.35 0 0 1 1.61-2.23c1.69-.55 2-1.64 2.5-3.67a15 15 0 0 1 .49-1.73 17 17 0 0 1-1.75-2c-1.05-1.33-1.79-2.2-2.54-2.2a3 3 0 0 1-2.16-.45 2.5 2.5 0 0 1-1-1.82c0-1 0-4.12-4.84-4.12a13.7 13.7 0 0 0-4.28.74c-9.52 3.18-26.11 4.72-50.7 4.72l-10.28-.41c-15.81-.58-30.74-1.13-40.41-4.31a13.8 13.8 0 0 0-4.28-.74c-4.85 0-4.85 3.11-4.85 4.12a2.35 2.35 0 0 1-.87 1.83 2.15 2.15 0 0 1-2 .47c-1.26.08-1.84.84-2.89 2.16a15.5 15.5 0 0 1-1.75 2Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M585.49 450.44c-1.88 0-1.88 1 0 1 .84 0 .84-1 0-1"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M585.49 452.73c-2.54 0-2.67-1.48-2.67-1.78 0-1.06 1.6-1.77 2.67-1.77a1.78 1.78 0 1 1 0 3.55"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M585.49 446.71c-1 0-1 1.88 0 1.88 1.86 0 1.86-1.88 0-1.88"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M585.49 449.85c-.69 0-2-1.51-2-2.21s1.35-2.19 2-2.19a2.24 2.24 0 1 1 0 4.4"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M585.49 460.81c-1 0-1 1.87 0 1.87.84 0 .84-1.87 0-1.87"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M585.49 464c-.69 0-2-1.51-2-2.21s1.35-2.19 2-2.19 1.89 1.51 1.89 2.19-1.19 2.21-1.89 2.21"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M589.21 461.83c-1.86 0-1.86 1.87 0 1.87 1 0 1-1.87 0-1.87"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M589.21 465a2.37 2.37 0 0 1-2.66-2.2c0-1.15 1.51-2.19 2.66-2.19.69 0 2 1.51 2 2.19a2.39 2.39 0 0 1-2 2.2"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M593 462.68c-.84 0-.84 1.86 0 1.86 1.88 0 1.88-1.86 0-1.86"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M593 465.8c-.7 0-1.89-1.49-1.89-2.19s1.19-2.19 1.89-2.19a2.38 2.38 0 0 1 2.67 2.19c0 1.09-.92 2.19-2.67 2.19"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M651.4 453.34c-.87 0-.87 1.86 0 1.86s1-1.86 0-1.86"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M651.4 456.46c-.69 0-1.91-1.49-1.91-2.19s1.22-2.19 1.91-2.19 2 1.49 2 2.19-1.32 2.19-2 2.19"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M652.42 457.07c-1.86 0-1.86.86 0 .86.84 0 .84-.86 0-.86"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M652.42 457.07c-1.86 0-1.86.86 0 .86.84 0 .84-.86 0-.86"
        className="Egypt_svg__cls-5"
      />
      <path
        d="M655.15 466.41c-.87 0-.87 2 0 2s1-2 0-2"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M655.15 469.72c-.7 0-1.92-1.6-1.92-2.29s1.22-2.28 1.92-2.28 2 1.6 2 2.28-1.32 2.29-2 2.29"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M659 466.41c-2 0-2 2 0 2 .87 0 .87-2 0-2"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M659 469.72c-1.82 0-2.78-1.15-2.78-2.29s1-2.28 2.78-2.28c.7 0 1.92 1.6 1.92 2.28 0 1-.93 2.29-1.92 2.29"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M697.62 458.94c-1.89 0-1.89 1 0 1 1 0 1-1 0-1"
        className="Egypt_svg__cls-4"
      />
      <path
        d="M697.62 461.22c-2.54 0-2.67-1.48-2.67-1.77 0-1.07 1.59-1.77 2.67-1.77.69 0 2 1.07 2 1.77s-1.32 1.77-2 1.77M595.65 462.44a6.1 6.1 0 0 1-2.12-.45 5.6 5.6 0 0 1-2.8-1.25 7.5 7.5 0 0 1-3-.61c-.86.1-2.7-1.46-2.7-4.07a48.4 48.4 0 0 0 2.29-7.32l.63-2.36a1.23 1.23 0 0 1 1.55-.88 1.26 1.26 0 0 1 .88 1.55l-.62 2.33a38 38 0 0 1-2.36 7.27 2 2 0 0 0 .78 1c.83.24 1.49.45 1.84.54a12.4 12.4 0 0 1 .57-1.77c.21-1.81.59-2.68 1.3-3a6.6 6.6 0 0 1 .81 1.36l.7.47c0 .07-.09.48-.19.9a.7.7 0 0 1 0 .36 21 21 0 0 1-.71 2.44 6.3 6.3 0 0 0 1.7.57c.71.25 1.44.48 1.8.25s.88-1.26 1.29-3l.38.28-1.32-1.49a2.26 2.26 0 0 1 2-.82 3.74 3.74 0 0 1 1.47 2.31c-.48 2.25-1.09 4.18-2.58 5a3.23 3.23 0 0 1-1.58.38Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M588.19 460.2a1.3 1.3 0 0 1-.33 0c-2.85-.78-4.66-2.38-4.62-4.09 0-1.06.73-2.45 3.85-3.12a1.32 1.32 0 0 1 1.49 1 1.26 1.26 0 0 1-1 1.5c-1.6.33-1.86.78-1.86.78a4.84 4.84 0 0 0 2.77 1.52 1.26 1.26 0 0 1-.33 2.48ZM615.4 467.82a2.66 2.66 0 0 1-1.67-.61c-.23.45-2.17-.07-2.17-3.51a4.36 4.36 0 0 1-2.13-.78c-2.4 1-5.15 1.26-6.85.63a5.76 5.76 0 0 1-2.47 2.14 1.29 1.29 0 0 1-1.66-.63 1.26 1.26 0 0 1 .62-1.66 3.66 3.66 0 0 0 1.58-1.41 4.3 4.3 0 0 1-.46-2 2.5 2.5 0 0 1 0-2.58c2.16-.4 2.82.45 3.43 2.16a7.4 7.4 0 0 1-.13 1.66 6.3 6.3 0 0 0 3.77-.15 3.84 3.84 0 0 1 4-6.2 4 4 0 0 1 2.27 3.79 3.77 3.77 0 0 1-1.53 3.03 14 14 0 0 0 1.48.9c.65 2.55.93 2.55 1.28 2.55.55 0 1.45-1.62 1.45-3.32v-8.49c0-1.39 2.52-1.39 2.52 0v8.49c0 2.31-1.26 5.34-2.94 5.95a1.3 1.3 0 0 1-.35 0ZM620.3 465a1.26 1.26 0 0 1-1.3-1.3 48 48 0 0 1 .54-5.5 40 40 0 0 0 .48-4.86c0-1.39 2.52-1.39 2.52 0a46 46 0 0 1-.51 5.2 41 41 0 0 0-.5 5.16 1.26 1.26 0 0 1-1.23 1.3M623.21 470.56a1.26 1.26 0 0 1-.77-2.25c1.87-1.46 2.65-2.55 3-3.55a6.6 6.6 0 0 1-1.34-1.28 2.8 2.8 0 0 1-.23-3 2.94 2.94 0 0 1 1.74-.78c1 .13 1.94 1.05 2.49 2.55v1h.7s.4-.26.55-1.89a1.6 1.6 0 0 1-.8-1.43c0-.7 1-2 1.68-2s1.34.89 1.58 1.58h1.74c2.08 1 5.86.91 6.59.39 2.29-.45 3.17 1.11 3.17 3.76a14.6 14.6 0 0 1-.66 2c-.63 1.65-1.18 2.91-2.41 3.16a4 4 0 0 1-3-1.52c-.37-1.22-.37-1.5-1.83-1.5h-6.61a7.4 7.4 0 0 1-1.08-.08c-.46 1.36-1.51 2.83-3.75 4.56a1.22 1.22 0 0 1-.77.28Zm8.38-7.28h3.83c3.47 0 4 2 4 3.13 0 0 1-1.91 1-2a6.6 6.6 0 0 0 .21-2 12.12 12.12 0 0 1-7.71-.5 9 9 0 0 1-1.13.05 7 7 0 0 1-.23 1.31Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M645.8 465.8a1.28 1.28 0 0 1-.75-.26 4 4 0 0 0-1.77.07c-.7 0-1.87-.46-1.87-1.16s0-1.17.65-1.17v1.26l.68-1.38c.41-.08.87-.18 1.38-.23a3.2 3.2 0 0 1 .84-2.57 2.83 2.83 0 0 1 3.11-.56 3.06 3.06 0 0 1 1.87 2.88c0 2-2.86 3.09-4.09 3.12ZM653.39 465.57a2.08 2.08 0 0 1-2.23-1.87 5.09 5.09 0 0 1 3.39-2.58 21 21 0 0 0-.62-7.44 1.29 1.29 0 0 1 .88-1.56 1.26 1.26 0 0 1 1.55.89 23.7 23.7 0 0 1 .64 8.72c.28 0 1.87.71 2 .71.92-.1 1.32-.42 1.44-2.11-.57-.78-1.22-2-1-2.4a2 2 0 0 1 2-.86 2.93 2.93 0 0 1 1.62 1.87v.86a1.4 1.4 0 0 1 0 .4c-.16 3.7-1.13 4.76-4 4.76a3 3 0 0 1-3-.1l-.17-.17a4 4 0 0 1-2.44.88ZM664.66 468.7a1.25 1.25 0 0 1-.88-.37 1.23 1.23 0 0 1 0-1.77 11 11 0 0 0 2.1-3.24c-2.13-1.24-2.48-2.61-2.48-3.36 0-.54 0-2.16 1.28-2.67s2.49.59 3.61 1.83c1.55 2.78-.63 7.09-2.74 9.21a1.27 1.27 0 0 1-.89.37M671.28 466.83a1.28 1.28 0 0 1-1.18-.81 1.26 1.26 0 0 1 .73-1.62 2.6 2.6 0 0 0 1.65-1.32c-2 .14-2.71-.84-2.94-1.74a3.3 3.3 0 0 1 2-3.5 5 5 0 0 1 2.13-.41c1 .41 1.55 1.53 1.61 3.09a32.2 32.2 0 0 0 11.33-3.67 1.22 1.22 0 0 1 1.72.42 1.24 1.24 0 0 1-.42 1.73 32.1 32.1 0 0 1-12.75 3.92 4.79 4.79 0 0 1-3.4 3.83 1.5 1.5 0 0 1-.45.08Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M682.59 463.29a3.75 3.75 0 0 1-1.48-.31 13.6 13.6 0 0 1-1.64-3.62 3.3 3.3 0 0 0-.32 1.45c0 1.4-2.52 1.4-2.52 0a5.07 5.07 0 0 1 2-4.44c0-.24-.11-.5-.17-.75l-1-3.81a1.29 1.29 0 0 1 .89-1.56 1.25 1.25 0 0 1 1.55.89l1 3.9c0 .14.07.28.1.41a4.2 4.2 0 0 1 2.23.29 3.51 3.51 0 0 1 2.35 3.2 2.82 2.82 0 0 1-.59 3.3 3.35 3.35 0 0 1-2.44 1.05ZM689.54 460.63a3.48 3.48 0 0 1-3.48-2.28 5.2 5.2 0 0 1 .13-2.21c1.49-2.48 5.83-2.69 7.55-1a3.25 3.25 0 0 1-.13 3.48 5.14 5.14 0 0 1-4.07 2Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M692.86 457.07a21 21 0 0 0 2.87-1.87Z"
        className="Egypt_svg__cls-5"
      />
      <path
        d="M697.39 457.43a3.4 3.4 0 0 1-1.53-.37c-2.12-1.11-3.25-4-3.25-8.47 0-.93-.27-1.51-.81-1.73a2.61 2.61 0 0 0-2.64.74 1.25 1.25 0 1 1-1.77-1.77 5 5 0 0 1 5.35-1.29 4.06 4.06 0 0 1 2.4 4c0 3.87 1 5.77 1.9 6.25a1.75 1.75 0 0 0 1.22.07 1 1 0 0 1 .11-.32c.28-.49 1.63-1.93 2.26-1.7s1.12 1.73 1.12 2.31c-1.9 1.73-3.31 2.23-4.36 2.23Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="m640.12 365.82 81.08 75.28-5.74-136.73c-.87-20.72-18.89-16-31.94-8.49-13.26 8.49-28.37 8.49-44.36 2.88-15.95 5.61-31.07 5.61-44.33-2.88-13.08-7.48-31.1-12.23-31.94 8.49l-5.77 136.73Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M557.12 442.36a1.2 1.2 0 0 1-.54-.13 1.25 1.25 0 0 1-.72-1.19l5.77-136.72c.55-13.29 7.88-16.07 13.94-16.07 7.34 0 15.61 4.09 19.89 6.54 12.12 7.77 26.6 8.67 43.28 2.78 18.66 5.89 32.07 5 44.1-2.75 4.32-2.47 12.57-6.57 19.91-6.57 6.07 0 13.4 2.78 14 16.07L722.46 441a1.28 1.28 0 0 1-.74 1.21 1.23 1.23 0 0 1-1.37-.24l-80.25-74.44L558 442a1.22 1.22 0 0 1-.84.33Zm18.45-151.61c-7.31 0-11.05 4.49-11.41 13.68l-5.66 133.73 80.78-73.27a1.23 1.23 0 0 1 1.69 0l78.85 73.2-5.62-133.66c-.64-15.47-12.84-17.32-30.06-7.47-12.6 8.1-27.93 9.13-45.4 3-15.52 6.14-31.91 5.11-44.59-3-6.95-4-13.57-6.18-18.58-6.18Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M652.42 303.52c4.58 2.71 9.34 2.71 12.24 2.71-9.51-6.61-12.24-24.45-11.4-31.92q2.84-22.69-11.2-25.49c-1.88 0-2.9-1-6.64-1-2.87-.84-5 1.88-6.06 3.75-2.88-1.87-5.31-.86-8-2.73-2.87-1-7.63 0-7.63 6.63l1.83-.79a19.8 19.8 0 0 1 4.32 2.11c2.47 1.93 6.06 3.26 7.08 9.89 1.89 14.1 0 27.34-13.23 39.57 2.87 0 7.63 0 12.21-2.71a30.6 30.6 0 0 1-4.58 5.6c5.6-1 10.39-2.89 13.08-7.47 1 1.87 3.75 4.58 4.76 6.45 1-1.87 3.74-4.58 4.76-6.45 2.73 4.58 7.48 6.45 13.08 7.47a33 33 0 0 1-4.58-5.6Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="m617.78 311 2.71-2.68c.73-.71 1.29-1.29 1.82-1.9a28.5 28.5 0 0 1-8.62 1h-3l2.2-2c11.24-10.4 15.09-21.94 12.87-38.56-.77-5-3.08-6.63-5.31-8.17l-1.33-.95a21.5 21.5 0 0 0-3.78-1.87l-2.82 1.4v-1.78c0-7.56 5.52-9.07 9.19-7.73a8.4 8.4 0 0 0 3.77 1.3 12.7 12.7 0 0 1 3.46.95c1-1.5 2.84-3.5 5.41-3.5a23 23 0 0 1 5.87.84 6.9 6.9 0 0 0 1.84.34c10.24 2 14.33 11 12.36 26.8-.87 7.73 2 24.65 10.91 30.81l3.07 2.15h-3.74a28.6 28.6 0 0 1-8.63-1c.52.61 1.09 1.2 1.8 1.91l2.67 2.64-3.7-.67c-4.29-.79-9.5-2.24-12.87-6.53-.45.6-1 1.23-1.48 1.87a23 23 0 0 0-2.26 3.05l-1 1.9-1-1.9a23 23 0 0 0-2.26-3.05c-.51-.64-1-1.27-1.48-1.87-3.34 4.29-8.56 5.74-12.87 6.53l-3.74.68Zm26.1-11.69 1 1.78c1.75 2.92 4.41 4.83 8.66 6.07-.63-.79-1.29-1.69-2.14-2.93l1.6-1.73a19 19 0 0 0 8.35 2.46c-7.94-8.1-10.06-23.75-9.26-30.79 1.8-14.54-1.55-22.45-10.27-24.2-.71.37-1.46-.19-2.23-.4a20 20 0 0 0-4.46-.59 5.54 5.54 0 0 0-4.75 3.13l-.61 1.12-1.06-.7a8.8 8.8 0 0 0-3.61-1.19 10 10 0 0 1-4.46-1.55c-.72-.14-4.7-1.38-5.63 3.82 1.61.06 2.88.77 4.63 1.74l2.1 1.4c2.4 1.65 5.37 3.71 6.3 9.76 1.48 11 1.42 25.22-11.43 38.49a19.2 19.2 0 0 0 8.66-2.48l1.57 1.69c-.86 1.24-1.51 2.14-2.14 2.93 4.27-1.25 6.94-3.15 8.65-6.07l1.06-1.8 1 1.83a21.7 21.7 0 0 0 2.27 3.05c.5.64 1 1.26 1.46 1.85.45-.59 1-1.21 1.47-1.85a22.3 22.3 0 0 0 2.26-3.05l1-1.81Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M653 305.64a1.27 1.27 0 0 1-1.12-.7c-2.77-5.41-5.48-11.37-5.48-19.42 0-1.4 2.52-1.4 2.52 0 0 7.5 2.45 12.92 5.2 18.29a1.24 1.24 0 0 1-.55 1.68 1.2 1.2 0 0 1-.57.15M643.92 302.91a1.26 1.26 0 0 1-1.06-.58c-2-3.13-3.08-8.94-3.08-16.81 0-1.4 2.53-1.4 2.53 0 0 7.28.94 12.76 2.67 15.45a1.27 1.27 0 0 1-.38 1.74 1.34 1.34 0 0 1-.68.2M625.32 305.64a1.3 1.3 0 0 1-.58-.15 1.25 1.25 0 0 1-.53-1.69c2.62-5.1 5.19-10.7 5.19-18.28 0-1.4 2.52-1.4 2.52 0 0 8.15-2.71 14.07-5.48 19.44a1.27 1.27 0 0 1-1.12.68M634.4 302.91a1.34 1.34 0 0 1-.68-.2 1.27 1.27 0 0 1-.38-1.74c1.75-2.72 2.7-8.2 2.7-15.45 0-1.4 2.53-1.4 2.53 0 0 7.85-1.08 13.65-3.11 16.81a1.25 1.25 0 0 1-1.06.58M614.3 254.56c2 .13 2.87.82 5.54 2.21a12.39 12.39 0 0 1 7.08 9c-1-2.88.87-5.77 2.9-4.75-2.9-6.62-11.78-8.36-15.52-6.49Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M626.92 266.4a.59.59 0 0 1-.55-.41.15.15 0 0 1 0-.08c-.06-.23-.19-.84-.25-1.1a11.9 11.9 0 0 0-6.48-7.47l-1.07-1.25-.28.52a8.8 8.8 0 0 0-4-1.45c.78-1.61 2.11-1.87 3.61-1.87 4.42 0 10.21 2.35 12.49 7.55.08.17-.35.29-.47.45s-.65.15-.88.15c-1.55 1.12-1.84 2.14-1.74 3.15a.63.63 0 0 1-.09.39 2.2 2.2 0 0 1 .35.74.57.57 0 0 1-.44.67.4.4 0 0 1-.14 0Zm-10.59-11.84a6.7 6.7 0 0 1 2.5 1l1.34.71a13.62 13.62 0 0 1 6.24 6 4 4 0 0 1 .7-1.13 2.53 2.53 0 0 1 1.87-.9c-2-4.18-8.68-6.38-12.65-5.69ZM642.9 380.8l11.38 51.82-3.75 3.74-3.88-2.89-6.47-46.21 2.72 46.21-3.74 4.74-3.74-4.74 2.73-46.21-6.47 46.21-3.89 2.89-3.74-3.74 11.37-51.82z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="m639.16 439.93-4.57-5.81 1.18-22.23-3 21.73-4.29 3.59-5.14-3.84 11.09-52.8 8.52-.83 12.42 52.64-4 4.73-5.37-2.78-3.34-22.73 1.34 21.81-4.8 6.52Zm0-51.89-2.68 45.49 2.64 3 3-3.74-2.96-44.79Zm2.7-7-6.44.82-10.33 51 3.45 2.75 2.5-3 6.06-45.51 2.06.21 2.06-.21 6.49 46.22 3.46 2.17 2.35-3.64L641.86 381ZM572.24 299.62l-5.6 133-9.52 8.48 5.77-136.73c2.7-1.87 7.46-4.75 9.35-4.75M583.61 309.12l-4.76 111.27-9.52 9.73 5.78-128.47c1.88 1.87 7.48 6.45 8.5 7.47M594 317.62l-3.71 92.79-7.66 7.47L587.35 312c1.86 1.88 5.6 4.6 6.61 5.62ZM605.19 322.38l-3.74 79.08-7.45 6.06 3.75-88c1.88.84 5.6 2.89 7.48 2.89ZM615.55 322.38l-2.73 65.89-7.63 7.49 2.87-72.54c1.89 0 6.65 0 7.49-.84M706.09 299.62l5.63 133 9.48 8.48-5.77-136.73c-2.7-1.87-7.46-4.75-9.34-4.75M694.72 309.12l4.75 111.27 9.52 8.32-5.77-127.06c-1.89 1.87-7.49 6.45-8.5 7.47M684.36 317.62l3.92 92.79 7.45 6.06L691 312c-1.85 1.86-5.59 4.58-6.61 5.6ZM673.13 322.38l3.75 77.67 7.48 6.06-3.74-86.62c-1.86.84-5.6 2.89-7.49 2.89M662.78 322.38l2.72 65.89 7.63 7.49-2.87-72.54c-1.89 0-6.64 0-7.48-.84"
        className="Egypt_svg__cls-3"
      />
      <path
        d="m613.69 358.04-22.6 80.17 31.1 4.76 13.23-60.3z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M622.19 444.15a.5.5 0 0 1-.17 0l-31.1-4.76a1.11 1.11 0 0 1-.81-.52 1.15 1.15 0 0 1-.15-1l22.6-80.18a1.15 1.15 0 0 1 .87-.82 1.31 1.31 0 0 1 1.15.36l21.72 24.63-13 61.33a1.18 1.18 0 0 1-1.15.93Zm-29.6-6.89 28.69 4.38 13-59.21-20-22-21.65 76.85Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="m664.66 358.04 22.57 80.17-31.07 4.76-13.26-60.3z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M656.16 444.15a1.16 1.16 0 0 1-1.14-.93l-13.26-60.3 22-25.66a1.29 1.29 0 0 1 1.14-.36 1.19 1.19 0 0 1 .87.82l22.57 80.18a1.16 1.16 0 0 1-1 1.47l-31.07 4.76a.6.6 0 0 1-.18 0Zm8-83.71-20.32 23 13.27 58.19 28.68-4.39-21.63-76.82ZM608.93 393.28h-.21a1.26 1.26 0 0 1-1-.76l-2.72-6.61a1.25 1.25 0 0 1 .68-1.64 1.21 1.21 0 0 1 1.64.68l2 4.79 7.16-7.88a1.25 1.25 0 0 1 1.86 1.68l-8.5 9.35a1.3 1.3 0 0 1-.93.42Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M619.46 398.89a.75.75 0 0 1-.33-.06 1.23 1.23 0 0 1-.91-1.07l-2.9-26.33a1.25 1.25 0 0 1 1.11-1.38 1.29 1.29 0 0 1 1.38 1.12l2.55 23.21 7.44-9.75a1.29 1.29 0 0 1 1.77-.23 1.27 1.27 0 0 1 .24 1.77l-9.35 12.23a1.28 1.28 0 0 1-1 .49"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M630.66 405.5a1.25 1.25 0 0 1-1.16-.79l-5.6-14.1a1.25 1.25 0 0 1 .7-1.63 1.18 1.18 0 0 1 1.62.7l5.6 14.1a1.24 1.24 0 0 1-.69 1.62 1 1 0 0 1-.47.1M622.19 416.89a1 1 0 0 1-.3 0 1.23 1.23 0 0 1-.9-.84l-3.86-12.29-3.21 5.88c-.23.42-.72.94-1.16.65a1.24 1.24 0 0 1-1.1-.77l-3.21-7.7-7 6.28a1.27 1.27 0 0 1-1.77-.09 1.25 1.25 0 0 1 .09-1.77l8.32-7.47a1.05 1.05 0 0 1 1.12-.29 1.26 1.26 0 0 1 .88.74l2.91 6.88 3.36-6.18a1.51 1.51 0 0 1 1.22-.65 1.26 1.26 0 0 1 1.09.87l4.13 13.14 6.17-6.18a1.25 1.25 0 0 1 1.77 1.77l-7.64 7.64a1.2 1.2 0 0 1-.88.38ZM625.06 433.88a1.27 1.27 0 0 1-1.23-1l-2.34-11.6-5.8 7.38a1.15 1.15 0 0 1-1.2.45 1.24 1.24 0 0 1-1-.84l-3-9.15-5.16 6.64a1.5 1.5 0 0 1-1.16.48 1.25 1.25 0 0 1-1-.77l-5.8-14.1a1.26 1.26 0 0 1 .68-1.64 1.23 1.23 0 0 1 1.64.68l5 12.1 5.3-6.79a1.38 1.38 0 0 1 1.2-.46 1.23 1.23 0 0 1 1 .84l3 9.15 6-7.67a1.16 1.16 0 0 1 1.28-.44 1.27 1.27 0 0 1 .94 1l2.88 14.27a1.27 1.27 0 0 1-1 1.48 2 2 0 0 1-.25 0ZM617.43 444.23a1.5 1.5 0 0 1-.35-.05 1.25 1.25 0 0 1-.84-.82l-2.88-8.74-4.21 7.12c-.25.42-.72.87-1.19.61a1.24 1.24 0 0 1-1.07-.8L603 431.31l-4.24 5.79a1.28 1.28 0 0 1-1.75.27 1.24 1.24 0 0 1-.28-1.75l5.6-7.65a1.39 1.39 0 0 1 1.17-.5 1.24 1.24 0 0 1 1 .79l3.83 10 4.27-7.26a1.24 1.24 0 0 1 1.22-.61 1.27 1.27 0 0 1 1.06.86l3.18 9.63 4.35-3.6a1.26 1.26 0 0 1 1.59 1.95l-5.77 4.75a1.25 1.25 0 0 1-.8.29ZM669.39 393.28a1.27 1.27 0 0 1-.93-.42l-8.46-9.35a1.24 1.24 0 0 1 .09-1.76 1.23 1.23 0 0 1 1.77.08l7.13 7.86 2-4.77a1.21 1.21 0 0 1 1.64-.68 1.27 1.27 0 0 1 .68 1.64l-2.73 6.61a1.26 1.26 0 0 1-.94.76h-.22Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M659 398.89a1.21 1.21 0 0 1-1-.49l-9.52-12.23a1.25 1.25 0 0 1 .22-1.76 1.21 1.21 0 0 1 1.75.22l7.6 9.76 2.41-23.22a1.26 1.26 0 0 1 2.5.26l-2.73 26.33a1.23 1.23 0 0 1-.9 1.07.8.8 0 0 1-.35.06Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M647.66 405.5a1 1 0 0 1-.46-.1 1.24 1.24 0 0 1-.7-1.62l5.6-14.1a1.19 1.19 0 0 1 1.62-.7 1.25 1.25 0 0 1 .7 1.63l-5.6 14.1a1.25 1.25 0 0 1-1.16.79M656.16 416.89a1.3 1.3 0 0 1-.9-.38l-7.48-7.64a1.27 1.27 0 0 1 0-1.78 1.29 1.29 0 0 1 1.79 0l5.9 6 3.21-12.92a1.22 1.22 0 0 1 1-.93 1.27 1.27 0 0 1 1.26.54l4.31 6.51 3-7.15a1.24 1.24 0 0 1 .87-.74 1.2 1.2 0 0 1 1.12.28l8.5 7.47a1.25 1.25 0 0 1-1.66 1.88l-7.18-6.31-3.22 7.72a1.26 1.26 0 0 1-1 .77 1.35 1.35 0 0 1-1.16-.55l-4-6.09-3.06 12.32a1.27 1.27 0 0 1-.89.92 1.2 1.2 0 0 1-.33 0ZM653.26 433.88a2 2 0 0 1-.25 0 1.27 1.27 0 0 1-1-1.48l2.9-14.27a1.26 1.26 0 0 1 .94-1 1.16 1.16 0 0 1 1.28.45l6 7.66 3-9.15a1.24 1.24 0 0 1 1-.84 1.3 1.3 0 0 1 1.21.46l5.3 6.79 5-12.1a1.21 1.21 0 0 1 1.63-.68 1.26 1.26 0 0 1 .69 1.64l-5.78 14.1a1.27 1.27 0 0 1-1 .77 1.41 1.41 0 0 1-1.16-.48l-5.2-6.64-3 9.15a1.27 1.27 0 0 1-1 .84 1.19 1.19 0 0 1-1.21-.46l-5.77-7.37-2.37 11.6a1.26 1.26 0 0 1-1.23 1ZM660.92 444.23a1.25 1.25 0 0 1-.8-.29l-5.77-4.75a1.26 1.26 0 1 1 1.59-1.95l4.36 3.6 3.17-9.63a1.27 1.27 0 0 1 1.06-.86 1.35 1.35 0 0 1 1.22.61l4.26 7.25 3.83-9.95a1.25 1.25 0 0 1 1-.79 1.41 1.41 0 0 1 1.17.5l5.6 7.65a1.25 1.25 0 0 1-.27 1.75 1.29 1.29 0 0 1-1.76-.27l-4.23-5.79-3.93 10.24a1.26 1.26 0 0 1-1.08.8c-.43.26-.94-.19-1.19-.61l-4.15-7.11-2.87 8.73a1.25 1.25 0 0 1-.84.82 1.4 1.4 0 0 1-.35.05Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M639.16 312c9.52 7.49 21.76 11.22 35.86 10.38-1 32.95-10.36 55.54-35.86 72.52-25.47-17-34.81-39.57-35.85-72.52 14.12.84 26.51-2.89 35.85-10.38"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M639.16 395.79a.94.94 0 0 1-.49-.15c-24.3-16.2-35.13-38.1-36.25-73.24a10.9 10.9 0 0 1 4.77-.79c12.16 0 23-3.56 31.42-10.29A55 55 0 0 0 675 321.49c-.15 36.05-11 57.94-35.31 74.15a.94.94 0 0 1-.49.15Zm-35.91-72.53c2 33.35 12.49 54.76 35.91 70.58 23.46-15.82 33.93-37.23 35-71.49-.39 1-1.69 1-3 1-12.41 0-23.65-3.7-32.54-10.68-7.61 7-18.86 10.68-31.42 10.68-1.29 0-2.61-.05-3.94-.12Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M639.16 391.17c19.87-13.25 31.1-35.84 32.12-66.08-12.25 0-24.63-2.71-32.12-9.34-8.5 6.63-19.7 9.34-32.95 9.34 1.85 30.24 13.25 52.83 32.95 66.08"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M639.16 392.35a1.16 1.16 0 0 1-.65-.2c-20-13.48-31.61-36.64-33.48-67a1.25 1.25 0 0 1 .32-.88 1.17 1.17 0 0 1 .86-.37c13.83 0 24.38-3 32.23-9.09a1.23 1.23 0 0 1 1.51 0c6.48 5.76 17.91 9.05 31.33 9.05a1.2 1.2 0 0 1 1.17 1.22c-1 30.27-12.3 53.45-32.64 67a1.2 1.2 0 0 1-.65.2Zm-31.71-66.1c2 28.65 13 50.56 31.71 63.5 19-13 29.66-34.93 30.9-63.5-8.21-.12-21.91-1.55-30.94-9-7.94 5.87-18.32 8.83-31.67 9"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M607.05 438.21c7.66 1 16.16 1.87 22.77 1 3.74 0 6.44 6.46-1 7.47-6.62.85-17 0-22.6-1-4.76-.86-15.12-2.73-21.76-4.6-6.62-2.89-1.86-8.48 1.88-7.64a124 124 0 0 0 20.72 4.75Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M622.38 448.31a100.6 100.6 0 0 1-16.38-1.38c-4.53-.81-15.1-2.71-21.88-4.62-4.45-1.92-4.36-4.73-4.13-5.85a5.86 5.86 0 0 1 5.57-4.34 158 158 0 0 0 21.66 4.88c5.92.79 15.54 1.91 22.44 1a5.16 5.16 0 0 1 5.1 4.68c.15 2.15-1.3 4.67-5.8 5.28a54 54 0 0 1-6.6.35Zm-36.5-13.66a3.42 3.42 0 0 0-3.43 2.32c-.32 1.5 1.43 2.51 2.51 3 6.49 1.81 17 3.69 21.46 4.51 5.11.91 15.52 1.85 22.22 1 2.4-.32 3.72-1.28 3.63-2.61a2.61 2.61 0 0 0-2.45-2.35c-6.6.85-16.87-.24-23-1.05a125.5 125.5 0 0 1-20.93-4.81Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M671.28 438.21c-7.66 1-16.13 1.87-22.6 1-3.92 0-6.62 6.46.84 7.47 6.64.85 17 0 22.6-1 4.76-.86 15.11-2.73 21.75-4.6 6.62-2.89 1.86-8.48-1.88-7.64a123 123 0 0 1-20.71 4.75Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M656 448.31a54 54 0 0 1-6.6-.35c-4.5-.61-6-3.1-5.8-5.23a5.26 5.26 0 0 1 5.12-4.75c7.32.92 16.52-.2 22.42-1a123 123 0 0 0 20.5-4.7 5.93 5.93 0 0 1 6.74 4.19c.23 1.12.32 3.93-4 5.79-7 2-17.52 3.87-22 4.68a100.3 100.3 0 0 1-16.38 1.37m-7.44-7.82a2.73 2.73 0 0 0-2.45 2.39c-.09 1.33 1.23 2.27 3.62 2.59 6.65.82 17.12-.08 22.21-1 4.48-.82 15-2.7 21.64-4.57.9-.42 2.66-1.43 2.34-2.93a3.37 3.37 0 0 0-3.11-2.34 140.5 140.5 0 0 1-21.32 4.83c-6.63.89-16 1.92-22.93 1Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M583.61 434.49c-5.78-1-8.5 5.59-6.62 9.34.84-1.87 4.76-1.87 5.6-3.75 1-2.88-.84-2.88 1-5.59Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M577 445.09a1.23 1.23 0 0 1-1.11-.7 8.32 8.32 0 0 1 .56-7.82 7 7 0 0 1 6-3.45c.42.08 1.46.42 1.62.82s.81.91.57 1.26c-.74 1.09-.69 1.4-.58 2a6 6 0 0 1-.28 3.27c-.67 1.49-2.19 2.08-3.52 2.62-.9.35-1.9.74-2.12 1.22a1 1 0 0 1-1.15.74Zm4.59-9.23a4.4 4.4 0 0 0-3 2 6.35 6.35 0 0 0-.9 3.62 16 16 0 0 1 1.66-.74c.9-.35 1.9-.74 2.12-1.22a3.25 3.25 0 0 0 .13-1.91 4.5 4.5 0 0 1 0-1.79Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M606.21 451.85c0-3.73 3.74-3.26 3.74-7 0-1.87-1-4.76-2.9-4.76a4 4 0 0 0-3.74 3.75c-.85 3.73 2.9 4.29 2.9 8Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M606.21 453.11a1.26 1.26 0 0 1-1.27-1.26 4.55 4.55 0 0 0-1.33-3c-.94-1.21-2.1-2.7-1.52-5.27-.05-2.29 2.39-4.73 5-4.73 2.84 0 4.16 3.6 4.16 6a5.46 5.46 0 0 1-2.31 4.46 3 3 0 0 0-1.43 2.55 1.26 1.26 0 0 1-1.26 1.26Zm.84-11.76a2.81 2.81 0 0 0-2.48 2.48 3.45 3.45 0 0 0 1 3.43c.23.32.49.64.74 1a12 12 0 0 1 .91-.86 3 3 0 0 0 1.44-2.57c0-1.64-.87-3.49-1.64-3.49Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M633.1 441.1c5.6 0 5 7.49 2.32 11.21 0-2.71-4.76-3.72-4.76-5.6 0-2.88 4.29-2.88 2.44-5.61"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M635.42 453.57a1.1 1.1 0 0 1-.39-.07 1.25 1.25 0 0 1-.87-1.19c0-.82-1.09-1.58-2.16-2.3-1.28-.87-2.6-1.79-2.6-3.3a4.66 4.66 0 0 1 2.13-3.64 2.74 2.74 0 0 1 .19-1.93c.22-.4.91-1.3 1.38-1.3a5.12 5.12 0 0 1 4.83 2.81c1.57 3.09.48 7.72-1.5 10.4a1.23 1.23 0 0 1-1 .52Zm-.64-10.8a4 4 0 0 1-1.65 2.25c-.77.63-1.21 1-1.21 1.69a6.6 6.6 0 0 0 1.5 1.22 12.6 12.6 0 0 1 2.06 1.64c.78-2 .93-4.39.21-5.79a2.5 2.5 0 0 0-.91-1Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M694.72 434.49c5.77-1 8.5 5.59 6.61 9.34-.84-1.87-4.76-1.87-5.6-3.75-1-2.88.84-2.88-1-5.59Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M701.33 445.09c-.59-.13-.94-.29-1.15-.74s-1.21-.87-2.11-1.22c-1.34-.54-2.86-1.13-3.48-2.52a5.93 5.93 0 0 1-.32-3.37c.1-.64.16-.95-.58-2a1.25 1.25 0 0 1 .81-1.95c4.06-.29 6 1.15 7.38 3.32a8.36 8.36 0 0 1 .57 7.82 1.26 1.26 0 0 1-1.12.7Zm-4.61-9.37a4.35 4.35 0 0 1 0 1.93 3.27 3.27 0 0 0 .17 2c.17.38 1.18.77 2.08 1.12a16 16 0 0 1 1.65.74 6.2 6.2 0 0 0-.9-3.62 4.58 4.58 0 0 0-3-2.18Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M672.12 451.85c0-3.73-3.75-3.26-3.75-7 0-1.87 1-4.76 2.91-4.76a4 4 0 0 1 3.74 3.75c.84 3.73-2.9 4.29-2.9 8Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M672.12 453.11a1.25 1.25 0 0 1-1.26-1.26 3 3 0 0 0-1.44-2.55 5.48 5.48 0 0 1-2.31-4.46c0-2.42 1.32-6 4.17-6a5.28 5.28 0 0 1 5 5c.54 2.29-.62 3.78-1.57 5a4.55 4.55 0 0 0-1.33 3 1.26 1.26 0 0 1-1.26 1.26Zm-.84-11.76c-.77 0-1.64 1.85-1.64 3.49a3 3 0 0 0 1.43 2.57c.29.25.61.54.92.86.24-.37.51-.69.74-1 .84-1.05 1.37-1.75 1.07-3.16a2.92 2.92 0 0 0-2.52-2.75Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M645.22 441.1c-5.59 0-5 7.49-2.32 11.21 0-2.71 4.76-3.72 4.76-5.6 0-2.88-4.29-2.88-2.44-5.61"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M642.9 453.57a1.24 1.24 0 0 1-1-.52c-2-2.68-3.06-7.31-1.5-10.4a5.12 5.12 0 0 1 4.83-2.81 1.26 1.26 0 0 1 1.12.68 1.25 1.25 0 0 1-.07 1.29c1.36 2 2.65 3 2.65 4.9 0 1.51-1.32 2.43-2.59 3.3-1.08.72-2.16 1.48-2.16 2.3a1.25 1.25 0 0 1-.87 1.19 1.1 1.1 0 0 1-.4.07Zm.64-10.8a2.5 2.5 0 0 0-.91 1c-.71 1.4-.57 3.75.22 5.79a12 12 0 0 1 2.06-1.64 6.3 6.3 0 0 0 1.5-1.24c0-.66-.45-1-1.21-1.67a4 4 0 0 1-1.66-2.25Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M585.49 436.36c1.86 0 4.76.84 5.6 1.85Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M591.09 439.1a.8.8 0 0 1-.27 0l-5.6-1.86a.9.9 0 0 1-.6-1 .89.89 0 0 1 .87-.74c1.87 0 5.17.83 6.28 2.18a.9.9 0 0 1 .05 1.06.89.89 0 0 1-.73.39Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M594.83 439.24a20.7 20.7 0 0 1 6.62 1.86Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M601.45 442h-.23l-6.62-1.86a.9.9 0 0 1-.64-1c.19-1.6 5.21-.28 7.91 1.2a.89.89 0 0 1-.42 1.66"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M628.81 443c-1.89 0-5.6 0-6.62.86Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M622.19 444.71a.87.87 0 0 1-.56-1.55c1-.9 3.52-1.07 7.18-1.07a.88.88 0 0 1 .11 1.75l-6.61.86Z"
        className="Egypt_svg__cls-3"
      />
      <path d="M618.45 443c-1-1-4.76-1-6.65 0Z" className="Egypt_svg__cls-1" />
      <path
        d="M618.45 443.86h-6.65a.89.89 0 0 1-.85-.67.91.91 0 0 1 .43-1c1.67-.87 5.43-2.12 7.69.16a.85.85 0 0 1 .19.95.86.86 0 0 1-.81.56"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M692.86 436.36a9.8 9.8 0 0 0-5.63 1.85Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M687.23 439.1a.87.87 0 0 1-.75-.44.85.85 0 0 1 .14-1.08c1.29-1.25 4.27-2.11 6.24-2.11a.88.88 0 0 1 .87.74.91.91 0 0 1-.59 1l-5.63 1.86a.9.9 0 0 1-.28 0Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M683.52 439.24a20.8 20.8 0 0 0-6.64 1.86Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M676.88 442a.89.89 0 0 1-.43-1.67c2.86-1.53 7.71-2.77 7.94-1.19a.88.88 0 0 1-.64 1l-6.64 1.86z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M649.52 443c1.88 0 5.63 0 6.64.86Z"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M656.16 444.71h-.11l-6.65-.86a.88.88 0 0 1 .12-1.75c3.67 0 6.16.17 7.21 1.07a.88.88 0 0 1-.57 1.55Z"
        className="Egypt_svg__cls-3"
      />
      <path d="M659.88 443c1-1 4.78-1 6.64 0Z" className="Egypt_svg__cls-1" />
      <path
        d="M666.52 443.86h-6.64a.87.87 0 0 1-.82-.56.89.89 0 0 1 .21-1c2.33-2.25 6.07-1 7.67-.13a.86.86 0 0 1 .43 1 .88.88 0 0 1-.85.67ZM652.42 257.32c-1.89-4.76-5.25-8.51-9.84-9.37-2.35-.71-4.78-.51-7.51-.51-2.9-.84-5.25 2.24-6.26 4.11 2.87 2 3.53 4.1 5 4.74 2.88 1 4.33 0 7.23-2.71 2.87-2.89 9.48-2 11.37 3.74Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M635.8 257.28a6.5 6.5 0 0 1-2.18-.44 6 6 0 0 1-2.12-1.84 15 15 0 0 0-3-2.93c.39-1.83 2.53-5.31 5.61-5.31l2.47.13a16.6 16.6 0 0 1 6.21.53c3.87.73 7.25 3.59 9.47 8.05-3.7-2.58-8.4-3.85-10.76-1.43-2.13 2-3.66 3.28-5.67 3.28Zm-1-9.26c-2.75 0-4.45 2-5.45 3.8a6.35 6.35 0 0 1 3.07 2.38 5.2 5.2 0 0 0 1.66 1.55c2.43.84 3.58.22 6.58-2.61 2.09-2.1 6.27-2.26 9-.46a14.34 14.34 0 0 0-7.15-4.15 15.7 15.7 0 0 0-4.54-.52h-2.87a1.4 1.4 0 0 0-.29 0Z"
        className="Egypt_svg__cls-3"
      />
      <path
        d="M634.4 250a1.45 1.45 0 0 0 0 2.9 1.45 1.45 0 0 0 0-2.9"
        className="Egypt_svg__cls-1"
      />
      <path
        d="M634.4 254.17a2.63 2.63 0 0 1-2.65-2.71c0-3.08 5.31-3.08 5.31 0a2.63 2.63 0 0 1-2.66 2.71M650.71 322v59.28a64 64 0 0 1-11.55 9.86 65 65 0 0 1-11.54-9.86v-59.41a44.5 44.5 0 0 0 11.54-6.12 35.8 35.8 0 0 0 11.55 6.25"
        className="Egypt_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgEgypt;
