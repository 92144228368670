import * as React from "react";
import type { SVGProps } from "react";
const SvgAlgeria = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Algeria_svg__Algeria"
    viewBox="0 0 1280 720"
    {...props}
  >
    <defs>
      <clipPath id="Algeria_svg__clip-path">
        <path
          d="M99.5-.33h1081v720.66H99.5Zm584 504.46A144.13 144.13 0 1 0 539.38 360a144.13 144.13 0 0 0 144.13 144.13"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <style>{".Algeria_svg__cls-5{fill:#d21034}"}</style>
    </defs>
    <path
      d="M99.5-.33H1280v720.66H99.5Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0-.33h640v720.66H0Z"
      style={{
        fill: "#006233",
      }}
    />
    <circle
      cx={640}
      cy={360}
      r={180.17}
      className="Algeria_svg__cls-5"
      style={{
        clipPath: "url(#Algeria_svg__clip-path)",
      }}
    />
    <path
      id="Algeria_svg__o"
      d="m803 360-85.67-27.84-16.7 51.41Z"
      className="Algeria_svg__cls-5"
    />
    <path
      id="Algeria_svg__o-2"
      d="m803 360-85.67 27.84-16.7-51.41Z"
      className="Algeria_svg__cls-5"
      data-name="o"
    />
    <path
      id="Algeria_svg__o-3"
      d="M740.72 445.67v-90.08h-54.05Z"
      className="Algeria_svg__cls-5"
      data-name="o"
    />
    <path
      id="Algeria_svg__o-4"
      d="m740.72 445.67-53-72.87 43.77-31.8Z"
      className="Algeria_svg__cls-5"
      data-name="o"
    />
    <path
      id="Algeria_svg__o-5"
      d="m740.72 274.33-53 72.87 43.77 31.8Z"
      className="Algeria_svg__cls-5"
      data-name="o"
    />
    <path
      id="Algeria_svg__o-6"
      d="M740.72 274.33v90.08h-54.05Z"
      className="Algeria_svg__cls-5"
      data-name="o"
    />
    <path
      id="Algeria_svg__o-7"
      d="m640 413 85.67-27.84L709 333.71Z"
      className="Algeria_svg__cls-5"
      data-name="o"
    />
    <path
      id="Algeria_svg__o-8"
      d="m640 413 53-72.88 43.73 31.77Z"
      className="Algeria_svg__cls-5"
      data-name="o"
    />
    <g id="Algeria_svg__t">
      <path
        id="Algeria_svg__o-9"
        d="m640 307.05 53 72.88 43.73-31.77Z"
        className="Algeria_svg__cls-5"
        data-name="o"
      />
      <path
        id="Algeria_svg__o-10"
        d="m640 307.05 85.67 27.84-16.67 51.4Z"
        className="Algeria_svg__cls-5"
        data-name="o"
      />
    </g>
  </svg>
);
export default SvgAlgeria;
