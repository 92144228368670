import * as React from "react";
import type { SVGProps } from "react";
const SvgKiribati = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Kiribati_svg__cls-7{fill:#fcd116;stroke:#bd9c08}.Kiribati_svg__cls-9{stroke:#bd9c08;stroke-linecap:round}.Kiribati_svg__cls-6{fill:#fff}.Kiribati_svg__cls-7,.Kiribati_svg__cls-9{stroke-width:1.2px}.Kiribati_svg__cls-9{fill:none}"
        }
      </style>
    </defs>
    <g id="Kiribati_svg__Kiribati">
      <path
        id="Kiribati_svg__rect3015"
        d="M0-.25h1280v720.5H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        id="Kiribati_svg__path6046"
        d="M758.12 339.08c21 5.64 52.87 4.24 67.16 20.92-21-5.64-47 28.36-67.16 20.92 17.84 12.49-259.48 0-237.24 0-21-5.64-52.87-4.24-67.16-20.92 21 5.64 47-28.36 67.16-20.92-17.84-12.49-48.78-22.16-55.95-42.62 17.83 12.49 54.12-10.68 70.26 3.32-12.49-17.84-38.6-37.42-38-59.2 12.49 17.84 54.52 8.29 64.89 27.15-5.64-21-22.61-48.16-15.47-68.62 5.64 21 48 26.26 51.69 47.7 1.9-21.69-5.76-53.08 8.94-69.77-1.9 21.7 36.34 41.07 32.26 62.51 9.2-19.74 14.29-51.62 32.26-62.51-9.2 19.74 20.82 50.92 8.94 69.77 15.39-15.39 30.25-43.92 51.69-47.7-15.4 15.4 1 54.62-15.47 68.62 19.74-9.2 43-30.93 64.89-27.15-19.73 9.21-17.78 51.75-38 59.2 21.7-1.9 50.84-14.21 70.26-3.32-21.69 1.9-33.71 42.62-55.95 42.62Z"
        style={{
          fill: "#fcd116",
          stroke: "#bd9c08",
          strokeWidth: "1.5px",
        }}
      />
      <path
        id="Kiribati_svg__path4182"
        d="m539.49 360-75.86-31 81.89-3.19-60.68-55.08 78.05 25-38.18-72.51 64.78 50.19-11.07-81.2 43.71 69.32 17.37-80.09 17.36 80.09 43.71-69.32-11.07 81.19 64.78-50.19-38.18 72.51 78-25-60.63 55.08 81.89 3.2-75.86 31 75.86 31H463.63Z"
        style={{
          fill: "#fcd116",
          stroke: "#bd9c08",
          strokeWidth: "1.5px",
          strokeLinejoin: "round",
        }}
      />
      <path
        id="Kiribati_svg__path1076"
        d="M738.83 360a99.33 99.33 0 1 1-99.33-99.33A99.33 99.33 0 0 1 738.83 360Z"
        style={{
          fill: "#fcd116",
          stroke: "#bd9c08",
          strokeLinecap: "round",
          strokeWidth: "1.8px",
        }}
      />
      <path
        id="Kiribati_svg__rect4894"
        d="M0 396.03h1280v324.23H0z"
        style={{
          fill: "#003f87",
        }}
      />
      <path
        id="Kiribati_svg__path6138"
        d="M0 617.32c42.67 0 85.33 30.88 128 30.88s85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88v-51.46c-42.67 0-85.33 30.88-128 30.88s-85.33-30.88-128-30.88-85.33 30.88-128 30.88-85.33-30.88-128-30.88-85.33 30.88-128 30.88-85.33-30.88-128-30.88-85.33 30.88-128 30.88-85.33-30.88-128-30.88-85.33 30.88-128 30.88-85.33-30.88-128-30.88Z"
        className="Kiribati_svg__cls-6"
      />
      <path
        id="Kiribati_svg__path18554"
        d="m672.16 119.5 53.27.35c.34-2.25-4.57-6.73-8.76-7.39-13.48-2.12-23.59 2.25-40 2.42Z"
        className="Kiribati_svg__cls-7"
      />
      <path
        id="Kiribati_svg__path18555"
        d="M566.08 107c12.51 15.8 36.76 26.78 59.93 26.93 31.22.19 65.84-25.08 107.72-32.81L597 98.27c-17.69-12.12-31.11-10.58-40.26-3.07l-26.11.8c-3.7 2 3.06 10.43 8.53 10.74Z"
        className="Kiribati_svg__cls-7"
      />
      <path
        id="Kiribati_svg__path18556"
        d="M627.88 97.9c-5-22.86-23.32-32.71-45.37-35.63-25.48-3.38-84.12-6.84-123.06-18 16.85 16.58 52.14 24.85 76.65 28.59 26 4 51.24 9.74 71.27 24.86Z"
        className="Kiribati_svg__cls-7"
      />
      <path
        id="Kiribati_svg__path18557"
        d="M611.32 113.16c8.15 10.46 20.79 9.86 34.38.74s28.3-34.7 35.36-35.12c49.7-2.88 109.82-23.86 138.49-40.06-36.35 5.15-72.39 11-109.06 11.13-62.56.25-70 28.74-95.46 48.72"
        style={{
          fill: "#fcd116",
          stroke: "#bd9c08",
          strokeLinecap: "round",
          strokeWidth: "1.2px",
        }}
      />
      <path
        id="Kiribati_svg__path18558"
        d="M621 100.87c13.33-8.5 24.17-19.58 36.65-28.84 20.68-15.33 44.56-15.77 70.19-16.09 22.72-.28 62.55-9.77 84.16-14.84"
        className="Kiribati_svg__cls-9"
      />
      <path
        id="Kiribati_svg__path18559"
        d="M607.16 96.86c-3.94-20.44-23.07-26.57-47.24-30.24-26.29-4-53.11-6.22-79.34-13.47-6.51-1.8-12.57-5-18.85-7.46"
        style={{
          stroke: "#bd9c08",
          strokeWidth: "1.2px",
          fill: "none",
        }}
      />
      <path
        id="Kiribati_svg__path18560"
        d="M615.54 127c24.67 1.81 43-5.61 64.06-16.11"
        className="Kiribati_svg__cls-9"
      />
      <path
        id="Kiribati_svg__path18561"
        d="M530.54 100.22h31.13"
        className="Kiribati_svg__cls-9"
      />
      <path
        id="Kiribati_svg__path18562"
        d="M575.54 96.87a3.89 3.89 0 1 1-3.89-3.9 3.88 3.88 0 0 1 3.89 3.9"
        style={{
          fill: "#bd9c08",
        }}
      />
      <g id="Kiribati_svg__use2426">
        <path
          id="Kiribati_svg__path6138-2"
          d="M0 411.46c42.67 0 85.33 30.88 128 30.88s85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88V360c-42.67 0-85.33 30.88-128 30.88S1066.67 360 1024 360s-85.33 30.88-128 30.88S810.67 360 768 360s-85.33 30.88-128 30.88S554.67 360 512 360s-85.33 30.88-128 30.88S298.67 360 256 360s-85.33 30.88-128 30.88S42.67 360 0 360Z"
          className="Kiribati_svg__cls-6"
          data-name="path6138"
        />
      </g>
      <g id="Kiribati_svg__use2428">
        <path
          id="Kiribati_svg__path6138-3"
          d="M0 514.39c42.67 0 85.33 30.88 128 30.88s85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88 85.33 30.88 128 30.88 85.33-30.88 128-30.88v-51.46c-42.67 0-85.33 30.88-128 30.88s-85.33-30.88-128-30.88-85.33 30.88-128 30.88-85.33-30.88-128-30.88-85.33 30.88-128 30.88-85.33-30.88-128-30.88-85.33 30.88-128 30.88-85.33-30.88-128-30.88-85.33 30.88-128 30.88-85.33-30.88-128-30.88Z"
          className="Kiribati_svg__cls-6"
          data-name="path6138"
        />
      </g>
    </g>
  </svg>
);
export default SvgKiribati;
