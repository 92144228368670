import * as React from "react";
import type { SVGProps } from "react";

const SvgGuadeloupe = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 800" {...props}>
    {/* Background with stripes */}
    <rect width="1200" height="800" fill="#0057a0" />
    <rect width="1200" height="200" fill="#fff" />
    <rect width="1200" height="400" fill="#0033a0" />
    <rect width="1200" height="600" fill="#fff" />
    
    {/* Cross in the top left corner */}
    <rect x="0" y="0" width="200" height="200" fill="#ffcc00" />
    <rect x="0" y="0" width="200" height="50" fill="#ffcc00" />
    <rect x="0" y="0" width="50" height="200" fill="#ffcc00" />
    
    {/* Fleur-de-lis symbols */}
    <path
      d="M50 50l40 40l-10 20l10 20l-40 -40l-40 40l10 -20l-10 -20z"
      fill="#ffcc00"
      transform="translate(100, 100)"
    />
    <path
      d="M50 50l40 40l-10 20l10 20l-40 -40l-40 40l10 -20l-10 -20z"
      fill="#ffcc00"
      transform="translate(200, 100)"
    />
    <path
      d="M50 50l40 40l-10 20l10 20l-40 -40l-40 40l10 -20l-10 -20z"
      fill="#ffcc00"
      transform="translate(100, 200)"
    />
    <path
      d="M50 50l40 40l-10 20l10 20l-40 -40l-40 40l10 -20l-10 -20z"
      fill="#ffcc00"
      transform="translate(200, 200)"
    />
  </svg>
);

export default SvgGuadeloupe;