import * as React from "react";
import type { SVGProps } from "react";
const SvgAzerbaijan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Azerbaijan_svg__cls-2{fill:#de1d37}.Azerbaijan_svg__cls-4{fill:#fff}"
        }
      </style>
    </defs>
    <g id="Azerbaijan_svg__Azerbaijan">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#0097c2",
        }}
      />
      <path d="M0 240h1280v240H0z" className="Azerbaijan_svg__cls-2" />
      <path
        d="M0 480h1280v240H0z"
        style={{
          fill: "#00ad65",
        }}
      />
      <circle
        cx={625.92}
        cy={359.19}
        r={102.19}
        className="Azerbaijan_svg__cls-4"
      />
      <circle
        cx={647.49}
        cy={359.19}
        r={85.16}
        className="Azerbaijan_svg__cls-2"
        transform="rotate(-85.05 647.484 359.195)"
      />
      <path
        d="m779.63 399.3-29.29-13.9L739.5 416l-10.88-30.54-29.27 13.94 13.9-29.29-30.56-10.84 30.54-10.89-13.94-29.27L728.58 333l10.84-30.55L750.31 333l29.27-14-13.9 29.29 30.55 10.84L765.69 370Z"
        className="Azerbaijan_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgAzerbaijan;
