import * as React from "react";
import type { SVGProps } from "react";
const SvgIreland = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.3h426.67v720.6H0z"
      style={{
        fill: "#009949",
      }}
    />
    <path
      d="M426.67-.3h426.67v720.6H426.67z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M853.33-.3H1280v720.6H853.33z"
      style={{
        fill: "#f37920",
      }}
    />
  </svg>
);
export default SvgIreland;
