import * as React from "react";
import type { SVGProps } from "react";
const SvgLesoto = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <clipPath id="Lesoto_svg__clip-path">
        <path
          d="M0-820.49h1280V-99.5H0z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <style>{".Lesoto_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Lesoto_svg__Lesoto">
      <path d="M0 .33h1280v719.33H0z" className="Lesoto_svg__cls-2" />
      <path
        d="M0 503.87h1280v215.8H0z"
        style={{
          fill: "#009543",
        }}
      />
      <path
        d="M0 .33h1280v215.8H0z"
        style={{
          fill: "#00209f",
        }}
      />
      <path
        id="Lesoto_svg__path1873"
        d="M639.43 229.49c-4.1.08-8.1 4.63-8.1 4.63l.36 48.51-15.32 16.05h12.4l-.1 27.82-73.36 98.77-10.84-3.75-19 40.43s46.93 29.35 114.96 28.55c74.68-.89 115.25-29.91 115.25-29.91l-19.58-39.9-9.59 4.17-74.29-98.26-.11-28.6h12.4l-16.88-15.9.1-48.34s-4.2-4.36-8.3-4.27Z"
        style={{
          stroke: "#000",
          strokeWidth: ".88px",
        }}
      />
      <path
        id="Lesoto_svg__path2760"
        d="M665 345.64h-50.71s-18.41-38.79-15.43-65.91c3-27.61 18.82-40.67 39.8-40.9 24.81-.28 37.81 12.15 41.73 39.8C684.29 306 665 345.64 665 345.64Z"
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: "4.4px",
        }}
      />
      <path
        id="Lesoto_svg__path3649"
        d="M550.77 438.09c-.83 1.11-6.91 13.27-6.91 13.27l10.51-2.21Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3651"
        d="m556.85 453.85-11.05 3.59 13.26 5.25Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3653"
        d="m561.83 438.37 5.53 16.31 13.54-3.87-3.59-7.74Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3655"
        d="m570.4 460.48 1.93 6.63 18 4.43-7.19-15.2Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3657"
        d="m590.3 446.66 6.35 15.2 13.54-5.25-4.42-6.61Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3659"
        d="m599.14 467.39 2.21 5.8 21 3-8.85-13.81Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3661"
        d="m619.31 451.08 7.74 13.55 19.63-7.19-2.21-5.25Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3663"
        d="m630.65 469.6 4.14 7.19 24.32-.83-9.11-13.54Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3665"
        d="M656.35 451.64 663 463l15.48-7.46-4.15-6.08Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3667"
        d="m681.78 460.2-15.48 7.74 4.14 6.64 20.73-3.32Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3669"
        d="m686.75 448.32 8 9.95 13-10.23-4.43-4.69Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3671"
        d="M710.52 453.57 698.08 463l3.32 5.8 17.13-4.42Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3673"
        d="m727.65 434 3 4.42-8.56 12.71-8.85-11.33Z"
        className="Lesoto_svg__cls-2"
      />
      <path
        id="Lesoto_svg__path3675"
        d="m733.46 445 5.25 10.51-10.5 3.59-.83-5Z"
        className="Lesoto_svg__cls-2"
      />
    </g>
    <path
      id="Lesoto_svg__Namibia"
      d="M67.03 11.45-67.03-210.47l1280-720.98 134.06 221.92z"
      className="Lesoto_svg__cls-2"
      style={{
        clipPath: "url(#Lesoto_svg__clip-path)",
      }}
    />
  </svg>
);
export default SvgLesoto;
