import * as React from "react";
import type { SVGProps } from "react";

const SvgGibraltar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 800" {...props}>
    {/* Background */}
    <rect width="1200" height="800" fill="#fff" />
    
    {/* Red Stripe */}
    <rect width="1200" height="200" y="600" fill="#d52b1e" />
    
    {/* White Stripe */}
    <rect width="1200" height="600" y="0" fill="#fff" />
    
    {/* Castle */}
    <rect x="350" y="400" width="500" height="200" fill="#d52b1e" />
    <rect x="375" y="450" width="450" height="150" fill="#fff" />
    <rect x="375" y="450" width="80" height="150" fill="#d52b1e" />
    <rect x="455" y="450" width="80" height="150" fill="#d52b1e" />
    <rect x="535" y="450" width="80" height="150" fill="#d52b1e" />
    <rect x="615" y="450" width="80" height="150" fill="#d52b1e" />
    <rect x="695" y="450" width="80" height="150" fill="#d52b1e" />
    <rect x="775" y="450" width="80" height="150" fill="#d52b1e" />
    <rect x="855" y="450" width="80" height="150" fill="#d52b1e" />
    <rect x="935" y="450" width="80" height="150" fill="#d52b1e" />
    
    {/* Key */}
    <rect x="580" y="620" width="80" height="80" fill="#fff" />
    <path d="M600,680 l-10,-10 l20,0 z" fill="#d52b1e" />
  </svg>
);

export default SvgGibraltar;