import * as React from "react";
import type { SVGProps } from "react";
const SvgGreece = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Greece_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Greece_svg__Greece">
      <path
        d="M0-.3h1280v720.6H0z"
        style={{
          fill: "#175fac",
        }}
      />
      <path d="M160.12-.19h81v401.98h-81z" className="Greece_svg__cls-2" />
      <path
        d="M0 160.3h403.1v79.5H0zM403.1 77.8H1280v82.5H403.1zM403.1 239.8H1280v82.5H403.1zM0 399.79h1280v82.5H0zM0 560.79h1280v82.5H0z"
        className="Greece_svg__cls-2"
      />
    </g>
  </svg>
);
export default SvgGreece;
