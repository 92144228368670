import * as React from "react";
import type { SVGProps } from "react";
const SvgMonaco = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M.32 0h1279.36v360H.32z"
      style={{
        fill: "#cd2028",
      }}
    />
    <path
      d="M.32 360h1279.36v360H.32z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgMonaco;
