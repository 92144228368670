import * as React from "react";
import type { SVGProps } from "react";
const SvgIraq = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Iraq_svg__cls-3{fill:#007a3d}"}</style>
    </defs>
    <g id="Iraq_svg__Iraq">
      <path
        id="Iraq_svg__rect3"
        d="M0-.33h1280v240.22H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        id="Iraq_svg__rect5"
        d="M0 239.89h1280v240.22H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path id="Iraq_svg__rect7" d="M0 480.11h1280v240.22H0z" />
      <g id="Iraq_svg__g9">
        <g id="Iraq_svg__g4314">
          <g id="Iraq_svg__g4316">
            <path
              id="Iraq_svg__path4318"
              d="m428.18 449.24-6.18-6.82 6.27-6.75 6.27-6.76 6.21 6.82 6.21 6.82-6.27 6.75-6.27 6.76-6.2-6.83Zm-42.65-3.44c-2.23-.88-5.86-3.81-8.23-6.63-4.48-5.33-5.5-7.82-2.49-6.07 4.48 2.61 13.37 4.31 16.53 3.17 11.67-4.24 14.32-21.18 5-31.92-1.82-2.11-3.32-4.56-3.32-5.45s1.94-2.54 4.32-3.68c3.32-1.6 5-3.35 7.08-7.6s3-5.25 4.08-4.31c1.48 1.33 6.49 14.32 6.49 16.83 0 1.33 1.81 1.62 10 1.62h10l-1.56-4.07a52 52 0 0 0-3.14-6.7c-1.41-2.34-1.41-2.75-.06-3.8.83-.65 3.59-2.76 6.15-4.71s4.93-4.38 5.29-5.42c.88-2.59 6.41-10.05 7.44-10.05.45 0 .83 7.82.83 17.37v17.38h124.78V352l-3.47-2.34-3.47-2.33-3.52 2.91-3.51 2.92v35.57h-52.42c-28.83 0-52.41-.2-52.41-.43 0-.38 26.08-38.69 29.27-43 .66-.89 2.3-3.33 3.64-5.42a44 44 0 0 1 3-4.35 43 43 0 0 0 3-4.34c1.33-2.09 2.88-4.37 3.44-5.07s10-14.47 21-30.61 20.3-29.36 20.7-29.4 1 1.28 1.27 2.92c1.65 9.09 2.91 14.18 4.22 17 1.67 3.57.69 4.91-3.72 5.06-2.09.07-6.9 6.49-29.45 39.32C497.5 352 485.22 370 485 370.49s13.18.86 29.7.86h30v-11.67c0-6.42.34-12.18.75-12.8 1.88-2.88 21.74-18 23.12-17.57.86.25 6.17 4.26 11.8 8.92l10.24 8.48v72.42H417.28l-1.74 7c-2.09 8.39-5.51 14.44-10.1 17.86-4.36 3.19-14.18 4.09-19.93 1.8Zm216.14-83.06c0-39.42.33-56.64 1.09-57.15.59-.4 3.17 1.66 5.73 4.58s7.13 7.61 10.16 10.42c4.76 4.44 5.26 5.26 3.79 6.24-4.92 3.24-4.79 2.22-4.79 39.69v35.21h9.56c8.48 0 10-.32 14-2.82 4.87-3.11 5.19-2.7 2.78 3.5a47.8 47.8 0 0 0-2.25 10.18l-.6 6-19.72.29-19.72.3v-56.44Z"
              className="Iraq_svg__cls-3"
            />
            <path
              id="Iraq_svg__path4320"
              d="M704.91 404.5c.52-15.88 2-21.26 7.39-26.38 4.12-3.93 10-5.8 16.84-5.32l5.11.36.31-6.49c.39-8.23-.3-11.17-2.81-12-3-1-2.41-4.45 1.25-6.94a80 80 0 0 0 9.74-9.35c3.57-3.93 6.84-7.15 7.27-7.15s.78 15.7.78 34.88V401h14v-35.94c0-31.17-.21-36.12-1.58-37.6a5.23 5.23 0 0 0-2.5-1.72c-2.25 0-.66-4.36 2.32-6.39 1.8-1.21 6.16-5.4 9.7-9.3s6.81-6.84 7.27-6.53.82 22.35.82 49V401h14v-99.2l-2.5-2.36c-1.38-1.3-2.51-3-2.51-3.69s3.12-4.05 6.93-7.4 7.9-7.57 9.09-9.39c5.11-7.81 5-9 5 68.09v71.39H704.45l.64-13.91Zm29.82-10.64v-7.08h-3.93c-5.27 0-10.09 5-10.09 10.44V401h14v-7.08ZM822 361.8c0-39.57.33-56.86 1.09-57.37.59-.4 3.19 1.67 5.76 4.6s7.15 7.64 10.15 10.46c4.79 4.46 5.29 5.28 3.81 6.26C837.86 329 838 328 838 365.6V401h9.79c8.88 0 10.14-.27 13.61-2.83 2.1-1.55 4.06-2.55 4.36-2.22s-.24 2.7-1.2 5.27a51.6 51.6 0 0 0-2.38 10.67l-.63 6-19.79.29-19.79.3V361.8Zm-78.9-59.31a14.1 14.1 0 0 1-4.51-2.77c-1.09-1.25-.55-1.37 3.41-.76 7.12 1.11 11.49-.2 15.46-4.66l3.5-3.91 4.85 1.88c2.67 1 5.46 1.61 6.21 1.3 1.92-.8 5.41-6.82 5.06-8.73-.42-2.34 1.55-2 2.77.49 2.39 4.86.39 11-4.18 12.9-2.24.92-4.4.95-8 .1-4.46-1-5.19-.92-7.14 1.06-4.36 4.46-11 5.65-17.41 3.11Zm12.14-18c-2.41-1.79-7.51-11-7.7-13.89-.17-2.5.24-3.27 1.88-3.53 2.86-.44 4.19 1.63 6.39 10 1.81 6.87 1.62 9-.56 7.4Z"
              className="Iraq_svg__cls-3"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgIraq;
