import * as React from "react";
import type { SVGProps } from "react";
const SvgGabon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.38h1280v720.75H0z"
      style={{
        fill: "#3a75c4",
      }}
    />
    <path
      d="M0-.38h1280v480.5H0z"
      style={{
        fill: "#fcd116",
      }}
    />
    <path
      d="M0-.38h1280v240.25H0z"
      style={{
        fill: "#009e60",
      }}
    />
  </svg>
);
export default SvgGabon;
