import * as React from "react";
import type { SVGProps } from "react";
const SvgSeychelles = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0Z"
      style={{
        fill: "#d62828",
      }}
    />
    <path
      d="M0 0h853.33L0 720Z"
      style={{
        fill: "#fcd856",
      }}
    />
    <path
      d="M1280 240v480H0Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0 0h426.67L0 720Z"
      style={{
        fill: "#003f87",
      }}
    />
    <path
      d="M0 720h1280V480Z"
      style={{
        fill: "#007a3d",
      }}
    />
  </svg>
);
export default SvgSeychelles;
