import * as React from "react";
import type { SVGProps } from "react";
const SvgColombia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v360H0z"
      style={{
        fill: "#fcd116",
      }}
    />
    <path
      d="M0 360h1280v180H0z"
      style={{
        fill: "#003893",
      }}
    />
    <path
      d="M0 540h1280v180H0z"
      style={{
        fill: "#ce1126",
      }}
    />
  </svg>
);
export default SvgColombia;
