import * as React from "react";
import type { SVGProps } from "react";
const SvgUkraine = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#245eab",
      }}
    />
    <path
      d="M0 360h1280v360H0z"
      style={{
        fill: "#fdd400",
      }}
    />
  </svg>
);
export default SvgUkraine;
