import * as React from "react";
import type { SVGProps } from "react";
const SvgTurkmenistan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Turkmenistan_svg__cls-1{fill:#28ae66}.Turkmenistan_svg__cls-2{fill:#ca3745}.Turkmenistan_svg__cls-3{fill:#fff}.Turkmenistan_svg__cls-4{fill:#383739}.Turkmenistan_svg__cls-5{fill:#faae29}"
        }
      </style>
    </defs>
    <g id="Turkmenistan_svg__Turkmenistan">
      <path
        id="Turkmenistan_svg__rect12"
        d="M0 0h1280v720H0z"
        className="Turkmenistan_svg__cls-1"
      />
      <path
        id="Turkmenistan_svg__rect14"
        d="M129.6 0H324v720H129.6z"
        className="Turkmenistan_svg__cls-2"
      />
      <g id="Turkmenistan_svg__\u0437\u0432\u0435\u0437\u0434\u044B">
        <path
          id="Turkmenistan_svg___155960960"
          d="M536.19 79.85a89.59 89.59 0 1 1-93.36 150.57 89.59 89.59 0 0 0 93.36-150.57"
          className="Turkmenistan_svg__cls-3"
          data-name={155960960}
        />
        <path
          id="Turkmenistan_svg___155960888"
          d="m467.16 87.69-10.15-7.37 3.88-11.93-10.15 7.37-10.15-7.37 3.87 11.93-10.15 7.37h12.55l3.88 11.93 3.88-11.93z"
          className="Turkmenistan_svg__cls-3"
          data-name={155960888}
        />
        <path
          id="Turkmenistan_svg___155960816"
          d="m467.16 133.77-10.15-7.37 3.88-11.93-10.15 7.37-10.15-7.37 3.87 11.93-10.15 7.37h12.55l3.88 11.93 3.88-11.93z"
          className="Turkmenistan_svg__cls-3"
          data-name={155960816}
        />
        <path
          id="Turkmenistan_svg___155960744"
          d="m467.16 179.85-10.15-7.37 3.88-11.94-10.15 7.38-10.15-7.38 3.87 11.94-10.15 7.37h12.55l3.88 11.93 3.88-11.93z"
          className="Turkmenistan_svg__cls-3"
          data-name={155960744}
        />
        <path
          id="Turkmenistan_svg___155960672"
          d="m421.64 156.24-10.15-7.38 3.88-11.93-10.16 7.38-10.15-7.38 3.88 11.93-10.15 7.38h12.55l3.87 11.93 3.88-11.93z"
          className="Turkmenistan_svg__cls-3"
          data-name={155960672}
        />
        <path
          id="Turkmenistan_svg___155960600"
          d="m479.84 111.31 10.15-7.38L486.11 92l10.15 7.37L506.41 92l-3.87 11.93 10.15 7.38h-12.55l-3.88 11.93-3.87-11.93z"
          className="Turkmenistan_svg__cls-3"
          data-name={155960600}
        />
      </g>
      <g id="Turkmenistan_svg___155914472" data-name={155914472}>
        <path
          id="Turkmenistan_svg___155960456"
          d="m202.78 118.01-22.38-12.59h-11.76l-14.72-8.86V84.41l-15.58-9.55V51.89l15.58-9.55V30.19l14.72-8.86h11.76l22.38-12.59h48.04l22.37 12.59h11.76l14.73 8.86v12.15l15.57 9.55v22.97l-15.57 9.55v12.15l-14.73 8.86h-11.76l-22.37 12.59z"
          className="Turkmenistan_svg__cls-4"
          data-name={155960456}
        />
        <path
          id="Turkmenistan_svg___155960384"
          d="m250.68 117.5 22.38-12.59h11.75l14.35-8.64V84.12l15.58-9.55V52.18l-15.58-9.55V30.48l-14.35-8.64h-11.75L250.68 9.25h-47.77l-22.38 12.59h-11.75l-14.35 8.64v12.15l-15.57 9.55v22.39l15.57 9.55v12.15l14.35 8.64h11.75l22.38 12.59z"
          className="Turkmenistan_svg__cls-1"
          data-name={155960384}
        />
        <path
          id="Turkmenistan_svg___155960312"
          d="m204.2 112.84-22.38-12.59h-11.67l-10.93-6.57V81.55L143.64 72V54.75l15.58-9.55V33.07l10.93-6.58h11.67l22.38-12.58h45.2l22.37 12.58h11.68l10.92 6.58V45.2l15.58 9.55V72l-15.58 9.55v12.13l-10.92 6.57h-11.68l-22.37 12.59z"
          className="Turkmenistan_svg__cls-4"
          data-name={155960312}
        />
        <path
          id="Turkmenistan_svg___155960240"
          d="m283.31 99.74 10.55-6.35V81.27l15.58-9.56v-8.08h-82.39v48.7h22.21l22.38-12.59z"
          className="Turkmenistan_svg__cls-5"
          data-name={155960240}
        />
        <path
          id="Turkmenistan_svg___155960168"
          d="M159.73 33.36v12.12l-15.57 9.55v8.09h82.38v-48.7h-22.21l-22.38 12.59h-11.66z"
          className="Turkmenistan_svg__cls-5"
          data-name={155960168}
        />
        <path
          id="Turkmenistan_svg___155960096"
          d="m159.73 93.39 10.56 6.35h11.66l22.38 12.59h22.21v-48.7h-82.38v8.08l15.57 9.56z"
          className="Turkmenistan_svg__cls-3"
          data-name={155960096}
        />
        <path
          id="Turkmenistan_svg___155960024"
          d="M293.86 45.48V33.36l-10.55-6.35h-11.67l-22.38-12.59h-22.21v48.7h82.39v-8.09z"
          className="Turkmenistan_svg__cls-3"
          data-name={155960024}
        />
        <path
          id="Turkmenistan_svg___155959952"
          d="m264.14 45.69-2.97-3.13 9.02-8.51v-5.77l5.26 3.26 3.84-3.58 3.8 2.35-3.84 3.58 4.93 3.05h-10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155959952}
        />
        <path
          id="Turkmenistan_svg___155959880"
          d="m279.35 28.61-3.44 3.21 2.88 1.79 3.45-3.22Zm-8.65.59v5.07l-8.8 8.31 2.26 2.42 9-8.54h9.16z"
          className="Turkmenistan_svg__cls-2"
          data-name={155959880}
        />
        <path
          id="Turkmenistan_svg___155959808"
          d="m245.99 44.12-2.97-3.13 9.02-8.51v-5.77l5.26 3.26 3.84-3.58 3.8 2.34-3.84 3.59 4.93 3.05h-10.77z"
          className="Turkmenistan_svg__cls-4"
          data-name={155959808}
        />
        <path
          id="Turkmenistan_svg___155959736"
          d="m261.2 27-3.44 3.21 2.88 1.79 3.45-3.22Zm-8.64.59v5.11l-8.81 8.3 2.25 2.4 9.05-8.54h9.16l-11.66-7.23Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155959736}
        />
        <path
          id="Turkmenistan_svg___155959664"
          d="m265.47 55.96-2.97-3.14 9.02-8.5v-5.78l5.26 3.26 3.84-3.57 3.8 2.34-3.84 3.58 4.93 3.06h-10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155959664}
        />
        <path
          id="Turkmenistan_svg___155959592"
          d="m280.68 38.87-3.44 3.21 2.88 1.79 3.45-3.21zm-8.64.6v5.07l-8.81 8.3 2.26 2.39 9.05-8.53h9.16Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155959592}
        />
        <path
          id="Turkmenistan_svg___155959520"
          d="m264.14 81.06-2.97 3.13 9.02 8.51v5.77l5.26-3.26 3.84 3.57 3.8-2.34-3.84-3.58 4.93-3.05h-10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155959520}
        />
        <path
          id="Turkmenistan_svg___155959448"
          d="m279.35 98.14-3.44-3.21 2.88-1.79 3.45 3.22Zm-8.65-.59v-5.07l-8.8-8.31 2.26-2.39 9 8.54h9.16z"
          className="Turkmenistan_svg__cls-2"
          data-name={155959448}
        />
        <path
          id="Turkmenistan_svg___155959376"
          d="m245.99 82.63-2.97 3.13 9.02 8.51v5.77l5.26-3.26 3.84 3.58 3.8-2.35-3.84-3.58 4.93-3.05h-10.77z"
          className="Turkmenistan_svg__cls-4"
          data-name={155959376}
        />
        <path
          id="Turkmenistan_svg___155959304"
          d="m261.2 99.71-3.44-3.21 2.88-1.79 3.45 3.22Zm-8.64-.59v-5.07l-8.81-8.31 2.25-2.39 9.05 8.54h9.16l-11.66 7.23Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155959304}
        />
        <path
          id="Turkmenistan_svg___155959232"
          d="m265.47 70.79-2.97 3.13 9.02 8.51v5.77l5.26-3.26 3.84 3.58 3.8-2.35-3.84-3.58 4.93-3.05h-10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155959232}
        />
        <path
          id="Turkmenistan_svg___155959160"
          d="m280.68 87.88-3.44-3.22 2.88-1.79 3.45 3.22zm-8.64-.6v-5.07l-8.81-8.31 2.26-2.38 9.05 8.53h9.16Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155959160}
        />
        <path
          id="Turkmenistan_svg___155959088"
          d="m189.45 81.06 2.97 3.13-9.02 8.51v5.77l-5.26-3.26-3.84 3.57-3.8-2.34 3.84-3.58-4.93-3.05h10.77z"
          className="Turkmenistan_svg__cls-4"
          data-name={155959088}
        />
        <path
          id="Turkmenistan_svg___155917160"
          d="m174.24 98.14 3.45-3.21-2.89-1.79-3.45 3.22Zm8.65-.59v-5.07l8.8-8.31-2.26-2.39-9.05 8.54h-9.16Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155917160}
        />
        <path
          id="Turkmenistan_svg___155917088"
          d="m207.6 82.63 2.97 3.13-9.02 8.51v5.77l-5.26-3.26-3.84 3.58-3.8-2.35 3.85-3.58-4.94-3.05h10.77z"
          className="Turkmenistan_svg__cls-4"
          data-name={155917088}
        />
        <path
          id="Turkmenistan_svg___155917016"
          d="m192.39 99.71 3.45-3.21-2.84-1.79-3.45 3.22 2.89 1.78Zm8.65-.59v-5.07l8.8-8.31-2.26-2.39-9.05 8.54h-9.16Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155917016}
        />
        <path
          id="Turkmenistan_svg___155916944"
          d="m188.12 70.79 2.97 3.13-9.02 8.51v5.77l-5.26-3.26-3.84 3.58-3.8-2.35 3.84-3.58-4.93-3.05h10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155916944}
        />
        <path
          id="Turkmenistan_svg___155916872"
          d="m172.91 87.88 3.44-3.22-2.88-1.79-3.47 3.22Zm8.65-.6v-5.07l8.8-8.31-2.26-2.38-9 8.53h-9.16Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155916872}
        />
        <path
          id="Turkmenistan_svg___155916800"
          d="m189.45 45.69 2.97-3.13-9.02-8.51v-5.77l-5.26 3.26-3.84-3.58-3.8 2.35 3.84 3.58-4.93 3.05h10.77z"
          className="Turkmenistan_svg__cls-4"
          data-name={155916800}
        />
        <path
          id="Turkmenistan_svg___155916728"
          d="m174.24 28.61 3.45 3.21-2.89 1.79-3.45-3.22Zm8.65.59v5.07l8.8 8.31-2.26 2.42-9.05-8.54h-9.16Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155916728}
        />
        <path
          id="Turkmenistan_svg___155916656"
          d="m207.6 44.12 2.97-3.13-9.02-8.51v-5.77l-5.26 3.26-3.84-3.58-3.8 2.34 3.85 3.59-4.94 3.05h10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155916656}
        />
        <path
          id="Turkmenistan_svg___155916584"
          d="m192.39 27 3.45 3.21L193 32l-3.45-3.22Zm8.65.59v5.11l8.8 8.31-2.26 2.39-9.05-8.54h-9.16L201 27.63Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155916584}
        />
        <path
          id="Turkmenistan_svg___155916512"
          d="m188.12 55.96 2.97-3.14-9.02-8.5v-5.78l-5.26 3.26-3.84-3.57-3.8 2.34 3.84 3.58-4.93 3.06h10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155916512}
        />
        <path
          id="Turkmenistan_svg___155916440"
          d="m172.91 38.87 3.44 3.21-2.88 1.79-3.47-3.21Zm8.64.6v5.07l8.81 8.3-2.26 2.39-9-8.53h-9.16Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155916440}
        />
        <path
          id="Turkmenistan_svg___155916368"
          d="m257.11 26.76 1.71-4.16-7.32-3.8h-9.91l-5.08-4.89h-19.43L212 18.8h-9.9l-7.32 3.8 1.7 4.16 6.53-3.42h2.86l10.01 11.57 10.92-6.65 10.91 6.65 10.01-11.57h2.86z"
          className="Turkmenistan_svg__cls-4"
          data-name={155916368}
        />
        <path
          id="Turkmenistan_svg___155916296"
          d="m257.11 99.98 1.71 4.16-7.32 3.8h-9.91l-5.08 4.9h-19.43l-5.08-4.9h-9.9l-7.32-3.8 1.7-4.16 6.53 3.43h2.86l10.01-11.57 10.92 6.64 10.91-6.64 10.01 11.57h2.86z"
          className="Turkmenistan_svg__cls-4"
          data-name={155916296}
        />
        <path
          id="Turkmenistan_svg___155916224"
          d="M174.98 54.43h-5.02l-1.12-1.34v-3.57l2.82-1.87-4.63-1.73-.11.08-5.98 4.15v1.11l-9.57 12.11 9.57 12.11v1.12l6.09 4.22 4.63-1.73-2.82-1.87v-3.56l1.12-1.34h5.02z"
          className="Turkmenistan_svg__cls-4"
          data-name={155916224}
        />
        <path
          id="Turkmenistan_svg___155916152"
          d="M161.46 76.33v-1.02l-9.44-11.94 9.44-11.93v-1.02l5.65-3.92 3.43 1.28-2.22 1.47v4.02l1.4 1.67h4.75v16.87h-4.75l-1.4 1.67v4.02l2.22 1.47-3.43 1.28z"
          className="Turkmenistan_svg__cls-2"
          data-name={155916152}
        />
        <path
          id="Turkmenistan_svg___155916080"
          d="M278.61 54.43h5.02l1.12-1.34v-3.57l-2.82-1.87 4.63-1.73.11.08 5.98 4.15v1.11l9.57 12.11-9.57 12.11v1.12l-6.09 4.22-4.63-1.73 2.82-1.87v-3.56l-1.12-1.34h-5.02z"
          className="Turkmenistan_svg__cls-4"
          data-name={155916080}
        />
        <path
          id="Turkmenistan_svg___155916008"
          d="M292.13 76.33v-1.02l9.44-11.94-9.44-11.93v-1.02l-5.65-3.92-3.43 1.28 2.22 1.47v4.02l-1.4 1.67h-4.74v16.87h4.74l1.4 1.67v4.02l-2.22 1.47 3.43 1.28z"
          className="Turkmenistan_svg__cls-2"
          data-name={155916008}
        />
        <path
          id="Turkmenistan_svg___155915936"
          d="M226.54 14.42v13.4L216 34.25l-9.87-11.43h-3.23l-6.14 3.23-1.31-3.21 6.79-3.52h10l5.08-4.9Zm.51 1.46v11.94l10.56 6.43 9.88-11.43h3.22l6.14 3.23 1.31-3.21-6.79-3.52h-10l-5.08-4.9h-9.25v1.46Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155915936}
        />
        <path
          id="Turkmenistan_svg___155915864"
          d="M226.54 112.33v-13.4L216 92.5l-9.87 11.43h-3.23l-6.14-3.23-1.31 3.2 6.79 3.53h10l5.08 4.9Zm.51-1.46V98.93l10.56-6.43 9.88 11.43h3.22l6.14-3.23 1.31 3.2-6.79 3.53h-10l-5.08 4.9h-9.25v-1.46Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155915864}
        />
        <path
          id="Turkmenistan_svg___155915792"
          d="M178.54 65.7h-9.12l-4 4.05 2.71-.02-4.69 3.81-2.6-2.26V70.2l-5.6-6.83 5.6-6.82v-1.08l2.6-2.26 4.69 3.81-2.71-.02 4 4.05h9.12l7.18-5.61v-14.8h17.95l20.85-12.8v-3.35l-3.19-2.13h-1.01l-3.47 2.33-.54 1.73-2.72-3.71 5.28-4.43h.85l5.95-4.37h2.25l5.96 4.37h.84l5.28 4.43-2.72 3.71-.54-1.73-3.47-2.33h-1.01l-3.19 2.13v3.35l20.85 12.8h17.95v14.8l7.18 5.61h9.12l4-4.05-2.71.02 4.69-3.81 2.6 2.26v1.08l5.6 6.82-5.6 6.83v1.08l-2.6 2.26-4.69-3.81 2.71.02-4-4.05h-9.12l-7.18 5.61v14.8h-17.95L229.07 98.9v3.36l3.19 2.12h1.01l3.47-2.32.54-1.73 2.72 3.7-5.28 4.44h-.84l-5.96 4.37h-2.25l-5.95-4.37h-.85l-5.28-4.44 2.72-3.7.54 1.73 3.47 2.32h1.01l3.19-2.12V98.9l-20.85-12.79h-17.95v-14.8z"
          className="Turkmenistan_svg__cls-4"
          data-name={155915792}
        />
        <path
          id="Turkmenistan_svg___155915720"
          d="m227.05 104.78 2.29 1.75-2.29 1.74ZM219.8 108h.09l6 4.37h.7v-3.41l-3.13-2.39 3.13-2.39v-6.45L204.23 84h-16.49V70.29l-8.51-6.66h-12.85l-3.22 3.65-3.23-3.65h-3.81l5.23 6.37v1l2.07 1.8 3.33-2.59h-1.95v-.65l4.35-4.44h9.57l7.52 5.88v14.6h17.58l21.21 13v3.91l-3.54 2.37h-1.33l-3.75-2.51-.29-.94-1.83 2.5 4.77 4Zm-56.64-47.76 2.54 2.88h-5.08Zm63.38-41.76-2.28 1.74 2.28 1.78Zm61.35 45.15 2.54 2.87 2.57-2.87Zm-54.18-44.84-6-4.37h-.7v3.41l3.13 2.39-3.13 2.39V29l22.35 13.7h16.49v13.76l8.51 6.66h12.84l3.23-3.65 3.23 3.65h3.81l-5.23-6.38v-1l-2.07-1.8-3.33 2.59h2v.64l-4.35 4.44h-9.57l-7.51-5.87V41.15h-17.64l-21.21-13v-3.94l3.55-2.36h1.32l3.75 2.51.29.93 1.83-2.49-4.77-4Z"
          className="Turkmenistan_svg__cls-5"
          data-name={155915720}
        />
        <path
          id="Turkmenistan_svg___155915648"
          d="m226.54 108.27-2.28-1.74 2.28-1.75ZM165.7 63.63l-2.54 2.87-2.54-2.87ZM233.79 108h-.08l-6 4.37h-.7v-3.41l3.13-2.39-3.13-2.39v-6.45L249.36 84h16.49V70.29l8.51-6.66h12.85l3.22 3.65 3.23-3.65h3.81L292.24 70v1l-2.07 1.8-3.33-2.59h2v-.65l-4.35-4.44h-9.57L267.41 71v14.6h-17.64l-21.21 13v3.91l3.55 2.37h1.32l3.75-2.51.29-.94 1.83 2.5-4.77 4Zm56.64-47.72-2.54 2.88H293Zm-70.54-41.49 6-4.37h.7v3.41l-3.13 2.39 3.13 2.39V29l-22.36 13.7h-16.49v13.76l-8.51 6.66h-12.85l-3.22-3.65-3.23 3.65h-3.81l5.23-6.38v-1l2.07-1.8 3.33 2.59h-1.95v.64l4.35 4.44h9.57l7.52-5.87V41.15h17.58l21.21-13v-3.94l-3.54-2.36h-1.33l-3.75 2.51-.29.93-1.83-2.49 4.77-4Zm7.16 3.21 2.29-1.74-2.29-1.74Z"
          className="Turkmenistan_svg__cls-3"
          data-name={155915648}
        />
        <path
          id="Turkmenistan_svg___155915576"
          d="m226.54 29.62-22.16 13.6h-16.12v13.49l-8.2 6.41h46.48z"
          className="Turkmenistan_svg__cls-1"
          data-name={155915576}
        />
        <path
          id="Turkmenistan_svg___155915504"
          d="m227.05 97.13 22.17-13.6h16.12V70.04l8.19-6.41h-46.48z"
          className="Turkmenistan_svg__cls-1"
          data-name={155915504}
        />
        <path
          id="Turkmenistan_svg___155915432"
          d="m273.53 63.12-8.19-6.41V43.22h-16.12l-22.17-13.6v33.5z"
          className="Turkmenistan_svg__cls-2"
          data-name={155915432}
        />
        <path
          id="Turkmenistan_svg___155915360"
          d="m180.06 63.63 8.2 6.41v13.49h16.12l22.16 13.6v-33.5z"
          className="Turkmenistan_svg__cls-2"
          data-name={155915360}
        />
        <path
          id="Turkmenistan_svg___155915288"
          d="m214.25 61.03 2.97-3.13-9.01-8.51v-5.77l-5.27 3.26-3.83-3.58-3.8 2.35 3.84 3.58-4.93 3.05h10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155915288}
        />
        <path
          id="Turkmenistan_svg___155915216"
          d="m199 43.94 3.45 3.22L199.6 49l-3.45-3.22zm8.65.6v5.07l8.81 8.31-2.27 2.39-9-8.54H196l11.67-7.23Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155915216}
        />
        <path
          id="Turkmenistan_svg___155915144"
          d="m239.34 65.72-2.97 3.13 9.02 8.51v5.77l5.26-3.26 3.83 3.57 3.8-2.34-3.84-3.58 4.93-3.05h-10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155915144}
        />
        <path
          id="Turkmenistan_svg___155915072"
          d="m254.55 82.8-3.45-3.21 2.9-1.79 3.44 3.2Zm-8.65-.59v-5.07l-8.8-8.31 2.26-2.39 9 8.54h9.16z"
          className="Turkmenistan_svg__cls-2"
          data-name={155915072}
        />
        <path
          id="Turkmenistan_svg___155915000"
          d="m214.25 65.72 2.97 3.13-9.02 8.51v5.77l-5.26-3.26-3.83 3.57-3.8-2.34 3.84-3.58-4.93-3.05h10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155915000}
        />
        <path
          id="Turkmenistan_svg___155914928"
          d="m199 82.8 3.45-3.21-2.85-1.79-3.45 3.2Zm8.65-.59v-5.07l8.8-8.31-2.26-2.39-9 8.54H196Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155914928}
        />
        <path
          id="Turkmenistan_svg___155914856"
          d="m239.34 61.03-2.97-3.13 9.02-8.51v-5.77l5.26 3.26 3.83-3.58 3.8 2.35-3.84 3.58 4.93 3.05h-10.76z"
          className="Turkmenistan_svg__cls-4"
          data-name={155914856}
        />
        <path
          id="Turkmenistan_svg___155914784"
          d="m254.55 43.94-3.45 3.22L254 49l3.45-3.22Zm-8.65.6v5.07l-8.8 8.31 2.26 2.39 9-8.54h9.16z"
          className="Turkmenistan_svg__cls-1"
          data-name={155914784}
        />
        <path
          id="Turkmenistan_svg___155914712"
          d="M250.63 63.38 226.8 79.26l-23.83-15.88 23.83-15.89z"
          className="Turkmenistan_svg__cls-4"
          data-name={155914712}
        />
        <path
          id="Turkmenistan_svg___155914640"
          d="m226.54 48.28-22.26 14.84h22.26Zm.51 14.84h22.27l-22.27-14.84Zm-22.77.51 22.26 14.84V63.63Zm22.77 14.84 22.27-14.84h-22.27Z"
          className="Turkmenistan_svg__cls-3"
          data-name={155914640}
        />
        <path
          id="Turkmenistan_svg___155914568"
          d="M233.17 53.55v4.36h8.25v10.93h-8.25v4.36h-12.75v-4.36h-8.24V57.91h8.24v-4.36z"
          data-name={155914568}
          style={{
            fill: "#383739",
            stroke: "#000",
            strokeWidth: ".1px",
          }}
        />
        <path
          id="Turkmenistan_svg___155914496"
          d="M220.94 54.06v4.37h-8.25v4.69h13.85v-9.06Zm6.11 9.06h13.85v-4.69h-8.24v-4.37h-5.61zm-14.36.51v4.69h8.25v4.37h5.6v-9.06Zm14.36 9.06h5.61v-4.37h8.24v-4.69h-13.85z"
          className="Turkmenistan_svg__cls-2"
          data-name={155914496}
        />
      </g>
      <g id="Turkmenistan_svg___155856272" data-name={155856272}>
        <path
          id="Turkmenistan_svg___155914400"
          d="M205.33 244.33v7h42.94v-7h19.18v-6.99h19.19v-7h19.18v-63.22h-19.18v-6.99h-19.19v-7h-19.18v-7h-42.94v7h-19.19v7h-19.18v6.99h-19.19v63.22h19.19v7h19.18v6.99z"
          className="Turkmenistan_svg__cls-4"
          data-name={155914400}
        />
        <path
          id="Turkmenistan_svg___155914328"
          d="M247.75 243.82v6.99h-41.91v-6.99h-19.18v-7h-19.19v-6.99h-19.19v-62.19h19.19v-7h19.19v-7h19.18v-6.99h41.91v6.99h19.19v7h19.18v7h19.19v62.19h-19.19v6.99h-19.18v7z"
          className="Turkmenistan_svg__cls-2"
          data-name={155914328}
        />
        <path
          id="Turkmenistan_svg___155914256"
          d="M210.92 238.74v6.99h31.75v-6.99h19.19v-7h19.18v-6.99h19.19v-52.03h-19.19v-7h-19.18v-7h-19.19v-6.99h-31.75v6.99h-19.18v7h-19.19v7h-19.19v52.03h19.19v6.99h19.19v7z"
          className="Turkmenistan_svg__cls-4"
          data-name={155914256}
        />
        <path
          id="Turkmenistan_svg___155914184"
          d="M242.16 238.22v7h-30.72v-7h-19.19v-6.99h-19.19v-7h-19.18v-51h19.18v-7h19.19v-6.99h19.19v-7h30.72v7h19.18v6.99h19.19v7h19.19v51h-19.19v7h-19.19v6.99z"
          className="Turkmenistan_svg__cls-2"
          data-name={155914184}
        />
        <path
          id="Turkmenistan_svg___155914112"
          d="m206.47 164.42-.01-5.04-4.87 4.36-4.87-4.36-.01 5.04-4.97-3.76v7.21l-4.76 3.03-.01-4.52-4.57 4.1-4.56-4.1-.02 4.52-5.27-3.35v6.68l-4.26 3.09-.01-4.1-5.06 3.67-5.07-3.67-.01 4.1-4.78-3.46v20.26h-5.59v9.22h5.59v20.26l4.78-3.46.01 4.1 5.07-3.67 5.06 3.67.01-4.1 4.26 3.09v6.68l5.27-3.35.02 4.52 4.56-4.1 4.57 4.1.01-4.52 4.76 3.03v7.21l4.97-3.76.01 5.04 4.87-4.35 4.87 4.35.01-5.04 4.45 3.37v7.69l6.66-6.07v7.14l9.22-5.92 9.21 5.92v-7.14l6.66 6.07v-7.69l4.46-3.37.01 5.04 4.87-4.35 4.87 4.35.01-5.04 4.97 3.76v-7.21l4.76-3.03.01 4.52 4.56-4.1 4.57 4.1.01-4.52 5.27 3.35v-6.68l4.27-3.09.01 4.1 5.06-3.67 5.06 3.67.01-4.1 4.78 3.46v-20.26h5.59v-9.22h-5.59v-20.26l-4.78 3.46-.01-4.1-5.06 3.67-5.06-3.67-.01 4.1-4.27-3.09v-6.68l-5.27 3.35-.01-4.52-4.57 4.1-4.56-4.1-.01 4.52-4.76-3.03v-7.21l-4.97 3.76-.01-5.04-4.87 4.36-4.87-4.36-.01 5.04-4.46-3.37v-7.69l-6.66 6.07v-7.14l-9.21 5.93-9.22-5.93v7.14l-6.66-6.07v7.69z"
          className="Turkmenistan_svg__cls-4"
          data-name={155914112}
        />
        <path
          id="Turkmenistan_svg___155914040"
          d="M148.28 202.83v-8.2h5.6v-19.76l4.77 3.45.01-4.1 4.56 3.3.15-.11 4.4-3.19.01 4.1 4.77-3.45v47.72l-4.77-3.45-.01 4.1-4.55-3.3-4.56 3.3-.01-4.1-4.77 3.45v-19.76z"
          className="Turkmenistan_svg__cls-1"
          data-name={155914040}
        />
        <path
          id="Turkmenistan_svg___155913968"
          d="m178.34 225.63-5.28 3.35v-60.5l5.28 3.35.01-4.3 4.05 3.64 4.05-3.64.02 4.3 5.27-3.35v60.5l-5.27-3.35-.02 4.3-4.05-3.64-4.05 3.64z"
          className="Turkmenistan_svg__cls-5"
          data-name={155913968}
        />
        <path
          id="Turkmenistan_svg___155913896"
          d="m197.22 232.01-4.97 3.76v-74.08l4.97 3.76.01-4.92 4.36 3.89 4.35-3.89.02 4.92 4.96-3.76v74.08l-4.96-3.76-.02 4.93-4.35-3.9-4.36 3.9z"
          className="Turkmenistan_svg__cls-1"
          data-name={155913896}
        />
        <path
          id="Turkmenistan_svg___155913824"
          d="m226.8 158.83-8.7-5.59v7.35l-6.66-6.07v88.42l6.66-6.07v7.36l8.7-5.6 8.7 5.6v-7.36l6.66 6.07v-88.42l-6.66 6.07v-7.35z"
          className="Turkmenistan_svg__cls-5"
          data-name={155913824}
        />
        <path
          id="Turkmenistan_svg___155913752"
          d="M305.31 202.83v-8.2h-5.59v-19.76l-4.78 3.45-.01-4.1-4.55 3.3-.15-.11-4.4-3.19-.01 4.1-4.78-3.45v47.72l4.78-3.45.01 4.1 4.55-3.3 4.55 3.3.01-4.1 4.78 3.45v-19.76z"
          className="Turkmenistan_svg__cls-1"
          data-name={155913752}
        />
        <path
          id="Turkmenistan_svg___155913680"
          d="m275.26 225.63 5.27 3.35v-60.5l-5.27 3.35-.01-4.3-4.06 3.64-4.05-3.64-.01 4.3-5.27-3.35v60.5l5.27-3.35.01 4.3 4.05-3.64 4.06 3.64z"
          className="Turkmenistan_svg__cls-5"
          data-name={155913680}
        />
        <path
          id="Turkmenistan_svg___155913608"
          d="m256.38 232.01 4.97 3.76v-74.08l-4.97 3.76-.01-4.92-4.36 3.89-4.36-3.89-.01 4.92-4.97-3.76v74.08l4.97-3.76.01 4.93 4.36-3.9 4.36 3.9z"
          className="Turkmenistan_svg__cls-1"
          data-name={155913608}
        />
        <path
          id="Turkmenistan_svg___155913536"
          d="m232.63 172.2-2.06 1.77v-5.21l4.66-3.44-8.43-6.21-8.44 6.21 4.67 3.44v5.21l-2.07-1.77v-2.87l-4.93 4.06 5.29 4.88-2.07 2.86h15.09l-2.06-2.86 5.29-4.88-4.94-4.06z"
          className="Turkmenistan_svg__cls-4"
          data-name={155913536}
        />
        <path
          id="Turkmenistan_svg___155913464"
          d="M220.45 170.42v2.02l3.09 2.65v-6.59l-4.31-3.18 7.57-5.57 7.56 5.57-4.31 3.18v6.59l3.1-2.65v-2.02l3.64 2.99-5.2 4.79 1.74 2.41h-13.07l1.74-2.41-5.19-4.79z"
          className="Turkmenistan_svg__cls-2"
          data-name={155913464}
        />
        <path
          id="Turkmenistan_svg___155913392"
          d="m226.8 168.16 3.85-2.84-3.85-2.84-.16.11-3.7 2.73z"
          className="Turkmenistan_svg__cls-4"
          data-name={155913392}
        />
        <path
          id="Turkmenistan_svg___155913320"
          d="m226.8 163.12 3 2.2-3 2.2c-.26-.18-2.54-1.87-3-2.2Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155913320}
        />
        <path
          id="Turkmenistan_svg___155913248"
          d="M260.82 205.05h25.82v-3.37h8.44v-5.9h-8.44v-3.37h-25.82l-17.97-11.8h-32.11l-17.97 11.8h-25.81v3.37h-8.45v5.9h8.45v3.37h25.81l17.97 11.8h32.11z"
          className="Turkmenistan_svg__cls-4"
          data-name={155913248}
        />
        <path
          id="Turkmenistan_svg___155913176"
          d="M167.47 204.53v-3.36h-8.44v-4.88h8.44v-3.36h25.45l17.97-11.8h31.81l17.97 11.8h25.45v3.36h8.45v4.88h-8.45v3.36h-25.45l-17.97 11.81h-31.81l-17.97-11.81z"
          className="Turkmenistan_svg__cls-2"
          data-name={155913176}
        />
        <path
          id="Turkmenistan_svg___155882848"
          d="M277.13 202.17v-6.88h-18.4v-1.64l-16.42-10.77h-31.03l-16.41 10.77v1.64h-18.4v6.88h18.4v1.64l16.41 10.77h31.03l16.42-10.77v-1.64z"
          className="Turkmenistan_svg__cls-4"
          data-name={155882848}
        />
        <path
          id="Turkmenistan_svg___155882776"
          d="M176.98 195.8h18.4v-1.87l16.05-10.54h30.73l16.05 10.54v1.87h18.4v5.86h-18.4v1.87l-16.05 10.54h-30.73l-16.05-10.54v-1.87h-18.4z"
          className="Turkmenistan_svg__cls-2"
          data-name={155882776}
        />
        <path
          id="Turkmenistan_svg___155882704"
          d="m229.64 185.06-2.84 2.14-2.8-2.14h-5.31l5.5 4.13-10 7.55-5.31-4 1.63-1.22 1.92 1.43 2.66-2-4.58-3.43-6.95 5.21 8 6-8 6 6.95 5.22 4.49-3.45-2.66-2-1.92 1.43-1.63-1.22 5.31-4 10 7.54-5.5 4.13h5.4l2.85-2.14 2.84 2.14H235l-5.5-4.13 10-7.54 5.32 4-1.64 1.22-1.91-1.43-2.66 2 4.57 3.44 6.95-5.22-8-6 8-6-6.95-5.21-4.57 3.49 2.66 2 1.91-1.43 1.64 1.22-5.32 4-10-7.55 5.5-4.13Zm-2.84 6.13 10 7.54-10 7.54-10.05-7.54Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155882704}
        />
        <path
          id="Turkmenistan_svg___155882632"
          d="m233.42 185.58-4.82 3.61 10.9 8.19 6.17-4.64-2.49-1.87-1.91 1.44-1.81-1.31 3.72-2.79 6.09 4.57-8 6 8 6-6.09 4.57-3.72-2.79 1.81-1.35 1.91 1.44 2.49-1.87-6.17-4.64-10.9 8.19 4.82 3.61h-3.61l-3-2.26-3 2.26h-3.6l4.82-3.61-10.91-8.19-6.17 4.64 2.49 1.87 1.92-1.44 1.8 1.35-3.72 2.79-6.09-4.57 8-6-8-6 6.09-4.57 3.72 2.79-1.8 1.35-1.92-1.44-2.49 1.87 6.17 4.64 10.88-8.23-4.82-3.61h3.6l3 2.26 3-2.26Zm-6.78 5.08-10.74 8.07 10.9 8.18 10.9-8.18-10.9-8.18z"
          className="Turkmenistan_svg__cls-5"
          data-name={155882632}
        />
        <path
          id="Turkmenistan_svg___155882560"
          d="m198.2 198.73 4.67 3.1 4.67-3.1-4.67-3.1z"
          className="Turkmenistan_svg__cls-4"
          data-name={155882560}
        />
        <path
          id="Turkmenistan_svg___155882488"
          d="m202.87 196.25 3.74 2.48-3.74 2.49-3.74-2.49z"
          className="Turkmenistan_svg__cls-1"
          data-name={155882488}
        />
        <path
          id="Turkmenistan_svg___155882416"
          d="m222.13 198.73 4.67 3.1 4.67-3.1-4.67-3.1z"
          className="Turkmenistan_svg__cls-4"
          data-name={155882416}
        />
        <path
          id="Turkmenistan_svg___155882344"
          d="m226.8 196.25 3.74 2.48-3.74 2.49-3.74-2.49z"
          className="Turkmenistan_svg__cls-1"
          data-name={155882344}
        />
        <path
          id="Turkmenistan_svg___155882272"
          d="m255.4 198.73-4.67 3.1-4.68-3.1 4.68-3.1z"
          className="Turkmenistan_svg__cls-4"
          data-name={155882272}
        />
        <path
          id="Turkmenistan_svg___155882200"
          d="m250.73 196.25-3.75 2.48 3.75 2.49 3.74-2.49z"
          className="Turkmenistan_svg__cls-1"
          data-name={155882200}
        />
        <path
          id="Turkmenistan_svg___155882128"
          d="M161.15 190a2.86 2.86 0 0 0 4.13 0c1.83-1.84 1.89-5.16 1.87-7.55l-2.49 2.63c-.26-.46-1.45-2.5-1.45-2.5l-1.44 2.5-2.49-2.63c-.02 2.43.04 5.75 1.87 7.55"
          className="Turkmenistan_svg__cls-4"
          data-name={155882128}
        />
        <path
          id="Turkmenistan_svg___155882056"
          d="m163.21 183.65 1.35 2.32 2.05-2.16c-.07 2.7-.66 4.83-1.69 5.87a2.36 2.36 0 0 1-3.41 0c-1-1-1.62-3.17-1.69-5.87l2.05 2.16Z"
          className="Turkmenistan_svg__cls-3"
          data-name={155882056}
        />
        <path
          id="Turkmenistan_svg___155881984"
          d="m186.36 177.15-3.96-3.4-3.96 3.4 2.72 2.33v5.42l-1.54-1.54v-3.05l-3.52 3.26 6.3 6.31 6.3-6.31-3.52-3.26v3.05l-1.54 1.54v-5.42z"
          className="Turkmenistan_svg__cls-4"
          data-name={155881984}
        />
        <path
          id="Turkmenistan_svg___155881912"
          d="m181.67 179.25-2.44-2.1 3.17-2.72 3.17 2.72-2.44 2.1v6.89l2.56-2.56v-2.09l2.27 2.1-5.56 5.56-5.56-5.56 2.27-2.1v2.09l2.56 2.56z"
          className="Turkmenistan_svg__cls-2"
          data-name={155881912}
        />
        <path
          id="Turkmenistan_svg___155881840"
          d="m205.55 171.08-3.96-3.4-3.96 3.4 2.71 2.33v5.41l-1.53-1.53v-3.05l-3.53 3.26 6.31 6.3 6.3-6.3-3.53-3.26v3.05l-1.53 1.53v-5.41z"
          className="Turkmenistan_svg__cls-4"
          data-name={155881840}
        />
        <path
          id="Turkmenistan_svg___155881768"
          d="m200.86 173.18-2.45-2.1 3.18-2.73 3.17 2.73-2.45 2.1v6.89l2.57-2.57v-2.09l2.27 2.11-5.56 5.56-5.56-5.56 2.26-2.11v2.09l2.57 2.57z"
          className="Turkmenistan_svg__cls-3"
          data-name={155881768}
        />
        <path
          id="Turkmenistan_svg___155881696"
          d="m186.36 220.31-3.96 3.4-3.96-3.4 2.72-2.33v-5.42l-1.54 1.54v3.05l-3.52-3.26 6.3-6.3 6.3 6.3-3.52 3.26v-3.05l-1.54-1.54v5.42z"
          className="Turkmenistan_svg__cls-4"
          data-name={155881696}
        />
        <path
          id="Turkmenistan_svg___155881624"
          d="m181.67 218.21-2.44 2.1 3.17 2.72 3.17-2.72-2.44-2.1v-6.89l2.56 2.56v2.1l2.27-2.11-5.56-5.56-5.56 5.56 2.27 2.11v-2.1l2.56-2.56z"
          className="Turkmenistan_svg__cls-2"
          data-name={155881624}
        />
        <path
          id="Turkmenistan_svg___155881552"
          d="m267.24 177.15 3.95-3.4 3.96 3.4-2.71 2.33v5.42l1.53-1.54v-3.05l3.53 3.26-6.31 6.31-6.3-6.31 3.53-3.26v3.05l1.53 1.54v-5.42z"
          className="Turkmenistan_svg__cls-4"
          data-name={155881552}
        />
        <path
          id="Turkmenistan_svg___155881480"
          d="m271.92 179.25 2.45-2.1-3.18-2.72-3.17 2.72 2.45 2.1v6.89l-2.57-2.56v-2.09l-2.27 2.1 5.56 5.56 5.56-5.56-2.26-2.1v2.09l-2.57 2.56z"
          className="Turkmenistan_svg__cls-2"
          data-name={155881480}
        />
        <path
          id="Turkmenistan_svg___155881408"
          d="m267.24 220.31 3.95 3.4 3.96-3.4-2.71-2.33v-5.42l1.53 1.54v3.05l3.53-3.26-6.31-6.3-6.3 6.3 3.53 3.26v-3.05l1.53-1.54v5.42z"
          className="Turkmenistan_svg__cls-4"
          data-name={155881408}
        />
        <path
          id="Turkmenistan_svg___155881336"
          d="m271.92 218.21 2.45 2.1-3.18 2.72-3.17-2.72 2.45-2.1v-6.89l-2.57 2.56v2.1l-2.27-2.11 5.56-5.56 5.56 5.56-2.26 2.11v-2.1l-2.57-2.56z"
          className="Turkmenistan_svg__cls-2"
          data-name={155881336}
        />
        <path
          id="Turkmenistan_svg___155881264"
          d="m205.55 226.38-3.96 3.4-3.96-3.4 2.71-2.33v-5.41l-1.53 1.53v3.06l-3.53-3.27 6.31-6.3 6.3 6.3-3.53 3.27v-3.06l-1.53-1.53v5.41z"
          className="Turkmenistan_svg__cls-4"
          data-name={155881264}
        />
        <path
          id="Turkmenistan_svg___155881192"
          d="m200.86 224.29-2.45 2.09 3.18 2.73 3.17-2.73-2.45-2.09v-6.89l2.57 2.56v2.09l2.27-2.11-5.56-5.56-5.56 5.56 2.26 2.11v-2.09l2.57-2.56z"
          className="Turkmenistan_svg__cls-3"
          data-name={155881192}
        />
        <path
          id="Turkmenistan_svg___155881120"
          d="m248.05 171.08 3.96-3.4 3.96 3.4-2.72 2.33v5.41l1.53-1.53v-3.05l3.53 3.26-6.3 6.3-6.3-6.3 3.52-3.26v3.05l1.54 1.53v-5.41z"
          className="Turkmenistan_svg__cls-4"
          data-name={155881120}
        />
        <path
          id="Turkmenistan_svg___155881048"
          d="m252.74 173.18 2.44-2.1-3.17-2.73-3.17 2.73 2.44 2.1v6.89l-2.56-2.57v-2.09l-2.27 2.11 5.56 5.56 5.56-5.56-2.27-2.11v2.09l-2.56 2.57z"
          className="Turkmenistan_svg__cls-3"
          data-name={155881048}
        />
        <path
          id="Turkmenistan_svg___155880976"
          d="m248.05 226.38 3.96 3.4 3.96-3.4-2.72-2.33v-5.41l1.53 1.53v3.06l3.53-3.27-6.3-6.3-6.3 6.3 3.52 3.27v-3.06l1.54-1.53v5.41z"
          className="Turkmenistan_svg__cls-4"
          data-name={155880976}
        />
        <path
          id="Turkmenistan_svg___155880904"
          d="m252.74 224.29 2.44 2.09-3.17 2.73-3.17-2.73 2.44-2.09v-6.89l-2.56 2.56v2.09l-2.27-2.11 5.56-5.56 5.56 5.56-2.27 2.11v-2.09l-2.56-2.56z"
          className="Turkmenistan_svg__cls-3"
          data-name={155880904}
        />
        <path
          id="Turkmenistan_svg___155856944"
          d="M161.15 207.42a2.86 2.86 0 0 1 4.13 0c1.83 1.84 1.89 5.16 1.87 7.55l-2.49-2.63c-.26.46-1.45 2.5-1.45 2.5l-1.44-2.5-2.49 2.66c-.02-2.42.04-5.74 1.87-7.58"
          className="Turkmenistan_svg__cls-4"
          data-name={155856944}
        />
        <path
          id="Turkmenistan_svg___155856872"
          d="m163.21 213.81 1.35-2.32 2.05 2.16c-.07-2.7-.66-4.83-1.69-5.87a2.36 2.36 0 0 0-3.41 0c-1 1-1.62 3.17-1.69 5.87l2.05-2.16Z"
          className="Turkmenistan_svg__cls-3"
          data-name={155856872}
        />
        <path
          id="Turkmenistan_svg___155856800"
          d="M292.45 190a2.87 2.87 0 0 1-4.14 0c-1.82-1.84-1.88-5.16-1.87-7.55l2.49 2.63 1.45-2.5 1.45 2.5 2.49-2.63c.01 2.43-.05 5.75-1.87 7.55"
          className="Turkmenistan_svg__cls-4"
          data-name={155856800}
        />
        <path
          id="Turkmenistan_svg___155856728"
          d="M290.38 183.65 289 186l-2-2.16c.07 2.7.67 4.83 1.7 5.87a2.34 2.34 0 0 0 3.4 0c1-1 1.63-3.17 1.7-5.87l-2 2.16-1.35-2.32Z"
          className="Turkmenistan_svg__cls-3"
          data-name={155856728}
        />
        <path
          id="Turkmenistan_svg___155856656"
          d="M292.45 207.42a2.87 2.87 0 0 0-4.14 0c-1.82 1.84-1.88 5.16-1.87 7.55l2.49-2.63 1.45 2.5 1.45-2.5 2.49 2.63c.01-2.39-.05-5.71-1.87-7.55"
          className="Turkmenistan_svg__cls-4"
          data-name={155856656}
        />
        <path
          id="Turkmenistan_svg___155856584"
          d="m290.38 213.81-1.38-2.32s-1.45 1.54-2 2.16c.07-2.7.67-4.83 1.7-5.87a2.34 2.34 0 0 1 3.4 0c1 1 1.63 3.17 1.7 5.87l-2-2.16-1.35 2.32Z"
          className="Turkmenistan_svg__cls-3"
          data-name={155856584}
        />
        <path
          id="Turkmenistan_svg___155856512"
          d="m232.63 225.26-2.06-1.77v5.21l4.66 3.44-8.43 6.21-8.44-6.21 4.67-3.44v-5.21l-2.07 1.77v2.87l-4.93-4.06 5.29-4.88-2.07-2.85h15.09l-2.06 2.85 5.29 4.88-4.94 4.06z"
          className="Turkmenistan_svg__cls-4"
          data-name={155856512}
        />
        <path
          id="Turkmenistan_svg___155856440"
          d="M220.45 227.04v-2.02l3.09-2.65v6.59l-4.31 3.18 7.57 5.58 7.56-5.58-4.31-3.18v-6.59l3.1 2.65v2.02l3.64-2.99-5.2-4.79 1.74-2.41h-13.07l1.74 2.41-5.19 4.79z"
          className="Turkmenistan_svg__cls-2"
          data-name={155856440}
        />
        <path
          id="Turkmenistan_svg___155856368"
          d="m226.8 229.3 3.85 2.84-3.85 2.84-.16-.11-3.7-2.73z"
          className="Turkmenistan_svg__cls-4"
          data-name={155856368}
        />
        <path
          id="Turkmenistan_svg___155856296"
          d="m226.8 234.34 3-2.2-3-2.2c-.26.18-2.54 1.87-3 2.2Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155856296}
        />
      </g>
      <g id="Turkmenistan_svg___155766872" data-name={155766872}>
        <path
          id="Turkmenistan_svg___155856200"
          d="M161.35 330.4v-4.86l-9.22 5.85-3.7-2.85-3.81.44-1.84 2.59 3.29 2.52-3.29 2.51 1.84 2.59 3.81.45 3.7-2.86 9.22 5.86v-4.87l-5.8-3.68z"
          className="Turkmenistan_svg__cls-4"
          data-name={155856200}
        />
        <path
          id="Turkmenistan_svg___155856128"
          d="m152.1 332.02 8.74-5.54v3.64l-6.25 3.97 6.25 3.97v3.64l-8.74-5.55-3.82 2.95-3.38-.39-1.41-2 3.43-2.62-3.43-2.62 1.41-2 3.38-.4z"
          className="Turkmenistan_svg__cls-1"
          data-name={155856128}
        />
        <path
          id="Turkmenistan_svg___155856056"
          d="M161.35 313.51v-4.87l-9.22 5.86-3.7-2.86-3.81.45-1.84 2.59 3.29 2.51-3.29 2.52 1.84 2.59 3.81.44 3.7-2.85 9.22 5.85v-4.86l-5.8-3.69z"
          className="Turkmenistan_svg__cls-4"
          data-name={155856056}
        />
        <path
          id="Turkmenistan_svg___155855984"
          d="m152.1 315.13 8.74-5.55v3.65l-6.25 3.96 6.25 3.97v3.65l-8.74-5.55-3.82 2.95-3.38-.39-1.41-2.01 3.43-2.62-3.43-2.62 1.41-2 3.38-.39z"
          className="Turkmenistan_svg__cls-1"
          data-name={155855984}
        />
        <path
          id="Turkmenistan_svg___155855912"
          d="M161.35 354.67v4.86l-9.22-5.86-3.7 2.86-3.81-.44-1.84-2.59 3.29-2.52-3.29-2.51 1.84-2.59 3.81-.45 3.7 2.86 9.22-5.86v4.87l-5.8 3.68z"
          className="Turkmenistan_svg__cls-4"
          data-name={155855912}
        />
        <path
          id="Turkmenistan_svg___155855840"
          d="m152.1 353.05 8.74 5.54v-3.64l-6.25-3.97 6.25-3.97v-3.64l-8.74 5.55-3.82-2.95-3.38.39-1.41 2 3.43 2.62-3.43 2.62 1.41 2 3.38.4z"
          className="Turkmenistan_svg__cls-1"
          data-name={155855840}
        />
        <path
          id="Turkmenistan_svg___155855768"
          d="M292.25 330.4v-4.86l9.21 5.85 3.7-2.85 3.82.44 1.83 2.59-3.28 2.52 3.28 2.51-1.83 2.59-3.82.45-3.7-2.86-9.21 5.86v-4.87l5.8-3.68z"
          className="Turkmenistan_svg__cls-4"
          data-name={155855768}
        />
        <path
          id="Turkmenistan_svg___155855696"
          d="m301.49 332.02-8.73-5.54v3.64l6.25 3.97-6.25 3.97v3.64l8.73-5.55 3.82 2.95 3.38-.39 1.42-2-3.43-2.62 3.43-2.62-1.42-2-3.38-.4z"
          className="Turkmenistan_svg__cls-1"
          data-name={155855696}
        />
        <path
          id="Turkmenistan_svg___155855624"
          d="M292.25 313.51v-4.87l9.21 5.86 3.7-2.86 3.82.45 1.83 2.59-3.28 2.51 3.28 2.52-1.83 2.59-3.82.44-3.7-2.85-9.21 5.85v-4.86l5.8-3.69z"
          className="Turkmenistan_svg__cls-4"
          data-name={155855624}
        />
        <path
          id="Turkmenistan_svg___155855552"
          d="m301.49 315.13-8.73-5.55v3.65l6.25 3.96-6.25 3.97v3.65l8.73-5.55 3.82 2.95 3.38-.39 1.42-2.01-3.43-2.62 3.43-2.62-1.42-2-3.38-.39z"
          className="Turkmenistan_svg__cls-1"
          data-name={155855552}
        />
        <path
          id="Turkmenistan_svg___155855480"
          d="M292.25 354.67v4.86l9.21-5.86 3.7 2.86 3.82-.44 1.83-2.59-3.28-2.52 3.28-2.51-1.83-2.59-3.82-.45-3.7 2.86-9.21-5.86v4.87l5.8 3.68z"
          className="Turkmenistan_svg__cls-4"
          data-name={155855480}
        />
        <path
          id="Turkmenistan_svg___155855408"
          d="m301.49 353.05-8.73 5.54v-3.64l6.25-3.97-6.25-3.97v-3.64l8.73 5.55 3.82-2.95 3.38.39 1.42 2-3.43 2.62 3.43 2.62-1.42 2-3.38.4z"
          className="Turkmenistan_svg__cls-1"
          data-name={155855408}
        />
        <path
          id="Turkmenistan_svg___155855336"
          d="M272.07 282.88v7h5.11V293h5v-5.66h-4.48v-4.47Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155855336}
        />
        <path
          id="Turkmenistan_svg___155855264"
          d="M277.21 283.39v4.48h4.48v4.63h-4v-3.11h-5.11v-6Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155855264}
        />
        <path
          id="Turkmenistan_svg___155855192"
          d="M287.14 294v7h5.11v3.11h5v-5.65h-4.48V294Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155855192}
        />
        <path
          id="Turkmenistan_svg___155855120"
          d="M292.28 294.47V299h4.48v4.63h-4v-3.11h-5.11v-6Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155855120}
        />
        <path
          id="Turkmenistan_svg___155855048"
          d="M181.52 282.88v7h-5.11V293h-5v-5.66h4.48v-4.47Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155855048}
        />
        <path
          id="Turkmenistan_svg___155854976"
          d="M176.38 283.39v4.48h-4.48v4.63h4v-3.11h5.1v-6Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155854976}
        />
        <path
          id="Turkmenistan_svg___155786952"
          d="M272.07 385.3v-7h5.11v-3.1h5v5.65h-4.48v4.48Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155786952}
        />
        <path
          id="Turkmenistan_svg___155786880"
          d="M277.21 384.78v-4.47h4.48v-4.63h-4v3.11h-5.11v6Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155786880}
        />
        <path
          id="Turkmenistan_svg___155786808"
          d="M181.52 385.3v-7h-5.11v-3.1h-5v5.65h4.48v4.48Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155786808}
        />
        <path
          id="Turkmenistan_svg___155786736"
          d="M176.38 384.78v-4.47h-4.48v-4.63h4v3.11h5.1v6Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155786736}
        />
        <path
          id="Turkmenistan_svg___155786664"
          d="M287.14 374.22v-7h5.11v-3.1h5v5.65h-4.48v4.48Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155786664}
        />
        <path
          id="Turkmenistan_svg___155786592"
          d="M292.28 373.7v-4.47h4.48v-4.63h-4v3.1h-5.11v6Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155786592}
        />
        <path
          id="Turkmenistan_svg___155786520"
          d="M166.46 294v7h-5.11v3.11h-5v-5.65h4.48V294Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155786520}
        />
        <path
          id="Turkmenistan_svg___155786448"
          d="M161.32 294.47V299h-4.48v4.63h4v-3.11h5.11v-6Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155786448}
        />
        <path
          id="Turkmenistan_svg___155786376"
          d="M166.46 374.22v-7h-5.11v-3.1h-5v5.65h4.48v4.48Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155786376}
        />
        <path
          id="Turkmenistan_svg___155786304"
          d="M161.32 373.7v-4.47h-4.48v-4.63h4v3.1h5.11v6Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155786304}
        />
        <path
          id="Turkmenistan_svg___155786232"
          d="m245.7 282.71-3.9-3.78-3.8 3.55 3.34 3.24-4.34 4.18h6.4l2.3-2.23 2.3 2.23h6.4l-4.31-4.18 3.34-3.24-3.84-3.55-3.9 3.78Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155786232}
        />
        <path
          id="Turkmenistan_svg___155786160"
          d="M241.79 279.64c.32.3 3.91 3.79 3.91 3.79s3.59-3.49 3.91-3.79c.3.27 2.74 2.54 3.08 2.84l-3.33 3.24 3.78 3.67h-4.92L245.7 287l-2.51 2.44h-4.92l3.78-3.67-3.33-3.24Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155786160}
        />
        <path
          id="Turkmenistan_svg___155786088"
          d="m226.8 282.71-3.9-3.78-3.83 3.55 3.34 3.24-4.32 4.18h6.4l2.31-2.23 2.3 2.23h6.4l-4.31-4.18 3.34-3.24-3.83-3.55z"
          className="Turkmenistan_svg__cls-4"
          data-name={155786088}
        />
        <path
          id="Turkmenistan_svg___155786016"
          d="M222.89 279.64c.32.3 3.91 3.79 3.91 3.79s3.59-3.49 3.91-3.79l3.07 2.84-3.33 3.24 3.78 3.67h-4.92L226.8 287l-2.51 2.44h-4.93l3.78-3.67-3.33-3.24c.34-.3 2.78-2.57 3.08-2.84Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155786016}
        />
        <path
          id="Turkmenistan_svg___155785944"
          d="m207.89 282.71 3.9-3.78 3.84 3.55-3.34 3.24 4.31 4.18h-6.4l-2.31-2.23-2.3 2.23h-6.4l4.31-4.18-3.34-3.24 3.83-3.55z"
          className="Turkmenistan_svg__cls-4"
          data-name={155785944}
        />
        <path
          id="Turkmenistan_svg___155785872"
          d="M211.8 279.64c-.32.3-3.91 3.79-3.91 3.79s-3.59-3.49-3.91-3.79l-3.07 2.84 3.33 3.24-3.78 3.67h4.92l2.51-2.44 2.52 2.44h4.92l-3.78-3.67 3.33-3.24c-.34-.3-2.78-2.57-3.08-2.84"
          className="Turkmenistan_svg__cls-1"
          data-name={155785872}
        />
        <path
          id="Turkmenistan_svg___155785800"
          d="m245.7 385.46-3.9 3.79-3.8-3.55 3.34-3.24-4.34-4.19h6.4l2.3 2.24 2.31-2.24h6.4l-4.31 4.19 3.34 3.24-3.84 3.55z"
          className="Turkmenistan_svg__cls-4"
          data-name={155785800}
        />
        <path
          id="Turkmenistan_svg___155785728"
          d="m241.79 388.54 3.91-3.79 3.91 3.79 3.08-2.85-3.33-3.23 3.78-3.67h-4.92l-2.52 2.43-2.51-2.43h-4.92l3.78 3.67-3.33 3.23Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155785728}
        />
        <path
          id="Turkmenistan_svg___155785656"
          d="m226.8 385.46-3.9 3.79-3.83-3.55 3.34-3.24-4.32-4.19h6.4l2.31 2.24 2.3-2.24h6.4l-4.31 4.19 3.34 3.24-3.83 3.55z"
          className="Turkmenistan_svg__cls-4"
          data-name={155785656}
        />
        <path
          id="Turkmenistan_svg___155785584"
          d="m222.89 388.54 3.91-3.79 3.91 3.79 3.07-2.85-3.33-3.23 3.78-3.67h-4.92l-2.51 2.43-2.51-2.43h-4.93l3.78 3.67-3.33 3.23z"
          className="Turkmenistan_svg__cls-1"
          data-name={155785584}
        />
        <path
          id="Turkmenistan_svg___155785512"
          d="m207.89 385.46 3.9 3.79 3.84-3.55-3.34-3.24 4.31-4.19h-6.4l-2.31 2.24-2.3-2.24h-6.4l4.31 4.19-3.34 3.24 3.83 3.55z"
          className="Turkmenistan_svg__cls-4"
          data-name={155785512}
        />
        <path
          id="Turkmenistan_svg___155785440"
          d="m211.8 388.54-3.91-3.79-3.89 3.79-3.07-2.85 3.33-3.23-3.78-3.67h4.92l2.51 2.43 2.52-2.43h4.92l-3.78 3.67 3.33 3.23Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155785440}
        />
        <path
          id="Turkmenistan_svg___155785368"
          d="M175.9 289.39v11.08h-15.06v67.23h15.06v11.09h101.8V367.7h15.06v-67.23H277.7v-11.08z"
          className="Turkmenistan_svg__cls-4"
          data-name={155785368}
        />
        <path
          id="Turkmenistan_svg___155785296"
          d="M277.18 289.9v11.09h15.07v66.2h-15.07v11.08H176.41v-11.08h-15.06v-66.2h15.06V289.9z"
          className="Turkmenistan_svg__cls-2"
          data-name={155785296}
        />
        <path
          id="Turkmenistan_svg___155785224"
          d="M270.67 294.16h-87.74v13.34H165.6v53.18h17.33v13.34h87.74v-13.34h17.32V307.5h-17.32z"
          className="Turkmenistan_svg__cls-4"
          data-name={155785224}
        />
        <path
          id="Turkmenistan_svg___155785152"
          d="M270.15 294.67v13.34h17.33v52.15h-17.33v13.34h-86.71v-13.34h-17.32v-52.15h17.32v-13.34z"
          className="Turkmenistan_svg__cls-2"
          data-name={155785152}
        />
        <path
          id="Turkmenistan_svg___155785080"
          d="m165.6 350.55 31.77 23.47h58.85l31.77-23.47v-32.93l-31.77-23.46h-58.85l-31.77 23.46z"
          className="Turkmenistan_svg__cls-4"
          data-name={155785080}
        />
        <path
          id="Turkmenistan_svg___155785008"
          d="m256.05 294.67 31.43 23.21v32.41l-31.43 23.21h-58.51c-.13-.1-31.22-23.05-31.42-23.21v-32.41l31.42-23.21Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155785008}
        />
        <path
          id="Turkmenistan_svg___155819744"
          d="M262.36 301.31H191V307h-9.12v54.2H191v5.67h71.63v-5.67h9.13V307h-9.13v-5.67Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155819744}
        />
        <path
          id="Turkmenistan_svg___155819672"
          d="M262.1 301.82v5.67h9.12v53.2h-9.12v5.66h-70.6v-5.66h-9.13v-53.2h9.13v-5.67z"
          className="Turkmenistan_svg__cls-2"
          data-name={155819672}
        />
        <path
          id="Turkmenistan_svg___155819600"
          d="m184.09 353.82 15.52 13.05h54.37l15.53-13.05v-39.47l-15.53-13.04h-54.37l-15.52 13.04z"
          className="Turkmenistan_svg__cls-4"
          data-name={155819600}
        />
        <path
          id="Turkmenistan_svg___155819528"
          d="M253.79 301.82 269 314.59v39l-15.2 12.77h-54l-15.2-12.77v-39l15.2-12.77Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155819528}
        />
        <path
          id="Turkmenistan_svg___155819456"
          d="m186.8 352.56 13.8 11.59h52.39l13.81-11.59v-36.94l-13.81-11.6H200.6l-13.8 11.6z"
          className="Turkmenistan_svg__cls-4"
          data-name={155819456}
        />
        <path
          id="Turkmenistan_svg___155819384"
          d="m252.81 304.54 13.47 11.32v36.46l-13.47 11.32h-52l-13.48-11.32v-36.46l13.48-11.32Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155819384}
        />
        <path
          id="Turkmenistan_svg___155819312"
          d="M206.86 301.82h39.88v-2.08l-4.2-3.61-3.94 3.39-3.93-3.39-3.94 3.39-3.93-3.39-3.94 3.39-3.93-3.39-3.94 3.39-3.94-3.39-4.19 3.61z"
          className="Turkmenistan_svg__cls-4"
          data-name={155819312}
        />
        <path
          id="Turkmenistan_svg___155819240"
          d="M246.22 301.31h-7.36v-1.33l3.68-3.17 3.68 3.17z"
          className="Turkmenistan_svg__cls-2"
          data-name={155819240}
        />
        <path
          id="Turkmenistan_svg___155819168"
          d="M238.35 301.31h-7.36v-1.33l3.68-3.17 3.68 3.17z"
          className="Turkmenistan_svg__cls-5"
          data-name={155819168}
        />
        <path
          id="Turkmenistan_svg___155819096"
          d="M230.48 301.31h-7.36v-1.33l3.68-3.17 3.68 3.17z"
          className="Turkmenistan_svg__cls-2"
          data-name={155819096}
        />
        <path
          id="Turkmenistan_svg___155819024"
          d="M222.6 301.31h-7.35v-1.33l3.68-3.17 3.67 3.17z"
          className="Turkmenistan_svg__cls-5"
          data-name={155819024}
        />
        <path
          id="Turkmenistan_svg___155818952"
          d="M214.73 301.31h-7.36v-1.33l3.68-3.17 3.68 3.17z"
          className="Turkmenistan_svg__cls-2"
          data-name={155818952}
        />
        <path
          id="Turkmenistan_svg___155818880"
          d="m169.99 341.96 3.39 3.94-3.39 3.93 3.61 4.19h8.77v-39.87h-8.77l-3.61 4.19 3.39 3.94-3.39 3.93 3.39 3.94-3.39 3.94 3.39 3.93z"
          className="Turkmenistan_svg__cls-4"
          data-name={155818880}
        />
        <path
          id="Turkmenistan_svg___155818808"
          d="m173.84 353.51-3.17-3.68 3.17-3.68h8.02v7.36z"
          className="Turkmenistan_svg__cls-5"
          data-name={155818808}
        />
        <path
          id="Turkmenistan_svg___155818736"
          d="m173.84 345.64-3.17-3.68 3.17-3.68h8.02v7.36z"
          className="Turkmenistan_svg__cls-2"
          data-name={155818736}
        />
        <path
          id="Turkmenistan_svg___155818664"
          d="m173.84 337.77-3.17-3.68 3.17-3.68h8.02v7.36z"
          className="Turkmenistan_svg__cls-5"
          data-name={155818664}
        />
        <path
          id="Turkmenistan_svg___155818592"
          d="m173.84 329.89-3.17-3.68 3.17-3.67h8.02v7.35z"
          className="Turkmenistan_svg__cls-2"
          data-name={155818592}
        />
        <path
          id="Turkmenistan_svg___155818520"
          d="m173.84 322.02-3.17-3.68 3.17-3.67h8.02v7.35z"
          className="Turkmenistan_svg__cls-5"
          data-name={155818520}
        />
        <path
          id="Turkmenistan_svg___155818448"
          d="M206.86 366.35h39.88v2.08l-4.2 3.62-3.94-3.4-3.93 3.4-3.94-3.4-3.93 3.4-3.94-3.4-3.93 3.4-3.94-3.4-3.94 3.4-4.19-3.62z"
          className="Turkmenistan_svg__cls-4"
          data-name={155818448}
        />
        <path
          id="Turkmenistan_svg___155818376"
          d="M246.22 366.87h-7.36v1.33l3.68 3.17 3.68-3.17z"
          className="Turkmenistan_svg__cls-2"
          data-name={155818376}
        />
        <path
          id="Turkmenistan_svg___155818304"
          d="M238.35 366.87h-7.36v1.33l3.68 3.17 3.68-3.17z"
          className="Turkmenistan_svg__cls-5"
          data-name={155818304}
        />
        <path
          id="Turkmenistan_svg___155818232"
          d="M230.48 366.87h-7.36v1.33l3.68 3.17 3.68-3.17z"
          className="Turkmenistan_svg__cls-2"
          data-name={155818232}
        />
        <path
          id="Turkmenistan_svg___155818160"
          d="M222.6 366.87h-7.35v1.33l3.68 3.17 3.67-3.17z"
          className="Turkmenistan_svg__cls-5"
          data-name={155818160}
        />
        <path
          id="Turkmenistan_svg___155818088"
          d="M214.73 366.87h-7.36v1.33l3.68 3.17 3.68-3.17z"
          className="Turkmenistan_svg__cls-2"
          data-name={155818088}
        />
        <path
          id="Turkmenistan_svg___155818016"
          d="m283.61 341.96-3.4 3.94 3.4 3.93-3.62 4.19h-8.77v-39.87h8.77l3.62 4.19-3.4 3.94 3.4 3.93-3.4 3.94 3.4 3.94-3.4 3.93z"
          className="Turkmenistan_svg__cls-4"
          data-name={155818016}
        />
        <path
          id="Turkmenistan_svg___155817944"
          d="m279.76 353.51 3.17-3.68-3.17-3.68h-8.02v7.36z"
          className="Turkmenistan_svg__cls-5"
          data-name={155817944}
        />
        <path
          id="Turkmenistan_svg___155817872"
          d="m279.76 345.64 3.17-3.68-3.17-3.68h-8.02v7.36z"
          className="Turkmenistan_svg__cls-2"
          data-name={155817872}
        />
        <path
          id="Turkmenistan_svg___155817800"
          d="m279.76 337.77 3.17-3.68-3.17-3.68h-8.02v7.36z"
          className="Turkmenistan_svg__cls-5"
          data-name={155817800}
        />
        <path
          id="Turkmenistan_svg___155802624"
          d="m279.76 329.89 3.17-3.68-3.17-3.67h-8.02v7.35z"
          className="Turkmenistan_svg__cls-2"
          data-name={155802624}
        />
        <path
          id="Turkmenistan_svg___155802552"
          d="m279.76 322.02 3.17-3.68-3.17-3.67h-8.02v7.35z"
          className="Turkmenistan_svg__cls-5"
          data-name={155802552}
        />
        <path
          id="Turkmenistan_svg___155802480"
          d="m208.55 363.64 2.95-2.84 2.95 2.84z"
          className="Turkmenistan_svg__cls-3"
          data-name={155802480}
        />
        <path
          id="Turkmenistan_svg___155802408"
          d="m211.5 354.86-2.88-2.31-2.89 1.25-.52 3.3 2.75 2.41-4.52 4.37h5.6l2.46-2.37 2.46 2.37h5.61l-4.52-4.37 2.74-2.41-.52-3.3-2.89-1.25z"
          className="Turkmenistan_svg__cls-4"
          data-name={155802408}
        />
        <path
          id="Turkmenistan_svg___155802336"
          d="m206.19 354.16 2.35-1.01 2.96 2.37.16-.13 2.8-2.24 2.35 1.01.43 2.74-2.95 2.6 4.28 4.14h-4.12l-2.95-2.84-2.95 2.84h-4.12l4.28-4.14-2.95-2.6z"
          className="Turkmenistan_svg__cls-1"
          data-name={155802336}
        />
        <path
          id="Turkmenistan_svg___155802264"
          d="m223.85 363.64 2.95-2.84 2.95 2.84z"
          className="Turkmenistan_svg__cls-3"
          data-name={155802264}
        />
        <path
          id="Turkmenistan_svg___155802192"
          d="m226.8 354.86-2.89-2.31-2.88 1.25-.52 3.3 2.74 2.41-4.52 4.37h5.61l2.46-2.37 2.46 2.37h5.6l-4.52-4.37 2.75-2.41-.52-3.3-2.89-1.25z"
          className="Turkmenistan_svg__cls-4"
          data-name={155802192}
        />
        <path
          id="Turkmenistan_svg___155802120"
          d="m221.49 354.16 2.35-1.01 2.96 2.37.16-.13 2.8-2.24 2.34 1.01.44 2.74-2.95 2.6 4.28 4.14h-4.12l-2.95-2.84-2.95 2.84h-4.13l4.29-4.14-2.95-2.6z"
          className="Turkmenistan_svg__cls-1"
          data-name={155802120}
        />
        <path
          id="Turkmenistan_svg___155802048"
          d="m245.05 363.64-2.95-2.84-2.95 2.84z"
          className="Turkmenistan_svg__cls-3"
          data-name={155802048}
        />
        <path
          id="Turkmenistan_svg___155801976"
          d="m242.1 354.86 2.88-2.31 2.89 1.25.52 3.3-2.75 2.41 4.52 4.37h-5.6l-2.46-2.37-2.46 2.37h-5.61l4.52-4.37-2.74-2.41.51-3.3 2.89-1.25z"
          className="Turkmenistan_svg__cls-4"
          data-name={155801976}
        />
        <path
          id="Turkmenistan_svg___155801904"
          d="m247.4 354.16-2.34-1.01-2.96 2.37-.16-.13-2.81-2.24-2.34 1.01-.43 2.74 2.95 2.6-4.29 4.14h4.13l2.95-2.84 2.95 2.84h4.12l-4.29-4.14 2.96-2.6z"
          className="Turkmenistan_svg__cls-1"
          data-name={155801904}
        />
        <path
          id="Turkmenistan_svg___155801832"
          d="m208.55 304.54 2.95 2.84 2.95-2.84z"
          className="Turkmenistan_svg__cls-3"
          data-name={155801832}
        />
        <path
          id="Turkmenistan_svg___155801760"
          d="m211.5 313.32-2.88 2.3-2.89-1.25-.52-3.29 2.75-2.42-4.62-4.46h5.6l2.56 2.46 2.56-2.46h5.6l-4.61 4.46 2.74 2.42-.52 3.3-2.89 1.24z"
          className="Turkmenistan_svg__cls-4"
          data-name={155801760}
        />
        <path
          id="Turkmenistan_svg___155801688"
          d="m206.19 314.01 2.35 1.02 2.96-2.37.16.13 2.8 2.24 2.35-1.02.43-2.73-2.95-2.6 4.28-4.14h-4.12l-2.95 2.84-2.95-2.84h-4.12l4.28 4.14-2.95 2.6z"
          className="Turkmenistan_svg__cls-1"
          data-name={155801688}
        />
        <path
          id="Turkmenistan_svg___155801616"
          d="m223.85 304.54 2.95 2.84 2.95-2.84z"
          className="Turkmenistan_svg__cls-3"
          data-name={155801616}
        />
        <path
          id="Turkmenistan_svg___155801544"
          d="m226.8 313.32-2.89 2.3-2.88-1.25-.52-3.29 2.74-2.42-4.62-4.46h5.61l2.56 2.46 2.55-2.46h5.61l-4.62 4.46 2.75 2.42-.52 3.3-2.89 1.24z"
          className="Turkmenistan_svg__cls-4"
          data-name={155801544}
        />
        <path
          id="Turkmenistan_svg___155801472"
          d="m221.49 314.01 2.35 1.02 2.96-2.37.16.13 2.8 2.24 2.34-1.02.44-2.73-2.95-2.6 4.28-4.14h-4.12l-2.95 2.84-2.95-2.84h-4.13l4.29 4.14-2.95 2.6z"
          className="Turkmenistan_svg__cls-1"
          data-name={155801472}
        />
        <path
          id="Turkmenistan_svg___155801400"
          d="m245.05 304.54-2.95 2.84-2.95-2.84z"
          className="Turkmenistan_svg__cls-3"
          data-name={155801400}
        />
        <path
          id="Turkmenistan_svg___155801328"
          d="m242.1 313.32 2.88 2.3 2.89-1.25.52-3.29-2.75-2.42 4.62-4.46h-5.61l-2.55 2.46-2.56-2.46h-5.61l4.62 4.46-2.74 2.42.51 3.3 2.89 1.24z"
          className="Turkmenistan_svg__cls-4"
          data-name={155801328}
        />
        <path
          id="Turkmenistan_svg___155801256"
          d="m247.4 314.01-2.34 1.02-2.96-2.37-.16.13-2.81 2.24-2.34-1.02-.43-2.73 2.95-2.6-4.29-4.14h4.13l2.95 2.84 2.95-2.84h4.12l-4.29 4.14 2.96 2.6z"
          className="Turkmenistan_svg__cls-1"
          data-name={155801256}
        />
        <path
          id="Turkmenistan_svg___155801184"
          d="M266.28 319.4v3.87h-.01l-2.33-1.93z"
          className="Turkmenistan_svg__cls-3"
          data-name={155801184}
        />
        <path
          id="Turkmenistan_svg___155801112"
          d="m262.7 318.47-2.43-2.2-3.27.41-1.28 2.37 2.29 2.29-2.29 2.28L257 326l3.27.41 2.43-2.2 3.84 3.17v-4.55l-1.79-1.48 1.79-1.49v-4.06l-.29-.25Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155801112}
        />
        <path
          id="Turkmenistan_svg___155801040"
          d="m266.28 319.4-2.34 1.94 2.34 1.94v3.21l-3.6-3-2.58 2.34-2.77-.35-1-1.8 2.39-2.37-2.37-2.31 1-1.8 2.77-.35 2.58 2.34 3.6-3Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155801040}
        />
        <path
          id="Turkmenistan_svg___155800968"
          d="M266.28 332.15v3.87h-.01l-2.33-1.93z"
          className="Turkmenistan_svg__cls-3"
          data-name={155800968}
        />
        <path
          id="Turkmenistan_svg___155800896"
          d="m262.7 331.22-2.43-2.2-3.27.42-1.28 2.36 2.29 2.29-2.29 2.28 1.28 2.37 3.27.41 2.43-2.2 3.89 3.21v-4.54l-1.84-1.53 1.84-1.53V328l-3.89 3.21Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155800896}
        />
        <path
          id="Turkmenistan_svg___155800824"
          d="m266.28 332.15-2.34 1.94 2.34 1.93v3.22l-3.6-3-2.58 2.34-2.77-.35-1-1.8 2.39-2.37-2.39-2.38 1-1.8 2.77-.35 2.58 2.34 3.6-3Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155800824}
        />
        <path
          id="Turkmenistan_svg___155800752"
          d="M266.28 348.77v-3.87h-.01l-2.33 1.94z"
          className="Turkmenistan_svg__cls-3"
          data-name={155800752}
        />
        <path
          id="Turkmenistan_svg___155800680"
          d="m262.7 349.7-2.43 2.2-3.27-.41-1.28-2.37 2.29-2.28-2.29-2.29 1.28-2.36 3.27-.42 2.43 2.2 3.89-3.21v4.55l-1.84 1.53 1.84 1.53v4l-.31.26-3.58-2.95Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155800680}
        />
        <path
          id="Turkmenistan_svg___155768192"
          d="m266.28 348.78-2.34-1.94 2.34-1.94v-3.21l-3.6 3-2.58-2.34-2.77.35-1 1.8 2.39 2.38-2.39 2.37 1 1.8 2.77.35 2.58-2.34 3.6 3v-3.21Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155768192}
        />
        <path
          id="Turkmenistan_svg___155768120"
          d="M187.32 319.4v3.87l2.34-1.93z"
          className="Turkmenistan_svg__cls-3"
          data-name={155768120}
        />
        <path
          id="Turkmenistan_svg___155768048"
          d="m190.9 318.47 2.43-2.2 3.26.41 1.29 2.37-2.29 2.29 2.29 2.28-1.29 2.38-3.26.41-2.43-2.2-3.84 3.17v-4.55l1.79-1.48-1.79-1.49v-4.12l.25-.21 3.59 3Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155768048}
        />
        <path
          id="Turkmenistan_svg___155767976"
          d="m187.32 319.4 2.34 1.94-2.34 1.94v3.21l3.6-3 2.58 2.34 2.77-.35c.1-.19.83-1.53 1-1.8l-2.38-2.37 2.38-2.38c-.14-.27-.87-1.61-1-1.8l-2.77-.35-2.58 2.34-3.6-3Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155767976}
        />
        <path
          id="Turkmenistan_svg___155767904"
          d="M187.32 332.15v3.87l2.34-1.93z"
          className="Turkmenistan_svg__cls-3"
          data-name={155767904}
        />
        <path
          id="Turkmenistan_svg___155767832"
          d="m190.9 331.22 2.43-2.2 3.26.42 1.29 2.36-2.29 2.29 2.29 2.28-1.29 2.37-3.26.41-2.43-2.15-3.85 3.18v-4.55l1.8-1.49-1.8-1.49v-4.55q1.95 1.54 3.85 3.12"
          className="Turkmenistan_svg__cls-4"
          data-name={155767832}
        />
        <path
          id="Turkmenistan_svg___155767760"
          d="m187.32 332.15 2.34 1.94-2.34 1.91v3.22l3.6-3 2.58 2.34 2.77-.35c.1-.19.83-1.53 1-1.8l-2.38-2.37 2.38-2.38c-.14-.27-.87-1.61-1-1.8l-2.77-.35-2.58 2.34-3.6-3Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155767760}
        />
        <path
          id="Turkmenistan_svg___155767688"
          d="M187.32 348.77v-3.87l2.34 1.94z"
          className="Turkmenistan_svg__cls-3"
          data-name={155767688}
        />
        <path
          id="Turkmenistan_svg___155767616"
          d="m190.9 349.7 2.43 2.2 3.26-.41 1.29-2.37-2.29-2.28 2.29-2.29-1.29-2.36-3.26-.42-2.43 2.23-3.85-3.17v4.55l1.8 1.49-1.8 1.49v4.08l.28.24Z"
          className="Turkmenistan_svg__cls-4"
          data-name={155767616}
        />
        <path
          id="Turkmenistan_svg___155767544"
          d="m187.32 348.78 2.34-1.94-2.34-1.94v-3.21l3.6 3 2.58-2.34 2.77.35c.1.19.83 1.53 1 1.8l-2.38 2.38 2.38 2.37c-.14.27-.87 1.61-1 1.8l-2.77.35-2.58-2.4-3.6 3v-3.21Z"
          className="Turkmenistan_svg__cls-1"
          data-name={155767544}
        />
        <path
          id="Turkmenistan_svg___155767472"
          d="M239.88 342.9v-4.55h6.36v-8.52h-6.36v-4.55h-4.6v-4.99h-16.96v4.99h-4.61v4.55h-6.36v8.52h6.36v4.55h4.61v4.98h16.96v-4.98z"
          className="Turkmenistan_svg__cls-4"
          data-name={155767472}
        />
        <path
          id="Turkmenistan_svg___155767400"
          d="M214.23 330.34v-4.55h4.6v-4.98h7.71v5.14l-7.29 4.14v3.74h-11.38v-3.49z"
          className="Turkmenistan_svg__cls-5"
          data-name={155767400}
        />
        <path
          id="Turkmenistan_svg___155767328"
          d="M239.37 337.83v4.55h-4.61v4.99h-7.7v-5.14l7.28-4.15v-3.73h11.38v3.48z"
          className="Turkmenistan_svg__cls-5"
          data-name={155767328}
        />
        <path
          id="Turkmenistan_svg___155767256"
          d="M239.37 325.79v4.55h6.35v3.49h-11.38v-3.74l-7.28-4.14v-5.14h7.7v4.98z"
          className="Turkmenistan_svg__cls-3"
          data-name={155767256}
        />
        <path
          id="Turkmenistan_svg___155767184"
          d="M218.83 342.38h-4.6v-4.55h-6.36v-3.48h11.38v3.73l7.29 4.15v5.14h-7.71z"
          className="Turkmenistan_svg__cls-3"
          data-name={155767184}
        />
        <path
          id="Turkmenistan_svg___155767112"
          d="M219.77 334.35v3.46l6.77 3.85v-7.31z"
          className="Turkmenistan_svg__cls-2"
          data-name={155767112}
        />
        <path
          id="Turkmenistan_svg___155767040"
          d="m226.54 326.51-6.77 3.85v3.47h6.77z"
          className="Turkmenistan_svg__cls-1"
          data-name={155767040}
        />
        <path
          id="Turkmenistan_svg___155766968"
          d="M233.83 333.83v-3.47l-6.77-3.85v7.32z"
          className="Turkmenistan_svg__cls-2"
          data-name={155766968}
        />
        <path
          id="Turkmenistan_svg___155766896"
          d="m227.06 341.66 6.77-3.85v-3.46h-6.77z"
          className="Turkmenistan_svg__cls-1"
          data-name={155766896}
        />
      </g>
      <g id="Turkmenistan_svg___155698728" data-name={155698728}>
        <path
          id="Turkmenistan_svg___155766800"
          d="M165.18 424.48v14.06h-13.4v12.75H138.2v36.31h13.58v12.75h13.4v14.06h48.71l8.57 5.15-4.12 2.47h-17.37l-1.5-1.69h15.75l-4.44-4.46h-22.52l10.21 10.62h21.11l7.22-4.33 7.21 4.33h21.12l10.2-10.62h-22.52l-4.43 4.46h15.74l-1.5 1.69h-17.37l-4.12-2.47 8.57-5.15h48.72v-14.06h13.4V487.6h13.58v-36.31h-13.58v-12.75h-13.4v-14.06H239.7l-8.57-5.15 4.12-2.47h17.37l1.5 1.69h-15.74l4.43 4.46h22.52l-10.2-10.62h-21.12l-7.21 4.33-7.22-4.33h-21.11l-10.21 10.62h22.52l4.44-4.46h-15.75l1.5-1.69h17.37l4.12 2.47-8.57 5.15z"
          className="Turkmenistan_svg__cls-4"
          data-name={155766800}
        />
        <path
          id="Turkmenistan_svg___155766728"
          d="M301.3 451.8h13.58v35.29H301.3v12.75h-13.4v14.06h-48.34l-9.43 5.66 4.98 2.99h17.74l2.41-2.72h-15.65l3.42-3.44h21.1l-9.22 9.6h-20.76l-7.35-4.42-7.36 4.42h-20.75l-9.22-9.6h21.1l3.41 3.44h-15.65l2.41 2.72h17.75l4.97-2.99-9.42-5.66h-48.35v-14.06h-13.4v-12.75h-13.58V451.8h13.58v-12.75h13.4v-14.06h48.35l9.42-5.66-4.97-2.99h-17.75l-2.41 2.72h15.65l-3.41 3.44h-21.1l9.22-9.6h20.75l7.36 4.42 7.35-4.42h20.76l9.22 9.6h-21.1l-3.42-3.44h15.65l-2.41-2.72h-17.74l-4.98 2.99 9.43 5.66h48.34v14.06h13.4z"
          className="Turkmenistan_svg__cls-2"
          data-name={155766728}
        />
        <path
          id="Turkmenistan_svg___155766656"
          d="m226.8 517.55 14.49-8.7h41.56v-14.06h13.4v-12.76h13.58v-25.17h-13.58V444.1h-13.4v-14.06h-41.56l-14.49-8.71-14.5 8.71h-41.56v14.06h-13.4v12.76h-13.58v25.17h13.58v12.76h13.4v14.06h41.56z"
          className="Turkmenistan_svg__cls-4"
          data-name={155766656}
        />
        <path
          id="Turkmenistan_svg___155766584"
          d="M282.34 494.27v14.06h-41.19l-14.35 8.63-14.35-8.63h-41.19v-14.06h-13.4v-12.75h-13.58v-24.15h13.58v-12.75h13.4v-14.06h41.19l14.35-8.62 14.35 8.62h41.19v14.06h13.4v12.75h13.57v24.15h-13.57v12.75z"
          className="Turkmenistan_svg__cls-5"
          data-name={155766584}
        />
        <path
          id="Turkmenistan_svg___155766512"
          d="m226.8 513.44 13.51-8.12h39.01v-14.06h13.4v-12.75h13.58v-18.13h-13.58v-12.75h-13.4v-14.06h-39.01l-13.51-8.12-13.52 8.12h-39.01v14.06h-13.4v12.75h-13.58v18.13h13.58v12.75h13.4v14.06h39.01z"
          className="Turkmenistan_svg__cls-4"
          data-name={155766512}
        />
        <path
          id="Turkmenistan_svg___155766440"
          d="M278.81 490.75v14.06h-38.64l-13.37 8.03-13.38-8.03h-38.64v-14.06h-13.4v-12.76h-13.57V460.9h13.57v-12.76h13.4v-14.06h38.64l13.38-8.03 13.37 8.03h38.64v14.06h13.4v12.76h13.58v17.09h-13.58v12.76z"
          className="Turkmenistan_svg__cls-1"
          data-name={155766440}
        />
        <path
          id="Turkmenistan_svg___155766368"
          d="M179.84 485.82v13.61h21.24l-21.24-14.09z"
          className="Turkmenistan_svg__cls-4"
          data-name={155766368}
        />
        <path
          id="Turkmenistan_svg___155766296"
          d="m180.36 486.3 19.02 12.61h-19.02z"
          className="Turkmenistan_svg__cls-2"
          data-name={155766296}
        />
        <path
          id="Turkmenistan_svg___155749368"
          d="M240.54 484.79v-6.95h-2.37l-11.37-7.11-.14.08-11.23 7.03h-2.37v6.95h-6.75v7.46h6.75v6.95h2.37l11.37 7.11 11.37-7.11h2.37v-6.95h6.74v-7.46z"
          className="Turkmenistan_svg__cls-4"
          data-name={155749368}
        />
        <path
          id="Turkmenistan_svg___155749296"
          d="m226.8 471.33 10.4 6.51h-20.8zm10.4 27.87-10.4 6.5-10.4-6.5Z"
          className="Turkmenistan_svg__cls-5"
          data-name={155749296}
        />
        <path
          id="Turkmenistan_svg___155749224"
          d="M240.03 492.25v6.43h-12.98v-6.43z"
          className="Turkmenistan_svg__cls-2"
          data-name={155749224}
        />
        <path
          id="Turkmenistan_svg___155749152"
          d="M226.54 492.25v6.43h-12.97v-6.43z"
          className="Turkmenistan_svg__cls-2"
          data-name={155749152}
        />
        <path
          id="Turkmenistan_svg___155749080"
          d="M246.77 485.3v6.44H233.8v-6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155749080}
        />
        <path
          id="Turkmenistan_svg___155749008"
          d="M233.28 485.3v6.44h-12.97v-6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155749008}
        />
        <path
          id="Turkmenistan_svg___155748936"
          d="M219.8 485.3v6.44h-12.97v-6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155748936}
        />
        <path
          id="Turkmenistan_svg___155748864"
          d="M240.03 478.35v6.44h-12.98v-6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155748864}
        />
        <path
          id="Turkmenistan_svg___155748792"
          d="M226.54 478.35v6.44h-12.97v-6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155748792}
        />
        <path
          id="Turkmenistan_svg___155748720"
          d="M206.94 465.71v-6.95h-2.38l-11.37-7.11-.13.09-11.24 7.02h-2.37v6.95h-5.35v7.47h5.35v6.95h2.37l11.37 7.11 11.37-7.11h2.38v-6.95h8.13v-7.47z"
          className="Turkmenistan_svg__cls-4"
          data-name={155748720}
        />
        <path
          id="Turkmenistan_svg___155748648"
          d="m193.19 452.26 10.4 6.5h-20.8Zm10.4 27.87-10.4 6.5-10.4-6.5Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155748648}
        />
        <path
          id="Turkmenistan_svg___155748576"
          d="M206.42 473.18v6.43h-12.97v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155748576}
        />
        <path
          id="Turkmenistan_svg___155748504"
          d="M192.94 473.18v6.43h-12.97v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155748504}
        />
        <path
          id="Turkmenistan_svg___155748432"
          d="M214.56 466.23v6.43h-12.97v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155748432}
        />
        <path
          id="Turkmenistan_svg___155748360"
          d="M201.07 466.23v6.43H188.1v-6.43z"
          className="Turkmenistan_svg__cls-2"
          data-name={155748360}
        />
        <path
          id="Turkmenistan_svg___155748288"
          d="M187.59 466.23v6.43h-12.97v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155748288}
        />
        <path
          id="Turkmenistan_svg___155748216"
          d="M206.42 459.28v6.43h-12.97v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155748216}
        />
        <path
          id="Turkmenistan_svg___155748144"
          d="M192.94 459.28v6.43h-12.97v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155748144}
        />
        <path
          id="Turkmenistan_svg___155748072"
          d="M246.66 465.71v-6.95h2.37l11.37-7.11.14.09 11.23 7.02h2.37v6.95h5.35v7.47h-5.35v6.95h-2.37l-11.37 7.11-11.37-7.11h-2.37v-6.95h-8.14v-7.47z"
          className="Turkmenistan_svg__cls-4"
          data-name={155748072}
        />
        <path
          id="Turkmenistan_svg___155748000"
          d="m260.4 452.26-10.4 6.5h20.8ZM250 480.13l10.4 6.5 10.4-6.5Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155748000}
        />
        <path
          id="Turkmenistan_svg___155747928"
          d="M247.18 473.18v6.43h12.96v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155747928}
        />
        <path
          id="Turkmenistan_svg___155747856"
          d="M260.66 473.18v6.43h12.97v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155747856}
        />
        <path
          id="Turkmenistan_svg___155747784"
          d="M239.04 466.23v6.43h12.97v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155747784}
        />
        <path
          id="Turkmenistan_svg___155747712"
          d="M252.52 466.23v6.43h12.97v-6.43z"
          className="Turkmenistan_svg__cls-2"
          data-name={155747712}
        />
        <path
          id="Turkmenistan_svg___155747640"
          d="M266 466.23v6.43h12.98v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155747640}
        />
        <path
          id="Turkmenistan_svg___155747568"
          d="M247.18 459.28v6.43h12.96v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155747568}
        />
        <path
          id="Turkmenistan_svg___155747496"
          d="M260.66 459.28v6.43h12.97v-6.43z"
          className="Turkmenistan_svg__cls-5"
          data-name={155747496}
        />
        <path
          id="Turkmenistan_svg___155747424"
          d="M240.54 454.1v6.95h-2.37l-11.37 7.11-.14-.08-11.23-7.03h-2.37v-6.95h-6.75v-7.46h6.75v-6.95h2.37l11.37-7.11 11.37 7.11h2.37v6.95h6.74v7.46z"
          className="Turkmenistan_svg__cls-4"
          data-name={155747424}
        />
        <path
          id="Turkmenistan_svg___155734872"
          d="m226.8 467.56 10.4-6.51h-20.8zm10.4-27.87-10.4-6.5-10.4 6.5Z"
          className="Turkmenistan_svg__cls-5"
          data-name={155734872}
        />
        <path
          id="Turkmenistan_svg___155734800"
          d="M240.03 446.64v-6.43h-12.98v6.43z"
          className="Turkmenistan_svg__cls-2"
          data-name={155734800}
        />
        <path
          id="Turkmenistan_svg___155734728"
          d="M226.54 446.64v-6.43h-12.97v6.43z"
          className="Turkmenistan_svg__cls-2"
          data-name={155734728}
        />
        <path
          id="Turkmenistan_svg___155734656"
          d="M246.77 453.59v-6.44H233.8v6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155734656}
        />
        <path
          id="Turkmenistan_svg___155734584"
          d="M233.28 453.59v-6.44h-12.97v6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155734584}
        />
        <path
          id="Turkmenistan_svg___155734512"
          d="M219.8 453.59v-6.44h-12.97v6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155734512}
        />
        <path
          id="Turkmenistan_svg___155734440"
          d="M240.03 460.54v-6.44h-12.98v6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155734440}
        />
        <path
          id="Turkmenistan_svg___155734368"
          d="M226.54 460.54v-6.44h-12.97v6.44z"
          className="Turkmenistan_svg__cls-2"
          data-name={155734368}
        />
        <path
          id="Turkmenistan_svg___155734296"
          d="M179.84 453.07v-13.61h21.24l-21.24 14.09z"
          className="Turkmenistan_svg__cls-4"
          data-name={155734296}
        />
        <path
          id="Turkmenistan_svg___155734224"
          d="m180.36 452.59 19-12.61h-19Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155734224}
        />
        <path
          id="Turkmenistan_svg___155734152"
          d="M273.75 485.82v13.61h-21.24l21.24-14.09z"
          className="Turkmenistan_svg__cls-4"
          data-name={155734152}
        />
        <path
          id="Turkmenistan_svg___155734080"
          d="m273.24 486.3-19 12.61h19Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155734080}
        />
        <path
          id="Turkmenistan_svg___155734008"
          d="M273.75 453.07v-13.61h-21.24l21.24 14.09z"
          className="Turkmenistan_svg__cls-4"
          data-name={155734008}
        />
        <path
          id="Turkmenistan_svg___155733936"
          d="m273.24 452.59-19-12.61h19Z"
          className="Turkmenistan_svg__cls-2"
          data-name={155733936}
        />
        <path
          id="Turkmenistan_svg___155733864"
          d="M184.02 467.66v3.57h-5.83v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155733864}
        />
        <path
          id="Turkmenistan_svg___155733792"
          d="M183.47 468.21h-4.73v2.47h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155733792}
        />
        <path
          id="Turkmenistan_svg___155733720"
          d="M189.36 460.71v3.57h-5.82v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155733720}
        />
        <path
          id="Turkmenistan_svg___155733648"
          d="M188.82 461.26h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155733648}
        />
        <path
          id="Turkmenistan_svg___155733576"
          d="M202.85 460.71v3.57h-5.83v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155733576}
        />
        <path
          id="Turkmenistan_svg___155733504"
          d="M202.3 461.26h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155733504}
        />
        <path
          id="Turkmenistan_svg___155733432"
          d="M197.5 467.66v3.57h-5.82v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155733432}
        />
        <path
          id="Turkmenistan_svg___155733360"
          d="M196.95 468.21h-4.73v2.47h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155733360}
        />
        <path
          id="Turkmenistan_svg___155733288"
          d="M210.99 467.66v3.57h-5.83v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155733288}
        />
        <path
          id="Turkmenistan_svg___155733216"
          d="M210.44 468.21h-4.73v2.47h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155733216}
        />
        <path
          id="Turkmenistan_svg___155733144"
          d="M202.85 474.61v3.57h-5.83v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155733144}
        />
        <path
          id="Turkmenistan_svg___155733072"
          d="M202.3 475.15h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155733072}
        />
        <path
          id="Turkmenistan_svg___155733000"
          d="M189.36 474.61v3.57h-5.82v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155733000}
        />
        <path
          id="Turkmenistan_svg___155732928"
          d="M188.82 475.15h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155732928}
        />
        <path
          id="Turkmenistan_svg___155727696"
          d="M222.97 441.63v3.58h-5.83v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155727696}
        />
        <path
          id="Turkmenistan_svg___155727624"
          d="M222.42 442.18h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155727624}
        />
        <path
          id="Turkmenistan_svg___155727552"
          d="M236.45 441.63v3.58h-5.82v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155727552}
        />
        <path
          id="Turkmenistan_svg___155727480"
          d="M235.9 442.18h-4.72v2.48h4.72z"
          className="Turkmenistan_svg__cls-3"
          data-name={155727480}
        />
        <path
          id="Turkmenistan_svg___155727408"
          d="M216.23 448.58v3.58h-5.83v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155727408}
        />
        <path
          id="Turkmenistan_svg___155727336"
          d="M215.68 449.13h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155727336}
        />
        <path
          id="Turkmenistan_svg___155727264"
          d="M229.71 448.58v3.58h-5.83v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155727264}
        />
        <path
          id="Turkmenistan_svg___155727192"
          d="M229.16 449.13h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155727192}
        />
        <path
          id="Turkmenistan_svg___155727120"
          d="M243.19 448.58v3.58h-5.82v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155727120}
        />
        <path
          id="Turkmenistan_svg___155727048"
          d="M242.65 449.13h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155727048}
        />
        <path
          id="Turkmenistan_svg___155726976"
          d="M222.97 455.53v3.58h-5.83v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155726976}
        />
        <path
          id="Turkmenistan_svg___155726904"
          d="M222.42 456.08h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155726904}
        />
        <path
          id="Turkmenistan_svg___155726832"
          d="M236.45 455.53v3.58h-5.82v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155726832}
        />
        <path
          id="Turkmenistan_svg___155726760"
          d="M235.9 456.08h-4.72v2.48h4.72z"
          className="Turkmenistan_svg__cls-3"
          data-name={155726760}
        />
        <path
          id="Turkmenistan_svg___155714360"
          d="M256.57 460.71v3.57h-5.82v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155714360}
        />
        <path
          id="Turkmenistan_svg___155714288"
          d="M256.02 461.26h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155714288}
        />
        <path
          id="Turkmenistan_svg___155714216"
          d="M270.06 460.71v3.57h-5.83v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155714216}
        />
        <path
          id="Turkmenistan_svg___155714144"
          d="M269.51 461.26h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155714144}
        />
        <path
          id="Turkmenistan_svg___155714072"
          d="M248.44 467.66v3.57h-5.83v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155714072}
        />
        <path
          id="Turkmenistan_svg___155714000"
          d="M247.89 468.21h-4.73v2.47h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155714000}
        />
        <path
          id="Turkmenistan_svg___155713928"
          d="M261.92 467.66v3.57h-5.83v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155713928}
        />
        <path
          id="Turkmenistan_svg___155713856"
          d="M261.37 468.21h-4.73v2.47h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155713856}
        />
        <path
          id="Turkmenistan_svg___155713784"
          d="M275.4 467.66v3.57h-5.82v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155713784}
        />
        <path
          id="Turkmenistan_svg___155713712"
          d="M274.85 468.21h-4.72v2.47h4.72z"
          className="Turkmenistan_svg__cls-3"
          data-name={155713712}
        />
        <path
          id="Turkmenistan_svg___155713640"
          d="M256.57 474.61v3.57h-5.82v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155713640}
        />
        <path
          id="Turkmenistan_svg___155713568"
          d="M256.02 475.15h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155713568}
        />
        <path
          id="Turkmenistan_svg___155713496"
          d="M270.06 474.61v3.57h-5.83v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155713496}
        />
        <path
          id="Turkmenistan_svg___155713424"
          d="M269.51 475.15h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155713424}
        />
        <path
          id="Turkmenistan_svg___155708192"
          d="M222.97 479.78v3.58h-5.83v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155708192}
        />
        <path
          id="Turkmenistan_svg___155708120"
          d="M222.42 480.33h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155708120}
        />
        <path
          id="Turkmenistan_svg___155708048"
          d="M236.45 479.78v3.58h-5.82v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155708048}
        />
        <path
          id="Turkmenistan_svg___155707976"
          d="M235.9 480.33h-4.72v2.48h4.72z"
          className="Turkmenistan_svg__cls-3"
          data-name={155707976}
        />
        <path
          id="Turkmenistan_svg___155707904"
          d="M216.23 486.73v3.58h-5.83v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155707904}
        />
        <path
          id="Turkmenistan_svg___155707832"
          d="M215.68 487.28h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155707832}
        />
        <path
          id="Turkmenistan_svg___155707760"
          d="M229.71 486.73v3.58h-5.83v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155707760}
        />
        <path
          id="Turkmenistan_svg___155707688"
          d="M229.16 487.28h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155707688}
        />
        <path
          id="Turkmenistan_svg___155707616"
          d="M243.19 486.73v3.58h-5.82v-3.58z"
          className="Turkmenistan_svg__cls-4"
          data-name={155707616}
        />
        <path
          id="Turkmenistan_svg___155707544"
          d="M242.65 487.28h-4.73v2.48h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155707544}
        />
        <path
          id="Turkmenistan_svg___155707472"
          d="M222.97 493.68v3.57h-5.83v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155707472}
        />
        <path
          id="Turkmenistan_svg___155707400"
          d="M222.42 494.23h-4.73v2.47h4.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155707400}
        />
        <path
          id="Turkmenistan_svg___155707328"
          d="M236.45 493.68v3.57h-5.82v-3.57z"
          className="Turkmenistan_svg__cls-4"
          data-name={155707328}
        />
        <path
          id="Turkmenistan_svg___155698752"
          d="M235.9 494.23h-4.72v2.47h4.72z"
          className="Turkmenistan_svg__cls-3"
          data-name={155698752}
        />
      </g>
      <g id="Turkmenistan_svg___155661568" data-name={155661568}>
        <path
          id="Turkmenistan_svg___155698656"
          d="m300.38 619.97-50.46 27.16h-46.25l-50.46-27.16v-44.74l50.46-27.16h46.25l50.46 27.16z"
          className="Turkmenistan_svg__cls-4"
          data-name={155698656}
        />
        <path
          id="Turkmenistan_svg___155698584"
          d="m249.79 646.62 50.08-26.95v-44.14l-50.08-26.95H203.8l-50.07 26.95v44.14l50.07 26.95z"
          className="Turkmenistan_svg__cls-1"
          data-name={155698584}
        />
        <path
          id="Turkmenistan_svg___155698512"
          d="m296.3 617.51-47.4 25.51h-44.2l-47.41-25.51v-39.82l47.41-25.52h44.2l47.4 25.52z"
          className="Turkmenistan_svg__cls-4"
          data-name={155698512}
        />
        <path
          id="Turkmenistan_svg___155698440"
          d="M295.79 617.21v-19.35h-68.74v44.65h21.72z"
          className="Turkmenistan_svg__cls-5"
          data-name={155698440}
        />
        <path
          id="Turkmenistan_svg___155698368"
          d="M157.81 597.34h68.73v-44.65h-21.71l-47.02 25.3z"
          className="Turkmenistan_svg__cls-5"
          data-name={155698368}
        />
        <path
          id="Turkmenistan_svg___155698296"
          d="M204.83 642.51h21.71v-44.65h-68.73v19.35z"
          className="Turkmenistan_svg__cls-3"
          data-name={155698296}
        />
        <path
          id="Turkmenistan_svg___155698224"
          d="m295.79 577.99-47.02-25.3h-21.72v44.65h68.74z"
          className="Turkmenistan_svg__cls-3"
          data-name={155698224}
        />
        <path
          id="Turkmenistan_svg___155698152"
          d="m248.8 615.64-13.51 8.58v9.26l8.86 6.5h-13.13v-13.05l-4.22 2.67-4.23-2.67v13.05h-13.13l8.87-6.5v-9.26l-13.52-8.58h-9.44v-36.08h9.44l13.52-8.58v-9.26l-8.87-6.5h13.13v13.05l4.23-2.67 4.22 2.67v-13.05h13.13l-8.86 6.5v9.26l13.51 8.58h9.44v36.08z"
          className="Turkmenistan_svg__cls-4"
          data-name={155698152}
        />
        <path
          id="Turkmenistan_svg___155698080"
          d="m248.65 580.07-13.88-8.8v-9.81l7.81-5.73h-11.05v13.48l-4.48-2.84v30.97h30.68v-17.27z"
          className="Turkmenistan_svg__cls-5"
          data-name={155698080}
        />
        <path
          id="Turkmenistan_svg___155698008"
          d="m204.94 615.13 13.88 8.8v9.81l-7.8 5.73h11.04v-13.48l4.48 2.84v-30.97h-30.67v17.27z"
          className="Turkmenistan_svg__cls-5"
          data-name={155698008}
        />
        <path
          id="Turkmenistan_svg___155697936"
          d="M231.53 639.47h11.05l-7.81-5.73v-9.81l13.88-8.8h9.08v-17.27h-30.68v30.97l4.48-2.84z"
          className="Turkmenistan_svg__cls-3"
          data-name={155697936}
        />
        <path
          id="Turkmenistan_svg___155697864"
          d="m218.82 571.27-13.88 8.8h-9.07v17.27h30.67v-30.97l-4.48 2.84v-13.48h-11.04l7.8 5.73z"
          className="Turkmenistan_svg__cls-3"
          data-name={155697864}
        />
        <path
          id="Turkmenistan_svg___155697792"
          d="M247.71 583.34h6.78v28.52h-6.78l-20.91 13.27-20.92-13.27h-6.77v-28.52h6.77l20.92-13.28.13.09z"
          className="Turkmenistan_svg__cls-4"
          data-name={155697792}
        />
        <path
          id="Turkmenistan_svg___155674840"
          d="m247.56 583.85-20.76-13.18-20.77 13.18h-6.41v27.5h6.41l20.77 13.18 20.76-13.18h6.41v-27.5h-6.41"
          className="Turkmenistan_svg__cls-2"
          data-name={155674840}
        />
        <path
          id="Turkmenistan_svg___155674768"
          d="M232.29 584.43v1.91h2.4v3.81h-6.19v-9.53h14.07v6.63h6.83v9.73h-7.13v-3.82h3.34v-2.09h-4.44l-10.72 6.53 10.72 6.53h4.44v-2.09h-3.34v-3.82h7.13v9.73h-6.83v6.64H228.5v-9.54h6.19v3.81h-2.4v1.91h6.48v-3.64l-11.97-7.3-11.98 7.3v3.64h6.48v-1.91h-2.4v-3.81h6.19v9.54h-14.06v-6.64h-6.84v-9.73h7.13v3.82h-3.33v2.09h4.43l10.72-6.53-10.72-6.53h-4.43v2.09h3.33v3.82h-7.13v-9.73h6.84v-6.63h14.06v9.53h-6.19v-3.81h2.4v-1.91h-6.48v3.65l11.98 7.29 11.97-7.29v-3.65z"
          className="Turkmenistan_svg__cls-4"
          data-name={155674768}
        />
        <path
          id="Turkmenistan_svg___155674696"
          d="M207.47 601.52h3.34v-2.78h-6.1v8.69h6.83v6.64h13.04v-8.51h-5.16v2.79h2.4v2.93h-7.51v-4.44l12.49-7.62 12.49 7.62v4.44h-7.51v-2.93h2.4v-2.78h-5.17v8.5h13.04v-6.64h6.84v-8.69h-6.11v2.78h3.34v3.13h-5.09l-11.57-7.05 11.57-7.05h5.09v3.13h-3.34v2.78h6.11v-8.69h-6.84v-6.64h-13.04v8.51h5.17v-2.79h-2.4v-2.93h7.51v4.44l-12.49 7.62-12.49-7.62v-4.44h7.51v2.93h-2.4v2.79h5.16v-8.51h-13.04v6.64h-6.83v8.69h6.1v-2.78h-3.34v-3.13h5.09l11.57 7.05-11.57 7.05h-5.09z"
          className="Turkmenistan_svg__cls-3"
          data-name={155674696}
        />
        <path
          id="Turkmenistan_svg___155674624"
          d="M273.59 599.86v4.99h6.05v-4.99h6.01v14.12h-6.01v-4.99h-6.05v4.99h-6.01v-14.12z"
          className="Turkmenistan_svg__cls-4"
          data-name={155674624}
        />
        <path
          id="Turkmenistan_svg___155674552"
          d="M252.1 618.6v4.98h6.05v-4.98h6.01v14.11h-6.01v-4.98h-6.05v4.98h-6.01V618.6z"
          className="Turkmenistan_svg__cls-4"
          data-name={155674552}
        />
        <path
          id="Turkmenistan_svg___155674480"
          d="M273.59 595.34v-4.99h6.05v4.99h6.01v-14.12h-6.01v4.99h-6.05v-4.99h-6.01v14.12z"
          className="Turkmenistan_svg__cls-4"
          data-name={155674480}
        />
        <path
          id="Turkmenistan_svg___155674408"
          d="M180 599.86v4.99h-6.05v-4.99h-6.01v14.12h6.01v-4.99H180v4.99h6.01v-14.12z"
          className="Turkmenistan_svg__cls-4"
          data-name={155674408}
        />
        <path
          id="Turkmenistan_svg___155674336"
          d="M180 595.34v-4.99h-6.05v4.99h-6.01v-14.12h6.01v4.99H180v-4.99h6.01v14.12z"
          className="Turkmenistan_svg__cls-4"
          data-name={155674336}
        />
        <path
          id="Turkmenistan_svg___155674264"
          d="M252.1 576.6v-4.99h6.05v4.99h6.01v-14.11h-6.01v4.98h-6.05v-4.98h-6.01v14.11z"
          className="Turkmenistan_svg__cls-4"
          data-name={155674264}
        />
        <path
          id="Turkmenistan_svg___155674192"
          d="M201.5 618.6v4.98h-6.06v-4.98h-6v14.11h6v-4.98h6.06v4.98h6V618.6z"
          className="Turkmenistan_svg__cls-4"
          data-name={155674192}
        />
        <path
          id="Turkmenistan_svg___155674120"
          d="M201.5 576.6v-4.99h-6.06v4.99h-6v-14.11h6v4.98h6.06v-4.98h6v14.11z"
          className="Turkmenistan_svg__cls-4"
          data-name={155674120}
        />
        <path
          id="Turkmenistan_svg___155674048"
          d="M268.1 600.38v13.08h4.97v-4.98h7.09v4.98h4.98v-13.08h-4.98v4.98h-7.09v-4.98z"
          className="Turkmenistan_svg__cls-2"
          data-name={155674048}
        />
        <path
          id="Turkmenistan_svg___155673976"
          d="M246.61 619.11v13.09h4.97v-4.99h7.09v4.99h4.97v-13.09h-4.97v4.99h-7.09v-4.99z"
          className="Turkmenistan_svg__cls-2"
          data-name={155673976}
        />
        <path
          id="Turkmenistan_svg___155662528"
          d="M268.1 594.82v-13.08h4.97v4.98h7.09v-4.98h4.98v13.08h-4.98v-4.98h-7.09v4.98z"
          className="Turkmenistan_svg__cls-2"
          data-name={155662528}
        />
        <path
          id="Turkmenistan_svg___155662456"
          d="M185.5 600.38v13.08h-4.98v-4.98h-7.08v4.98h-4.98v-13.08h4.98v4.98h7.08v-4.98z"
          className="Turkmenistan_svg__cls-2"
          data-name={155662456}
        />
        <path
          id="Turkmenistan_svg___155662384"
          d="M185.5 594.82v-13.08h-4.98v4.98h-7.08v-4.98h-4.98v13.08h4.98v-4.98h7.08v4.98z"
          className="Turkmenistan_svg__cls-2"
          data-name={155662384}
        />
        <path
          id="Turkmenistan_svg___155662312"
          d="M246.61 576.09V563h4.97v4.98h7.09V563h4.97v13.09h-4.97v-4.99h-7.09v4.99z"
          className="Turkmenistan_svg__cls-2"
          data-name={155662312}
        />
        <path
          id="Turkmenistan_svg___155662240"
          d="M206.99 619.11v13.09h-4.98v-4.99h-7.08v4.99h-4.98v-13.09h4.98v4.99h7.08v-4.99z"
          className="Turkmenistan_svg__cls-2"
          data-name={155662240}
        />
        <path
          id="Turkmenistan_svg___155662168"
          d="M206.99 576.09V563h-4.98v4.98h-7.08V563h-4.98v13.09h4.98v-4.99h7.08v4.99z"
          className="Turkmenistan_svg__cls-2"
          data-name={155662168}
        />
        <path
          id="Turkmenistan_svg___155662096"
          d="m287.66 577.43-19.92-10.72v10.72z"
          className="Turkmenistan_svg__cls-4"
          data-name={155662096}
        />
        <path
          id="Turkmenistan_svg___155662024"
          d="m165.94 617.77 19.91 10.72v-10.72z"
          data-name={155662024}
        />
        <path
          id="Turkmenistan_svg___155661952"
          d="m165.94 577.43 19.91-10.72v10.72z"
          data-name={155661952}
        />
        <path
          id="Turkmenistan_svg___155661880"
          d="m287.66 617.77-19.92 10.72v-10.72z"
          className="Turkmenistan_svg__cls-4"
          data-name={155661880}
        />
        <path
          id="Turkmenistan_svg___155661808"
          d="M268.26 576.91v-9.34l17.36 9.34z"
          className="Turkmenistan_svg__cls-5"
          data-name={155661808}
        />
        <path
          id="Turkmenistan_svg___155661736"
          d="M185.34 618.29v9.34l-17.36-9.34z"
          className="Turkmenistan_svg__cls-5"
          data-name={155661736}
        />
        <path
          id="Turkmenistan_svg___155661664"
          d="M185.34 576.91v-9.34l-17.36 9.34z"
          className="Turkmenistan_svg__cls-3"
          data-name={155661664}
        />
        <path
          id="Turkmenistan_svg___155661592"
          d="M268.26 618.29v9.34l17.36-9.34z"
          className="Turkmenistan_svg__cls-3"
          data-name={155661592}
        />
      </g>
      <g id="Turkmenistan_svg__\u043E\u043B\u0438\u0432\u0430">
        <path
          id="Turkmenistan_svg___155640120"
          d="M145.37 637c1 4.09 4.21 7.17 5 11.42 1.55-4.43 3-8.13 1.91-13-.76-3.46-3.67-5.93-4-9.48-3.2 2.87-3.88 6.92-2.91 11.06m-.81 17.13a26.8 26.8 0 0 0 10.57 8.41c-.82-3.23.71-5.67 2.4-8.23 2.05-3.09 3-6.13 1.38-9.68a22 22 0 0 1-3 5 14.6 14.6 0 0 0-3.28 7.83c-2.09-1.55-3-3.87-4.13-6.11a19.3 19.3 0 0 0-7.61-7.64c.84 3.6 1.49 7.4 3.71 10.46Zm15.1 11a19.1 19.1 0 0 0-1 6.22 43.3 43.3 0 0 0-6.25-4.9c-2.87-2.32-6.21-4-8.56-6.91a14 14 0 0 0 5.75 10.54c3.73 2.8 8.24 4.3 11.92 7.23-.56-3.95.9-7.12 2.14-10.75a10.06 10.06 0 0 0-2.33-9.65c.08 2.92-.85 5.44-1.67 8.21Zm8.34 13.35c.77 3 2.92 5.44 4.39 8.12-4-1.23-7.45-3.38-11-5.4-4.26-2.32-8.89-3.8-12.75-6.86 1.92 7.11 6.68 11.23 13.65 13.33 4.76 1.44 8.77.72 14.54 3.06a40.4 40.4 0 0 0-3.67-11.66 42.8 42.8 0 0 1-3.92-7.87 9.5 9.5 0 0 0-1.21 7.28Zm14.57 10.88a18.3 18.3 0 0 0 5.39 5.85c-5.62.36-10.14-3.21-15.62-3.36-3.38-.09-6.6 1-10 .64 4.1 2.76 8.38 5.18 13.37 5.82 6.48.84 12.46-.79 19.61-.15-3.34-2.36-5-5.52-7.1-8.91-2.71-4.24-5.27-7.26-10.54-7.94 2.11 2.38 3.35 5.33 4.94 8.05Zm19.95 9.33c-9.9 1.68-19.38 3.86-29.49 4 11.31 3.94 22 4.66 33.2.58 6.07-2.2 10.72-3.38 15.3-3.05 6.7.48 14.83 3.12 23.84 12.62L249 710c-12.91-11.5-29.8-14.11-46.39-11.28Z"
          className="Turkmenistan_svg__cls-5"
          data-name={155640120}
        />
        <path
          id="Turkmenistan_svg___155640048"
          d="M308.28 637c-1 4.09-4.21 7.17-5 11.42-1.55-4.43-3-8.13-1.91-13 .76-3.46 3.67-5.93 4-9.48 3.19 2.87 3.88 6.92 2.91 11.06m.8 17.13a26.8 26.8 0 0 1-10.56 8.41c.81-3.23-.72-5.67-2.41-8.23-2-3.09-3-6.13-1.37-9.68a21.6 21.6 0 0 0 3 5 14.66 14.66 0 0 1 3.27 7.83c2.09-1.55 3-3.87 4.13-6.11a19.3 19.3 0 0 1 7.61-7.64c-.84 3.6-1.48 7.4-3.71 10.46Zm-15.09 11a19.4 19.4 0 0 1 1 6.22 43.7 43.7 0 0 1 6.24-4.9c2.87-2.32 6.22-4 8.56-6.91a14 14 0 0 1-5.79 10.53c-3.74 2.8-8.24 4.3-11.92 7.23.55-3.95-.9-7.12-2.14-10.75a10.06 10.06 0 0 1 2.32-9.65c0 2.93.89 5.45 1.72 8.22Zm-8.39 13.36c-.77 3-2.92 5.44-4.39 8.12 4-1.23 7.45-3.38 11-5.4 4.26-2.32 8.89-3.8 12.75-6.86-1.92 7.11-6.68 11.23-13.64 13.33-4.76 1.44-8.77.72-14.55 3.06a40.1 40.1 0 0 1 3.68-11.66 43.4 43.4 0 0 0 3.91-7.87 9.5 9.5 0 0 1 1.21 7.28ZM271 689.36a18.4 18.4 0 0 1-5.4 5.85c5.62.36 10.15-3.21 15.62-3.36 3.39-.09 6.6 1 10.05.64-4.09 2.76-8.38 5.18-13.37 5.82-6.48.84-12.46-.79-19.61-.15 3.34-2.36 5-5.52 7.1-8.91 2.71-4.24 5.27-7.26 10.55-7.94-2.12 2.38-3.35 5.33-4.94 8.05m-20 9.33c9.9 1.68 19.39 3.86 29.5 4-11.32 3.94-22 4.66-33.21.58-6.07-2.2-10.72-3.38-15.3-3.05-6.69.48-14.83 3.12-23.84 12.62L204.7 710c12.9-11.5 29.79-14.11 46.38-11.28Z"
          className="Turkmenistan_svg__cls-5"
          data-name={155640048}
        />
      </g>
      <g id="Turkmenistan_svg___155639520" data-name={155639520}>
        <path
          id="Turkmenistan_svg___155639904"
          d="m144.4 157.9 3.69 3.44-18.49 17.23v-7.04l10.94-10.19-3.69-3.44 7.78-7.26-3.39-3.16v4.53h-6.71v-5.42l15.1-14.06h7.59l.87 4.97h-4.4l4.77 4.45 11.94-10.9-11.94-10.9-4.77 4.45h4.4l-.87 4.98h-7.59l-15.1-14.07v-5.42h6.71v4.53l3.39-3.16-7.78-7.26 3.69-3.44-10.94-10.19v-7.04l18.49 17.23-3.69 3.44 7.79 7.26-6.69 6.22 4.42 4.12 8.51-7.92 18.69 17.17-18.69 17.18-8.51-7.93-4.42 4.12 6.69 6.22z"
          className="Turkmenistan_svg__cls-4"
          data-name={155639904}
        />
        <path
          id="Turkmenistan_svg___155639832"
          d="m152.37 125.11 6.09-5.68 12.72 11.62-12.72 11.62-6.09-5.68h5.08l-.69-3.96h-6.9l-14.78 13.78v4.69h5.61v-5.25l4.72 4.39-7.79 7.26 3.69 3.44-11.71 10.91v5.6l17.72-16.51-3.69-3.44 7.78-7.26-6.68-6.22 5.19-4.84 8.52 7.93 17.9-16.46-17.9-16.46-8.52 7.93-5.19-4.84 6.68-6.22-7.78-7.26 3.69-3.44-17.72-16.51v5.6l11.71 10.91-3.69 3.44 7.79 7.26-4.72 4.39v-5.25h-5.61v4.7l14.78 13.77h6.9l.69-3.96z"
          className="Turkmenistan_svg__cls-5"
          data-name={155639832}
        />
        <path
          id="Turkmenistan_svg___155639760"
          d="m165.07 130.82-3.94 3.67-.64-3.67.64-3.67z"
          className="Turkmenistan_svg__cls-4"
          data-name={155639760}
        />
        <path
          id="Turkmenistan_svg___155639688"
          d="m161.5 128.22-.46 2.6.46 2.61 2.8-2.61z"
          className="Turkmenistan_svg__cls-5"
          data-name={155639688}
        />
        <path
          id="Turkmenistan_svg___155639616"
          d="m138.49 134.77 3.71-3.71-3.71-3.73h-4.55v-5.2l-4.34-4.35v26.54l4.34-4.34v-5.21z"
          className="Turkmenistan_svg__cls-4"
          data-name={155639616}
        />
        <path
          id="Turkmenistan_svg___155639544"
          d="m138.28 127.85 3.19 3.21-3.19 3.19h-4.85v5.51l-3.83 3.84v-25.09l3.83 3.83v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155639544}
        />
      </g>
      <g id="Turkmenistan_svg___155634008" data-name={155634008}>
        <path
          id="Turkmenistan_svg___155639448"
          d="m144.4 293.26 3.69 3.44-18.49 17.22v-7.03l10.94-10.19-3.69-3.44 7.78-7.26-3.39-3.16v4.53h-6.71v-5.42l15.1-14.07h7.59l.87 4.98h-4.4l4.77 4.45 11.94-10.9-11.94-10.9-4.77 4.45h4.4l-.87 4.97h-7.59l-15.1-14.06v-5.42h6.71v4.53l3.39-3.17-7.78-7.25 3.69-3.44-10.94-10.19v-7.04l18.49 17.23-3.69 3.44 7.79 7.25-6.69 6.23 4.42 4.12 8.51-7.93 18.69 17.18-18.69 17.17-8.51-7.92-4.42 4.12 6.69 6.22z"
          className="Turkmenistan_svg__cls-4"
          data-name={155639448}
        />
        <path
          id="Turkmenistan_svg___155639376"
          d="m152.37 260.47 6.09-5.68 12.72 11.62-12.72 11.62-6.09-5.68h5.08l-.69-3.96h-6.9l-14.78 13.77v4.7h5.61v-5.25l4.72 4.39-7.79 7.26 3.69 3.44-11.71 10.91v5.59l17.72-16.5-3.69-3.44 7.78-7.26-6.68-6.22 5.19-4.84 8.52 7.93 17.9-16.46-17.9-16.46-8.52 7.93-5.19-4.84 6.68-6.23-7.78-7.25 3.69-3.44-17.72-16.51v5.6l11.71 10.91-3.69 3.44 7.79 7.25-4.72 4.4v-5.25h-5.61v4.69l14.78 13.77h6.9l.69-3.95z"
          className="Turkmenistan_svg__cls-5"
          data-name={155639376}
        />
        <path
          id="Turkmenistan_svg___155639304"
          d="m165.07 266.18-3.94 3.67-.64-3.67.64-3.67z"
          className="Turkmenistan_svg__cls-4"
          data-name={155639304}
        />
        <path
          id="Turkmenistan_svg___155639232"
          d="m161.5 263.57-.46 2.61.46 2.61 2.8-2.61z"
          className="Turkmenistan_svg__cls-5"
          data-name={155639232}
        />
        <path
          id="Turkmenistan_svg___155634104"
          d="m138.49 270.13 3.71-3.72-3.71-3.72h-4.55v-5.21l-4.34-4.34v26.54l4.34-4.35v-5.2z"
          className="Turkmenistan_svg__cls-4"
          data-name={155634104}
        />
        <path
          id="Turkmenistan_svg___155634032"
          d="m138.28 263.21 3.19 3.2-3.19 3.2h-4.85v5.51l-3.83 3.83v-25.09l3.83 3.84v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155634032}
        />
      </g>
      <g id="Turkmenistan_svg___155633552" data-name={155633552}>
        <path
          id="Turkmenistan_svg___155633936"
          d="m144.4 428.61 3.69 3.44-18.49 17.23v-7.04l10.94-10.19-3.69-3.44 7.78-7.25-3.39-3.16v4.52h-6.71v-5.41l15.1-14.07h7.59l.87 4.97h-4.4l4.77 4.45 11.94-10.9-11.94-10.9-4.77 4.45h4.4l-.87 4.98h-7.59l-15.1-14.07v-5.42h6.71v4.53l3.39-3.16-7.78-7.25 3.69-3.44-10.94-10.2v-7.03l18.49 17.23-3.69 3.44 7.79 7.25-6.69 6.23 4.42 4.12 8.51-7.93 18.69 17.17-18.69 17.18-8.51-7.93-4.42 4.12 6.69 6.23z"
          className="Turkmenistan_svg__cls-4"
          data-name={155633936}
        />
        <path
          id="Turkmenistan_svg___155633864"
          d="m152.37 395.82 6.09-5.67 12.72 11.61-12.72 11.62-6.09-5.68h5.08l-.69-3.95h-6.9l-14.78 13.77v4.69h5.61v-5.24l4.72 4.39-7.79 7.25 3.69 3.44-11.71 10.91v5.6l17.72-16.51-3.69-3.44 7.78-7.25-6.68-6.23 5.19-4.84 8.52 7.93 17.9-16.46-17.9-16.45-8.52 7.93-5.19-4.84 6.68-6.23-7.78-7.25 3.69-3.44-17.72-16.51v5.59l11.71 10.92-3.69 3.44 7.79 7.25-4.72 4.39v-5.25h-5.61v4.7l14.78 13.77h6.9l.69-3.96z"
          className="Turkmenistan_svg__cls-5"
          data-name={155633864}
        />
        <path
          id="Turkmenistan_svg___155633792"
          d="m165.07 401.54-3.94 3.67-.64-3.67.64-3.67z"
          className="Turkmenistan_svg__cls-4"
          data-name={155633792}
        />
        <path
          id="Turkmenistan_svg___155633720"
          d="m161.5 398.93-.46 2.61.46 2.6 2.8-2.6z"
          className="Turkmenistan_svg__cls-5"
          data-name={155633720}
        />
        <path
          id="Turkmenistan_svg___155633648"
          d="m138.49 405.48 3.71-3.71-3.71-3.72h-4.55v-5.21l-4.34-4.35v26.55l4.34-4.35v-5.21z"
          className="Turkmenistan_svg__cls-4"
          data-name={155633648}
        />
        <path
          id="Turkmenistan_svg___155633576"
          d="m138.28 398.56 3.19 3.21-3.19 3.2h-4.85v5.51l-3.83 3.83v-25.09l3.83 3.83v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155633576}
        />
      </g>
      <g id="Turkmenistan_svg___155620248" data-name={155620248}>
        <path
          id="Turkmenistan_svg___155633480"
          d="m144.4 563.97 3.69 3.44-18.49 17.23v-7.04l10.94-10.19-3.69-3.44 7.78-7.26-3.39-3.16v4.53h-6.71v-5.42l15.1-14.06h7.59l.87 4.97h-4.4l4.77 4.45 11.94-10.9-11.94-10.9-4.77 4.45h4.4l-.87 4.97h-7.59l-15.1-14.06v-5.42h6.71v4.53l3.39-3.16-7.78-7.26 3.69-3.44-10.94-10.19v-7.03l18.49 17.22-3.69 3.44 7.79 7.26-6.69 6.22 4.42 4.12 8.51-7.92 18.69 17.17-18.69 17.18-8.51-7.93-4.42 4.12 6.69 6.22z"
          className="Turkmenistan_svg__cls-4"
          data-name={155633480}
        />
        <path
          id="Turkmenistan_svg___155633408"
          d="m152.37 531.18 6.09-5.68 12.72 11.62-12.72 11.62-6.09-5.68h5.08l-.69-3.95h-6.9l-14.78 13.77v4.69h5.61v-5.25l4.72 4.39-7.79 7.26 3.69 3.44-11.71 10.91v5.6l17.72-16.51-3.69-3.44 7.78-7.26-6.68-6.22 5.19-4.84 8.52 7.93 17.9-16.46-17.9-16.46-8.52 7.93-5.19-4.84 6.68-6.22-7.78-7.26 3.69-3.44-17.72-16.51v5.6l11.71 10.91-3.69 3.44 7.79 7.26-4.72 4.39v-5.25h-5.61v4.7l14.78 13.77h6.9l.69-3.96z"
          className="Turkmenistan_svg__cls-5"
          data-name={155633408}
        />
        <path
          id="Turkmenistan_svg___155633336"
          d="m165.07 536.89-3.94 3.67-.64-3.67.64-3.67z"
          className="Turkmenistan_svg__cls-4"
          data-name={155633336}
        />
        <path
          id="Turkmenistan_svg___155633264"
          d="m161.5 534.28-.46 2.61.46 2.61 2.8-2.61z"
          className="Turkmenistan_svg__cls-5"
          data-name={155633264}
        />
        <path
          id="Turkmenistan_svg___155633192"
          d="m138.49 540.84 3.71-3.71-3.71-3.73h-4.55v-5.2l-4.34-4.35v26.54l4.34-4.34v-5.21z"
          className="Turkmenistan_svg__cls-4"
          data-name={155633192}
        />
        <path
          id="Turkmenistan_svg___155620272"
          d="m138.28 533.92 3.19 3.21-3.19 3.19h-4.85v5.52l-3.83 3.83v-25.09l3.83 3.83v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155620272}
        />
      </g>
      <g id="Turkmenistan_svg___155619792" data-name={155619792}>
        <path
          id="Turkmenistan_svg___155620176"
          d="m309.2 157.9-3.7 3.44 18.5 17.23v-7.04l-10.94-10.19 3.69-3.44-7.79-7.26 3.39-3.16v4.53h6.71v-5.42l-15.1-14.06h-7.59l-.87 4.97h4.41l-4.78 4.45-11.94-10.9 11.94-10.9 4.78 4.45h-4.41l.87 4.98h7.59l15.1-14.07v-5.42h-6.71v4.53l-3.39-3.16 7.79-7.26-3.69-3.44L324 90.57v-7.04l-18.5 17.23 3.7 3.44-7.79 7.26 6.68 6.22-4.42 4.12-8.51-7.92-18.68 17.17 18.68 17.18 8.51-7.93 4.42 4.12-6.68 6.22z"
          className="Turkmenistan_svg__cls-4"
          data-name={155620176}
        />
        <path
          id="Turkmenistan_svg___155620104"
          d="m301.23 125.11-6.1-5.68-12.72 11.62 12.72 11.62 6.1-5.68h-5.09l.7-3.96h6.9l14.78 13.78v4.69h-5.62v-5.25l-4.71 4.39 7.78 7.26-3.69 3.44L324 172.25v5.6l-17.72-16.51 3.69-3.44-7.79-7.26 6.69-6.22-5.2-4.84-8.51 7.93-17.91-16.46 17.91-16.46 8.51 7.93 5.2-4.84-6.69-6.22 7.79-7.26-3.69-3.44L324 84.25v5.6l-11.72 10.91 3.69 3.44-7.78 7.26 4.71 4.39v-5.25h5.62v4.7l-14.78 13.77h-6.9l-.7-3.96z"
          className="Turkmenistan_svg__cls-5"
          data-name={155620104}
        />
        <path
          id="Turkmenistan_svg___155620032"
          d="m288.52 130.82 3.94 3.67.65-3.67-.65-3.67z"
          className="Turkmenistan_svg__cls-4"
          data-name={155620032}
        />
        <path
          id="Turkmenistan_svg___155619960"
          d="m292.09 128.22.46 2.6-.46 2.61-2.8-2.61z"
          className="Turkmenistan_svg__cls-5"
          data-name={155619960}
        />
        <path
          id="Turkmenistan_svg___155619888"
          d="m315.1 134.77-3.7-3.71 3.7-3.73h4.55v-5.2l4.35-4.35v26.54l-4.35-4.34v-5.21z"
          className="Turkmenistan_svg__cls-4"
          data-name={155619888}
        />
        <path
          id="Turkmenistan_svg___155619816"
          d="m315.31 127.85-3.19 3.21 3.19 3.19h4.85v5.51l3.84 3.84v-25.09l-3.84 3.83v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155619816}
        />
      </g>
      <g id="Turkmenistan_svg___155619336" data-name={155619336}>
        <path
          id="Turkmenistan_svg___155619720"
          d="m309.2 293.26-3.7 3.44 18.5 17.22v-7.03l-10.94-10.19 3.69-3.44-7.79-7.26 3.39-3.16v4.53h6.71v-5.42l-15.1-14.07h-7.59l-.87 4.98h4.41l-4.78 4.45-11.94-10.9 11.94-10.9 4.78 4.45h-4.41l.87 4.97h7.59l15.1-14.06v-5.42h-6.71v4.53l-3.39-3.17 7.79-7.25-3.69-3.44L324 225.93v-7.04l-18.5 17.23 3.7 3.44-7.79 7.25 6.68 6.23-4.42 4.12-8.51-7.93-18.68 17.18 18.68 17.17 8.51-7.92 4.42 4.12-6.68 6.22z"
          className="Turkmenistan_svg__cls-4"
          data-name={155619720}
        />
        <path
          id="Turkmenistan_svg___155619648"
          d="m301.23 260.47-6.1-5.68-12.72 11.62 12.72 11.62 6.1-5.68h-5.09l.7-3.96h6.9l14.78 13.77v4.7h-5.62v-5.25l-4.71 4.39 7.78 7.26-3.69 3.44L324 307.61v5.59l-17.72-16.5 3.69-3.44-7.79-7.26 6.69-6.22-5.2-4.84-8.51 7.93-17.91-16.46 17.91-16.46 8.51 7.93 5.2-4.84-6.69-6.23 7.79-7.25-3.69-3.44L324 219.61v5.6l-11.72 10.91 3.69 3.44-7.78 7.25 4.71 4.4v-5.25h5.62v4.69l-14.78 13.77h-6.9l-.7-3.95z"
          className="Turkmenistan_svg__cls-5"
          data-name={155619648}
        />
        <path
          id="Turkmenistan_svg___155619576"
          d="m288.52 266.18 3.94 3.67.65-3.67-.65-3.67z"
          className="Turkmenistan_svg__cls-4"
          data-name={155619576}
        />
        <path
          id="Turkmenistan_svg___155619504"
          d="m292.09 263.57.46 2.61-.46 2.61-2.8-2.61z"
          className="Turkmenistan_svg__cls-5"
          data-name={155619504}
        />
        <path
          id="Turkmenistan_svg___155619432"
          d="m315.1 270.13-3.7-3.72 3.7-3.72h4.55v-5.21l4.35-4.34v26.54l-4.35-4.35v-5.2z"
          className="Turkmenistan_svg__cls-4"
          data-name={155619432}
        />
        <path
          id="Turkmenistan_svg___155619360"
          d="m315.31 263.21-3.19 3.2 3.19 3.2h4.85v5.51l3.84 3.83v-25.09l-3.84 3.84v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155619360}
        />
      </g>
      <g id="Turkmenistan_svg___155610240" data-name={155610240}>
        <path
          id="Turkmenistan_svg___155610624"
          d="m309.2 428.61-3.7 3.44 18.5 17.23v-7.04l-10.94-10.19 3.69-3.44-7.79-7.25 3.39-3.16v4.52h6.71v-5.41l-15.1-14.07h-7.59l-.87 4.97h4.41l-4.78 4.45-11.94-10.9 11.94-10.9 4.78 4.45h-4.41l.87 4.98h7.59l15.1-14.07v-5.42h-6.71v4.53l-3.39-3.16 7.79-7.25-3.69-3.44 10.94-10.2v-7.03l-18.5 17.23 3.7 3.44-7.79 7.25 6.68 6.23-4.42 4.12-8.51-7.93-18.68 17.17 18.68 17.18 8.51-7.93 4.42 4.12-6.68 6.23z"
          className="Turkmenistan_svg__cls-4"
          data-name={155610624}
        />
        <path
          id="Turkmenistan_svg___155610552"
          d="m301.23 395.82-6.1-5.67-12.72 11.61 12.72 11.62 6.1-5.68h-5.09l.7-3.95h6.9l14.78 13.77v4.69h-5.62v-5.24l-4.71 4.39 7.78 7.25-3.69 3.44L324 442.96v5.6l-17.72-16.51 3.69-3.44-7.79-7.25 6.69-6.23-5.2-4.84-8.51 7.93-17.91-16.46 17.91-16.45 8.51 7.93 5.2-4.84-6.69-6.23 7.79-7.25-3.69-3.44L324 354.97v5.59l-11.72 10.92 3.69 3.44-7.78 7.25 4.71 4.39v-5.25h5.62v4.7l-14.78 13.77h-6.9l-.7-3.96z"
          className="Turkmenistan_svg__cls-5"
          data-name={155610552}
        />
        <path
          id="Turkmenistan_svg___155610480"
          d="m288.52 401.54 3.94 3.67.65-3.67-.65-3.67z"
          className="Turkmenistan_svg__cls-4"
          data-name={155610480}
        />
        <path
          id="Turkmenistan_svg___155610408"
          d="m292.09 398.93.46 2.61-.46 2.6-2.8-2.6z"
          className="Turkmenistan_svg__cls-5"
          data-name={155610408}
        />
        <path
          id="Turkmenistan_svg___155610336"
          d="m315.1 405.48-3.7-3.71 3.7-3.72h4.55v-5.21l4.35-4.35v26.55l-4.35-4.35v-5.21z"
          className="Turkmenistan_svg__cls-4"
          data-name={155610336}
        />
        <path
          id="Turkmenistan_svg___155610264"
          d="m315.31 398.56-3.19 3.21 3.19 3.2h4.85v5.51l3.84 3.83v-25.09l-3.84 3.83v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155610264}
        />
      </g>
      <g id="Turkmenistan_svg___155609784" data-name={155609784}>
        <path
          id="Turkmenistan_svg___155610168"
          d="m309.2 563.97-3.7 3.44 18.5 17.23v-7.04l-10.94-10.19 3.69-3.44-7.79-7.26 3.39-3.16v4.53h6.71v-5.42l-15.1-14.06h-7.59l-.87 4.97h4.41l-4.78 4.45-11.94-10.9 11.94-10.9 4.78 4.45h-4.41l.87 4.97h7.59l15.1-14.06v-5.42h-6.71v4.53l-3.39-3.16 7.79-7.26-3.69-3.44L324 496.64v-7.03l-18.5 17.22 3.7 3.44-7.79 7.26 6.68 6.22-4.42 4.12-8.51-7.92-18.68 17.17 18.68 17.18 8.51-7.93 4.42 4.12-6.68 6.22z"
          className="Turkmenistan_svg__cls-4"
          data-name={155610168}
        />
        <path
          id="Turkmenistan_svg___155610096"
          d="m301.23 531.18-6.1-5.68-12.72 11.62 12.72 11.62 6.1-5.68h-5.09l.7-3.95h6.9l14.78 13.77v4.69h-5.62v-5.25l-4.71 4.39 7.78 7.26-3.69 3.44L324 578.32v5.6l-17.72-16.51 3.69-3.44-7.79-7.26 6.69-6.22-5.2-4.84-8.51 7.93-17.91-16.46 17.91-16.46 8.51 7.93 5.2-4.84-6.69-6.22 7.79-7.26-3.69-3.44L324 490.32v5.6l-11.72 10.91 3.69 3.44-7.78 7.26 4.71 4.39v-5.25h5.62v4.7l-14.78 13.77h-6.9l-.7-3.96z"
          className="Turkmenistan_svg__cls-5"
          data-name={155610096}
        />
        <path
          id="Turkmenistan_svg___155610024"
          d="m288.52 536.89 3.94 3.67.65-3.67-.65-3.67z"
          className="Turkmenistan_svg__cls-4"
          data-name={155610024}
        />
        <path
          id="Turkmenistan_svg___155609952"
          d="m292.09 534.28.46 2.61-.46 2.61-2.8-2.61z"
          className="Turkmenistan_svg__cls-5"
          data-name={155609952}
        />
        <path
          id="Turkmenistan_svg___155609880"
          d="m315.1 540.84-3.7-3.71 3.7-3.73h4.55v-5.2l4.35-4.35v26.54l-4.35-4.34v-5.21z"
          className="Turkmenistan_svg__cls-4"
          data-name={155609880}
        />
        <path
          id="Turkmenistan_svg___155609808"
          d="m315.31 533.92-3.19 3.21 3.19 3.19h4.85v5.52l3.84 3.83v-25.09l-3.84 3.83v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155609808}
        />
      </g>
      <g id="Turkmenistan_svg___155603432" data-name={155603432}>
        <path
          id="Turkmenistan_svg___155609712"
          d="M141.24 16.43v4.53h-6.71v-5.41l15.1-14.07h7.59l.87 4.97h-4.4l4.77 4.45L170.4 0h6.72l-18.69 17.18-8.51-7.93-4.42 4.12 6.69 6.22-7.79 7.26 3.69 3.44-18.49 17.23v-7.04l10.94-10.19-3.69-3.44 7.78-7.26z"
          className="Turkmenistan_svg__cls-4"
          data-name={155609712}
        />
        <path
          id="Turkmenistan_svg___155603744"
          d="M141.31 30.29 129.6 41.2v5.6l17.72-16.51-3.69-3.44 7.78-7.26-6.68-6.22 5.19-4.84 8.52 7.93L176.34 0h-5.16l-12.72 11.62-6.09-5.68h5.08l-.69-3.95h-6.9l-14.78 13.77v4.69h5.61v-5.24l4.72 4.38-7.79 7.26z"
          className="Turkmenistan_svg__cls-5"
          data-name={155603744}
        />
        <path
          id="Turkmenistan_svg___155603672"
          d="m164.83 0-3.7 3.44-.6-3.44z"
          className="Turkmenistan_svg__cls-4"
          data-name={155603672}
        />
        <path
          id="Turkmenistan_svg___155603600"
          d="m161.08 0 .42 2.38L164.06 0z"
          className="Turkmenistan_svg__cls-5"
          data-name={155603600}
        />
        <path
          id="Turkmenistan_svg___155603528"
          d="M142.19 0H129.6v13.28l4.34-4.35V3.72h4.55L142.2.01z"
          className="Turkmenistan_svg__cls-4"
          data-name={155603528}
        />
        <path
          id="Turkmenistan_svg___155603456"
          d="M129.6 12.55V0h11.87v.01l-3.19 3.2h-4.85v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155603456}
        />
      </g>
      <g id="Turkmenistan_svg___155602976" data-name={155602976}>
        <path
          id="Turkmenistan_svg___155603360"
          d="M312.35 16.43v4.53h6.71v-5.41l-15.1-14.07h-7.59l-.87 4.97h4.41l-4.78 4.45L283.19 0h-6.71l18.68 17.18 8.51-7.93 4.42 4.12-6.68 6.22 7.79 7.26-3.7 3.44L324 47.52v-7.04l-10.94-10.19 3.69-3.44-7.79-7.26z"
          className="Turkmenistan_svg__cls-4"
          data-name={155603360}
        />
        <path
          id="Turkmenistan_svg___155603288"
          d="M312.28 30.29 324 41.2v5.6l-17.72-16.51 3.69-3.44-7.79-7.26 6.69-6.22-5.2-4.84-8.51 7.93L277.26 0h5.16l12.71 11.62 6.1-5.68h-5.09l.7-3.95h6.9l14.78 13.77v4.69h-5.62v-5.24l-4.71 4.38 7.78 7.26z"
          className="Turkmenistan_svg__cls-5"
          data-name={155603288}
        />
        <path
          id="Turkmenistan_svg___155603216"
          d="m288.77 0 3.69 3.44.6-3.44z"
          className="Turkmenistan_svg__cls-4"
          data-name={155603216}
        />
        <path
          id="Turkmenistan_svg___155603144"
          d="m292.51 0-.42 2.38L289.54 0z"
          className="Turkmenistan_svg__cls-5"
          data-name={155603144}
        />
        <path
          id="Turkmenistan_svg___155603072"
          d="M311.4 0H324v13.28l-4.35-4.35V3.72h-4.55L311.4.01z"
          className="Turkmenistan_svg__cls-4"
          data-name={155603072}
        />
        <path
          id="Turkmenistan_svg___155603000"
          d="M324 12.55V0h-11.87l-.01.01 3.19 3.2h4.85v5.51z"
          className="Turkmenistan_svg__cls-5"
          data-name={155603000}
        />
      </g>
    </g>
  </svg>
);
export default SvgTurkmenistan;
