import * as React from "react";
import type { SVGProps } from "react";
const SvgGuatemala = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1280 720"
    {...props}
  >
    <defs>
      <radialGradient
        id="Guatemala_svg__radial-gradient"
        cx={597.26}
        cy={-492.87}
        r={19.82}
        gradientTransform="matrix(1 0 0 -1 8.34 -122.96)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.22} stopColor="#b07e09" stopOpacity={0} />
        <stop offset={1} stopColor="#b07e09" />
      </radialGradient>
      <radialGradient
        xlinkHref="#Guatemala_svg__radial-gradient"
        id="Guatemala_svg__radial-gradient-2"
        cx={602.23}
        cy={-498.54}
        r={13.08}
      />
      <radialGradient
        xlinkHref="#Guatemala_svg__radial-gradient"
        id="Guatemala_svg__radial-gradient-3"
        cx={605.29}
        cy={-493.32}
        r={11.72}
      />
      <radialGradient
        xlinkHref="#Guatemala_svg__radial-gradient"
        id="Guatemala_svg__radial-gradient-4"
        cx={610.41}
        cy={-491.42}
        r={20.8}
      />
      <radialGradient
        xlinkHref="#Guatemala_svg__radial-gradient"
        id="Guatemala_svg__radial-gradient-5"
        cx={595.54}
        cy={-425.86}
        r={15.6}
      />
      <radialGradient
        id="Guatemala_svg__radial-gradient-6"
        cx={628.66}
        cy={-396.48}
        r={17.54}
        gradientTransform="matrix(1 0 0 -1 8.34 -122.96)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.26} stopColor="#4c0505" stopOpacity={0} />
        <stop offset={1} stopColor="#4c0505" />
      </radialGradient>
      <radialGradient
        id="Guatemala_svg__radial-gradient-7"
        cx={403.86}
        cy={-334.63}
        r={0.41}
        gradientTransform="matrix(1 0 0 -1 238.23 -76.2)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        xlinkHref="#Guatemala_svg__radial-gradient"
        id="Guatemala_svg__linear-gradient"
        x1={629.01}
        x2={627.15}
        y1={-434.02}
        y2={-401.41}
        gradientTransform="matrix(1 0 0 -1 8.34 -122.96)"
      />
      <linearGradient
        xlinkHref="#Guatemala_svg__radial-gradient"
        id="Guatemala_svg__linear-gradient-2"
        x1={640.06}
        x2={642.9}
        y1={-478.95}
        y2={-514.82}
        gradientTransform="matrix(1 0 0 -1 8.34 -122.96)"
      />
      <linearGradient
        xlinkHref="#Guatemala_svg__radial-gradient"
        id="Guatemala_svg__linear-gradient-3"
        x1={602.2}
        x2={606.79}
        y1={-422.46}
        y2={-411.73}
        gradientTransform="matrix(1 0 0 -1 8.34 -122.96)"
      />
      <linearGradient
        xlinkHref="#Guatemala_svg__radial-gradient"
        id="Guatemala_svg__linear-gradient-4"
        x1={628.3}
        x2={631.44}
        y1={-407.74}
        y2={-447.53}
        gradientTransform="matrix(1 0 0 -1 8.34 -122.96)"
      />
      <style>
        {
          ".Guatemala_svg__cls-14,.Guatemala_svg__cls-15,.Guatemala_svg__cls-3{fill:none}.Guatemala_svg__cls-3,.Guatemala_svg__cls-4,.Guatemala_svg__cls-5{stroke:#24420e}.Guatemala_svg__cls-3{stroke-width:2px}.Guatemala_svg__cls-4{fill:#67923d}.Guatemala_svg__cls-14,.Guatemala_svg__cls-21,.Guatemala_svg__cls-4,.Guatemala_svg__cls-5{stroke-width:.75px}.Guatemala_svg__cls-5{fill:#406325}.Guatemala_svg__cls-6,.Guatemala_svg__cls-7{fill:#ba1f3e;stroke:#511124}.Guatemala_svg__cls-14,.Guatemala_svg__cls-15,.Guatemala_svg__cls-6,.Guatemala_svg__cls-7{stroke-linecap:round}.Guatemala_svg__cls-12,.Guatemala_svg__cls-14,.Guatemala_svg__cls-15,.Guatemala_svg__cls-6,.Guatemala_svg__cls-7{stroke-linejoin:round}.Guatemala_svg__cls-6{stroke-width:.85px}.Guatemala_svg__cls-10,.Guatemala_svg__cls-9{fill:#8c959d}.Guatemala_svg__cls-13,.Guatemala_svg__cls-14,.Guatemala_svg__cls-15,.Guatemala_svg__cls-9{stroke:#485654}.Guatemala_svg__cls-17,.Guatemala_svg__cls-9{stroke-width:.5px}.Guatemala_svg__cls-11{fill:#485654}.Guatemala_svg__cls-12,.Guatemala_svg__cls-19{fill:#6c301e}.Guatemala_svg__cls-12{stroke:#351710}.Guatemala_svg__cls-13{fill:#b2b6ba}.Guatemala_svg__cls-15{stroke-width:.53px}.Guatemala_svg__cls-16,.Guatemala_svg__cls-17,.Guatemala_svg__cls-18{fill:#fab81c}.Guatemala_svg__cls-16,.Guatemala_svg__cls-17{stroke:#6c301e}.Guatemala_svg__cls-21{fill:#f9f0aa;stroke:#999270}.Guatemala_svg__cls-27{fill:#999270}.Guatemala_svg__cls-29{fill:#b07e09}.Guatemala_svg__cls-35,.Guatemala_svg__cls-42{fill:#448127}.Guatemala_svg__cls-35{stroke:#34541f}.Guatemala_svg__cls-38{fill:#34541f}"
        }
      </style>
    </defs>
    <g id="Guatemala_svg__Guatemala">
      <path
        id="Guatemala_svg__rect6"
        d="M0 .42h1280v719.16H0z"
        style={{
          fill: "#4997d0",
        }}
      />
      <path
        id="Guatemala_svg__rect8"
        d="M426.66.42h426.67v719.16H426.66z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        id="Guatemala_svg__path9167"
        d="M611.23 452.87a34 34 0 0 0 7.24-2.14"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9169"
        d="M621.13 445.87a15.6 15.6 0 0 1 3.59 5.55"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9171"
        d="M615 445.34a18.7 18.7 0 0 1 3.76 5"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9129"
        d="M559.32 286.61c-1.25 1.92-3.7 3.27-5.72 4.11"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9131"
        d="M553.19 290.48c0-1.64-.28-3.62.06-5.26"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9133"
        d="M550.88 291.24c.13.44.52 1.68.81 2.43"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9135"
        d="M554.12 294.77c-.46.09-1.83.3-2.43.4"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9137"
        d="M546.94 294.13a86 86 0 0 0 .64 9.37"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9139"
        d="M545.44 302.35a7 7 0 0 0 1.85 2.25"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9141"
        d="M541.79 312.36a16.8 16.8 0 0 1 1.56 4.74"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9143"
        d="M547.81 311.55a17.3 17.3 0 0 0-2.32 1.85"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9145"
        d="M547.4 318.67a18.7 18.7 0 0 1-4.11 2.54"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9147"
        d="M538.72 369.65a19.1 19.1 0 0 0 4.75 5.61"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9149"
        d="M544.16 377.29a9.5 9.5 0 0 1-3.53-1.16"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9151"
        d="m545.9 386.2 2 1.16"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9153"
        d="M547.4 391.53a21.5 21.5 0 0 1 4.52 4.63"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9155"
        d="M550.18 399.57a14.4 14.4 0 0 1 3.88 1.27"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9157"
        d="m552.79 403.74 2.54.08"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9159"
        d="M556.78 397.37a48 48 0 0 1-.58 5.09"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9161"
        d="m557.41 404.37.53-2"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9163"
        d="m563.55 409.58-.18 3.88"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9173"
        d="m610.37 446.91-1.37 1.91"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9175"
        d="m606.08 444.13-.81-2.2"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path50-3"
        d="M556.37 397.9c-3.35-7.55-1.64-25.9-.24-28.21 7.95 15.59 5.3 28.46.24 28.21Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path140-2"
        d="M556.45 397.71c5.07.26 7.68-12.46-.32-28 1.45 14.75 1.45 26.72.32 28Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path12"
        d="M652 461.8a10 10 0 0 0-3.93-3.19c-9.26-4.86-18.46-7-28.9-8.68-2.41-.4-4.83-2.66-7.3-3.82-6-2.83-12.57-3.94-18.35-6.83a9.3 9.3 0 0 1-2.53-1.85c-2.3-2.66-6.45-3.47-9-6-10-9.44-20.6-18.12-26.1-30.62-6.68-15.23-15-30.91-13.86-48.22.73-10.94-.9-22 1.79-32.41 4-15.46 9.1-30.74 18.47-43.53l-1.57 1c-9.43 12.79-14.54 28.07-18.52 43.52-2.7 10.42-1.07 21.48-1.8 32.42-1.12 17.3 7.19 33 13.86 48.27 5.5 12.44 16.17 21.13 26.1 30.62 2.58 2.43 6.74 3.24 9 6a12 12 0 0 0 2.52 1.85c5.78 2.9 12.41 4 18.36 6.78 2.47 1.21 4.88 3.41 7.29 3.82 10.44 1.67 19.65 3.87 28.91 8.68a9.66 9.66 0 0 1 3.93 3.18l1.62-1"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path18-4"
        d="M611.34 452.31c-8.5.43-24.71 11.14-26.21 13.66 18.08.14 28.79-8.77 26.21-13.66Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path22-6"
        d="M620.28 447.17c-8.21-6.54-17-28.36-16.82-31.77 18.11 12.99 22.7 29.1 16.82 31.77Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path24-9"
        d="M614.23 447.24c-8.33-5.08-18.92-24.11-19.11-27.28 18.22 9.82 24.21 24.13 19.11 27.28Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path26-3"
        d="M595.57 439.11c1.18-9.37-6.79-29.05-9.09-31.14-3.54 19.74 3.59 33.03 9.09 31.14Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path32-3"
        d="M562.84 410c-2.76-6.77-.74-23.16.56-25.17 6.6 14.02 3.92 25.43-.56 25.17Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path34-6"
        d="M569.85 421.68c-5.37-6.56-23.23-13.58-26-13.36 10.62 14.49 23.8 18.1 26 13.36Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path40-2"
        d="M540.58 358.18c-1.37-9.64-14.78-27.23-17.67-28.73 1.82 20.55 12.64 32 17.67 28.73Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path44-0"
        d="M550.64 399.28c-6-4.41-22.74-6.41-25.1-5.66 12.15 9.95 24.22 10.14 25.1 5.66Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path48-0"
        d="M539.42 369.52c-3.46-6.82-17.76-16.16-20.25-16.43 6.61 14.85 17.45 20.31 20.25 16.43Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path54-7"
        d="M542.7 312.54c1.08-9.08-6.64-28.1-8.81-30.16-3.43 19.09 3.5 31.96 8.81 30.16Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path56-3"
        d="M546.41 317.91c4.3-8 22-18.83 25.16-19.09-8.12 17.46-21.57 23.77-25.16 19.09Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path58-3"
        d="M547.26 311.25c2.58-8.73 17.83-23.09 20.81-24-4.47 18.75-16.39 27.75-20.81 24Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path60-1"
        d="M548.05 294.66c3.19-7.79.91-26.89-.63-29.27-7.67 16.19-4.58 29.54.63 29.27Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path62-4"
        d="M554.07 285.48c3.76-5.72 5-21.19 4.18-23.33-8.67 11.64-8.34 22.7-4.18 23.33Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path66-6"
        d="M561.2 278.13c5.28-4.17 11.06-18.58 10.89-20.9-11.68 8.34-14.65 18.99-10.89 20.9Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path70-2"
        d="M542 329.9c.12 1.05-1.18-9.8-12.06-21.95.45 17.76 7.46 24.48 12.06 21.95Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path32-3-3"
        d="M580.59 430.09c-6-7.4-9.6-28.6-8.74-31.59 13.51 15 14.42 30.24 8.74 31.59Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path32-3-0"
        d="M581 432.72c-7.4-5.46-28-8.06-30.88-7.1 15.03 12.38 29.8 12.64 30.88 7.1Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path52-8"
        d="M539.69 328.75c4.88-5.6 20.93-10.95 23.49-10.63-9.64 12.48-21.51 15-23.49 10.63Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path76-1"
        d="M595.46 439c-5.45 1.91-12.52-11.29-9-31 3.33 16.7 7.37 30 9 31Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path80-0"
        d="M611.4 452.36c2.53 4.89-8 13.71-26.12 13.56 14.33-5.86 25.46-11.82 26.12-13.56Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path88-4"
        d="M620.25 447c5.83-2.7 1.32-18.62-16.79-31.61 10.3 15.85 17.32 29.51 16.79 31.61Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path92-1"
        d="M614.17 447.1c5.13-3.2-.83-17.32-19-27.14 11.27 13.41 19.29 25.13 19 27.14Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path104-5"
        d="M562.88 410c4.48.26 7.14-11.1.57-25.08.87 13.15.51 23.94-.57 25.08Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path108-3"
        d="M569.73 421.74c-2.16 4.69-15.21 1.09-25.94-13.37 13.04 8.19 24.26 13.8 25.94 13.37Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path120-6"
        d="M540.5 358.15c-5 3.27-15.74-8.07-17.6-28.62 7.94 15.82 15.62 28.02 17.6 28.62Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path128-2"
        d="M550.59 399.32c-.92 4.47-12.81 4.3-25-5.66 12.85 4.09 23.65 6.39 25 5.66Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path136-9"
        d="M539.27 369.41c-2.8 3.88-13.53-1.52-20.15-16.37 9.82 9.55 18.57 16.48 20.15 16.37Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path148-0"
        d="M542.58 312.41c-5.31 1.87-12.18-10.88-8.75-30 3.17 16.2 7.17 29.01 8.75 30Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path152-2"
        d="M546.39 318c3.54 4.68 16.85-1.57 25-19.08-12.19 11.19-23 19.25-25 19.08Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path156-4"
        d="M547.4 311.26c4.42 3.77 16.24-5.16 20.7-23.95-9.7 13.48-18.75 23.69-20.7 23.95Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path160-0"
        d="M547.93 294.6c-5.16.27-8.23-13-.56-29.15-.98 15.34-.61 27.85.56 29.15Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path164-2"
        d="M554 285.41c-4.17-.63-4.46-11.58 4.22-23.22-3.32 11.96-5.01 22.02-4.22 23.22Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path172-0"
        d="M561.14 278.13c-3.76-2-.84-12.51 10.83-20.78-6.62 10.36-11.17 19.39-10.83 20.78Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path180-7"
        d="M541.88 329.92c-4.56 2.52-11.48-4.2-12-22 6.12 14.08 10.34 21.38 12 22Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path104-5-9"
        d="M580.63 430c5.68-1.35 4.73-16.52-8.7-31.49 6.01 16.1 9.61 29.7 8.7 31.49Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path104-5-93"
        d="M580.91 432.76c-1.06 5.54-15.75 5.27-30.77-7.07 15.78 5.13 29.06 8.03 30.77 7.07Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path144-3"
        d="M539.62 328.84c1.94 4.34 13.73 1.83 23.37-10.64-14.35 8.14-19.99 11.67-23.37 10.64Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path74-9"
        d="M541.85 350.14c2.47-10.05-2-27.09-5.15-30.64-5.7 18.5-.3 31.89 5.15 30.64Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path188-9"
        d="M541.5 352.53c-7.07 1.6-10.36-14.43-4.89-32.46 1.28 16.24 3.39 31.34 4.89 32.46Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path42-2"
        d="M546.25 386.39c-8.28 1.31-25.6-5.55-27.47-7.59 17.42-3.8 29.11 2.48 27.47 7.59Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path124-5"
        d="M546 386.32c1.68-5.08-9.95-11.23-27.33-7.5 14.72 2.63 26.45 6.04 27.33 7.5Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path38-5"
        d="M543.59 374.79c1-6.69 10.39-19 12.42-20-1.33 14.33-8.93 22.29-12.42 20Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path116-3"
        d="M543.7 374.8c3.48 2.26 11-5.58 12.33-19.92-5.6 11.01-11.03 19.5-12.33 19.92Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path46-1"
        d="M540.06 352.48c-.67-9 8.31-27.68 10.68-29.62 2.42 18.94-5.34 31.54-10.68 29.62Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path132-9"
        d="M540.19 352.34c5.29 2 13-10.5 10.59-29.44-4.21 15.9-8.93 28.48-10.59 29.44Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path72-1"
        d="M541.74 363.91c0-9.12 10.15-26.65 12.66-28.31 1 19.4-7.59 30.96-12.66 28.31Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path184-3"
        d="M541.82 364.08c5.07 2.65 13.55-8.82 12.55-28.2-5.28 15.41-10.84 27.45-12.55 28.2Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path32-3-2"
        d="M571.12 421.4c-4.82-5.21-8.58-20.79-8.09-23.06 10.8 10.47 12.25 21.77 8.09 23.06Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path104-5-4"
        d="M571.14 421.34c4.16-1.28 2.7-12.52-8-23 5.23 11.66 8.59 21.6 8 23Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path9034"
        d="M546.28 301.59a1.3 1.3 0 1 1-2.6 0 1.3 1.3 0 1 1 2.6 0"
        className="Guatemala_svg__cls-6"
      />
      <path
        id="Guatemala_svg__path9034-1"
        d="M556.3 294.16a1.3 1.3 0 1 1-1.3-1.3 1.3 1.3 0 0 1 1.3 1.3"
        className="Guatemala_svg__cls-6"
      />
      <path
        id="Guatemala_svg__path9034-4"
        d="M552 290.13a1.31 1.31 0 1 1-1.3-1.3 1.3 1.3 0 0 1 1.3 1.3"
        className="Guatemala_svg__cls-6"
      />
      <path
        id="Guatemala_svg__path9034-9"
        d="M541.31 375.73a1.53 1.53 0 1 1-1.53-1.53 1.54 1.54 0 0 1 1.53 1.53"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-7"
        d="M544.35 356.56a1.53 1.53 0 1 1-1.53-1.53 1.53 1.53 0 0 1 1.53 1.53"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-76"
        d="M560.14 401.3a1.53 1.53 0 1 1-1.53-1.53 1.54 1.54 0 0 1 1.53 1.53"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-44"
        d="M553.17 403.66a1.53 1.53 0 1 1-1.54-1.53 1.53 1.53 0 0 1 1.54 1.53"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-6"
        d="M606.28 441.06a1.53 1.53 0 0 1-3.06 0h0a1.53 1.53 0 1 1 3.06 0"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-78"
        d="M609.77 449.55a1.53 1.53 0 1 1-1.53-1.53 1.54 1.54 0 0 1 1.53 1.53"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path30-1"
        d="M550.89 391.43c-4.28-6.93-4.65-25.06-3.5-27.51 9.9 14.15 8.69 27.08 3.5 27.51Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path36-4"
        d="M548 391.52c-3.19-7.5-17.74-18.53-20.35-19 5.92 16.36 17.07 22.96 20.35 19Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path100-0"
        d="M550.81 391.25c5.2-.45 6.39-13.23-3.51-27.39 3.19 14.21 4.54 25.99 3.51 27.39Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path112-2"
        d="M547.73 391.54c-3.27 4-14.3-2.58-20.22-19 9.75 10.95 18.55 18.98 20.22 19Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path20-6"
        d="M605.11 445c-10.16.53-29.52 13.32-31.32 16.33 21.61.12 34.41-10.53 31.32-16.33Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path84-7"
        d="M605.07 445.13c3.09 5.85-9.54 16.39-31.21 16.21 17.14-7 30.42-14.12 31.21-16.21Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path10706-3"
        d="M741.92 310.82c.75.75 1.41 1 2.75 2.27"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9131-9"
        d="M736.73 290.39a41.6 41.6 0 0 0-1.1-6"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10698"
        d="M739.76 288a13.6 13.6 0 0 1-1.27 6.59"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10700"
        d="M733.5 294a28.4 28.4 0 0 0 6.31 4.67"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10702"
        d="M744.39 300.09a22.2 22.2 0 0 1-.82 7.12"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10704"
        d="m744 309 1.58-2.71"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10706"
        d="M743.3 321c1.68.35 3.37 2.33 4.2 4.17"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10708"
        d="m748.07 323.58 2-2.62"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10710"
        d="M752.08 357.79a28 28 0 0 1-2.94 4.17"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10712"
        d="M744.39 361.88a22.3 22.3 0 0 0 3.11 4.83"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10714"
        d="M750.45 373.75a16 16 0 0 1-5.32 4.83"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10716"
        d="M745.29 384c-1.31.9-2.91 3.54-4.42 5.65"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10761"
        d="m751 365.43-2.55 1"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10763"
        d="m746.76 357.5 1.51 1.33"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9157-0"
        d="M737.13 403.64a10 10 0 0 0-2.54.09"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10718"
        d="m732.2 401.41.73 2"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10722"
        d="M723.68 409.51a23.6 23.6 0 0 0 .25 6.71"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10765"
        d="m729.09 405.42 1.22 2.7"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10767"
        d="m734.9 407.88-3 .49"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9165-6"
        d="m694.89 438.65-1.1 2.08"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10724"
        d="M708.05 436a18.8 18.8 0 0 1-4.91-.25"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10726"
        d="M696.59 442.9a34 34 0 0 0-3.51-1.14"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10730"
        d="M675.4 445a26 26 0 0 0-3.68 4.91"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10732"
        d="M671.06 444.38c-2.92 1.5-3.81 4.44-5.32 6.95"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10734"
        d="M669.1 454.77a64 64 0 0 1-6.39-2.45"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10757"
        d="M682.8 451.5c-2.78-1.45-5.69-1.31-8.55-1.76"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10759"
        d="M687.14 449.33a36.2 36.2 0 0 0-12.31-.17"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10769"
        d="m684.15 446.05-1.67-.69"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path10771"
        d="m675.85 452-2.78-1.56"
        className="Guatemala_svg__cls-3"
      />
      <path
        id="Guatemala_svg__path9153-6"
        d="M742.51 391.43a22 22 0 0 0-4.51 4.63"
        style={{
          stroke: "#24420e",
          strokeWidth: 2,
          fill: "#2ca02c",
        }}
      />
      <path
        id="Guatemala_svg__path50-3-2"
        d="M732.94 401.47c3.28-8.89 0-29.77-1.79-32.3-7.97 18.42-4 32.96 1.79 32.3Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path140-2-6"
        d="M732.83 401.27c-5.78.66-9.7-13.71-1.67-32.11-.59 17.01.28 30.72 1.67 32.11Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path12-81"
        d="M637.93 461.7a10 10 0 0 1 3.93-3.18c9.26-4.87 18.47-7 28.91-8.69 2.41-.4 4.83-2.66 7.29-3.82 6-2.83 12.58-3.93 18.36-6.83a9.2 9.2 0 0 0 2.52-1.85c2.3-2.66 6.46-3.47 9-6 10-9.43 20.6-18.12 26.1-30.62 6.67-15.22 15-30.91 13.86-48.22-.73-10.93.9-22-1.8-32.41-4-15.45-9.09-30.73-18.46-43.53l1.57 1c9.43 12.79 14.54 28.07 18.52 43.52 2.69 10.42 1.07 21.48 1.8 32.42 1.12 17.31-7.19 33-13.87 48.27-5.5 12.45-16.16 21.13-26.1 30.62-2.58 2.43-6.73 3.24-9 6a12 12 0 0 1-2.53 1.86c-5.78 2.89-12.4 4-18.35 6.77-2.47 1.21-4.88 3.41-7.3 3.82-10.44 1.68-19.64 3.88-28.9 8.68a9.66 9.66 0 0 0-3.93 3.18l-1.63-1"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path18-4-9"
        d="M668.86 453.6c8.5.43 24.7 11.14 26.2 13.66-18.06.14-28.79-8.77-26.2-13.66Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path22-6-7"
        d="M668.86 444.08c2.52-9.55 18-25.42 21-26.55-4.32 20.53-16.47 30.6-21 26.55Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path26-3-6"
        d="M694.35 439c-1.18-9.37 6.79-29.05 9.09-31.13 3.56 19.74-3.59 33.06-9.09 31.13Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path32-3-8"
        d="M724.42 409.9c2.76-6.76.73-23.16-.57-25.16-6.61 14.01-3.91 25.43.57 25.16Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path34-6-3"
        d="M720.07 421.58c5.36-6.56 23.23-13.58 26-13.35-10.63 14.48-23.8 18.09-26 13.35Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path40-2-6"
        d="M751 357.74c1.08-7.58 11.63-21.43 13.91-22.61-1.44 16.2-9.97 25.19-13.91 22.61Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path44-0-1"
        d="M737 403.36c7-5.14 26.49-7.46 29.24-6.59-14.18 11.59-28.24 11.81-29.24 6.59Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path48-0-1"
        d="M749.29 374c3.63-7.16 18.66-17 21.28-17.25-7 15.6-18.34 21.33-21.28 17.25Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path54-7-8"
        d="M747.51 319.37c-.11-9.14 9.57-27.25 12-29.06 1.35 19.35-6.91 31.41-12 29.06Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path56-3-6"
        d="M744.56 321c-3.95-7.31-20.22-17.28-23.09-17.52 7.45 16.05 19.84 21.84 23.09 17.52Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path58-3-2"
        d="M742.65 311.42c-2.14-7.28-14.85-19.24-17.34-20 3.69 15.65 13.69 23.14 17.34 20Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path60-1-6"
        d="M739.2 288.3c-2.78-6.33-1.32-22.07-.11-24.06 6.65 13.14 4.4 24.18.11 24.06Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path62-4-5"
        d="M734.13 285.69c-6.32-5.62-12.76-23.68-12.48-26.5 14.08 11.11 17.24 24.41 12.48 26.5Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path70-2-3"
        d="M748.08 327.09c-.38 1 3.53-9.23 17-18.35-4.77 17.1-13.2 21.91-17 18.35Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path32-3-3-5"
        d="M706.78 432.63c5.2-8 6.65-29.43 5.49-32.32-11.92 16.29-11.27 31.55-5.49 32.32Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path32-3-0-3"
        d="M707.21 434.85c8.39-6.2 31.8-9.15 35-8.06-17.05 14.08-33.8 14.34-35 8.06Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path52-8-11"
        d="M748.34 329.36c-4.24-4.86-18.18-9.51-20.39-9.23 8.36 10.87 18.66 13.02 20.39 9.23Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path76-1-4"
        d="M694.46 438.9c5.45 1.91 12.52-11.29 9-31-3.33 16.7-7.37 30.02-9 31Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path80-0-2"
        d="M668.8 453.65c-2.54 4.89 8 13.71 26.11 13.56-14.32-5.86-25.46-11.82-26.11-13.56Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path88-4-1"
        d="M669 444c4.53 4 16.55-5.93 20.86-26.47C680.11 432.42 671 443.65 669 444Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path104-5-46"
        d="M724.37 409.86c-4.47.27-7.14-11.1-.57-25.08-.86 13.15-.5 24 .57 25.08Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path108-3-7"
        d="M720.18 421.64c2.17 4.7 15.22 1.1 26-13.37-13.1 8.19-24.31 13.8-26 13.37Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path120-6-5"
        d="M751.05 357.73c4 2.57 12.39-6.36 13.86-22.54-6.25 12.46-12.3 22.06-13.86 22.54Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path128-2-0"
        d="M737 403.4c1.07 5.22 14.93 5 29.14-6.58-14.96 4.75-27.54 7.45-29.14 6.58Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path136-9-4"
        d="M749.45 373.86c2.93 4.08 14.22-1.6 21.17-17.2-10.32 10.04-19.52 17.34-21.17 17.2Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path148-0-5"
        d="M747.65 319.26c5.08 2.41 13.26-9.54 11.86-28.88-4.89 15.74-10.14 28.06-11.86 28.88Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path152-2-68"
        d="M744.57 321.08c-3.24 4.29-15.46-1.44-22.92-17.51 11.18 10.27 21.15 17.67 22.92 17.51Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path156-4-57"
        d="M742.54 311.43c-3.68 3.14-13.53-4.3-17.25-20 8.08 11.28 15.62 19.76 17.25 20Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path160-0-2"
        d="M739.3 288.25c4.24.12 6.49-10.82-.16-24 1.14 12.62 1.09 22.91.16 24Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path164-2-2"
        d="M734.23 285.56c4.76-2.09 1.59-15.23-12.5-26.33 7.76 13.2 13.01 24.65 12.5 26.33Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path180-7-4"
        d="M748.15 327.12c3.81 3.56 12.16-1.27 17-18.37-9.38 12.08-15.24 18.18-17 18.37Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path104-5-9-97"
        d="M706.74 432.56c-5.79-.77-6.39-16 5.45-32.21-4.35 16.65-6.55 30.49-5.45 32.21Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path104-5-93-3"
        d="M707.28 434.89c1.21 6.29 17.88 6 34.92-8-17.9 5.8-32.97 9.11-34.92 8Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path144-3-2"
        d="M748.39 329.44c-1.68 3.77-11.91 1.59-20.28-9.24 12.45 7.07 17.39 10.13 20.28 9.24Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path74-9-9"
        d="M748.88 350.66c-1.13-9.3 4.76-24.14 8-27 3.03 17.25-3.24 28.71-8 27Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path188-9-63"
        d="M748.94 352.85c6.18 2.21 10.88-11.85 7.91-28.66-2.91 14.46-6.45 27.81-7.91 28.66Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path42-2-51"
        d="M745 384.89c7.35 1.15 22.72-4.94 24.38-6.75-15.48-3.35-25.86 2.21-24.38 6.75Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path124-5-9"
        d="M745.17 384.82c-1.49-4.51 8.83-10 24.26-6.66-13.04 2.34-23.43 5.37-24.26 6.66Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path38-5-1"
        d="M744.8 378.12c0-6.76-7.67-20.21-9.54-21.5-.66 14.38 5.74 23.32 9.54 21.5Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path116-3-0"
        d="M744.69 378.12c-3.76 1.76-10.12-7.06-9.46-21.44 4.03 11.68 8.19 20.83 9.46 21.44Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path46-1-34"
        d="M749.86 352.38c.67-9-8.31-27.67-10.69-29.61-2.41 18.94 5.35 31.53 10.69 29.61Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path132-9-1"
        d="M749.73 352.24c-5.29 2-13-10.5-10.59-29.44 4.21 15.9 8.92 28.49 10.59 29.44Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path72-1-9"
        d="M745.29 361.73c0-9.12-10.16-26.65-12.66-28.31-1 19.37 7.59 30.96 12.66 28.31Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path184-3-48"
        d="M745.2 361.9c-5.07 2.65-13.55-8.83-12.55-28.2 5.29 15.41 10.85 27.45 12.55 28.2Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path32-3-2-3"
        d="M715.54 425.62c3.92-5.92 5.13-21.91 4.27-24.07-8.97 12.08-8.58 23.45-4.27 24.07Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path104-5-4-1"
        d="M715.51 425.57c-4.31-.6-4.68-11.93 4.24-24-3.33 12.37-5.05 22.71-4.24 24Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path9034-97"
        d="M744.78 305.94a1.31 1.31 0 1 0 1.31-1.31 1.31 1.31 0 0 0-1.31 1.3Z"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-4-8"
        d="M749.33 320.23a1.31 1.31 0 0 0 2.61 0 1.3 1.3 0 0 0-1.3-1.3 1.31 1.31 0 0 0-1.31 1.3"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-9-9"
        d="M750.7 365.15a1.54 1.54 0 0 0 3.07 0h0a1.54 1.54 0 0 0-3.07 0"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-7-3"
        d="M744.59 356.75a1.53 1.53 0 0 0 3.06 0h0a1.53 1.53 0 1 0-3.06 0"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-76-8"
        d="M726.91 404.56a1.54 1.54 0 1 0 1.54-1.53 1.54 1.54 0 0 0-1.54 1.53"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-44-5"
        d="M734.46 407.82a1.53 1.53 0 1 0 1.53-1.53 1.52 1.52 0 0 0-1.53 1.53"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-6-1"
        d="M683.64 446.61a1.53 1.53 0 1 0 1.53-1.53 1.54 1.54 0 0 0-1.53 1.53"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path9034-78-8"
        d="M675.24 452.64a1.53 1.53 0 1 0 1.53-1.53 1.52 1.52 0 0 0-1.53 1.53"
        className="Guatemala_svg__cls-7"
      />
      <path
        id="Guatemala_svg__path60-1-6-2"
        d="M743.5 300.62c-2.6-7.05 0-24 1.48-26.1 6.34 14.69 3.16 26.48-1.48 26.1Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path160-0-2-2"
        d="M743.61 300.57c4.59.4 7.75-11.31 1.42-26 .39 13.72-.34 24.87-1.42 26Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path24-9-2"
        d="M673.89 444.63c3-9.7 19.41-25.27 22.55-26.27-5.2 20.88-18.06 30.64-22.55 26.27Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path92-1-8"
        d="M674 444.54c4.54 4.34 17.22-5.3 22.42-26.18-10.6 14.81-20.34 25.9-22.42 26.18Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path30-1-9"
        d="M739 391.34c4.28-6.94 4.65-25.06 3.5-27.52-9.87 14.18-8.66 27.07-3.5 27.52Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path36-4-9"
        d="M739.26 394.78c3.18-7.51 17.73-18.53 20.35-19-5.93 16.36-17.08 22.96-20.35 19Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path100-0-3"
        d="M739.1 391.15c-5.19-.44-6.39-13.23 3.52-27.38-3.19 14.23-4.54 25.98-3.52 27.38Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path112-2-5"
        d="M739.52 394.8c3.28 4 14.3-2.58 20.22-19-9.74 10.95-18.54 18.98-20.22 19Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path20-6-0"
        d="M681.9 451.94c5.37 6.6 23.56 13.35 26.47 13.07-10.55-14.66-24.05-18.01-26.47-13.07Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path84-7-2"
        d="M682 451.88c2.42-5 15.76-1.65 26.34 13.06-13.13-8.1-24.51-13.55-26.34-13.06Z"
        className="Guatemala_svg__cls-4"
      />
      <path d="m740.19 300.63-2.58-.87" className="Guatemala_svg__cls-3" />
      <path
        id="Guatemala_svg__path9034-97-2"
        d="M735.71 299.17a1.3 1.3 0 1 0 1.31-1.3 1.31 1.31 0 0 0-1.31 1.3"
        className="Guatemala_svg__cls-7"
        data-name="path9034-97"
      />
      <path
        id="Guatemala_svg___39021056-8-3"
        d="M589.82 403.48a2.25 2.25 0 0 0 2.55 2.48c.47-.09.75-.7.16-.59a1.57 1.57 0 0 1-1.44-.45 2 2 0 0 1-.48-2.3z"
        className="Guatemala_svg__cls-9"
        data-name="39021056-8-3"
      />
      <g id="Guatemala_svg___39019424-9-6" data-name="39019424-9-6">
        <path
          d="M593.4 406c-1.21 1.4-3.07 2-4.1 1a1.92 1.92 0 0 1-.56-2.29c-.54.57-1 1.07-1.35 1.47a5.3 5.3 0 0 0 2.05 2.11c1.47.66 3.56-.34 4.82-1.88.88-1.08 1.87-2.91 1.15-4.32a4.77 4.77 0 0 0-2.35-2l-1.23 1.32a1.91 1.91 0 0 1 2 .38c1.52 1.24.64 3-.46 4.25Z"
          className="Guatemala_svg__cls-10"
        />
        <path
          d="M593.4 406a4.5 4.5 0 0 1-1.39 1.14 2.93 2.93 0 0 1-1.76.39 1.8 1.8 0 0 1-.86-.36 3 3 0 0 1-.61-.69 2 2 0 0 1-.29-.89 1.84 1.84 0 0 1 .17-.92l.14.1-1.33 1.48v-.12a5.7 5.7 0 0 0 1.25 1.56 3.2 3.2 0 0 0 .83.53 2.6 2.6 0 0 0 .95.16 4.1 4.1 0 0 0 1.89-.58 5.8 5.8 0 0 0 1.61-1.3 7 7 0 0 0 1.12-1.66 3.8 3.8 0 0 0 .45-1.91 2 2 0 0 0-.27-.93 3.4 3.4 0 0 0-.59-.78 4.9 4.9 0 0 0-1.71-1.12h.11l-1.24 1.31-.1-.14a1.75 1.75 0 0 1 .9-.14 2.3 2.3 0 0 1 .86.29 2.35 2.35 0 0 1 1.11 1.38 2.6 2.6 0 0 1-.25 1.73 6 6 0 0 1-1 1.45Zm0 0a6.4 6.4 0 0 0 1-1.47 2.52 2.52 0 0 0 .2-1.68 2.3 2.3 0 0 0-1.08-1.28 1.79 1.79 0 0 0-1.62-.12l-.1-.14 1.22-1.33v-.05h.06a5.1 5.1 0 0 1 1.74 1.15 4.4 4.4 0 0 1 .64.83 2.5 2.5 0 0 1 .3 1 4.2 4.2 0 0 1-.46 2 7.5 7.5 0 0 1-1.15 1.73 6.2 6.2 0 0 1-1.63 1.29 4.4 4.4 0 0 1-2 .6 2.7 2.7 0 0 1-1-.19 3.1 3.1 0 0 1-.89-.57 6 6 0 0 1-1.29-1.63v-.12l1.36-1.46.15.09a1.76 1.76 0 0 0-.17.84 1.7 1.7 0 0 0 .26.82 2.6 2.6 0 0 0 .57.67 1.7 1.7 0 0 0 .78.35A2.8 2.8 0 0 0 592 407a4.9 4.9 0 0 0 1.4-1"
          className="Guatemala_svg__cls-11"
        />
      </g>
      <path
        id="Guatemala_svg__path10388-7-3"
        d="m686.9 299.17-48.11 47.36c-3.66 3.64-45.51 43.61-49.27 47.21-1.32 1.58-5.07 5.82-5.47 6.41-2.55 2.77-5.32 6.39-8.62 9.63-.59.74-1.37.47-2.08.88a28 28 0 0 0-6.06 5.24l-14.63 16.19c-.94 1-1.2 1.65-.56 2.24l7.9 10.61c1.47 1.45 3.17 2.2 3.83 1 3.77-6.53 13.14-16.49 16.68-24.37 2.11-4.71 4.77-13.26 6.66-15.32 2.32-2.59 8.6-9.27 14.15-15.23l1.12-1.2 1.13-1.22c28.17-28.71 60.79-63.58 84.54-88.16-.94-.93-.35-.38-1.25-1.25Z"
        className="Guatemala_svg__cls-12"
      />
      <path
        id="Guatemala_svg__path13539-8"
        d="M587 393.69c-1.47.05-2.42-.85-3.41-1.68 1.4.42 2.81 1 4.13.41Z"
        className="Guatemala_svg__cls-9"
      />
      <path
        id="Guatemala_svg___39022408-7-3"
        d="m737 264.23-38.12 31.43c-.37.45-.75.32-1.11 0l-3.47-2.9v-.13a1.7 1.7 0 0 0-.09-.55l1.7-1.74c.21-.26.14-.37-.15-.65s-.41-.44-.7-.72-.5-.05-.77.21l-1.7 1.82a1.68 1.68 0 0 0-1.62 1.63c-23.06 22.45-42.9 42.48-65.85 64.58L598.06 383c-1 1.25-5.14 3.49-7.9 5.29a6.8 6.8 0 0 0-2.18 1.85l-2.21 6.52c-.7 1.43-2.91 4.79-3 5 2.84 2.86 2.69 2.65 4.63 4.47 2.18-2.43 8-8.52 13.32-14.21.43-.46.46-.5 1-1.16a15 15 0 0 0-1.67-2c-.47-.45-1-.92-1.44-1.31-.29-.25-.56-.47-.75-.66s.29-.53.51-.76c31.72-30.67 65.88-64.37 93.82-91.81a1.8 1.8 0 0 0 .47.06 1.6 1.6 0 0 0 .76-.18l3.34 3.85a1.66 1.66 0 0 0 2.07.47z"
        className="Guatemala_svg__cls-13"
        data-name="39022408-7-3"
      />
      <path
        id="Guatemala_svg__path13541-0"
        d="M585.87 396.42a3 3 0 0 0 .73 0 1.8 1.8 0 0 0 .68-.19c.28-1 .57-1.9.86-2.85s.6-1.9.91-2.84a1.84 1.84 0 0 0-.88-.59 1.8 1.8 0 0 1 .92.55c-.27 1-.56 1.91-.85 2.86s-.59 1.89-.9 2.83a1.64 1.64 0 0 1-.72.16 3 3 0 0 1-.75.07"
        className="Guatemala_svg__cls-11"
      />
      <path
        id="Guatemala_svg__path13543-1"
        d="M595.27 393.72a.74.74 0 1 1-1.48 0 .74.74 0 0 1 1.48 0"
        className="Guatemala_svg__cls-14"
      />
      <path
        id="Guatemala_svg__path13543-4-9"
        d="M590.79 398.21a.75.75 0 0 1-.75.74.74.74 0 1 1 0-1.48.75.75 0 0 1 .75.74"
        className="Guatemala_svg__cls-14"
      />
      <path
        id="Guatemala_svg__path13563-1"
        d="M597.87 386.82a14 14 0 0 1-1.08-.93 14 14 0 0 1-1-1 14 14 0 0 1 1.08.92 14 14 0 0 1 1 1.01"
        className="Guatemala_svg__cls-11"
      />
      <path
        id="Guatemala_svg___39010280-3-5-8"
        d="m675.41 315.01.78-.7-4.18-4.09-.78.7z"
        className="Guatemala_svg__cls-9"
        data-name="39010280-3-5-8"
      />
      <path
        id="Guatemala_svg___39021056-8-3-3"
        d="M696.51 405.45a2.24 2.24 0 0 1-2.67 2.35c-.44-.13-.69-.74-.11-.6a1.53 1.53 0 0 0 1.46-.38 2.11 2.11 0 0 0 .6-2.28 6.6 6.6 0 0 0 .72.91Z"
        className="Guatemala_svg__cls-9"
        data-name="39021056-8-3-3"
      />
      <g id="Guatemala_svg___39019424-9-6-4" data-name="39019424-9-6-4">
        <path
          d="M692.8 407.75c1.15 1.45 3 2.17 4.06 1.21a1.89 1.89 0 0 0 .67-2.27l1.26 1.53a5.5 5.5 0 0 1-2.16 2c-1.5.59-3.54-.53-4.72-2.12-.82-1.13-1.74-3-.94-4.38a4.75 4.75 0 0 1 2.47-1.91l1.16 1.39a1.89 1.89 0 0 0-2.05.28c-1.59 1.18-.77 3 .25 4.28Z"
          className="Guatemala_svg__cls-10"
        />
        <path
          d="M692.8 407.75a5.1 5.1 0 0 0 1.37 1.17 2.9 2.9 0 0 0 1.71.41 1.73 1.73 0 0 0 .81-.32 2.8 2.8 0 0 0 .61-.63 1.7 1.7 0 0 0 .29-.81 1.8 1.8 0 0 0-.14-.85l.15-.09 1.27 1.52v.13a6.3 6.3 0 0 1-1.37 1.55 3.2 3.2 0 0 1-.92.52 2.6 2.6 0 0 1-1.06.13 4.45 4.45 0 0 1-2-.7 6.4 6.4 0 0 1-1.52-1.38 7.7 7.7 0 0 1-1.07-1.78 4.15 4.15 0 0 1-.36-2.06 2.5 2.5 0 0 1 .36-1 4.1 4.1 0 0 1 .69-.8 4.8 4.8 0 0 1 1.8-1.06h.06v.05l1.14 1.4-.11.13a1.7 1.7 0 0 0-1.61.05 2.3 2.3 0 0 0-1.14 1.23 2.47 2.47 0 0 0 .12 1.69 7 7 0 0 0 .92 1.5m0 0a6.3 6.3 0 0 1-.93-1.5 2.57 2.57 0 0 1-.17-1.75 2 2 0 0 1 .46-.77 3 3 0 0 1 .71-.55 1.88 1.88 0 0 1 1.77-.07l-.11.13-1.17-1.37h.11a4.8 4.8 0 0 0-1.7 1 3.6 3.6 0 0 0-.65.75 2.1 2.1 0 0 0-.32.91 3.8 3.8 0 0 0 .35 1.93 7.7 7.7 0 0 0 1 1.72 6.3 6.3 0 0 0 1.49 1.33 4.2 4.2 0 0 0 1.86.67 2.4 2.4 0 0 0 1-.11 3.2 3.2 0 0 0 .86-.48 6.2 6.2 0 0 0 1.34-1.5v.13c-.42-.51-.84-1-1.24-1.54l.15-.09a2 2 0 0 1 .13.93 1.85 1.85 0 0 1-.33.88 2.7 2.7 0 0 1-.65.66 1.8 1.8 0 0 1-.88.32 2.93 2.93 0 0 1-1.75-.47 5 5 0 0 1-1.33-1.16"
          className="Guatemala_svg__cls-11"
        />
      </g>
      <path
        id="Guatemala_svg__path10388-7-3-5"
        d="m604.48 296.58 45.74 49.65c3.49 3.8 43.31 45.79 46.9 49.56 1.25 1.64 4.78 6.08 5.17 6.69 2.41 2.88 5 6.64 8.14 10 .56.76 1.33.55 2.05 1a27.4 27.4 0 0 1 5.78 5.53l13.82 16.9c.89 1.06 1.1 1.7.44 2.26l-8.43 10.21c-1.54 1.39-3.28 2-3.89.79-3.44-6.71-12.3-17.1-15.47-25.16-1.9-4.81-4.13-13.49-5.92-15.64-2.21-2.69-8.12-9.66-13.38-15.9-.36-.43-.69-.83-1-1.24s-.73-.84-1.09-1.27c-26.74-30.05-57.61-66.49-80.12-92.21 1-.89.36-.36 1.31-1.19Z"
        className="Guatemala_svg__cls-12"
      />
      <path
        id="Guatemala_svg__path13539-8-9"
        d="M699.82 395.82c1.46.11 2.44-.72 3.5-1.51-1.42.35-2.88.81-4.14.22Z"
        className="Guatemala_svg__cls-9"
      />
      <path
        id="Guatemala_svg___39022408-7-3-9"
        d="m556.32 259.19 36.55 33.25c.33.47.72.35 1.12.06l3.59-2.73v-.14a1.4 1.4 0 0 1 .13-.55l-1.62-1.83c-.2-.29-.13-.39.17-.65a10 10 0 0 1 .73-.7c.29-.2.49 0 .76.25l1.62 1.86a1.67 1.67 0 0 1 1.52 1.72c21.92 23.55 40.79 44.51 62.62 67.71l25.76 27.13c.92 1.3 5 3.74 7.63 5.65a6.7 6.7 0 0 1 2.07 2l1.88 6.63c.63 1.46 2.66 4.92 2.75 5.17-3 2.72-2.81 2.52-4.86 4.23-2-2.51-7.6-8.9-12.62-14.84-.4-.48-.43-.51-1-1.2a17.5 17.5 0 0 1 1.77-1.91c.51-.43 1-.86 1.5-1.22.29-.23.58-.43.8-.63s-.26-.53-.49-.79c-30.17-32.17-62.66-67.5-89.22-96.28a2 2 0 0 1-.49 0 1.8 1.8 0 0 1-.76-.21l-3.54 3.67a1.71 1.71 0 0 1-2.11.39l-36.31-36Z"
        className="Guatemala_svg__cls-13"
        data-name="39022408-7-3-9"
      />
      <path
        id="Guatemala_svg__path13541-0-7"
        d="M700.8 398.59a3.6 3.6 0 0 1-.73 0 1.7 1.7 0 0 1-.71-.21c-.27-.95-.51-1.91-.76-2.87s-.48-1.93-.7-2.9a2.8 2.8 0 0 1 .44-.32 1.2 1.2 0 0 1 .51-.17 1.8 1.8 0 0 0-.91.54c.27.95.52 1.91.76 2.87s.49 1.93.71 2.9a1.8 1.8 0 0 0 .68.23 3 3 0 0 0 .71-.07"
        className="Guatemala_svg__cls-11"
      />
      <path
        id="Guatemala_svg__path13543-1-2"
        d="M691.59 395.44a.74.74 0 0 0 .7.78.74.74 0 1 0 .07-1.48.74.74 0 0 0-.77.7"
        className="Guatemala_svg__cls-15"
      />
      <path
        id="Guatemala_svg__path13543-4-9-8"
        d="M695.83 400.16a.74.74 0 1 0 1.48.07.74.74 0 0 0-.7-.78.75.75 0 0 0-.78.71"
        className="Guatemala_svg__cls-15"
      />
      <path
        id="Guatemala_svg__path13563-1-1"
        d="M689.27 388.42a14 14 0 0 1 1.05-1 12 12 0 0 1 1.14-.87c-.33.34-.69.66-1.05 1a14 14 0 0 1-1.14.87"
        className="Guatemala_svg__cls-11"
      />
      <path
        id="Guatemala_svg___39010280-3-5-8-1"
        d="m619.71 309.04-4.35 3.87-.77-.73 4.39-3.88z"
        className="Guatemala_svg__cls-9"
        data-name="39010280-3-5-8-1"
      />
      <path
        id="Guatemala_svg__path28-8"
        d="M589.41 438.34c-9.43-4-32.23-.8-35 1.12 19.49 9.6 35.38 5.48 35-1.12Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path96-8"
        d="M589.23 438.48c.39 6.6-15.33 10.65-34.85 1.08 18.34 1.12 33.32.44 34.85-1.08Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path28-8-1"
        d="M696.12 442.32c9.43-4 32.23-.8 35 1.12-19.5 9.56-35.39 5.48-35-1.12Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path96-8-3"
        d="M696.29 442.43c-.38 6.6 15.33 10.65 34.85 1.08-18.33 1.12-33.31.49-34.85-1.08Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path2253_352-9-6"
        d="M678.8 431.9c-24.85-36.1-62.32-65.45-91.21-81.49 4.26-.2 13.73 4.65 17.58 7.13 28.7 18.49 53.1 42.91 78.71 71.7a44 44 0 0 1-4.13 3.66Z"
        className="Guatemala_svg__cls-13"
        data-name="path2253 352-9-6"
      />
      <g id="Guatemala_svg__path2274_353-9-0" data-name="path2274 353-9-0">
        <path
          d="m681.28 431.61-1.74 1.67c-27.58-35.5-66-69.45-91.9-82.86C623.7 367 654 400.49 681.18 431.51Z"
          className="Guatemala_svg__cls-10"
        />
        <path
          d="m681.28 431.61-1.74 1.68c-6.39-8.18-13.16-16.05-20.22-23.65s-14.39-14.94-22-22-15.52-13.78-23.75-20.09c-4.11-3.15-8.32-6.19-12.63-9.07a147 147 0 0 0-13.35-8l.12-.23a161.8 161.8 0 0 1 26.82 15.91 254 254 0 0 1 24 19.94c7.62 7.09 14.89 14.54 22 22.17s13.9 15.51 20.75 23.34m0 0c-6.88-7.8-13.78-15.59-20.88-23.19s-14.37-15.05-22-22.13a251 251 0 0 0-24-19.89 161.3 161.3 0 0 0-26.8-15.86l.11-.23a146 146 0 0 1 13.36 8c4.32 2.89 8.52 5.93 12.64 9.1 8.22 6.32 16.11 13.08 23.73 20.13s15 14.4 22 22 13.77 15.53 20.12 23.73Z"
          className="Guatemala_svg__cls-11"
        />
      </g>
      <path
        id="Guatemala_svg__path2266_354-0-1"
        d="M689.61 426a22.2 22.2 0 0 0-6.3 2.67c-.28 1.68-2.34 3.55-4.3 4-.36-.45-.44-.59-.71-.92a.16.16 0 0 0-.2-.05c-.17.09-.4.22-.69.41a1.82 1.82 0 0 0-2 1.17c-.45 1.16.46 2.69 1.47 3.69s1.66 1.29 2.94 1.11a4.07 4.07 0 0 0 2.31-1.84A76.3 76.3 0 0 0 695.6 449c2.52 0 3.87-1.57 3-3.36a1.37 1.37 0 0 0-1.6-.78 1.2 1.2 0 0 0 0-.54c3-2.72 4.22-6.1 1.1-11.84-2.67-4.83-5.65-6.47-8.49-6.45Zm7.49 5.43c.52.89.83 1.66 1.22 2.46 1.78 3.6.24 7.92-2.79 9.24a.47.47 0 0 0-.26 0c.5-.83-1-2.58-1.75-2 .36-.91-.8-2.42-1.75-2 .5-.84-.64-2.28-1.7-1.81a1.41 1.41 0 0 0-1.49-2 1.48 1.48 0 0 0-1.62-1.92 1.86 1.86 0 0 0-.61-1.73 39 39 0 0 1 4.12-3.28c3.26-1.42 5.28 1.28 6.62 3Z"
        className="Guatemala_svg__cls-16"
        data-name="path2266 354-0-1"
      />
      <path
        id="Guatemala_svg__path518-4-7-5-4-0"
        d="M698.47 435.24c-.62-.31-.91-.91-.71-1.33s.85-.52 1.46-.21 1 .89.76 1.3a1.14 1.14 0 0 1-1.51.24Z"
        className="Guatemala_svg__cls-17"
      />
      <g id="Guatemala_svg__path13066-2">
        <path
          d="M682.14 436.23c.82-2.12 2.45-4.09 4.2-4.56"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M682.14 436.23a9 9 0 0 1 .65-1.46 8.5 8.5 0 0 1 .92-1.33 6.8 6.8 0 0 1 1.18-1.09 4 4 0 0 1 1.45-.68 5 5 0 0 0-1.39.76 7 7 0 0 0-1.14 1.1 11.2 11.2 0 0 0-1.67 2.7"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-8">
        <path
          d="M683.84 438.27c2 .46 3.95-1.46 4.72-2.88"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M683.84 438.27a3 3 0 0 0 1.44-.1 4.4 4.4 0 0 0 1.29-.66 6.6 6.6 0 0 0 1.1-1 8 8 0 0 0 .89-1.17 5.7 5.7 0 0 1-.82 1.24 6.5 6.5 0 0 1-1.09 1 4.1 4.1 0 0 1-1.34.64 2.63 2.63 0 0 1-1.47.05"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-6-2">
        <path
          d="M685.62 440.17a5 5 0 0 0 4.48-2.87"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M685.62 440.17A5.3 5.3 0 0 0 687 440a4.5 4.5 0 0 0 1.24-.61 5.6 5.6 0 0 0 1-.91 8.5 8.5 0 0 0 .84-1.13 5.44 5.44 0 0 1-1.8 2.15 4.7 4.7 0 0 1-1.28.6 4.2 4.2 0 0 1-1.38.07"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-0-3">
        <path
          d="M687.44 442a5.28 5.28 0 0 0 4.37-2.9"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M687.44 442a4.9 4.9 0 0 0 2.53-.92 6.2 6.2 0 0 0 1-.88 5 5 0 0 0 .8-1.1 4 4 0 0 1-.73 1.17 5.7 5.7 0 0 1-1 .92 5 5 0 0 1-1.24.62 3.6 3.6 0 0 1-1.36.19"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-1-2">
        <path
          d="M689.19 443.67a4.68 4.68 0 0 0 4.34-2.49"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M689.19 443.67a9 9 0 0 0 1.29-.22 5.8 5.8 0 0 0 1.22-.47 4.6 4.6 0 0 0 1-.75 4.5 4.5 0 0 0 .78-1.05 3.5 3.5 0 0 1-.71 1.12 4.4 4.4 0 0 1-1.06.8 5.5 5.5 0 0 1-2.52.57"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-16-2">
        <path
          d="M691.16 445.38a4.35 4.35 0 0 0 4.12-2.23"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M691.16 445.38a5.6 5.6 0 0 0 1.22-.15 4.4 4.4 0 0 0 1.14-.43 4.3 4.3 0 0 0 1-.7 3.6 3.6 0 0 0 .76-1 3.2 3.2 0 0 1-.69 1 4.6 4.6 0 0 1-1 .74 4.5 4.5 0 0 1-1.18.41 4 4 0 0 1-1.25.13"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-3-9">
        <path
          d="M682.42 436.53c1.51.31 4-1.49 4.56-3.11"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M682.42 436.53a2.85 2.85 0 0 0 1.41-.24 5.6 5.6 0 0 0 1.26-.71 6.5 6.5 0 0 0 1.08-1 4.5 4.5 0 0 0 .81-1.2 3.8 3.8 0 0 1-.74 1.27 5.9 5.9 0 0 1-2.37 1.7 2.6 2.6 0 0 1-1.45.18"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-16-9-8">
        <path
          d="M693.29 447.12a3.81 3.81 0 0 0 3.76-2.28"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M693.29 447.12a5 5 0 0 0 1.15-.15 3.4 3.4 0 0 0 1-.45 3.7 3.7 0 0 0 .89-.73 4.6 4.6 0 0 0 .67-1 3.65 3.65 0 0 1-1.49 1.8 3.6 3.6 0 0 1-1.1.43 3.8 3.8 0 0 1-1.12.1"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <path
        id="Guatemala_svg__path518-4-7-5-45"
        d="M698.1 434c-.62-.3-.91-.91-.7-1.32s.84-.52 1.46-.22 1 .89.75 1.31a1.13 1.13 0 0 1-1.51.23Z"
        className="Guatemala_svg__cls-17"
      />
      <path
        id="Guatemala_svg__path518-4-7-7"
        d="M697.36 432.65a1.1 1.1 0 0 1-.71-1.32c.2-.41.84-.52 1.46-.21s1 .89.76 1.3a1.15 1.15 0 0 1-1.52.23Z"
        className="Guatemala_svg__cls-17"
      />
      <path
        id="Guatemala_svg__path518-4-7-0-0"
        d="M696.42 431.43a1.09 1.09 0 0 1-.7-1.32c.2-.41.84-.52 1.46-.22s1 .9.76 1.31a1.15 1.15 0 0 1-1.52.23Z"
        className="Guatemala_svg__cls-17"
      />
      <path
        id="Guatemala_svg__path518-4-7-8-4"
        d="M695.19 430.24a1.1 1.1 0 0 1-.7-1.32c0-.06 0-.16.07-.14.25.13.77.23.71-.16-.08-.14-.17-.27-.08-.17a2.3 2.3 0 0 1 .76.27c.62.3 1 .89.76 1.3a1.15 1.15 0 0 1-1.52.23Z"
        className="Guatemala_svg__cls-17"
      />
      <path
        id="Guatemala_svg__path64-5"
        d="M559.85 288.11c8.64-2.84 22.45-18.53 23.34-21.48-18.69 4.98-27.27 17.19-23.34 21.48Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path168-7"
        d="M559.85 288.11c-4-4.29 4.54-16.39 23.18-21.36-13.14 10.07-23 19.39-23.18 21.36Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path64-5-31"
        d="M734.52 293.74c-2-8.45-15.91-22.79-18.66-23.87 3.31 18.13 14.28 27.28 18.66 23.87Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path168-7-7"
        d="M734.52 293.74c-4.38 3.46-15.24-5.58-18.56-23.7 8.59 13.27 16.7 23.39 18.56 23.7Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path9165"
        d="m595 438.75 1.1 2.08"
        style={{
          fill: "none",
          strokeWidth: 2,
          stroke: "#000",
        }}
      />
      <path
        id="Guatemala_svg__path2253_352-9-3"
        d="M608.82 428.84c27-34.56 66.11-61.63 95.91-75.92-4.25-.45-14 3.82-18 6.07-29.74 16.75-55.56 39.67-82.84 66.89a42 42 0 0 0 3.91 3.89Z"
        className="Guatemala_svg__cls-13"
        data-name="path2253 352-9-3"
      />
      <g id="Guatemala_svg__path2274_353-9-4" data-name="path2274 353-9-4">
        <path
          d="m606.37 428.4 1.63 1.77c29.65-33.79 70.06-65.4 96.67-77.24-37 14.43-69.25 46-98.2 75.37Z"
          className="Guatemala_svg__cls-10"
        />
        <path
          d="m606.37 428.4 1.64 1.77c6.83-7.81 14-15.3 21.5-22.5s15.25-14.08 23.27-20.66 16.29-12.86 24.88-18.69c4.3-2.91 8.68-5.7 13.16-8.32a144 144 0 0 1 13.81-7.19l.1.24A161.4 161.4 0 0 0 677 367.29a253 253 0 0 0-25.16 18.41c-8 6.62-15.74 13.61-23.27 20.79S613.7 421 606.37 428.4m0 0c7.3-7.41 14.62-14.81 22.13-22s15.23-14.19 23.25-20.82a255 255 0 0 1 25.15-18.48 162.6 162.6 0 0 1 27.72-14.29l.1.24a144 144 0 0 0-13.79 7.16c-4.48 2.61-8.86 5.39-13.15 8.29-8.6 5.81-16.88 12.07-24.9 18.64s-15.8 13.46-23.29 20.63-14.73 14.62-21.59 22.4Z"
          className="Guatemala_svg__cls-11"
        />
      </g>
      <path
        d="M612.12 430.38a1.84 1.84 0 0 0-1.92-1.29c-.28-.2-.49-.34-.66-.44a.14.14 0 0 0-.2 0c-.29.32-.38.45-.77.88-1.93-.54-3.88-2.53-4.05-4.22a22 22 0 0 0-6.14-3c-2.84-.19-5.92 1.27-8.86 5.93-3.46 5.54-2.4 9 .39 11.88a1.2 1.2 0 0 0 0 .54 1.37 1.37 0 0 0-1.64.69c-1 1.74.31 3.4 2.82 3.53a76.3 76.3 0 0 0 14.19-11.93 4.1 4.1 0 0 0 2.2 2c1.27.26 1.85-.11 3-.92s2.02-2.47 1.64-3.65Zm-11.53-.5a1.47 1.47 0 0 0-1.73 1.82 1.4 1.4 0 0 0-1.61 1.9c-1-.53-2.25.84-1.81 1.71-.92-.44-2.17 1-1.87 1.92-.72-.66-2.31 1-1.86 1.86a.38.38 0 0 0-.25 0c-3-1.5-4.23-5.91-2.25-9.39.45-.78.8-1.53 1.38-2.38 1.45-1.65 3.62-4.22 6.79-2.62a41 41 0 0 1 3.92 3.52 1.88 1.88 0 0 0-.71 1.66Z"
        className="Guatemala_svg__cls-16"
      />
      <g id="Guatemala_svg__path13066-3">
        <path
          d="M605.23 433c-.69-2.17-2.2-4.23-3.92-4.8"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M605.23 433a11 11 0 0 0-1.5-2.8 7.5 7.5 0 0 0-1.08-1.17 4.8 4.8 0 0 0-1.34-.83 4 4 0 0 1 1.41.76 6.4 6.4 0 0 1 1.11 1.16 8.5 8.5 0 0 1 1.4 2.88"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-2">
        <path
          d="M603.41 434.89c-2 .34-3.85-1.69-4.53-3.16"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M603.41 434.89a2.73 2.73 0 0 1-1.47-.09 4.3 4.3 0 0 1-1.29-.73 5.7 5.7 0 0 1-1-1.06 5.5 5.5 0 0 1-.74-1.28 7.6 7.6 0 0 0 .81 1.22 7.7 7.7 0 0 0 1 1 4.5 4.5 0 0 0 1.25.74 3 3 0 0 0 1.44.2"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-6-9">
        <path
          d="M601.52 436.68a5 5 0 0 1-4.3-3.13"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M601.52 436.68a3.9 3.9 0 0 1-1.39-.21 4.1 4.1 0 0 1-1.25-.67 5.37 5.37 0 0 1-1.66-2.25 7 7 0 0 0 .76 1.17 5.4 5.4 0 0 0 1 1 4.6 4.6 0 0 0 1.2.68 4.8 4.8 0 0 0 1.34.28"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-0-4">
        <path
          d="M599.6 438.39a5.24 5.24 0 0 1-4.19-3.16"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M599.6 438.39a3.8 3.8 0 0 1-1.36-.27 5.1 5.1 0 0 1-1.2-.69 5.7 5.7 0 0 1-1-1 4 4 0 0 1-.66-1.21 5 5 0 0 0 .73 1.15 6.8 6.8 0 0 0 1 .95 4.9 4.9 0 0 0 2.49 1.07"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-1-8">
        <path
          d="M597.75 440a4.66 4.66 0 0 1-4.18-2.74"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M597.75 440a5.4 5.4 0 0 1-2.53-.72 4.2 4.2 0 0 1-1-.86 3.4 3.4 0 0 1-.64-1.16 4.2 4.2 0 0 0 .71 1.09 4.6 4.6 0 0 0 1 .81 5.7 5.7 0 0 0 1.18.54 8 8 0 0 0 1.28.3"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-16-6">
        <path
          d="M595.69 441.55a4.35 4.35 0 0 1-4-2.47"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M595.69 441.55a4 4 0 0 1-1.24-.12 4.5 4.5 0 0 1-1.16-.48 4.6 4.6 0 0 1-1-.8 3.3 3.3 0 0 1-.63-1.07 3.9 3.9 0 0 0 .7 1 4.6 4.6 0 0 0 1 .75 4.7 4.7 0 0 0 1.12.5 5.4 5.4 0 0 0 1.21.22"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-3-5">
        <path
          d="M604.94 433.23c-1.53.23-3.87-1.72-4.37-3.36"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M604.94 433.23a2.47 2.47 0 0 1-1.44-.22 5.5 5.5 0 0 1-1.25-.77 6 6 0 0 1-1-1.07 3.8 3.8 0 0 1-.66-1.3 4.3 4.3 0 0 0 .74 1.24 6.6 6.6 0 0 0 1 1 6.4 6.4 0 0 0 1.21.78 2.8 2.8 0 0 0 1.4.34"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <g id="Guatemala_svg__path13066-0-16-9-4">
        <path
          d="M593.45 443.16a3.8 3.8 0 0 1-3.61-2.49"
          className="Guatemala_svg__cls-18"
        />
        <path
          d="M593.45 443.16a3.5 3.5 0 0 1-1.16-.12 3.3 3.3 0 0 1-1.08-.5 3.62 3.62 0 0 1-1.37-1.87 4.7 4.7 0 0 0 .61 1 4 4 0 0 0 .84.79 3.9 3.9 0 0 0 1 .51 5 5 0 0 0 1.16.19"
          className="Guatemala_svg__cls-19"
        />
      </g>
      <path
        id="Guatemala_svg__path518-4-7-5-4-7"
        d="M589 431a1.08 1.08 0 0 0 .78-1.28c-.18-.42-.81-.57-1.44-.3s-1 .84-.84 1.26a1.14 1.14 0 0 0 1.5.32Z"
        className="Guatemala_svg__cls-17"
      />
      <path
        id="Guatemala_svg__path518-4-7-5-2"
        d="M589.43 429.79c.64-.27 1-.86.78-1.28s-.81-.57-1.44-.3-1 .83-.84 1.26a1.15 1.15 0 0 0 1.5.32Z"
        className="Guatemala_svg__cls-17"
      />
      <path
        id="Guatemala_svg__path518-4-7-6"
        d="M590.26 428.48a1.11 1.11 0 0 0 .78-1.28c-.18-.42-.81-.57-1.45-.3s-1 .83-.83 1.26a1.14 1.14 0 0 0 1.5.32Z"
        className="Guatemala_svg__cls-17"
      />
      <path
        id="Guatemala_svg__path518-4-7-0-3"
        d="M591.26 427.32c.64-.27 1-.86.78-1.29s-.81-.56-1.44-.29-1 .83-.84 1.25a1.16 1.16 0 0 0 1.5.33Z"
        className="Guatemala_svg__cls-17"
      />
      <path
        id="Guatemala_svg__path518-4-7-8-3"
        d="M592.56 426.2c.63-.26 1-.85.78-1.28 0-.05 0-.16-.06-.14-.26.12-.79.18-.71-.2.09-.13.2-.26.1-.17a2.8 2.8 0 0 0-.78.22c-.64.27-1 .84-.83 1.26a1.15 1.15 0 0 0 1.49.33Z"
        className="Guatemala_svg__cls-17"
      />
      <path
        id="Guatemala_svg__path814-3"
        d="M611.34 381.69s-7.38.79-9.45-9.39c-2.21-10.89 6.36-12.05 6.36-12.05s9.72-.93 15.76-1.09l2.69 21.52Z"
        className="Guatemala_svg__cls-21"
      />
      <path
        id="Guatemala_svg__path814-3-2"
        d="M611.34 381.69s-7.38.79-9.45-9.39c-2.21-10.89 6.36-12.05 6.36-12.05s9.72-.93 15.76-1.09l2.69 21.52Z"
        data-name="path814-3"
        style={{
          strokeWidth: ".75px",
          stroke: "#999270",
          fill: "url(#Guatemala_svg__radial-gradient)",
        }}
      />
      <path
        d="M612.32 378.41s-4.78.92-5.77-6.65c-.69-5.37 2.62-6.13 2.62-6.13l11.07 1.88.06 9.88Z"
        className="Guatemala_svg__cls-21"
      />
      <path
        d="M612.32 378.41s-4.78.92-5.77-6.65c-.69-5.37 2.62-6.13 2.62-6.13l11.07 1.88.06 9.88Z"
        style={{
          strokeWidth: ".75px",
          stroke: "#999270",
          fill: "url(#Guatemala_svg__radial-gradient-2)",
        }}
      />
      <path
        d="M609.17 365.63s7.08-.51 10.78-1l1.53 6.85-8.48.52s-.61-6.16-3.83-6.37Z"
        className="Guatemala_svg__cls-21"
      />
      <path
        d="M609.17 365.63s7.08-.51 10.78-1l1.53 6.85-8.48.52s-.61-6.16-3.83-6.37Z"
        style={{
          strokeWidth: ".75px",
          stroke: "#999270",
          fill: "url(#Guatemala_svg__radial-gradient-3)",
        }}
      />
      <path
        d="M607.69 360.43s9.08-2.23 10.21 7.78c.29 2.61-1.13 8.59-5.58 10.2l16.68-1.74-1.51-18-5.6.47s-11.89.42-14.2 1.29Z"
        className="Guatemala_svg__cls-21"
      />
      <path
        d="M607.69 360.43s9.08-2.23 10.21 7.78c.29 2.61-1.13 8.59-5.58 10.2l16.68-1.74-1.51-18-5.6.47s-11.89.42-14.2 1.29Z"
        style={{
          strokeWidth: ".75px",
          stroke: "#999270",
          fill: "url(#Guatemala_svg__radial-gradient-4)",
        }}
      />
      <path
        id="Guatemala_svg__path814-3-3"
        d="M611.34 381.69s62.21-4.18 68.58-3.92c18.87-10.36-.09-51-16-73.76 1.81-5.12-35.79-16.65-52.8-15.61-2.13.13-4.2.28-6.18.43-9.22 1-9.32 12.82-5.55 20.28 3.65 7.25 36.33 66.08 13.35 72.3Z"
        className="Guatemala_svg__cls-21"
        data-name="path814-3"
      />
      <path
        d="M678 328a179 179 0 0 0-14-24c1.81-5.12-35.79-16.65-52.8-15.61-2.13.13-4.2.28-6.18.43-9.22 1-9.32 12.82-5.55 20.28 1.14 2.28 5.15 9.64 9.4 18.86"
        style={{
          fill: "url(#Guatemala_svg__linear-gradient)",
        }}
      />
      <path
        d="M678 328q-3-6.26-6.58-12.27c-2.36-4-4.85-7.92-7.51-11.72a1.2 1.2 0 0 0 0-.79 2.7 2.7 0 0 0-.42-.71 7 7 0 0 0-1.23-1.14 21 21 0 0 0-2.89-1.76 58 58 0 0 0-6.2-2.73 130 130 0 0 0-12.92-4.12c-4.37-1.17-8.79-2.16-13.25-2.93-2.23-.39-4.46-.72-6.71-1a64 64 0 0 0-6.77-.42c-1.13 0-2.25.06-3.38.13l-3.39.23-1.69.12a10 10 0 0 0-1.65.31 7.7 7.7 0 0 0-3 1.55 8.4 8.4 0 0 0-2 2.65 12 12 0 0 0-1 3.21 19 19 0 0 0 .16 6.73 22.2 22.2 0 0 0 2.24 6.37c1 2 2.1 4 3.12 6 2 4 4 8.1 5.92 12.22-1.91-4.11-3.91-8.16-6-12.19l-3.14-6a22.8 22.8 0 0 1-2.27-6.39 19.3 19.3 0 0 1-.17-6.78 12 12 0 0 1 1-3.25 8.4 8.4 0 0 1 2.06-2.7 7.9 7.9 0 0 1 3-1.59 10.6 10.6 0 0 1 1.68-.31l1.69-.13 3.39-.23c1.12-.08 2.26-.14 3.39-.14a64 64 0 0 1 6.79.41c2.25.24 4.49.57 6.72.95 4.47.78 8.89 1.79 13.26 3a133 133 0 0 1 12.93 4.15 61 61 0 0 1 6.21 2.75 21.5 21.5 0 0 1 2.9 1.78 7.3 7.3 0 0 1 1.25 1.17 2.6 2.6 0 0 1 .44.74 1.3 1.3 0 0 1 0 .87c2.66 3.81 5.15 7.74 7.48 11.75S676 323.85 678 328"
        className="Guatemala_svg__cls-27"
      />
      <path
        d="M614.74 342c6.66 17.56 10.39 36.09-2 39.44l-1.41.28s62.21-4.18 68.58-3.92c10.23-5.62 9.34-20.14 3.84-35.89"
        style={{
          fill: "url(#Guatemala_svg__linear-gradient-2)",
        }}
      />
      <path
        d="M614.74 342c1.35 3.53 2.59 7.1 3.63 10.74a67.7 67.7 0 0 1 2.33 11.09 33.6 33.6 0 0 1 .22 5.67 18.2 18.2 0 0 1-1.06 5.56 10.24 10.24 0 0 1-3.26 4.59 10 10 0 0 1-2.47 1.41 11 11 0 0 1-1.36.45l-1.39.28v-.14l17.13-1.14 17.14-1.09q8.57-.52 17.14-1l8.58-.44 4.29-.18c1.43 0 2.86-.1 4.3-.07a13.7 13.7 0 0 0 3.8-3 14.5 14.5 0 0 0 2.54-4.13 24.1 24.1 0 0 0 1.51-9.59 50.3 50.3 0 0 0-1.26-9.69 86 86 0 0 0-2.71-9.4 86 86 0 0 1 2.75 9.39 50.4 50.4 0 0 1 1.32 9.7 29.6 29.6 0 0 1-.26 4.89 20.4 20.4 0 0 1-1.24 4.74 15 15 0 0 1-2.55 4.18 14.3 14.3 0 0 1-3.83 3.05c-1.42 0-2.85 0-4.28.08l-4.29.19q-4.29.21-8.58.46-8.56.48-17.14 1l-17.14 1.07-17.14 1.11v-.14l1.39-.28a9.5 9.5 0 0 0 1.34-.44 9.7 9.7 0 0 0 2.44-1.38 10.2 10.2 0 0 0 3.23-4.53 18.6 18.6 0 0 0 1.06-5.53 34.5 34.5 0 0 0-.21-5.65 71.3 71.3 0 0 0-2.28-11.09c-1.14-3.66-2.36-7.24-3.69-10.74"
        className="Guatemala_svg__cls-27"
      />
      <g id="Guatemala_svg__g3643">
        <g id="Guatemala_svg__text6835-1-5-5-4-6-7-1">
          <path
            id="Guatemala_svg__path3436"
            d="M636.93 336.84a1.7 1.7 0 0 0 .25.41 1 1 0 0 0 .31.23 1.8 1.8 0 0 0 .41.12l.53.06a.3.3 0 0 1 .19.08.7.7 0 0 1 .11.16.23.23 0 0 1 0 .16.16.16 0 0 1-.15.1h-2.18l-.45.06-.44.06h-.4a.28.28 0 0 1-.21-.08.5.5 0 0 1-.13-.17.3.3 0 0 1 0-.16.14.14 0 0 1 .13-.1l.47-.12a.8.8 0 0 0 .32-.16.45.45 0 0 0 .14-.26.8.8 0 0 0-.09-.41l-1.55-3.73a1.5 1.5 0 0 0-.14-.28.4.4 0 0 0-.13-.16.5.5 0 0 0-.17-.07h-.56l-.35.05a.3.3 0 0 1-.23-.06.6.6 0 0 1-.14-.19.24.24 0 0 1 0-.15c0-.05 0-.09.1-.12.32-.1.62-.22.91-.33s.59-.24.91-.35a.44.44 0 0 1 .23 0 .26.26 0 0 1 .19.18Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3438"
            d="M637.83 331.44h2.34a.5.5 0 0 0 .2-.06.34.34 0 0 0 .12-.12.5.5 0 0 0 0-.15v-.15c0-.05.05-.1.13-.1a.3.3 0 0 1 .14 0 .4.4 0 0 1 .15.07.21.21 0 0 1 .11.15v.52a3 3 0 0 1 0 .28 1 1 0 0 1-.07.26.54.54 0 0 1-.18.19.63.63 0 0 1-.3.1h-2.04c.05.17.11.36.18.55s.16.41.25.64a1 1 0 0 1 .07.17v.09c-.05 0-.05 0 0-.06l.21-.07.27-.06h.28a3 3 0 0 1 .94.09 3.8 3.8 0 0 1 .91.38 3.2 3.2 0 0 1 .77.61 2.8 2.8 0 0 1 .54.83 2.45 2.45 0 0 1 .21 1 1.35 1.35 0 0 1-.24.76 1.5 1.5 0 0 1-.66.48 3.1 3.1 0 0 1-1 .21h-.53a3.5 3.5 0 0 1-.55-.1 2 2 0 0 1-.51-.25.9.9 0 0 1-.35-.42.66.66 0 0 1 0-.39.31.31 0 0 1 .32-.26h.21a1 1 0 0 1 .22.09.6.6 0 0 1 .19.16.7.7 0 0 1 .14.2 1 1 0 0 1 0 .11.3.3 0 0 1 0 .09v.05l.08.07.18.06h.54c.44 0 .7-.19.77-.5a2.07 2.07 0 0 0-.2-1.24 2.5 2.5 0 0 0-.8-1.07 1.87 1.87 0 0 0-1.26-.33 1.3 1.3 0 0 0-.44.09l-.22.08a.47.47 0 0 1-.33-.07.6.6 0 0 1-.19-.27c-.18-.43-.34-.85-.5-1.26s-.29-.83-.42-1.24a.22.22 0 0 1 0-.16.21.21 0 0 1 .19-.09Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3440"
            d="M646.05 332.66a5 5 0 0 0-.25-.53 2 2 0 0 0-.27-.37 1.1 1.1 0 0 0-.34-.21 1.5 1.5 0 0 0-.46-.08.25.25 0 0 1-.2-.07.4.4 0 0 1-.11-.16.3.3 0 0 1 0-.17c0-.05.06-.08.14-.09h1.95c.24 0 .49 0 .73-.05a4.6 4.6 0 0 1 1.56.18 5.5 5.5 0 0 1 1.44.66 5.6 5.6 0 0 1 1.19 1.05 4.9 4.9 0 0 1 .83 1.36 3.6 3.6 0 0 1 .31 1.44 1.9 1.9 0 0 1-.31 1.05 1.9 1.9 0 0 1-.84.65 3.7 3.7 0 0 1-1.26.26 8 8 0 0 1-.84 0 6 6 0 0 0-.72 0h-.94l-.33.05a.3.3 0 0 1-.22-.07.5.5 0 0 1-.13-.18.22.22 0 0 1 0-.16c0-.05 0-.08.13-.1l.33-.09a.44.44 0 0 0 .18-.14.36.36 0 0 0 0-.22 1.5 1.5 0 0 0-.11-.37Zm2.43 2.95c.13.32.24.56.33.74a1.2 1.2 0 0 0 .3.37.73.73 0 0 0 .37.15 4 4 0 0 0 .6 0 1.3 1.3 0 0 0 .65-.2 1 1 0 0 0 .37-.53 2.1 2.1 0 0 0 0-.85 4.8 4.8 0 0 0-.32-1.14 7.5 7.5 0 0 0-.62-1.2 3.5 3.5 0 0 0-.79-.84 3.2 3.2 0 0 0-1-.49 4.5 4.5 0 0 0-1.32-.12c-.1 0-.15 0-.17.05s0 .09 0 .19Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3442"
            d="M656.2 330.51a.5.5 0 0 1 .18 0 .34.34 0 0 1 .23.26.7.7 0 0 0 0 .15 1.3 1.3 0 0 0 .07.18l.06.16c.06.14.11.29.18.42l.16.37v.14a.3.3 0 0 1-.15.05h-.16a.3.3 0 0 1-.16-.15c-.13-.22-.25-.4-.34-.53a1 1 0 0 0-.27-.28.8.8 0 0 0-.33-.1h-1.47a.4.4 0 0 0-.17.05.2.2 0 0 0 0 .15c0 .08.06.19.12.35l.65 1.67c0 .06 0 .09.05.09a4 4 0 0 0 .64-.06.5.5 0 0 0 .29-.13.28.28 0 0 0 0-.23 2.3 2.3 0 0 0-.09-.4.14.14 0 0 1 .05-.14.3.3 0 0 1 .16-.05.5.5 0 0 1 .18 0 .24.24 0 0 1 .13.15c0 .09.07.18.1.27s.05.17.08.25 0 .16.07.24.06.17.11.29a4 4 0 0 0 .23.51c0 .07.08.16.13.27l.13.31v.15a.25.25 0 0 1-.15 0 .4.4 0 0 1-.18 0 .26.26 0 0 1-.16-.13 5 5 0 0 0-.23-.43.7.7 0 0 0-.23-.22 1.1 1.1 0 0 0-.38-.1 5 5 0 0 0-.67 0 .14.14 0 0 0 0 .07l.49 1.25a4.3 4.3 0 0 0 .3.66.8.8 0 0 0 .28.34 1 1 0 0 0 .4.12h1.09a.7.7 0 0 0 .25-.06 1.3 1.3 0 0 0 .24-.15.4.4 0 0 0 .15-.19 1 1 0 0 0 0-.28 3 3 0 0 0-.09-.46v-.14a.4.4 0 0 1 .16-.05.6.6 0 0 1 .21.05.23.23 0 0 1 .14.15c.08.27.15.53.22.76a5 5 0 0 0 .24.69.15.15 0 0 1 0 .07.08.08 0 0 1 0 .08.2.2 0 0 1-.08.07.4.4 0 0 1-.14 0l-3.41.13h-.69l-.6.06a.28.28 0 0 1-.21-.08.4.4 0 0 1-.12-.17.3.3 0 0 1 0-.16c0-.05 0-.08.12-.1a1.1 1.1 0 0 0 .33-.09.3.3 0 0 0 .18-.14.3.3 0 0 0 0-.22 1.2 1.2 0 0 0-.1-.36l-1.43-3.67-.17-.39a2.3 2.3 0 0 0-.2-.35 1.5 1.5 0 0 0-.23-.26.66.66 0 0 0-.28-.13h-.43a.22.22 0 0 1-.19-.07.6.6 0 0 1-.11-.16.3.3 0 0 1 0-.17c0-.06.05-.09.12-.09h1.18Z"
            className="Guatemala_svg__cls-29"
          />
        </g>
        <g id="Guatemala_svg__text6835-1-5-6-4-1-9-5">
          <path
            id="Guatemala_svg__path3445"
            d="M640.47 359.18a5 5 0 0 0-.17-.54 1.2 1.2 0 0 0-.21-.36.7.7 0 0 0-.3-.21 1.4 1.4 0 0 0-.45-.07.17.17 0 0 1-.18-.07.3.3 0 0 1-.09-.15.33.33 0 0 1 0-.17c0-.06.06-.09.15-.1h1.17c.28 0 .54 0 .78-.07s.49 0 .74-.06a4.3 4.3 0 0 1 1.52.16 4.4 4.4 0 0 1 1.33.63 4.2 4.2 0 0 1 1 1 3.9 3.9 0 0 1 .61 1.36 3.6 3.6 0 0 1 .09 1.44A2.2 2.2 0 0 1 646 363a2.5 2.5 0 0 1-.94.68 4 4 0 0 1-1.29.28 8 8 0 0 1-.85 0h-1.66l-.34.06a.24.24 0 0 1-.21-.07.33.33 0 0 1-.1-.17.22.22 0 0 1 0-.16.17.17 0 0 1 .15-.1 3 3 0 0 0 .34-.1.6.6 0 0 0 .2-.14.4.4 0 0 0 .07-.23 1.4 1.4 0 0 0 0-.37Zm2 2.92a6 6 0 0 0 .22.73 1.1 1.1 0 0 0 .23.38.74.74 0 0 0 .36.14 4 4 0 0 0 .59 0 1.36 1.36 0 0 0 .68-.21 1.34 1.34 0 0 0 .45-.54 2.1 2.1 0 0 0 .18-.85 4.5 4.5 0 0 0-.14-1.14 5.6 5.6 0 0 0-.43-1.2 2.6 2.6 0 0 0-.66-.83 2.25 2.25 0 0 0-.94-.47 3.9 3.9 0 0 0-1.31-.1c-.09 0-.15 0-.17.05s0 .09 0 .19Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3447"
            d="M650.89 356.8h.18a.28.28 0 0 1 .19.26 1 1 0 0 0 0 .15v.33a4 4 0 0 0 .11.42c0 .14.08.26.11.38a.12.12 0 0 1-.06.14.3.3 0 0 1-.15.05.5.5 0 0 1-.16 0 .2.2 0 0 1-.14-.14 4 4 0 0 0-.26-.53.8.8 0 0 0-.23-.27.57.57 0 0 0-.31-.1 4 4 0 0 0-.51 0h-.96a.27.27 0 0 0-.17.06s0 .08 0 .15a4 4 0 0 0 .07.35l.4 1.66a.4.4 0 0 0 0 .1 5 5 0 0 0 .65-.08.7.7 0 0 0 .31-.13.36.36 0 0 0 .08-.24 4 4 0 0 0 0-.4.15.15 0 0 1 .08-.15.3.3 0 0 1 .16-.05.4.4 0 0 1 .17 0 .19.19 0 0 1 .11.14c0 .1 0 .19.06.28s0 .17 0 .25v.24c0 .07 0 .17.07.29a4 4 0 0 0 .14.51c0 .07.06.16.1.27s.06.21.08.31a.14.14 0 0 1 0 .15.4.4 0 0 1-.16 0h-.18a.27.27 0 0 1-.13-.13 3 3 0 0 0-.17-.43.5.5 0 0 0-.19-.22.8.8 0 0 0-.37-.09 5 5 0 0 0-.66 0s0 0 0 .07l.3 1.25a6 6 0 0 0 .2.66.6.6 0 0 0 .61.44 5 5 0 0 0 .68 0h.41a1 1 0 0 0 .26-.07l.26-.15a.6.6 0 0 0 .17-.19.66.66 0 0 0 .08-.29 2.6 2.6 0 0 0 0-.46.15.15 0 0 1 .08-.15.4.4 0 0 1 .16 0 .5.5 0 0 1 .2 0 .18.18 0 0 1 .12.15c0 .28.07.53.1.76a4.6 4.6 0 0 0 .14.7v.07a.16.16 0 0 1 0 .08.3.3 0 0 1-.09.07h-.15l-3.41.2-.69.06-.61.08a.24.24 0 0 1-.2-.08.4.4 0 0 1-.1-.17.26.26 0 0 1 0-.15c0-.06.06-.09.14-.11l.34-.09a.46.46 0 0 0 .2-.15.4.4 0 0 0 .08-.23 1 1 0 0 0 0-.36l-.88-3.66c0-.14-.07-.27-.11-.4a2 2 0 0 0-.14-.35 1.3 1.3 0 0 0-.19-.25.53.53 0 0 0-.25-.12h-.43a.21.21 0 0 1-.17-.07.4.4 0 0 1-.09-.16.33.33 0 0 1 0-.17.13.13 0 0 1 .14-.09h1.18Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3449"
            d="M658.67 361.71a1.2 1.2 0 0 0 .17.41.67.67 0 0 0 .27.23 1.2 1.2 0 0 0 .39.13l.52.06a.26.26 0 0 1 .17.09.3.3 0 0 1 .09.15.33.33 0 0 1 0 .17.16.16 0 0 1-.16.09h-2.2l-.46.05-.46.05h-.4a.24.24 0 0 1-.2-.08.32.32 0 0 1-.09-.17.22.22 0 0 1 0-.16.21.21 0 0 1 .15-.1 5 5 0 0 0 .49-.11 1 1 0 0 0 .36-.16.48.48 0 0 0 .18-.26.7.7 0 0 0 0-.41l-.87-3.73a1.6 1.6 0 0 0-.08-.28.4.4 0 0 0-.1-.16.4.4 0 0 0-.17-.07 1.3 1.3 0 0 0-.29 0h-.62a.26.26 0 0 1-.23-.07.5.5 0 0 1-.1-.18.4.4 0 0 1 0-.15c0-.06.06-.09.13-.12.34-.1.66-.21 1-.33l1-.33a.45.45 0 0 1 .23 0 .24.24 0 0 1 .16.18Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3451"
            d="M663.09 357.73a1.5 1.5 0 0 0-.38-.78.92.92 0 0 0-.71-.24.69.69 0 0 0-.59.28.9.9 0 0 0-.08.74 1 1 0 0 0 .17.37 1.5 1.5 0 0 0 .32.29 3 3 0 0 0 .46.26l.57.25a1 1 0 0 0 .28-.46 1.5 1.5 0 0 0 0-.7Zm1 3.47a1.2 1.2 0 0 0-.23-.49 1.7 1.7 0 0 0-.4-.35 2.7 2.7 0 0 0-.53-.28l-.64-.28a1.4 1.4 0 0 0-.43.57 1.16 1.16 0 0 0-.06.69 1.9 1.9 0 0 0 .53 1 1.25 1.25 0 0 0 .9.32.86.86 0 0 0 .77-.38 1 1 0 0 0 .09-.8m1.07-.25a1.54 1.54 0 0 1 0 .83 1.6 1.6 0 0 1-.4.61 2 2 0 0 1-.66.38 2.6 2.6 0 0 1-.8.15 3.6 3.6 0 0 1-.81-.06 2.7 2.7 0 0 1-.75-.27 2.1 2.1 0 0 1-.6-.52 1.85 1.85 0 0 1-.35-.78 1.52 1.52 0 0 1 .13-1.12 1.5 1.5 0 0 1 .86-.67 3 3 0 0 1-.92-.58 1.7 1.7 0 0 1-.56-.91 1.6 1.6 0 0 1 0-.75 1.46 1.46 0 0 1 .32-.57 1.6 1.6 0 0 1 .57-.37 2.6 2.6 0 0 1 .75-.16 2.7 2.7 0 0 1 .75.08 2.4 2.4 0 0 1 .69.3 2.1 2.1 0 0 1 .54.5 1.9 1.9 0 0 1 .32.7 1.1 1.1 0 0 1 0 .48 1.3 1.3 0 0 1-.17.43 1.6 1.6 0 0 1-.32.38.8.8 0 0 1-.39.2 2 2 0 0 1 .54.19 2.7 2.7 0 0 1 .56.37 2.1 2.1 0 0 1 .44.5 2.2 2.2 0 0 1 .27.66Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3453"
            d="M669.07 361.61a.7.7 0 0 0 .54-.21 1.35 1.35 0 0 0 .16-.78.13.13 0 0 1 .06-.14.25.25 0 0 1 .15-.05.6.6 0 0 1 .21.05.3.3 0 0 1 .14.17 5 5 0 0 1 .08.68 2.5 2.5 0 0 1-.07.62 1.1 1.1 0 0 1-.25.48.72.72 0 0 1-.51.21h-.64l-.63-.05h-.59a4 4 0 0 0-.49 0 1.3 1.3 0 0 0-.48.09l-.2.08-.18.06a.32.32 0 0 1-.29-.11 1 1 0 0 1-.1-.24.5.5 0 0 1 0-.17 3.4 3.4 0 0 1 .25-.81 3.6 3.6 0 0 1 .37-.64 6 6 0 0 1 .42-.5l.46-.43.4-.4a2 2 0 0 0 .29-.41 1.6 1.6 0 0 0 .16-.5 2 2 0 0 0 0-.62 1.9 1.9 0 0 0-.46-1 1.19 1.19 0 0 0-1-.37.9.9 0 0 0-.46.11.9.9 0 0 0-.29.24.8.8 0 0 0-.14.28.5.5 0 0 0 0 .23.5.5 0 0 0 0 .1.3.3 0 0 0 .06.1.3.3 0 0 0 .1.08.5.5 0 0 0 .19 0 .55.55 0 0 1 .41.13.6.6 0 0 1 .18.33.48.48 0 0 1-.07.4.46.46 0 0 1-.41.18.9.9 0 0 1-.47-.09 1.1 1.1 0 0 1-.33-.26 1 1 0 0 1-.2-.32 1.7 1.7 0 0 1-.1-.29 1.6 1.6 0 0 1 0-.69 1.46 1.46 0 0 1 .3-.58 1.6 1.6 0 0 1 .57-.41 2.3 2.3 0 0 1 .79-.16 3 3 0 0 1 1 .1 2.4 2.4 0 0 1 .74.35 2 2 0 0 1 .51.57 2.5 2.5 0 0 1 .28.73 1.8 1.8 0 0 1 0 1 2.4 2.4 0 0 1-.45.76 6 6 0 0 1-.66.64l-.32.25-.34.3a2.6 2.6 0 0 0-.48.53.62.62 0 0 0-.09.53s0-.09.07-.12a3 3 0 0 1 .58-.08h1.01Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3455"
            d="M673.79 361.11a1.2 1.2 0 0 0 .16.42.7.7 0 0 0 .26.23 1.8 1.8 0 0 0 .39.14l.52.07a.26.26 0 0 1 .17.09.4.4 0 0 1 .09.16.23.23 0 0 1 0 .16.15.15 0 0 1-.16.09h-3.53a.26.26 0 0 1-.2-.08.35.35 0 0 1-.09-.17.18.18 0 0 1 0-.16.16.16 0 0 1 .15-.1l.49-.1a1.2 1.2 0 0 0 .36-.15.6.6 0 0 0 .2-.25.9.9 0 0 0 0-.41l-.78-3.74a1.6 1.6 0 0 0-.08-.28.5.5 0 0 0-.1-.16.3.3 0 0 0-.16-.07 1.2 1.2 0 0 0-.29 0h-.63a.23.23 0 0 1-.22-.08.3.3 0 0 1-.1-.18.4.4 0 0 1 0-.15.22.22 0 0 1 .14-.12c.33-.09.66-.19 1-.3l1-.31a.4.4 0 0 1 .24 0 .22.22 0 0 1 .15.18Z"
            className="Guatemala_svg__cls-29"
          />
        </g>
        <g id="Guatemala_svg__text6835-1-5-8-4-4-7-7">
          <path
            id="Guatemala_svg__path3458"
            d="M618.85 321a4 4 0 0 0-.23-.48 1.7 1.7 0 0 0-.24-.31 1 1 0 0 0-.31-.16 1.8 1.8 0 0 0-.44 0 .27.27 0 0 1-.2-.07.6.6 0 0 1-.12-.17.3.3 0 0 1 0-.17c0-.06.05-.09.13-.09h2.78a.19.19 0 0 1 .19.07.5.5 0 0 1 .11.15.35.35 0 0 1 0 .18c0 .06 0 .09-.11.1a1.1 1.1 0 0 0-.4.1.3.3 0 0 0-.16.17.63.63 0 0 0 0 .32 5 5 0 0 0 .19.51l1.41 3.31a5 5 0 0 0 .23.49 1.7 1.7 0 0 0 .23.31.9.9 0 0 0 .25.17 1.8 1.8 0 0 0 .36.1h.61a1.7 1.7 0 0 0 .51-.09.6.6 0 0 0 .3-.21.9.9 0 0 0 .11-.41 3.4 3.4 0 0 0-.05-.66.11.11 0 0 1 .05-.14.25.25 0 0 1 .15-.05h.21a.28.28 0 0 1 .15.15c.08.28.17.57.27.84s.19.53.29.77a.14.14 0 0 1 0 .07.08.08 0 0 1 0 .08.16.16 0 0 1-.07.07.26.26 0 0 1-.14 0l-3.37.24-.68.08-.64.09a.3.3 0 0 1-.21-.07.5.5 0 0 1-.13-.16.28.28 0 0 1 0-.18.16.16 0 0 1 .11-.1 2.4 2.4 0 0 0 .42-.11.3.3 0 0 0 .19-.12.33.33 0 0 0 0-.24 2.5 2.5 0 0 0-.17-.47l-1.57-3.91Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3460"
            d="M624.63 320.54a4 4 0 0 0-.23-.48 1.3 1.3 0 0 0-.24-.3.7.7 0 0 0-.3-.16 1.4 1.4 0 0 0-.45-.06.22.22 0 0 1-.2-.07.8.8 0 0 1-.12-.16.35.35 0 0 1 0-.17c0-.06.06-.09.13-.1h2.79a.24.24 0 0 1 .2.08.5.5 0 0 1 .1.16.33.33 0 0 1 0 .17.1.1 0 0 1-.12.09 1.3 1.3 0 0 0-.39.11.27.27 0 0 0-.16.17.5.5 0 0 0 0 .31c0 .13.1.3.19.52l1.58 3.73a3 3 0 0 0 .23.46.7.7 0 0 0 .2.22.8.8 0 0 0 .29.1h.46a.28.28 0 0 1 .2.09.36.36 0 0 1 .12.16.22.22 0 0 1 0 .16c0 .06-.06.09-.14.1h-.74a5 5 0 0 0-.67 0 6 6 0 0 0-.71.08 6 6 0 0 1-.67.09.27.27 0 0 1-.2-.07.4.4 0 0 1-.15-.33c0-.06 0-.09.11-.11a2 2 0 0 0 .41-.1.28.28 0 0 0 .19-.13.37.37 0 0 0 0-.24c0-.1-.09-.26-.17-.46Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3462"
            d="M630 321.39a1 1 0 0 0 .07.13s.06 0 .12 0h.18a2.3 2.3 0 0 0 .51-.09.7.7 0 0 0 .33-.2.53.53 0 0 0 .12-.37 1.7 1.7 0 0 0-.15-.6 2.5 2.5 0 0 0-.3-.52 1.5 1.5 0 0 0-.36-.32 1.1 1.1 0 0 0-.43-.17 1.5 1.5 0 0 0-.52 0h-.4a.4.4 0 0 0 0 .1l.08.2Zm.81 1.92a8 8 0 0 0 .36.79 1.4 1.4 0 0 0 .28.4.6.6 0 0 0 .3.14h.47q.6 0 .72-.33a1.3 1.3 0 0 0-.14-.91 2.7 2.7 0 0 0-.41-.7 2 2 0 0 0-.5-.4 1.6 1.6 0 0 0-.57-.17 3.3 3.3 0 0 0-.63 0h-.17c-.1 0-.15 0-.16.05a.55.55 0 0 0 .07.25Zm-2.44-2.85-.18-.39a2 2 0 0 0-.21-.35 1.5 1.5 0 0 0-.24-.26.7.7 0 0 0-.27-.12H627a.22.22 0 0 1-.19-.08.4.4 0 0 1-.12-.16.3.3 0 0 1 0-.16c0-.06 0-.09.13-.09h3a5 5 0 0 1 .58.06 3.3 3.3 0 0 1 .58.15 2.6 2.6 0 0 1 .55.28 2.3 2.3 0 0 1 .48.42 2.1 2.1 0 0 1 .35.58 1.4 1.4 0 0 1 .13.55.9.9 0 0 1-.08.44.9.9 0 0 1-.26.33 3 3 0 0 1-.35.24 4 4 0 0 1 1.45.55 2.5 2.5 0 0 1 .94 1.17 1.86 1.86 0 0 1 .18.88 1 1 0 0 1-.27.57 1.3 1.3 0 0 1-.57.32 3.5 3.5 0 0 1-.75.13h-2.7l-.34.05a.23.23 0 0 1-.21-.06.7.7 0 0 1-.13-.18.4.4 0 0 1 0-.16.11.11 0 0 1 .11-.1 1.5 1.5 0 0 0 .33-.1.33.33 0 0 0 .18-.13.34.34 0 0 0 0-.23 1.5 1.5 0 0 0-.11-.37Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3464"
            d="M637.24 318.18h.18a.36.36 0 0 1 .23.26.7.7 0 0 0 .05.15 1.3 1.3 0 0 0 .07.18l.06.15c.06.15.13.29.19.43a4 4 0 0 1 .17.37v.14a.3.3 0 0 1-.15.05h-.16a.24.24 0 0 1-.16-.14c-.14-.23-.26-.4-.35-.53a1.2 1.2 0 0 0-.28-.27.7.7 0 0 0-.33-.1h-1.46a.24.24 0 0 0-.16.06s0 .07 0 .15a3 3 0 0 0 .13.35l.68 1.66.06.1a3.7 3.7 0 0 0 .64-.07.46.46 0 0 0 .28-.14.28.28 0 0 0 .05-.23c0-.1-.05-.23-.1-.4a.12.12 0 0 1 0-.15.25.25 0 0 1 .15-.05.5.5 0 0 1 .18 0 .32.32 0 0 1 .14.14c0 .1.07.19.1.28a2 2 0 0 1 .08.25c0 .08 0 .16.08.24l.12.29a4 4 0 0 0 .23.51c0 .07.09.16.14.27l.13.31a.12.12 0 0 1 0 .15.25.25 0 0 1-.15.05h-.18a.33.33 0 0 1-.16-.13 3 3 0 0 0-.24-.43.7.7 0 0 0-.23-.22.9.9 0 0 0-.38-.09 5 5 0 0 0-.66 0s0 0 0 .07l.52 1.25a5 5 0 0 0 .31.66.8.8 0 0 0 .29.33.76.76 0 0 0 .4.12 5 5 0 0 0 .67 0h.41a.9.9 0 0 0 .25-.07l.24-.15a.4.4 0 0 0 .13-.19.65.65 0 0 0 0-.29 3 3 0 0 0-.1-.46c0-.07 0-.12.05-.15a.4.4 0 0 1 .16 0 .5.5 0 0 1 .2 0 .22.22 0 0 1 .15.15c.08.28.16.54.23.76a5 5 0 0 0 .26.7s0 0 0 .07a.08.08 0 0 1 0 .08l-.07.07a.3.3 0 0 1-.14 0l-3.39.2c-.23 0-.46 0-.68.06l-.6.07a.28.28 0 0 1-.21-.08.5.5 0 0 1-.12-.17.26.26 0 0 1 0-.15c0-.06 0-.09.12-.11a1.1 1.1 0 0 0 .32-.09.4.4 0 0 0 .18-.15.33.33 0 0 0 0-.23 1.4 1.4 0 0 0-.1-.36l-1.51-3.66-.18-.39a1.7 1.7 0 0 0-.21-.35 1.1 1.1 0 0 0-.23-.25.57.57 0 0 0-.27-.13 1.4 1.4 0 0 0-.23 0h-.21a.24.24 0 0 1-.19-.07.6.6 0 0 1-.11-.16.3.3 0 0 1 0-.17c0-.06 0-.09.13-.09h1.16Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3466"
            d="M640 319.76c0-.13-.11-.26-.17-.39a2 2 0 0 0-.21-.36 1.1 1.1 0 0 0-.23-.25.56.56 0 0 0-.28-.13h-.43a.22.22 0 0 1-.19-.07.4.4 0 0 1-.12-.16.3.3 0 0 1 0-.17.13.13 0 0 1 .14-.09h2.09c.26 0 .5 0 .7-.05a3.2 3.2 0 0 1 .81.05 2.7 2.7 0 0 1 .81.28 2.6 2.6 0 0 1 .71.53 2.4 2.4 0 0 1 .52.8 1.9 1.9 0 0 1 .15.67.86.86 0 0 1-.12.51 1 1 0 0 1-.33.35 2 2 0 0 1-.5.22 4 4 0 0 1 .65.3 3.4 3.4 0 0 1 .5.35 3.4 3.4 0 0 1 .38.38q.17.19.3.39a4 4 0 0 1 .24.38l.24.38a.9.9 0 0 0 .25.28.32.32 0 0 0 .18.07.16.16 0 0 0 .12-.08.4.4 0 0 0 0-.24v-.15a.17.17 0 0 1 .13-.05.5.5 0 0 1 .22.06.33.33 0 0 1 .16.19 1 1 0 0 1 0 .44.84.84 0 0 1-.15.33.7.7 0 0 1-.29.19 1.2 1.2 0 0 1-.37.08 2 2 0 0 1-.33 0 1.2 1.2 0 0 1-.32-.1 1.3 1.3 0 0 1-.31-.17 1.5 1.5 0 0 1-.25-.25c-.08-.1-.16-.22-.25-.35l-.26-.38-.19-.55-.22-.34a3.2 3.2 0 0 0-.74-.79 1.16 1.16 0 0 0-.77-.21.23.23 0 0 0-.18.06s0 .1 0 .19l.52 1.29a2 2 0 0 0 .26.48.85.85 0 0 0 .28.26 1.2 1.2 0 0 0 .34.13h.44a.26.26 0 0 1 .2.07.44.44 0 0 1 .12.17.25.25 0 0 1 0 .17c0 .06-.06.09-.14.1h-1.51c-.23 0-.45 0-.67.07s-.44.06-.67.08a.3.3 0 0 1-.2-.08.5.5 0 0 1-.12-.17.3.3 0 0 1 0-.16.12.12 0 0 1 .11-.1l.39-.09a.35.35 0 0 0 .17-.14.4.4 0 0 0 0-.25 3 3 0 0 0-.15-.43Zm1.68 1.07c.05.12.1.19.14.2a1.7 1.7 0 0 0 .4 0 1.8 1.8 0 0 0 .55-.09.48.48 0 0 0 .27-.23.73.73 0 0 0 0-.4 4 4 0 0 0-.19-.62 3.2 3.2 0 0 0-.31-.59 1.5 1.5 0 0 0-.33-.36 1.5 1.5 0 0 0-.43-.18 2.4 2.4 0 0 0-.55 0c-.22 0-.34 0-.37.07s0 .17 0 .34Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3468"
            d="M650.34 322.89a4 4 0 0 0 .21.47.8.8 0 0 0 .19.23.9.9 0 0 0 .29.1 4 4 0 0 0 .48.05.37.37 0 0 1 .2.09.6.6 0 0 1 .12.16.33.33 0 0 1 0 .17.17.17 0 0 1-.15.09 6 6 0 0 1-.74 0h-.68c-.21 0-.47 0-.72.07a6 6 0 0 1-.68.07.24.24 0 0 1-.2-.08.45.45 0 0 1-.12-.16.4.4 0 0 1 0-.16.14.14 0 0 1 .11-.11 2 2 0 0 0 .42-.09.35.35 0 0 0 .19-.12.37.37 0 0 0 0-.24 4 4 0 0 0-.16-.46l-1.68-4.23c-.08-.2-.17-.32-.28-.36a1.35 1.35 0 0 0-.55-.05 2.2 2.2 0 0 0-.52.07.54.54 0 0 0-.27.18.7.7 0 0 0-.07.36 3.3 3.3 0 0 0 .08.6c0 .07 0 .12-.05.14a.34.34 0 0 1-.16 0 .6.6 0 0 1-.17-.05.22.22 0 0 1-.13-.14c-.07-.24-.16-.48-.25-.72a7 7 0 0 0-.32-.71c0-.12 0-.21.08-.25a1.1 1.1 0 0 1 .33-.06l5-.21a1.1 1.1 0 0 1 .37 0 .33.33 0 0 1 .26.22 2 2 0 0 0 .08.28 3 3 0 0 0 .13.36 4 4 0 0 0 .17.41c.06.12.12.26.19.39a.12.12 0 0 1 0 .15.2.2 0 0 1-.13.07.4.4 0 0 1-.19 0 .28.28 0 0 1-.16-.12 4 4 0 0 0-.38-.61 1.5 1.5 0 0 0-.37-.34 1.1 1.1 0 0 0-.41-.16 3 3 0 0 0-.52 0 1.2 1.2 0 0 0-.51.09c-.08.06-.09.18 0 .37Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3470"
            d="M654.81 321.8a.25.25 0 0 0-.12 0 .17.17 0 0 0 0 .12v1.12a1 1 0 0 0 0 .19.4.4 0 0 0 .11.16.6.6 0 0 0 .17.1l.23.06h.26a.25.25 0 0 1 .21.08.44.44 0 0 1 .12.17.3.3 0 0 1 0 .17c0 .06 0 .09-.14.09h-1.1a3 3 0 0 0-.44.05 3 3 0 0 1-.49.06.3.3 0 0 1-.21-.09.5.5 0 0 1-.12-.18.26.26 0 0 1 0-.15q0-.08.12-.09h.13l.21-.07a.6.6 0 0 0 .14-.13.6.6 0 0 0 .09-.23 2.5 2.5 0 0 0 0-.46v-5.43c0-.09.07-.14.2-.15a.6.6 0 0 1 .34.12c.32.41.67.84 1 1.3l1.1 1.4 1.09 1.38 1.08 1.35a4 4 0 0 0 .37.41 2 2 0 0 0 .27.2.8.8 0 0 0 .23.08l.25.05a.3.3 0 0 1 .19.09.4.4 0 0 1 .1.16.34.34 0 0 1 0 .17c0 .05 0 .08-.13.09H659q-.39 0-.72.06c-.22 0-.44 0-.67.06a.28.28 0 0 1-.21-.08.6.6 0 0 1-.13-.18.3.3 0 0 1 0-.16.15.15 0 0 1 .13-.09 1 1 0 0 0 .4-.12s0-.11 0-.23l-.07-.14a2 2 0 0 0-.15-.22c-.06-.1-.16-.23-.28-.39l-.44-.57a.35.35 0 0 0-.13-.1.6.6 0 0 0-.21 0Zm1.4-.62h.1l-1.71-2.23v2.33h.06Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3472"
            d="M659.88 319q-.12-.3-.24-.54a1.5 1.5 0 0 0-.26-.37 1 1 0 0 0-.33-.22 1.2 1.2 0 0 0-.46-.08.28.28 0 0 1-.2-.08.7.7 0 0 1-.11-.16.33.33 0 0 1 0-.17c0-.06.06-.08.14-.09h2.71a4.7 4.7 0 0 1 1.57.23 5.47 5.47 0 0 1 2.6 1.76 4.7 4.7 0 0 1 .79 1.37 3.7 3.7 0 0 1 .27 1.43 1.8 1.8 0 0 1-.35 1 1.9 1.9 0 0 1-.86.63 3.9 3.9 0 0 1-1.28.22 8 8 0 0 1-.86 0H661a.32.32 0 0 1-.22-.08.7.7 0 0 1-.13-.18.3.3 0 0 1 0-.16.15.15 0 0 1 .14-.09l.34-.08a.44.44 0 0 0 .18-.13.32.32 0 0 0 .05-.22 1.8 1.8 0 0 0-.09-.37Zm2.36 3a8 8 0 0 0 .31.74 1.2 1.2 0 0 0 .29.38.73.73 0 0 0 .37.15 5 5 0 0 0 .6 0 1.35 1.35 0 0 0 .67-.18 1 1 0 0 0 .39-.52 2 2 0 0 0 .07-.83 4.5 4.5 0 0 0-.28-1.14 6.4 6.4 0 0 0-.59-1.2 3.4 3.4 0 0 0-.77-.86 2.9 2.9 0 0 0-1-.51 4.2 4.2 0 0 0-1.33-.16.3.3 0 0 0-.18 0s0 .09 0 .19Z"
            className="Guatemala_svg__cls-29"
          />
        </g>
        <g id="Guatemala_svg__text6835-1-2-3-4-7-5-4">
          <path
            id="Guatemala_svg__path3475"
            d="M623.5 347.62a1.1 1.1 0 0 0 .26.43 1.7 1.7 0 0 0 .37.28 3 3 0 0 0 .46.19l.49.16.85.32a4.2 4.2 0 0 1 .79.4 3 3 0 0 1 .69.59 2.6 2.6 0 0 1 .48.86 1.6 1.6 0 0 1 .09.89 1.3 1.3 0 0 1-.35.63 1.7 1.7 0 0 1-.64.39 3.4 3.4 0 0 1-.8.16 3 3 0 0 1-.64 0 2.7 2.7 0 0 1-.7-.2h-.16a.12.12 0 0 0-.07.05s0 .08 0 .17a.14.14 0 0 1-.08.13.24.24 0 0 1-.15 0 .25.25 0 0 1-.16 0 .24.24 0 0 1-.11-.13c0-.15-.06-.3-.1-.46s-.09-.32-.14-.48l-.18-.48c-.07-.16-.14-.33-.23-.51a.17.17 0 0 1 0-.18.22.22 0 0 1 .14-.08.4.4 0 0 1 .18 0 .23.23 0 0 1 .15.12 4 4 0 0 0 .44.69 2.7 2.7 0 0 0 .53.48 2.1 2.1 0 0 0 .58.29 1.5 1.5 0 0 0 .62.07 1.2 1.2 0 0 0 .43-.1.76.76 0 0 0 .3-.22.63.63 0 0 0 .15-.32.9.9 0 0 0-.05-.44 1.5 1.5 0 0 0-.33-.55 2.4 2.4 0 0 0-.48-.38 3 3 0 0 0-.55-.27l-.58-.22-.71-.24a4 4 0 0 1-.72-.34 2.6 2.6 0 0 1-.59-.51 2.1 2.1 0 0 1-.43-.78 1.53 1.53 0 0 1-.09-.82 1.17 1.17 0 0 1 .33-.57 1.7 1.7 0 0 1 .61-.35 3.4 3.4 0 0 1 .79-.15 2.4 2.4 0 0 1 .47 0 3.4 3.4 0 0 1 .46.08h.21a.3.3 0 0 0 .14 0h.05a.14.14 0 0 1 .09-.11h.3a.16.16 0 0 1 .11.14 3 3 0 0 0 0 .33c0 .11.07.22.11.36s.12.31.18.44.13.28.21.47 0 .1 0 .13a.24.24 0 0 1-.13.07.35.35 0 0 1-.17 0 .19.19 0 0 1-.14-.1 6 6 0 0 0-.44-.67 1.7 1.7 0 0 0-.39-.37 1.1 1.1 0 0 0-.43-.16 2 2 0 0 0-.56 0 1.2 1.2 0 0 0-.39.09.7.7 0 0 0-.28.19.6.6 0 0 0-.12.29.8.8 0 0 0 0 .39Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3477"
            d="M631.52 345.65a.5.5 0 0 1 .18 0 .28.28 0 0 1 .21.25s0 .09 0 .15l.06.18v.15c.05.15.1.29.16.43s.1.25.14.37 0 .11 0 .14a.3.3 0 0 1-.15.05.35.35 0 0 1-.17 0 .33.33 0 0 1-.15-.14 5 5 0 0 0-.3-.53.84.84 0 0 0-.26-.27.64.64 0 0 0-.32-.1h-1.46c-.09 0-.14 0-.16.06s0 .08 0 .15a3 3 0 0 0 .11.35l.56 1.66v.1a3.6 3.6 0 0 0 .64-.09.6.6 0 0 0 .29-.13.3.3 0 0 0 .06-.24 3 3 0 0 0-.07-.4.14.14 0 0 1 .06-.15.3.3 0 0 1 .15-.05.3.3 0 0 1 .18 0 .22.22 0 0 1 .13.15 3 3 0 0 1 .08.27 2 2 0 0 1 .07.25 2 2 0 0 0 .06.24c0 .08 0 .17.09.29a3 3 0 0 0 .2.51c0 .07.07.16.11.27a2 2 0 0 1 .11.31.11.11 0 0 1 0 .15.3.3 0 0 1-.15.05.4.4 0 0 1-.18 0 .27.27 0 0 1-.15-.13 4 4 0 0 0-.21-.42.64.64 0 0 0-.21-.22.9.9 0 0 0-.37-.09 5 5 0 0 0-.66 0s0 0 0 .06l.42 1.25a7 7 0 0 0 .26.66.76.76 0 0 0 .26.33.86.86 0 0 0 .39.11 5 5 0 0 0 .68 0l.41-.05a1 1 0 0 0 .25-.07 1.2 1.2 0 0 0 .25-.16.7.7 0 0 0 .15-.19.9.9 0 0 0 0-.29 2.3 2.3 0 0 0-.06-.46.14.14 0 0 1 .06-.15.3.3 0 0 1 .16-.05.4.4 0 0 1 .2 0 .18.18 0 0 1 .14.14l.17.77a6 6 0 0 0 .2.69.14.14 0 0 1 0 .07.1.1 0 0 1 0 .08.3.3 0 0 1-.08.07.26.26 0 0 1-.14 0l-3.39.25c-.23 0-.46 0-.68.06l-.6.08a.23.23 0 0 1-.21-.07.45.45 0 0 1-.11-.17.3.3 0 0 1 0-.16.13.13 0 0 1 .13-.1 2 2 0 0 0 .33-.1.5.5 0 0 0 .19-.15.34.34 0 0 0 .05-.23 1.2 1.2 0 0 0-.07-.36l-1.24-3.66a2.6 2.6 0 0 0-.15-.39 1.6 1.6 0 0 0-.18-.35.9.9 0 0 0-.21-.25.6.6 0 0 0-.26-.12h-.43a.19.19 0 0 1-.18-.07.4.4 0 0 1-.1-.15.33.33 0 0 1 0-.17c0-.06.06-.09.13-.1h1.17Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3479"
            d="M636.54 350.6a2.3 2.3 0 0 0 .21.48 1 1 0 0 0 .27.26 1.3 1.3 0 0 0 .35.12 3 3 0 0 0 .46 0 .24.24 0 0 1 .2.07.4.4 0 0 1 .1.18.3.3 0 0 1 0 .17s-.07.08-.16.09h-.88a5 5 0 0 0-.7 0 5 5 0 0 0-.68.08 5 5 0 0 1-.67.08.22.22 0 0 1-.19-.07.4.4 0 0 1-.11-.16.3.3 0 0 1 0-.16.13.13 0 0 1 .11-.11 2 2 0 0 0 .39-.1.4.4 0 0 0 .19-.15.37.37 0 0 0 0-.24 2 2 0 0 0-.11-.43l-1.18-3.55a3 3 0 0 0-.15-.41 2 2 0 0 0-.18-.36.9.9 0 0 0-.21-.26.6.6 0 0 0-.26-.12h-.44a.19.19 0 0 1-.18-.07.4.4 0 0 1-.11-.16.25.25 0 0 1 0-.17.15.15 0 0 1 .14-.09h1.26l.84-.09c.25 0 .48-.06.69-.07a3.5 3.5 0 0 1 .79 0 2.3 2.3 0 0 1 .77.25 2.3 2.3 0 0 1 .65.51 2.4 2.4 0 0 1 .45.8 1.8 1.8 0 0 1 .09.94 1.14 1.14 0 0 1-.4.65 2 2 0 0 1-.76.38 5 5 0 0 1-1 .2h-.07a.3.3 0 0 0 0 .09Zm-.69-2.06h.15a3.5 3.5 0 0 0 .71-.11.7.7 0 0 0 .38-.25.63.63 0 0 0 .11-.42 2.3 2.3 0 0 0-.13-.64 2 2 0 0 0-.58-1 1.3 1.3 0 0 0-1-.22q-.33 0-.39.09t0 .33Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3481"
            d="M643.22 350.26a3 3 0 0 0 .17.46.6.6 0 0 0 .18.23.8.8 0 0 0 .28.1h.47a.23.23 0 0 1 .19.09.33.33 0 0 1 .11.16.25.25 0 0 1 0 .17.17.17 0 0 1-.15.09 6 6 0 0 1-.74 0 5 5 0 0 0-.68 0 6 6 0 0 0-.71.08c-.25 0-.48.06-.69.08a.25.25 0 0 1-.19-.07.4.4 0 0 1-.1-.16.25.25 0 0 1 0-.17c0-.06 0-.09.11-.11a3 3 0 0 0 .42-.09.46.46 0 0 0 .2-.13.3.3 0 0 0 0-.24 2.3 2.3 0 0 0-.12-.47l-1.38-4.22c-.07-.2-.15-.32-.26-.36a1.5 1.5 0 0 0-.54 0 2.6 2.6 0 0 0-.52.08.5.5 0 0 0-.28.19.73.73 0 0 0-.1.36 3.6 3.6 0 0 0 0 .6q0 .12-.06.15a.34.34 0 0 1-.16 0 .6.6 0 0 1-.17-.05.24.24 0 0 1-.12-.14c-.06-.24-.12-.48-.19-.71a6 6 0 0 0-.28-.71c0-.13 0-.21.1-.25a.9.9 0 0 1 .33-.07l5-.31a1.5 1.5 0 0 1 .36 0 .3.3 0 0 1 .24.22 2 2 0 0 0 .06.28c0 .1.06.22.1.36l.15.41.16.39a.14.14 0 0 1 0 .15.23.23 0 0 1-.14.07.6.6 0 0 1-.18 0 .24.24 0 0 1-.15-.13 4 4 0 0 0-.34-.6 1.4 1.4 0 0 0-.34-.34 1.3 1.3 0 0 0-.4-.15 3 3 0 0 0-.52 0 1.3 1.3 0 0 0-.52.1c-.08.06-.09.18 0 .37Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3483"
            d="M645.73 346.31a3 3 0 0 0-.19-.48.9.9 0 0 0-.21-.3.64.64 0 0 0-.29-.17 1.7 1.7 0 0 0-.44-.05.22.22 0 0 1-.19-.08.33.33 0 0 1-.1-.17.3.3 0 0 1 0-.16.13.13 0 0 1 .14-.09h2.81a.23.23 0 0 1 .2.07.7.7 0 0 1 .09.17.5.5 0 0 1 0 .17.13.13 0 0 1-.13.09 1.2 1.2 0 0 0-.4.1.33.33 0 0 0-.18.17.5.5 0 0 0 0 .3 3.5 3.5 0 0 0 .14.52l1.21 3.73a2 2 0 0 0 .18.46.54.54 0 0 0 .18.23.7.7 0 0 0 .28.1 4 4 0 0 0 .46.05.25.25 0 0 1 .19.09.4.4 0 0 1 .11.16.3.3 0 0 1 0 .16.16.16 0 0 1-.15.1H648a6 6 0 0 0-.72.08 6 6 0 0 1-.68.07.24.24 0 0 1-.2-.07.5.5 0 0 1-.1-.16.3.3 0 0 1 0-.17.13.13 0 0 1 .12-.1 2 2 0 0 0 .42-.1.3.3 0 0 0 .2-.13.28.28 0 0 0 0-.23 2 2 0 0 0-.12-.47Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3485"
            d="M652.64 344.39a.5.5 0 0 1 .17 0 .28.28 0 0 1 .21.25 1 1 0 0 0 0 .15c0 .06 0 .12.05.18v.16l.15.42.14.37c0 .07 0 .12-.05.14a.3.3 0 0 1-.15.05.4.4 0 0 1-.16 0 .27.27 0 0 1-.15-.15c-.12-.23-.22-.4-.3-.53a.8.8 0 0 0-.25-.27.64.64 0 0 0-.32-.1 4 4 0 0 0-.51 0h-.96a.4.4 0 0 0-.17.05.18.18 0 0 0 0 .15 2 2 0 0 0 .09.35l.54 1.67v.09a4 4 0 0 0 .65-.07.56.56 0 0 0 .29-.13.3.3 0 0 0 .07-.23 2 2 0 0 0-.06-.4.14.14 0 0 1 .06-.15.3.3 0 0 1 .16-.05.3.3 0 0 1 .18 0 .21.21 0 0 1 .12.14c0 .1.06.19.08.27l.06.26a2 2 0 0 1 0 .24l.09.29a5 5 0 0 0 .19.51c0 .07.07.16.11.27s.08.21.11.31a.12.12 0 0 1 0 .15.4.4 0 0 1-.15.05.6.6 0 0 1-.18 0 .33.33 0 0 1-.15-.14 3 3 0 0 0-.2-.42.56.56 0 0 0-.21-.22.9.9 0 0 0-.37-.1h-.69a.3.3 0 0 0 0 .07l.4 1.25a5 5 0 0 0 .25.66.67.67 0 0 0 .64.45h1.1l.25-.06a1.2 1.2 0 0 0 .25-.16.5.5 0 0 0 .16-.19.7.7 0 0 0 0-.28 3 3 0 0 0 0-.46.14.14 0 0 1 .06-.15.4.4 0 0 1 .16-.05.5.5 0 0 1 .2 0 .21.21 0 0 1 .14.15c.05.28.11.53.15.76a4.6 4.6 0 0 0 .2.7s0 0 0 .07a.13.13 0 0 1 0 .08.3.3 0 0 1-.08.07h-.15l-3.4.17c-.24 0-.47 0-.69.06l-.61.07a.24.24 0 0 1-.2-.08.4.4 0 0 1 .04-.08.3.3 0 0 1 0-.16c0-.05.06-.08.13-.1l.34-.09a.5.5 0 0 0 .19-.15.4.4 0 0 0 .06-.23 1.1 1.1 0 0 0-.07-.35l-1.17-3.67c0-.13-.09-.26-.14-.39a2.3 2.3 0 0 0-.17-.35 1.1 1.1 0 0 0-.21-.25.57.57 0 0 0-.27-.13h-.43a.22.22 0 0 1-.18-.08.4.4 0 0 1-.09-.15.33.33 0 0 1 0-.17c0-.06.06-.09.14-.1h1.17Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3487"
            d="M655 345.55a2 2 0 0 0-.17-.42.6.6 0 0 0-.18-.21.7.7 0 0 0-.25-.09 2 2 0 0 0-.39 0 .24.24 0 0 1-.19-.08.36.36 0 0 1-.11-.17.25.25 0 0 1 0-.17.15.15 0 0 1 .15-.08h1.54a4 4 0 0 1 .37 0 .26.26 0 0 1 .23.12l3.31 4.43h-.06l.46-4.62c0-.06.08-.11.25-.13l.57-.07h1.12a.21.21 0 0 1 .19.07.4.4 0 0 1 .09.16.3.3 0 0 1 0 .16.17.17 0 0 1-.12.11 2.3 2.3 0 0 0-.44.12.28.28 0 0 0-.18.15.8.8 0 0 0 0 .34c0 .15.1.38.2.69l1.1 3.52a2.5 2.5 0 0 0 .18.46.5.5 0 0 0 .18.22.66.66 0 0 0 .28.11 4 4 0 0 0 .46.05.28.28 0 0 1 .19.09.4.4 0 0 1 .1.16.25.25 0 0 1 0 .17.18.18 0 0 1-.16.09 6 6 0 0 1-.74 0h-.68a6 6 0 0 0-.72.06 6 6 0 0 1-.69.07.22.22 0 0 1-.19-.07.35.35 0 0 1-.1-.16.33.33 0 0 1 0-.17.14.14 0 0 1 .12-.1l.43-.09a.37.37 0 0 0 .2-.13.4.4 0 0 0 0-.24 3 3 0 0 0-.11-.46l-1.11-3.53-.34 3.29v1.54a.1.1 0 0 1-.07.1h-.28a.3.3 0 0 1-.13-.09l-3.57-4.7 1.11 3.54a2.3 2.3 0 0 0 .21.5.74.74 0 0 0 .22.24.7.7 0 0 0 .26.1h.38a.26.26 0 0 1 .17.09 1 1 0 0 1 .09.16.4.4 0 0 1 0 .16c0 .06-.06.09-.14.09a4 4 0 0 1-.49 0 5 5 0 0 0-.58 0 5 5 0 0 0-.61.07H656a.24.24 0 0 1-.18-.07.6.6 0 0 1-.1-.16.25.25 0 0 1 0-.17c0-.05.05-.08.11-.1a3 3 0 0 0 .38-.08.3.3 0 0 0 .19-.11.4.4 0 0 0 0-.26 2.6 2.6 0 0 0-.12-.5Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3489"
            d="M665.51 346.57a.3.3 0 0 0 .06.12s0 0 .11 0h.18a4 4 0 0 0 .53-.07.9.9 0 0 0 .36-.19.67.67 0 0 0 .16-.36 1.4 1.4 0 0 0-.09-.6 2 2 0 0 0-.23-.53 1.3 1.3 0 0 0-.33-.33 1.2 1.2 0 0 0-.41-.18 1.8 1.8 0 0 0-.53 0h-.4v.1c0 .1 0 .1.05.19Zm.59 1.91a7 7 0 0 0 .27.8 1.4 1.4 0 0 0 .24.4.44.44 0 0 0 .29.14 2 2 0 0 0 .47 0c.41 0 .66-.12.76-.31a1.27 1.27 0 0 0 0-.9 2.2 2.2 0 0 0-.34-.71 1.7 1.7 0 0 0-.45-.4 1.5 1.5 0 0 0-.55-.19 3.3 3.3 0 0 0-.63 0h-.33a.5.5 0 0 0 .05.25Zm-2.1-2.88c0-.13-.08-.26-.13-.39a3 3 0 0 0-.17-.36 1.1 1.1 0 0 0-.21-.26.6.6 0 0 0-.26-.13h-.43a.19.19 0 0 1-.18-.08.5.5 0 0 1-.1-.16.22.22 0 0 1 0-.16c0-.06.06-.09.14-.09h3.04a3.4 3.4 0 0 1 .57.08 3 3 0 0 1 .57.16 2.4 2.4 0 0 1 .52.29 2 2 0 0 1 .43.43 1.9 1.9 0 0 1 .29.58 1.3 1.3 0 0 1 .07.56 1 1 0 0 1-.14.42 1 1 0 0 1-.29.33 2 2 0 0 1-.39.22 3.6 3.6 0 0 1 1.39.59 2.16 2.16 0 0 1 .81 1.18 1.6 1.6 0 0 1 .07.87 1 1 0 0 1-.33.56 1.36 1.36 0 0 1-.61.31 4.6 4.6 0 0 1-.77.11h-2.83l-.34.05a.26.26 0 0 1-.21-.07.4.4 0 0 1-.1-.18.3.3 0 0 1 0-.16c0-.06.05-.09.12-.1a2 2 0 0 0 .35-.08.35.35 0 0 0 .26-.36 1.6 1.6 0 0 0-.07-.37Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3491"
            d="M670 345.39c0-.13-.08-.27-.13-.4a1.6 1.6 0 0 0-.17-.35 1 1 0 0 0-.2-.26.7.7 0 0 0-.26-.14h-.44a.22.22 0 0 1-.18-.08.4.4 0 0 1-.1-.16.22.22 0 0 1 0-.16c0-.06.06-.09.15-.09h2.82a3.3 3.3 0 0 1 .81.07 2.5 2.5 0 0 1 .78.29 2.6 2.6 0 0 1 .66.54 2.2 2.2 0 0 1 .43.81 1.9 1.9 0 0 1 .08.67 1 1 0 0 1-.18.5 1.14 1.14 0 0 1-.38.34 2 2 0 0 1-.52.21 3.3 3.3 0 0 1 .62.31 3 3 0 0 1 .47.36 3 3 0 0 1 .34.38 4 4 0 0 1 .25.39 4 4 0 0 1 .21.39l.2.38a.8.8 0 0 0 .21.29.4.4 0 0 0 .17.07.23.23 0 0 0 .14-.08.5.5 0 0 0 0-.24.17.17 0 0 1 .05-.15.18.18 0 0 1 .13 0 .4.4 0 0 1 .22.07.26.26 0 0 1 .14.19 1 1 0 0 1 0 .43.7.7 0 0 1-.19.32.8.8 0 0 1-.31.19 1.2 1.2 0 0 1-.38.07h-.33a1.1 1.1 0 0 1-.31-.1 1.4 1.4 0 0 1-.29-.18.9.9 0 0 1-.22-.25 3 3 0 0 1-.22-.36l-.22-.38-.2-.37-.19-.34a2.6 2.6 0 0 0-.65-.8 1.14 1.14 0 0 0-.75-.23.4.4 0 0 0-.19.05s0 .1 0 .2l.38 1.28a1.8 1.8 0 0 0 .21.49.73.73 0 0 0 .25.27 1 1 0 0 0 .33.13h.43a.22.22 0 0 1 .2.08.4.4 0 0 1 .1.17.2.2 0 0 1 0 .17.17.17 0 0 1-.15.09h-1.55a5 5 0 0 0-.69.05c-.23 0-.45.05-.68.06a.22.22 0 0 1-.19-.08.45.45 0 0 1-.11-.17.4.4 0 0 1 0-.16c0-.05 0-.08.12-.1a2 2 0 0 0 .4-.08.35.35 0 0 0 .19-.13.38.38 0 0 0 0-.25 3 3 0 0 0-.1-.43Zm1.57 1.1c0 .12.08.18.12.19a1.5 1.5 0 0 0 .4 0 2.4 2.4 0 0 0 .56-.08.54.54 0 0 0 .3-.22.7.7 0 0 0 .07-.4 3 3 0 0 0-.12-.62 2.8 2.8 0 0 0-.24-.59 1.2 1.2 0 0 0-.3-.36 1.1 1.1 0 0 0-.4-.19 2.3 2.3 0 0 0-.56-.05c-.22 0-.34 0-.37.07s-.06.17 0 .34Z"
            className="Guatemala_svg__cls-29"
          />
          <path
            id="Guatemala_svg__path3493"
            d="M679.79 343.45a.5.5 0 0 1 .18 0 .31.31 0 0 1 .21.26 1 1 0 0 0 0 .15 1 1 0 0 1 0 .18v.15c0 .15.09.29.13.43s.09.25.13.37 0 .11 0 .14a.34.34 0 0 1-.16 0h-.16a.27.27 0 0 1-.12-.13 3.5 3.5 0 0 0-.28-.53 1 1 0 0 0-.24-.28.64.64 0 0 0-.32-.11 3.5 3.5 0 0 0-.51 0h-.97c-.09 0-.15 0-.17.05s0 .07 0 .15 0 .19.09.35l.48 1.66a.2.2 0 0 0 0 .1 6 6 0 0 0 .65-.05.7.7 0 0 0 .31-.12.33.33 0 0 0 .07-.23v-.4a.12.12 0 0 1 .06-.14.33.33 0 0 1 .16-.05.4.4 0 0 1 .18.05.24.24 0 0 1 .12.14c0 .1.05.19.07.28s0 .17 0 .25a2 2 0 0 0 .05.24c0 .07 0 .17.07.29s.12.37.17.51a2 2 0 0 1 .11.27c0 .11.07.21.09.31s0 .12 0 .15a.3.3 0 0 1-.15 0h-.18a.34.34 0 0 1-.15-.14 4 4 0 0 0-.18-.43.8.8 0 0 0-.21-.23 1 1 0 0 0-.37-.1 4.4 4.4 0 0 0-.67 0s0 0 0 .06l.36 1.26a6 6 0 0 0 .23.66.66.66 0 0 0 .63.47h1.11a1 1 0 0 0 .25-.05 1.5 1.5 0 0 0 .26-.15.5.5 0 0 0 .17-.18.7.7 0 0 0 .06-.28 3 3 0 0 0 0-.46.14.14 0 0 1 .07-.14.33.33 0 0 1 .17 0 .5.5 0 0 1 .2 0 .23.23 0 0 1 .13.16c.05.27.09.53.13.75a4.5 4.5 0 0 0 .17.7s0 0 0 .07v.08a.16.16 0 0 1-.08.06.3.3 0 0 1-.15 0l-3.44.06h-.69l-.62.05a.28.28 0 0 1-.2-.08.6.6 0 0 1-.11-.18.4.4 0 0 1 0-.15.16.16 0 0 1 .14-.1 2 2 0 0 0 .34-.08.43.43 0 0 0 .2-.14.36.36 0 0 0 .07-.22 1.5 1.5 0 0 0-.06-.36l-1.05-3.67c0-.13-.08-.26-.12-.39a3 3 0 0 0-.16-.36 1 1 0 0 0-.21-.25.6.6 0 0 0-.26-.14h-.43a.25.25 0 0 1-.18-.08.4.4 0 0 1-.09-.16.25.25 0 0 1 0-.17c0-.06.06-.08.14-.09h1.18Z"
            className="Guatemala_svg__cls-29"
          />
        </g>
      </g>
      <path
        d="M605.58 292.31c-4.44 0-5.58 4.27-5.52 7 .16 7.08 5.52 7.42 5.52 7.42l7.15-.39 3.43-14.58Z"
        className="Guatemala_svg__cls-21"
      />
      <path
        d="M605.58 292.26c-4.44 0-5.58 4.27-5.52 7 .16 7.08 5.52 7.42 5.52 7.42l7.15-.4 3.43-14.58Z"
        style={{
          strokeWidth: ".75px",
          stroke: "#999270",
          fill: "url(#Guatemala_svg__radial-gradient-5)",
        }}
      />
      <path
        d="m605.58 292.31 10.58-.58 1.09 10.89-9.25.38s3-8.7-2.42-10.69Z"
        className="Guatemala_svg__cls-21"
      />
      <path
        d="m605.58 292.31 10.58-.58 1.09 10.89-9.25.38s3-8.7-2.42-10.69Z"
        style={{
          strokeWidth: ".75px",
          stroke: "#999270",
          fill: "url(#Guatemala_svg__linear-gradient-3)",
        }}
      />
      <path
        d="M664 304c8-1.28 7.91-12.06 2.43-16.39-18.58-.68-43-.17-60.68 1.15 2 0 7.59.46 8.21 7.81a9.8 9.8 0 0 1-3.67 8.37 8.3 8.3 0 0 1-4.68 1.75 30 30 0 0 0 4.32-.1c.88-.06 3.05-.32 3.66-.4a328 328 0 0 1 50.29-2.19z"
        className="Guatemala_svg__cls-21"
      />
      <path
        d="M664 304c8-1.28 7.91-12.06 2.43-16.39-18.58-.68-43-.17-60.68 1.15 2 0 7.59.46 8.21 7.81a9.8 9.8 0 0 1-3.67 8.37 8.3 8.3 0 0 1-4.68 1.75 30 30 0 0 0 4.32-.1c.88-.06 3.05-.32 3.66-.4a328 328 0 0 1 50.29-2.19z"
        style={{
          strokeWidth: ".75px",
          stroke: "#999270",
          fill: "url(#Guatemala_svg__linear-gradient-4)",
        }}
      />
      <path
        id="Guatemala_svg__path14573-4"
        d="m639.62 263.4-4.52 5.37c-1.44 6.28 2.13 11 9.17 15.12 5.69 3.54 16.24 2.92 19.57.07L647.4 269.8Z"
        style={{
          fill: "#a50a0a",
          stroke: "#4c0505",
        }}
      />
      <path
        id="Guatemala_svg__path14573-4-2"
        d="m639.62 263.4-4.52 5.37c-1.44 6.28 2.13 11 9.17 15.12 5.69 3.54 16.24 4.42 19.57 1.57L647.4 269.8Z"
        data-name="path14573-4"
        style={{
          stroke: "#4c0505",
          fill: "url(#Guatemala_svg__radial-gradient-6)",
        }}
      />
      <path
        d="M672.92 425c-.81-1-1.62-1.94-2.44-2.9-10.26 13.54-23.07 25.68-39.1 34.25 18.07-5.08 29.9-18.25 41.54-31.35ZM654.41 467.85c6.38-12.92 14.06-25.83 21.82-38.74-.85-1.05-1.7-2.11-2.57-3.16-8.81 14.61-19.15 31.98-19.25 41.9ZM704.43 330.61a75 75 0 0 0-5.16-15.61c-3.19-6.51-7.94-13-14.18-20.14-11.25-12.2-22.5-24-38.27-32.66l.08-.17.09.06h.2l-.19-.24h.56l-.3-.31h.52l-.42-.48h.55l-.39-.39h.59l-.33-.44h.48l-.45-.47.66-.13-.53-.33.57-.11-.48-.33.7-.23-.62-.36.78-.35-.75-.55.8-.27-.87-.35.73-.39-.86-.15.58-.68h-.84l.38-.71-.8.06v-.07l.44-.54-.81.15.36-.73-.78.39.22-.85-.75.52.11-.87-.69.6.07-.83-.61.64.05-.86-.6.73-.06-.86-.48.67-.25-.81-.4.81-.2-.86-.38.86-.19-.86-.33.86-.24-.81-.26.81-.19-.71-.29.86-.27-.81-.29.92-.35-.82-.15 1-.42-.8-.05 1-.46-.72v.94l-.49-.68v.83l-.52-.44.26.89-.63-.36.24.78-.52-.29.18.75-.47-.15.25.57v.07l-.36-.06.21.43a10.7 10.7 0 0 0-.42 4.21c0 .27.71.55.82.73a1.18 1.18 0 0 1 .27.79c-1.91 3.09-3.61 5.48-3.81 9 1.54-1.94 3.13-4.31 5.35-4.31-1.1 1.84-1.62 7.4-.39 8.24l.93-1.62a8 8 0 0 0 .46 2.78 18 18 0 0 1 1.16-2.08 8.4 8.4 0 0 0 .84 4 5.46 5.46 0 0 1 1.94-1.89 8.5 8.5 0 0 0-.47 3.25 20 20 0 0 1 2.32-3c-.45 1.91 0 3.32.5 5.42.48-2.48.67-2.75 1.82-3.8.12 2.29-.31 4.36.17 5.63 1-2 1.75-1.72 2.38-3-.13 1.89-.51 3.95 0 5.64a5.38 5.38 0 0 1 2.33-3.21c.07 2.64.68 2.21-1 4.44.69.32 3-.45 4.32-1-.62 1.42-.44 2.52-1 3.52a14.2 14.2 0 0 0 3.47-2.78c-.5 1.62-1.82 3.24-1.16 4.86a3.87 3.87 0 0 1 2.69-3c-.18.85-.3 2.61-1.53 3.46 2.93.3 4.09-1.68 5.56-3.24-.19 1.62-.13 2.93 1.86 5.22-.4-2.92.29-2.32 1.61-4.07.64 2.58 1.37 5.31 4.06 7.21-1.12-2.72-1.32-4.12-.69-5.83.34 1.77 2 3.9 2.42 5.57a16.3 16.3 0 0 1 1.15-4.86 24.2 24.2 0 0 1 1.62 6.71 20 20 0 0 0 1.39-3.7c23.66 24.72 28.93 56.14 3 89.34 9.31-6.29 11.71-16.26 16.89-24.76-5 11.13-7.3 23.66-16.2 32.87 8.62-7.44 10.44-14.25 15.62-21.41-5.52 15-13.16 31.4-23.75 46.43 1.88 2.05 3.76 4.13 5.65 6.22 1.64-2.73 3.29-5.47 4.91-8.21 11.14-18.56 29.47-45 20.18-85.33Z"
        className="Guatemala_svg__cls-35"
      />
      <path
        id="Guatemala_svg__path14571-4"
        d="M636.45 258.46a4.28 4.28 0 0 0-2.53 1.73 15 15 0 0 0 3 .32c.31 0 .42-.42.35-.7l-.16-1.15c0-.26-.72-.24-1-.1Z"
        style={{
          fill: "#eac102",
          stroke: "#a08307",
        }}
      />
      <path
        id="Guatemala_svg__path1314-2-0-8"
        d="M641.88 259.61a1 1 0 1 0-.93-1 1 1 0 0 0 .93 1Z"
        style={{
          stroke: "#000",
          strokeWidth: ".45px",
        }}
      />
      <g id="Guatemala_svg__g9834">
        <path
          id="Guatemala_svg__path15026-1"
          d="M654.46 467.51c.56-1.79 1.17-3.57 1.83-5.32s1.39-3.49 2.14-5.21c1.51-3.44 3.19-6.8 5-10.11s3.65-6.56 5.59-9.78c1-1.6 1.95-3.2 2.94-4.79l3-4.76-2.9 4.82q-1.46 2.42-2.88 4.84c-1.9 3.23-3.77 6.48-5.53 9.79s-3.46 6.65-5 10.07c-.77 1.71-1.51 3.43-2.2 5.17s-1.39 3.5-1.99 5.28"
          className="Guatemala_svg__cls-38"
        />
        <path
          id="Guatemala_svg__path15028-4"
          d="M631.88 456.16c2-.88 3.94-1.83 5.85-2.85s3.8-2.09 5.64-3.24a85.5 85.5 0 0 0 10.46-7.69 100 100 0 0 0 9.31-9.07c1.46-1.6 2.87-3.25 4.26-4.92s2.74-3.36 4.07-5.07c-1.29 1.74-2.62 3.46-4 5.15s-2.77 3.35-4.21 5a99 99 0 0 1-9.29 9.14 82 82 0 0 1-10.53 7.66c-1.85 1.14-3.75 2.2-5.68 3.19s-3.88 1.86-5.88 2.7"
          className="Guatemala_svg__cls-38"
        />
      </g>
      <g id="Guatemala_svg__g9838">
        <path
          id="Guatemala_svg__path15030-6"
          d="M676.56 421c.93-1.29 1.78-2.62 2.64-3.95s1.7-2.67 2.52-4q2.46-4.06 4.74-8.23c1.54-2.77 3-5.57 4.5-8.37l4.41-8.42-2.1 4.27-2.13 4.24q-2.16 4.25-4.45 8.41c-1.54 2.77-3.12 5.52-4.8 8.21s-3.42 5.3-5.33 7.84"
          className="Guatemala_svg__cls-38"
        />
        <path
          id="Guatemala_svg__path15032-7"
          d="M683.63 390.9a68 68 0 0 0 5.62-7.69 72 72 0 0 0 4.57-8.36 87 87 0 0 0 6-18.06c.17-.78.34-1.56.5-2.33s.24-1.57.35-2.36c.21-1.57.37-3.15.48-4.73a64 64 0 0 0 0-9.52 60 60 0 0 0-1.48-9.41 64 64 0 0 0-2.88-9.08 64 64 0 0 1 3 9.05 61 61 0 0 1 1.54 9.42 66 66 0 0 1 .08 9.55c-.1 1.59-.26 3.18-.46 4.75-.1.8-.21 1.58-.35 2.37s-.31 1.56-.48 2.34a87.2 87.2 0 0 1-6.12 18.08 74 74 0 0 1-4.62 8.36 69 69 0 0 1-5.75 7.62"
          className="Guatemala_svg__cls-38"
        />
        <path
          id="Guatemala_svg__path15034-9"
          d="M681.66 384.06a42.8 42.8 0 0 0 5.87-7 62 62 0 0 0 4.55-7.94c1.34-2.75 2.52-5.56 3.63-8.41.56-1.42 1.1-2.85 1.63-4.28l.4-1.07c.13-.36.24-.72.35-1.09.23-.73.43-1.46.69-2.19a50 50 0 0 0 1-9.19 55 55 0 0 0-.68-9.23 70 70 0 0 0-2.08-9c-.87-3-1.91-5.88-3.06-8.75 1.19 2.85 2.27 5.76 3.18 8.71a70 70 0 0 1 2.13 9 56.3 56.3 0 0 1 .73 9.26 49.4 49.4 0 0 1-.94 9.24c-.27.71-.47 1.45-.7 2.18-.11.36-.23.73-.36 1.09l-.41 1.07c-.54 1.43-1.08 2.86-1.65 4.28-1.13 2.84-2.32 5.66-3.67 8.41a63 63 0 0 1-4.6 7.93 43 43 0 0 1-6.01 6.98"
          className="Guatemala_svg__cls-38"
        />
        <path
          id="Guatemala_svg__path15028-4-3"
          d="M674.68 419c1.44-2 2.86-4 4.28-6l2.14-3c.7-1 1.44-2 2.07-3 1.28-2.1 2.39-4.29 3.48-6.49s2.18-4.4 3.24-6.61c2.14-4.42 4.2-8.87 6-13.46a89.4 89.4 0 0 0 4.13-14.1c1-4.79 1.7-9.66 2.26-14.53a70 70 0 0 0 0-14.68A62.7 62.7 0 0 0 699 322.8a86.4 86.4 0 0 0-6.11-13.38 86.4 86.4 0 0 1 6.23 13.33 62.5 62.5 0 0 1 3.32 14.34 69.6 69.6 0 0 1 0 14.72c-.55 4.88-1.22 9.75-2.22 14.57a90 90 0 0 1-4.19 14.12c-1.77 4.59-3.85 9-6 13.46-1.07 2.21-2.18 4.4-3.27 6.6s-2.2 4.39-3.5 6.48c-.64 1-1.39 2-2.11 3l-2.15 3c-1.42 1.96-2.86 3.96-4.32 5.96"
          className="Guatemala_svg__cls-38"
        />
        <path
          id="Guatemala_svg__path15026-1-8"
          d="M678 422.62c2.84-4.6 5.65-9.2 8.34-13.88s5.37-9.37 7.75-14.21a98 98 0 0 0 6-15c.78-2.58 1.44-5.2 2-7.84.27-1.32.51-2.65.71-4s.39-2.67.57-4a124 124 0 0 0 1.1-16.13 88.5 88.5 0 0 0-1.47-16.08 69.5 69.5 0 0 0-4.77-15.43 65 65 0 0 0-8.34-13.82 65 65 0 0 1 8.45 13.77 69.5 69.5 0 0 1 4.82 15.44 88 88 0 0 1 1.48 16.12 125 125 0 0 1-1.06 16.17c-.18 1.34-.37 2.68-.58 4s-.45 2.67-.73 4c-.55 2.64-1.21 5.27-2 7.85a99 99 0 0 1-6 15c-2.4 4.85-5.08 9.54-7.8 14.2s-5.62 9.27-8.47 13.84"
          className="Guatemala_svg__cls-38"
        />
      </g>
      <path
        id="Guatemala_svg__path1314-2-0-8-2"
        d="M642.09 258.86a.43.43 0 0 0 0-.85.43.43 0 0 0 0 .85"
        data-name="path1314-2-0-8"
        style={{
          fill: "url(#Guatemala_svg__radial-gradient-7)",
        }}
      />
      <path
        d="m633.92 260.19 2.56-.58"
        style={{
          fill: "none",
        }}
      />
      <path
        d="M633.92 260.19a12 12 0 0 1 1.26-.36 11 11 0 0 1 1.3-.22c-.42.14-.84.25-1.27.35a11 11 0 0 1-1.29.23"
        style={{
          fill: "#a08307",
        }}
      />
      <path
        d="M644.66 265a9 9 0 0 1 1.7.13c.56.09 1.12.21 1.67.35a23 23 0 0 1 3.22 1.15 30.5 30.5 0 0 1 5.87 3.48 42.3 42.3 0 0 1 5.11 4.53c.78.83 1.54 1.67 2.28 2.54s1.43 1.77 2.11 2.68a68.275 68.275 0 0 0-4.54-5.08 45 45 0 0 0-5.11-4.46 31.8 31.8 0 0 0-5.8-3.51 22 22 0 0 0-3.17-1.2 13.6 13.6 0 0 0-3.34-.61"
        className="Guatemala_svg__cls-38"
      />
      <path
        d="M664.29 278a19.15 19.15 0 0 1 5.71 5.3 19 19 0 0 1-9.67-5.57"
        className="Guatemala_svg__cls-42"
      />
      <path
        d="M664.29 278a19 19 0 0 1 1.65 1.06 18 18 0 0 1 1.53 1.23 15 15 0 0 1 1.4 1.39 12 12 0 0 1 1.19 1.57l.16.26-.29-.06a17 17 0 0 1-2.73-.85 20 20 0 0 1-2.56-1.25 16.4 16.4 0 0 1-4.36-3.6 18 18 0 0 0 4.46 3.43 21 21 0 0 0 2.55 1.2 17 17 0 0 0 2.68.82l-.13.19a13 13 0 0 0-1.16-1.55c-.42-.49-.88-.95-1.34-1.4s-1-.87-1.48-1.28-1.03-.81-1.57-1.16"
        className="Guatemala_svg__cls-38"
      />
      <path
        d="M656.14 276.92s8.66 5.53 9.92 6a13.23 13.23 0 0 0-6-6"
        className="Guatemala_svg__cls-42"
      />
      <path
        d="m656.14 276.92 5 3 2.48 1.49c.42.24.84.49 1.26.72a12 12 0 0 0 1.26.66l-.15.17a13 13 0 0 0-1.11-1.83 15.5 15.5 0 0 0-1.37-1.66 13.4 13.4 0 0 0-1.61-1.41 11 11 0 0 0-1.85-1.11 10 10 0 0 1 1.91 1 12.4 12.4 0 0 1 1.68 1.39A15 15 0 0 1 665 281a13 13 0 0 1 1.15 1.86l.15.3-.3-.16a22 22 0 0 1-2.56-1.4c-.83-.49-1.65-1-2.47-1.51-1.61-1.04-3.23-2.09-4.83-3.17"
        className="Guatemala_svg__cls-38"
      />
      <path
        d="M659.59 274.14s4.5 1.91 5.52 5.25c0 0-8.09-3.41-9.54-5.22"
        className="Guatemala_svg__cls-42"
      />
      <path
        d="M659.59 274.14a12.4 12.4 0 0 1 1.75.87 14 14 0 0 1 1.61 1.14 8.4 8.4 0 0 1 1.37 1.43 6.1 6.1 0 0 1 .92 1.77l.08.26-.26-.11c-.83-.36-1.66-.73-2.49-1.12s-1.65-.77-2.46-1.2-1.61-.85-2.39-1.33c-.39-.24-.77-.49-1.14-.76a5.2 5.2 0 0 1-1-.92 5.5 5.5 0 0 0 1 .87c.38.25.77.48 1.16.7.79.45 1.6.87 2.42 1.27 1.64.79 3.29 1.55 5 2.26l-.17.15a5.8 5.8 0 0 0-.87-1.69 8.8 8.8 0 0 0-1.31-1.42 12 12 0 0 0-1.54-1.19 16 16 0 0 0-1.68-.98"
        className="Guatemala_svg__cls-38"
      />
      <path
        d="M652.76 277.6s8 5.7 10.32 5.7a15.76 15.76 0 0 0-6.46-5.69"
        className="Guatemala_svg__cls-42"
      />
      <path
        d="M652.76 277.6c.82.55 1.65 1.09 2.48 1.62s1.68 1 2.53 1.55 1.7 1 2.58 1.44c.44.22.89.43 1.34.61a4.5 4.5 0 0 0 1.39.36l-.11.19a14.4 14.4 0 0 0-1.28-1.74 19 19 0 0 0-1.5-1.57 16 16 0 0 0-1.69-1.36 12 12 0 0 0-1.88-1.09 12.7 12.7 0 0 1 3.69 2.32 15 15 0 0 1 1.55 1.55 15 15 0 0 1 1.33 1.76l.12.19h-.23a4.5 4.5 0 0 1-1.48-.37c-.47-.18-.92-.4-1.36-.62-.88-.46-1.74-1-2.59-1.48s-1.66-1.07-2.48-1.63-1.63-1.13-2.41-1.73"
        className="Guatemala_svg__cls-38"
      />
      <path
        d="M652.76 279.39s4.14 3.5 6.5 3.5a15.67 15.67 0 0 0-6.46-5.7"
        className="Guatemala_svg__cls-42"
      />
      <path
        d="M652.76 279.39c.5.37 1 .73 1.52 1.08s1 .69 1.58 1a14 14 0 0 0 1.65.85 9 9 0 0 0 .86.3 3.4 3.4 0 0 0 .89.14l-.11.19a13.4 13.4 0 0 0-1.28-1.74 18 18 0 0 0-1.49-1.57 16 16 0 0 0-1.7-1.36 13 13 0 0 0-1.88-1.09 12.8 12.8 0 0 1 3.7 2.32 15 15 0 0 1 1.54 1.55 15 15 0 0 1 1.33 1.76l.12.19h-.23a4.7 4.7 0 0 1-1.85-.47 15 15 0 0 1-1.67-.88c-.53-.34-1-.7-1.54-1.08s-.98-.77-1.44-1.19"
        className="Guatemala_svg__cls-38"
      />
      <path
        d="M650.68 271.52s8.64 6.13 9.66 9.46c0 0-7.81-3.59-9.26-5.39"
        className="Guatemala_svg__cls-42"
      />
      <path
        d="M650.68 271.52c1 .64 1.86 1.32 2.77 2s1.78 1.42 2.64 2.17a31 31 0 0 1 2.46 2.38 13 13 0 0 1 1.09 1.33 5.7 5.7 0 0 1 .83 1.53l.08.28-.26-.12c-.82-.38-1.62-.78-2.43-1.18s-1.6-.82-2.38-1.26-1.56-.89-2.32-1.38c-.37-.25-.74-.5-1.1-.77a5.4 5.4 0 0 1-1-.92 6 6 0 0 0 1 .86c.37.26.74.5 1.13.73.77.46 1.55.89 2.34 1.31 1.59.83 3.2 1.63 4.83 2.37l-.18.16a5.6 5.6 0 0 0-.78-1.46 15 15 0 0 0-1.07-1.32c-.76-.84-1.58-1.62-2.42-2.39s-1.71-1.5-2.59-2.22-1.73-1.41-2.64-2.1"
        className="Guatemala_svg__cls-38"
      />
      <path
        id="Guatemala_svg__path32-3-6"
        d="M602.73 444.29c-5.86 4.37-22.24 6.51-24.51 5.76 11.92-9.92 23.64-10.16 24.51-5.76Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path104-5-8"
        d="M602.68 444.26c-.87-4.4-12.54-4.13-24.43 5.73 12.52-4.13 23.06-6.48 24.43-5.73Z"
        className="Guatemala_svg__cls-4"
      />
      <path
        id="Guatemala_svg__path20-6-0-2"
        d="M686.34 449.62c6.3 5.72 25.3 9.66 28.14 8.95-12.63-12.91-26.48-14.22-28.14-8.95Z"
        className="Guatemala_svg__cls-5"
      />
      <path
        id="Guatemala_svg__path84-7-2-9"
        d="M686.48 449.55c1.64-5.28 15.33-4 28 8.94-14.24-6.02-26.31-9.71-28-8.94Z"
        className="Guatemala_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgGuatemala;
