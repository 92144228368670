import * as React from "react";
import type { SVGProps } from "react";
const SvgDelivered = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1000}
    height={1000}
    viewBox="0 0 750 750"
    {...props}
  >
    <defs>
      <clipPath id="Delivered_svg__a">
        <path d="M0 41.375h750v656.25H0Zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#Delivered_svg__a)">
      <path
        fill="#fff"
        d="M592.594 342.824V188.34c0-6.395-3.711-12.211-9.512-14.903L303.215 43.512a16.45 16.45 0 0 0-13.84 0L9.512 173.438A16.43 16.43 0 0 0 0 188.34v321.254c0 6.394 3.71 12.215 9.512 14.906l279.87 129.922a16.42 16.42 0 0 0 13.84 0l111.551-51.781c29.891 56.367 89.164 94.863 157.286 94.863C670.184 697.504 750 617.68 750 519.566c0-91.171-68.93-166.5-157.406-176.726ZM296.297 85.582l89.226 41.426-226.98 105.387-89.234-41.426 226.988-105.38Zm-20.535 517.234L41.078 493.86V223.141l100.371 46.593v92.41c0 11.344 9.2 20.536 20.535 20.536s20.536-9.2 20.536-20.535v-73.336l93.234 43.289v270.726Zm20.535-306.476-88.98-41.313 226.98-105.386 88.988 41.312-226.988 105.379Zm103.515 267.95-82.98 38.526V332.09L551.52 223.133v119.691c-88.477 10.219-157.407 85.547-157.407 176.727 0 15.449 1.989 30.441 5.7 44.738m172.247 92.132c-75.465 0-136.868-61.406-136.868-136.871 0-75.469 61.403-136.871 136.868-136.871 75.468 0 136.87 61.402 136.87 136.87 0 75.466-61.402 136.872-136.87 136.872m0 0"
      />
    </g>
    <path
      fill="#fff"
      d="m615.914 467.184-57.812 60.765-29.895-31.43c-7.848-8.218-20.828-8.535-29.047-.718-8.219 7.824-8.535 20.82-.719 29.047l44.782 47.066a20.58 20.58 0 0 0 14.879 6.379c5.62 0 11-2.305 14.882-6.379l72.696-76.406c7.816-8.223 7.492-21.219-.72-29.043-8.233-7.817-21.214-7.485-29.046.719m0 0"
    />
  </svg>
);
export default SvgDelivered;

