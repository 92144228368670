import * as React from "react";
import type { SVGProps } from "react";
const SvgChile = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Chile_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Chile_svg__Chile">
      <path
        d="M1280 720H0V0h1280Z"
        style={{
          fill: "#d52b1e",
        }}
      />
      <path d="M0 360h1280V0H0Z" className="Chile_svg__cls-2" />
      <path
        d="M360 360H0V0h360Z"
        style={{
          fill: "#0039a6",
        }}
      />
      <g id="Chile_svg__t">
        <path
          id="Chile_svg__o"
          d="m180 90-27.81 85.6L195 189.5Z"
          className="Chile_svg__cls-2"
        />
        <path
          id="Chile_svg__o-2"
          d="m180 90 27.81 85.6L165 189.5Z"
          className="Chile_svg__cls-2"
          data-name="o"
        />
      </g>
      <g id="Chile_svg__f">
        <g id="Chile_svg__t-2" data-name="t">
          <path
            id="Chile_svg__o-3"
            d="M265.6 152.19h-90v45Z"
            className="Chile_svg__cls-2"
            data-name="o"
          />
          <path
            id="Chile_svg__o-4"
            d="m265.6 152.19-72.82 52.9-26.45-36.41Z"
            className="Chile_svg__cls-2"
            data-name="o"
          />
        </g>
      </g>
      <g id="Chile_svg__t-3" data-name="t">
        <path
          id="Chile_svg__o-5"
          d="m94.4 152.19 72.82 52.9 26.45-36.41Z"
          className="Chile_svg__cls-2"
          data-name="o"
        />
        <path
          id="Chile_svg__o-6"
          d="M94.4 152.19h90v45Z"
          className="Chile_svg__cls-2"
          data-name="o"
        />
      </g>
      <g id="Chile_svg__t-4" data-name="t">
        <path
          id="Chile_svg__o-7"
          d="m232.9 252.81-27.81-85.59-42.8 13.9Z"
          className="Chile_svg__cls-2"
          data-name="o"
        />
        <path
          id="Chile_svg__o-8"
          d="m232.9 252.81-72.81-52.9 26.45-36.4Z"
          className="Chile_svg__cls-2"
          data-name="o"
        />
      </g>
      <g id="Chile_svg__f-2" data-name="f">
        <g id="Chile_svg__t-5" data-name="t">
          <path
            id="Chile_svg__o-9"
            d="m127.1 252.81 72.81-52.9-26.45-36.4Z"
            className="Chile_svg__cls-2"
            data-name="o"
          />
          <path
            id="Chile_svg__o-10"
            d="m127.1 252.81 27.81-85.59 42.8 13.9Z"
            className="Chile_svg__cls-2"
            data-name="o"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgChile;
