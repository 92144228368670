import * as React from "react";
import type { SVGProps } from "react";
const SvgRwanda = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Rwanda_svg__cls-4{fill:#e5be01}"}</style>
    </defs>
    <g id="Rwanda_svg__Rwanda">
      <path
        d="M0 0h1280v360H0z"
        style={{
          fill: "#00a1de",
        }}
      />
      <path
        d="M0 360h1280v180H0z"
        style={{
          fill: "#fad201",
        }}
      />
      <path
        d="M0 540h1280v180H0z"
        style={{
          fill: "#20603d",
        }}
      />
      <g id="Rwanda_svg__h">
        <path
          id="Rwanda_svg__q"
          d="m1202.6 188.1-80.41 4.7 76.45 25.35-78.88-16.27 67.29 44.27-72-36.13 53.54 60.18-60.18-53.54 36.13 72-44.27-67.29 16.27 78.88-25.35-76.45-4.7 80.41-1-117.1Z"
          className="Rwanda_svg__cls-4"
        />
        <path
          id="Rwanda_svg__q-2"
          d="m1202.6 188.1-80.41-4.7 76.45-25.35-78.88 16.27 67.29-44.27-72 36.13L1168.6 106l-60.18 53.54 36.13-72-44.27 67.29L1116.55 76l-25.35 76.45-4.7-80.45-1 117.1Z"
          className="Rwanda_svg__cls-4"
          data-name="q"
        />
      </g>
      <g id="Rwanda_svg__h-2" data-name="h">
        <path
          id="Rwanda_svg__q-3"
          d="m970.4 188.1 80.41 4.7-76.45 25.35 78.88-16.27L986 246.15l72-36.13-53.6 60.18 60.18-53.54-36.13 72 44.27-67.29-16.27 78.88 25.35-76.45 4.7 80.41 1-117.1Z"
          className="Rwanda_svg__cls-4"
          data-name="q"
        />
        <path
          id="Rwanda_svg__q-4"
          d="m970.4 188.1 80.41-4.7-76.45-25.35 78.88 16.27L986 130.05l72 36.13-53.6-60.18 60.18 53.54-36.13-72 44.27 67.29L1056.45 76l25.35 76.45 4.7-80.45 1 117.1Z"
          className="Rwanda_svg__cls-4"
          data-name="q"
        />
      </g>
      <circle
        cx={1086.5}
        cy={188.1}
        r={34.3}
        style={{
          fill: "#e5be01",
          stroke: "#00a1de",
          strokeWidth: "3.4px",
        }}
      />
    </g>
  </svg>
);
export default SvgRwanda;
