import * as React from "react";
import type { SVGProps } from "react";
const SvgBenin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.33h1280v720.67H0z"
      style={{
        fill: "#fcd116",
      }}
    />
    <path
      d="M0 360h1280v360.33H0z"
      style={{
        fill: "#e8112d",
      }}
    />
    <path
      d="M0-.33h512v720.67H0z"
      style={{
        fill: "#008751",
      }}
    />
  </svg>
);
export default SvgBenin;
