import * as React from "react";
import type { SVGProps } from "react";
const SvgVanuatu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Vanuatu_svg__cls-3,.Vanuatu_svg__cls-4{fill:#fdce12}.Vanuatu_svg__cls-4{stroke:#fdce12}"
        }
      </style>
    </defs>
    <g id="Vanuatu_svg__Vanuatu">
      <path
        d="M0 0h1280v360H0z"
        style={{
          fill: "#d21034",
        }}
      />
      <path
        d="M0 360h1280v360H0z"
        style={{
          fill: "#009543",
        }}
      />
      <path d="m0 0 698.29 360L0 720Z" />
      <path d="M0 295.58h1280v128.84H0z" />
      <path d="M0 51.16 599.05 360 0 668.84Z" className="Vanuatu_svg__cls-3" />
      <path d="M0 341.05h1280v37.89H0z" className="Vanuatu_svg__cls-3" />
      <path d="M0 93.8 516.36 360 0 626.2Z" />
      <path
        d="M145.87 428.87v41c35.69 0 105.73-27.34 105.73-115.39s-74.71-104.39-97.06-104.39-91.38 16-91.38 88 67.7 78.72 81 78.72 63.37-12 57.7-66c0 15.34-17.34 47.69-53.69 47.69s-59-27-59-53.69 22-62.37 60.37-62.37 66.37 34 66.37 68.37-27.01 78.06-70.04 78.06"
        className="Vanuatu_svg__cls-3"
      />
      <g id="Vanuatu_svg__frond">
        <path
          d="m114.6 375.47 2.13 1.37s16.88-38.52 62.28-65.91c-5.75 3.13-36.14 18.26-64.41 64.54Z"
          className="Vanuatu_svg__cls-4"
        />
        <path
          d="M119 368.84s-5.25-10.76-.62-15.51 2.5 12.63 2.5 12.63l2-2.87s-3.13-13.14.37-15.89 1.75 12.63 1.75 12.63l2.13-2.75s-2.25-12.75 1.13-15.13 1.25 12 1.25 12l2.5-2.88s-1.88-12.13 2.25-15 .5 11.51.5 11.51l2.75-3s-1.25-11.14 2.25-13.64.5 10.51.5 10.51l2.5-2.63s-.75-10.38 2.63-12.75.25 9.88.25 9.88l2.63-2.51s-.63-10.38 3.62-12.38-.75 9.76-.75 9.76l2.88-2.5s.88-12.14 4.5-13.26-1.62 10.76-1.62 10.76l2.62-2.13s1.38-10.38 5.38-11.63-2.5 9.5-2.5 9.5l2.63-2s2.87-10.38 6.63-11.5-3.88 9.75-3.88 9.75l2.5-1.75s4.13-9.38 7.5-10-3.75 7.76-3.75 7.76l3.13-1.88s5.38-9.75 7.25-6.75-7.5 7.38-7.5 7.38l-3.13 1.75s11.38-5.38 12.76-2.63-15.63 4.5-15.63 4.5l-2.76 1.88s11.38-3.13 10-.25-12.63 2.25-12.63 2.25l-2.63 1.88s11.38-2.75 9.88-.13-12.63 2.38-12.63 2.38l-2.75 2.25s11.13-2.88 9.5.5-14.26 3.25-14.26 3.25l-3.12 2.88s11.75-4 10.75-.88-14.39 4.8-14.39 4.8l-3 3s12.63-4.25 8.88-.38-12.88 4.88-12.88 4.88l-3.63 4.38s12.51-7.51 10.76-3.25-14 7.13-14 7.13l-2.5 3s11.25-6.51 10.13-2.88-13.13 7.5-13.13 7.5l-2 2.76s13.26-7.88 12.14-3.88-13.76 6.88-13.76 6.88l-1.63 2.5s12.26-7 12.38-3.63-14.38 6.76-14.38 6.76Z"
          className="Vanuatu_svg__cls-3"
        />
      </g>
      <g id="Vanuatu_svg__frond-2" data-name="frond">
        <path
          d="m186.66 375.47-2.13 1.37s-16.88-38.52-62.28-65.91c5.75 3.13 36.14 18.26 64.41 64.54Z"
          className="Vanuatu_svg__cls-4"
        />
        <path
          d="M182.28 368.84s5.25-10.76.63-15.51-2.5 12.63-2.5 12.63l-2-2.87s3.12-13.09-.41-15.89-1.75 12.63-1.75 12.63l-2.13-2.75S176.4 344.33 173 342s-1.25 12-1.25 12l-2.51-2.88s1.88-12.13-2.25-15-.5 11.51-.5 11.51l-2.75-3s1.25-11.14-2.25-13.64-.5 10.51-.5 10.51l-2.5-2.63s.75-10.38-2.63-12.75-.25 9.88-.25 9.88l-2.61-2.56s.62-10.38-3.63-12.38.75 9.76.75 9.76l-2.88-2.5s-.87-12.14-4.5-13.26 1.63 10.76 1.63 10.76l-2.63-2.13s-1.38-10.38-5.38-11.63 2.5 9.5 2.5 9.5l-2.62-2s-2.88-10.38-6.63-11.5 3.87 9.75 3.87 9.75l-2.5-1.75s-4.12-9.38-7.5-10 3.75 7.76 3.75 7.76l-3.13-1.88s-5.37-9.75-7.25-6.75 7.5 7.38 7.5 7.38l3.13 1.75s-11.38-5.38-12.76-2.63 15.64 4.5 15.64 4.5l2.75 1.88s-11.38-3.13-10-.25 12.64 2.25 12.64 2.25l2.62 1.88s-11.38-2.75-9.88-.13 12.63 2.38 12.63 2.38l2.76 2.25s-11.14-2.88-9.51.5 14.26 3.25 14.26 3.25l3.12 2.88s-11.75-4-10.75-.88 14.39 4.8 14.39 4.8l3 3s-12.63-4.25-8.88-.38 12.89 4.88 12.89 4.88l3.62 4.38s-12.5-7.51-10.75-3.25 14 7.13 14 7.13l2.51 3s-11.26-6.51-10.13-2.88 13.13 7.5 13.13 7.5l2 2.76s-13.26-7.88-12.13-3.88 13.75 6.88 13.75 6.88l1.63 2.5s-12.26-7-12.38-3.63 14.35 6.71 14.35 6.71Z"
          className="Vanuatu_svg__cls-3"
        />
      </g>
    </g>
  </svg>
);
export default SvgVanuatu;
