import * as React from "react";
import type { SVGProps } from "react";
const SvgGuineaBissau = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h426.67v720H0z"
      style={{
        fill: "#ce1126",
      }}
    />
    <path
      d="M426.67 0H1280v360H426.67z"
      style={{
        fill: "#fcd116",
      }}
    />
    <path
      d="M426.67 360H1280v360H426.67z"
      style={{
        fill: "#009e49",
      }}
    />
    <path d="m213.33 243.33-33 101.45 50.72 16.48" />
    <path d="m213.33 243.33 33 101.45-50.73 16.48" data-name="t" />
    <g data-name="c">
      <path d="M314.78 317H208.11v53.33" data-name="t" />
      <path d="m314.78 317-86.3 62.7-31.34-43.15" data-name="t" />
    </g>
    <g data-name="c">
      <path d="m276 436.3-33-101.45-50.72 16.48" data-name="t" />
      <path d="m276 436.3-86.29-62.7 31.34-43.15" data-name="t" />
    </g>
    <g data-name="c">
      <path d="m150.64 436.3 86.29-62.7-31.35-43.15" data-name="t" />
      <path d="m150.64 436.3 33-101.45 50.72 16.48" data-name="t" />
    </g>
    <g data-name="c">
      <path d="m111.89 317 86.29 62.7 31.35-43.15" data-name="t" />
      <path d="M111.89 317h106.66v53.33" data-name="t" />
    </g>
  </svg>
);
export default SvgGuineaBissau;
