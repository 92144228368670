import * as React from "react";
import type { SVGProps } from "react";
const SvgGhana = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M.31-.46h1279.38v720.92H.31"
      style={{
        fill: "#006b3f",
      }}
    />
    <path
      d="M.31-.46h1279.38v480.61H.31"
      style={{
        fill: "#fcd116",
      }}
    />
    <path
      d="M.31-.46h1279.38v240.31H.31"
      style={{
        fill: "#ce1126",
      }}
    />
    <path d="M512.23 331.16H767l-204.26 149 76.9-242.71 81.7 242.71" />
  </svg>
);
export default SvgGhana;
