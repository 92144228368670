import * as React from "react";
import type { SVGProps } from "react";
const SvgJapan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 .33h1280v719.33H0z"
      style={{
        fill: "#fff",
      }}
    />
    <circle
      cx={634.5}
      cy={360}
      r={215.8}
      style={{
        fill: "#bc002d",
      }}
    />
  </svg>
);
export default SvgJapan;
