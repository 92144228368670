import * as React from "react";
import type { SVGProps } from "react";
const SvgKenya = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Kenya_svg__cls-1{fill:#fff}.Kenya_svg__cls-3{stroke:#000;stroke-miterlimit:10}"
        }
      </style>
    </defs>
    <g id="Kenya_svg__Kenya">
      <path d="M0-.33h1280v720.67H0z" className="Kenya_svg__cls-1" />
      <path d="M0-.33h1280v216.2H0z" />
      <path
        d="M0 504.13h1280v216.2H0z"
        style={{
          fill: "#060",
        }}
      />
      <path
        id="Kenya_svg__spearshape"
        d="m487.58 574 9.24 4.5L746 214c14.57-3.29 22.57-15 30.57-26.7 5.34-7.8 26.67-39 35.92-79.55a273.8 273.8 0 0 0-63.63 66c-8 11.7-16 23.4-12.1 35.71Z"
        className="Kenya_svg__cls-3"
      />
      <path
        id="Kenya_svg__spearshape-2"
        d="m487.58 574 9.24 4.5L746 214c14.57-3.29 22.57-15 30.57-26.7 5.34-7.8 26.67-39 35.92-79.55a273.8 273.8 0 0 0-63.63 66c-8 11.7-16 23.4-12.1 35.71Z"
        className="Kenya_svg__cls-1"
        data-name="spearshape"
      />
      <g id="Kenya_svg__spear">
        <path
          id="Kenya_svg__spearshape-3"
          d="m792.42 574-9.24 4.5L534.05 214c-14.57-3.29-22.57-15-30.57-26.7-5.34-7.8-26.67-39-35.92-79.55a273.8 273.8 0 0 1 63.63 66c8 11.7 16 23.4 12.1 35.71Z"
          className="Kenya_svg__cls-3"
          data-name="spearshape"
        />
        <path
          id="Kenya_svg__spearshape-4"
          d="m792.42 574-9.24 4.5L534.05 214c-14.57-3.29-22.57-15-30.57-26.7-5.34-7.8-26.67-39-35.92-79.55a273.8 273.8 0 0 1 63.63 66c8 11.7 16 23.4 12.1 35.71Z"
          className="Kenya_svg__cls-1"
          data-name="spearshape"
        />
      </g>
      <path
        d="M0 251.9v216.2h538.67c16 36 69.33 108.1 101.33 108.1s85.33-72.07 101.33-108.1H1280V251.9H741.33c-16-36-69.33-108.1-101.33-108.1s-85.33 72.07-101.33 108.1Z"
        style={{
          fill: "#b00",
        }}
      />
      <path
        id="Kenya_svg__deco_r"
        d="M741.33 468.1C757.33 432.07 768 396 768 360s-10.67-72.07-26.67-108.1c-16 36-26.66 72.07-26.66 108.1s10.66 72.07 26.66 108.1"
        data-name="deco r"
      />
      <path
        id="Kenya_svg__deco_r-2"
        d="M538.67 468.1C522.67 432.07 512 396 512 360s10.67-72.07 26.67-108.1c16 36 26.66 72.07 26.66 108.1s-10.66 72.07-26.66 108.1"
        data-name="deco r"
      />
      <ellipse
        cx={640}
        cy={360}
        className="Kenya_svg__cls-1"
        rx={21.33}
        ry={27.03}
      />
      <path
        id="Kenya_svg__deco_br"
        d="M645.33 386.35s21.34 36 21.34 94.59-21.34 94.58-21.34 94.58Z"
        className="Kenya_svg__cls-1"
        data-name="deco br"
      />
      <path
        id="Kenya_svg__deco_br-2"
        d="M634.67 333.65s-21.34-36-21.34-94.59 21.34-94.58 21.34-94.58Z"
        className="Kenya_svg__cls-1"
        data-name="deco br"
      />
      <path
        id="Kenya_svg__deco_br-3"
        d="M634.67 386.35s-21.34 36-21.34 94.59 21.34 94.58 21.34 94.58Z"
        className="Kenya_svg__cls-1"
        data-name="deco br"
      />
      <path
        id="Kenya_svg__deco_br-4"
        d="M645.33 333.65s21.34-36 21.34-94.59-21.34-94.58-21.34-94.58Z"
        className="Kenya_svg__cls-1"
        data-name="deco br"
      />
    </g>
  </svg>
);
export default SvgKenya;
