import * as React from "react";
import type { SVGProps } from "react";
const SvgCambodia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Cambodia_svg__cls-2{fill:#032ea1}.Cambodia_svg__cls-11,.Cambodia_svg__cls-12,.Cambodia_svg__cls-3,.Cambodia_svg__cls-4,.Cambodia_svg__cls-5,.Cambodia_svg__cls-6{fill:#fff}.Cambodia_svg__cls-10,.Cambodia_svg__cls-11,.Cambodia_svg__cls-12,.Cambodia_svg__cls-3,.Cambodia_svg__cls-4,.Cambodia_svg__cls-5,.Cambodia_svg__cls-6,.Cambodia_svg__cls-9{stroke:#000}.Cambodia_svg__cls-3,.Cambodia_svg__cls-5,.Cambodia_svg__cls-6,.Cambodia_svg__cls-9{stroke-width:1.2px}.Cambodia_svg__cls-4,.Cambodia_svg__cls-6{stroke-linecap:square}.Cambodia_svg__cls-4,.Cambodia_svg__cls-5,.Cambodia_svg__cls-6{stroke-linejoin:bevel}.Cambodia_svg__cls-4{stroke-width:1.29px}.Cambodia_svg__cls-10,.Cambodia_svg__cls-9{fill:none}.Cambodia_svg__cls-11{stroke-width:1.31px}.Cambodia_svg__cls-12{stroke-width:1.3px}"
        }
      </style>
    </defs>
    <g id="Cambodia_svg__Cambodia">
      <path
        id="Cambodia_svg__rect4074"
        d="M0 180h1280v360H0Z"
        style={{
          fill: "#e00025",
        }}
      />
      <path
        id="Cambodia_svg__rect4070"
        d="M0 0h1280v180H0Z"
        className="Cambodia_svg__cls-2"
      />
      <path
        id="Cambodia_svg__rect4072"
        d="M0 540h1280v180H0Z"
        className="Cambodia_svg__cls-2"
      />
      <path
        id="Cambodia_svg__path4053"
        d="M639.39 219.35s-.26-7.4 4.05-7.48c4.31.08 4 7.48 4 7.48Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path5298"
        d="M817.53 412.87v-36.93c1.21-4.68 5.18-8.83 8.17-9.66v-34.64c-4.52 0-6.73 5.89-6.73 5.89l-7.69 33.87v41.19Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4002"
        d="M750.43 339.35h-11.09v23.5h11.09Z"
        className="Cambodia_svg__cls-4"
      />
      <path
        id="Cambodia_svg__rect4016"
        d="M542.23 339.35h204.84v78.82H542.23Z"
        className="Cambodia_svg__cls-5"
      />
      <path
        id="Cambodia_svg__path3912"
        d="M470.07 412.73v-36.79c-1.21-4.68-5.19-8.83-8.18-9.66v-32.39l6.74 3.64 7.69 33.87v41.33Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3963"
        d="M470.07 412.87v-36.93c-1.21-4.68-5.19-8.83-8.18-9.66v-34.64c4.52 0 6.74 5.89 6.74 5.89l7.69 33.87v41.19Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3923"
        d="M462.1 422.31a16.85 16.85 0 0 0 8-9.58h347.5a16.82 16.82 0 0 0 8 9.58Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect3953"
        d="M736.49 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4008"
        d="M542.23 368.2h204.84v9.16H542.23z"
        className="Cambodia_svg__cls-5"
      />
      <path
        id="Cambodia_svg__rect4010"
        d="M542.23 355.78h204.84v8.08H542.23z"
        className="Cambodia_svg__cls-5"
      />
      <path
        id="Cambodia_svg__rect4014"
        d="M542.23 343.38h204.84v7.84H542.23z"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeLinejoin: "bevel",
          strokeWidth: "1.18px",
        }}
      />
      <path
        id="Cambodia_svg__path3968"
        d="M750.43 339.35h-11.09v23.5h11.09Z"
        className="Cambodia_svg__cls-4"
      />
      <path
        id="Cambodia_svg__path3933"
        d="M822 422.31c-3.11-1.41-7.65-5.26-7.65-9.13v-46l2.14-3.8h-78.32l3.05 3.8v46c0 3.87-3.11 7.72-6.22 9.13Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect3951"
        d="M596.14 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect3955"
        d="M684.33 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__path3937"
        d="M695.13 422.31c-3.65-1.41-9-5.26-9-9.13v-43.73l4.75-6.05h-95l4.56 6.05v43.73c0 3.87-3.65 7.72-7.3 9.13Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect3939"
        d="M612.82 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect3941"
        d="M667.38 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4125"
        d="M594.22 313.63v49.78h97.24v-10.72l.15-39.06a7.57 7.57 0 0 0-4.68 5.17V340h-87.75v-21.2a8.43 8.43 0 0 0-4.96-5.17Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3935"
        d="M678.27 422.31c-3.18-1.41-10.1-5.26-10.1-9.13v-48.94c.68-2.74 4.38-4.35 6.77-6.05h-64.48c3.07 1.62 6.49 3 7.71 6.05v48.94c0 3.87-5.47 7.72-8.65 9.13Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3868"
        d="M686.92 358.71v-35.25h-8.84V320h-69.91v3.44h-8.84v35.25Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect3943"
        d="M543.85 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__path3324"
        d="M465.28 422.31c3.11-1.41 7.65-5.26 7.65-9.13v-46l-2.14-3.8h78.34l-3 3.8v46c0 3.87 3.11 7.72 6.22 9.13Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect4007"
        d="M473.67 332.8h7.23v26.82h-7.23z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4009"
        d="M473.63 327.06h7.23v5.65h-7.23z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__path3905"
        d="M536.49 422.31c-3.11-1.41-7.65-5.26-7.65-9.13v-43.73l6.52-6.05H482.1l6.51 6.05v43.73c0 3.87-4.53 7.72-7.65 9.13Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3901"
        d="M529 422.31c-3.11-1.41-7.65-5.26-7.65-9.13v-39.53l4.27-6h-33.81l4.26 6v39.53c0 3.87-4.53 7.72-7.64 9.13Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3913"
        d="M806.09 422.31c-3.11-1.41-7.65-5.26-7.65-9.13v-43.73l6.56-6.05h-53.3l6.51 6.05v43.73c0 3.87-4.53 7.72-7.65 9.13Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3903"
        d="M798.63 422.31c-3.11-1.41-7.65-5.26-7.65-9.13v-39.53l4.27-6h-33.84l4.26 6v39.53c0 3.87-4.53 7.72-7.65 9.13Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3887"
        d="M500.64 367.6h16.17v54.71h-16.17Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3897"
        d="M664.34 422.31c-3.12-1.41-7.65-5.26-7.65-9.13v-41.79l2.56-3.79H627.4l2.56 3.8v41.78c0 3.87-4.53 7.72-7.65 9.13Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3891"
        d="M635.26 367.61h16.13v54.68h-16.13Z"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: "1.23px",
        }}
      />
      <path
        id="Cambodia_svg__path3893"
        d="M770.24 367.6h16.17v54.71h-16.17Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect4019"
        d="M627.13 346.82h32.39v11.93h-32.39z"
        className="Cambodia_svg__cls-5"
      />
      <path
        id="Cambodia_svg__path3876"
        d="M659.87 355.58c0-3.6 10.51-3.76 15.9-6.83h-64.71c5.39 3.07 15.61 3.32 15.61 6.83l2.19 7 26.79 1.07Z"
        className="Cambodia_svg__cls-5"
      />
      <path
        id="Cambodia_svg__path4078"
        d="M672.52 317.53c0-8.82.37-12.21 3.08-12.21v28c-6.64 2.41-11.38 10.89-11.38 10.89h-41.78s-4.75-8.48-11.39-10.89v-28c3.34 0 3.36 3.55 3.36 12.21Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4023"
        d="M623.06 322.75c5.38 6.47 4.88 17.43 4.82 23.89h31.22c-.07-6.46-.57-17.42 4.82-23.89Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect3927"
        d="M478.65 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect3944"
        d="M806.61 332.8h7.23v26.82h-7.23z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__path3929"
        d="M462.1 422.31a16.85 16.85 0 0 0 8-9.58h347.5a16.82 16.82 0 0 0 8 9.58Z"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__rect3931"
        d="M533.99 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__path3971"
        d="M468.61 323.66v39.74h4.52v-39.84c-1.63-.82-3.13-.95-4.52.1Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3973"
        d="M468.75 349.37c5.6 2.87 11.15 6.18 12.56 14h-12.56Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect3864"
        d="M537.09 339.35h11.08v23.5h-11.08Z"
        className="Cambodia_svg__cls-4"
      />
      <path
        id="Cambodia_svg__path3977"
        d="M546.45 337.47v25.93h3.67v-26a3.9 3.9 0 0 0-3.67.07Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4132"
        d="M616.9 317.53v-10c-.14-5-5-5.35-5.11-9 0 0-.62-5.45.82-7.93 1.95 7.28 5.5 5.94 5.5 2.95 0-2.62-2-5.05-6.14-11.4-1.31-2-.5-8.38 1.35-10.66.71 5.53 1.53 8.18 3.91 8.18 1.44 0 2.63-1 2.63-3.72 0-3.52-2.39-5.3-3.56-8.5a9 9 0 0 1 1.85-9.68c1 5.45.68 7.62 3.1 7.62 4.89-1.54 0-8.61-1-10.4-1.2-2.08 1.61-6.22 1.61-6.22 1.56 4.84 2 5.23 3.73 4.78 2.11-.56 1.84-3.7-.74-6.23-1.63-1.6-1.45-4 .29-5.89 1.76 3.43 4 3.22 4.22 1.18l-1.41-7.91H659l-1.54 7.65c-.44 2.19 2.51 2.67 4.35-.92 1.74 1.91 1.92 4.29.29 5.89-2.58 2.53-2.85 5.67-.74 6.23 1.68.45 2.17.06 3.73-4.78 0 0 2.52 2.82 1.61 6.22-1 1.79-5.93 8.86-1 10.4 2.42 0 2.13-2.17 3.1-7.62a9 9 0 0 1 1.85 9.68c-1.17 3.2-3.56 5-3.56 8.5 0 2.77 1.19 3.72 2.63 3.72 2.38 0 3.2-2.65 3.91-8.18 1.85 2.28 2.67 8.64 1.35 10.66-4.13 6.35-6.14 8.78-6.14 11.4 0 3 3.55 4.33 5.5-2.95 1.44 2.48.82 7.93.82 7.93-.15 3.63-5 4-5.11 9v10Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path5748"
        d="M628.29 244.24h30.83"
        className="Cambodia_svg__cls-10"
      />
      <path
        id="Cambodia_svg__path4031"
        d="m640.61 248.72-4.28-2.2v-6.3c1.75.53 3.71.75 3.9 3.53.61-4 1.71-3.78 3.43-5.33 1.73 1.55 2.83 1.35 3.43 5.33.2-2.78 2.16-3 3.91-3.53v6.3l-4.28 2.2Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4060"
        d="M624.64 253.86h37.65"
        className="Cambodia_svg__cls-10"
      />
      <path
        id="Cambodia_svg__path4029"
        d="m640.32 259.06-7.1-4.89v-5.8c2.49.58 5.28.81 5.56 3.87.86-4.36 2.43-6.62 4.88-8.32 2.46 1.7 4 4 4.89 8.32.27-3.06 3.07-3.29 5.56-3.87v5.8l-7.1 4.89Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4058"
        d="M621 265.55h44.83"
        className="Cambodia_svg__cls-10"
      />
      <path
        id="Cambodia_svg__path4025"
        d="m639.82 275.15-8.17-7.15v-8.52c2.86.84 6.08 1.19 6.39 5.68 1-6.41 2.8-9.72 5.62-12.22 2.83 2.5 4.62 5.81 5.62 12.22.32-4.49 3.53-4.84 6.39-5.68V268l-8.16 7.17Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4037"
        d="m654.88 284.68-7.39 10.22h-8.36l-7.39-10.22Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4033"
        d="M627.49 301.83c3.7 2 5.06 6 5.46 13.6h20.78c.4-7.56 1.76-11.58 5.46-13.6Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4062"
        d="M617.16 279.82h53.17"
        className="Cambodia_svg__cls-10"
      />
      <path
        id="Cambodia_svg__path4064"
        d="M615.94 296.09h54.77"
        className="Cambodia_svg__cls-10"
      />
      <path
        id="Cambodia_svg__path4017"
        d="M659.49 284.68v-10.26a9.38 9.38 0 0 0-6.79 5.86c0-3.4-4.49-11.26-9.37-15.17-4.91 4.38-9.47 11.48-9.38 15.17-.83-2.68-3.28-4.89-6.79-5.86v10.26Z"
        className="Cambodia_svg__cls-5"
      />
      <path
        id="Cambodia_svg__path4015"
        d="M661.05 301.83v-10.26c-3.85 1-6.34 3-7.45 5.86 0-3.41-4.91-11.26-10.27-15.17-5.38 4.37-10.38 11.48-10.28 15.17-.91-2.68-3.6-4.9-7.45-5.86v10.26Z"
        className="Cambodia_svg__cls-5"
      />
      <path
        id="Cambodia_svg__path4013"
        d="M665.38 322.89v-11.84c-4.79 1.24-7.77 4.78-9.27 6.84 0-6.41-7.24-16.18-12.78-19.38-5.69 3.28-12.79 13.38-12.79 19.38-1.54-2.05-4.48-5.6-9.26-6.84v11.84Z"
        className="Cambodia_svg__cls-5"
      />
      <path
        id="Cambodia_svg__path4011"
        d="M662.78 349.17V337c-4.22 1.66-5.48 5.73-6.81 8.49.52-12.34-7-25.61-12.64-29-5.7 3.37-13.31 16.92-12.65 29-1.36-2.75-2.59-6.83-6.81-8.49v12.17Z"
        className="Cambodia_svg__cls-5"
      />
      <path
        id="Cambodia_svg__rect3946"
        d="M806.65 327.06h7.23v5.65h-7.23z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4066"
        d="M746.94 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4068"
        d="M802.29 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__path3870"
        d="M599.33 349.17h87.59"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__rect3355"
        d="M627.13 349.18h32.46v11.93h-32.46z"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path3917"
        d="M659.82 367.6v-12.17c-4.42 1.05-4.69 2.68-6.1 5.41.52-7.38-4.7-15.79-10.39-19.15-5.7 3.36-10.92 11.77-10.4 19.15-1.4-2.73-1.58-4.36-6.1-5.41v12.17Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3908"
        d="M550.12 349.37c-5.61 2.87-11.16 6.18-12.56 14h12.56Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect3914"
        d="M473.13 332.8h7.77v30.43h-7.77Z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect3916"
        d="M473.13 327.06h7.73v5.65h-7.73Z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__path3938"
        d="M537.09 339.35h11.08v23.5h-11.08Z"
        className="Cambodia_svg__cls-4"
      />
      <path
        id="Cambodia_svg__path3931"
        d="M480.79 363v-52.09c2.22 0 2.56 7.63 7.65 7.63 2.65 0 2.37-3.16.88-5.74-1.32-2.28-2.95-5.43-.73-11.3 1.52 4.49 5.6 5.92 4.85 3.1-1.3-4.84-5-5.63-2.23-13.14 1 6.26 5 6 4 2.34-1.1-4.1-3.37-5.78-.57-11.57 1.57 6.57 3.7 6.19 3.7 2.11 0-6-.23-12.52 7.56-14.93 0 0 .45-5.52 3.26-5.52s3.27 5.52 3.27 5.52c7.79 2.41 7.56 8.91 7.56 14.93 0 4.08 2.12 4.46 3.69-2.11 2.81 5.79.54 7.47-.56 11.57-1 3.65 3 3.92 4-2.34 2.8 7.51-.93 8.3-2.23 13.14-.75 2.82 3.33 1.39 4.85-3.1 2.21 5.87.58 9-.73 11.3-1.49 2.58-1.77 5.74.88 5.74 5.09 0 5.43-7.63 7.65-7.63V363Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3920"
        d="m513.08 288.6 3.26-2.74v-3.52a3.48 3.48 0 0 0-2.73 1.68 9 9 0 0 0-4.61-5.27 8.86 8.86 0 0 0-4.61 5.19 3.38 3.38 0 0 0-2.73-1.6v3.52l3.26 2.74Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3922"
        d="m514.82 297.41 1.52-3.28v-4.57a3.44 3.44 0 0 0-2.73 1.71 9.1 9.1 0 0 0-4.61-5.36 9 9 0 0 0-4.61 5.27 3.35 3.35 0 0 0-2.73-1.62v4.57l1.52 3.28Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3924"
        d="m515.05 309.33 4.85-5.91v-5.84c-1.77.2-2.74.91-4 2.89-1.11-4-3.56-4.87-6.85-6.81-3.29 1.94-5.74 2.67-6.85 6.67-1.31-2-2.28-2.55-4-2.75v5.84l4.85 5.91Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3926"
        d="M516.47 321.06s5.66-4.94 6-7.64v-6.24a7.09 7.09 0 0 0-5.88 3.39c-1.38-5.17-3.52-6.61-7.58-9.12-4.06 2.51-6.2 3.95-7.58 9.12a7.09 7.09 0 0 0-5.88-3.39v6.24c.71 2.7 6 7.64 6 7.64Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3928"
        d="M518.88 342.7s7.75-8.58 7.92-11.91v-9.73c-2.89.4-5.63 2.39-7.77 6.39-1.82-8.06-4.67-13.67-10-17.58-5.36 3.91-8.21 9.52-10 17.58-2.14-4-4.88-6-7.77-6.39v9.73c.55 3.33 7.92 11.91 7.92 11.91Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3930"
        d="M518.88 355.49s6.62-6.74 7.92-9.66v-9.74c-2.89.41-5.63 2.4-7.77 6.4-1.82-8.06-4.67-12.67-10-16.57-5.36 3.9-8.21 8.51-10 16.57-2.14-4-4.88-6-7.77-6.4v9.74c1.68 2.92 7.92 9.66 7.92 9.66Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3932"
        d="M521.56 362.93c-1.94-8.4-3.6-14.63-12.9-20.95-9.3 6.32-11 12.55-12.89 20.95Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3911"
        d="M526.34 367.6v-12.17c-4.22 1.65-8 4.1-9.45 6.83-1.57-6.76-4.6-9.74-8.37-13.82-3.78 4.08-6.3 7.06-7.88 13.82-1.4-2.73-5.22-5.18-9.44-6.83v12.17Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3934"
        d="M468.61 323.66v39.74h4.52v-39.84c-1.63-.82-3.13-.95-4.52.1Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3936"
        d="M468.75 349.37c5.6 2.87 11.15 6.18 12.56 14h-12.56Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3940"
        d="M546.45 337.47v25.93h3.67v-26a3.9 3.9 0 0 0-3.67.07Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3942"
        d="M550.12 349.17c-5.61 2.87-11.16 6.18-12.56 14h12.56Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3964"
        d="M818.91 323.66v39.74h-4.51v-39.84c1.6-.82 3.14-.95 4.51.1Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3939"
        d="M750.23 363v-52.09c2.21 0 2.55 7.63 7.64 7.63 2.65 0 2.37-3.16.88-5.74-1.31-2.28-2.94-5.43-.73-11.3 1.52 4.49 5.6 5.92 4.85 3.1-1.3-4.84-5-5.63-2.23-13.14 1 6.26 5 6 4 2.34-1.1-4.1-3.37-5.78-.56-11.57 1.57 6.57 3.69 6.19 3.69 2.11 0-6-.23-12.52 7.56-14.93 0 0 .45-5.52 3.27-5.52s3.26 5.52 3.26 5.52c7.79 2.41 7.56 8.91 7.56 14.93 0 4.08 2.13 4.46 3.7-2.11 2.8 5.79.53 7.47-.57 11.57-1 3.65 3 3.92 4-2.34 2.8 7.51-.93 8.3-2.23 13.14-.75 2.82 3.33 1.39 4.85-3.1 2.22 5.87.59 9-.73 11.3-1.49 2.58-1.77 5.74.88 5.74 5.09 0 5.43-7.63 7.65-7.63V363Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3331"
        d="m782.51 288.6 3.26-2.74v-3.52A3.49 3.49 0 0 0 783 284a9 9 0 0 0-4.61-5.27 8.86 8.86 0 0 0-4.61 5.19 3.36 3.36 0 0 0-2.73-1.6v3.52l3.27 2.74Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3333"
        d="m784.26 297.41 1.51-3.28v-4.57a3.45 3.45 0 0 0-2.73 1.71 9.1 9.1 0 0 0-4.61-5.36 9 9 0 0 0-4.61 5.27 3.34 3.34 0 0 0-2.73-1.62v4.57l1.52 3.28Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3335"
        d="m784.49 309.33 4.85-5.91v-5.84c-1.78.2-2.75.91-4.06 2.89-1.11-4-3.56-4.87-6.85-6.81-3.28 1.94-5.73 2.67-6.85 6.67-1.31-2-2.28-2.55-4.05-2.75v5.84l4.85 5.91Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3337"
        d="M785.91 321.06s5.65-4.94 6-7.64v-6.24a7.09 7.09 0 0 0-5.88 3.39c-1.37-5.17-3.52-6.61-7.58-9.12-4 2.51-6.2 3.95-7.58 9.12a7.09 7.09 0 0 0-5.88-3.39v6.24c.71 2.7 6 7.64 6 7.64Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3339"
        d="M788.32 342.7s7.74-8.58 7.92-11.91v-9.73c-2.9.4-5.64 2.39-7.78 6.39-1.82-8.06-4.66-13.67-10-17.58-5.36 3.91-8.2 9.52-10 17.58-2.14-4-4.88-6-7.78-6.39v9.73c.55 3.33 7.92 11.91 7.92 11.91Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3341"
        d="M788.32 355.49s6.61-6.74 7.92-9.66v-9.74c-2.9.41-5.64 2.4-7.78 6.4-1.82-8.06-4.66-12.67-10-16.57-5.36 3.9-8.2 8.51-10 16.57-2.14-4-4.88-6-7.78-6.4v9.74c1.68 2.92 7.92 9.66 7.92 9.66Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3343"
        d="M791 362.93c-1.93-8.4-3.59-14.63-12.9-20.95-9.3 6.32-11 12.55-12.89 20.95Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3966"
        d="M818.76 349.37c-5.6 2.87-10.55 6.18-12 14h12Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3970"
        d="M741.06 337.47v25.93h-3.66v-26a3.88 3.88 0 0 1 3.66.07Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3972"
        d="M737.4 349.37c5.6 2.87 11.15 6.18 12.55 14H737.4Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4004"
        d="M741.06 337.47v25.93h-3.66v-26a3.88 3.88 0 0 1 3.66.07Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4006"
        d="M737.4 349.37c5.6 2.87 11.15 6.18 12.55 14H737.4Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect3321"
        d="M675.6 313.92c0-10.11 8.73-11.25 8.73-11.25v9c-3.32-.14-4.92 2.84-4.92 7.28s2.67 4.51 2.67 4.51V349h-6.48Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4325"
        d="M611.09 313.92c0-10.11-8.73-11.25-8.73-11.25v9c3.32-.14 4.92 2.84 4.92 7.28s-2.67 4.51-2.67 4.51V349h6.48Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path5240"
        d="M795.92 367.46v-12.17c-4.23 1.65-8 4.1-9.45 6.83-1.57-6.76-4.6-9.74-8.38-13.83-3.77 4.09-6.3 7.07-7.87 13.83-1.41-2.73-5.23-5.18-9.45-6.83v12.17Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4136"
        d="M422.83 466.78h441.59v22.55H422.83Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__rect2997"
        d="M411.19 489.29h464.87v22.53H411.19Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4138"
        d="M446 433h395.23v14.59H446Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4140"
        d="M434.47 447.54h418.32v19.11H434.47Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path4134"
        d="M452.93 421.81h381.4v11.12h-381.4Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3823"
        d="M494.32 421.87h28.81v89.89h-28.81Z"
        className="Cambodia_svg__cls-11"
      />
      <path
        id="Cambodia_svg__path3825"
        d="M500.7 421.87h16.05v89.89H500.7Z"
        className="Cambodia_svg__cls-12"
      />
      <path
        id="Cambodia_svg__path4448"
        d="M500.41 433h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4455"
        d="M500.41 444.21h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4457"
        d="M500.41 455.46h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4459"
        d="M500.41 466.71h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4461"
        d="M500.41 478h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4463"
        d="M500.41 489.21h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4465"
        d="M500.41 500.46h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4480"
        d="M628.72 421.87h28.81v89.89h-28.81Z"
        className="Cambodia_svg__cls-11"
      />
      <path
        id="Cambodia_svg__path3825-1"
        d="M635.3 421.87h16.05v89.89H635.3Z"
        className="Cambodia_svg__cls-12"
      />
      <path
        id="Cambodia_svg__path4484"
        d="M635.38 433h15.49"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4486"
        d="M635.38 444.2h15.49"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4488"
        d="M635.38 455.46h15.49"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4490"
        d="M635.38 466.71h15.49"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4492"
        d="M635.38 478h15.49"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4494"
        d="M635.38 489.23h15.49"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4496"
        d="M635.38 500.48h15.49"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4500"
        d="M763.92 421.87h28.81v89.89h-28.81Z"
        className="Cambodia_svg__cls-11"
      />
      <path
        id="Cambodia_svg__path4502"
        d="M770.3 421.87h16.05v89.89H770.3Z"
        className="Cambodia_svg__cls-12"
      />
      <path
        id="Cambodia_svg__path4504"
        d="M770 433h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4506"
        d="M770 444.21h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4508"
        d="M770 455.46h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4510"
        d="M770 466.71h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4512"
        d="M770 478h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4514"
        d="M770 489.21h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__path4516"
        d="M770 500.46h16.64"
        className="Cambodia_svg__cls-9"
      />
      <path
        id="Cambodia_svg__rect4518"
        d="M556.49 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4520"
        d="M569.99 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4522"
        d="M583.49 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4524"
        d="M696.56 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4526"
        d="M710.06 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__rect4528"
        d="M723.56 382.86h6.46v29.69h-6.46z"
        className="Cambodia_svg__cls-6"
      />
      <path
        id="Cambodia_svg__path3144"
        d="m629.9 234.69-.71-5.62h28.5l-.71 5.62Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3916"
        d="m631.81 229-.61-4.63h24.48l-.61 4.63Z"
        className="Cambodia_svg__cls-3"
      />
      <path
        id="Cambodia_svg__path3918"
        d="m635.7 224.16-.41-4.62h16.3l-.41 4.62Z"
        className="Cambodia_svg__cls-3"
      />
    </g>
  </svg>
);
export default SvgCambodia;
