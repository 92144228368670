import * as React from "react";
import type { SVGProps } from "react";
const SvgPalau = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#4aadd6",
      }}
    />
    <circle
      cx={640}
      cy={360}
      r={216}
      style={{
        fill: "#ffde00",
      }}
    />
  </svg>
);
export default SvgPalau;
