import * as React from "react";
import type { SVGProps } from "react";
const SvgCzechRepublic = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <g data-name="Czech Republic">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#d62027",
        }}
      />
      <path
        d="M0 0h1280v360H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M540 360 0 0v720Z"
        style={{
          fill: "#17467d",
        }}
      />
    </g>
  </svg>
);
export default SvgCzechRepublic;
