import * as React from "react";
import type { SVGProps } from "react";
const SvgSenegal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Senegal_svg__cls-1{fill:#00853f}"}</style>
    </defs>
    <g id="Senegal_svg__Senegal">
      <path d="M0-.33h426.67v720.67H0z" className="Senegal_svg__cls-1" />
      <path
        d="M426.67-.33h426.67v720.67H426.67z"
        style={{
          fill: "#fdef42",
        }}
      />
      <path
        d="M853.33-.33H1280v720.67H853.33z"
        style={{
          fill: "#e31b23",
        }}
      />
      <g id="Senegal_svg__c">
        <path
          id="Senegal_svg__t"
          d="m640 229.89-40.53 124.75 62.37 20.26Z"
          className="Senegal_svg__cls-1"
        />
        <path
          id="Senegal_svg__t-2"
          d="m640 229.89 40.53 124.75-62.37 20.26Z"
          className="Senegal_svg__cls-1"
          data-name="t"
        />
      </g>
      <g id="Senegal_svg__c-2" data-name="c">
        <path
          id="Senegal_svg__t-3"
          d="M764.75 320.52H633.58v65.59Z"
          className="Senegal_svg__cls-1"
          data-name="t"
        />
        <path
          id="Senegal_svg__t-4"
          d="m764.75 320.52-106.12 77.1-38.55-53.06Z"
          className="Senegal_svg__cls-1"
          data-name="t"
        />
      </g>
      <g id="Senegal_svg__c-3" data-name="c">
        <path
          id="Senegal_svg__t-5"
          d="m717.1 467.17-40.53-124.75-62.38 20.27Z"
          className="Senegal_svg__cls-1"
          data-name="t"
        />
        <path
          id="Senegal_svg__t-6"
          d="M717.1 467.17 611 390.07 649.53 337Z"
          className="Senegal_svg__cls-1"
          data-name="t"
        />
      </g>
      <g id="Senegal_svg__c-4" data-name="c">
        <path
          id="Senegal_svg__t-7"
          d="m562.9 467.17 106.1-77.1L630.47 337Z"
          className="Senegal_svg__cls-1"
          data-name="t"
        />
        <path
          id="Senegal_svg__t-8"
          d="m562.9 467.17 40.53-124.75 62.38 20.27Z"
          className="Senegal_svg__cls-1"
          data-name="t"
        />
      </g>
      <g id="Senegal_svg__c-5" data-name="c">
        <path
          id="Senegal_svg__t-9"
          d="m515.25 320.52 106.12 77.1 38.55-53.06Z"
          className="Senegal_svg__cls-1"
          data-name="t"
        />
        <path
          id="Senegal_svg__t-10"
          d="M515.25 320.52h131.17v65.59Z"
          className="Senegal_svg__cls-1"
          data-name="t"
        />
      </g>
    </g>
  </svg>
);
export default SvgSenegal;
