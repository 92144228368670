import * as React from "react";
import type { SVGProps } from "react";
const SvgMalawi = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Malawi_svg__cls-1{fill:#ce1126}"}</style>
    </defs>
    <g id="Malawi_svg__Malawi">
      <path d="M0-.34h1280v720.67H0z" />
      <circle
        cx={639.57}
        cy={273.52}
        r={154.94}
        className="Malawi_svg__cls-1"
      />
      <path d="M0 239.89h1280v240.22H0z" className="Malawi_svg__cls-1" />
      <path
        id="Malawi_svg__r"
        d="M639.57 28.49c-5.29 0-7.21 7.93-7.21 15.62 0 19.21 2.4 28.82 7.21 56.45 4.9-27.63 7.2-37.48 7.2-56.45 0-7.93-2.4-15.62-7.2-15.62"
        className="Malawi_svg__cls-1"
      />
      <path
        id="Malawi_svg__r-2"
        d="M660.92 29.42c-5.26-.46-7.87 7.27-8.54 14.93-1.67 19.14-.12 28.93 2.26 56.87 7.29-27.1 10.45-36.71 12.1-55.61.69-7.9-1-15.77-5.82-16.19"
        className="Malawi_svg__cls-1"
        data-name="r"
      />
      <g id="Malawi_svg__r2">
        <path
          id="Malawi_svg__r-3"
          d="M682.12 32.21c-5.21-.91-8.48 6.56-9.81 14.13-3.34 18.93-2.64 28.81-2.71 56.85 9.62-26.36 13.61-35.66 16.9-54.35 1.38-7.81.35-15.79-4.38-16.63"
          className="Malawi_svg__cls-1"
          data-name="r"
        />
        <path
          id="Malawi_svg__r-4"
          d="M703 36.84c-5.1-1.37-9 5.79-11 13.22-5 18.56-5.14 28.46-7.65 56.39C696.22 81 701 72.12 705.9 53.79c2.06-7.66 1.73-15.71-2.92-16.95"
          className="Malawi_svg__cls-1"
          data-name="r"
        />
      </g>
      <g id="Malawi_svg__r8">
        <g id="Malawi_svg__r2-2" data-name="r2">
          <path
            id="Malawi_svg__r-5"
            d="M723.37 43.27c-5-1.81-9.48 5-12.11 12.21-6.57 18.05-7.6 27.91-12.54 55.51 14.06-24.28 19.59-32.75 26.08-50.58 2.71-7.45 3.09-15.5-1.43-17.14"
            className="Malawi_svg__cls-1"
            data-name="r"
          />
          <path
            id="Malawi_svg__r-6"
            d="M743.12 51.45c-4.79-2.24-9.88 4.14-13.13 11.1C721.87 80 720 89.7 712.66 116.76c16.12-23 22.37-30.92 30.39-48.11 3.35-7.19 4.42-15.17.07-17.2"
            className="Malawi_svg__cls-1"
            data-name="r"
          />
        </g>
        <g id="Malawi_svg__r4">
          <g id="Malawi_svg__r2-3" data-name="r2">
            <path
              id="Malawi_svg__r-7"
              d="M762.08 61.32c-4.58-2.64-10.2 3.26-14.05 9.92-9.61 16.64-12.33 26.16-22 52.49 18.05-21.47 25-28.85 34.46-45.29 4-6.86 5.73-14.72 1.57-17.12"
              className="Malawi_svg__cls-1"
              data-name="r"
            />
            <path
              id="Malawi_svg__r-8"
              d="M780.11 72.8c-4.33-3-10.45 2.36-14.86 8.66-11 15.74-14.57 25-26.48 50.38 19.86-19.82 27.4-26.57 38.29-42.11 4.54-6.5 7-14.17 3.05-16.93"
              className="Malawi_svg__cls-1"
              data-name="r"
            />
          </g>
        </g>
      </g>
      <path
        id="Malawi_svg__r-9"
        d="M797.07 85.82c-4.05-3.4-10.62 1.44-15.56 7.33-12.35 14.72-16.69 23.62-30.77 47.87 21.52-18 29.61-24.07 41.81-38.61 5.1-6.07 8.2-13.51 4.52-16.59"
        className="Malawi_svg__cls-1"
        data-name="r"
      />
      <path
        id="Malawi_svg__r-10"
        d="M812.83 100.26c-3.74-3.74-10.7.51-16.14 5.94-13.59 13.59-18.68 22.09-34.82 45 23-16.07 31.59-21.41 45-34.83 5.61-5.6 9.35-12.73 6-16.13"
        className="Malawi_svg__cls-1"
        data-name="r"
      />
      <g id="Malawi_svg__r2-4" data-name="r2">
        <path
          id="Malawi_svg__r-11"
          d="M827.27 116c-3.4-4-10.71-.43-16.59 4.51-14.73 12.36-20.54 20.37-38.62 41.81 24.32-14 33.34-18.57 47.88-30.76 6.07-5.1 10.42-11.88 7.33-15.56"
          className="Malawi_svg__cls-1"
          data-name="r"
        />
        <path
          id="Malawi_svg__r-12"
          d="M840.28 133c-3-4.33-10.62-1.36-16.92 3.05-15.74 11-22.24 18.5-42.11 38.28 25.44-11.83 34.83-15.59 50.38-26.47 6.49-4.55 11.41-10.93 8.65-14.86"
          className="Malawi_svg__cls-1"
          data-name="r"
        />
      </g>
      <g id="Malawi_svg__r8-2" data-name="r8">
        <g id="Malawi_svg__r2-5" data-name="r2">
          <path
            id="Malawi_svg__r-13"
            d="M851.77 151c-2.64-4.57-10.47-2.27-17.13 1.57-16.64 9.61-23.76 16.49-45.28 34.43 26.37-9.57 36-12.5 52.49-22 6.86-4 12.32-9.88 9.92-14.05"
            className="Malawi_svg__cls-1"
            data-name="r"
          />
          <path
            id="Malawi_svg__r-14"
            d="M861.64 170c-2.24-4.79-10.23-3.19-17.2.06-17.42 8.13-25.11 14.36-48.12 30.39 27.11-7.23 37-9.3 54.21-17.32 7.19-3.35 13.14-8.78 11.11-13.13"
            className="Malawi_svg__cls-1"
            data-name="r"
          />
        </g>
        <g id="Malawi_svg__r4-2" data-name="r4">
          <g id="Malawi_svg__r2-6" data-name="r2">
            <path
              id="Malawi_svg__r-15"
              d="M869.82 189.71c-1.81-5-9.92-4.06-17.14-1.43-18.06 6.58-26.27 12.12-50.58 26.08 27.63-4.84 37.68-6 55.51-12.53 7.45-2.71 13.85-7.6 12.21-12.12"
              className="Malawi_svg__cls-1"
              data-name="r"
            />
            <path
              id="Malawi_svg__r-16"
              d="M876.25 210.1c-1.37-5.1-9.53-4.91-17-2.92-18.56 5-27.22 9.78-52.67 21.57 28-2.41 38.07-2.73 56.4-7.65 7.66-2 14.46-6.36 13.22-11"
              className="Malawi_svg__cls-1"
              data-name="r"
            />
          </g>
        </g>
      </g>
      <path
        id="Malawi_svg__r-17"
        d="M398.26 231c-.92 5.21 6.56 8.47 14.13 9.81 18.92 3.34 28.8 2.64 56.84 2.7-26.35-9.62-35.65-13.6-54.34-16.9-7.81-1.37-15.8-.34-16.63 4.39"
        className="Malawi_svg__cls-1"
        data-name="r"
      />
      <path
        id="Malawi_svg__r-18"
        d="M402.89 210.1c-1.37 5.11 5.79 9 13.21 11 18.57 5 28.47 5.14 56.4 7.65-25.42-11.88-34.33-16.66-52.67-21.57-7.65-2.05-15.7-1.72-16.94 2.92"
        className="Malawi_svg__cls-1"
        data-name="r"
      />
      <g id="Malawi_svg__r2-7" data-name="r2">
        <path
          id="Malawi_svg__r-19"
          d="M409.32 189.71c-1.81 5 5 9.49 12.2 12.12 18.06 6.57 27.91 7.6 55.52 12.53-24.29-14.05-32.75-19.59-50.59-26.08-7.45-2.71-15.49-3.08-17.13 1.43"
          className="Malawi_svg__cls-1"
          data-name="r"
        />
        <path
          id="Malawi_svg__r-20"
          d="M417.5 170c-2.24 4.79 4.13 9.88 11.1 13.13 17.42 8.12 27.14 10 54.21 17.32-23-16.11-30.92-22.37-48.12-30.39-7.18-3.35-15.16-4.42-17.19-.06"
          className="Malawi_svg__cls-1"
          data-name="r"
        />
      </g>
      <g id="Malawi_svg__r8-3" data-name="r8">
        <g id="Malawi_svg__r2-8" data-name="r2">
          <path
            id="Malawi_svg__r-21"
            d="M427.37 151c-2.65 4.58 3.26 10.21 9.91 14.05 16.65 9.61 26.17 12.34 52.5 22-21.48-18.06-28.85-25-45.29-34.47-6.86-4-14.72-5.73-17.12-1.57"
            className="Malawi_svg__cls-1"
            data-name="r"
          />
          <path
            id="Malawi_svg__r-22"
            d="M438.85 133c-3 4.33 2.36 10.45 8.66 14.86 15.74 11 25 14.56 50.38 26.47-19.82-19.86-26.57-27.4-42.11-38.28-6.5-4.55-14.17-7-16.93-3.05"
            className="Malawi_svg__cls-1"
            data-name="r"
          />
        </g>
        <g id="Malawi_svg__r4-3" data-name="r4">
          <g id="Malawi_svg__r2-9" data-name="r2">
            <path
              id="Malawi_svg__r-23"
              d="M451.86 116c-3.39 4 1.44 10.61 7.33 15.56 14.72 12.35 23.63 16.68 47.88 30.76-18-21.51-24.07-29.61-38.61-41.81-6.08-5.09-13.51-8.19-16.6-4.51"
              className="Malawi_svg__cls-1"
              data-name="r"
            />
            <path
              id="Malawi_svg__r-24"
              d="M466.31 100.26c-3.74 3.73.5 10.7 5.94 16.13 13.59 13.59 22.08 18.69 45 34.83-16.06-23-21.4-31.6-34.82-45-5.6-5.6-12.74-9.34-16.13-5.94"
              className="Malawi_svg__cls-1"
              data-name="r"
            />
          </g>
        </g>
      </g>
      <g id="Malawi_svg__r2-10" data-name="r2">
        <path
          id="Malawi_svg__r-25"
          d="M482.07 85.82c-4.05 3.39-.43 10.7 4.51 16.59C498.93 117.13 507 123 528.39 141c-14-24.31-18.57-33.34-30.77-47.87-5.09-6.08-11.87-10.42-15.55-7.33"
          className="Malawi_svg__cls-1"
          data-name="r"
        />
        <path
          id="Malawi_svg__r-26"
          d="M499 72.8c-4.33 3-1.35 10.63 3.06 16.93 11 15.74 18.5 22.23 38.28 42.11-11.81-25.44-15.57-34.84-26.46-50.38C509.34 75 503 70.05 499 72.8"
          className="Malawi_svg__cls-1"
          data-name="r"
        />
      </g>
      <g id="Malawi_svg__r4-4" data-name="r4">
        <g id="Malawi_svg__r2-11" data-name="r2">
          <path
            id="Malawi_svg__r-27"
            d="M517.05 61.32c-4.57 2.64-2.28 10.47 1.57 17.12 9.61 16.65 16.49 23.77 34.47 45.29-9.57-26.37-12.5-36.06-22-52.49-4-6.87-9.89-12.32-14.05-9.92"
            className="Malawi_svg__cls-1"
            data-name="r"
          />
          <path
            id="Malawi_svg__r-28"
            d="M536 51.45c-4.79 2.23-3.18 10.23.07 17.2 8.12 17.41 14.36 25.11 30.39 48.11-7.23-27.11-9.31-37-17.33-54.21-3.35-7.18-8.77-13.13-13.13-11.1"
            className="Malawi_svg__cls-1"
            data-name="r"
          />
        </g>
      </g>
      <g id="Malawi_svg__r16">
        <g id="Malawi_svg__r8-4" data-name="r8">
          <g id="Malawi_svg__r2-12" data-name="r2">
            <path
              id="Malawi_svg__r-29"
              d="M555.76 43.27c-5 1.81-4.06 9.91-1.43 17.14 6.57 18 12.12 26.26 26.08 50.58-4.84-27.64-6-37.68-12.54-55.51-2.71-7.45-7.59-13.86-12.11-12.21"
              className="Malawi_svg__cls-1"
              data-name="r"
            />
            <path
              id="Malawi_svg__r-30"
              d="M576.15 36.84c-5.11 1.37-4.91 9.52-2.92 16.95 5 18.56 9.78 27.22 21.57 52.66-2.42-27.95-2.74-38.06-7.65-56.39-2-7.66-6.36-14.46-11-13.22"
              className="Malawi_svg__cls-1"
              data-name="r"
            />
          </g>
          <g id="Malawi_svg__r4-5" data-name="r4">
            <g id="Malawi_svg__r2-13" data-name="r2">
              <path
                id="Malawi_svg__r-31"
                d="M597 32.21c-5.21.92-5.72 9.06-4.39 16.63 3.34 18.93 7.37 28 16.9 54.35 0-28.06.59-38.16-2.7-56.85-1.38-7.81-5.08-15-9.81-14.13"
                className="Malawi_svg__cls-1"
                data-name="r"
              />
              <path
                id="Malawi_svg__r-32"
                d="M618.21 29.42c-5.26.46-6.49 8.53-5.82 16.19 1.68 19.14 4.91 28.5 12.1 55.61 2.48-27.95 3.92-38 2.26-56.87-.69-7.9-3.75-15.35-8.54-14.93"
                className="Malawi_svg__cls-1"
                data-name="r"
              />
            </g>
          </g>
        </g>
      </g>
      <path d="M99.06 221.87h1081.01v18.02H99.06z" />
      <path
        d="M0 480.11h1280v240.22H0z"
        style={{
          fill: "#339e35",
        }}
      />
    </g>
  </svg>
);
export default SvgMalawi;
