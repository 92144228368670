import * as React from "react";
import type { SVGProps } from "react";
const SvgLuxembourg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 360h1280v360.25H0z"
      style={{
        fill: "#119fda",
      }}
    />
    <path
      d="M0-.25h1280V360H0z"
      style={{
        fill: "#ec2b3a",
      }}
    />
    <path
      d="M0 239.92h1280v240.17H0z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgLuxembourg;
