import * as React from "react";
import type { SVGProps } from "react";
const SvgBarbados = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#00267f",
      }}
    />
    <path
      d="M426.67 0h426.67v720H426.67z"
      style={{
        fill: "#ffc726",
      }}
    />
    <path d="M640 185.58c-11.7 31.91-23.62 65.12-49.14 90.54 7.92-2.61 21.78-4.95 30.69-4.72v134.18l-37.89 5.63c-1.35-.14-1.8-2.25-1.8-5.13-3.64-41.67-13.5-76.68-24.84-112.9-.81-4.95-15.16-23.85-4.09-20.52 1.35.18 16.15 6.21 13.81 3.33-20.16-20.88-49.63-35.91-78.25-40.37-2.52-.63-4 .63-1.76 3.56 38 58.45 69.75 127.43 69.48 209.29 14.76 0 50.54-8.73 65.34-8.73v94.68h18.68L644.5 270Z" />
    <path
      d="M640 185.58c11.7 31.91 23.63 65.12 49.14 90.54-7.92-2.61-21.78-4.95-30.69-4.72v134.18l37.89 5.63c1.35-.14 1.8-2.25 1.8-5.13 3.65-41.67 13.5-76.68 24.84-112.9.81-4.95 15.17-23.85 4.1-20.52-1.35.18-16.16 6.21-13.82 3.33 20.16-20.88 49.64-35.91 78.26-40.37 2.52-.63 4 .63 1.75 3.56-38 58.45-69.75 127.43-69.48 209.29-14.76 0-50.53-8.73-65.34-8.73v94.68h-18.67L635.5 270Z"
      data-name="h"
    />
  </svg>
);
export default SvgBarbados;
