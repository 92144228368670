import * as React from "react";
import type { SVGProps } from "react";

const SvgTaiwan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Taiwan_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Taiwan_svg__Taiwan">
      <path
        d="M1280 720H0V0h1280Z"
        style={{
          fill: "#fe0000",
        }}
      />
      <path d="M0 360h640V0H0Z" style={{ fill: "#000095" }} />
      <circle
        cx="320"
        cy="180"
        r="100"
        style={{
          fill: "#fff",
        }}
      />
      <g id="Taiwan_svg__sun">
        <path
          d="M320 80l-20 60h40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M320 80l20 60h-40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M220 180l60-20v40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M220 180l60 20v-40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M320 280l20-60h-40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M320 280l-20-60h40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M420 180l-60 20v-40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M420 180l-60-20v40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M270 120l20 40-40 0Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M270 120l40 0-20 40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M370 120l-20 40 40 0Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M370 120l-40 0 20 40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M270 240l20-40-40 0Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M270 240l40 0-20-40Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M370 240l-20-40 40 0Z"
          className="Taiwan_svg__cls-2"
        />
        <path
          d="M370 240l-40 0 20-40Z"
          className="Taiwan_svg__cls-2"
        />
      </g>
    </g>
  </svg>
);

export default SvgTaiwan;