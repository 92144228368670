import * as React from "react";
import type { SVGProps } from "react";
const SvgMorocco = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.01h1280v720.02H0z"
      style={{
        fill: "#c1272d",
      }}
    />
    <path
      d="m640 240-27 83.14-87.34.07 70.61 51.38-26.88 83.21c18.19-13.34 51.15-37.26 70.61-51.37l70.69 51.34-27-83.14 70.61-51.46H667Zm0 56.92 8.59 26.22h-17.1Zm-32.66 43.8L602.05 357l-22.31-16.2Zm18.45 0h28.5l8.81 27.16-23.1 16.8-23.06-16.8Zm47 0h27.53L678 357Zm-61.55 44.78 13.87 10-22.31 16.25Zm57.6 0 8.44 26.25-22.28-16.2Z"
      style={{
        fill: "#006233",
      }}
    />
  </svg>
);
export default SvgMorocco;
