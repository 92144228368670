import * as React from "react";
import type { SVGProps } from "react";
const SvgCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.25 14.375H10.25M5.25 17.9375H12.75M1.5 1.3125V22.6875H36.5V1.3125H1.5Z" stroke="#38BA9C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.5 6.0625V8.4375H36.5V6.0625H1.5Z" fill="#38BA9C" stroke="#38BA9C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
export default SvgCreditCard;


