import * as React from "react";
import type { SVGProps } from "react";
const SvgBosniaAndHerzegovina = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Bosnia_and_Herzegovina_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g
      id="Bosnia_and_Herzegovina_svg__Bosnia_and_Herzegovina"
      data-name="Bosnia and Herzegovina"
    >
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#293688",
        }}
      />
      <path
        id="Bosnia_and_Herzegovina_svg__star"
        d="M821.79 677.24 862 553.5l40.2 123.74L797 600.76h130.1Z"
        className="Bosnia_and_Herzegovina_svg__cls-2"
      />
      <path
        d="M361.6 0h720v720Z"
        style={{
          fill: "#fdcb08",
        }}
      />
      <path
        id="Bosnia_and_Herzegovina_svg__path11682"
        d="M207 0h50l15.23 47.24L232 18l-40.2 29.24Z"
        className="Bosnia_and_Herzegovina_svg__cls-2"
      />
      <path
        id="Bosnia_and_Herzegovina_svg__path11689"
        d="m927 720-40-29.24h49.7L952 643.5l15.3 47.25h49.75L977 720z"
        className="Bosnia_and_Herzegovina_svg__cls-2"
      />
      <path
        id="Bosnia_and_Herzegovina_svg__star-2"
        d="M281.8 137.24 322 13.5l40.2 123.74L257 60.76h130.1Z"
        className="Bosnia_and_Herzegovina_svg__cls-2"
        data-name="star"
      />
      <path
        id="Bosnia_and_Herzegovina_svg__star-3"
        d="M371.8 227.24 412 103.5l40.2 123.74L347 150.76h130.1Z"
        className="Bosnia_and_Herzegovina_svg__cls-2"
        data-name="star"
      />
      <path
        id="Bosnia_and_Herzegovina_svg__star-4"
        d="M461.8 317.24 502 193.5l40.2 123.74L437 240.76h130.1Z"
        className="Bosnia_and_Herzegovina_svg__cls-2"
        data-name="star"
      />
      <path
        id="Bosnia_and_Herzegovina_svg__star-5"
        d="M551.8 407.24 592 283.5l40.21 123.74L527 330.76h130.1Z"
        className="Bosnia_and_Herzegovina_svg__cls-2"
        data-name="star"
      />
      <path
        id="Bosnia_and_Herzegovina_svg__star-6"
        d="M641.8 497.24 682 373.5l40.21 123.74L617 420.76h130.1Z"
        className="Bosnia_and_Herzegovina_svg__cls-2"
        data-name="star"
      />
      <path
        id="Bosnia_and_Herzegovina_svg__star-7"
        d="M731.79 587.24 772 463.5l40.21 123.74L707 510.76h130.1Z"
        className="Bosnia_and_Herzegovina_svg__cls-2"
        data-name="star"
      />
    </g>
  </svg>
);
export default SvgBosniaAndHerzegovina;
