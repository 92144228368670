import * as React from "react";
import type { SVGProps } from "react";

const SvgChina = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".China_svg__cls-1{fill:#ffde00}"}</style>
    </defs>
    <g id="China_svg__China" data-name="China">
      <rect width="1280" height="720" style={{ fill: "#de2910" }} />
      <path
        d="M245.6 143.1 264 205l62.3-.5-50.3 36.8 19 61.8-50.5-36.6-50.6 36.6 19.1-61.8-50.3-36.8 62.2.5z"
        className="China_svg__cls-1"
      />
      <path
        d="m371.4 287.9 10.5-30.2-27.6-20.5 34.4-1 10.4-30.3 10.5 30.2 34.3 1-27.6 20.6 10.5 30.2-27.5-20.6zm45.6-118.5-8-26.5 24-22.2-31.5 2.5-8-26.4-10 26.3-31.6-2.4 24 22.1-10 26.3 24.1-22zm16 79.1-16.4-22.2 1.2-27.9-21.3 18.7-16.5-22.1-1.3 27.9-21.3-18.6 16.5 22.1-1.2 27.8 21.3-18.7z"
        className="China_svg__cls-1"
      />
    </g>
  </svg>
);

export default SvgChina;