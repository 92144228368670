import * as React from "react";
import type { SVGProps } from "react";

const SvgIsleOfMan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 768" {...props}>
    {/* Red Background */}
    <rect width="1024" height="768" fill="#d52b1e" />
    
    {/* Three Legs of Man */}
    <g stroke="#fff" strokeWidth="40" strokeLinecap="round">
      <path d="M320,400 l-80,-40 l-40,80 l-80,-40 l80,120 l80,-40 l40,80 l80,-40 l-80,-120 l-40,80 Z" fill="#fff" />
      <path d="M640,400 l-80,-40 l-40,80 l-80,-40 l80,120 l80,-40 l40,80 l80,-40 l-80,-120 l-40,80 Z" fill="#fff" />
      <path d="M960,400 l-80,-40 l-40,80 l-80,-40 l80,120 l80,-40 l40,80 l80,-40 l-80,-120 l-40,80 Z" fill="#fff" />
    </g>
  </svg>
);

export default SvgIsleOfMan;