import * as React from "react";
import type { SVGProps } from "react";
const SvgSomalia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 .06h1280v719.88H0z"
      style={{
        fill: "#4189dd",
      }}
    />
    <path
      d="M476 307.06h124.9l38.6-118.78 38.58 118.78H803l-101 73.4 38.58 118.78-101-73.4-101 73.4 38.58-118.78Z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgSomalia;
