import * as React from "react";
import type { SVGProps } from "react";
const SvgEstonia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#4d8fcc",
      }}
    />
    <path d="M0 240h1280v480H0z" />
    <path
      d="M0 480h1280v240H0z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgEstonia;
