import * as React from "react";
import type { SVGProps } from "react";
const SvgHungary = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.3h1280v240.2H0z"
      style={{
        fill: "#cd2028",
      }}
    />
    <path
      d="M0 239.9h1280v240.2H0z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0 480.1h1280v240.2H0z"
      style={{
        fill: "#008651",
      }}
    />
  </svg>
);
export default SvgHungary;
