


import * as React from "react";
import type { SVGProps } from "react";
const SvgPaymentSuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="136" height="137" viewBox="0 0 136 137" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M68 0.6875C85.985 0.6875 103.233 7.83201 115.951 20.5493C128.668 33.2666 135.812 50.515 135.812 68.5C135.812 86.485 128.668 103.733 115.951 116.451C103.233 129.168 85.985 136.312 68 136.312C50.015 136.312 32.7666 129.168 20.0493 116.451C7.33201 103.733 0.1875 86.485 0.1875 68.5C0.1875 50.515 7.33201 33.2666 20.0493 20.5493C32.7666 7.83201 50.015 0.6875 68 0.6875ZM59.5525 81.8784L44.4884 66.8047C43.9484 66.2646 43.3073 65.8363 42.6017 65.544C41.8961 65.2517 41.1398 65.1013 40.3761 65.1013C39.6124 65.1013 38.8561 65.2517 38.1505 65.544C37.4449 65.8363 36.8038 66.2646 36.2637 66.8047C35.1731 67.8953 34.5604 69.3746 34.5604 70.917C34.5604 72.4595 35.1731 73.9387 36.2637 75.0294L55.445 94.2106C55.9835 94.7534 56.6241 95.1842 57.3299 95.4782C58.0357 95.7722 58.7928 95.9236 59.5573 95.9236C60.3219 95.9236 61.079 95.7722 61.7848 95.4782C62.4906 95.1842 63.1312 94.7534 63.6697 94.2106L103.388 54.4822C103.936 53.9444 104.371 53.3036 104.669 52.5967C104.968 51.8898 105.123 51.1308 105.127 50.3635C105.13 49.5962 104.982 48.8359 104.69 48.1262C104.398 47.4166 103.969 46.7717 103.427 46.2288C102.885 45.686 102.24 45.2558 101.531 44.9632C100.822 44.6706 100.061 44.5214 99.2942 44.524C98.5269 44.5267 97.7677 44.6812 97.0605 44.9788C96.3532 45.2763 95.7119 45.7109 95.1734 46.2575L59.5525 81.8784Z" fill="#38BA9C" fillOpacity="0.55"/>
  </svg>
)
export default SvgPaymentSuccessIcon;


