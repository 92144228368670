import * as React from "react";
import type { SVGProps } from "react";
const SvgInTransit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1000}
    height={1000}
    viewBox="0 0 750 750"
    {...props}
  >
    <defs>
      <clipPath id="In_Transit_svg__a">
        <path d="M174 178h421v381H174Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__b">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__c">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__d">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__e">
        <path d="M374 327h133v301H374Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__f">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__g">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__h">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__i">
        <path d="M136 223h299v152H136Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__j">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__k">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__l">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__m">
        <path d="M197 514h60v135h-60Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__n">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__o">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__p">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__q">
        <path d="M90 467h135v69H90Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__r">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__s">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
      <clipPath id="In_Transit_svg__t">
        <path d="m424.633 0 330.61 368.406-425.34 381.703L-.708 381.703Zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#In_Transit_svg__a)">
      <g clipPath="url(#In_Transit_svg__b)">
        <g clipPath="url(#In_Transit_svg__c)">
          <g clipPath="url(#In_Transit_svg__d)">
            <path
              fill="#fff"
              d="M471.766 348.066c93.086-92.902 137.343-140.449 118.515-163.48a24 24 0 0 0-.304-.34 24 24 0 0 0-.305-.34c-20.867-21.199-72.824 17.594-175.309 100.2C327.2 354.343 213.051 487.952 179.125 528.53c-5.71 6.813-5.605 16.856.348 23.489l.613.68c5.953 6.636 15.84 7.898 23.312 2.878 44.086-29.426 189.141-128.426 268.368-207.512m0 0"
            />
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#In_Transit_svg__e)">
      <g clipPath="url(#In_Transit_svg__f)">
        <g clipPath="url(#In_Transit_svg__g)">
          <g clipPath="url(#In_Transit_svg__h)">
            <path
              fill="#fff"
              d="m471.074 327.957 35.57 248.934c1.11 7.914-1.75 15.851-7.703 21.195l-32.496 29.164-92.16-218.727Zm0 0"
            />
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#In_Transit_svg__i)">
      <g clipPath="url(#In_Transit_svg__j)">
        <g clipPath="url(#In_Transit_svg__k)">
          <g clipPath="url(#In_Transit_svg__l)">
            <path
              fill="#fff"
              d="m434.207 286.875-243.648-62.203c-7.66-2.031-15.946.027-21.903 5.371l-32.496 29.164 207.516 115.207Zm0 0"
            />
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#In_Transit_svg__m)">
      <g clipPath="url(#In_Transit_svg__n)">
        <g clipPath="url(#In_Transit_svg__o)">
          <g clipPath="url(#In_Transit_svg__p)">
            <path
              fill="#fff"
              d="m240.766 514.215 15.972 111.582c.54 3.508-.793 7.16-3.43 9.527l-14.632 13.13-41.387-98.141Zm0 0"
            />
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#In_Transit_svg__q)">
      <g clipPath="url(#In_Transit_svg__r)">
        <g clipPath="url(#In_Transit_svg__s)">
          <g clipPath="url(#In_Transit_svg__t)">
            <path
              fill="#fff"
              d="m224.2 495.758-109.208-27.91c-3.43-.914-7.203.015-9.84 2.379L90.52 483.359l93.109 51.727Zm0 0"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgInTransit;

