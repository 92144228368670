import * as React from "react";
import type { SVGProps } from "react";

const SvgNewCaledonia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20" {...props}>
    {/* Blue Background */}
    <rect width="30" height="20" fill="#0033a0" />
    
    {/* Green Triangle */}
    <polygon points="0,0 0,20 10,10" fill="#009e49" />
    
    {/* Red Circle */}
    <circle cx="8" cy="10" r="4" fill="#c8102e" />
    
    {/* Black Circle */}
    <circle cx="8" cy="10" r="3" fill="#000" />
  </svg>
);

export default SvgNewCaledonia;