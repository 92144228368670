import * as React from "react";
import type { SVGProps } from "react";
const SvgSyria = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Syria_svg__cls-3{fill:#007a3d}"}</style>
    </defs>
    <g id="Syria_svg__Syria">
      <path d="M0 0h1280v720H0z" />
      <path
        d="M0 0h1280v480H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M0 0h1280v240H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <g id="Syria_svg__t">
        <path
          id="Syria_svg__o"
          d="m434 282-24.1 74.18L455.55 371"
          className="Syria_svg__cls-3"
        />
        <path
          id="Syria_svg__o-2"
          d="m434 282 24.1 74.18L412.45 371"
          className="Syria_svg__cls-3"
          data-name="o"
        />
      </g>
      <g id="Syria_svg__f">
        <g id="Syria_svg__t-2" data-name="t">
          <path
            id="Syria_svg__o-3"
            d="M508.18 335.9h-78v48"
            className="Syria_svg__cls-3"
            data-name="o"
          />
          <path
            id="Syria_svg__o-4"
            d="m508.18 335.9-63.1 45.84-28.21-38.83"
            className="Syria_svg__cls-3"
            data-name="o"
          />
        </g>
      </g>
      <g id="Syria_svg__t-3" data-name="t">
        <path
          id="Syria_svg__o-5"
          d="m359.82 335.9 63.1 45.84 28.21-38.83"
          className="Syria_svg__cls-3"
          data-name="o"
        />
        <path
          id="Syria_svg__o-6"
          d="M359.82 335.9h78v48"
          className="Syria_svg__cls-3"
          data-name="o"
        />
      </g>
      <g id="Syria_svg__s">
        <g id="Syria_svg__t-4" data-name="t">
          <path
            id="Syria_svg__o-7"
            d="m479.85 423.1-24.11-74.18-45.65 14.83"
            className="Syria_svg__cls-3"
            data-name="o"
          />
          <path
            id="Syria_svg__o-8"
            d="m479.85 423.1-63.11-45.84L445 338.42"
            className="Syria_svg__cls-3"
            data-name="o"
          />
        </g>
        <g id="Syria_svg__f-2" data-name="f">
          <g id="Syria_svg__t-5" data-name="t">
            <path
              id="Syria_svg__o-9"
              d="m388.15 423.1 63.11-45.84L423 338.42"
              className="Syria_svg__cls-3"
              data-name="o"
            />
            <path
              id="Syria_svg__o-10"
              d="m388.15 423.1 24.11-74.18 45.65 14.83"
              className="Syria_svg__cls-3"
              data-name="o"
            />
          </g>
        </g>
      </g>
      <g id="Syria_svg__t-6" data-name="t">
        <path
          id="Syria_svg__o-11"
          d="m866 282-24.1 74.18L887.55 371"
          className="Syria_svg__cls-3"
          data-name="o"
        />
        <path
          id="Syria_svg__o-12"
          d="m866 282 24.1 74.18L844.45 371"
          className="Syria_svg__cls-3"
          data-name="o"
        />
      </g>
      <g id="Syria_svg__f-3" data-name="f">
        <g id="Syria_svg__t-7" data-name="t">
          <path
            id="Syria_svg__o-13"
            d="M940.18 335.9h-78v48"
            className="Syria_svg__cls-3"
            data-name="o"
          />
          <path
            id="Syria_svg__o-14"
            d="m940.18 335.9-63.1 45.84-28.21-38.83"
            className="Syria_svg__cls-3"
            data-name="o"
          />
        </g>
      </g>
      <g id="Syria_svg__t-8" data-name="t">
        <path
          id="Syria_svg__o-15"
          d="m791.82 335.9 63.1 45.84 28.21-38.83"
          className="Syria_svg__cls-3"
          data-name="o"
        />
        <path
          id="Syria_svg__o-16"
          d="M791.82 335.9h78v48"
          className="Syria_svg__cls-3"
          data-name="o"
        />
      </g>
      <g id="Syria_svg__s-2" data-name="s">
        <g id="Syria_svg__t-9" data-name="t">
          <path
            id="Syria_svg__o-17"
            d="m911.85 423.1-24.11-74.18-45.65 14.83"
            className="Syria_svg__cls-3"
            data-name="o"
          />
          <path
            id="Syria_svg__o-18"
            d="m911.85 423.1-63.11-45.84L877 338.42"
            className="Syria_svg__cls-3"
            data-name="o"
          />
        </g>
        <g id="Syria_svg__f-4" data-name="f">
          <g id="Syria_svg__t-10" data-name="t">
            <path
              id="Syria_svg__o-19"
              d="m820.15 423.1 63.11-45.84L855 338.42"
              className="Syria_svg__cls-3"
              data-name="o"
            />
            <path
              id="Syria_svg__o-20"
              d="m820.15 423.1 24.11-74.18 45.65 14.83"
              className="Syria_svg__cls-3"
              data-name="o"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgSyria;
