import * as React from "react";
import type { SVGProps } from "react";
const SvgMauritius = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0-.67h1280v180.33H0z"
      style={{
        fill: "#ea2839",
      }}
    />
    <path
      d="M0 179.67h1280V360H0z"
      style={{
        fill: "#1a206d",
      }}
    />
    <path
      d="M0 360h1280v180.33H0z"
      style={{
        fill: "#ffd500",
      }}
    />
    <path
      d="M0 540.33h1280v180.33H0z"
      style={{
        fill: "#00a551",
      }}
    />
  </svg>
);
export default SvgMauritius;
