import * as React from "react";
import type { SVGProps } from "react";
const SvgBahrain = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M1280 0H315.73L512 72l-196.27 72L512 216l-196.27 72L512 360l-196.27 72L512 504l-196.27 72L512 648l-196.27 72H1280"
      style={{
        fill: "#ce1126",
      }}
    />
  </svg>
);
export default SvgBahrain;
