import * as React from "react";
import type { SVGProps } from "react";

const SvgCaymanIslands = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 480" {...props}>
    {/* Blue background of the flag */}
    <rect width="960" height="480" fill="#00247D" />

    {/* Union Jack in the upper left corner */}
    <g transform="scale(0.5)">
      {/* Blue background */}
      <rect width="960" height="480" fill="#012169" />
      {/* Red diagonals */}
      <polygon points="0,0 320,160 960,0 960,320 640,160 960,480 320,320 0,480" fill="#C8102E" />
      {/* White diagonal border */}
      <polygon points="0,0 320,160 960,0 960,320 640,160 960,480 320,320 0,480" fill="none" stroke="#FFF" strokeWidth="60" />
      {/* Horizontal and vertical white cross */}
      <rect x="400" width="160" height="480" fill="#FFF" />
      <rect y="160" width="960" height="160" fill="#FFF" />
      {/* Horizontal and vertical red cross */}
      <rect x="480" width="80" height="480" fill="#C8102E" />
      <rect y="200" width="960" height="80" fill="#C8102E" />
    </g>

    {/* Cayman Islands Coat of Arms */}
    <g transform="translate(700, 200) scale(0.6)">
      {/* White shield */}
      <rect width="200" height="240" fill="#fff" stroke="#000" strokeWidth="5" />
      {/* Blue and white waves in lower part */}
      <g fill="#00247D">
        <rect y="160" width="200" height="20" fill="#fff" />
        <rect y="180" width="200" height="20" />
        <rect y="200" width="200" height="20" fill="#fff" />
        <rect y="220" width="200" height="20" />
      </g>
      {/* Yellow lion on top */}
      <polygon points="100,40 120,60 140,40 120,20" fill="#FFD100" />
      {/* Pineapple and motto "He hath founded it upon the seas" */}
      <text x="100" y="140" fontSize="18" textAnchor="middle" fill="#000">Pineapple</text>
      <text x="100" y="220" fontSize="12" textAnchor="middle" fill="#000">
        He hath founded it upon the seas
      </text>
    </g>
  </svg>
);

export default SvgCaymanIslands;