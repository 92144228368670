import * as React from "react";
import type { SVGProps } from "react";
const SvgMarshallIslands = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Marshall_Islands_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Marshall_Islands_svg__Marshall_Islands" data-name="Marshall Islands">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#003893",
        }}
      />
      <path
        d="m256 464.82-8.65-143.42-30.07 79L230.7 317l-49.53 68.5 34.6-77.12-65.54 53.36 53.36-65.54-77.12 34.55 68.53-49.5-83.49 13.42 79-30.07L47.13 256l143.36-8.63-79-30.07L195 230.7l-68.5-49.53 77.12 34.6-53.36-65.54 65.54 53.36-34.6-77.12L230.7 195l-13.42-83.43 30.07 79L256 47.13l8.62 143.36 30.07-78.95L281.25 195l49.48-68.5-34.6 77.12 65.59-53.36-53.36 65.54 77.12-34.6L317 230.7l83.43-13.42-79 30.07L464.82 256l-143.42 8.6 79 30.07-83.4-13.42 68.5 49.48-77.12-34.55 53.36 65.54-65.59-53.36 34.6 77.12-49.5-68.48 13.42 83.43-30.07-79zM0 708.48l1280-414.72V11.52L0 685.44Z"
        className="Marshall_Islands_svg__cls-2"
      />
      <path
        d="m0 697 1280-544.36V11.52L0 685.44Z"
        style={{
          fill: "#dd7500",
        }}
      />
    </g>
  </svg>
);
export default SvgMarshallIslands;
