import * as React from "react";
import type { SVGProps } from "react";
const SvgComoros = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {".Comoros_svg__cls-2{fill:#fff}.Comoros_svg__cls-5{fill:#3d8e33}"}
      </style>
    </defs>
    <g id="Comoros_svg__Comoros">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#ffc61e",
        }}
      />
      <path d="M0 180h1280v540H0z" className="Comoros_svg__cls-2" />
      <path
        d="M0 360h1280v360H0z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        d="M0 540h1280v180H0z"
        style={{
          fill: "#3a75c4",
        }}
      />
      <path d="m0 720 640-360L0 0Z" className="Comoros_svg__cls-5" />
      <circle cx={204} cy={360} r={162} className="Comoros_svg__cls-2" />
      <circle cx={276} cy={360} r={162} className="Comoros_svg__cls-5" />
      <path
        id="Comoros_svg__star"
        d="M211.47 234.78h21.8l6.73-20.73 6.73 20.73h21.8l-17.63 12.81 6.73 20.73L240 255.51l-17.63 12.81 6.73-20.73Z"
        className="Comoros_svg__cls-2"
      />
      <path
        id="Comoros_svg__star-2"
        d="M211.47 312.08h21.8l6.73-20.73 6.73 20.73h21.8l-17.63 12.81 6.73 20.73L240 332.81l-17.63 12.81 6.73-20.73Z"
        className="Comoros_svg__cls-2"
        data-name="star"
      />
      <path
        id="Comoros_svg__star-3"
        d="M211.47 389.38h21.8l6.73-20.73 6.73 20.73h21.8l-17.63 12.81 6.73 20.73L240 410.11l-17.63 12.81 6.73-20.73Z"
        className="Comoros_svg__cls-2"
        data-name="star"
      />
      <path
        id="Comoros_svg__star-4"
        d="M211.47 466.68h21.8L240 446l6.73 20.73h21.8l-17.63 12.76 6.73 20.73L240 487.41l-17.63 12.81 6.73-20.73Z"
        className="Comoros_svg__cls-2"
        data-name="star"
      />
    </g>
  </svg>
);
export default SvgComoros;
