import * as React from "react";
import type { SVGProps } from "react";
const SvgSuriname = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#377e3f",
      }}
    />
    <path
      d="M0 144h1280v432H0z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0 216h1280v288H0z"
      style={{
        fill: "#b40a2d",
      }}
    />
    <path
      d="m640 229.75 84.64 260.5-221.59-161H777l-221.59 161Z"
      style={{
        fill: "#ecc81d",
      }}
    />
  </svg>
);
export default SvgSuriname;
