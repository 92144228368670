import * as React from "react";
import type { SVGProps } from "react";
const SvgPickPack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1000}
    height={1000}
    viewBox="0 0 750 750"
    {...props}
  >
    <defs>
      <clipPath id="Pick_Pack_svg__a">
        <path d="M0 2.41h741V750H0Zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#Pick_Pack_svg__a)">
      <path
        fill="#fafdfd"
        d="m739.547 148.188.488-1.348L370.371 2.41 1.273 148.336l-1.234-.484v.933l-.039.04.04.148v463.265l370.144 145.067L740.37 612.199V147.852ZM370 30.816 495.414 75.73 153.563 208.406 37.8 162.746Zm-12.988 693.778-138.63-55.992-69.573-26.122-122.313-48.246V186.738l121.078 47.754v101.875l10.778-2.992 7.296 9.992 10.145-2.36 9.844 10.407 8.57-2.996 13.848 13.738V258.332l10.777 4.27 138.18 54.492Zm13.21-430.707L235.04 240.555l333.356-135.11 137.507 56.028ZM713.95 594.234l-330.515 130.36v-407.5L713.91 186.699v407.535Zm0 0"
      />
    </g>
  </svg>
);
export default SvgPickPack;

