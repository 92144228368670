import * as React from "react";
import type { SVGProps } from "react";
const SvgNepal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Nepal_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="Nepal_svg__Nepal">
      <path d="M0 0h1280v720H0z" className="Nepal_svg__cls-1" />
      <path
        id="Nepal_svg__b"
        d="M496.87 340.94H968.3L496.87 45.71v628.58H968.3L496.87 202.86Z"
        style={{
          fill: "#dc143c",
          stroke: "#003893",
          strokeWidth: 17,
        }}
      />
      <path
        id="Nepal_svg__b-2"
        d="M496.87 340.94H968.3L496.87 45.71v628.58H968.3L496.87 202.86Z"
        data-name="b"
        style={{
          fill: "#dc143c",
        }}
      />
      <path
        d="M520.83 230.23a100.89 100.89 0 0 0 187.79 0 93.9 93.9 0 0 1-187.79 0"
        className="Nepal_svg__cls-1"
      />
      <circle cx={614.72} cy={273.92} r={43.69} className="Nepal_svg__cls-1" />
      <g id="Nepal_svg__jags3">
        <path
          id="Nepal_svg__jag"
          d="m631.44 233.55-4.88-19.14-11.84 15.82"
          className="Nepal_svg__cls-1"
        />
        <path
          id="Nepal_svg__jag-2"
          d="m645.62 243 2.82-19.55-17 10.08"
          className="Nepal_svg__cls-1"
          data-name="jag"
        />
        <path
          id="Nepal_svg__jag-3"
          d="m655.09 257.2 10.09-17-19.56 2.8"
          className="Nepal_svg__cls-1"
          data-name="jag"
        />
      </g>
      <g id="Nepal_svg__jags6">
        <g id="Nepal_svg__jags3-2" data-name="jags3">
          <path
            id="Nepal_svg__jag-4"
            d="m658.42 273.92 15.82-11.84-19.15-4.88"
            className="Nepal_svg__cls-1"
            data-name="jag"
          />
          <path
            id="Nepal_svg__jag-5"
            d="m655.09 290.64 19.15-4.88-15.82-11.84"
            className="Nepal_svg__cls-1"
            data-name="jag"
          />
          <path
            id="Nepal_svg__jag-6"
            d="m645.62 304.82 19.56 2.81-10.09-17"
            className="Nepal_svg__cls-1"
            data-name="jag"
          />
        </g>
      </g>
      <g id="Nepal_svg__jags3-3" data-name="jags3">
        <path
          id="Nepal_svg__jag-7"
          d="m598 233.55 4.88-19.14 11.84 15.82"
          className="Nepal_svg__cls-1"
          data-name="jag"
        />
        <path
          id="Nepal_svg__jag-8"
          d="M583.83 243 581 223.47l17 10.08"
          className="Nepal_svg__cls-1"
          data-name="jag"
        />
        <path
          id="Nepal_svg__jag-9"
          d="m574.35 257.2-10.08-17 19.56 2.8"
          className="Nepal_svg__cls-1"
          data-name="jag"
        />
      </g>
      <g id="Nepal_svg__jags6-2" data-name="jags6">
        <g id="Nepal_svg__jags3-4" data-name="jags3">
          <path
            id="Nepal_svg__jag-10"
            d="m571 273.92-15.82-11.84 19.14-4.88"
            className="Nepal_svg__cls-1"
            data-name="jag"
          />
          <path
            id="Nepal_svg__jag-11"
            d="m574.35 290.64-19.14-4.88L571 273.92"
            className="Nepal_svg__cls-1"
            data-name="jag"
          />
          <path
            id="Nepal_svg__jag-12"
            d="m583.83 304.82-19.56 2.81 10.08-17"
            className="Nepal_svg__cls-1"
            data-name="jag"
          />
        </g>
      </g>
      <circle cx={614.72} cy={507.61} r={63.98} className="Nepal_svg__cls-1" />
      <g id="Nepal_svg__rays2">
        <path
          id="Nepal_svg__ray"
          d="m631.28 569.42-16.56 39.08-16.56-39.08"
          className="Nepal_svg__cls-1"
        />
        <path
          id="Nepal_svg__ray-2"
          d="m598.16 445.81 16.56-39.09 16.56 39.09"
          className="Nepal_svg__cls-1"
          data-name="ray"
        />
      </g>
      <g id="Nepal_svg__rays4">
        <g id="Nepal_svg__rays2-2" data-name="rays2">
          <path
            id="Nepal_svg__ray-3"
            d="m552.92 524.17-39.08-16.56 39.08-16.56"
            className="Nepal_svg__cls-1"
            data-name="ray"
          />
          <path
            id="Nepal_svg__ray-4"
            d="m676.53 491.05 39.08 16.56-39.08 16.56"
            className="Nepal_svg__cls-1"
            data-name="ray"
          />
        </g>
      </g>
      <g id="Nepal_svg__rays2-3" data-name="rays2">
        <path
          id="Nepal_svg__ray-5"
          d="M598.16 569.42 564.28 595l5.2-42.12"
          className="Nepal_svg__cls-1"
          data-name="ray"
        />
        <path
          id="Nepal_svg__ray-6"
          d="m631.28 445.81 33.89-25.57-5.17 42.13"
          className="Nepal_svg__cls-1"
          data-name="ray"
        />
      </g>
      <g id="Nepal_svg__rays4-2" data-name="rays4">
        <g id="Nepal_svg__rays2-4" data-name="rays2">
          <path
            id="Nepal_svg__ray-7"
            d="m552.92 491.05-25.57-33.88 42.13 5.2"
            className="Nepal_svg__cls-1"
            data-name="ray"
          />
          <path
            id="Nepal_svg__ray-8"
            d="m676.53 524.17 25.56 33.88-42.09-5.19"
            className="Nepal_svg__cls-1"
            data-name="ray"
          />
        </g>
      </g>
      <g id="Nepal_svg__rays2-5" data-name="rays2">
        <path
          id="Nepal_svg__ray-9"
          d="m569.48 552.86-42.13 5.19 25.57-33.88"
          className="Nepal_svg__cls-1"
          data-name="ray"
        />
        <path
          id="Nepal_svg__ray-10"
          d="m660 462.37 42.12-5.2-25.56 33.88"
          className="Nepal_svg__cls-1"
          data-name="ray"
        />
      </g>
      <g id="Nepal_svg__rays4-3" data-name="rays4">
        <g id="Nepal_svg__rays2-6" data-name="rays2">
          <path
            id="Nepal_svg__ray-11"
            d="m569.48 462.37-5.2-42.13 33.88 25.57"
            className="Nepal_svg__cls-1"
            data-name="ray"
          />
          <path
            id="Nepal_svg__ray-12"
            d="m660 552.86 5.2 42.12-33.89-25.56"
            className="Nepal_svg__cls-1"
            data-name="ray"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgNepal;
