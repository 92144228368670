import * as React from "react";
import type { SVGProps } from "react";
const SvgGambia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v240H0z"
      style={{
        fill: "#ce1126",
      }}
    />
    <path
      d="M0 240h1280v240H0z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M0 280h1280v160H0z"
      style={{
        fill: "#0c1c8c",
      }}
    />
    <path
      d="M0 480h1280v240H0z"
      style={{
        fill: "#3a7728",
      }}
    />
  </svg>
);
export default SvgGambia;
