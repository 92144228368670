import * as React from "react";
import type { SVGProps } from "react";
const SvgGermany = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path d="M0 .31h1280v719.37H0z" data-name="black stripe" />
    <path
      d="M0 240.1h1280v479.58H0z"
      data-name="red stripe"
      style={{
        fill: "#da1f26",
      }}
    />
    <path
      d="M0 479.9h1280v239.79H0z"
      data-name="gold stripe"
      style={{
        fill: "#fdcd08",
      }}
    />
  </svg>
);
export default SvgGermany;
