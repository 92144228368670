import * as React from "react";
import type { SVGProps } from "react";
const SvgMauritania = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Mauritania_svg__cls-1{fill:#006233}.Mauritania_svg__cls-2{fill:#ffc400}"
        }
      </style>
    </defs>
    <g id="Mauritania_svg__Mauritania">
      <path d="M0-.47h1280v720.94H0z" className="Mauritania_svg__cls-1" />
      <circle
        cx={640.3}
        cy={269.88}
        r={232.8}
        className="Mauritania_svg__cls-2"
      />
      <ellipse
        cx={640.3}
        cy={217.43}
        className="Mauritania_svg__cls-1"
        rx={225.29}
        ry={217.67}
      />
      <g id="Mauritania_svg__c">
        <path
          id="Mauritania_svg__t"
          d="m640.3 119.69-27.85 85.7 42.85 13.93"
          className="Mauritania_svg__cls-2"
        />
        <path
          id="Mauritania_svg__t-2"
          d="m640.3 119.69 27.84 85.7-42.85 13.93"
          className="Mauritania_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Mauritania_svg__c-2" data-name="c">
        <path
          id="Mauritania_svg__t-3"
          d="M726 182h-90.11v45"
          className="Mauritania_svg__cls-2"
          data-name="t"
        />
        <path
          id="Mauritania_svg__t-4"
          d="m726 182-72.9 53-26.49-36.46"
          className="Mauritania_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Mauritania_svg__c-3" data-name="c">
        <path
          id="Mauritania_svg__t-5"
          d="M693.27 282.71 665.42 197l-42.86 13.93"
          className="Mauritania_svg__cls-2"
          data-name="t"
        />
        <path
          id="Mauritania_svg__t-6"
          d="m693.27 282.71-72.91-53 26.48-36.45"
          className="Mauritania_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Mauritania_svg__c-4" data-name="c">
        <path
          id="Mauritania_svg__t-7"
          d="m587.33 282.71 72.9-53-26.48-36.45"
          className="Mauritania_svg__cls-2"
          data-name="t"
        />
        <path
          id="Mauritania_svg__t-8"
          d="M587.33 282.71 615.17 197 658 210.93"
          className="Mauritania_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Mauritania_svg__c-5" data-name="c">
        <path
          id="Mauritania_svg__t-9"
          d="m554.59 182 72.91 53 26.5-36.53"
          className="Mauritania_svg__cls-2"
          data-name="t"
        />
        <path
          id="Mauritania_svg__t-10"
          d="M554.59 182h90.12v45"
          className="Mauritania_svg__cls-2"
          data-name="t"
        />
      </g>
    </g>
  </svg>
);
export default SvgMauritania;
