import * as React from "react";
import type { SVGProps } from "react";
const SvgAntiguaAndBarbuda = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Antigua_and_Barbuda_svg__Antigua_and_Barbuda"
    data-name="Antigua and Barbuda"
    viewBox="0 0 1280 720"
    {...props}
  >
    <defs>
      <style>{".Antigua_and_Barbuda_svg__cls-2{fill:#fcd116}"}</style>
    </defs>
    <path
      d="M0-.33h1280v720.67H0z"
      style={{
        fill: "#fff",
      }}
    />
    <path d="M0-.33h1280v282H0z" />
    <g id="Antigua_and_Barbuda_svg__c">
      <path
        id="Antigua_and_Barbuda_svg__t"
        d="m439.14 281.67 249.91 45.84 12.42-57.62"
        className="Antigua_and_Barbuda_svg__cls-2"
      />
      <path
        id="Antigua_and_Barbuda_svg__t-2"
        d="m439.14 281.67 249.91-45.85 12.42 57.62"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
    </g>
    <g id="Antigua_and_Barbuda_svg__c-2" data-name="c">
      <path
        id="Antigua_and_Barbuda_svg__t-3"
        d="M382.92 191.74 614.29 322.3l38.64-48.85"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
      <path
        id="Antigua_and_Barbuda_svg__t-4"
        d="m382.92 191.74 272.91 45.84-13.57 57.62"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
    </g>
    <g id="Antigua_and_Barbuda_svg__c-3" data-name="c">
      <path
        id="Antigua_and_Barbuda_svg__t-5"
        d="m443.24 115.5 154.59 195.39 57.84-32.64"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
      <path
        id="Antigua_and_Barbuda_svg__t-6"
        d="m443.24 115.5 231.37 130.56L636 294.9"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
    </g>
    <g id="Antigua_and_Barbuda_svg__c-4" data-name="c">
      <path
        id="Antigua_and_Barbuda_svg__t-7"
        d="M533.51 64.55 587.8 295l68.2-11.42"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
      <path
        id="Antigua_and_Barbuda_svg__t-8"
        d="M533.51 64.55 688.11 260l-57.84 32.64"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
    </g>
    <g id="Antigua_and_Barbuda_svg__c-5" data-name="c">
      <path
        id="Antigua_and_Barbuda_svg__t-9"
        d="m640 46.67-54.29 230.48 68.23 11.46"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
      <path
        id="Antigua_and_Barbuda_svg__t-10"
        d="m640 46.67 54.29 230.48-68.23 11.46"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
    </g>
    <g id="Antigua_and_Barbuda_svg__c-6" data-name="c">
      <path
        id="Antigua_and_Barbuda_svg__t-11"
        d="M746.49 64.55 591.89 260l57.84 32.64"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
      <path
        id="Antigua_and_Barbuda_svg__t-12"
        d="M746.49 64.55 692.2 295 624 283.58"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
    </g>
    <g id="Antigua_and_Barbuda_svg__c-7" data-name="c">
      <path
        id="Antigua_and_Barbuda_svg__t-13"
        d="M836.76 115.5 605.39 246.06 644 294.9"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
      <path
        id="Antigua_and_Barbuda_svg__t-14"
        d="M836.76 115.5 682.17 310.89l-57.84-32.64"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
    </g>
    <g id="Antigua_and_Barbuda_svg__c-8" data-name="c">
      <path
        id="Antigua_and_Barbuda_svg__t-15"
        d="m897.08 191.74-272.91 45.84 13.57 57.62"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
      <path
        id="Antigua_and_Barbuda_svg__t-16"
        d="M897.08 191.74 665.71 322.3l-38.64-48.85"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
    </g>
    <g id="Antigua_and_Barbuda_svg__c-9" data-name="c">
      <path
        id="Antigua_and_Barbuda_svg__t-17"
        d="m918.26 281.67-272.91-45.85-13.57 57.62"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
      <path
        id="Antigua_and_Barbuda_svg__t-18"
        d="m918.26 281.67-272.91 45.84-13.57-57.62"
        className="Antigua_and_Barbuda_svg__cls-2"
        data-name="t"
      />
    </g>
    <path
      d="M0 281.67h1280v156.67H0z"
      style={{
        fill: "#0072c6",
      }}
    />
    <path
      d="M0-.33v720.66h1280V-.33L640 720.33Z"
      style={{
        fill: "#ce1126",
      }}
    />
  </svg>
);
export default SvgAntiguaAndBarbuda;
