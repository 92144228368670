import * as React from "react";
import type { SVGProps } from "react";
const SvgZimbabwe = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>
        {
          ".Zimbabwe_svg__cls-3{fill:#de2010}.Zimbabwe_svg__cls-6,.Zimbabwe_svg__cls-7{fill:none;stroke:#000}.Zimbabwe_svg__cls-6{stroke-width:.21px}"
        }
      </style>
    </defs>
    <g id="Zimbabwe_svg__Zimbabwe">
      <path
        id="Zimbabwe_svg__green_stripes"
        d="M0 .49h1280v719.03H0z"
        data-name="green stripes"
        style={{
          fill: "#319208",
        }}
      />
      <path
        id="Zimbabwe_svg__yellow_stripes"
        d="M0 103.21h1280V616.8H0z"
        data-name="yellow stripes"
        style={{
          fill: "#ffd200",
        }}
      />
      <path
        id="Zimbabwe_svg__red_stripes"
        d="M0 205.92h1280v308.15H0z"
        className="Zimbabwe_svg__cls-3"
        data-name="red stripes"
      />
      <path
        id="Zimbabwe_svg__black_stripe"
        d="M0 308.64h1280v102.72H0z"
        data-name="black stripe"
      />
      <path d="M26.15.49 505.5 360 26.15 719.51H0V.49Z" />
      <path
        d="M0 .49 479.35 360 0 719.51"
        style={{
          fill: "#fff",
        }}
      />
      <path
        id="Zimbabwe_svg__triangle_ur"
        d="m194.02 177.39-80.81 163.75 122.81 60.62z"
        className="Zimbabwe_svg__cls-3"
        data-name="triangle ur"
      />
      <g id="Zimbabwe_svg__triangle_ul" data-name="triangle ul">
        <path
          id="Zimbabwe_svg__triangle_ur-2"
          d="m194.02 177.39 80.82 163.75-122.81 60.62z"
          className="Zimbabwe_svg__cls-3"
          data-name="triangle ur"
        />
      </g>
      <path
        id="Zimbabwe_svg__triangle2_ur"
        d="m194.02 177.39-61.19 172.05 129.03 45.9z"
        className="Zimbabwe_svg__cls-3"
        data-name="triangle2 ur"
      />
      <g id="Zimbabwe_svg__triangle2_ul" data-name="triangle2 ul">
        <path
          id="Zimbabwe_svg__triangle2_ur-2"
          d="m194.02 177.39 61.2 172.05-129.04 45.9z"
          className="Zimbabwe_svg__cls-3"
          data-name="triangle2 ur"
        />
      </g>
      <path
        id="Zimbabwe_svg__triangle_ur-3"
        d="m367.69 303.57-180.71-26.26-19.69 135.54z"
        className="Zimbabwe_svg__cls-3"
        data-name="triangle ur"
      />
      <g id="Zimbabwe_svg__triangle_ul-2" data-name="triangle ul">
        <path
          id="Zimbabwe_svg__triangle_ur-4"
          d="m20.35 303.57 180.71-26.26 19.7 135.54z"
          className="Zimbabwe_svg__cls-3"
          data-name="triangle ur"
        />
      </g>
      <path
        id="Zimbabwe_svg__triangle2_ur-3"
        d="m367.69 303.57-182.53-5.03-3.78 136.9z"
        className="Zimbabwe_svg__cls-3"
        data-name="triangle2 ur"
      />
      <g id="Zimbabwe_svg__upper_star_part" data-name="upper star part">
        <g id="Zimbabwe_svg__triangle2_ul-2" data-name="triangle2 ul">
          <path
            id="Zimbabwe_svg__triangle2_ur-4"
            d="m20.35 303.57 182.54-5.03 3.78 136.9z"
            className="Zimbabwe_svg__cls-3"
            data-name="triangle2 ur"
          />
        </g>
      </g>
      <g id="Zimbabwe_svg__cone_l" data-name="cone l">
        <path
          id="Zimbabwe_svg__triangle_l"
          d="m194.02 203.07-64.39 143.11 71.56 32.2z"
          className="Zimbabwe_svg__cls-3"
          data-name="triangle l"
        />
        <path
          id="Zimbabwe_svg__triangle_l-2"
          d="m194.02 203.07 64.39 143.11-71.55 32.2z"
          className="Zimbabwe_svg__cls-3"
          data-name="triangle l"
        />
      </g>
      <g id="Zimbabwe_svg__star">
        <g id="Zimbabwe_svg__cone_l-2" data-name="cone l">
          <path
            id="Zimbabwe_svg__triangle_l-3"
            d="m286.26 486.96-32.02-153.63-76.81 16.01z"
            className="Zimbabwe_svg__cls-3"
            data-name="triangle l"
          />
          <path
            id="Zimbabwe_svg__triangle_l-4"
            d="m286.26 486.96-136.21-77.93 38.97-68.11z"
            className="Zimbabwe_svg__cls-3"
            data-name="triangle l"
          />
        </g>
        <g id="Zimbabwe_svg__lower_star_part" data-name="lower star part">
          <g id="Zimbabwe_svg__cone_l-3" data-name="cone l">
            <path
              id="Zimbabwe_svg__triangle_l-5"
              d="m101.78 486.96 136.21-77.93-38.96-68.11z"
              className="Zimbabwe_svg__cls-3"
              data-name="triangle l"
            />
            <path
              id="Zimbabwe_svg__triangle_l-6"
              d="m101.78 486.96 32.03-153.63 76.81 16.01z"
              className="Zimbabwe_svg__cls-3"
              data-name="triangle l"
            />
          </g>
        </g>
      </g>
      <g id="Zimbabwe_svg__bird">
        <path
          d="M163.41 315c11.28 24.57 14.1 26.18 42.29 35.85s107.84 18.83 115.69 19.73c-5.13-5.13-94.85-71.59-113.27-84.88s-23.57-19.33-23.57-19.33-16.61-29.91-22.35-38.67-16.31-21.17-30.2-21.17-22.35 8.15-26.38 22.65c-2.21.61-14.09 7.86-13.69 15.31 3.42-.41 25-6.45 32.42 17.92s7.86 64.24.2 84S105 381 105 398.34c0 2.42 1.61 7.25 5.64 7.25 1.41 7.66 12.68 62.23 12.68 62.23H284l25.52-98.68"
          style={{
            fill: "#ffc618",
          }}
        />
        <path
          d="M163.41 315c11.28 24.57 14.1 26.18 42.29 35.85s107.84 18.83 115.69 19.73c-5.13-5.13-94.85-71.59-113.27-84.88s-23.57-19.33-23.57-19.33-16.61-29.91-22.35-38.67-16.31-21.17-30.2-21.17-22.35 8.15-26.38 22.65c-2.21.61-14.09 7.86-13.69 15.31 3.42-.41 25-6.45 32.42 17.92s7.86 64.24.2 84S105 381 105 398.34c0 2.42 1.61 7.25 5.64 7.25 1.41 7.66 12.68 62.23 12.68 62.23H284l25.52-98.68"
          className="Zimbabwe_svg__cls-6"
        />
        <path
          d="M110.65 405.59s34.23-2 43.5-2 95.49.11 143.6 11.23"
          className="Zimbabwe_svg__cls-6"
        />
        <path
          d="M122.74 404.91s-3.43-4.15-1.22-12.41 26.38-29.6 26.38-59.81M143.47 403.58s-2.82-13.49 28-25c8.65-2.62 33.42-16.52 34.23-27.79"
          className="Zimbabwe_svg__cls-6"
        />
        <path
          d="M168.19 379.89s75.57.33 135.38 12.41M187.13 370.87s26 .89 30.2-.32S229 366.2 232 357.35"
          className="Zimbabwe_svg__cls-6"
        />
        <path
          d="M202.53 371.18s13.89-7.22 16.61-16.61M160.59 467.82s-5.94-41.18-7.15-64.24l15.56 14.8L185.35 404l14.11 15.06 16.3-13.84 14.11 15.85 16.13-13.7 12.85 14.94L275 410.66l19.15 18.2"
          className="Zimbabwe_svg__cls-6"
        />
        <path
          d="M290.75 441.86c-57.52-8.24-134.5-7.7-134.5-7.7M129.5 291.82s46.8-22.36 55.05-25.48"
          className="Zimbabwe_svg__cls-6"
        />
        <path
          d="m154.72 419.89 15.73 14.32L184.67 420l14.37 14.82 15.52-13.72 14.29 15.02 16.12-13.31 13.17 15.38 16.03-12.26 16.85 14.89"
          className="Zimbabwe_svg__cls-7"
        />
        <circle
          cx={199.36}
          cy={392.71}
          r={6.24}
          className="Zimbabwe_svg__cls-7"
        />
        <circle
          cx={225.71}
          cy={394.47}
          r={6.24}
          className="Zimbabwe_svg__cls-7"
        />
        <circle
          cx={127.61}
          cy={225.86}
          r={4.83}
          className="Zimbabwe_svg__cls-7"
        />
      </g>
    </g>
  </svg>
);
export default SvgZimbabwe;
