import * as React from "react";
import type { SVGProps } from "react";
const SvgCentralAfricanRepublic = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Central_African_Republic_svg__cls-4{fill:#ffce00}"}</style>
    </defs>
    <g
      id="Central_African_Republic_svg__Central_African_Republic"
      data-name="Central African Republic"
    >
      <path
        d="M0-1h1280v180.5H0z"
        style={{
          fill: "#003082",
        }}
      />
      <path
        d="M0 179.5h1280V360H0z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M0 360h1280v180.5H0z"
        style={{
          fill: "#289728",
        }}
      />
      <path
        d="M0 540.5h1280V719H0z"
        className="Central_African_Republic_svg__cls-4"
      />
      <path
        d="M533.33-1h213.33v722H533.33z"
        style={{
          fill: "#d21034",
        }}
      />
      <path
        d="m188.5 15.78 18.24 56.13h59.01l-47.74 34.68 18.23 56.13-47.74-34.69-47.74 34.69 18.23-56.13-47.74-34.68h59.01z"
        className="Central_African_Republic_svg__cls-4"
      />
    </g>
  </svg>
);
export default SvgCentralAfricanRepublic;
