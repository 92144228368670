import * as React from "react";
import type { SVGProps } from "react";
const SvgDenmark = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Denmark_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Denmark_svg__Denmark">
      <path
        d="M0-.11h1280v720.22H0z"
        style={{
          fill: "#cf1f34",
        }}
      />
      <path d="M368-.11h129v720H368z" className="Denmark_svg__cls-2" />
      <path d="M0 293.89h1280v131H0z" className="Denmark_svg__cls-2" />
    </g>
  </svg>
);
export default SvgDenmark;
