import * as React from "react";
import type { SVGProps } from "react";

const SvgNetherlandsAntilles = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20" {...props}>
    {/* Background Color */}
    <rect width="30" height="20" fill="#0033a0" />
    
    {/* White Stripes */}
    <rect y="0" width="30" height="6" fill="#ffffff" />
    <rect y="14" width="30" height="6" fill="#ffffff" />

    {/* Red Stripes */}
    <rect y="6" width="30" height="2" fill="#ef4135" />
    <rect y="12" width="30" height="2" fill="#ef4135" />

    {/* White Stars */}
    <circle cx="7" cy="4" r="1.5" fill="#ffffff" />
    <circle cx="11" cy="4" r="1.5" fill="#ffffff" />
    <circle cx="9" cy="2" r="1.5" fill="#ffffff" />
    <circle cx="9" cy="6" r="1.5" fill="#ffffff" />
    <circle cx="7" cy="8" r="1.5" fill="#ffffff" />
    <circle cx="11" cy="8" r="1.5" fill="#ffffff" />
    <circle cx="7" cy="10" r="1.5" fill="#ffffff" />
    <circle cx="11" cy="10" r="1.5" fill="#ffffff" />
    <circle cx="9" cy="8" r="1.5" fill="#ffffff" />
  </svg>
);

export default SvgNetherlandsAntilles;