import * as React from "react";
import type { SVGProps } from "react";
const SvgFederatedStatesOfMicronesia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Federated_States_of_Micronesia_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <g
      id="Federated_States_of_Micronesia_svg__Federated_States_of_Micronesia"
      data-name="Federated States of Micronesia"
    >
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#75b2dd",
        }}
      />
      <g id="Federated_States_of_Micronesia_svg__c">
        <path
          id="Federated_States_of_Micronesia_svg__t"
          d="m640 89.37-20.82 64.07 32 10.41"
          className="Federated_States_of_Micronesia_svg__cls-2"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-2"
          d="m640 89.37 20.82 64.07-32 10.41"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-2" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-3"
          d="M704.07 135.92H636.7v33.68"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-4"
          d="m704.07 135.92-54.5 39.6-19.8-27.25"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-3" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-5"
          d="m679.6 211.24-20.82-64.07-32 10.41"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-6"
          d="m679.6 211.24-54.5-39.6 19.8-27.25"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-4" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-7"
          d="m600.4 211.24 54.5-39.6-19.8-27.25"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-8"
          d="m600.4 211.24 20.82-64.07 32 10.41"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__star">
        <g id="Federated_States_of_Micronesia_svg__c-5" data-name="c">
          <path
            id="Federated_States_of_Micronesia_svg__t-9"
            d="m575.93 135.92 54.5 39.6 19.8-27.25"
            className="Federated_States_of_Micronesia_svg__cls-2"
            data-name="t"
          />
          <path
            id="Federated_States_of_Micronesia_svg__t-10"
            d="M575.93 135.92h67.37v33.68"
            className="Federated_States_of_Micronesia_svg__cls-2"
            data-name="t"
          />
        </g>
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-6" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-11"
          d="M909.47 358.84 845.4 338l-10.41 32"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-12"
          d="m909.47 358.84-64.07 20.82-10.41-32"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-7" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-13"
          d="M862.92 422.91v-67.37h-33.68"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-14"
          d="m862.92 422.91-39.59-54.5 27.25-19.8"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-8" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-15"
          d="m787.6 398.44 64.07-20.82-10.4-32"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-16"
          d="m787.6 398.44 39.6-54.5 27.25 19.8"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-9" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-17"
          d="m787.6 319.24 39.6 54.51L854.45 354"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-18"
          d="m787.6 319.24 64.07 20.82-10.4 32"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__star-2" data-name="star">
        <g id="Federated_States_of_Micronesia_svg__c-10" data-name="c">
          <path
            id="Federated_States_of_Micronesia_svg__t-19"
            d="m862.92 294.77-39.59 54.5 27.25 19.8"
            className="Federated_States_of_Micronesia_svg__cls-2"
            data-name="t"
          />
          <path
            id="Federated_States_of_Micronesia_svg__t-20"
            d="M862.92 294.77v67.37h-33.68"
            className="Federated_States_of_Micronesia_svg__cls-2"
            data-name="t"
          />
        </g>
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-11" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-21"
          d="m640 628.32 20.82-64.08-32-10.4"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-22"
          d="m640 628.32-20.82-64.08 32-10.4"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-12" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-23"
          d="M575.93 581.77h67.37v-33.69"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-24"
          d="m575.93 581.77 54.5-39.6 19.8 27.25"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-13" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-25"
          d="m600.4 506.45 20.82 64.07 32-10.41"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-26"
          d="M600.4 506.45 654.9 546l-19.8 27.25"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-14" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-27"
          d="M679.6 506.45 625.1 546l19.8 27.25"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-28"
          d="m679.6 506.45-20.82 64.07-32-10.41"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__star-3" data-name="star">
        <g id="Federated_States_of_Micronesia_svg__c-15" data-name="c">
          <path
            id="Federated_States_of_Micronesia_svg__t-29"
            d="m704.07 581.77-54.5-39.6-19.8 27.25"
            className="Federated_States_of_Micronesia_svg__cls-2"
            data-name="t"
          />
          <path
            id="Federated_States_of_Micronesia_svg__t-30"
            d="M704.07 581.77H636.7v-33.69"
            className="Federated_States_of_Micronesia_svg__cls-2"
            data-name="t"
          />
        </g>
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-16" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-31"
          d="m370.53 358.84 64.07 20.82 10.41-32"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-32"
          d="M370.53 358.84 434.6 338l10.41 32"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-17" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-33"
          d="M417.08 294.77v67.37h33.68"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-34"
          d="m417.08 294.77 39.59 54.5-27.25 19.8"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-18" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-35"
          d="m492.4 319.24-64.07 20.82 10.4 32"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-36"
          d="m492.4 319.24-39.6 54.51L425.55 354"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__c-19" data-name="c">
        <path
          id="Federated_States_of_Micronesia_svg__t-37"
          d="m492.4 398.44-39.6-54.5-27.25 19.8"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
        <path
          id="Federated_States_of_Micronesia_svg__t-38"
          d="m492.4 398.44-64.07-20.82 10.4-32"
          className="Federated_States_of_Micronesia_svg__cls-2"
          data-name="t"
        />
      </g>
      <g id="Federated_States_of_Micronesia_svg__star-4" data-name="star">
        <g id="Federated_States_of_Micronesia_svg__c-20" data-name="c">
          <path
            id="Federated_States_of_Micronesia_svg__t-39"
            d="m417.08 422.91 39.59-54.5-27.25-19.8"
            className="Federated_States_of_Micronesia_svg__cls-2"
            data-name="t"
          />
          <path
            id="Federated_States_of_Micronesia_svg__t-40"
            d="M417.08 422.91v-67.37h33.68"
            className="Federated_States_of_Micronesia_svg__cls-2"
            data-name="t"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFederatedStatesOfMicronesia;
