import * as React from "react";
import type { SVGProps } from "react";
const SvgJordan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Jordan_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="Jordan_svg__Jordan">
      <path d="M0 .66h1280v718.67H0z" />
      <path d="M0 240.22h1280v239.56H0z" className="Jordan_svg__cls-1" />
      <path
        d="M0 479.78h1280v239.56H0z"
        style={{
          fill: "#007a3d",
        }}
      />
      <path
        d="M640 360 0 719.34V.67z"
        style={{
          fill: "#ce1126",
        }}
      />
      <path
        id="Jordan_svg__path14"
        d="m240.44 360.72-27.27 2.54 3.59 27.25-18.99-19.81-18.99 19.81 3.6-27.25-27.28-2.54 23.47-14.17-15.02-22.98 25.68 9.58 8.54-26.11 8.54 26.11 25.68-9.58-15.02 22.98z"
        className="Jordan_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgJordan;
