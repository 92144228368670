import * as React from "react";
import type { SVGProps } from "react";
const SvgTanzania = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Tanzania_svg__cls-3{fill:#eac126}"}</style>
    </defs>
    <g id="Tanzania_svg__Tanzania">
      <path
        d="M0 0v546.69L975.85 0z"
        style={{
          fill: "#42a04b",
        }}
      />
      <path
        d="M304.15 720H1280V173.28z"
        style={{
          fill: "#4694d2",
        }}
      />
      <path
        d="m1279.31 0 .21.26.48-.26zM0 720l.48-.26-.48-.61zM975.85 0 0 546.69v55.59L1073.32 0z"
        className="Tanzania_svg__cls-3"
      />
      <path
        d="M0 720h.69l-.21-.26zM1280 0l-.48.26.48.61zM206.68 720h97.47L1280 173.28v-55.59z"
        className="Tanzania_svg__cls-3"
      />
      <path d="M1073.32 0 0 602.28v116.85l.48.61.21.26h205.99L1280 117.72V.87l-.48-.61-.21-.26z" />
    </g>
  </svg>
);
export default SvgTanzania;
