import * as React from "react";
import type { SVGProps } from "react";
const SvgNauru = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <path
      d="M0 0h1280v720H0z"
      style={{
        fill: "#002b7f",
      }}
    />
    <path
      d="M0 330h1280v60H0z"
      style={{
        fill: "#ffc61e",
      }}
    />
    <path
      d="m360 630-15.53-62L300 614l17.57-61.49L256.08 570l46-44.47L240 510l62-15.53L256 450l61.49 17.57L300 406.08l44.47 46L360 390l15.53 62L420 406l-17.57 61.49L463.92 450l-46 44.47L480 510l-62 15.53L464 570l-61.49-17.57L420 613.92l-44.47-46Z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgNauru;
