import * as React from "react";
import type { SVGProps } from "react";
const SvgEthiopia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720" {...props}>
    <defs>
      <style>{".Ethiopia_svg__cls-2{fill:#fcdd09}"}</style>
    </defs>
    <g id="Ethiopia_svg__Ethiopia">
      <path
        d="M0 0h1280v720H0z"
        style={{
          fill: "#da121a",
        }}
      />
      <path d="M0 0h1280v480H0z" className="Ethiopia_svg__cls-2" />
      <path
        d="M0 0h1280v240H0z"
        style={{
          fill: "#078930",
        }}
      />
      <circle
        cx={640}
        cy={360}
        r={213.33}
        style={{
          fill: "#0f47af",
        }}
      />
      <path
        id="Ethiopia_svg__path12"
        d="M477.69 306.6h124l28-86.29 7.48 23-40.75 125.4-12.09-8.79 12.7-39.11h-99.8zm62 190.81L578 379.48l-73.4-53.33h24.2l106.66 77.5-12.09 8.79-33.27-24.17-30.84 94.92-19.58 14.22m200.64 0L640 424.53l-73.4 53.32 7.48-23 106.66-77.5 4.62 14.23-33.26 24.16 80.74 58.66 7.48 23m62-190.81L702 379.48l28 86.29-19.57-14.22-40.74-125.4h14.95l12.71 39.11 80.74-58.66h24.19M640 188.67l38.32 117.93H769l-19.57 14.22H617.62l4.63-14.22h41.11l-30.84-94.92 7.48-23Z"
        className="Ethiopia_svg__cls-2"
      />
      <path
        id="Ethiopia_svg__path14"
        d="M640 438.22v92.45m-74.39-146.5-87.92 28.57M594 296.72l-54.34-74.79M686 296.72l54.34-74.79m-25.95 162.24 87.92 28.57Z"
        style={{
          stroke: "#fcdd09",
          strokeWidth: "6.67px",
        }}
      />
    </g>
  </svg>
);
export default SvgEthiopia;
